import React from 'react';
import { FormControl, InputLabel, FilledInput } from '@material-ui/core';
import './InputComponents.css';
const TextInputNoIcon = props => {
    const {
        onChange,
        onBlur,
        label,
        error,
    } = props;
    return (
        <FormControl variant="filled" style={{width:"100%"}}>
            <InputLabel className="blackColor verticalCenter">{label}</InputLabel>
            <FilledInput required={true} className='textInput' type='text' onChange={onChange} onBlur={onBlur}
                error={error} fullWidth disableUnderline style={{height:40}}
            />
        </FormControl>
    );
}

export default TextInputNoIcon;
