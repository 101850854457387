import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const compareGenderSearchAction = (data) => {
    return {
        type: actionTypes.COMPARE_GENDER,
        searchResult: data
    }
}

export const compareGenderSearch = (token, ids, depts) => {

    var z = ""
    for (let i = 0; i < ids.length; i++) {
        if (i === 0) {
            z += "id=";
            z += ids[i];
        } else {
            z += "&id=";
            z += ids[i];
        }
    }
    var dep = "";
    for (let i = 0; i < depts.length; i++) {
        dep += "&department=";
        dep += depts[i];
    }
    return async dispatch => {
        const response = await fetch(BACKEND_URL + "gender?" + z + dep, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(errorResData);
        }
        const responseData = await response.json();
        dispatch(compareGenderSearchAction(responseData));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_COMPARE_GENDER });
    }
}