import React from 'react';
import classes from './SideNav.module.css';
import { Link } from 'react-router-dom';
import schoolLogo from '../../assets/Images/schools.png';
import facultyLogo from '../../assets/Images/faculty.png';
import othersLogo from '../../assets/Images/specialreports.png';
const sideNav2 = props => {
    const linkStyle = {
        textDecoration: 'none',
        color: 'white',
    }
    let sideNav
    let isLoggedIn = false
    if (isLoggedIn) {
        sideNav = <div></div>
    }
    else {
        sideNav = <div>
            {/* <div className={classes.social}>
       <ul> */}
            {/* <Link to = "/scheduleDemo" style={linkStyle} className="test" id="scheduleDemo">
               SCHEDULE DEMO 
               <img src = {scheduleDemoLogo} alt="Research Loupe"/>
           </Link>
           <Link to = "/demoVideo"style={linkStyle} >
               DEMO VIDEO 
               <img src = {videoLogo} alt="Research Loupe"/>
           </Link> */}
            {/* <Link to = "/" style={linkStyle} > 
               FOR SCHOOLS 
               <img src = {schoolLogo} alt="Research Loupe" />
           </Link>
           <Link to = "/forFaculty" style={linkStyle}>
               FOR FACULTY 
               <img src = {facultyLogo} alt="Research Loupe"/>
           </Link>
           <Link to = "/forOthers"style={linkStyle} >
               SPECIAL REPORTS 
               <img src = {othersLogo} alt="Research Loupe"/>
           </Link>
       </ul>
       </div> */}

        </div>
    }
    return (
        sideNav
    );
}

export default sideNav2;