import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { List, ListItem, Button, Divider } from '@material-ui/core';
import '../SidebarNav/SidebarNav.css';
import * as authActions from '../../../../../store/actions/auth';
import * as profileActions from '../../../../../store/actions/profile';
import * as univProfileActions from '../../../../../store/actions/univProfile';
import * as facultyProgressActions from '../../../../../store/actions/facultyProgress';
import * as compareYourselfActions from '../../../../../store/actions/compareYourself';
import * as findYourFitActions from '../../../../../store/actions/findYourFit';
import * as exploreActions from '../../../../../store/actions/explore';
import * as authorRankActions from '../../../../../store/actions/authorRank';
import * as journalDistributionActions from '../../../../../store/actions/journalDistributionTree';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    padding: 0
  },
  button: {
    color: "#FFFFFF",
    padding: '8px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    borderRadius: 0,
    width: '100%',
    fontWeight: 200,
    fontSize: "1rem",
    lineHeight: 1.167,
    letterSpacing: "0em",
    marginLeft: 14,
    '&:visited': {
      color: '#FFFFFF'
    },
    '&:focus': {
      backgroundColor: "#BBB218 !important",
      color: '#000000'
    },
    '&:hover': {
      backgroundColor: "#BBB218 !important",
      color: "#000000"
    },
    '&:active': {
      color: '#000000'
    }
  },

  a: {
    color: '#00FF00',
    '&:visited': {
      color: '#00FF00'
    },
    '&:focus': {
      color: '#00FF00'
    },
    '&:hover': {
      color: '#00FF00'
    },
    '&:active': {
      color: '#00FF00'
    }
  },
  icon: {
    color: '#FFFFFF',
    width: 32,
    height: 24,
    display: 'flex',
    alignItems: 'end',
    marginRight: 1,
    opacity: 0.4,
  },
  whiteDivider: {
    backgroundColor: "#ffffff"
  }

}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { adminPages, pages } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  // If user is faculty and their profile is not complete, disable all sidebar tabs except 'Logout' and 'My Profile'
  const userType = useSelector(state => state.auth.user_type);
  const changedLogin = useSelector(state => state.auth.changed_login);
  const isComplete = useSelector(state => state.profile.profile.is_complete);
  const token = localStorage.getItem('rl_token');

  const clearReduxStore = () => {
    dispatch(profileActions.clearState());
    dispatch(univProfileActions.clearState());
    dispatch(facultyProgressActions.clearState());
    dispatch(compareYourselfActions.clearState());
    dispatch(findYourFitActions.clearState());
    dispatch(exploreActions.clearState());
    dispatch(authorRankActions.clearState());
    dispatch(journalDistributionActions.clearState());
  }

  const click = (title) => {
    if (title === 'Log Out') {
      dispatch(authActions.logOut());
      clearReduxStore();
    }
  }

  useEffect(() => {
    dispatch(profileActions.get_author_profile(token));
  }, [])

  return (
    <Paper style={{ maxHeight: '100%', overflow: 'auto', backgroundColor: '#555559', margin: '0px' }}>
      <List style={{ margin: '0px', padding: '0px' }}>
        {pages.map(page => (
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}>
            <Button disabled={
              (
                !isComplete && userType === 'faculty' &&
                localStorage.getItem("rl_token_a") == null &&
                !(['Log Out', 'My Profile'].includes(page.title))
              )
              ||
              (
                changedLogin === true &&
                ['My Profile'].includes(page.title)
              )}
              onClick={() => { click(page.title) }}
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
              style={{ margin: '0px' }}>

              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}
        {adminPages.length > 0 && <Divider className={classes.whiteDivider} />}
        {adminPages.length > 0 ?
          adminPages.map(page => (
            <ListItem
              className={classes.item}
              disableGutters
              key={page.title}>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
                style={{ margin: '0px' }}>

                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          )) :
          null
        }
      </List>
    </Paper>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;