import React from 'react';
import {
    Button, Paper, Grid
} from '@material-ui/core';
import classes from './NameMismatch.module.css';
import BasicProfile from '../BasicProfile/BasicProfile';
import * as logActions from '../../../store/actions/log';
import { useDispatch } from 'react-redux';

const NameMismatch = (props) => {
    
    const log_details = props.history.location.state;
    const dispatch = useDispatch();
    const token = localStorage.getItem("rl_token");

    const goBack = () => {
        props.history.replace({ 
            pathname: "/logs",
        });
    }

    const misMatch = async () => {
        //dont map this paper and this author. Remove log error from DB and return to /logs page
        await dispatch(logActions.resolveNameMismatch(token, log_details, false));
        goBack();
    };

    const match = async () => {
        //map this paper and this author. Remove log error from DB and return to /logs page
        await dispatch(logActions.resolveNameMismatch(token, log_details, true));
        goBack();
    };

    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <div className={classes.featureHeading}>
                {log_details.error === "GS_NAME_MISMATCH" ? "Google Scholar Name Mismatch" : "Research Gate Name Mismatch"}
            </div>
            
            <div className={classes.subheader}>Paper</div>
            <Paper square style={{ padding: "10px 22px", marginTop: "10px" }}>
                <h5>{log_details.paper.document_title}</h5>
                <div style = {{display:"flex"}}>
                    <div style = {{flex:1}}>
                        <strong>Name on Paper:</strong> {log_details.author_name}
                    </div>
                    <div style = {{flex:1}}>
                        <strong>Name on record:</strong> {log_details.already_name}
                    </div>
                </div>
                <div style = {{display:"flex"}}>
                    <div style = {{flex:1}}>
                        <strong>Publisher:</strong> {log_details.paper.publisher}
                    </div>
                    <div style = {{flex:1}}>
                        <strong>Publlication Year:</strong> {log_details.paper.publication_year}
                    </div>
                </div>
            </Paper>
            <br/>
            <Paper id="userAccessCard" square style={{ padding: "10px 22px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingTop: "5px" }}>
                        <div style={{fontSize: "18px"}}>To resolve this log please have a look at the papers, Research Gate and Gooogle Scholar profiles of the author. Only click <b>YES</b> when you are sure that the paper belongs to the author displayed below.</div>
                    </Grid>
                </Grid>
            </Paper>
            <div style={{ textAlign: "center", margin: "2%" }}>
                <Button style={{ width: 145, marginRight: 20 }} className={classes.submitButton} variant="contained" onClick = {() => match()}>
                    {"Yes"}
                </Button>
                <Button style={{ width: 145 }} className={classes.submitButton} variant="contained" onClick = {() => misMatch()}>
                    {"No"}
                </Button>
            </div>

            <div className={classes.subheader}>Author Details</div>
            <BasicProfile data={log_details} />

        </div>
    )
};

export default NameMismatch;