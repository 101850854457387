import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const create_update_author_object = (profile) => {
    var updateObj = {}
    if (profile.first_name !== undefined)
        updateObj["first_name"] = profile.first_name
    if (profile.mid_name !== undefined)
        updateObj["mid_name"] = profile.mid_name
    if (profile.last_name !== undefined)
        updateObj["last_name"] = profile.last_name
    if (profile.cv !== undefined)
        updateObj["cv"] = profile.cv
    if (profile.highlighted_cv !== undefined)
        updateObj["highlighted_cv"] = profile.highlighted_cv
    if (profile.bio !== undefined)
        updateObj["bio"] = profile.bio
    if (profile.education !== undefined) {
        var newEdu = []
        var education = profile.education;
        for (var i = 0; i < education.length; i++) {
            var obj = {
                area: education[i].area, degree: education[i].degree, institution: education[i].institution, year: education[i].year, advisorFirstName: education[i].advisorFirstName
                , advisorLastName: education[i].advisorLastName, coadvisorFirstName: education[i].coadvisorFirstName, coadvisorLastName: education[i].coadvisorLastName
            };
            if (typeof education[i].isPHDtoConsider !== 'undefined') {
                obj = { isPHDtoConsider: education[i].isPHDtoConsider, ...obj };
            }
            newEdu.push(obj);
        }
        updateObj["education"] = newEdu
    }
    if (profile.suffix !== undefined)
        updateObj["suffix"] = profile.suffix
    if (profile.prefix !== undefined)
        updateObj["prefix"] = profile.prefix
    if (profile.position !== undefined)
        updateObj["position"] = profile.position
    if (profile.email !== undefined)
        updateObj["email"] = profile.email
    if (profile.univ_name !== undefined)
        updateObj["univ_name"] = profile.univ_name
    if (profile.univ_id !== undefined)
        updateObj["univ_id"] = profile.univ_id
    if (profile.work_history_manual !== undefined) {
        var newworkhis = []
        var workhistory = profile.work_history_manual;
        for (var i = 0; i < workhistory.length; i++) {
            var obj = {
                institution: workhistory[i].institution, from: workhistory[i].from, school: workhistory[i].school,
                title: workhistory[i].title, to: workhistory[i].to, subTitle: workhistory[i].subTitle, marker: workhistory[i].marker
            }
            newworkhis.push(obj);
        }
        updateObj["work_history_manual"] = newworkhis
    }
    if (profile.is_complete !== undefined)
        updateObj["is_complete"] = profile.is_complete
    if (profile.gender !== undefined)
        updateObj["gender"] = profile.gender
    if (profile.marker !== undefined)
        updateObj["marker"] = profile.marker
    if (profile.department !== undefined)
        updateObj["department"] = profile.department
    if (profile.gs !== undefined)
        updateObj["gs"] = profile.gs
    if (profile.rg !== undefined)
        updateObj["rg"] = profile.rg
    if (profile.percent_appointment !== undefined)
        updateObj["percent_appointment"] = profile.percent_appointment
    if (profile.unusual_work_hist !== undefined)
        updateObj["unusual_work_hist"] = profile.unusual_work_hist
    if (profile.name_variations !== undefined)
        updateObj["name_variations"] = profile.name_variations
    if (profile.univ_profile_link !== undefined)
        updateObj["univ_profile_link"] = profile.univ_profile_link
    if (profile.keywords !== undefined)
        updateObj["keywords"] = profile.keywords
    if (profile.area_of_interest !== undefined)
        updateObj["area_of_interest"] = profile.area_of_interest
    return updateObj
};

export const get_author_profile = (token, id) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'profile/get_profile', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong in fetching author profile' + errorResData);
        }
        const resData = await response.json();
        dispatch({ type: actionTypes.GET_AUTHOR_PROFILE, profileData: resData });
    };
};

export const get_co_author_profile = (token, id) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'profile/' + id, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong in fetching author profile' + errorResData);
        }
        const resData = await response.json();
        dispatch({ type: actionTypes.GET_AUTHOR_PROFILE, profileData: resData });
    };
};

export const verify_author_profile = (token, profileData) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'profile/' + profileData._id, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong in fetching author profile' + errorResData);
        }
        dispatch({ type: actionTypes.VERIFY_AUTHOR_PROFILE, profileData: profileData });
    };
};

export const update_author_profile = (token, profile) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'profile/update', {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "update": create_update_author_object(profile)
            })
        });
        if (!response.ok) {
            throw new Error('Something went wrong in updating Author Profile.');
        }
        dispatch({ type: actionTypes.UPDATE_AUTHOR_PROFILE, profileData: profile });
    };
};

export const clearState = () => {

    return async dispatch => {

        dispatch({ type: actionTypes.CLEAR_PROFILE });
    };
};