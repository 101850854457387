import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    facultyData: [],
    showModal: false,
    confirmedFacultyId: "",
    confirmedFacultyPic: "",
    confirmedFacultyData: {},
    len: 0
}

const facultyInfoInputReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SIMILAR_FACULTY:
            let confirmedFaculty1 = "";
            let confirmedFacultyPic1 = "";
            let confirmedFacultyData1 = {};
            if (action.searchResult.authors.length === 1) {
                confirmedFaculty1 = action.searchResult.authors[0].author_id;
                confirmedFacultyPic1 = action.searchResult.authors[0].pic;
                confirmedFacultyData1 = action.searchResult.authors[0];
            }
            return updateObject(state, { facultyData: action.searchResult.authors, len: action.searchResult.authors.length, confirmedFacultyId: confirmedFaculty1, confirmedFacultyPic: confirmedFacultyPic1, confirmedFacultyData: confirmedFacultyData1, showModal: action.searchResult.authors.length > 1 });
        case actionTypes.TOGGLE_MODAL:
            return updateObject(state, { showModal: action.value });
        case actionTypes.CLEAR_DATA:
            return updateObject(state, { facultyData: [], showModal: false, confirmedFacultyId: "", confirmedFacultyPic: "", confirmedFacultyData: {}, len: 0 });
        case actionTypes.SET_FACULTY_ID:
            return updateObject(state, { confirmedFacultyId: action.id, confirmedFacultyPic: action.pic, confirmedFacultyData: action.data });
        default:
            return state
    }
}

export default facultyInfoInputReducer;