import React  from 'react';
import DirectionReveal from '../../scripts/direction-reveal';
import '../swing/Swing.module.css';
import 'rodal/lib/rodal.css';
import '../../styles/site.scss';
import '../../styles/direction-reveal.scss';



const SwingComponent = props => {
    const directionRevealBackground = {
        // width: "67vw",
        height: "37vh",
        textAlign:"center",
        // borderStyle:"solid",
        // borderRadius:"3%",
        padding:"6px"
    }
    React.useEffect(()=>{
            const directionRevealDefault = DirectionReveal({
            selector: '.direction-reveal',
            itemSelector: '.direction-reveal__card',
            animationName: 'swing',
            animationPostfixEnter: 'enter',
            animationPostfixLeave: 'leave',
            enableTouch: true,
            touchThreshold: 250
          });
          directionRevealDefault.init();
    });

    const addSquareData = (data) =>{
        let jsx1 = []
        for( let text of data){
            jsx1.push(
                <div className="direction-reveal__card" key={text["title"]} >
                    <div style={directionRevealBackground}>
                        <h4 style={{color:"#4F738A"}}> {text["title"]} </h4>
                        <p>{text["detailedDescription"]}</p>
                    </div>
                    <div className="direction-reveal__overlay direction-reveal__anim--enter" style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                        <h5 className="direction-reveal__title">{ text["title"] }</h5>
                        <p className="direction-reveal__text">
                            { text["description"] }
                        </p>
                    </div>
                </div>
            );
        }
        return jsx1;
    }

    const getSchoolData = () =>{
        let text = [
            {
                "title" : "Cutting edge faculty recruiting strategies",
                "description" : "ResearchLoupe's advanced intelligence presents you with names of potential faculty hires before the competition knows they're on the market",
                "detailedDescription" : "Identify potential faculty hires before the competition.  See who is unlikely to get tenure at the top schools before they're on the market"
            },
            {
                "title" : "Accurate research impact measures, by faculty and average faculty",
                "description" : "ResearchLoupe helps you understand where each of your faculty is positioned among his or her peers",
                "detailedDescription" : "Accurately understand the contribution of each faculty at your school and peer schools. View research impact by papers and/or citations.  Analyze standing by journal tier or by ratio across journal tiers"
            },
            {
                "title" : "Advanced tools for informed negotiations",
                "description" : "ResearchLoupe measures the actual impact of a single faculty member on his or her department or school",
                "detailedDescription" : "Simulate in advance the impact of adding or subtracting specific scholar(s) at your school. With clear metrics, negotiate challenging offer and retention cases with new confidence."
            },
            {
                "title" : "Critical research impact comparisons",
                "description" : "ResearchLoupe presents the vital faculty research metrics you can't afford to ignore and allows you to compare your school to the competition on multiple dimensions",
                "detailedDescription" : "Compare your school to peer institutions by journal rank, gender distribution-faculty rank, departmental strength, multidisciplinarity, etc"
            },
            {
                "title" : "Cross-departmental faculty comparisons",
                "description" : "ResearchLoupe's innovative methodology lets you compare academic departments within a school and faculty across academic departments",
                "detailedDescription" : "Easily compare academic departments by impact, average faculty productivity, journal rank distribution, gender, ethnicity, etc"
            },
            {
                "title" : "Instant, customized cohort analyses",
                "description" : "ResearchLoupe instantly generates a list of comparative faculty for cohort analysis or helps you create customized cohorts",
                "detailedDescription" : "Substantively reduce the amount of administrative time spent on tenure and promotion cases in your school."
            },
            {
                "title" : "Effective tools for tenure review and promotion",
                "description" : "ResearchLoupe generates a list of names in the specific area by levels of association with the faculty",
                "detailedDescription" : "Decide with ease from whom to request tenure letters. Speed the promotion and tenure process with confidence and helping resources"
            },
            {
                "title" : "Customized and tailored to your school",
                "description" : "ResearchLoupe adapts to your priorities and rules. You choose the journals for each tier as perceived by your school. The makeup of your school's departments is the basis for comparisons.",
                "detailedDescription" : "Set your own school's classification for top and near-top journals. View other schools through the lenses of your school's departmental structures"
            },
            {
                "title" : "Strategic positioning support",
                "description" : "ResearchLoupe creates positioning maps aligned with your department profiles",
                "detailedDescription" : "Identify academic areas in which your school could further differentiate itself.  Identify needs and advocate for new faculty hires"
            }
        ];
         return text;
    }

    const getFacultyData = () => {
        let text = [
            {
                "title" : "Find your fit",
                "description" : "We show career developments of other faculty with research profiles like yours",
                "detailedDescription" : "Find which schools would be a good fit for your research profile"
            },
            {
                "title" : "See how you are doing and choose your next steps",
                "description" : "We show how you position with respect to your peers in your school and in other schools",
                "detailedDescription" : "Identify your level of participation among your pears. Aim to align or differentiate your research profile from others"
            },
            {
                "title" : "Understand your field's network",
                "description" : "We map your connections and strong faculty connections in your area",
                "detailedDescription" : "Visualize your network of academic proximity to any faculty"
            },
            {
                "title" : "Compare yourself with your role models and follow the path to a successful career",
                "description" : "We allow you to isolate other faculty's performance to the period of time in their careers that compares to yours. We give you the opportunity to compare your path with others that mirror your goals",
                "detailedDescription" : "Visualize how you compare with other faculty across different seniority levels. Present successful paths and arguments to support your career moves and strategies"
            }
        ];
        return text;
    }

    
    let squares = [
    ];

    if(props.type === "schools"){
        let data = getSchoolData();
        squares = addSquareData(data);
    }
    else if(props.type === "faculty"){
        let data = getFacultyData();
        squares = addSquareData(data);
    }
    const getMatrix = () => {
        if (props.type==="faculty"){
            return (
                <div className="direction-reveal direction-reveal--2-grid direction-reveal--demo-swing">
                    {squares}
                </div>
            )
        }
        return(
            <div className="direction-reveal direction-reveal--3-grid direction-reveal--demo-swing">
                {squares}
            </div>
        )
    }
        return ( 
                <div className="fullwidth">
                    <div className="container separator">                
                        {getMatrix()}
                    </div>
                </div>
            );
}

export default SwingComponent;