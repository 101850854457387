import { Paper } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import Chart from "react-google-charts";
import Settings from './Settings';
import Loading from '../../../../../assets/Images/loading.gif';

const UniversityGenderDistribution = props => {
    const dispatch = useDispatch();
    const genderDistributionResult = useSelector(state => state.dashboards.genderDistributionResult);
    const [department, setDepartment] = React.useState("");
    const [showSpinner, setShowSpinner] = React.useState(false);

    React.useEffect(() => {
        genderDistribution(null);
    }, []);

    const genderDistribution = async (dept) => {
        const token = localStorage.getItem('rl_token');
        setShowSpinner(true);
        try {
            if (dept === null) {
                await dispatch(dashboardActions.genderDistribution(token, true, ""));
            } else {
                await dispatch(dashboardActions.genderDistribution(token, false, dept));
            }
        } catch (error) {
            console.log("Error getting university gender distribution")
        }
        setShowSpinner(false);
    }

    const handleSchoolDeptSwitchChange = async (department) => {
        if (department === null) {
            genderDistribution(null);
            setDepartment(null);
        }
        else {
            genderDistribution(department);
            setDepartment(department);
        }
    }

    return (
        <Paper style={{ height: "360px", padding: "12px" }}>
            <div style={{ fontSize: "20px" }}>
                School Gender Distribution{department === null || department === "" ? "" : ` - ${department}`}
                <span style={{ float: "right" }}>
                    <Settings handleSchoolDeptSwitchChange={handleSchoolDeptSwitchChange} department={department} />
                </span>
            </div>
            {
                showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <Chart
                        height={'275px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Gender distribution', 'Percentage'],
                            ['Male', genderDistributionResult["male"]],
                            ['Female', genderDistributionResult["female"]],
                            ['Others', genderDistributionResult["other"]],
                        ]}
                        options={{
                            legend: { position: 'bottom' },
                            pieSliceText: 'label',
                            pieStartAngle: 100,
                            colors: ['#00259A', '#E24301', '#bbb218'],
                        }}
                        rootProps={{ 'data-testid': '4' }}
                    />
            }
        </Paper>

    )
}

export default UniversityGenderDistribution;