import {FETCH_LOGS, RESOLVE_LOG, FILTER_LOGS} from './actionTypes';
import { BACKEND_URL } from '../../utils';
import axios from 'axios';

const fetchLogsAction = (logs) => {
    return {
        type: FETCH_LOGS,
        payload: logs
    }
}

const filterLogsAction = (logs, filter_by) => {
    return {
        type: FILTER_LOGS,
        payload: logs,
        condition: filter_by
    }
}

const resolveLogAction = (log_to_resolve) => {
    return {
        type: RESOLVE_LOG,
        payload: log_to_resolve
    }
}

export const filterLogs =  (token, filter_by) => {
    return async dispatch => {
        let url = BACKEND_URL + "data_issues"
        if (!filter_by) {
            filter_by = "All"
        }
        if (filter_by !== "All") {
            url += "/" + filter_by.toLowerCase();
        }
        const response = await fetch(url, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching logs with error: ' +errorResData);
        }
        const logs = await response.json();
        dispatch(filterLogsAction(logs, filter_by));
    }
}

export const resolveLog = (log_to_resolve) => {
    return dispatch => {
        dispatch(resolveLogAction(log_to_resolve));
    }
}

export const fetchLogs = (token, filterCondition) => {
    return async dispatch => {
        let url = BACKEND_URL + "data_issues"
        if (filterCondition !== "All") {
            url += "/" + filterCondition.toLowerCase();
        }
        const response = await fetch(url, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching logs with error: ' +errorResData);
        }
        const logs = await response.json();
        dispatch(fetchLogsAction(logs));
    }
}

export const resolveMultipleNameVariationLog = (token, log, removedPapers, removedNameVariations) => {
    return async dispatch => {
        let removedPapersId = [];
        removedPapers.forEach(paper => removedPapersId.push(paper._id));
        let post_body = {
            "mongo_author_id": log.mongo_author_id,
            "removed_papers": removedPapersId,
            "removed_name_variations": removedNameVariations
        }
        const response = await axios.post(BACKEND_URL + 'data_issues/multiple_last_name_two_character_name_variation/'+log._id,  post_body, 
            {
                headers: {
                    Authorization: 'Bearer '+ token,
            }}
        );
        if(response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while resolving multiple_last_name_two_character_name_variation error : ' +errorResData);
        }
        else {
            dispatch(resolveLogAction(log));
        }
    }
}

export const resolveGapBetweenPapersLog = (token, log, removedPapers) => {
    return async dispatch => {
        let removedPapersId = [];
        removedPapers.forEach(paper => removedPapersId.push(paper._id));
        let post_body = {
            "mongo_author_id": log.author_id,
            "removed_papers": removedPapersId
        }
        const response = await axios.post(BACKEND_URL + 'data_issues/gap_of_10_years/'+log._id,  post_body, 
            {
                headers: {
                    Authorization: 'Bearer '+ token,
            }}
        );
        console.log(response);
        if(response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while resolving gap_of_10_years error: ' +errorResData);
        }
        else {
            dispatch(resolveLogAction(log));
        }
    }
}

export const resolveNameMismatch = (token, log, toMap) => {
    return async dispatch => {
        let post_body = {
            "author_details_id": log.author_detail_id,
            "author_id": log.author_id,
            "paper_id": log.paper_id,
            "toMap": toMap
        }
        const response = await axios.post(BACKEND_URL + 'data_issues/' + log.error.toLowerCase() + '/' + log._id, post_body, 
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        );
        console.log(response);
        if(response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while resolving rg/gs_name_mismatch error: ' +errorResData);
        } else {
            console.log("RESOLVE:  "+ toMap);
            dispatch(resolveLogAction(log));
        }
    }
}

export const resolveRgGsNameMismatchAfterMerge = (token, log, author_id, removedPapers, removedNameVariations) => {
    return async dispatch => {
        let removedPapersId = [];
        removedPapers.forEach(paper => removedPapersId.push(paper._id));
        // const response = await fetch(BACKEND_URL + `get_logs?`, {
        //     method:'GET',
        //     headers:{
        //         Authorization: 'Bearer '+ token,
        //     }
        // });
        // if(!response.ok) {
        //     const errorResData = await response.json();
        //     console.log(errorResData)
        //     throw new Error('Something went wrong while fetching logs with error: ' +errorResData);
        // }
        dispatch(resolveLogAction(log));
    }
}
