import React from 'react';
import { InputAdornment, FormControl, InputLabel, IconButton, FilledInput } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import './InputComponents.css';
const TextInputControlled = props => {
    const {
        onChange,
        onBlur,
        label,
        error,
        value,
        disabled
    } = props;
    return (
        <FormControl variant="filled" style={{width:"100%"}}>
            <InputLabel className="blackColor verticalCenter">{label}</InputLabel>
            <FilledInput className='textInput' type='text' onChange={onChange} onBlur={onBlur}
                error={error} fullWidth disableUnderline style={{height:40}} value={value}
                disabled={disabled}
                endAdornment={
                    <InputAdornment position="end" disablePointerEvents>
                        <IconButton edge="end">
                            <Search className="blackColor" />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export default TextInputControlled;