// This is different from MultipleSelect.js. 
// An additional option of "Select All" has been added in this without. 
// Select All doesn't have to be part of options you pass in this tool.
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
import './InputComponents.css';
import makeStyles from "@material-ui/core/styles/makeStyles";

const MultiSelect = ({
    items,
    selectedValues,
    label,
    placeholder,
    selectAllLabel,
    noOptionsText,
    limitTags,
    onToggleOption,
    onClearOptions,
    onSelectAll,
    getOptionLabel,
    disablePortal
}) => {
    const allSelected = items.length === selectedValues.length;
    const handleToggleSelectAll = () => {
        onSelectAll && onSelectAll(!allSelected);
    };
    

    const useStyles = makeStyles({
        checkbox: {
          "&$checked": {
            color: "#BBB219"
          }
        },
        tag: {
            "& .MuiChip-label": {
              color: "black",
              fontSize:"1rem"
            },
            "& .MuiAutocomplete-tag":{
                background:"#f2f2f2"
            }
        }
      });
    
    const classes = useStyles();

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find(option => option.value === "select-all")) {
                handleToggleSelectAll();
            } else {
                onToggleOption && onToggleOption(selectedOptions);
            }
        } else if (reason === "clear") {
            onClearOptions && onClearOptions();
        }
    };
    const optionRenderer = (option, { selected }) => {
        const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};
        return (
            <>
                <Checkbox
                    className={classes.checkbox}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };
    const inputRenderer = params => (
        <TextField {...params} label={label} placeholder={placeholder} variant="filled" />
    );
    const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value

    const filter = createFilterOptions();
    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            className={classes.tag}
            limitTags={limitTags}
            options={items}
            value={selectedValues}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            noOptionsText={noOptionsText}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
            }}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
            disablePortal={disablePortal !== undefined ? disablePortal : true}
        />
    );
};

MultiSelect.defaultProps = {
    limitTags: 1,
    items: [],
    selectedValues: [],
    getOptionLabel: value => value
};

export default MultiSelect;
