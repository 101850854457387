import React from "react"
import * as profileActions from '../../../../store/actions/profile';
import { useDispatch } from 'react-redux';
import DepartmentFacts from "../DashboardHome/Components/DepartmentFacts"
import { Grid } from '@material-ui/core';
import InterestingFacts from "./Components/InterestingFacts";
import UniversityGenderDistribution from "./Components/UniversityGenderDistribution";
import FacultyByMarketShare from "./Components/FacultyByMarketShare";
import TenureReadiness from "./Components/TenureReadiness";
import Outliers from "./Components/Outliers";
import PerceptualMapCard from './Components/PerceptualMapCard';
import CitationsTreeMap from "./Components/CitationsTreeMap";
import * as dashboardActions from '../../../../store/actions/dashboardUniv';

const DashboardHomeNew = props => {
    const dispatch = useDispatch();

    const getDepartments = async () => {
        const token = localStorage.getItem('rl_token');
        const userId = localStorage.getItem('rl_userId');
        try {
            await dispatch(dashboardActions.departments(token, userId));
        }
        catch (err) {
            console.log("cannot get departments of this university. Error: ", err);
        }
    }


    React.useEffect(() => {
        const token = localStorage.getItem('rl_token');
        const userType = localStorage.getItem('rl_type');
        if (token && token !== null) {
            getProfile(token, userType);
        }
        getDepartments();
    },[]);

    const getProfile = async (token1, userType) => {
        if (userType === "faculty")
            await dispatch(profileActions.get_author_profile(token1, ""));
    }
    return (
        <div>
            <div style={{padding:"4px"}}>
            <span className="heading" style={{ padding: "16px" }}>
                 Dashboard
            </span>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <InterestingFacts type = "school"/>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TenureReadiness />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DepartmentFacts />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <Outliers/>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <CitationsTreeMap card={"Most Cited Faculty"}/>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <FacultyByMarketShare />
                </Grid>
            
                <Grid item xs={12} sm={6} md={4}>
                    <UniversityGenderDistribution />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <CitationsTreeMap card={"Most Cited Papers"}/>
                </Grid>

                 {
                  localStorage.getItem('rl_type')==="university" ?   
                <Grid item xs={12} sm={6} md={8}>
                    <PerceptualMapCard />
                </Grid>
                : null
                 }
            </Grid>
        </div>
    )
}

export default DashboardHomeNew;