import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';


const initialState = {
    authors: [],
    authorsInCompleteUniv: []
}


const authorRankReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AUTHOR_RANKING:
            let filteredAuthors = action.payload.filter(author => author["status"] === "Completed")
            return updateObject(state, { authors: action.payload, authorsInCompleteUniv: filteredAuthors });
        case actionTypes.CLEAR_FACULTY_RANKING:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}


export default authorRankReducer;