import React from 'react';
import {
    Button, Paper, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Typography, List, ListItemText,
    ListItem, ListItemAvatar, Avatar
} from '@material-ui/core';
import TextInput from '../../../InputComponents/TextInput';
import './Explore.css';
import * as exploreActions from '../../../../store/actions/explore';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../assets/Images/loading.gif';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
const defaultFaculty = require('../../../../assets/Images/default_faculty.png');

const useStyles = makeStyles((theme) => ({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    }
}));
const Explore = props => {
    const history = useHistory();
    const [searchBy, setSearchBy] = React.useState('Faculty');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [paper, setPaper] = React.useState('');
    const [showSpinner, setShowSpinner] = React.useState(false);
    const authorSearchResult = useSelector(state => state.explore.authorSearchResult);
    const paperSearchResult = useSelector(state => state.explore.paperSearchResult);

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }
    const handlePaperChange = (event) => {
        setPaper(event.target.value);
    }

    const search = async () => {
        setShowSpinner(true);
        const token = localStorage.getItem("rl_token");
        try {
            if (searchBy === 'Faculty') {
                await dispatch(exploreActions.authorSearch(token, firstName.trim(), lastName.trim()));
            }
            else {
                await dispatch(exploreActions.paperSearch(token, paper));
            }
            setShowSpinner(false);
        }
        catch (error) {
            setShowSpinner(false);
            alert("Internal Server Error. Please try again after some time")
        }
    }
    const showCoAuthors = (id) => {
        localStorage.setItem('coauthorid', id);
        var curid = localStorage.getItem('rl_userId')
        if (id === curid) {

        } else {
            history.push({
                pathname: '/dashboards/profileEdit',
                state: { coauthorid: id, isReadOnly: true }
            })
        }
    }
    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <div className={classes.featureHeading}>
                Explore
            </div>
            <Paper square style={{ padding: "16px 22px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <FormControl component="fieldset" style={{ height: 20, paddingBottom: 35 }}>
                            <FormControlLabel value="Search by:" labelPlacement='start' control={
                                <RadioGroup style={{ paddingTop: 10 }} row name="position" defaultValue="top" value={searchBy} onChange={handleSearchByChange}>
                                    <FormControlLabel style={{ paddingLeft: 15 }} value="Faculty" control={<Radio color="primary" />} label="Faculty" />
                                    <FormControlLabel style={{ paddingLeft: 15 }} value="Paper" control={<Radio color="primary" />} label="Paper" />
                                </RadioGroup>} label="Search by:"></FormControlLabel>
                        </FormControl>
                    </Grid>
                    {searchBy === 'Faculty' &&
                        <Grid item xs={5} className="extraPadding">
                            <TextInput error={null} onChange={handleFirstNameChange} value={firstName} onBlur={null} label="Faculty First Name"></TextInput>
                        </Grid>
                    }
                    {searchBy === 'Faculty' &&
                        <Grid item xs={5} className="extraPadding">
                            <TextInput error={null} onChange={handleLastNameChange} value={lastName} onBlur={null} label="Faculty Last Name"></TextInput>
                        </Grid>
                    }
                    {searchBy !== 'Faculty' &&
                        <Grid item xs={10} className="extraPadding">
                            <TextInput error={null} onChange={handlePaperChange} onBlur={null} label="Paper Title"></TextInput>
                        </Grid>
                    }
                    <Grid item xs={2} className="extraPadding">
                        <Button variant="contained" className="searchButton" color="primary" onClick={search}>Search</Button>
                    </Grid>
                </Grid>
            </Paper>
            {showSpinner &&
                <div style={{ textAlign: "center" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
            }
            {/* { (paperSearchResult.length  > 0 || authorSearchResult.length > 0) && */}
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    {searchBy !== 'Faculty' && paperSearchResult.length > 0 &&
                        <div><Typography variant="h6" style={{ padding: "20px 0px" }}>
                            {paperSearchResult.length} results found
                        </Typography>
                            <div style={{ backgroundColor: "white" }}>
                                <List>
                                    {paperSearchResult.map((paper, i) =>
                                        <ListItem divider>
                                            <ListItemAvatar style={{ minWidth: 30 }}>
                                                {i + 1 + "."}
                                            </ListItemAvatar>
                                            <ListItemText primary={paper} />
                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        </div>
                    }
                    {searchBy === 'Faculty' && authorSearchResult.length > 0 &&
                        <div><Typography variant="h6" style={{ padding: "20px 0px" }}>
                            {authorSearchResult.length} results found
                        </Typography>
                            <div style={{ backgroundColor: "white" }}>
                                <List>
                                    {authorSearchResult.map((faculty, i) =>
                                        <ListItem divider style={{ cursor: "pointer" }} onClick={() => showCoAuthors(faculty.id)}>
                                            <ListItemAvatar style={{ minWidth: 30 }}>
                                                {i + 1 + "."}
                                            </ListItemAvatar>
                                            <ListItemAvatar style={{ paddingRight: 30 }}>
                                                {faculty.profile_picture != null ?
                                                    (<Avatar alt={faculty.first_name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                    :
                                                    (<Avatar style={{ height: 75, width: 75 }}>
                                                        <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                    </Avatar>)
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={faculty.first_name + " " + faculty.last_name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            {faculty.univ_name === null ? "" : faculty.univ_name}
                                                        </Typography>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        </div>
                    }
                </Grid>
            </Grid>
            {/* } */}
        </div>
    );
}

export default Explore;