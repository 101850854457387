import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    facultyList: [],
    departmentList: [],
    categories: [],
    paperSeries: [],
    citaionSeries: [],
    facultyDetails: {},
    inputData: {}
}

const facultyProductivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FACULTY_PRODUCTIVITY_INPUTS:
            return updateObject(state, { inputData: action.inputData })
        case actionTypes.GET_FACULTY_PRODUCTIVITY_BY_ALL_FACULTY:
            return updateObject(state, { facultyList: action.facultyList });
        case actionTypes.GET_FACULTY_PRODUCTIVITY_BY_DEPT:
            return updateObject(state, { departmentList: action.deptList });
        case actionTypes.GET_FACULTY_CARD_INFO:
            let paperSeries1 = [];
            let citationSeries1 = [];
            let categories1 = [];
            let facultyDetails = {
                avgCitationsPerPaper: action.facultyDetails.avg_citations_per_paper,
                avgPapersPerYear: action.facultyDetails.avg_papers_per_year,
                hIndex: action.facultyDetails.h_index,
                totalCitations: action.facultyDetails.total_citations,
                totalPapers: action.facultyDetails.total_papers
            }
            let citationsPerYear = action.facultyDetails.citations_per_year;
            let papersPerYear = action.facultyDetails.papers_per_year;
            for (let papers in papersPerYear) {
                let year = parseInt(papers)
                if (!categories1.includes(year)) {
                    categories1.push(year);
                }
            }
            for (let citaions in citationsPerYear) {
                let year = parseInt(citaions)
                if (!categories1.includes(year)) {
                    categories1.push(year);
                }
            }
            categories1.sort();
            for (let year of categories1) {
                paperSeries1.push(year in papersPerYear ? papersPerYear[year] : 0);
                citationSeries1.push(year in citationsPerYear ? citationsPerYear[year] : 0);
            }
            return updateObject(state, { facultyDetails: facultyDetails, categories: categories1, paperSeries: paperSeries1, citaionSeries: citationSeries1 });
        case actionTypes.CLEAR_FACULTY_PRODUCTIVITY:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default facultyProductivityReducer;