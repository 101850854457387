export const MenuItems = [
    {
        title: 'Schools',
        path:'/#homePageImage6',
        cName: 'dropdown-link',
        image:'school_256.png'
    },
    {
        title: 'Faculty',
        path:'/#homePageImage7',
        cName: 'dropdown-link',
        image:'faculty_256.png'
    }
    // ,
    // {
    //     title: 'Special Reports',
    //     path:'/NewLandingsPage/SpecialReports',
    //     cName: 'dropdown-link',
    //     image:'specialreports_256.png'
    // }
]