import React, { useEffect, useState } from "react";
import classes2 from "./redflags.module.css";
import Theme from '../../../../constants/Theme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

import { Button, Container, Row, Col } from "react-bootstrap";
import SingleSelectAutoComplete from "../../../InputComponents/SingleSelectAutoComplete";
import { BACKEND_URL } from "../../../../utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../../../../assets/Images/loading.gif";
import MultiSelect from "../../../InputComponents/MultiSelect";
import {
    Grid, Slider, Input, FormLabel
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import * as redflagActions from '../../../../store/actions/redflag';



function FacultyWithInconsistentPaperNumber() {
    const minPaper = 0;
    const maxPaper = 60;
    const maxPaperAssoc = 150;
    const maxPaperProf = 400;
    const theme = Theme();

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [searched, setSearched] = useState(false);
    const [assistPaperRange, setAssistPaperRange] = React.useState([minPaper, minPaper + 5]);
    const [associatePaperRange, setAssociatePaperRange] = React.useState([minPaper + 7, minPaper + 40]);
    const [profPaperRange, setProfPaperRange] = React.useState([minPaper + 20, maxPaper]);
    const history = useHistory();
    const dispatch = useDispatch();

    const authors = useSelector(state => state.redflags.authorSearchResult);

    const [selectedUniversities, setSelectedUniversities] = useState([]); // Final List of Universities
    const [universityOptions, setUniversityOptions] = React.useState([]);
    const [universityDropdownSelected, setuniversityDropdownSelected] =
        React.useState([]); // Maintains the structure to work with MultiSelect Autocomplete Component




    const getUniversityOptions = async (token) => {
        let url = BACKEND_URL + "get_universities/completed";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData);
            alert("Unable to fetch Universities Data!!!");
        } else {
            const response_data = await response.json();
            console.log('response data', response_data);
            let createUniversityOptions = [];
            for (let i in response_data) {
                createUniversityOptions.push({
                    univ_id: response_data[i]['_id'],
                    label: response_data[i].name,
                    value: response_data[i].name,
                });
            }
            setUniversityOptions(createUniversityOptions);
        }
    };

    const getResponse = async () => {
        setSearched(true);
        var unis = selectedUniversities;
        if (selectedUniversities.length == 0) {
            var allOptions = universityOptions;
            allOptions = allOptions.map((e) => e.univ_id).join(",");
            unis = allOptions;
            setSelectedUniversities([...selectedUniversities, ...allOptions]);
        }

        const url =
            BACKEND_URL +
            "red_flag/faculty_with_inconsistent_paper_number?" +
            "university_id=" +
            unis + '&paper_ranges=' + String(assistPaperRange[0]) + ',' + String(assistPaperRange[1]) + ',' +
            + String(associatePaperRange[0]) + ',' + String(associatePaperRange[1]) + ',' + + String(profPaperRange[0]) + ',' + String(profPaperRange[1]);
        const token = localStorage.getItem("rl_token_a");
        setShowSpinner(true);

        try {
            await dispatch(redflagActions.authorSearch(token, url));
        }
        catch (error) {
            alert("Internal Server Error. Please try again after some time")
        }
        setShowSpinner(false);

    };

    const handleClick = () => {
        getResponse();
    };

    const handleUniversityToggleOption = (universityDropdownSelected) => {
        setuniversityDropdownSelected(
            universityDropdownSelected.sort(function (a, b) {
                if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
                if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
                return 0;
            })
        );
        dropdownSelectedUniversityToArray(universityDropdownSelected);
    };

    const handleUniversityClearOptions = () => {
        setuniversityDropdownSelected([]);
        dropdownSelectedUniversityToArray([]);
    };

    const handleUniversitySelectAll = (isSelected) => {
        if (isSelected) {
            setuniversityDropdownSelected(
                universityOptions.sort(function (a, b) {
                    if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
                    if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
                    return 0;
                })
            );
            dropdownSelectedUniversityToArray(universityOptions);
        } else {
            handleUniversityClearOptions();
        }
    };

    const dropdownSelectedUniversityToArray = (updateUniversity) => {
        let selectedUniversityArray = [];
        for (let i in updateUniversity) {
            selectedUniversityArray.push(updateUniversity[i].univ_id);
        }
        setSelectedUniversities(selectedUniversityArray);
    };

    const handleValChange = (event) => {
        if (event.target.id === 'from-assistant') {
            let newPaperRange = [Number(event.target.value), assistPaperRange[1]];
            setAssistPaperRange(newPaperRange);
        }

        if (event.target.id === 'to-assistant') {
            let newPaperRange = [assistPaperRange[0], Number(event.target.value)];
            setAssistPaperRange(newPaperRange);
        }


        if (event.target.id === 'from-associate') {
            let newPaperRange = [Number(event.target.value), associatePaperRange[1]];
            setAssociatePaperRange(newPaperRange);
        }

        if (event.target.id === 'to-associate') {
            let newPaperRange = [associatePaperRange[0], Number(event.target.value)];
            setAssociatePaperRange(newPaperRange);
        }
        if (event.target.id === 'from-professor') {
            let newPaperRange = [Number(event.target.value), profPaperRange[1]];
            setProfPaperRange(newPaperRange);
        }

        if (event.target.id === 'to-professor') {
            let newPaperRange = [profPaperRange[0], Number(event.target.value)];
            setProfPaperRange(newPaperRange);
        }

    }

    const handlePaperSliderChangeAsist = (event, newValue) => {
        setAssistPaperRange(newValue)


    };

    const handlePaperSliderChangeAssociate = (event, newValue) => {
        setAssociatePaperRange(newValue)
    }

    const handlePaperSliderChangeProf = (event, newValue) => {
        setProfPaperRange(newValue)
    }



    const editProfile = (id) => {
        localStorage.setItem('coauthorid', id);

        history.push({
            pathname: '/dashboard/profileEdit',
            state: { coauthorid: id }
        })

    }

    const handleBlur = () => {
        return;
    }

    useEffect(() => {
        const token = localStorage.getItem("rl_token");

        getUniversityOptions(token);
    }, []);
    return (
        <div>
            <div className={classes2.formCard}>
                <Container>
                    <Row md={12} className={classes2.row}>
                        <Col md={2}></Col>
                        <Col md={4}>
                            <MultiSelect
                                items={universityOptions}
                                getOptionLabel={(option) => `${option.label}`}
                                getOptionDisabled={(option) =>
                                    (option.value === universityOptions[0]) !== undefined
                                        ? universityOptions[0].value
                                        : ""
                                }
                                selectedValues={universityDropdownSelected}
                                label="University"
                                placeholder="Select University"
                                selectAllLabel="Select all"
                                onToggleOption={handleUniversityToggleOption}
                                onClearOptions={handleUniversityClearOptions}
                                onSelectAll={handleUniversitySelectAll}
                                limitTags={10}
                                disablePortal={false}
                            />
                        </Col>

                        <Col md={4}>
                            <Button
                                variant="contained"
                                className="searchButton"
                                color="primary"
                                onClick={(e) => handleClick()}
                            >
                                Click to Search
                            </Button>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row md={8} className={classes2.row}>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <Grid item xs={5}>
                                <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend"> Assistant Professor Paper Range:</FormLabel>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={assistPaperRange[0]} id="from-assistant" margin="dense" onChange={handleValChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minPaper },
                                                max: { maxPaper },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }}
                                            step={1}
                                            value={assistPaperRange}
                                            valueLabelDisplay="auto"
                                            id='assist-slider'
                                            onChange={handlePaperSliderChangeAsist}
                                            min={minPaper}
                                            max={maxPaper} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={assistPaperRange[1]} id="to-assistant" margin="dense" onChange={handleValChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minPaper },
                                                max: { maxPaper },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Col>
                    </Row>

                    <Row md={8} className={classes2.row}>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <Grid item xs={5}>
                                <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Associate Professor Paper Range:</FormLabel>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={associatePaperRange[0]} id="from-associate" margin="dense" onChange={handleValChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minPaper },
                                                max: { maxPaperAssoc },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }}
                                            value={associatePaperRange}
                                            valueLabelDisplay="auto"
                                            id='associate-slider'
                                            onChange={handlePaperSliderChangeAssociate}
                                            min={minPaper}
                                            max={maxPaperAssoc} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={associatePaperRange[1]} id="to-associate" margin="dense" onChange={handleValChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minPaper },
                                                max: { maxPaperAssoc },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Col>
                    </Row>

                    <Row md={8} className={classes2.row}>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <Grid item xs={5}>
                                <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Professor Paper Range:</FormLabel>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={profPaperRange[0]} id="from-professor" margin="dense" onChange={handleValChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minPaper },
                                                max: { maxPaperProf },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }} value={profPaperRange}
                                            valueLabelDisplay="auto"
                                            id='prof-slider'
                                            onChange={handlePaperSliderChangeProf}
                                            min={minPaper}
                                            max={maxPaperProf} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={profPaperRange[1]} id="to-professor" margin="dense" onChange={handleValChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minPaper },
                                                max: { maxPaperProf },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Col>
                    </Row>




                    {
                        searched === true && showSpinner === true &&
                        <div style={{ textAlign: "center" }}>
                            <img src={Loading} alt="Loading..." width="80px" height="80px" />
                        </div>
                    }
                    {
                        ((searched === true && showSpinner === false) || authors !== 'Initial State') &&
                        // (authors !== 'Initial State') &&
                        <Row md={12} className={classes2.row}>
                            <Col md={2}></Col>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Author Name </TableCell>
                                            <TableCell align="right">Position</TableCell>
                                            <TableCell align="right">University</TableCell>
                                            <TableCell align="right">Paper Count (A - A- B)</TableCell>
                                            <TableCell align="right">University Profile URL</TableCell>
                                            <TableCell align="right">Auth ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {authors.map((author) => (
                                            <TableRow
                                                key={author["_id"]["$oid"]}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row"
                                                    onClick={() => editProfile(author["_id"]["$oid"])}>
                                                    <div className={classes2.pointer}>{author.name}</div>
                                                </TableCell>
                                                <TableCell align="right">{author.position}</TableCell>
                                                <TableCell align="right">{author.univ_name}</TableCell>
                                                <TableCell align="right">
                                                    {author['A'] + ' | ' + author['A-'] + ' | ' + author['B']}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <a href={author["univ_profile_link"]}>Link</a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {author["_id"]["$oid"]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Col md={2}></Col>
                        </Row>
                    }
                </Container>

            </div>
        </div >
    )
}

export default FacultyWithInconsistentPaperNumber;