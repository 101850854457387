import React from 'react';
import {
  Card,
  CardContent,
  CardHeader
} from '@material-ui/core';
import './InputComponents.css';
const InfoCard = props => {
  const {
    title,
    subheader,
    content
  } = props;
  return (
    <Card style={{padding:10, height:"100%"}} square>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        {content}
      </CardContent>
    </Card>
  );
};

export default InfoCard;