import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    token: null,
    error: null,
    loading: false,
    is_univ: false,
    success: null,
    forgotPasswordSuccess: null,
    changed_login: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return updateObject(initialState, { error: null, message: null, loading: true });
        case actionTypes.AUTH_SUCCESS:
            return updateObject(initialState, { token: action.token, message: null, error: null, loading: false, user_type: action.user_type });
        case actionTypes.AUTH_FAIL:
            try {
                return updateObject(initialState, { error: action.error, loading: false, message: action.error.response.data.message });
            }
            catch(err){
                console.log(err);
            }
        case actionTypes.AUTH_LOGOUT:
            return updateObject(initialState, { token: null })
        case actionTypes.SIGN_UP:
            return updateObject(initialState, { success: action.result })
        case actionTypes.FORGOT_PASSWORD:
            return updateObject(initialState, { forgotPasswordSuccess: action.result })
        case actionTypes.ADMIN_CHANGE_LOGIN:
            return updateObject(initialState, { token: action.token, message: null, error: null, loading: false, user_type: action.user_type, changed_login: action.changedLogin})
        default:
            return state
    }
};

export default authReducer;