import React,{useEffect} from 'react';
import {Container} from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import SideNav from '../SideNav/SideNav';

const Layout = props => {
    useEffect(() => {
        // Update the document title using the browser API
      },[props.location,props.history]);
    return(
            <Container fluid style={{paddingLeft:0, paddingRight:0, display:'flex', flexDirection:'column', minHeight:'100vh' }}>
                {/* <Header token={token}/> */}
                <SideNav/>
                {/* TODO: // remove this paading top! main should directly come under header*/}
                <main style={{flexGrow:1, backgroundColor: '#f4f6f8'}}> {props.children}</main>
                <Footer/>
            </Container>
       
    );
}

export default Layout;
