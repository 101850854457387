/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_URL, convertArrayToSummary } from '../../../utils';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Theme from '../../../constants/Theme';
import classes2 from './RSSFeed.module.css';
import { Paper, TextField, FormLabel, Typography, Divider, Link } from '@material-ui/core';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import SingleSelect from '../../../components/InputComponents/SingleSelect';
import classes from './RSSFeed.module.css';
import * as rssFeedActions from '../../../store/actions/rssFeed';
import Loading from '../../../assets/Images/loading.gif';

const DarkerDisabledTextField = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 1)" // (default alpha is 0.38)
      }
    }
  })(TextField);

const token = localStorage.getItem("rl_token");
const RSSFeed = props =>{
    const theme = Theme();
    const [rssFeedLink, setRSSFeedLink] = useState("");
    const [journalLink, setJournalLink] = useState("");
    const [publisherError, setPublisherError] = React.useState(false);
    const [journalError, setJournalError] = React.useState(false);
    const [publisherName, setPublisherName] = useState("");
    const [journalName, setJournalName] = useState("");
    const [publisherId, setPublisherId] = useState("");
    const [journalId, setJournalId] = useState("");
    const [publisherOptions, setPublisherOptions] = useState([]);
    const [journalOptions, setJournalOptions] = useState([]);
    const [publisherMapping, setPublisherMapping] = useState({});
    const [journalMapping, setJournalMapping] = useState({});
    const [isValidForm,setIsValidForm] = useState(false);
    const [isValidLink,setIsValidLink] = useState(false);
    const [tagNames, setTagNames] = React.useState([]);
    const [selectedTags, setSelectedTags] = React.useState([{}]);
    const [showTagError, setShowTagError] = React.useState(false);
    const [databaseFields, setDatabaseFields] = useState([]);
    const [mandatoryDatabaseFields, setMandatoryDatabaseFields] = useState([]);
    const [parsedData, setParsedData] = React.useState([]);
    const [mappedData, setMappedData] = React.useState([]);
    const [config, setConfig] = React.useState([{}]);
    const [showParsedData, setShowParsedData] = React.useState(false);
    const [showResult, setShowResult] = React.useState(false);
    const showTagErrorRef = React.useRef(showTagError);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuPropsStyles = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
        table: { borderTopWidth: 1, borderColor: "red", borderStyle: "solid" }
    };

    const handlePublisherChange = (event, newInputValue) => {
        setJournalName("");
        setShowParsedData(false);
        setPublisherName(newInputValue ? newInputValue : '');
        if (newInputValue && newInputValue !== '' && Object.keys(publisherMapping).includes(newInputValue)) {
            setPublisherError(false);
            setPublisherId(publisherMapping[newInputValue]);
            getJournalOptions(publisherMapping[newInputValue]);   
        }   
    };

    const handleJournalChange = (event, newInputValue) => {
        setJournalName(newInputValue ? newInputValue : '');
        setShowParsedData(false);
        if (newInputValue && newInputValue !== '' && Object.keys(journalMapping).includes(newInputValue)) {
            setJournalError(false);
            setJournalId(journalMapping[newInputValue][0]);
            setRSSFeedLink(journalMapping[newInputValue][1]); 
            setJournalLink(journalMapping[newInputValue][2]);
        }
    };
    
    const handleTagChange = (event, newInputValue, name) => {
        
        const newTags = [...selectedTags]
        newTags[0][name] = newInputValue
        setSelectedTags(newTags);
    };   
    
    const submitData = async () => {

        setShowSpinner(true);

        let response = await rssFeedActions.getData(rssFeedLink);
        if (response.error === undefined) {
            setTagNames(response['rss_fields']);
            setDatabaseFields(response['database_fields']);
            setMandatoryDatabaseFields(response['mandatory_database_fields']);
            setParsedData([response['parsed_data']]);
            setShowParsedData(true);
            setMappedData([]);
            setShowResult(false); 
            setSelectedTags([{}]);
        }
        setShowSpinner(false);
    };
    const checkTagsData = async () => {

        if (Object.keys(selectedTags[0]).length !== 0) {

            let response = await rssFeedActions.checkData(token, rssFeedLink, selectedTags[0]);
            setMappedData([response['parsed_data']]);
            setShowResult(true);
        }
    };

    const uploadData = async () => {

        if (Object.keys(selectedTags[0]).length !== 0){
            let response = await rssFeedActions.submitData(token, rssFeedLink, selectedTags[0], journalId);
            if (response.status === 200) {
                alert("Submitted Succesfully")
                window.location.reload();
            }
         }
    };

    const getFields = () => {
        let fields = [];

        mandatoryDatabaseFields.map((value, index) => {
            fields.push(
                <Row className = {classes2.row} md={12}>
                    <Col md={4} style={{textAlign: "right"}}>
                        <Typography variant="h6">{value}</Typography>
                    </Col>
                    <Col md={6}>
                        <SingleSelect required error={showTagError} onInputChange={(event, newInputValue) => handleTagChange(event, newInputValue, value)} options={tagNames}
                            inputValue={!(value in selectedTags[0]) ? '' : selectedTags[0][value]}  defaultValue = {!(value in config[0]) ? '' : config[0][value]}
                            label= {"Select " + value + " Tag"} freeSolo={true}/>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            )
        });

        databaseFields.map((value,index) => {
            fields.push(
                <Row className = {classes2.row} md={12}>
                    <Col md={4} style={{textAlign: "right"}}>
                        <Typography variant="h6">{value}</Typography>
                    </Col>
                    <Col md={6}>
                        <SingleSelect error={showTagError} onInputChange={(event, newInputValue) => handleTagChange(event, newInputValue, value)} options={tagNames}
                            inputValue={!(value in selectedTags[0]) ? '' : selectedTags[0][value]} defaultValue = {!(value in config[0]) ? '' : config[0][value]} 
                            label= {"Select " + value + " Tag"} freeSolo={true}/>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            )
        });
        return fields;
    };
    
    const getPublisherOptions = async() => {
        let response = await rssFeedActions.getPublishers();
        let publisherMap = {}
        response.map((data,i) =>{
            publisherMap[data["publisher_name"]] = data["id"];
        });

        setPublisherMapping(publisherMap);
    };

    const getJournalOptions = async (id) => {
        let response = await rssFeedActions.getJournals(id);

        let data = {};
        for (const key in response.config){
            data[key] = response.config[key];
        }
        setConfig([data]);

        let journalMap = {};
        response.data.map((data,i) =>{
            journalMap[data["title"]] = [data["id"], data['rss_feed_link'], data['journal_link']];
        });
        setJournalMapping(journalMap);
    }

    useEffect (() => {
        getPublisherOptions();
    }, []);

    return (
        <ThemeProvider>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={classes2.profile}>
                    RSS Feed Tool
                </div>
                <Paper style={{ align: "center", overflow: "auto"}}>
                    <Container style={{width: "100%"}}>
                        <Row className = {classes2.row} md={12}>
                            <Col md = {2} style={{textAlign: "right"}}>
                                <Typography variant="h6" style={{paddingTop: 5}}>Publisher Name</Typography>
                            </Col>
                            <Col md = {3}>
                                <SingleSelect 
                                error={publisherError} 
                                options={Object.keys(publisherMapping).sort((a,b) => a.localeCompare(b))} 
                                onInputChange={handlePublisherChange}
                                inputValue={publisherName} 
                                label="Publisher Name" 
                                helperText="Select a Publisher Name" 
                                />
                            </Col>
                            <Col md = {2} style={{textAlign: "right"}}>
                                <Typography variant="h6" style={{paddingTop: 5}}>Journal Name</Typography>
                            </Col>
                            <Col md = {3}>
                                <SingleSelect 
                                error={journalError} 
                                options={Object.keys(journalMapping).sort((a,b) => a.localeCompare(b))} 
                                onInputChange={handleJournalChange}
                                inputValue={journalName}
                                disabled={publisherName === ""}
                                label="Journal Name" 
                                helperText="Select a Journal Name" 
                                />
                            </Col>
                            <Col md = {2}>
                                <Button className={classes2.search_button} variant="contained"
                                onClick={submitData}>
                                    Submit
                                </Button> 
                            </Col>
                        </Row>
                    </Container>
                </Paper>
                <div style= {{padding: 10}}></div>
                {   
                    showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                { !showSpinner && showParsedData && 
                    <Paper style={{ align: "center", overflow: "auto"}}>
                        <Container style={{ maxHeight: "40em", align: "center", overflow: "auto", maxWidth: "100%"}}>
                            <Row>
                                <Col md={3}></Col>
                                <Col md={3}>
                                    <Link href={journalLink === undefined ? null : journalLink } target="_blank">
                                        <Button className={classes2.search_button} variant="contained">
                                            Open Journal Website
                                        </Button>
                                    </Link>
                                </Col>
                                <Col md={3}>
                                    <Link href={rssFeedLink} target="_blank">
                                        <Button className={classes2.search_button} variant="contained">
                                            Open RSS Feed
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Typography variant="h5" align= "center">Parsed Data</Typography>
                                    <pre style={{maxHeight: 350}}>{JSON.stringify(parsedData[0], null, 2)}</pre>
                                </Col>
                                <Col md={4}>
                                    <Typography variant="h5" align= "center">Mapped Data</Typography>
                                    {
                                        showResult ?
                                        <pre style={{maxHeight: 350}}>{JSON.stringify(mappedData[0], null, 2)}</pre> :
                                        <pre style={{height: 350, textAlign: "center"}}>No data to display</pre>
                                    }
                                </Col>
                            </Row>
                        </Container>
                        <Container style={{maxHeight: "60em", align: "center", overflow: "auto", maxWidth: "100%"}}>  
                            {
                                getFields()
                            }
                        </Container>
                        <Container style={{ maxHeight: "5em", align: "center", overflow: "auto"}} >
                            <Row className = {classes2.row} md={12} style={{alignItems: "center"}} >
                                <Col md={2}></Col>
                                <Col md={4}>
                                    <Button className={classes2.search_button} variant="contained" 
                                    onClick={checkTagsData}>
                                        Check
                                    </Button> 
                                </Col>
                                <Col md={4}>
                                    <Button className={classes2.search_button} variant="contained"
                                    onClick={uploadData}>
                                        Submit
                                    </Button> 
                                </Col>
                                <Col md={2}></Col>
                            </Row>
                        </Container>
                    </Paper>
                }   
            </div>
        </ThemeProvider>
    );
};

export default RSSFeed;