import { Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import ReactFusioncharts from "react-fusioncharts";
import { BACKEND_URL } from '../../../../../utils';
import Settings from './Settings';
import Loading from '../../../../../assets/Images/loading.gif';
import { useSelector } from 'react-redux';



const PerceptualMapCard = props => {
    const [department, setDepartment] = React.useState("");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const profileState = useSelector(state => state.univProfile);
    const [dataSrc, setDataSrc] = React.useState(
        {
            chart: {
                theme: "fusion",
                caption: "Average number of papers X Average Citations (A,A-) for your university",
                subcaption: '',
                captionFontBold: false,
                legendposition: "top",
                xaxisminvalue: "0",
                valueFontColor: "FFFFFF",
                valueFontBold: true,
                xaxismaxvalue: "100",
                yaxisminvalue: "0",
                yaxismaxvalue: "30000",
                yAxisNameFontBold: "0",
                numDivLines: "0",
                numVDivLines: "0",
                xAxisNameFontBold: "0",
                xAxisNameFontSize: "15",
                captionFontSize: "15",
                yAxisNameFontSize: "15",
                xaxisname: "Average Citations",
                yaxisname: "Average Papers",
                plottooltext: "$name",
                drawquadrant: "1",
                quadrantlabeltl: "Less Citations / More Publications",
                quadrantlabeltr: "More Citations / More Publications",
                quadrantlabelbl: "Less Citations / Less Publications",
                quadrantlabelbr: "More Citations / Less Publications",
                quadrantxval: "54",
                quadrantyval: "12000",
                showAlternateHGridColor: 0,
                showAlternateVGridColor: 0,
                showToolTipShadow: "1",
                showToolTip: "1",
                showValues: "1",
                bgColor: "#ffffff",
                baseFontSize: 12,
                baseFontColor: '#000000'
            },
            dataset: [
                {
                    data: [
                        {

                        }
                    ],
                    color: '#84266A',
                }
            ],
            trendlines: [
                {

                }
            ]
        }
    );
    const populateChart = (results) => {
        //x papers
        if (results === undefined || results.length === 0) {
            return;
        }
        // y citations
        var { chart, dataset, trendlines } = dataSrc;
        var max_papers = 0;
        var max_citations = 0;
        var min_papers = results[0].y;
        var min_citations = results[0].x;

        for (let i = 0; i < results.length; i++) {
            max_papers = Math.max(max_papers, results[i].y);
            max_citations = Math.max(max_citations, results[i].x);
            min_papers = Math.min(min_papers, results[i].y);
            min_citations = Math.min(min_citations, results[i].x);
        }
        chart.yaxismaxvalue = max_citations + ((30 / 100) * (max_citations));
        chart.xaxismaxvalue = max_papers + ((30 / 100) * (max_papers));
        chart.yaxisminvalue = min_citations - ((30 / 100) * (max_citations));
        chart.xaxisminvalue = min_papers - ((30 / 100) * (max_papers));
        chart.quadrantxval = chart.xaxismaxvalue / 2;
        chart.quadrantyval = chart.yaxismaxvalue / 2;

        var data = []
        for (let i = 0; i < results.length ; i++) {

            if(i!=results.length-2)
            {
                let cur_school = {}
                cur_school.x = (results[i].y).toString();
                cur_school.y = (results[i].x).toString();
                cur_school.z = "24";
                cur_school.name = (results[i].name);
                cur_school.displayValue = (results[i].displayValue);
                cur_school.value = (results[i].name);
                cur_school.color = "#E24301";
                cur_school.valueFontColor = "#000000";
                data.push(cur_school);
            }
        }
        let my_school = {}
        my_school.x = (results[results.length - 2].y).toString();
        my_school.y = (results[results.length - 2].x).toString();
        my_school.z = "24";
        my_school.name = (results[results.length - 2].name);
        my_school.value = (results[results.length - 2].name);
        my_school.displayValue = (results[results.length - 2].displayValue);
        my_school.color = "#85276B";
        my_school.valueFontColor = "#000000";
        data.push(my_school);
        dataset = [{ data: data }];
        setDataSrc({ chart: chart, dataset: dataset, trendlines: trendlines });
    }

    const getData = async (dept) => {
        setShowSpinner(true);
        try {
            const token = localStorage.getItem("rl_token");
            var schools = "range3";
            var titles = ["Assistant Professor", "Associate Professor", "Full Professor"]
            var x = "Papers per Faculty"
            var y = "Citations per Faculty"
            const params = {
                "titles": titles,
                "x": x,
                "y": y,
                "school": schools,
            }
            let url = new URL(BACKEND_URL + `perceptualMap`)
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify(params),
            });

            if (!response.ok) {
                const errorResData = await response.json();
                console.log(errorResData);
                throw new Error('Something went wrong while getting perceptual map for ' + params + ' with error: ' + errorResData);
            }
            const perceptualMapData = await response.json();
            populateChart(perceptualMapData.result);
        }
        catch (error) {
            console.log("error thrown while fetching data for Compare Schools page:" + error);
        }
        setShowSpinner(false);
    }
    useEffect(() => {
        getData(null);
    }, [])

    const handleSchoolDeptSwitchChange = async (department) => {
        if (department === null) {
            getData(null);
            setDepartment(null);
        }
        else {
            getData(department);
            setDepartment(department);
        }
    }
    return (
        <Paper style={{ height: "360px", padding: "12px" }}>
            <div style={{ fontSize: "20px" }}>
                Compare Schools{department === null || department === "" ? "" : ` - ${department}`}
                <span style={{ float: "right" }}>
                    <Settings handleSchoolDeptSwitchChange={handleSchoolDeptSwitchChange} department={department} />
                </span>
            </div>
            {
                showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <div>
                        <ReactFusioncharts type="bubble" width="100%" height="18%" dataFormat="JSON" dataSource={dataSrc} />
                    </div>
            }
        </Paper>
    )
}

export default PerceptualMapCard;