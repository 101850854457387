import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import Profile from './Components/Profile/Profile';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SidebarNav from './Components/SidebarNav/sidebarNav';
import { grey } from '@material-ui/core/colors';
import logo from '../../../assets/Images/logos/ResearchLoupe_Logo_White.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TrendingUp from '@material-ui/icons/TrendingUp';
import { useSelector } from 'react-redux';
import adminLogo from '../../..//assets/Images/logos/RL_Logo.jpg'
import { adminPages } from '../../Admin/adminPages';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: "18%",
    zIndex: 2,
    backgroundColor: '#555559',
    marginTop: 0
  },
  root: {
    backgroundColor: grey,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  divider: {
    margin: 2
  },
  nav: {
    marginBottom: 2,
  },
  profile: {
    marginTop: 0
  },
  logo: {
    width: '250px'
  },
  adminLogo: {
    width: '100%'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const userType = useSelector(state => state.auth.user_type);
  const classes = useStyles();
  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'My Profile',
      href: '/dashboard/profileedit',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Faculty Progress',
      href: '/dashboard/facultyProgress',
      icon: <TrendingUpIcon />
    },
    {
      title: 'Compare Yourself',
      href: '/dashboard/compareYourself',
      icon: <CompareArrowsIcon />
    },
    {
      title: 'Find your fit',
      href: '/dashboard/findYourFit',
      icon: <FindReplaceIcon />
    },
    {
      title: 'Explore',
      href: '/dashboard/explore',
      icon: <SearchIcon />
    },
    {
      title: 'Settings',
      href: '/dashboard/settings',
      icon: <SettingsIcon />
    },
    {
      title: 'Faculty Ranking',
      href: '/dashboard/authorrank',
      icon: <TrendingUp/>
    },
    {
      title: 'Log Out',
      href: '/login',
      icon: <ExitToAppIcon />
    }
  ];

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant} >
      <div {...rest} className={clsx(classes.root, className)} >
        <div style={{ textAlign: "center" }}>
          {userType === "admin" ?
          <img src={adminLogo} alt="Research Loupe" className={classes.adminLogo} />
          :
          <img src={logo} alt="Research Loupe" className={classes.logo} />
          }
        </div>
        <Profile className={classes.profile} />
        <Divider className={classes.divider} />
        <SidebarNav pages={pages} adminPages={localStorage.getItem("rl_token_a") !== null ? adminPages : []}/>
        <div style={{marginTop: "30px"}}></div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
