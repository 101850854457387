import React from 'react';
import classes2 from './redflags.module.css';
import { Button, Container, Row, Col } from 'react-bootstrap';
import SingleSelectAutoComplete from '../../../InputComponents/SingleSelectAutoComplete';
import { BACKEND_URL } from '../../../../utils';
import Loading from '../../../../assets/Images/loading.gif';
import ReactApexChart from 'react-apexcharts'


const TotalPapersByFacultyPerUniversity = () => {

    const [curTextUniv, setcurTextUniv] = React.useState("")
    const [univoptions, setunivOptions] = React.useState([]);
    const [curUnivID, setcurUnivID] = React.useState("");

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [searched, setSearched] = React.useState(false);
    const [chartSeries, setChartSeries] = React.useState([]);
    
    
    let apexChartOptions = {
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy',
                resetIcon: {
                    offsetX: -25,
                    offsetY: 0,
                    fillColor: '#fff',
                    strokeColor: '#37474F'
                },
                selection: {
                    background: '#90CAF9',
                    border: '#0D47A1'
                }    
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: []
                },
                export: {
                  csv: {
                    filename: undefined,
                    columnDelimiter: ',',
                    headerCategory: 'category',
                    headerValue: 'value',
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toDateString()
                    }
                  }
                },
                autoSelected: 'zoom' 
              },
            fillColor: '#e3e3e3',
        },
        colors: ['#546E7A'],
        xaxis: {
            tickAmount: 10,
            tickPlacement: 'on',
            title: {
                text: "Experience (in years)",
                style: {
                    color: undefined,
                    fontSize: '15px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-title',
                }
            }
        },
        yaxis: {
            tickAmount: 7,
            title: {
                text: "Total papers",
                style: {
                    color: undefined,
                    fontSize: '15px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-title',
                },
            }

        },
        legend:{
            show:false
        }
    };


    const handleClick = () => {
        getResponse();
    }

    const getResponse = () => {
        setSearched(true);
        setShowSpinner(true);
        const url = BACKEND_URL + 'red_flag/total_papers_by_faculty_per_university?university_id=' + curUnivID;

        (async () => {
            const response = await fetch(url, {
                method: 'GET'
            });

            const jsonData = await response.json();
            
            let series = [];
            for (let data in jsonData) {
                
                let temp = {
                    name: jsonData[data].faculty_name,
                    data: [[jsonData[data].experience, jsonData[data].total_papers]],
                }

                series.push(temp)
            }

            setChartSeries(series);
            setShowSpinner(false);
        })();
    }

    const onUnivInputChangeHandler = (event, newInputValue) => {
        
        setSearched(false);
        setcurTextUniv(newInputValue)
        if (!newInputValue || newInputValue.trim() === "") {
            return;
        }
        const url = BACKEND_URL + 'auto/univ/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const univs = await response.json();
            var p = Object.keys(univs).map((key) => univs[key]);
            setunivOptions(p[0]);
        })();
    }

    const onUnivChange = (event, value) => {
        if (value && !(Object.keys(value).length === 0 && value.constructor === Object)) {
            setcurTextUniv(value.name);
            setcurUnivID(value.univ_id);
            setChartSeries([]);
        }
    }

    const getOptionLabelUniv = (option) => {
        if (!option || !option.name) {
            return "";
        }
        return option.name
    }

    return (
        <div>
            <div className={classes2.formCard}>
                <Container >
                    <Row md={12} className={classes2.row}>
                        <Col md={2}>
                        </Col>
                        <Col md={4}>
                            <SingleSelectAutoComplete onInputChange={onUnivInputChangeHandler} options={univoptions}
                                curText={curTextUniv}
                                onChange={onUnivChange}
                                getOptionLabel={getOptionLabelUniv}
                                label={"Enter University Name"}></SingleSelectAutoComplete>
                        </Col>
                        <Col md={4}>
                            <Button variant="contained" className="searchButton" color="primary" onClick={(e) => handleClick()} >Click to Search</Button>
                        </Col>
                        <Col md={2}>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
              searched===true && showSpinner===true &&
              <div style={{ textAlign: "center" }}>
                <img src={Loading} alt="Loading..." width="80px" height="80px" />
              </div>
            }

            {
                searched === true && showSpinner === false &&
                <div className={classes2.formCard} style={{ height: "500px" }}>
                    <div id="chart">
                        <ReactApexChart options={apexChartOptions} series={chartSeries} type="scatter" height={400}/>
                    </div>
                </div>

            }
        </div>
    )
}

export default TotalPapersByFacultyPerUniversity;