import React, { useEffect } from 'react';
import {
    Button, Accordion, Grid, AccordionSummary, AccordionDetails, Paper, Table, TableCell, TableRow, TableContainer,
    TableHead, TableBody, ListItemAvatar, Avatar, ListItemText, IconButton, ListItemSecondaryAction, List, ListItem, Checkbox
} from '@material-ui/core';
import Loading from '../../../../assets/Images/loading.gif';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FacultyInfoInput from '../../../InputComponents/FacultyInfoInput';
import SingleSelect from '../../../InputComponents/SingleSelect';
import * as cohortAnalysisActions from '../../../../store/actions/cohortAnalysis';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Compareyourselfresult from '../../../Dashboard/Views/CompareYourself/compareYourselfResult';
import * as showSimilarAuthorAction from '../../../../store/actions/showSimilarAuthor';
import ShowSimilarAuthor from './ShowSimilarAuthor';

const defaultFaculty = require('../../../../assets/Images/default_faculty.png');
const milestones = ["Assistant Professor", "Associate Professor", "Professor"]; //, "Chair"];
const journalOptions = ["A Journals", "A and A- Journals", "All Journals"];
const journalOptionsDict = { "A Journals": "A", "A and A- Journals": ["A", "A-"], "All Journals": ["A", "A-", "B"] };
const useStyles = makeStyles((theme) => ({
    addButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
    removeButton: {
        color: "white !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#E24301 !important",

    },
    table: {
        border: "0px"
    },
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    mileStone: {
        width: "25%",
        float: "left",
        marginTop: "20px",
        marginBottom: "-9px"
    },
    paperStyle: {
        width: "100%",
        margin: "30px 0px 0px"
    }
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F2F2F2",
        color: "#FFFFF",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const StyledListItem = withStyles((theme) => ({
    container: {
        paddingTop: 0,
        paddingBottom: 0
    }
}))(ListItem);
const StyledButton = withStyles((theme) => ({
    root: {
        height: 42,
        borderRadius: 0,
        "&:hover": {
            backgroundColor: "#BBB21E"
        }
    },
    disabled: {
        backgroundColor: "#d4cf79 !important"
    }
}))(Button);
const CohortAnalysis = props => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [clearData, setClearData] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [currentFaculty, setCurrentFaculty] = React.useState({ faculty: {} });
    const [facultyDesc, setFacultyDesc] = React.useState("* faculties* ");
    const [journals, setJournals] = React.useState("");
    const [journalsErr, setJournalsErr] = React.useState(false);
    const [milestone, setMilestone] = React.useState("Assistant Professor");
    const cohortRows = useSelector(state => state.cohortAnalysis.cohortRows);
    const count = useSelector(state => state.cohortAnalysis.count);
    const cohortAnalysisData = useSelector(state => state.cohortAnalysis.cohortAnalysisData);
    const allData = useSelector(state => state.cohortAnalysis.cohortAnalysisAllData);
    const facultyInfoInput = React.useRef(null);
    const [showSimilarAuthorSection, setShowSimilarAuthorSection] = React.useState(false);
    const [selectedAuthors, setSelectedAuthors] = React.useState([]);
    const [showCompareSpinner, setShowCompareSpinner] = React.useState(false);
    const compareFacultyData = useSelector(state => state.cohortAnalysis.comparefacultyData);
    const [showCompareFacultyResult, setShowCompareFacultyResult] = React.useState(false);
    const [compareAccordianExpand, setCompareAccordianExpand] = React.useState(true);

    const setFacultyInfo = async (facultyInfo) => {
        let data1 = { ...currentFaculty }
        data1.faculty = facultyInfo
        if (data1.faculty.id !== currentFaculty.faculty.id) {
            setCurrentFaculty(data1);
        }
        if (data1.faculty.IsSimilarAuthor) {
            addCohort(data1)
        }

    }
    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }
    const handleJournalsChange = (event, newInputValue) => {
        setJournals(newInputValue ? newInputValue : '');
        setJournalsErr(newInputValue ? false : true);
    }

    const addCohort = async (similarFaculty = undefined) => {

        let newCount = count + 1
        setClearData(true);
        let facultyData = similarFaculty === undefined ? currentFaculty.faculty : similarFaculty.faculty;
        facultyInfoInput.current.clearFields();
        setCurrentFaculty({ faculty: {} })
        for (let i = 0; i < cohortRows.length; i++) {
            if (facultyData["id"] === cohortRows[i]["id"]) {
                setClearData(false);
                return;
            }
        }
        facultyData["index"] = newCount
        let facultyDesc1 = "";
        for (let f of cohortRows) {
            facultyDesc1 += `${f["firstName"]} ${f["lastName"]}, `
        }
        facultyDesc1 = facultyDesc1.slice(0, facultyDesc1.length - 2);
        if (cohortRows.length > 0) {
            facultyDesc1 += " and "
        }
        facultyDesc1 += `${facultyData["firstName"]} ${facultyData["lastName"]}`
        setFacultyDesc(facultyDesc1);
        await dispatch(cohortAnalysisActions.addCohortRow(facultyData, newCount));
        setClearData(false);
    }

    const removeCohort = async (idToRemove) => {
        await dispatch(cohortAnalysisActions.removeCohortRow(idToRemove));
        let facultyDesc1 = "";
        for (let f of cohortRows) {
            if (f["index"] !== idToRemove)
                facultyDesc1 += `${f["firstName"]} ${f["lastName"]}, `
        }
        facultyDesc1 = facultyDesc1.slice(0, facultyDesc1.length - 2);
        let comma = facultyDesc1.lastIndexOf(",");
        if (comma !== -1) {
            facultyDesc1 = facultyDesc1.substr(0, comma) + " and " + facultyDesc1.substr(comma + 2, facultyDesc1.length)
        }
        setFacultyDesc(facultyDesc1);
    }

    useEffect(() => {
        if (cohortRows.length > 0) {
            analyze();
        }
    }, [cohortRows])

    const analyze = async () => {
        if (checkValidity()) {
            setShowSpinner(true);
            setAccordionExpand(false);
            setShowCompareFacultyResult(false);
            setCompareAccordianExpand(true);
            setShowSimilarAuthorSection(false)
            setSelectedAuthors([]);
            try {
                let facultyIds = [];
                for (let faculty of cohortRows) {
                    facultyIds.push(faculty.id);
                }
                let journalsToSend = [];
                for (let journals1 of journalOptionsDict[journals]) {
                    journalsToSend.push(journals1);
                }
                const token = localStorage.getItem("rl_token");
                await dispatch(cohortAnalysisActions.analyze(token, facultyIds, journalsToSend));
            }
            catch (e) {
                alert("Internal server error occured. Please try again later");
                setCompareAccordianExpand(true);
            }
            setShowSpinner(false);
        }
    }
    const checkValidity = () => {
        let isValid = true;
        if (cohortRows.length < 1) {
            alert("Enter atleast one faculty for analysis");
            isValid = false;
        }
        if (journals === "") {
            setJournalsErr(true)
            isValid = false;
        }
        return isValid
    }
    const handleMilestoneChange = async (event, newInputValue) => {
        setMilestone(newInputValue ? newInputValue : '');
        await dispatch(cohortAnalysisActions.milestoneChange(newInputValue, allData))
    };

    const handleCheckbox = async (selected_faculty_id) => {
        let temp = [...selectedAuthors];
        if (selectedAuthors.indexOf(selected_faculty_id) === -1) {
            temp.push(selected_faculty_id);
        }
        else {
            temp = temp.filter((value) => value !== selected_faculty_id);
        }
        setShowCompareFacultyResult(false);
        setCompareAccordianExpand(true);
        await setSelectedAuthors(temp);

    }

    const comparefaculty = async () => {
        setShowCompareFacultyResult(true);
        setShowCompareSpinner(true);
        setCompareAccordianExpand(false);

        try {
            await dispatch(cohortAnalysisActions.compareFacultyData(selectedAuthors));
        }
        catch (err) {
            alert("Internal Server Error. Please try again later");
            setShowCompareFacultyResult(false);
            setCompareAccordianExpand(true);
        }
        setShowCompareSpinner(false);
    }

    const showSimilarAuthors = async () => {
        setShowSpinner(true)
        setShowSimilarAuthorSection(true)
        const token = localStorage.getItem("rl_token");
        try {
            await dispatch(showSimilarAuthorAction.similarAuthorSearch(token, cohortRows[0]['id']));
        } catch (error) {
            alert("Internal Server Error. Please try again after some time")
        }
        setShowSpinner(false)
    }

    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <div className={classes.featureHeading}>
                Cohort Analysis
            </div>
            <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />}>
                    <div style={{ float: "left" }}>
                        {`Considering ${journals !== "" ? journals : "* journals *"} when comparing ${facultyDesc}.`}
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: 25 }}>
                    <Grid container spacing={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={9} container direction="column" justify="center" alignItems="center">
                                <FacultyInfoInput ref={facultyInfoInput} index={0} setFacultyId={setFacultyInfo} clearData={clearData} />
                            </Grid>
                            <Grid item xs={3} container direction="column" justify="center" alignItems="center">
                                <StyledButton disabled={Object.keys(currentFaculty.faculty).length === 0} color='secondary' disableFocusRipple fullWidth variant="contained" onClick={() => { addCohort() }}>
                                    ADD TO COHORT
                                </StyledButton>
                            </Grid>
                        </Grid>
                        {cohortRows.length > 0 &&
                            <Paper square className={classes.paperStyle}>
                                <List style={{ width: "100%" }} disablePadding dense>
                                    {cohortRows.map((faculty, i) => (
                                        <StyledListItem divider>
                                            <ListItemAvatar style={{ minWidth: 30 }}>
                                                {i + 1 + "."}
                                            </ListItemAvatar>
                                            <ListItemAvatar style={{ paddingRight: 30 }}>
                                                {faculty.pic != null ?
                                                    (<Avatar alt={faculty.firstName} src={faculty.pic} style={{ height: 75, width: 75 }} />)
                                                    :
                                                    (<Avatar style={{ height: 75, width: 75 }}>
                                                        <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                    </Avatar>)
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={faculty.firstName + " " + faculty.lastName}
                                                secondary={
                                                    faculty.universityName
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete">
                                                    <Button variant="contained" className={classes.removeButton} onClick={() => { removeCohort(faculty.index) }}>
                                                        REMOVE
                                                    </Button>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </StyledListItem>
                                    ))}
                                </List>
                            </Paper>
                        }
                        <Grid container spacing={3} style={{ padding: "20px 0px 20px" }}>
                            <Grid item xs={3} />
                            <Grid item xs={3}>
                                <SingleSelect error={journalsErr} options={journalOptions} onInputChange={handleJournalsChange}
                                    inputValue={journals} label="Journals" helperText="Select journals" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" className={classes.addButton} onClick={() => { analyze() }}>
                                    ANALYZE
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <StyledButton color='secondary' fullWidth disableFocusRipple variant="contained" disabled={cohortRows.length < 1 ? true : false} onClick={() => { showSimilarAuthors() }}>
                                    SHOW SIMILAR AUTHORS
                                </StyledButton>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {!showSpinner && showSimilarAuthorSection && cohortRows.length > 0 &&
                <ShowSimilarAuthor selected_auth_info={setFacultyInfo} />
            }
            {showSpinner &&
                <div style={{ textAlign: "center" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
            }
            {!showSpinner && cohortAnalysisData.length > 0 &&
                <Paper square style={{ padding: "1px 17px 20px" }}>
                    <div className={classes.mileStone}>
                        <SingleSelect error={false} options={milestones} onInputChange={handleMilestoneChange}
                            inputValue={milestone} label="Milestone" helperText="" defaultValue={"Assistant Professor"} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item style={{ width: "100%", padding: "30px 12px" }}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell align="center">Total Papers</StyledTableCell>
                                            <StyledTableCell align="center">Total Citations</StyledTableCell>
                                            <StyledTableCell align="center">H-Index</StyledTableCell>
                                            <StyledTableCell align="center">A:B Journal ratio</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cohortAnalysisData.map((faculty) => (
                                            <StyledTableRow key={faculty.name}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Checkbox
                                                        checked={selectedAuthors.includes(faculty.id)}
                                                        disabled={selectedAuthors.length === 2 && !selectedAuthors.includes(faculty.id)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        onClick={() => handleCheckbox(faculty.id)}
                                                    /> {faculty.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{faculty.totalPapers}</StyledTableCell>
                                                <StyledTableCell align="center">{faculty.totalCitations}</StyledTableCell>
                                                <StyledTableCell align="center">{faculty.hIndex}</StyledTableCell>
                                                <StyledTableCell align="center">{faculty.abJournalRatio}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
            }
            {
                !showSpinner && cohortAnalysisData.length > 0 &&
                <Accordion square style={{ padding: "0px 16px" }} expanded={compareAccordianExpand} onChange={() => setCompareAccordianExpand(!compareAccordianExpand)}>
                    <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />}>
                        <div>
                            <p style={{ textAlign: "center" }}>Select any two of the above faculty to compare.</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 25 }}>
                        <Grid item xs={3} style={{ marginLeft: "auto", marginRight: "auto" }} container direction="column" justify="center" alignItems="center">
                            <StyledButton disabled={selectedAuthors.length !== 2} color='secondary' disableFocusRipple fullWidth variant="contained" onClick={() => { comparefaculty() }}>
                                COMPARE
                            </StyledButton>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                showCompareFacultyResult && !showCompareSpinner && compareFacultyData.chartCategories.length > 0 &&
                <Compareyourselfresult
                    faculty1={compareFacultyData.faculty1}
                    faculty2={compareFacultyData.faculty2}
                    papersChartseries={compareFacultyData.papersChartSeries}
                    citationsChartSeries={compareFacultyData.citationsChartSeries}
                    chartCategories={compareFacultyData.chartCategories}
                />
            }
            {
                showCompareFacultyResult && showCompareSpinner &&
                <div style={{ textAlign: "center" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
            }
        </div>
    );
}

export default CohortAnalysis;