import React from 'react'
import ReactApexChart from "react-apexcharts";
import { makeStyles } from '@material-ui/core/styles';
import './CompareYourself.css';
import {
    Button, Paper, Grid, Collapse, Divider, Avatar
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const defaultFacultyPic = require('../../../../assets/Images/default_faculty.png');

const useStyles = makeStyles((theme) => ({
    infoTableGrid: {
        paddingRight: "0px !important", paddingTop: "0px !important", paddingBottom: "0px !important",
    },
    infoTableValue: {
        padding: "0px 12px 0px 0px !important"
    },
    BG: {
        backgroundColor: "#F2F2F2"
    },
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    searchButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
}));
const Compareyourselfresult = (props) => {
    const currentYear = new Date().getFullYear();
    const { faculty1, faculty2, citationsChartSeries, papersChartseries, chartCategories } = props;
    const [showInfo, setShowInfo] = React.useState(false);
    const [expandFaculty1, setExpandFaculty1] = React.useState(false);
    const [expandFaculty2, setExpandFaculty2] = React.useState(false);

    const classes = useStyles();

    const dismissClick = () => {
        setShowInfo(!showInfo);
    }
    const handleExpand1 = () => {
        setExpandFaculty1(!expandFaculty1);
    }
    const handleExpand2 = () => {
        setExpandFaculty2(!expandFaculty2);
    }
    const options1 = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        colors: ['#BBB21E', '#FFA300'],
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return val
            },
            style: {
                colors: ['#BBB21E', '#FFA300'],
            },
            background: {
                enabled: true,
                foreColor: '#84266A',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#84266A',
                opacity: 0.9,
            }
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Papers over years',
            align: 'center',
            style: {
                fontWeight: 500,
                fontSize: 14
            }
        },
        grid: {
            borderColor: '#e7e7e7',
        },
        markers: {
            size: 1
        },
        xaxis: {
            categories: chartCategories,
            title: {
                text: 'Years',
                style: {
                    fontWeight: 500,
                    fontSize: 14
                }
            }
        },
        yaxis: {
            title: {
                text: 'Papers published',
                style: {
                    fontWeight: 500,
                    fontSize: 14
                }
            },
        },
        tooltip: {
            enabled: false
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            floating: true,
            offsetY: 4,
            offsetX: 5,
            markers: {
                offsetX: 0,
                offsetY: 4
            },
        }
    };
    const options2 = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        colors: ['#BBB21E', '#FFA300'],
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return val
            },
            style: {
                colors: ['#BBB21E', '#FFA300'],
            },
            background: {
                enabled: true,
                foreColor: '#84266A',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#84266A',
                opacity: 0.9,
            }
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Citations over years',
            align: 'center',
            style: {
                fontWeight: 500,
                fontSize: 14
            }
        },
        grid: {
            borderColor: '#e7e7e7',
        },
        markers: {
            size: 1
        },
        xaxis: {
            categories: chartCategories,
            title: {
                text: 'Years',
                style: {
                    fontWeight: 500,
                    fontSize: 14
                }
            }
        },
        yaxis: {
            title: {
                text: 'Citations',
                style: {
                    fontWeight: 500,
                    fontSize: 14
                }
            },
        },
        tooltip: {
            enabled: false
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            floating: true,
            offsetY: 5,
            offsetX: 5,
            markers: {
                offsetX: 0,
                offsetY: 5
            },
        }
    };

    return (
        <div>
            <Paper square style={{ padding: "8px 12px 0px 12px", marginTop: 35 }}>
                {showInfo &&
                    <Paper square className="infoPaper">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <div style={{ display: "inline-flex" }}>
                                    <div style={{ paddingRight: 8 }}>
                                        <ErrorOutlineIcon />
                                    </div>
                                    <div>
                                        We do not have the confirmed career trajectory dates for Fname Lname. We show their metrics - H-index, Papers, Citations and A:B journals.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" className="searchButton" color="primary" style={{ fontSize: 13 }}>Add Information</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" className="searchButton" color="primary" onClick={dismissClick} style={{ fontSize: 13 }}>Dismiss</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {/* FACULTY 1 */}
                <Grid container spacing={3} style={{ marginTop: 13 }}>
                    <Grid item xs={2}>
                        <div style={{ width: "fit-content", marginLeft: 15 }}>
                            <div>
                                <Avatar alt={faculty1.first_name} src={faculty1.data.profile_picture} style={{ height: 75, width: 75, borderRadius: '50%' }} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                {`${faculty1.first_name} ${faculty1.last_name}`}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={9} style={{ margin: "auto" }}>
                        {faculty1.data.work_history_title.length !== 0 ?
                            <div id="timeline-wrap">
                                <div id="timeline"></div>
                                {faculty1.data.work_history_title.length >= 1 &&
                                    <div>
                                        <span id="timelineHeader" className="m1">
                                            {faculty1.data.work_history_title[0]}
                                        </span>
                                        <div className="marker m1 timeline-icon one">
                                            {faculty1.data.work_history_year[0]}
                                        </div>
                                    </div>
                                }
                                {faculty1.data.work_history_title.length >= 2 &&
                                    <div>
                                        <span id="timelineHeader" className="m2">
                                            {faculty1.data.work_history_title[1]}
                                        </span>
                                        <div className="marker m2 timeline-icon two">
                                            {faculty1.data.work_history_year[1]}
                                        </div>
                                        <span id="timelineDuration" className="t1">
                                            {faculty1.data.work_history_year[1] - faculty1.data.work_history_year[0]} Years
                                        </span>
                                    </div>
                                }
                                {faculty1.data.work_history_title.length >= 3 &&
                                    <div>
                                        <span id="timelineHeader" className="m3" style={{ marginLeft: "-28px" }}>
                                            {faculty1.data.work_history_title[2]}
                                        </span>
                                        <div className="marker m3 timeline-icon three">
                                            {faculty1.data.work_history_year[2]}
                                        </div>
                                        <span id="timelineDuration" className="t2">
                                            {faculty1.data.work_history_year[2] - faculty1.data.work_history_year[1]} Years
                                        </span>
                                    </div>
                                }
                                {faculty1.data.work_history_title.length >= 4 &&
                                    <div>
                                        <span id="timelineHeader" className="m4" style={{ marginLeft: "-18px" }}>
                                            {faculty1.data.work_history_title[3]}
                                        </span>
                                        <div className="marker m4 timeline-icon three">
                                            {faculty1.data.work_history_year[3]}
                                        </div>
                                        <span id="timelineDuration" className="t3">
                                            {faculty1.data.work_history_year[3] - faculty1.data.work_history_year[2]} Years
                                        </span>
                                    </div>
                                }
                                <div>
                                    <span id="timelineHeader" className="mlast" style={{ left: "97%", right: "-9%" }}>
                                        Current Year
                                    </span>
                                    <div className="marker mlast timeline-icon four">
                                        {currentYear}
                                    </div>
                                    <span id="timelineDuration" className="t3">
                                        {currentYear - faculty1.data.work_history_year[faculty1.data.work_history_year.length - 1]} Years
                                    </span>
                                </div>
                            </div>
                            :
                            <div style={{ marginTop: "1% 0%" }}>
                                <InfoIcon style={{ marginTop: "-3px", color: "#555559" }} />
                                <span style={{ marginLeft: 5 }}>
                                    We do not have work history for this faculty.
                                </span>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={1} style={{ margin: "auto" }}>
                        {faculty1.data.work_history_title.length !== 0 ?
                            <IconButton style={{ transform: expandFaculty1 ? "rotate(180deg)" : "rotate(0)", transition: "all 0.2s linear" }} onClick={handleExpand1} >
                                <ExpandMoreIcon />
                            </IconButton>
                            : null
                        }
                    </Grid>
                </Grid>
                <Collapse in={expandFaculty1} timeout="auto" unmountOnExit>
                    <Grid container spacing={3} style={{ marginTop: 13, marginLeft: 3 }}>
                        <Grid item xs={2} className={`${classes.infoTableGrid} ${classes.BG}`}>
                            <div style={{ paddingLeft: 10 }}>
                                H-Index
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.BG} ${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty1.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty1.data.work_history_year[0] in faculty1.data.hindex ? faculty1.data.hindex[faculty1.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty1.data.work_history_year[1] in faculty1.data.hindex ? faculty1.data.hindex[faculty1.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty1.data.work_history_year[2] in faculty1.data.hindex ? faculty1.data.hindex[faculty1.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty1.data.work_history_year[3] in faculty1.data.hindex ? faculty1.data.hindex[faculty1.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty1.data.hindex ? faculty1.data.hindex[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={`${classes.infoTableGrid}`}>
                            <div style={{ paddingLeft: 10 }}>
                                Total Papers
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty1.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty1.data.work_history_year[0] in faculty1.data.total_papers ? faculty1.data.total_papers[faculty1.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty1.data.work_history_year[1] in faculty1.data.total_papers ? faculty1.data.total_papers[faculty1.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty1.data.work_history_year[2] in faculty1.data.total_papers ? faculty1.data.total_papers[faculty1.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty1.data.work_history_year[3] in faculty1.data.total_papers ? faculty1.data.total_papers[faculty1.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty1.data.total_citations ? faculty1.data.total_papers[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={`${classes.infoTableGrid} ${classes.BG}`}>
                            <div style={{ backgroundColor: "#F2F2F2", paddingLeft: 10 }}>
                                Total Citations
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.BG} ${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty1.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty1.data.work_history_year[0] in faculty1.data.total_citations ? faculty1.data.total_citations[faculty1.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty1.data.work_history_year[1] in faculty1.data.total_citations ? faculty1.data.total_citations[faculty1.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty1.data.work_history_year[2] in faculty1.data.total_citations ? faculty1.data.total_citations[faculty1.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty1.data.work_history_year[3] in faculty1.data.total_citations ? faculty1.data.total_citations[faculty1.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty1.data.total_citations ? faculty1.data.total_citations[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={`${classes.infoTableGrid}`}>
                            <div style={{ paddingLeft: 10 }}>
                                Proportion of A/b journals
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty1.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty1.data.work_history_year[0] in faculty1.data.abjournals ? faculty1.data.abjournals[faculty1.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty1.data.work_history_year[1] in faculty1.data.abjournals ? faculty1.data.abjournals[faculty1.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty1.data.work_history_year[2] in faculty1.data.abjournals ? faculty1.data.abjournals[faculty1.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty1.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty1.data.work_history_year[3] in faculty1.data.abjournals ? faculty1.data.abjournals[faculty1.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty1.data.abjournals ? faculty1.data.abjournals[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginTop: "1.5%" }} />
                {/* FACULTY 2 */}
                <Grid container spacing={3} style={{ marginTop: 13 }}>
                    <Grid item xs={2}>
                        <div style={{ width: "fit-content", marginLeft: 15 }}>
                            <div>
                                <Avatar alt={faculty2.first_name} src={faculty2.data.profile_picture} style={{ height: 75, width: 75, borderRadius: '50%' }} />

                            </div>
                            <div style={{ textAlign: "center" }}>
                                {`${faculty2.first_name} ${faculty2.last_name}`}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={9} style={{ margin: "auto" }}>
                        {faculty2.data.work_history_title.length !== 0 ?
                            <div id="timeline-wrap">
                                <div id="timeline2"></div>
                                {faculty2.data.work_history_title.length >= 1 &&
                                    <div>
                                        <span id="timelineHeader" className="m1">
                                            {faculty2.data.work_history_title[0]}
                                        </span>
                                        <div className="marker m1 timeline2-icon one">
                                            {faculty2.data.work_history_year[0]}
                                        </div>
                                    </div>
                                }
                                {faculty2.data.work_history_title.length >= 2 &&
                                    <div>
                                        <span id="timelineHeader" className="m2">
                                            {faculty2.data.work_history_title[1]}
                                        </span>
                                        <div className="marker m2 timeline2-icon two">
                                            {faculty2.data.work_history_year[1]}
                                        </div>
                                        <span id="timelineDuration" className="t1">
                                            {faculty2.data.work_history_year[1] - faculty2.data.work_history_year[0]} Years
                                        </span>
                                    </div>
                                }
                                {faculty2.data.work_history_title.length >= 3 &&
                                    <div>
                                        <span id="timelineHeader" className="m3" style={{ marginLeft: "-28px" }}>
                                            {faculty2.data.work_history_title[2]}
                                        </span>
                                        <div className="marker m3 timeline2-icon three">
                                            {faculty2.data.work_history_year[2]}
                                        </div>
                                        <span id="timelineDuration" className="t2">
                                            {faculty2.data.work_history_year[2] - faculty2.data.work_history_year[1]} Years
                                        </span>
                                    </div>
                                }
                                {faculty2.data.work_history_title.length >= 4 &&
                                    <div>
                                        <span id="timelineHeader" className="m4" style={{ marginLeft: "-18px" }}>
                                            {faculty2.data.work_history_title[3]}
                                        </span>
                                        <div className="marker m4 timeline2-icon three">
                                            {faculty2.data.work_history_year[3]}
                                        </div>
                                        <span id="timelineDuration" className="t3">
                                            {faculty2.data.work_history_year[3] - faculty2.data.work_history_year[2]} Years
                                        </span>
                                    </div>
                                }
                                <div>
                                    <span id="timelineHeader" className="mlast" style={{ left: "97%", right: "-9%" }}>
                                        Current Year
                                    </span>
                                    <div className="marker mlast timeline2-icon four">
                                        {currentYear}
                                    </div>
                                    <span id="timelineDuration" className="t3">
                                        {currentYear - faculty2.data.work_history_year[faculty2.data.work_history_year.length - 1]} Years
                                    </span>
                                </div>
                            </div>
                            :
                            <div style={{ marginTop: "1% 0%" }}>
                                <InfoIcon style={{ marginTop: "-3px", color: "#555559" }} />
                                <span style={{ marginLeft: 5 }} >
                                    We do not have work history for this faculty.
                                </span>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={1} style={{ margin: "auto" }}>
                        {faculty2.data.work_history_title.length !== 0 ?
                            <IconButton style={{ transform: expandFaculty2 ? "rotate(180deg)" : "rotate(0)", transition: "all 0.2s linear" }} onClick={handleExpand2} >
                                <ExpandMoreIcon />
                            </IconButton>
                            : null
                        }
                    </Grid>
                </Grid>
                <Collapse in={expandFaculty2} timeout="auto" unmountOnExit>
                    <Grid container spacing={3} style={{ marginTop: 13, marginLeft: 3 }}>
                        <Grid item xs={2} className={`${classes.infoTableGrid} ${classes.BG}`}>
                            <div style={{ paddingLeft: 10 }}>
                                H-Index
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.BG} ${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty2.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty2.data.work_history_year[0] in faculty2.data.hindex ? faculty2.data.hindex[faculty2.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty2.data.work_history_year[1] in faculty2.data.hindex ? faculty2.data.hindex[faculty2.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty2.data.work_history_year[2] in faculty2.data.hindex ? faculty2.data.hindex[faculty2.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty2.data.work_history_year[3] in faculty2.data.hindex ? faculty2.data.hindex[faculty2.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty2.data.hindex ? faculty2.data.hindex[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={`${classes.infoTableGrid}`}>
                            <div style={{ paddingLeft: 10 }}>
                                Total Papers
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty2.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty2.data.work_history_year[0] in faculty2.data.total_papers ? faculty2.data.total_papers[faculty2.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty2.data.work_history_year[1] in faculty2.data.total_papers ? faculty2.data.total_papers[faculty2.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty2.data.work_history_year[2] in faculty2.data.total_papers ? faculty2.data.total_papers[faculty2.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty2.data.work_history_year[3] in faculty2.data.total_papers ? faculty2.data.total_papers[faculty2.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty2.data.total_citations ? faculty2.data.total_papers[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={`${classes.infoTableGrid} ${classes.BG}`}>
                            <div style={{ backgroundColor: "#F2F2F2", paddingLeft: 10 }}>
                                Total Citations
                            </div>
                        </Grid>
                        <Grid item xs={9} className={`${classes.BG} ${classes.infoTableValue}`}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty2.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty2.data.work_history_year[0] in faculty2.data.total_citations ? faculty2.data.total_citations[faculty2.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty2.data.work_history_year[1] in faculty2.data.total_citations ? faculty2.data.total_citations[faculty2.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty2.data.work_history_year[2] in faculty2.data.total_citations ? faculty2.data.total_citations[faculty2.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty2.data.work_history_year[3] in faculty2.data.total_citations ? faculty2.data.total_citations[faculty2.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty2.data.total_citations ? faculty2.data.total_citations[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={`${classes.infoTableGrid}`}>
                            <div style={{ paddingLeft: 10 }}>
                                Proportion of A/b journals
                            </div>
                        </Grid>
                        <Grid item xs={9} style={{ padding: "0px 12px 30px 0px" }}>
                            <div id="timeline-wrap" style={{ margin: "24px 20px" }}>
                                {faculty2.data.work_history_year.length >= 1 &&
                                    <div className=" m1 test" >
                                        {faculty2.data.work_history_year[0] in faculty2.data.abjournals ? faculty2.data.abjournals[faculty2.data.work_history_year[0]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 2 &&
                                    <div className=" m2 test">
                                        {faculty2.data.work_history_year[1] in faculty2.data.abjournals ? faculty2.data.abjournals[faculty2.data.work_history_year[1]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 3 &&
                                    <div className=" m3 test">
                                        {faculty2.data.work_history_year[2] in faculty2.data.abjournals ? faculty2.data.abjournals[faculty2.data.work_history_year[2]] : "-"}
                                    </div>
                                }
                                {faculty2.data.work_history_year.length >= 4 &&
                                    <div className=" m4 test">
                                        {faculty2.data.work_history_year[3] in faculty2.data.abjournals ? faculty2.data.abjournals[faculty2.data.work_history_year[3]] : "-"}
                                    </div>
                                }
                                <div className=" mlast test">
                                    {currentYear in faculty2.data.abjournals ? faculty2.data.abjournals[currentYear] : "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Collapse>
            </Paper>
            <Paper square style={{ padding: "0px 12px", marginTop: 35 }}>
                <Grid container spacing={3} style={{ marginTop: 13 }}>
                    <Grid item xs={6}>
                        <ReactApexChart options={options1} series={papersChartseries} type="line" height={410} />
                    </Grid>
                    <Grid item xs={6}>
                        <ReactApexChart options={options2} series={citationsChartSeries} type="line" height={410} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default Compareyourselfresult;
