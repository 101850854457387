import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const authorSearchAction = (data) => {
    return {
        type: actionTypes.GET_AUTHOR_WITH_INCONSISTENT_PAPER,
        searchResult: data
    }
}

export const authorSearch = (token, reflag_url) => {

    return async dispatch => {
        const response = await fetch(reflag_url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for profile.');
        }
        const authorData = await response.json();

        dispatch(authorSearchAction(authorData));
    };
}

export const toggleFlag = (flag, val) => {
    return async dispatch => {
        if (flag === "FACULTY WITH INCORRECT PERCENT APPOINTMENT") {
            dispatch({ type: actionTypes.CHANGE_INCORRECT_PERCENT_APPOINTMENT_TOGGLE, isActive: val });
        }
        else if (flag === "FACULTY WITH INCONSISTENT PAPER NUMBERS") {
            dispatch({ type: actionTypes.CHANGE_INCONSISTENT_PAPER_REDFLAG_TOGGLE, isActive: val });
        }
        else if (flag === "FACULTY WITH MISSING GENDER") {
            dispatch({ type: actionTypes.CHANGE_MISSING_GENDER_TOGGLE, isActive: val });
        }
        else if (flag === "FACULTY WITH MISSING POSITION") {
            dispatch({ type: actionTypes.CHANGE_MISSING_POSITION_TOGGLE, isActive: val });
        }
        else if (flag === "FACULTY WITH MISSING DEPARTMENT") {
            dispatch({ type: actionTypes.CHANGE_MISSING_DEPARTMENT_TOGGLE, isActive: val });
        }
        else if (flag === "FACULTY WITH MISSING MARKER") {
            dispatch({ type: actionTypes.CHANGE_MISSING_MARKER_TOGGLE, isActive: val });
        }




    }
}

