import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    findYourFitResult: [],
    universityNameMap: {}
}

const findYourFitReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FIND_YOUR_FIT:
            let result = {
                a_to_b_journals_ratio: action.findYourFitResult.a_to_b_journals_ratio,
                solo_to_coauthored_papers_ratio: action.findYourFitResult.solo_to_coauthored_papers_ratio,
                citations_per_paper: action.findYourFitResult.citations_per_paper,
                avg_papers_per_year_per_faculty: action.findYourFitResult.avg_papers_per_year_per_faculty,
            }

            return updateObject(state, { findYourFitResult: result, universityNameMap: action.findYourFitResult.university_map });
        case actionTypes.CLEAR_FIND_YOUR_FIT:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default findYourFitReducer;