import {GET_MERGE_AUTHORS_FOR_TOOLS, MERGE_SUCCESS} from './actionTypes';
import { BACKEND_URL } from '../../utils';
import axios from 'axios';

const getMergeAuthorsForToolsAction = (authors) => {
    return {
        type: GET_MERGE_AUTHORS_FOR_TOOLS,
        payload: authors
    }
}

const mergeSuccess = (user_author_id) => {
    return {
        type: MERGE_SUCCESS,
        payload: user_author_id
    }
}

export const fetchAuthorsToMerge = (token) => {
    return async dispatch => {
        let url = BACKEND_URL + "tools/merge_user_author"
        const response = await fetch(url, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(response.status!==200) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching logs with error: ' +errorResData);
        }
        const authors = await response.json();
        dispatch(getMergeAuthorsForToolsAction(authors));
    }
}

export const mergeAuthors = (token, user_author_id, author_final_id, add_unmergable_author, note, remind_later) => {
    return async dispatch => {
        let url = BACKEND_URL + "tools/merge_authors"
        let post_body = {
            "user_author_id": user_author_id,
            "author_final_id": author_final_id,
            "add_unmergable_author": add_unmergable_author === true ? 1 : 0,
            "note": note,
            "remind_later": remind_later === true ? 1 : 0
        }
        const response = await axios.post(url,  post_body, 
            {
                headers: {
                    Authorization: 'Bearer '+ token,
            }}
        );
        if(response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while merging authors: ' +errorResData);
        }
        dispatch(mergeSuccess(user_author_id));
    }
}