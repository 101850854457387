import React from 'react';
import classes from './Footer.module.css';
import { Row } from 'react-bootstrap';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import { withRouter } from 'react-router-dom'; 

const currYear = new Date().getFullYear();
const footer = props => {
    return (
        <div className={classes.container}>
            <SocialMedia />
            <Row><h6>{"\u00A9"} {currYear} ResearchLoupe. All Rights Reserved.</h6></Row>
        </div>
    );
}

export default withRouter(footer);
