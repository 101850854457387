import React from 'react';
import classes from './Subscribe.module.css';
import schoolLogo from '../../assets/Images/school_256.png'
import facultyLogo from '../../assets/Images/faculty_256.png';
import othersLogo from '../../assets/Images/specialreports_256.png';

const Subscribe = props => {

    // const handleSubmit = event => {
    //     const form = event.currentTarget;
    //     event.preventDefault();
    //     if (form.checkValidity() === false) {
    //         console.log("Form Invalid");
    //     }
    //     else{
    //         const name = document.getElementById("name").value;
    //         const email = document.getElementById("email").value;
    //         console.log(name);
    //         console.log(email);
    //         setSubscribed(true);
    //     }
    //     setValidated(true);
    //   };
    
    // const [validated, setValidated] = useState(false);
    // const [subscribed, setSubscribed] = useState(false);
    const returnLogo = type => {
        if(type==="ForSchools"){
          return schoolLogo
        }else if(type==="ForFaculty"){
          return facultyLogo
        }else if(type==="SpecialReports"){
          return othersLogo
        }
    }

    return (
    // <div className={classes.boundary} style={{backgroundImage:"url(" + returnLogo(props.type) + ")"}}>
      
    //     {/* <h1> MEASURING YOUR POTENTIAL FOR SUCCESS</h1>
    //     <h4> Our new and inventive app will help you measure your success!</h4>
    //     <h4> Subscribe below to know when we launch !</h4>
    //     { subscribed ? 
    //       <h3> Thank You For Subscribing!</h3>:
    //       <SubscribeForm validated = {validated} onSubmit = {handleSubmit}/>
    //     } */}
      
        
    //     <span className={classes.description}>Research Performance Insights </span>
    // </div>
    <div className={classes.boundary}>
      <div className={classes.hero_text} >
        <p className={classes.description}>Research Performance Insights</p>
        {/* <span className={classes.description}>Research Performance Insights </span> */}
      </div>
      <div className={classes.hero_img} style={{backgroundImage:"url(" + returnLogo(props.type) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "right center"}}>

      </div>
    </div>

   );
}

export default Subscribe;
