import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    faculty1: {},
    faculty2: {},
    papersChartSeries: [],
    citationsChartSeries: [],
    chartCategories: []
}

const compareYourselfReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPARE_YOURSELF:
            let tempFaculty1 = action.searchResult.result[0];
            let tempFaculty2 = action.searchResult.result[1];
            let chartCategories = [];
            let faculty1Papers = [];
            let faculty1Citations = [];
            let faculty2Papers = [];
            let faculty2Citations = [];
            let maxYears = Math.max(Object.keys(tempFaculty1.data.papers_over_years).length, Object.keys(tempFaculty2.data.papers_over_years).length);
            chartCategories = Array.from({ length: maxYears }, (x, i) => (i + 1));
            for (const key in tempFaculty1.data.papers_over_years) {
                faculty1Papers.push(tempFaculty1.data.papers_over_years[key]);
                faculty1Citations.push(tempFaculty1.data.citation_over_years[key]);
            }
            for (const key in tempFaculty2.data.papers_over_years) {
                faculty2Papers.push(tempFaculty2.data.papers_over_years[key]);
                faculty2Citations.push(tempFaculty2.data.citation_over_years[key]);
            }
            let workHistoryTitle = [];
            let workHistoryYear = [];
            for (const key in tempFaculty1.data.work_history) {
                workHistoryTitle.push(key);
                workHistoryYear.push(tempFaculty1.data.work_history[key][0]);
            }
            tempFaculty1.data["work_history_title"] = workHistoryTitle;
            tempFaculty1.data["work_history_year"] = workHistoryYear;
            workHistoryTitle = [];
            workHistoryYear = [];
            for (const key in tempFaculty2.data.work_history) {
                workHistoryTitle.push(key);
                workHistoryYear.push(tempFaculty2.data.work_history[key][0]);
            }
            tempFaculty2.data["work_history_title"] = workHistoryTitle;
            tempFaculty2.data["work_history_year"] = workHistoryYear;
            let papersChartSeries1 = { "name": `${tempFaculty1["first_name"]} ${tempFaculty1["last_name"]}`, "data": faculty1Papers }
            let papersChartSeries2 = { "name": `${tempFaculty2["first_name"]} ${tempFaculty2["last_name"]}`, "data": faculty2Papers }
            let citationsChartSeries1 = { "name": `${tempFaculty1["first_name"]} ${tempFaculty1["last_name"]}`, "data": faculty1Citations }
            let citationsChartSeries2 = { "name": `${tempFaculty2["first_name"]} ${tempFaculty2["last_name"]}`, "data": faculty2Citations }
            return updateObject(state, {
                faculty1: tempFaculty1,
                faculty2: tempFaculty2,
                papersChartSeries: [papersChartSeries1, papersChartSeries2],
                citationsChartSeries: [citationsChartSeries1, citationsChartSeries2],
                chartCategories: chartCategories
            });
        case actionTypes.CLEAR_COMPARE_YOURSELF:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default compareYourselfReducer;