// export const BACKEND_URL = "https://ec2-13-56-227-108.us-west-1.compute.amazonaws.com/"
// export const BACKEND_URL_HTTP = "http://ec2-13-56-227-108.us-west-1.compute.amazonaws.com/"
export const BACKEND_URL = "https://api.researchloupe.com/";
// export const BACKEND_URL = "http://127.0.0.1:5000/";
export const BACKEND_URL_HTTP = "http://api.researchloupe.com/";
// https://api.researchloupe.com/
//export const BACKEND_URL = "http://localhost:5000/"

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const convertArrayToSummary = (array) => {
  let info = " ";
  for (let val of array) {
    info += ` ${val},`;
  }
  info = info.slice(0, info.length - 1);
  let comma = info.lastIndexOf(",");
  if (comma !== -1) {
    info =
      info.substr(0, comma) + " and " + info.substr(comma + 2, info.length);
  }
  return info;
};
