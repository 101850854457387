import React ,{useState} from 'react';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import RadioSelect from '../../../../InputComponents/RadioSelect';
import * as classes from './Education.module.css';
import InfoIcon from '@material-ui/icons/Info';

const ChoosePhdModal = props => {
    // Matches all of the degree's that we consider equivalent to a PHD
    const re = /(^(P|p)\.?(H|h)\.?(D|d)\.?$)|(^(D|d)\.?(B|b)\.?(A|a)\.?$)|(^(D|d)\.?(S|s)\.?(C|c)\.?$)|(^(J|j)\.?(S|s)\.?(D|d)\.?$)|(^(L|l)\.?(H|h)\.?(D|d)\.?$)|(^(M|m)\.?(D|d)\.?$)|(^(D|d)\.?(A|a)\.?$)|(^(D|d)\.?(P|p)(H|h)(I|i)(L|l)$)/;
    const saveChosenPHD = () => {
        var edu = props.education.map(value => {
            if (value.id === isPHDtoConsider) {
                value.isPHDtoConsider = true;
            } else {
                delete value.isPHDtoConsider;
            }
            return value
        });
        props.onSave(edu);
    };

    const onPHDSelect = (event) => {
        setIsPHDtoConsider(parseInt(event.target.value));
    };

    var chosenPHD = null;
    const radioData = props.education.filter(edu => {
            return re.test(edu.degree) === true || typeof edu.isPHDtoConsider !== 'undefined';
        }).map((item, index) => {
            if (typeof item.isPHDtoConsider !== 'undefined') {
                chosenPHD = item.id;
            }
            return (
                {
                    value: item.id,
                    label: `${item.degree} in ${item.area} at ${item.institution} in year ${parseFloat(item.year)}`
                }
            )
    });

    const [isPHDtoConsider, setIsPHDtoConsider] = useState(chosenPHD !== null ? chosenPHD: radioData.length !== 0 ? radioData[0].value : 1);

    if (radioData.map(item => item.value).includes(isPHDtoConsider) === false && radioData.length !== 0) {
        setIsPHDtoConsider(radioData[0].value);
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Choose PHD
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <div style={{fontSize: "1rem", paddingLeft: "15px"}}>
                            <InfoIcon fontSize="small" style={{ color: "gray" }}/>
                            <span style={{verticalAlign: "middle"}}>Please select which Ph.D. is the one that started your research career associated with your current position. This Ph.D year will be used as a start year for your research in this field.</span>
                        </div>
                    </Row>
                    <Row md={12}>
                        <RadioSelect data={radioData} value={isPHDtoConsider} onChange={onPHDSelect.bind(this)}/>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" style={{color:'#000000',backgroundColor:"#BBB218",borderRadius:"0%"}} onClick={saveChosenPHD}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ChoosePhdModal;