import React, { useEffect, useState } from 'react';
import classes from './Papers.module.css';
import profileClassses from '../Profile.module.css';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import DeleteWarningModal from './DeleteWarningModal';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPaperModal from './AddPaperModal';
import AddMorePapersModal from './AddMorePapersModal';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from "react-router-dom";
import NoteModal from '../WorkHistory/NoteModal';
import { Grid, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Papers = props => {
    const addColor = "#DADADA"
    const dangerColor = "#DADADA"
    const [papers, setPapers] = useState(props.data.papers);
    const [showDeleteCheck, setShowDeleteCheck] = useState(false);
    const [curIndex, setCurIndex] = useState(-1)
    const [curPaperId, setCurPaperId] = useState(0)
    const [showAddPaper, setshowAddPaper] = useState(false)
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [addMorePapersModal, setaddMorePapersModal] = useState(false)
    const [curPaper, setCurPaper] = useState({ journal: "", paper: "" })
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const history = useHistory();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const passIndex = (index, id) => {
        setCurIndex(index)
        setShowDeleteCheck(true)
        setCurPaperId(id)
    };

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const removePaper = (curId) => {
        setShowDeleteCheck(false)
        if (papers.length === 1) {
            setPapers([])
        } else {
            const curPapers = [papers.length - 1]
            var counter = 0;
            for (var i = 0; i < papers.length; i++) {
                if (i !== curId) {
                    curPapers[counter] = { ...papers[i] }
                    counter++;
                }
            }
            setPapers(curPapers);
        }
    };

    const addMorePaper = () => {
        setshowAddPaper(false)
        setCurPaper({ journal: "", paper: "" });
        setaddMorePapersModal(true)
    };

    const doNotRemove = () => {
        setShowDeleteCheck(false)
    };

    const getCoAuthorProfile = (id) => {
        localStorage.setItem('coauthorid', id);
        var curid = localStorage.getItem('rl_userId')
        if (id === curid) {
        } else {
            history.push({
                pathname: '/dashboard/profileEdit',
                state: { coauthorid: id, isReadOnly: true }
            })
        }
    };

    const showCoAuthors = (id, fname) => {
        getCoAuthorProfile(id)
    };

    const hideAddMorePapersModal = (status) => {
        setaddMorePapersModal(false);
        if (status === "Success")
            setNoteModalShow(true);
    };

    useEffect(() => {
    }, [props.location]);

    return (
        <Grid container xs={20} alignItems="center" justify="center" className={profileClassses.grid}>
            <Accordion square className={profileClassses.accordian} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary className={profileClassses.accordian_summary} expandIcon={<ExpandMoreIcon />} >
                    <Row className={classes.header}>
                        <Col sm={11} md={11}><h3 style={{ marginTop: "10px" }}>Papers</h3></Col>
                        {
                            profileUpdateState === true ?
                                <Col sm={1} md={1}>
                                    <span style={{ cursor: "pointer" }}>
                                        <LightTooltip title="Click to add Paper." placement="bottom">
                                            <AddCircleIcon style={{ width: '1.2em', height: '1.2em', color: addColor, marginLeft: "436px", marginTop: "9px", }} onClick={() => { setCurPaper({ journal: "", paper: "" }); setshowAddPaper(true) }} ></AddCircleIcon>
                                        </LightTooltip>
                                    </span>
                                </Col>
                                : null
                        }
                    </Row>
                </AccordionSummary>
                <Container className={classes.container}>

                    {
                        papers.sort((a, b) => (a.publication_year > b.publication_year) ? -1 : ((b.publication_year > a.publication_year) ? 1 : 0)).map((item, index) => {
                            return (
                                [
                                    <Row className={[classes.subheader, profileClassses.subheaderFirst]} >
                                        <Col md={11}>
                                            <span className={classes.standardSize} >
                                                {index + 1 + "."}
                                                &nbsp;&nbsp;
                                                {
                                                    (!item.authors) ? null :
                                                        item.authors.map((author, i) => {
                                                            return (
                                                                <span >
                                                                    <span className={classes.standardSizeUnderline} onClick={() => showCoAuthors(author.id, author.first_name)}>
                                                                        {(author.first_name !== undefined && author.first_name.trim() !== "" ? author.first_name + (((author.mid_name !== undefined && author.mid_name.trim() !== "") || (author.last_name !== undefined && author.last_name.trim() !== "")) ? " " : "") : "") + (author.mid_name !== undefined && author.mid_name.trim() !== "" ? author.mid_name + ((author.last_name !== undefined && author.last_name.trim() !== "") ? " " : "") : "") +
                                                                            (author.last_name !== undefined && author.last_name.trim() !== "" ? author.last_name + "" : "")}
                                                                    </span>
                                                                    <span className={classes.standardSize}>
                                                                        {(i === (item.authors.length - 1) ? " " : (i === (item.authors.length - 2) ? "; and " : "; "))}
                                                                    </span>
                                                                    <span>
                                                                        {" "}
                                                                    </span>
                                                                </span>
                                                            )
                                                        })
                                                }
                                                {item.document_title
                                                    +
                                                    ((item.document_title !== undefined && item.document_title.trim() !== "" && item.document_title.slice(-1) !== '?' && item.document_title.slice(-1) !== '!') ?
                                                        "."
                                                        :
                                                        "")
                                                }
                                            </span>
                                            <span className={classes.italics}>
                                                &nbsp;&nbsp;
                                                {item.publication_name}
                                            </span>
                                            <span className={classes.standardSize}>
                                                &nbsp; &nbsp;&nbsp;
                                                {"(" + item.publication_year + ")"}
                                            </span>
                                        </Col>
                                        <Col md={1}>
                                            {
                                                profileUpdateState === true ?
                                                    <span style={{ cursor: "pointer" }}>
                                                        <LightTooltip title="Not your paper? Click to remove." placement="bottom">
                                                            <DeleteIcon style={{ width: '1em', height: '1em', color: dangerColor }} name={item.id} onClick={() => passIndex(index, item._id)} />
                                                        </LightTooltip>
                                                    </span>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                ]
                            )
                        })
                    }
                    <DeleteWarningModal
                        curIndex={curIndex}
                        curId={curPaperId}
                        onYes={removePaper}
                        onNo={doNotRemove}
                        show={showDeleteCheck}
                        onHide={() => setShowDeleteCheck(false)}
                        isCoAuthor={props.isCoAuthor} coAuthorId={props.data._id} />
                    <AddPaperModal
                        curPapers={papers}
                        show={showAddPaper}
                        curPaper={curPaper}
                        onAddMore={addMorePaper}
                        onHide={() => { setshowAddPaper(false); setAccordionExpand(true) }}
                        isCoAuthor={props.isCoAuthor} coAuthorId={props.data._id} />
                    <AddMorePapersModal
                        profileState={props.data}
                        show={addMorePapersModal}
                        curPaper={curPaper}
                        onHide={(status) => hideAddMorePapersModal(status)} />
                    <NoteModal
                        show={noteModalShow}
                        onHide={() => setNoteModalShow(false)}
                        note={"An email with your request has been sent to our team. We will get back to you shortly."} />
                </Container>
            </Accordion>
        </Grid>
    );
}

export default Papers;