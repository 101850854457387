import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const contactSupportAction = (data) => {
    return {
        type: actionTypes.CONTACT_US_REQUEST,
        paginationData: data
    }
}

const updateContactedFlag = (updatedFlag) => {
    return {
        type: actionTypes.UPDATE_CONTACTED_FLAG,
        updatedContactedData: updatedFlag
    }
}

export const contactUsRequestAction = (token, skipData) => {

    return async dispatch => {
        const response = await fetch( BACKEND_URL + `contact_us?skip=` + skipData, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for page '+skipData+' with error: ' +errorResData);
        }

        const contactUsRequestData  = await response.json();
        dispatch(contactSupportAction(contactUsRequestData));
    };
}

export const updateContacted = (token, id, flag, originalData) => {
    
    for(let customer in originalData){
        
        if(originalData[customer]["id"] === id){
            originalData[customer]["contacted"] = flag;
        }
    }

    return async dispatch => {

        const updatedRepsonse = await fetch( BACKEND_URL + `contact_us?id=` + id + `&contacted=` + flag, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        
        if(!updatedRepsonse.ok) {
            const errorResData = await updatedRepsonse.json();
            throw new Error('Something went wrong. Error: ' + errorResData);
        }

        const updatedDataMessage = await updatedRepsonse.json();

        if(updatedDataMessage.message === "Update successful"){
            dispatch(updateContactedFlag(originalData));
        }else{
            alert('Something went wrong. Please try again')
        }

    };
}