import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const getPerceptualMapAction = (data) => {
    return {
        type: actionTypes.GET_PERCEPTUAL_MAP,
        perceptualMapData: data
    }
}

const invertAxesAction = (data) => {
    return {
        type: actionTypes.INVERT_AXES,
        perceptualMapData: data
    }
}

export const getPerceptualMap = (token, titles, x, y, school) => {
    return async dispatch => {

        const params = {
            "titles": titles,
            "x": x,
            "y": y,
            "school": school,
        }
        let url = new URL(BACKEND_URL + `perceptualMap`)
        // url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(params),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while getting perceptual map for ' + params + ' with error: ' + errorResData);
        }
        const perceptualMapData = await response.json();
        // console.log(perceptualMapData);
        // const perceptualMapData = {
        //     "result": [
        //         { display_name: null, university_name: "Dartmouth College", xdenominator: 5, xnumerator: 29, y: 541 }
        //         , { display_name: null, university_name: "University of Washington at Seattle", xdenominator: 24, xnumerator: 41, y: 1138 }
        //         , { display_name: null, university_name: "University of Georgia", xdenominator: 25, xnumerator: 43, y: 883 }
        //         , { display_name: null, university_name: "University of Florida", xdenominator: 11, xnumerator: 39, y: 895 }
        //         , { display_name: null, university_name: "Ohio State University", xdenominator: 17, xnumerator: 43, y: 1109 }
        //         , { display_name: null, university_name: "Temple University", xdenominator: 21, xnumerator: 34, y: 703 }
        //     ]
        // }
        dispatch(getPerceptualMapAction(perceptualMapData));

    };
}

export const invertAxes = (data) => {
    return async dispatch => {
        dispatch(invertAxesAction(data));
    }
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_COMPARE_SCHOOLS });
    }
}