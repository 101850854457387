import React from 'react';
import {
    Grid, Slider, Input, Accordion, AccordionSummary, AccordionDetails, FormLabel, styled
} from '@material-ui/core';
import {
    Typography, List, ListItemText,
    ListItem, ListItemAvatar, Avatar
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import classes2 from './ShowSimilarAuthor.module.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SliderStyled = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 2,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));


const ShowSimilarAuthor = props => {
    const currentYear = new Date().getFullYear();

    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const similarAuthorSearchResult = useSelector(state => state.similarAuthorSearch.similarAuthor);
    const primaryAuthorData = useSelector(state => state.cohortAnalysis.cohortRows[0]);
    const defaultFaculty = require('../../../../assets/Images/default_faculty.png');
    const [LRStep, setLRStep] = React.useState([-2, 2]);

    const [yearRange, setYearRange] = React.useState([primaryAuthorData ? primaryAuthorData['first_year_career'] : 1990 - 2, primaryAuthorData['first_year_career'] + 2]);

    const handleYearInputChange = (event,) => {
        let newYearRange = event.target.id === "from" ? [primaryAuthorData['first_year_career'] + Number(event.target.value), yearRange[1]] : [yearRange[0], primaryAuthorData['first_year_career'] + Number(event.target.value)];
        setYearRange(newYearRange);
        if (event.target.id === "from")
            setLRStep([Number(event.target.value), LRStep[1]])
        else
            setLRStep([LRStep[0], Number(event.target.value)])

    };
    const handleYearSliderChange = (event, newValue) => {

        setLRStep(newValue)
        setYearRange([primaryAuthorData['first_year_career'] + newValue[0], primaryAuthorData['first_year_career'] + newValue[1]])

    };
    const handleBlur = () => {
        if (LRStep[0] < -10) {
            setYearRange([primaryAuthorData['first_year_career'] - 10, yearRange[1]]);
        } else if (LRStep[0] > 10) {
            setYearRange([yearRange[0], primaryAuthorData['first_year_career'] + 10]);
        }
    };
    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }
    const passSimilarAuthorDetails = (selected_auth_data) => {
        var selected_similar_auth_data = {
            firstName: selected_auth_data['first_name'],
            id: selected_auth_data['author_id'],
            lastName: selected_auth_data['last_name'],
            pic: selected_auth_data['profile_picture'],
            universityName: selected_auth_data['univ_name'],
            first_year_career: selected_auth_data['first_year_career'],
            IsSimilarAuthor: true
        }
        props.selected_auth_info(selected_similar_auth_data)

    }
    function valuetext(value) {
        return `${value}`;
    }

    const marks = [
        {

            value: 0,
            label: primaryAuthorData ? primaryAuthorData['first_year_career'] : 1990,
        },

    ];

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}>
            <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />}>
                    <div style={{ float: "left" }}>
                        Compare with similar authors who started their careers between {yearRange[0]} and  {yearRange[1]}.
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: 25 }}>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={2} />
                        <Grid item xs={5} style={{ marginLeft: 100, width: 450 }} >
                            <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Choose the range of the cohort. {primaryAuthorData['firstName']}'s career started in {primaryAuthorData['first_year_career']}.</FormLabel>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <Input size="small" className="yearRangeInput" value={LRStep[0]} id="from" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                        inputProps={{
                                            step: 1,
                                            min: - 10
                                            ,
                                            max: 0,
                                            type: 'number',
                                        }} />
                                </Grid>
                                <Grid item xs  >
                                    <SliderStyled classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }} value={LRStep}
                                        onChange={handleYearSliderChange} min={-10} max={10}
                                        aria-label="Custom marks"
                                        getAriaValueText={valuetext}
                                        marks={marks}
                                    />
                                </Grid>
                                <Grid item>
                                    <Input className="yearRangeInput" value={LRStep[1]} id="to" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                        inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 10,
                                            type: 'number',
                                        }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} container justify="center" alignItems="center">
                        </Grid>
                        <Grid item xs={2} />

                        <div style={{ backgroundColor: "white", overflow: "auto", maxHeight: "475px", marginTop: '5px', marginBottom: '35px' }}>
                            <Grid item xs={15} style={{ marginLeft: 100, width: 450 }} >

                                <List>
                                    {similarAuthorSearchResult.map((faculty, i) =>
                                        (faculty.first_year_career >= yearRange[0] && faculty.first_year_career <= yearRange[1]) &&

                                        <ListItem divider style={{ cursor: "pointer" }} onClick={() => { passSimilarAuthorDetails(faculty) }} >

                                            <ListItemAvatar style={{ paddingRight: 30 }}>
                                                {faculty.profile_picture !== null && faculty.profile_picture !== "" ?
                                                    (<Avatar alt={faculty.first_name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                    :
                                                    (<Avatar style={{ height: 75, width: 75 }}>
                                                        <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                    </Avatar>)
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={faculty.first_name + " " + faculty.last_name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            {faculty.univ_name === null ? "" : faculty.univ_name}
                                                        </Typography>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </Grid>

                        </div>

                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default ShowSimilarAuthor;