import { BACKEND_URL } from '../../utils';

export const send_email = (token, emailData) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'send_email', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "recipient": emailData.recipient,
                "subject": emailData.subject,
                "body": emailData.body
            })
        });
        if (!response) {
            const error = await response.json();
            throw new Error('Someting went wrong while sending email: ', error);
        }
    };
};