import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    authorSearchResult: [],
    authorSearchResultCopy: [],
}

const authorSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_AUTHOR_SEARCH:
            action.searchResult.authors.sort((a, b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0));
            return updateObject(state, { authorSearchResult: action.searchResult.authors, authorSearchResultCopy: action.searchResult.authors, departments: action.searchResult.departments.sort((a, b) => a < b ? -1 : (a > b) ? 1 : 0) });
        case actionTypes.FILTER_ADMIN_AUTHOR_SEARCH:
            let temp = action.result.filter(function (el) {
                let fullName = el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase() + "\\\\";
                return fullName.search(action.value) !== -1;
            });
            return updateObject(state, { authorSearchResult: temp.sort((a, b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0)) });
        case actionTypes.FILTER_AUTHOR_BY_DEPARTMENT:
            function sortByVaraibleName(data,value){
                if (value === 'last_name_inc') {
                    return data.sort((a, b) => (a.last_name > b.last_name) ? 1 : ((b.last_name > a.last_name) ? -1 : 0));
                }
                else if (value === 'last_name_dec') {
                    return data.sort((a, b) => (a.last_name < b.last_name) ? 1 : ((b.last_name < a.last_name) ? -1 : 0));
                }
                else if (value === 'marker_inc') {
                    return data.sort((a, b) => (a.marker > b.marker) ? 1 : ((b.marker > a.marker) ? -1 : 0));
                }
                else if (value === 'marker_dec') {
                    return data.sort((a, b) => (a.marker < b.marker) ? 1 : ((b.marker < a.marker) ? -1 : 0));
                }
                else if (value === 'first_name_dec') {
                    return data.sort((a, b) => (a.first_name < b.first_name) ? 1 : ((b.first_name < a.first_name) ? -1 : 0));
                }
                else {
                    return data.sort((a, b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0));
                }
            }
            if (action.value === 'all') {
                let temp_data = [...action.result]
                return updateObject(state, { authorSearchResult: sortByVaraibleName(temp_data,action.sortBy) })
            }
            else {
                let temp_data = action.result.filter(function (el) {
                    return el.department.toString().toLowerCase().search(action.value) !== -1;
                });
                return updateObject(state, { authorSearchResult: sortByVaraibleName(temp_data,action.sortBy) });
            }
        default:
            return state
    }
}

export default authorSearchReducer;