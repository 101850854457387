import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const getFacultyCardInfoAction = (facultyDetails) => {
    return {
        type: actionTypes.GET_FACULTY_CARD_INFO,
        facultyDetails: facultyDetails,
    }
}

const getFacultyProductivityByAllFacultyAction = (data) => {
    return {
        type: actionTypes.GET_FACULTY_PRODUCTIVITY_BY_ALL_FACULTY,
        facultyList: data
    }
}

const getFacultyProductivityByDeptAction = (data) => {
    return {
        type: actionTypes.GET_FACULTY_PRODUCTIVITY_BY_DEPT,
        deptList: data
    }
}

export const getFacultyProductivity = (token, allFaculty, paperFrom, paperTo, citationsFrom, citationsTo, sortBy, acceptedFaculties, journalCategories) => {
    return async dispatch => {
        const params = {
            "papers_from": paperFrom,
            "papers_to": paperTo,
            "citations_from": citationsFrom,
            "citations_to": citationsTo,
            "accepted_faculty": acceptedFaculties,
            "journal_categories": journalCategories,
            "sort_by": sortBy.toLowerCase(),
            "all_faculty": allFaculty,
        }
        let url = new URL(BACKEND_URL + `dashboard/faculty_productivity`)
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while searching for with error: ' + errorResData);
        }
        const facultyProductivityData = await response.json();
        if (allFaculty === 1) {
            dispatch(getFacultyProductivityByAllFacultyAction(facultyProductivityData));
        }
        else {
            dispatch(getFacultyProductivityByDeptAction(facultyProductivityData));
        }

    };
}

export const getFacultyCardInfo = (token, facultyId) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dashboard/faculty_productivity/details?user_id=${facultyId}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while searching for with error: ' + errorResData);
        }
        const facultyDetails = await response.json();

        dispatch(getFacultyCardInfoAction(facultyDetails));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_FACULTY_PRODUCTIVITY });
    }
}
