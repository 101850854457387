import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    // top50PapersMarketShare: [],
    // top50CitationsMarketShare: [],
    schoolPapersMarketShare: [],
    schoolCitationsMarketShare: [],
    categories: [],
    abbreviation: "",
    max: 0,
    min: 10000
}

const compareDepartmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPARE_DEPARTMENTS:

            let schoolPapersMarketShare = [];
            let schoolCitationsMarketShare = [];
            let categories = [];
            for (let dept of action.compareDepartmentResult.departments) {
                categories.push(dept.department_name);
                schoolPapersMarketShare.push("school_papers_market_share" in dept ? dept.school_papers_market_share : 0);
                schoolCitationsMarketShare.push("school_citations_market_share" in dept ? dept.school_citations_market_share : 0);
            }
            console.log(schoolPapersMarketShare, schoolCitationsMarketShare);
            // let max = Math.max(...schoolPapersMarketShare,...schoolCitationsMarketShare);
            // let min = Math.min(...schoolPapersMarketShare,...schoolCitationsMarketShare);
            // // let min_Citations = Math.min(...schoolCitationsMarketShare);
            // // let max_Citations = Math.max(...schoolCitationsMarketShare);
            // for(let iterator=0;iterator<schoolCitationsMarketShare.length;iterator++)
            // {
            //     schoolCitationsMarketShare[iterator] = ((schoolCitationsMarketShare[iterator]-min)/(max-min)) ;
            // }

            // // let min_marketShare = Math.min(...schoolPapersMarketShare);
            // // let max_marketShare = Math.max(...schoolPapersMarketShare);
            // for(let iterator=0;iterator<schoolPapersMarketShare.length;iterator++)
            // {
            //     schoolPapersMarketShare[iterator] = ((schoolPapersMarketShare[iterator]-min)/(max-min)) ;
            // }


            return updateObject(state, { categories: categories, schoolPapersMarketShare: schoolPapersMarketShare, schoolCitationsMarketShare: schoolCitationsMarketShare, max: 100, abbreviation: action.compareDepartmentResult.abbreviation });
        // let max = Math.max(...top50PapersMarketShare,...top50CitationsMarketShare,...schoolPapersMarketShare,...schoolCitationsMarketShare);
        // return updateObject(state, { categories: categories, top50PapersMarketShare: top50PapersMarketShare, top50CitationsMarketShare: top50CitationsMarketShare, schoolPapersMarketShare: schoolPapersMarketShare, schoolCitationsMarketShare: schoolCitationsMarketShare, max:max, abbreviation: action.compareDepartmentResult.abbreviation});
        case actionTypes.FAILURE:
            return updateObject(state, initialState);
        case actionTypes.CLEAR_COMPARE_DEPARTMENTS:
            return {
                ...state,
                ...initialState
            }
        default:
            return initialState
    }
}

export default compareDepartmentsReducer;