import React, { useState, useEffect } from 'react';
import './RgGsNameMismatchLog.css';
import { useDispatch } from 'react-redux';
import {Container, Row, Col} from 'react-bootstrap';
import { Button } from '@material-ui/core';
import * as logActions from '../../../store/actions/log';
import {OpenInNew} from '@material-ui/icons';
import Basicprofile from '../BasicProfile/BasicProfile';


const RgGsnamemismatchlog = props => {
    const dispatch = useDispatch();
    const [author1, setAuthor1] = useState({});
    const [author2, setAuthor2] = useState({});
    const isMerged = false;
    const author_id = '';
    const log = props.location.state;


    const getAuthorDetails = async (token, author_id, num) => {
        // const response = await fetch(BACKEND_URL + `get_logs?`, {
        //     method:'GET',
        //     headers:{
        //         Authorization: 'Bearer '+ token,
        //     }
        // });
        // if(!response.ok) {
        //     const errorResData = await response.json();
        //     console.log(errorResData)
        //     throw new Error('Something went wrong while fetching logs with error: ' +errorResData);
        // }
        // const author = await response.json();
        const author = {
            "_id": {
                "$oid": "60126e33982ab0990e089683"
            },
            "name": "Young, Steven G.",
            "gs": {
                "our_author_id": {
                    "$oid": "5ffe8d6aa7fab5c78569f9b1"
                },
                "author_id": "kRws8e0AAAAJ",
                "org_id": "8114794062808693116",
                "org_name": "City University of New York"
            },
            "homepage_url": "https://sites.google.com/view/scamplab/home",
            "rg": {
                "our_author_id": {
                    "$oid": "5ffe8d53a7fab5c78569f9af"
                },
                "org_name": "City University of New York - Bernard M. Baruch College",
                "author_id": "Steven_Young13"
            },
            "work_history": [{
                "university": "CUNY Graduate Center",
                "school": "Program in Psychology",
                "position": "Professor (Associate)",
                "start_year": "2015",
                "end_year": "present"
            }],
            "papers": [
                {
                  _id: '5e7406780a334c1741d810de',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'David',
                      id: '5ef0eb3f467ff81a06017592',
                      last_name: 'Wilson',
                      mid_name: 'C.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 143,
                  document_title: 'Engaging the aging workforce: The relationship between perceived age    similarity, satisfaction with coworkers, and employee engagement',
                  issn: '0021-9010',
                  publication_name: 'JOURNAL OF APPLIED PSYCHOLOGY',
                  publication_year: 2007
                },
                {
                  _id: '5e7401030a334c1741d496f6',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 133,
                  document_title: 'Mean racial-ethnic differences in employee sales performance: The    moderating role of diversity climate',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2008
                },
                {
                  _id: '5e7406fd0a334c1741d8672a',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 124,
                  document_title: 'Target practice: An organizational impression management approach to    attracting minority and female job applicants',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2006
                },
                {
                  _id: '5e73f6940a334c1741cdf78c',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 113,
                  document_title: 'A TALE OF TWO CLIMATES: DIVERSITY CLIMATE FROM SUBORDINATES\' AND    MANAGERS\' PERSPECTIVES AND THEIR ROLE IN STORE UNIT SALES PERFORMANCE',
                  e_issn: '1744-6570',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2009
                },
                {
                  _id: '5e73f8b00a334c1741cf4f9b',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'David',
                      id: '5ef0eb3f467ff81a06017592',
                      last_name: 'Wilson',
                      mid_name: 'C.'
                    },
                    {
                      first_name: 'Scott',
                      id: '5ef0f080467ff81a0601ebf0',
                      last_name: 'Tonidandel',
                      mid_name: ''
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 90,
                  document_title: 'Unequal attendance: The relationships between race, organizational    diversity cues, and absenteeism',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2007
                },
                {
                  _id: '5e73f3d40a334c1741cc3a39',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Michael',
                      id: '5ef0ef2e467ff81a0601cecd',
                      last_name: 'McDaniel',
                      mid_name: 'A.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 86,
                  document_title: 'A reexamination of Black-White mean differences in work performance:    More data, more moderators',
                  issn: '0021-9010',
                  publication_name: 'JOURNAL OF APPLIED PSYCHOLOGY',
                  publication_year: 2006
                },
                {
                  _id: '5e73ff100a334c1741d359c0',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Hui',
                      id: '5ef0f074467ff81a0601eadf',
                      last_name: 'Liao',
                      mid_name: ''
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 74,
                  document_title: 'Does Diversity Climate Lead to Customer Satisfaction? It Depends on the    Service Climate and Business Unit Demography',
                  issn: '1047-7039',
                  publication_name: 'ORGANIZATION SCIENCE',
                  publication_year: 2011
                },
                {
                  _id: '5e73ee740a334c1741c8d488',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Douglas',
                      id: '5ef0ef6d467ff81a0601d46b',
                      last_name: 'Kruse',
                      mid_name: ''
                    },
                    {
                      first_name: 'F.',
                      id: '5ef0f92c467ff81a0602b9e1',
                      last_name: 'Bentley',
                      mid_name: 'Scott'
                    },
                    {
                      first_name: 'Lisa',
                      id: '5ef0fa7f467ff81a0602d5ed',
                      last_name: 'Schur',
                      mid_name: ''
                    },
                    {
                      first_name: 'Mason',
                      id: '5f0929251c479b70f63630f9',
                      last_name: 'Ameri',
                      mid_name: ''
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 16,
                  document_title: 'THE DISABILITY EMPLOYMENT PUZZLE: A FIELD EXPERIMENT ON EMPLOYER HIRING    BEHAVIOR',
                  e_issn: '2162-271X',
                  issn: '0019-7939',
                  publication_name: 'ILR REVIEW',
                  publication_year: 2018
                },
                {
                  _id: '5e73fa510a334c1741d05778',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Morela',
                      id: '5ef0eb02467ff81a06016fde',
                      last_name: 'Hernandez',
                      mid_name: ''
                    },
                    {
                      first_name: 'Sabrina',
                      id: '5ef0ed50467ff81a0601a58d',
                      last_name: 'Volpone',
                      mid_name: 'D.'
                    },
                    {
                      first_name: 'Robert',
                      id: '5ef0eda1467ff81a0601ad26',
                      last_name: 'Stewart',
                      mid_name: 'W.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Mikki',
                      id: '5ef10893467ff81a0603f9a2',
                      last_name: 'Hebl',
                      mid_name: ''
                    },
                    {
                      first_name: 'Aleksandra',
                      id: '5ef10ca5467ff81a0604317e',
                      last_name: 'Luksyte',
                      mid_name: ''
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 23,
                  document_title: 'Examining the Draw of Diversity: How Diversity Climate Perceptions    Affect Job-Pursuit Intentions',
                  e_issn: '1099-050X',
                  issn: '0090-4848',
                  publication_name: 'HUMAN RESOURCE MANAGEMENT',
                  publication_year: 2013
                },
                {
                  _id: '5e73ebec0a334c1741c73f83',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'David',
                      id: '5ef0eb3f467ff81a06017592',
                      last_name: 'Wilson',
                      mid_name: 'C.'
                    },
                    {
                      first_name: 'Sabrina',
                      id: '5ef0ed50467ff81a0601a58d',
                      last_name: 'Volpone',
                      mid_name: 'D.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 18,
                  document_title: 'DOES VOICE GO FLAT? HOW TENURE DIMINISHES THE IMPACT OF VOICE',
                  e_issn: '1099-050X',
                  issn: '0090-4848',
                  publication_name: 'HUMAN RESOURCE MANAGEMENT',
                  publication_year: 2011
                },
                {
                  _id: '5e73ebd80a334c1741c73332',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Sabrina',
                      id: '5ef0ed50467ff81a0601a58d',
                      last_name: 'Volpone',
                      mid_name: 'D.'
                    },
                    {
                      first_name: 'Scott',
                      id: '5ef0f080467ff81a0601ebf0',
                      last_name: 'Tonidandel',
                      mid_name: ''
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 25,
                  document_title: 'IS THERE METHOD TO THE MADNESS? EXAMINING HOW RACIOETHNIC MATCHING    INFLUENCES RETAIL STORE PRODUCTIVITY',
                  e_issn: '1744-6570',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2012
                },
                {
                  _id: '5e73feac0a334c1741d3191b',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'David',
                      id: '5ef0eb3f467ff81a06017592',
                      last_name: 'Wilson',
                      mid_name: 'C.'
                    },
                    {
                      first_name: 'Sabrina',
                      id: '5ef0ed50467ff81a0601a58d',
                      last_name: 'Volpone',
                      mid_name: 'D.'
                    },
                    {
                      first_name: 'Ying',
                      id: '5ef0f5f8467ff81a06026e8e',
                      last_name: 'Hong',
                      mid_name: ''
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Kaifeng',
                      id: '5ef0f834467ff81a0602a471',
                      last_name: 'Jiang',
                      mid_name: ''
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 14,
                  document_title: 'RETAINING EMPLOYEES THROUGH ANTI-SEXUAL HARASSMENT PRACTICES: EXPLORING    THE MEDIATING ROLE OF PSYCHOLOGICAL DISTRESS AND EMPLOYEE ENGAGEMENT',
                  e_issn: '1099-050X',
                  issn: '0090-4848',
                  publication_name: 'HUMAN RESOURCE MANAGEMENT',
                  publication_year: 2015
                },
                {
                  _id: '5e73fdc00a334c1741d2854e',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Scott',
                      id: '5ef0f080467ff81a0601ebf0',
                      last_name: 'Tonidandel',
                      mid_name: ''
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 14,
                  document_title: 'An alternative explanation for the asymmetrical effects in relational    demography research',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2008
                },
                {
                  _id: '5e73ebc50a334c1741c72823',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Emily',
                      id: '5ef0f907467ff81a0602b677',
                      last_name: 'Hunter',
                      mid_name: 'M.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 11,
                  document_title: 'Demography and disappearing merchandise: How older workforces influence    retail shrinkage',
                  e_issn: '1099-1379',
                  issn: '0894-3796',
                  publication_name: 'JOURNAL OF ORGANIZATIONAL BEHAVIOR',
                  publication_year: 2012
                },
                {
                  _id: '5e73fba30a334c1741d12e1d',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'David',
                      id: '5ef0eb3f467ff81a06017592',
                      last_name: 'Wilson',
                      mid_name: 'C.'
                    },
                    {
                      first_name: 'L.',
                      id: '5ef0ef60467ff81a0601d342',
                      last_name: 'Witt',
                      mid_name: 'A.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Daniel',
                      id: '5ef0f7de467ff81a06029c34',
                      last_name: 'McDonald',
                      mid_name: 'P.'
                    },
                    {
                      first_name: 'Brenda',
                      id: '5ef1036a467ff81a0603a926',
                      last_name: 'Moore',
                      mid_name: 'L.'
                    },
                    {
                      first_name: 'Cristina',
                      id: '5ef1184a467ff81a0604d35b',
                      last_name: 'Rubino',
                      mid_name: ''
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 4,
                  document_title: 'And justice for all: How organizational justice climate deters sexual    harassment',
                  e_issn: '1744-6570',
                  issn: '0031-5826',
                  publication_name: 'PERSONNEL PSYCHOLOGY',
                  publication_year: 2018
                },
                {
                  _id: '5e73f4150a334c1741cc640d',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Sabrina',
                      id: '5ef0ed50467ff81a0601a58d',
                      last_name: 'Volpone',
                      mid_name: 'D.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Ari',
                      id: '5f0922f61c479b70f635fc0a',
                      last_name: 'Malka',
                      mid_name: ''
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 5,
                  document_title: 'Are companies beholden to bias? The impact of leader race on consumer    purchasing behavior',
                  e_issn: '1095-9920',
                  issn: '0749-5978',
                  publication_name: 'ORGANIZATIONAL BEHAVIOR AND HUMAN DECISION PROCESSES',
                  publication_year: 2015
                },
                {
                  _id: '5e73f76c0a334c1741ce7ed3',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Sabrina',
                      id: '5ef0ed50467ff81a0601a58d',
                      last_name: 'Volpone',
                      mid_name: 'D.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 3,
                  document_title: 'Blaming the Building: How Venue Quality Influences Consumer Bias Against    Stigmatized Leaders',
                  e_issn: '1939-1854',
                  issn: '0021-9010',
                  publication_name: 'JOURNAL OF APPLIED PSYCHOLOGY',
                  publication_year: 2016
                },
                {
                  _id: '5e73ef0b0a334c1741c933be',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'L.',
                      id: '5ef0ef60467ff81a0601d342',
                      last_name: 'Witt',
                      mid_name: 'A.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Emily',
                      id: '5ef0fe01467ff81a06032751',
                      last_name: 'David',
                      mid_name: 'M.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 4,
                  document_title: 'A time-lagged investigation of the impact of coworker behavior on the    effects of demographic dissimilarity',
                  e_issn: '1099-1379',
                  issn: '0894-3796',
                  publication_name: 'JOURNAL OF ORGANIZATIONAL BEHAVIOR',
                  publication_year: 2015
                },
                {
                  _id: '5e73f3580a334c1741cbecfe',
                  authors: [
                    {
                      first_name: 'Patrick',
                      id: '5ef0eab9467ff81a06016911',
                      last_name: 'McKay',
                      mid_name: 'F.'
                    },
                    {
                      first_name: 'Derek',
                      id: '5ef0f60a467ff81a0602703b',
                      last_name: 'Avery',
                      mid_name: 'R.'
                    },
                    {
                      first_name: 'Erika',
                      id: '5ef0f85a467ff81a0602a796',
                      last_name: 'Hall',
                      mid_name: 'V.'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 0,
                  document_title: 'Composition and Compensation: The Moderating Effect of Individual and    Team Performance on the Relationship Between Black Team Member    Representation and Salary',
                  e_issn: '1939-1854',
                  issn: '0021-9010',
                  publication_name: 'JOURNAL OF APPLIED PSYCHOLOGY',
                  publication_year: 2019
                },
                {
                  _id: '5e73ebc50a334c1741c72786',
                  authors: [
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 4,
                  document_title: 'Spaontaneous Deregulation',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2016
                },
                {
                  _id: '5e73ebba0a334c1741c720b9',
                  authors: [
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 0,
                  document_title: 'MARKETING HOW TO MAKE THE MOST OF OMNICHANNEL RETAILING',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2016
                },
                {
                  _id: '5e73ecf60a334c1741c7e472',
                  authors: [
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 0,
                  document_title: 'CASE STUDY FOLLOW DUBIOUS ORDERS OR SPEAK UP?',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2017
                },
                {
                  _id: '5e73ec800a334c1741c79b85',
                  authors: [
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 4,
                  document_title: 'Are CIOs obsolete? - Introduction',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2000
                },
                {
                  _id: '5e73ebbf0a334c1741c72402',
                  authors: [
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 0,
                  document_title: 'WHY EVERY ORGANIZATION NEEDS AN AUGMENTED REALITY STRATEGY',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2018
                },
                {
                  _id: '5e7406740a334c1741d80e83',
                  authors: [
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 0,
                  document_title: 'A modest manifestor for shattering the glass ceiling',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2000
                },
                {
                  _id: '5e73ec1a0a334c1741c75b27',
                  authors: [
                    {
                      first_name: 'Punita',
                      id: '5ef0ed66467ff81a0601a790',
                      last_name: 'Bhatt',
                      mid_name: ''
                    },
                    {
                      first_name: 'Muhammad',
                      id: '5ef11649467ff81a0604b76d',
                      last_name: 'Roomi',
                      mid_name: 'Azam'
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    },
                    {
                      first_name: 'Deval',
                      id: '5fa3467afde7386441fb0d4a',
                      last_name: 'Vora',
                      mid_name: 'P'
                    }
                  ],
                  citation_count: 11,
                  document_title: 'Social innovation with open source software: User engagement and    development challenges in India',
                  e_issn: '1879-2383',
                  issn: '0166-4972',
                  publication_name: 'TECHNOVATION',
                  publication_year: 2016
                },
                {
                  _id: '5e73ebae0a334c1741c7192e',
                  authors: [
                    {
                      first_name: 'Frederic',
                      id: '5ef0ec15467ff81a06018942',
                      last_name: 'Godart',
                      mid_name: ''
                    },
                    {
                      first_name: 'Andrew',
                      id: '5ef10b99467ff81a0604233d',
                      last_name: 'Shipilov',
                      mid_name: ''
                    },
                    {
                      first_name: 'Himanshi',
                      id: '5f308cafc40a2d61b28bea12',
                      last_name: 'Alhawatsss',
                      mid_name: 'abc'
                    }
                  ],
                  citation_count: 2,
                  document_title: 'Luxury\'s Talent Factories',
                  issn: '0017-8012',
                  publication_name: 'HARVARD BUSINESS REVIEW',
                  publication_year: 2015
                }
              ],
            "name_variations": ["Steven G. Young", "Young, Steven G.", "Young, Steve G."],
            "email_list": ["steven.young@baruch.cuny.edu"],
            "start_author_id": {
                "$oid": "5e76ea8a905409583fcb3aeb"
            }
        }
        if (num === 1){
            setAuthor1(author);
        }
        else {
            setAuthor2(author);
        }
    }

    useEffect(() => {
        console.log("Called RG_GS_NAME UseEffect hook");
        //fetch profile from data._id
        const token = localStorage.getItem("rl_token");
        //TODO: Need to change it to .author_details_id
        getAuthorDetails(token, log.rg_detail_id, 1);
        getAuthorDetails(token, log.gs_detail_id, 2);

    },[]);

    const onMergeAndMapClick = async () => {
        goBack();
    }

    const onMergeClick = async(event, author_id) => {
        goBack();
    }

    const onResolveClick = async (removedNameVariations, removedPapers) => {
        const token = localStorage.getItem("rl_token");
        await dispatch(logActions.resolveRgGsNameMismatchAfterMerge(token, log, author_id,removedPapers, removedNameVariations));  
        goBack();
    }

    const goBack = () => {
        console.log("GO BACK");
        props.history.replace({ 
            pathname: "/logs",
        });
    }

    const generateGSLinkFromId  = (gs_id) => {
        return "https://scholar.google.com/citations?user="+gs_id;
    }

    const generateRGLinkFromId  = (rg_id) => {
        return "https://www.researchgate.net/profile/"+rg_id
    }
    console.log(log)
    return (
        <div>
            <span className="profile">
                        {"RG_GS_NAME_MISMATCH"}
            </span>
            <div style = {{ backgroundColor: "#fff", borderRadius: "2px", boxShadow: "0 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.22)", transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", margin: "1rem", zIndex: "-10", padding: "1rem"}}>
                <p>To resolve this log please have a look at the name variations and check if they are correct. Also have a look at papers and see if they all belong to the same author. If not then remove them. Only click <b>RESOLVE</b> when you are sure you have made all the necessary changes to the record.</p>
            </div>
            <div style = {{ backgroundColor: "#fff", borderRadius: "2px", boxShadow: "0 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.22)", transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", margin: "1rem", zIndex: "-10", padding: "1rem"}}>
                <h5>{log.paper.document_title}</h5>
                <div style={{display:"flex"}}>
                    <div style={{flex:1}}>
                        <p><b>Name on paper: </b>{log.author_name}</p>
                    </div>
                </div>
                <p>If both the records are of same author and the paper is to be mapped to that person then click below</p>
                <div>
                  <Button className="buttons1" style={{marginLeft:"40%"}} variant="contained" onClick={()=>onMergeAndMapClick()}>
                    Merge and Map
                  </Button>
                </div>

                {/* <div style={{display:"flex"}}>
                    <div style={{flex:1}}>
                        <p>{"Does this paper belong to " + log.already_name + " with profile shown below?"}</p>
                    </div>
                </div> */}

                {/* <Container>
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={(event)=>onButtonClick(event, 1)}>
                        YES
                    </Button>
                  </Col>
                  <Col md={1}></Col>
                  <Col md = {3}>
                    <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={(event)=>onButtonClick(event, 0)}>
                        NO
                    </Button>
                  </Col>
                </Row> 
              </Container> */}
            </div>
            {
                  isMerged === false ? 
                  <div className="profile-container">
                      {/* for Name And Links */}
                    <div className = "two-profile-container">
                        {
                            [author1, author2].map((author, index) => {
                                return <div key ={author._id} className="individual-profile">
                                <Container>
                                <Row>
                                    <Col>
                                        <Row className="profile-header">
                                            <Col><h3> {author.name}</h3></Col>
                                        </Row>
                                        <Row className="profile-link">
                                        {
                                            author.homepage_url && author.homepage_url!==null ?
                                            <Col sm={3} md={3}><a href={author.homepage_url} target="_blank" rel="noopener noreferrer">Homepage <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                            : null
                                        }
                                        {
                                            author.gs ? 
                                                <Col sm={4} md={4}><a href ={generateGSLinkFromId(author.gs.author_id)} target="_blank" rel="noopener noreferrer">Google Scholar <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                            : null
                                        }
                                        {
                                            author.rg ? 
                                            <Col sm={4} md={4}><a href ={generateRGLinkFromId(author.rg.author_id)} target="_blank" rel="noopener noreferrer">Researchgate <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                            : <Col sm={4} md={4}></Col>
                                        }
                                        </Row> 
                                    </Col>
                                </Row>
                                <hr></hr>
                                </Container>
                                
                                </div>
                            })
                        }
                    </div>
                        {/* for Name variations*/}
                    <div className = "two-profile-container">
                        {
                            [author1, author2].map((author, i) => {
                                return <div key ={author._id} className="individual-profile">
                                <Container>
                                    <Row className="profile-header">
                                        <Col sm={11} md={11}><h4>Name Variations</h4></Col>
                                    </Row>
                                    {
                                        author.name_variations && author.name_variations.map((name, index)=>{
                                            return (
                                                [
                                                    <Row key={index}>
                                                        <Col md={12}>{name}</Col>
                                                    </Row>
                                                ]
                                            );
                                        })
                                    }
                                </Container>
                                </div>
                            })
                        }
                    </div>
                    <div className = "two-profile-container" style={{paddingBottom:"0px"}}>
                    {
                            [author1, author2].map((author, i) => {
                                return <div key ={author._id} className="individual-profile">
                                <Container>
                                    <hr></hr>
                                </Container>
                                </div>
                            })
                        }
                    </div>
                        {/* for Papers */}
                    <div className = "two-profile-container">
                        {
                            [author1, author2].map((author, i) => {
                                return <div key ={author._id} className="individual-profile">
                                <Container>
                                <Row lassName="profile-header">
                                    <Col><h4>Papers</h4></Col>
                                </Row>
                                {
                                    author.papers && author.papers.sort((a,b) => (a.publication_year > b.publication_year) ? -1 : ((b.publication_year > a.publication_year) ? 1 : 0)).map((item,index)=>{
                                    return (
                                        [
                                        <Row key={index} className={index===0?"subheaderFirst": "subheader"} > 
                                            <Col md={12}>
                                                <span className="standardSize">
                                                {index+1+"."}
                                                {/* &nbsp;&nbsp;
                                                {
                                                    (!item.authors)? null:
                                                    item.authors.map((author,i)=>{
                                                    return (
                                                    <span key={i}>
                                                        <span className="standardSizeUnderline">
                                                            {(author.first_name!==undefined && author.first_name.trim()!=="" ? author.first_name+(((author.mid_name!==undefined && author.mid_name.trim()!=="") || (author.last_name!==undefined && author.last_name.trim()!=="")) ? " " : "") : "")+(author.mid_name!==undefined && author.mid_name.trim()!=="" ? author.mid_name+((author.last_name!==undefined && author.last_name.trim()!=="")? " ": ""): "")+ 
                                                            (author.last_name!==undefined && author.last_name.trim()!=="" ? author.last_name+"": "")}
                                                        </span>
                                                        <span className="standardSize">
                                                        {(i===(item.authors.length-1) ? " ": (i==(item.authors.length-2) ? "; and ": "; ")   )}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                        </span>
                                                    </span>
                                                    )
                                                    })
                                                } */}
                                                &nbsp;&nbsp;
                                                {item.document_title
                                                +
                                                ((item.document_title!==undefined && item.document_title.trim()!=="" && item.document_title.slice(-1)!=='?' && item.document_title.slice(-1)!=='!')?
                                                "."
                                                :
                                                "")}
                                                </span>
                                                <span className="italics">
                                                &nbsp;&nbsp;
                                                    {item.publication_name}
                                                    </span>
                                                    <span className="standardSize">
                                                    &nbsp; &nbsp;&nbsp;
                                                    {"("+item.publication_year+")"}
                                                </span>
                                            </Col>
                                        </Row>,
                                        <Row>
                                            <Col md={10}>
                                                
                                            </Col>
                                        </Row>
                                        ]
                                    )
                                    })
                                }
                                <hr></hr>
                                </Container>
                                
                                </div>
                            })
                        }
                    </div>
                        {/* for buttons */}
                    <div className = "two-profile-container">
                        {
                            [author1, author2].map((author, i) => {
                                return <div key ={author._id} className="individual-profile">
                                <Container>
                                    <Row>
                                    <Col md={4}></Col>
                                    <Col md={3}>
                                        <Button className="buttons1" style={{ width: "145px" }} variant="contained" onClick={(event)=>onMergeClick(event, author._id)}>
                                            Map
                                        </Button>
                                    </Col>
                                    </Row> 
                                </Container>
                                </div>
                            })
                        }
                    </div>
                    </div>
                  :
                  <Basicprofile data={log} showEditOption={true} showNameVariations={true} onResolve={onResolveClick} onCancel={goBack}/>
              }
        </div>
    )
}

export default RgGsnamemismatchlog;
