import React from 'react';
import classes2 from './redflags.module.css';
import { Button, Container, Row, Col } from 'react-bootstrap';
import {BACKEND_URL} from '../../../../utils';
import Loading from '../../../../assets/Images/loading.gif';

import axios from 'axios';

const AuthorsWithoutEducationField = props =>{
    const [showSpinner, setShowSpinner] = React.useState(false);
    const handleDownload =()=>{
        setShowSpinner(true)
         const url = BACKEND_URL +'red_flag/authors_without_education_field?download=1';
        (async () => {
            axios({
              url: url,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'authorsWithoutEducationField.csv');
              document.body.appendChild(link);
              link.click();
              setShowSpinner(false)
            });
          })();
    }
  
 
    return (
        <div>
          <div className={classes2.formCard}>
          <Container >
            <Row md={12} className={classes2.row}>
            <Col md={4}>
            </Col>
                <Col md={4}>
            <Button variant="contained" className="searchButton" color="primary" onClick={(e)=>handleDownload()}>Click to Download</Button>
            </Col> 
            <Col md={4}>
            </Col>
        </Row>
        </Container>
          </div>
          {
              showSpinner===true &&
              <div style={{ textAlign: "center" }}>
                <img src={Loading} alt="Loading..." width="80px" height="80px" />
              </div>
          }
        </div>
    )
}

export default AuthorsWithoutEducationField;