import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import classes from './WorkHistory.module.css';
import profileClassses from '../Profile.module.css';
import AddWorkHistoryModal from './AddWorkHistoryModal';
import NoteModal from './NoteModal';
import Profile from '../../../../../model/profile';
import * as profileActions from '../../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../../store/actions/coauthorprofile';
import uniqueId from 'uniqid';
import './WorkHistory.module.css';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import WorkIcon from '@material-ui/icons/Work';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { BACKEND_URL } from '../../../../../utils';
import AddUniversityWorkHistoryModal from './AddUniversityWorkHistoryModal';
import { Grid, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const titleMapping = {
    'Assistant Professor (tenure-track)': {
        'title': 'Assistant Professor',
        'marker': 1,
        'sortOrder': 3,
    },
    'Associate Professor (tenured)': {
        'title': 'Associate Professor',
        'marker': 2,
        'sortOrder': 2,
    },
    'Full Professor (tenured)': {
        'title': 'Professor',
        'marker': 3,
        'sortOrder': 1,
    },
    'Teaching Professor(tenured or non-tenured track)': {
        'title': '',
        'marker': 6,
        'sortOrder': 4,
    },
    'Emeritus': {
        'title': 'Emeritus',
        'marker': 4,
        'sortOrder': 5,
    },
    'Researcher/Visiting/Post-doc/Affiliate': {
        'title': '',
        'marker': 5,
        'sortOrder': 7,
    },
    'Named Chair': {
        'title': '',
        'marker': 7,
        'sortOrder': 6,
    },
    'Administrative Position': {
        'title': '',
        'marker': 9,
        'sortOrder': 8,
    },
}

export const titlePriorityLowToHigh = Object.values(titleMapping)
    .filter(val => val.title !== '')
    .sort((a, b) => a.sortOrder > b.sortOrder ? -1 : 1)
    .map(val => val.title);
export const titlesWithSubTitle = Object.keys(titleMapping).filter(key => titleMapping[key].title === '');
export const markersOfSubTitle = Object.values(titleMapping).filter(val => val.title === '').map(val => val.marker);
export const markerPriorityHighToLow = Object.values(titleMapping).sort((a, b) => a.sortOrder - b.sortOrder).map(obj => obj.marker);
const itemPositions = {};
for (const [index, id] of markerPriorityHighToLow.entries()) {
    itemPositions[id] = index;
}
export const markersForChair = [7, 70];
export const markersForAdministrative = [9, 90];
const markersForProfPos = [1, 2, 3, 10, 20, 30];

const WorkHistory = props => {
    const token = useSelector(state => state.auth.token);
    const addColor = "#DADADA"
    const editColor = "#DADADA"
    const primaryColor = '#555559';
    const [isEditing, setIsEditing] = useState(false);
    const [workHistory, setWorkHistory] = useState(props.data.work_history_manual);
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [chairInvalidModalShow, setChairInvalidModalShow] = useState(false);
    const [updateDeptNoteModalShow, setUpdateDeptNoteModalShow] = useState(false);
    const [UnivnoteModalShow, setUnivnoteModalShow] = useState(false);
    const [curWork, setCurWork] = useState({ rank: 1, isNew: true, id: 0, title: "", school: "", institution: "", from: "", to: "", subTitle: "" });
    const userType = useSelector(state => state.auth.user_type);
    const [universities, setUniversities] = useState([]);
    const [addMoreUnivModal, setaddMoreUnivModal] = useState(false)
    const [accordionExpand, setAccordionExpand] = React.useState(true);

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    let updatePresentWork = false;
    let updatePresentToPastWork = false;
    let newUnivId = "";
    let newUnivName = "";
    let newMarker = "";
    let newPosition = "";
    const rlHeadQuatersUnivId = "611dacca49cdbfa1daed1277";

    const addMoreUniv = () => {
        setModalShow(false)
        setaddMoreUnivModal(true)
    };

    const dispatch = useDispatch();

    const hideAddMoreUniversityModal = (status) => {
        setaddMoreUnivModal(false);
        if (status === "Success")
            setUnivnoteModalShow(true);
    };
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const noUpdateNeeded = () => {
        return updatePresentWork === false && updatePresentToPastWork === false
    };

    const doesUniversityUpdate = () => {
        return !noUpdateNeeded() && props.data.univ_id !== newUnivId;
    }

    const createUpdatedProfile = (workhis) => {
        return Object.assign(new Profile(), {
            _id: props.data._id,
            position: noUpdateNeeded() ? props.data.position : newPosition,
            marker: noUpdateNeeded() ? props.data.marker : newMarker,
            work_history_manual: workhis,
            univ_id: noUpdateNeeded() ? props.data.univ_id : newUnivId === undefined ? rlHeadQuatersUnivId : newUnivId,
            univ_name: noUpdateNeeded() ? props.data.univ_name : newUnivName,
            department: doesUniversityUpdate() ? [] : props.data.department,
            percent_appointment: doesUniversityUpdate() ? {} : props.data.percent_appointment,
        })
    };

    const editHandler = async (workhis) => {
        try {
            if (props.isCoAuthor === true) {
                await dispatch(coAuthorProfileActions.update_co_author_profile(token, createUpdatedProfile(workhis), props.data._id));
            } else {
                await dispatch(profileActions.update_author_profile(token, createUpdatedProfile(workhis)));
            }
        }
        catch (error) {
            alert("Failed to Update! Please Refresh the page and try again.");
        }
        setIsEditing(!isEditing);
    };

    const assignID = () => {
        var largestUsedId = 0
        for (var i = 0; i < workHistory.length; i++) {
            largestUsedId = Math.max(largestUsedId, workHistory[i].id)
        }
        return (largestUsedId + 1)
    };

    const assignRank = () => {
        var rank = 1;
        for (var i = 0; i < workHistory.length; i++) {
            if (workHistory[i].title === "Assistant Professor") {
                rank = Math.max(rank, 2);
            } else if (workHistory[i].title === "Associate Professor") {
                rank = Math.max(rank, 3);
            }
        }
        return rank;
    };

    const showModal = () => {
        setModalShow(true);
    };

    const getPresentTitlesIndexes = (workArray, markerByPriority) => {
        return workArray.filter(work => work.to === "Present").map(work => markerByPriority.indexOf(work.marker));
    };

    const updateNewValues = (work) => {
        [newPosition, newMarker, newUnivName] = [work.title, work.marker, work.institution];
        newUnivId = universities.filter(item => item.name === newUnivName).map(item => item._id)[0];
    };

    const checkIfValidChair = (newWorkHist, updatedWork) => {
        let isValidChair = false;
        newWorkHist.some(work => {
            if (markersForProfPos.includes(work.marker)) {
                if (work.from <= updatedWork.from && String(work.to) >= String(updatedWork.to)) {
                    isValidChair = true
                    return true
                }
            }
        });
        if (!isValidChair)
            setChairInvalidModalShow(true);
    };

    const addWorkHistory = (workhist) => {
        setAccordionExpand(true);
        setIsEditing(true);
        updatePresentWork = workhist.to === "Present";
        let presentTitlesIndexes = [];
        let noteModalWillBeShown = false;
        let updateDeptNoteModalWillBeShown = false;
        if (updatePresentWork === true) {
            presentTitlesIndexes = getPresentTitlesIndexes(workHistory, markerPriorityHighToLow);
            if (presentTitlesIndexes.length === 0 ||
                (markerPriorityHighToLow.indexOf(workhist.marker) < Math.min(...presentTitlesIndexes)) ||
                ((markerPriorityHighToLow.indexOf(workhist.marker) === Math.min(...presentTitlesIndexes))
                    && (workhist.title !== props.data.position || workhist.marker !== props.data.marker || workhist.institution !== props.data.univ_name))) {
                updateNewValues(workhist);
            }
            else {
                updatePresentWork = false;
            }
        }
        delete workhist.univId;
        if (workhist.isNew === true) {
            if (workhist.to === "Present" && workHistory.filter(work => work.institution === workhist.institution && work.to === "Present").length !== 0) {
                setNoteModalShow(true);
            }
            const updatedWorkHistory = [workHistory.length + 1]
            for (var i = 0; i < workHistory.length; i++) {
                updatedWorkHistory[i] = { ...workHistory[i] }
            }
            updatedWorkHistory[workHistory.length] = workhist;
            if (markersForChair.includes(workhist.marker))
                checkIfValidChair(updatedWorkHistory, workhist)
            setWorkHistory(updatedWorkHistory);
            setModalShow(false);
            editHandler(updatedWorkHistory);
        } else {
            const updatedWorkHistory = [workHistory.length]
            for (var i = 0; i < workHistory.length; i++) {
                if (workHistory[i].id === workhist.id) {
                    updatePresentToPastWork = workHistory[i].to === "Present" && typeof workhist.to === "number";
                    if (updatePresentToPastWork === true) {
                        const positionMatch = workhist.title === props.data.position;
                        if (positionMatch === true && workhist.institution === props.data.univ_name) {
                            presentTitlesIndexes = getPresentTitlesIndexes(workHistory, markerPriorityHighToLow);
                            if (presentTitlesIndexes.length === 1) {
                                newMarker = workhist.marker;
                                [newPosition, newUnivName, newUnivId] = [props.data.position, props.data.univ_name, props.data.univ_id];
                            } else {
                                const workHistoryWithoutCurrent = workHistory.filter((item) => item.id !== workhist.id);
                                presentTitlesIndexes = getPresentTitlesIndexes(workHistoryWithoutCurrent, markerPriorityHighToLow);
                                const highestPriorityPresentWork = presentTitlesIndexes.indexOf(Math.min(...presentTitlesIndexes));
                                updateNewValues(workHistoryWithoutCurrent[highestPriorityPresentWork]);
                            }
                        } else {
                            updatePresentToPastWork = false;
                        }
                    }
                    updatedWorkHistory[i] = workhist;
                } else {
                    updatedWorkHistory[i] = { ...workHistory[i] }
                    if (workHistory[i].to === "Present" && workhist.to === "Present" && workHistory[i].institution === workhist.institution) {
                        setNoteModalShow(true);
                        noteModalWillBeShown = true;
                    }
                }
            }
            if (markersForChair.includes(workhist.marker))
                checkIfValidChair(updatedWorkHistory, workhist)
            if (newUnivId !== "" && props.data.univ_id !== newUnivId) {
                setUpdateDeptNoteModalShow(true);
                updateDeptNoteModalWillBeShown = true;
            }
            setWorkHistory(updatedWorkHistory);
            setEditModalShow(false)
            editHandler(updatedWorkHistory);
        }
        if ((updatePresentToPastWork === true || updatePresentWork === true) && noteModalWillBeShown === false && updateDeptNoteModalWillBeShown === false) {
            props.refreshProfile()
        }
    };

    const showEditModal = () => {
        setEditModalShow(true);
    };

    const setModalandsetWorkHistory = () => {
        setNewWorkHistory()
        showModal()
    };

    const setNewWorkHistory = () => {
        var curID = assignID()
        var rank = assignRank()
        setCurWork({ ...curWork, rank: rank, title: "", school: "", institution: "", from: "", to: "", subTitle: "", isNew: true, id: curID, marker: "" })
    };

    const editWorkItems = (id) => {
        var curWork = {}
        var rank = 1;
        for (var i = 0; i < workHistory.length; i++) {
            if (workHistory[i].id === id) {
                curWork = { ...workHistory[i], isNew: false }
            } else {
                if (workHistory[i].title === "Assistant Professor") {
                    rank = Math.max(rank, 2);
                } else if (workHistory[i].title === "Associate Professor") {
                    rank = Math.max(rank, 3);
                }
            }
        }
        setCurWork({ ...curWork, rank: rank })
        showEditModal()
    };

    const hideWork = (curID) => {
        setModalShow(false);
        setAccordionExpand(true);
    };

    const hideWorkEdit = (curID) => {
        setEditModalShow(false)
        const updatedWorkHistory = workHistory.filter((item) => item.id !== curID);
        setWorkHistory(updatedWorkHistory);
        editHandler(updatedWorkHistory);
    };

    const hideNoteModal = () => {
        setNoteModalShow(false);
        props.refreshProfile()
    };

    const hideChairInvalidModal = () => {
        setChairInvalidModalShow(false);
    }
    const hideUnivNoteModal = () => {
        setUnivnoteModalShow(false);
    };

    const hideUpdateDeptNoteModalShow = () => {
        setUpdateDeptNoteModalShow(false);
        props.refreshProfile()
    }

    useEffect(() => {
        (async () => {
            await fetch(BACKEND_URL + 'get_universities/all', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }).then(async (response) => {
                const univs = await response.json();
                setUniversities(univs);
            })
        })();
    }, []);

    if (workHistory == null || (workHistory.length === 0 && !props.data.verified)) {
        return (
            <Container className={classes.container}>
                <Row className={classes.header}>
                    <Col sm={11} md={11}><h3>Work Experience</h3></Col>
                    <Col sm={1} md={1}>
                        {profileUpdateState === true ?
                            <span style={{ cursor: "pointer" }}>
                                <LightTooltip title="Click to add Work." placement="bottom">
                                    <AddCircleIcon style={{ width: '1.2em', height: '1.2em', color: addColor }} onClick={setModalandsetWorkHistory}> </AddCircleIcon>
                                </LightTooltip>
                            </span>
                            : null}
                    </Col>
                </Row>
                <AddWorkHistoryModal curWork={curWork} show={modalShow} onSave={addWorkHistory} onHide={() => setModalShow(false)} />
            </Container>
        );
    }

    return (
        <Grid container xs={20} alignItems="center" justify="center" className={profileClassses.grid}>
            <Accordion square className={profileClassses.accordian} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary className={profileClassses.accordian_summary}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Row className={classes.header}>
                        <Col sm={11} md={11}><h3 style={{ marginTop: "10px" }}>Work Experience</h3></Col>
                        <Col sm={1} md={1}>
                            {
                                profileUpdateState === true ?
                                    <span style={{ cursor: "pointer" }}>
                                        <LightTooltip title="Click to add Work." placement="bottom">
                                            <AddCircleIcon style={{ width: '1.2em', height: '1.2em', color: addColor, marginLeft: "371.5px", marginTop: "9px", }} onClick={setModalandsetWorkHistory}> </AddCircleIcon>
                                        </LightTooltip>
                                    </span>
                                    : null
                            }
                        </Col>
                    </Row>
                </AccordionSummary>
                <Container className={classes.container}>


                    {
                        workHistory.sort((a, b) =>
                            String(b.to).localeCompare(String(a.to)) || b.from - a.from
                            || itemPositions[parseInt(String(a.marker)[0])] - itemPositions[parseInt(String(b.marker)[0])]
                        ).map((item, index) => {
                            return (
                                [
                                    <Row key={uniqueId()} className={[classes.historyRow, profileClassses.subheaderFirst]}>
                                        <Col md={6} className={classes.subheader}>
                                            <span className={classes.subHeading}>
                                                {markersForChair.includes(item.marker) ? [<EventSeatIcon style={{ color: primaryColor }} />, <span>&nbsp;&nbsp;</span>] : null}
                                                {markersForAdministrative.includes(item.marker) ? [<WorkIcon style={{ color: primaryColor }} />, <span>&nbsp;&nbsp;</span>] : null}
                                                {
                                                    (markersOfSubTitle.includes(parseInt(String(item.marker)[0])) && item.subTitle !== "" ? item.subTitle : item.title) +
                                                    (userType === "admin" ? " - Marker " + item.marker : "")
                                                }
                                            </span>
                                        </Col>
                                        <Col md={3}></Col>
                                        <Col md={2}>
                                            {item.from + " - " + item.to}
                                        </Col>
                                        <Col md={1}>
                                            {
                                                profileUpdateState === true ?
                                                    <span style={{ cursor: "pointer" }}>
                                                        <LightTooltip title="Click to edit" placement="bottom">
                                                            <EditIcon style={{ width: '1em', height: '1em', color: editColor }} onClick={() => editWorkItems(workHistory[index].id)} ></EditIcon>
                                                        </LightTooltip>
                                                    </span>
                                                    : null
                                            }
                                        </Col>
                                    </Row>,
                                    <Row className={classes.bottomClass}>
                                        <Col className={classes.standardSize} md={11}>
                                            <span> {(item.school !== undefined && item.school !== null && item.school !== "" ? (item.school + ", ") : "")} </span>
                                            <span > {item.institution} </span>
                                        </Col>
                                    </Row>
                                ]
                            )
                        })
                    }
                    <AddWorkHistoryModal
                        data={props.data}
                        curWork={curWork}
                        show={modalShow}
                        onAddMore={addMoreUniv}
                        currUnivId={props.data.univ_id}
                        onSave={addWorkHistory}
                        onHide={hideWork} />
                    <AddWorkHistoryModal
                        data={props.data}
                        curWork={curWork}
                        show={editModalShow}
                        onAddMore={addMoreUniv}
                        currUnivId={props.data.univ_id}
                        onSave={addWorkHistory}
                        onHide={hideWorkEdit} />
                    <AddUniversityWorkHistoryModal
                        profileState={props.data}
                        show={addMoreUnivModal}
                        onHide={(status) => hideAddMoreUniversityModal(status)} />
                    <NoteModal show={UnivnoteModalShow} onHide={hideUnivNoteModal}
                        note={"An email with your request has been sent to our team. We will get back to you shortly."} />
                    <NoteModal show={noteModalShow} onHide={hideNoteModal}
                        note={"You now have more than one current positions at the same university. Please make sure to update the end dates of those positions if necessary."} />
                    <NoteModal show={chairInvalidModalShow} onHide={hideChairInvalidModal}
                        note={"You have added a Chair position without having a Professor position in same time frame. Please correct this."} />
                    <NoteModal show={updateDeptNoteModalShow} onHide={hideUpdateDeptNoteModalShow}
                        note={"Please update the departments for the new University"} />
                </Container>
            </Accordion>
        </Grid>
    );
};

export default WorkHistory;