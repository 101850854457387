import React, { useEffect, useState } from 'react';
import {
    Button, Paper, Grid, Typography, List, ListItemText,
    ListItem, ListItemAvatar, Avatar, ThemeProvider, createMuiTheme
} from '@material-ui/core';
import * as authorSearchActions from '../../../../store/actions/authorSearch';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../assets/Images/loading.gif';
import TextInput from '../../../InputComponents/TextInput';
import { BACKEND_URL } from '../../../../utils';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CSVLink } from "react-csv";
const defaultFaculty = require('../../../../assets/Images/default_faculty.png');

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        maxHeight: 560,
    },

}));
const AuthorSearch = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [univId, setUnivId] = React.useState(sessionStorage.getItem('universityID') || '');
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [university, setUniversity] = React.useState(sessionStorage.getItem('university') || '');
    const [filter, setFilter] = useState("");
    const authorSearchResult = useSelector(state => state.authorSearch.authorSearchResult);
    const departmentsList = useSelector(state => state.authorSearch.departments);
    const [universitySelectedFlag, setUniversitySelectedFlag] = React.useState(false);
    const [sortByVariable, setSortByVariable] = React.useState(sessionStorage.getItem('sortBy') || 'first_name_inc');
    const [reload, setReload] = React.useState(true);
    const [selectedDepartment, setSelectedDepartment] = React.useState(sessionStorage.getItem('department') || 'All');
    const [csvData, setCsvData] = React.useState([]);
    const [universityOptions, setUniversityOptions] = React.useState([]);

    useEffect(() => {
        const token = localStorage.getItem("rl_token");
        getUniversityOptions(token);

        if (univId !== '' && university !== '') {
            search()
            sessionStorage.clear();
        }
    }, [])

    const search = async () => {
        const token = localStorage.getItem("rl_token");
        if (univId !== '') {
            setShowSpinner(true);
            setUniversitySelectedFlag(false);
            // setSortByVariable('first_name_inc');
            try {
                await dispatch(authorSearchActions.authorSearch(token, univId));
                // await dispatch(authorSearchActions.authorSearch(token, "5e73ea496922717d461529b9"));
                setUniversitySelectedFlag(true);
                setShowSpinner(false);
                handleSortByDepartment(selectedDepartment);
            }
            catch (error) {
                setShowSpinner(false);
                alert("Internal Server Error. Please try again after some time")
            }
        } else {
            alert("Please select a university");
        }

    }

    const getUniversityOptions = async (token) => {
        let url = BACKEND_URL + "get_universities/completed"
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            alert("Unable to fetch Universities Data!!!")
        }
        else {
            const response_data = await response.json();
            setUniversityOptions(response_data);
        }
    }

    const onUniversityChange = (...params) => {
        let selectedUnivData = params[3].option;
        setUniversity(selectedUnivData.name);
        if (selectedUnivData._id !== '') {
            setUnivId(selectedUnivData._id);
        }
    }

    const showCoAuthors = (id) => {
        localStorage.setItem('coauthorid', id);
        var curid = localStorage.getItem('rl_userId')
        sessionStorage.setItem('university', university);
        sessionStorage.setItem('universityID', univId);
        sessionStorage.setItem('department', selectedDepartment);
        sessionStorage.setItem('sortBy', sortByVariable);
        if (id === curid) {

        } else {
            history.push({
                pathname: '/dashboard/profileEdit',
                state: { coauthorid: id }
            })
        }
    }
    const handleFilterChange = async (event) => {
        setFilter(event.target.value);
        await dispatch(authorSearchActions.filterSearch(event.target.value.toLowerCase()));
        setSortByVariable('first_name_inc');
        setSelectedDepartment('All');
    }

    const handleSortByDepartment = async (value) => {
        setSelectedDepartment(value);
        if (value !== null && sortByVariable !== null) {
            await dispatch(authorSearchActions.filterAuthorByDepartment(value.toLowerCase(), sortByVariable));
        }
        setFilter('');
    }

    const sortAuthorsBy = (value) => {
        setSortByVariable(value);
        setReload(false);
        if (value === 'last_name_inc') {
            authorSearchResult.sort((a, b) => (a.last_name > b.last_name) ? 1 : ((b.last_name > a.last_name) ? -1 : 0));
        }
        else if (value === 'last_name_dec') {
            authorSearchResult.sort((a, b) => (a.last_name < b.last_name) ? 1 : ((b.last_name < a.last_name) ? -1 : 0));
        }
        else if (value === 'marker_inc') {
            authorSearchResult.sort((a, b) => (a.marker > b.marker) ? 1 : ((b.marker > a.marker) ? -1 : 0));
        }
        else if (value === 'marker_dec') {
            authorSearchResult.sort((a, b) => (a.marker < b.marker) ? 1 : ((b.marker < a.marker) ? -1 : 0));
        }
        else if (value === 'first_name_dec') {
            authorSearchResult.sort((a, b) => (a.first_name < b.first_name) ? 1 : ((b.first_name < a.first_name) ? -1 : 0));
        }
        else {
            authorSearchResult.sort((a, b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0));
        }
        setReload(true);
    }


    const theme = createMuiTheme({
        palette: {
            primary: { 500: '#BBB21E' },
        },
    });

    const csvHeaders = [
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'Marker', key: 'marker' },
        { label: 'Departments', key: 'department' }
    ];

    const csvRefLink = React.useRef();

    const exists = (item) => {
        return item !== undefined && item !== null
    }

    const createCSVData = async () => {
        if (authorSearchResult.length > 0) {
            let tempData = []
            authorSearchResult.map((item, key) => {
                let tempElement = {
                    'firstName': exists(item.first_name) ? item.first_name : "",
                    'lastName': exists(item.last_name) ? item.last_name : "",
                    'email': exists(item.email) ? item.email : "",
                    'marker': exists(item.marker) ? item.marker : "",
                    'department': exists(item.department) ? item.department.toString() : ""
                }
                tempData.push(tempElement)
            })
            setCsvData(tempData);
            setTimeout(() => {
                csvRefLink.current.link.click();
            });
        }
    }

    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <Paper square style={{ padding: "30px 10px 17px 10px" }}>
                <Grid container spacing={3} justify="center" alignItems="center">
                    <Grid item xs={2} />
                    <Grid item xs={5} >

                        <Autocomplete
                            onChange={onUniversityChange}
                            value={university}
                            // limitTags={3}
                            id="checkboxes-tags-demo"
                            options={universityOptions}
                            getOptionLabel={(option) => {
                                if (option.hasOwnProperty('name')) {
                                    return option.name;
                                }
                                return option;
                            }}
                            fullWidth={true}
                            disableClearable
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="University" placeholder="Select university" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} container direction="column" justify="center" alignItems="center">
                        <Button variant="contained" style={{ color: "#000000", width: "100%", backgroundColor: "#BBB218", borderRadius: "0%" }} color="primary" onClick={search}>Search</Button>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </Paper>
            {showSpinner &&
                <div style={{ textAlign: "center" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
            }
            {universitySelectedFlag ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper square style={{ marginTop: "1%" }}>
                            <Grid container>
                                <Grid item xs={3} >
                                    <div style={{ padding: 20 }}>
                                        <TextInput error={null} value={filter} onChange={handleFilterChange} onBlur={null} label="Search from all faculty list" />
                                    </div>
                                </Grid>
                                <Grid item container xs={4} justify='center' style={{ textAlign: 'center', display: 'inline-flex', alignItems: 'center' }}>
                                    <Autocomplete
                                        value={selectedDepartment}
                                        options={['All', ...departmentsList]}
                                        getOptionLabel={(option) => option}
                                        style={{ width: '20rem' }}
                                        onChange={(event, newValue) => {
                                            handleSortByDepartment(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Filter By Department" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item container xs={3} justify='center' alignItems='center' style={{ textAlign: 'center', display: 'inline-flex' }}>
                                    <FormControl variant="outlined" style={{ minWidth: '15rem' }} >
                                        <InputLabel>Sort By</InputLabel>
                                        <Select
                                            value={sortByVariable}
                                            onChange={(e) => sortAuthorsBy(e.target.value)}
                                            label="Sort By"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            <MenuItem value="first_name_inc">First Name : (A - Z)</MenuItem>
                                            <MenuItem value="first_name_dec">First Name : (Z - A)</MenuItem>
                                            <MenuItem value='last_name_inc'>Last Name : (A - Z)</MenuItem>
                                            <MenuItem value='last_name_dec'>Last Name : (Z - A)</MenuItem>
                                            <MenuItem value='marker_inc'>Marker <CallMadeIcon /></MenuItem>
                                            <MenuItem value='marker_dec'>Marker <CallReceivedIcon style={{ transform: "scaleX(-1)" }} /></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={2} item container justify='center' style={{ textAlign: 'center', width: '80%', alignItems: 'center', display: 'inline-flex' }}>
                                    <CSVLink filename={university.replace(/ /g, "_") + '_Faculty_List'} headers={csvHeaders} data={csvData} ref={csvRefLink} />
                                    <ThemeProvider theme={theme}>
                                        <Button variant="contained" color="primary" onClick={createCSVData}>
                                            Download Report
                                        </Button>
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} >
                        {reload && authorSearchResult.length > 0 ?
                            <div>
                                <div style={{ padding: "20px 0px" }}>
                                    {authorSearchResult.length} results found
                                </div>
                                <Paper>
                                    <div style={{ backgroundColor: "white" }}>
                                        <List className={classes.root}>
                                            {authorSearchResult.map((faculty, i) =>
                                                <ListItem divider key={i}>
                                                    <ListItemAvatar style={{ minWidth: 30 }}>
                                                        {i + 1 + "."}
                                                    </ListItemAvatar>
                                                    <ListItemAvatar style={{ paddingRight: 30 }}>
                                                        {faculty.profile_picture !== undefined && faculty.profile_picture !== "" && faculty.profile_picture !== null ?
                                                            (<Avatar alt={faculty.first_name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                            :
                                                            (<Avatar style={{ height: 75, width: 75 }}>
                                                                <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                            </Avatar>)
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText style={{ cursor: "pointer" }} onClick={() => showCoAuthors(faculty.id)} primary={faculty.first_name + " " + faculty.last_name}
                                                        secondary={
                                                            <React.Fragment>
                                                                {/* <Typography component="div" variant="body2" color="textPrimary">
                                                                {faculty.univ_name === null ? "" : faculty.univ_name}
                                                            </Typography> */}
                                                                <Typography component="div" variant="body2" color="textPrimary">
                                                                    <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                                </Typography>
                                                                <Typography component="div" variant="body2" color="textPrimary">
                                                                    {
                                                                        ("department" in faculty) && faculty.department !== null &&
                                                                            faculty.department !== undefined && faculty.department.length > 0 ?
                                                                            faculty.department.sort((a, b) => a < b ? -1 : (a > b) ? 1 : 0).join(', ')
                                                                            : null
                                                                    }
                                                                </Typography>
                                                                <Typography component="div" variant="body2" color="textPrimary">
                                                                    Marker: {faculty.marker !== null ? faculty.marker : 'N/A'}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            )}
                                        </List>
                                    </div>
                                </Paper>
                            </div>
                            :
                            <div>
                                <div style={{ padding: "20px 0px" }}>
                                    No results found
                                </div>
                            </div>
                        }
                    </Grid>
                </Grid>
                : null}
        </div>
    );
}

export default AuthorSearch;