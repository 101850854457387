import * as actionTypes from '../actions/actionTypes';
import Profile from '../../model/profile';

const profileState = {
    profile: new Profile("", "", "", "", "", "", [], [], [], [], [], [], "", "", "", "", "", "", false, "", "", [], true, "", {}, {}, {}, false, [], "", [], [])
}

const profileReducer = (state = profileState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_AUTHOR_PROFILE:
            return state
        case actionTypes.GET_AUTHOR_PROFILE:
            var newEdu = []
            if (!('cv' in action.profileData) || !action.profileData.cv) {
                action.profileData.cv = "";
            }
            if (!('highlighted_cv' in action.profileData) || !action.profileData.highlighted_cv) {
                action.profileData.highlighted_cv = "";
            }
            if (!('bio' in action.profileData) || !action.profileData.bio) {
                action.profileData.bio = "";
            }
            if (!('department' in action.profileData) || !action.profileData.department) {
                action.profileData.department = [];
            }
            if (!('education' in action.profileData) || !action.profileData.education) {
                action.profileData.education = [];
            }
            if (!('email' in action.profileData) || !action.profileData.email) {
                action.profileData.email = "";
            }
            if (!('expertise' in action.profileData) || !action.profileData.expertise) {
                action.profileData.expertise = "";
            }
            if (!('first_name' in action.profileData) || !action.profileData.first_name) {
                action.profileData.first_name = "";
            }
            if (!('last_name' in action.profileData) || !action.profileData.last_name) {
                action.profileData.last_name = "";
            }
            if (!('middle_name' in action.profileData) || !action.profileData.middle_name) {
                action.profileData.middle_name = "";
            }
            if (!('papers' in action.profileData) || !action.profileData.papers) {
                action.profileData.papers = [];
            }
            if (!('position' in action.profileData) || !action.profileData.position) {
                action.profileData.position = "";
            }
            if (!('profile_picture' in action.profileData) || !action.profileData.profile_picture) {
                action.profileData.profile_picture = "";
            }
            if (!('suffix' in action.profileData) || !action.profileData.suffix) {
                action.profileData.suffix = "";
            }
            if (!('prefix' in action.profileData) || !action.profileData.prefix) {
                action.profileData.prefix = "";
            }
            if (!('gender' in action.profileData) || !action.profileData.gender) {
                action.profileData.gender = "";
            }
            if (!('gs' in action.profileData) || !action.profileData.gs) {
                action.profileData.gs = {};
            }
            if (!('rg' in action.profileData) || !action.profileData.rg) {
                action.profileData.rg = {};
            }
            if (!('percent_appointment' in action.profileData) || !action.profileData.percent_appointment) {
                action.profileData.percent_appointment = {};
            }
            if (!('unusual_work_hist' in action.profileData) || !action.profileData.unusual_work_hist) {
                action.profileData.unusual_work_hist = false;
            }
            if (!('name_variations' in action.profileData) || !action.profileData.name_variations) {
                action.profileData.name_variations = [];
            }
            if (!('univ_profile_link' in action.profileData) || !action.profileData.univ_profile_link) {
                action.profileData.univ_profile_link = "";
            }
            if (!('keywords' in action.profileData) || !action.profileData.keywords) {
                action.profileData.keywords = [];
            }
            if (!('area_of_interest' in action.profileData) || !action.profileData.area_of_interest) {
                action.profileData.area_of_interest = [];
            }
            var education = action.profileData.education;

            for (var i = 0; i < education.length; i++) {
                var obj = { ...education[i], id: i + 1 };
                if (!('advisorFirstName' in obj)) {
                    obj.advisorFirstName = "";
                }
                if (!('advisorLastName' in obj)) {
                    obj.advisorLastName = "";
                }
                if (!('coadvisorFirstName' in obj)) {
                    obj.coadvisorFirstName = "";
                }
                if (!('coadvisorLastName' in obj)) {
                    obj.coadvisorLastName = "";
                }
                newEdu.push(obj);
            }

            var workhis = []
            var work_history = action.profileData.work_history_manual;
            for (var i = 0; i < work_history.length; i++) {
                var obj = { ...work_history[i], id: i + 1 };
                if (!('subTitle' in obj)) {
                    obj.subTitle = "";
                }
                obj.isNew = true;
                if (obj.marker === 1 || obj.marker === 10) {
                    obj.rank = 1;
                } else if (obj.marker === 2 || obj.marker === 20) {
                    obj.rank = 2;
                } else if (obj.marker === 3 || obj.marker === 30) {
                    obj.rank = 3;
                } else {
                    obj.rank = 1;
                }
                workhis.push(obj);
            }
            return {
                ...state,
                profile: new Profile(
                    action.profileData._id,
                    action.profileData.first_name,
                    action.profileData.middle_name,
                    action.profileData.last_name,
                    action.profileData.position,
                    action.profileData.marker,
                    action.profileData.department,
                    newEdu,
                    action.profileData.expertise,
                    action.profileData.interests,
                    action.profileData.courses,
                    workhis,
                    action.profileData.bio,
                    action.profileData.profile_picture,
                    action.profileData.email,
                    action.profileData.cv,
                    action.profileData.univ_id,
                    action.profileData.univ_name,
                    action.profileData.verified,
                    action.profileData.suffix,
                    action.profileData.prefix,
                    action.profileData.papers,
                    action.profileData.is_complete,
                    action.profileData.gender,
                    action.profileData.gs,
                    action.profileData.rg,
                    action.profileData.percent_appointment,
                    action.profileData.unusual_work_hist,
                    action.profileData.name_variations,
                    action.profileData.univ_profile_link,
                    action.profileData.keywords,
                    action.profileData.area_of_interest,
                    action.profileData.highlighted_cv,
                )
            }
        case actionTypes.VERIFY_AUTHOR_PROFILE:
            return {
                ...state,
                profile: {
                    ...action.profileData,
                    verified: true,
                }
            }
        case actionTypes.CLEAR_PROFILE:
            return {
                ...state,
                profile: new Profile("", "", "", "", "", "", [], [], [], [], [], [], "", "", "", "", "", "", false, "", "", [], true, "", {}, {}, {}, false, [], "", [], [])
            }
        default:
            return state;
    }
}

export default profileReducer;