import { Grid, Paper } from '@material-ui/core';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import './NewHome.css';
import LandingPageNavbar from '../LandingPageNavbar';

const NewHome = () => {
    const bgLogoImage = require(`../../../../assets/Images/landingPage/bg_landingpage.png`)
    const bgSchoolImage = require(`../../../../assets/Images/landingPage/school_256px_school final.png`)
    const bgFacultyImage = require(`../../../../assets/Images/landingPage/facultyicon256px 2.png`)
    const trial1 = require(`../../../../assets/Images/landingPage/test_bg/testImage.png`)
    const webScreen = require(`../../../../assets/Images/landingPage/test_bg/bg_landingpagegray1440x353.png`)
    const ipadScreen = require(`../../../../assets/Images/landingPage/test_bg/bg_landingpagegray768x432.png`)
    const tabletScreen = require(`../../../../assets/Images/landingPage/test_bg/bg_landingpage_tablet.png`)
    const rlLogo = require(`../../../../assets/Images/logos/home_new_transparent.png`)
    const circles = require(`../../../../assets/Images/just_circles_clear_background.png`)
    const homePageImage1 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_1.jpg`)
    const homePageImage2 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_2.jpg`)
    const homePageImage3 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_3.jpg`)
    const homePageImage4 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_4.jpg`)
    const homePageImage5 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_5.jpg`)
    const homePageImage6 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_6.jpg`)
    const homePageImage7 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_7.jpg`)
    const homePageImage8 = require(`../../../../assets/Images/landingPage/ResearchLoupe - images_Page_8.jpg`)
    const location = useLocation()

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location,])

    return (
        <React.Fragment>
            <LandingPageNavbar />
            <div className='landingHome' center mt-4>
                <div className="boundary">
                    <div className="hero_text" style={{ backgroundImage: "url(" + circles + ")", backgroundPosition: "left bottom" }}>
                        <p className="description">Research Performance Insights</p>
                    </div>
                    <div className="hero_img" style={{ backgroundImage: "url(" + rlLogo + ")" }}>

                    </div>
                </div>

                <section id='homePageImage1' >
                    <div >
                        <Image src={homePageImage1} fluid />
                    </div>
                </section>
                <section id='homePageImage2' >
                    <div >
                        <Image src={homePageImage2} fluid />
                    </div>
                </section>
                <section id='homePageImage3' >
                    <div >
                        <Image src={homePageImage3} fluid />
                    </div>
                </section>
                <section id='homePageImage4' >
                    <div >
                        <Image src={homePageImage4} fluid />
                    </div>
                </section>
                <section id='homePageImage5' >
                    <div >
                        <Image src={homePageImage5} fluid />
                    </div>
                </section>
                <section id='homePageImage6' >
                    <div >
                        <Image src={homePageImage6} fluid />
                    </div>
                </section>
                <section id='homePageImage7' >
                    <div >
                        <Image src={homePageImage7} fluid />
                    </div>
                </section>
                <section id='homePageImage8' >
                    <div >
                        <Image src={homePageImage8} fluid />
                    </div>
                </section>

            </div>
        </React.Fragment>
    );
}

export default NewHome