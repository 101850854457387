import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

var currentMonth = new Date().getMonth() + 1;
const initialState = {
    tenureReadiness: []
};

const getUniversityNameCSS = (paper, citation) => {
    if ((paper >= 95  ) && currentMonth <= 5)
        return "green";
    else if ((paper >= 90  ) && currentMonth > 5)
        return "green";
    else if ((paper < 95 && paper >= 85)  && currentMonth <= 5)
        return "orange";
    else if ((paper < 90 && paper >= 80)  && currentMonth > 5)
        return "orange";
    else
        return "red";

};

const tenureReadinessReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_UNIVERSITIES_TENURE_READINESS:
            for (let faculty of action.tenureReadiness) {
                faculty["color"] = getUniversityNameCSS(faculty["percentPaper"], faculty["percentCitation"]);
            }
            return updateObject(state, { tenureReadiness: action.tenureReadiness });
        case actionTypes.CLEAR_TENURE_READINESS:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    };
}

export default tenureReadinessReducer;