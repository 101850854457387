import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';


const initialState = {
    authorSearchResult: 'Initial State',
    authorWithIncorrectPercentAppointment: false,
    authorWithInconsistentPaper: false,
    authorWithMissingMarker: false,
    authorWithMissingDepartment: false,
    authorWithMissingPosition: false,
    authorWithMissingGender: false
}

const updateFlag = (oldState, newState) => {
    return {
        ...initialState,
        ...newState,
    };
}

const redflagReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AUTHOR_WITH_INCONSISTENT_PAPER:
            return updateObject(state, { authorSearchResult: action.searchResult });
        case actionTypes.CHANGE_INCONSISTENT_PAPER_REDFLAG_TOGGLE:
            return updateFlag(state, { authorSearchResult: 'Initial State', authorWithInconsistentPaper: action.isActive });

        case actionTypes.GET_AUTHOR_WITH_INCORRECT_PERCENT_APPOINTMENT:
            return updateObject(state, { authorSearchResult: action.searchResult });
        case actionTypes.CHANGE_INCORRECT_PERCENT_APPOINTMENT_TOGGLE:
            return updateFlag(state, { authorSearchResult: 'Initial State', authorWithIncorrectPercentAppointment: action.isActive });

        case actionTypes.GET_AUTHOR_WITH_MISSING_GENDER:
            return updateObject(state, { authorSearchResult: action.searchResult });
        case actionTypes.CHANGE_MISSING_GENDER_TOGGLE:
            return updateFlag(state, { authorSearchResult: 'Initial State', authorWithMissingGender: action.isActive });

        case actionTypes.GET_AUTHOR_WITH_MISSING_POSITION:
            return updateObject(state, { authorSearchResult: action.searchResult });
        case actionTypes.CHANGE_MISSING_POSITION_TOGGLE:
            return updateFlag(state, { authorSearchResult: 'Initial State', authorWithMissingPosition: action.isActive });

        case actionTypes.GET_AUTHOR_WITH_MISSING_DEPARTMENT:
            return updateObject(state, { authorSearchResult: action.searchResult });
        case actionTypes.CHANGE_MISSING_DEPARTMENT_TOGGLE:
            return updateFlag(state, { authorSearchResult: 'Initial State', authorWithMissingDepartment: action.isActive });

        case actionTypes.GET_AUTHOR_WITH_MISSING_MARKER:
            return updateObject(state, { authorSearchResult: action.searchResult });
        case actionTypes.CHANGE_MISSING_MARKER_TOGGLE:
            return updateFlag(state, { authorSearchResult: 'Initial State', authorWithMissingMarker: action.isActive });


        default:
            return state
    }
}

export default redflagReducer;