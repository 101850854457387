import React, { useEffect, useState } from 'react';
import classes from './../Papers/Papers.module.css';
import profileClassses from '../Profile.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import AddAreaOfResearchModal from './AddAreaOfResearchModal';
import { convertArrayToSummary } from '../../../../../utils';
import * as profileActions from '../../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../../store/actions/coauthorprofile';
import Profile from '../../../../../model/profile';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const AreaOfResearch = props => {
    const editColor = "#DADADA";
    const [modalShow, setModalShow] = useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const [keywords, setKeywords] = useState(props.data.keywords);
    const [AreaOfInterest, setAreaOfInterest] = useState(props.data.area_of_interest);

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    const setPersonalInformationModal = () => {
        setModalShow(true);
    };

    const createUpdatedProfile = (updatedKeywords, updatdAOI) => {
        return Object.assign(new Profile(), {
            _id: props.data._id,
            ...(updatedKeywords !== undefined) && { keywords: updatedKeywords },
            ...(updatdAOI !== undefined) && { area_of_interest: updatdAOI }
        })

    }

    const editAreaOfResearch = async (updatedKeywords, updatdAOI) => {
        setAccordionExpand(true);
        setModalShow(false)
        setKeywords(updatedKeywords === undefined ? keywords : updatedKeywords)
        setAreaOfInterest(updatdAOI === undefined ? AreaOfInterest : updatdAOI)
        try {
            const newProfile = createUpdatedProfile(updatedKeywords, updatdAOI);

            if (props.isCoAuthor === true) {
                await dispatch(coAuthorProfileActions.update_co_author_profile(token, newProfile, props.data._id));
            } else {
                await dispatch(profileActions.update_author_profile(token, newProfile, props.data._id));
            }
        }
        catch (error) {
            alert("Failed to Update! Please Refresh the page and try again.");
        }
    }

    return (
        <Grid container xs={20} alignItems="center" justify="center" className={profileClassses.grid}>
            <Accordion square className={profileClassses.accordian} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary className={profileClassses.accordian_summary}
                    expandIcon={<ExpandMoreIcon />}>
                    <Row className={classes.header}>
                        <Col sm={11} md={11}><h3 style={{ marginTop: "10px" }}>Area of Research</h3></Col>
                        {
                            profileUpdateState === true ?
                                <Col sm={1} md={1}>
                                    <span style={{ cursor: "pointer" }}>
                                        <LightTooltip title="Click to edit area of research." placement="bottom">
                                            <EditIcon style={{ width: '1.2em', height: '1.2em', color: editColor, marginLeft: "370px", marginTop: "9px", }} onClick={setPersonalInformationModal}></EditIcon>

                                        </LightTooltip>
                                    </span>
                                </Col>
                                :
                                <Col sm={1} md={1}></Col>
                        }
                    </Row>
                </AccordionSummary>
                <Container className={classes.container}>
                    <div style={{ marginTop: 5 }}>
                        <Row style={{ marginLeft: 5 }} className={classes.header}>
                            <span >
                                <b>Keywords:</b> {keywords !== undefined && convertArrayToSummary(keywords.sort(function (a, b) {
                                    return a.localeCompare(b);
                                }))}
                            </span>
                        </Row>
                        <Row style={{ marginLeft: 5 }} className={classes.header}>
                            <span >
                                <b>Area of Interest:</b> {AreaOfInterest !== undefined && convertArrayToSummary(AreaOfInterest.sort(function (a, b) {
                                    return a.localeCompare(b);
                                }))}
                            </span>
                        </Row>
                    </div>
                    <AddAreaOfResearchModal
                        show={modalShow}
                        onSave={editAreaOfResearch}
                        isCoAuthor={props.isCoAuthor}
                        coAuthorId={props.data._id}
                        profileKeywords={keywords}
                        profileAOI={AreaOfInterest}
                        onHide={() => { setModalShow(false); setAccordionExpand(true) }} />
                </Container>
            </Accordion>
        </Grid >
    );
}

export default AreaOfResearch;