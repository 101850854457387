import React, {useState} from 'react';
import { MenuItems } from './MenuItems';
import './NavbarDropdown.css';
import { Link } from 'react-router-dom';

function NavbarDropdown() {
    const [click, setClick] = useState(false);

    const handleCLick = () => setClick(!click);

    return(
        <>
            <ul className={click ? 'dropdown_menu clicked': 'dropdown_menu'} onClick={handleCLick} >
                {MenuItems.map((item,index) =>{
                    // console.log(item)
                    return(
                        <li className='dropdown_li' key={index}>
                            <Link to={item.path} className={item.cName} onClick={() => setClick(false)}
                                style={{paddingTop:'0px',paddingBottom:'0px'}}>
                                <div className="dropdownIcon">

                                    <div className="dropdownIconChild icon">
                                        <img src={require(`../../../assets/Images/`+item.image)} 
                                        style={{height:'3rem', width:'3rem', textAlign:'center'}}/>
                                    </div>
                                    
                                    <div className="dropdownIconChild menu" style={{alignItems:'center', display:'flex'}}>
                                        <p style={{margin:'0 0 0 0'}}>{item.title}</p>
                                    </div>
                                </div>    
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export default NavbarDropdown;