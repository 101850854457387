import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const tenureReadinessAction = (data) => {
    return {
        type: actionTypes.GET_UNIVERSITIES_TENURE_READINESS,
        tenureReadiness: data
    };
};

export const getTenureReadiness = (token, univIDs, dept) => {
    var universities = "";
    for (let i = 0; i < univIDs.length; i++) {
        if (i === 0) {
            universities += "id=";
            universities += univIDs[i];
        } else {
            universities += "&id=";
            universities += univIDs[i];
        }
    }

    var departments = "";
    for (let i = 0; i < dept.length; i++) {
        departments += "&department=";
        departments += dept[i];
    }

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `tenure_track_compare?${universities}${departments}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            dispatch(tenureReadinessAction([]));
            throw new Error('Something went wrong while fetching tenure readiness for dashboard');
        } else {
            const tenureReadinessData = await response.json();
            dispatch(tenureReadinessAction(tenureReadinessData));
        }

    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_TENURE_READINESS });
    }
}