export const GET_AUTHOR_PROFILE = "GET_AUTHOR_PROFILE"
export const UPDATE_AUTHOR_PROFILE = "UPDATE_AUTHOR_PROFILE"
export const VERIFY_AUTHOR_PROFILE = "VERIFY_AUTHOR_PROFILE"
export const CLEAR_PROFILE = "CLEAR_PROFILE"
export const AUTH_START = "AUTH_START"
export const AUTH_FAIL = "AUTH_FAIL"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const GET_COAUTHOR_PROFILE = "GET_COAUTHOR_PROFILE"
export const UPDATE_COAUTHOR_PROFILE = "UPDATE_COAUTHOR_PROFILE"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const DONE_EDIT = "DONE_EDIT"
export const AUTHOR_SEARCH = "AUTHOR_SEARCH"
export const PAPER_SEARCH = "PAPER_SEARCH"
export const GET_FACULTY_PRODUCTIVITY_BY_DEPT = "GET_FACULTY_PRODUCTIVITY_BY_DEPT"
export const GET_FACULTY_PRODUCTIVITY_BY_ALL_FACULTY = "GET_FACULTY_PRODUCTIVITY_BY_ALL_FACULTY"
export const FACULTY_PRODUCTIVITY_FAILURE = "FACULTY_PRODUCTIVITY_FAILURE"
export const COMPARE_GENDER = "COMPARE_GENDER"
export const GET_PERCEPTUAL_MAP = "GET_PERCEPTUAL_MAP"
export const GET_COMPARE_YOURSELF = "GET_COMPARE_YOURSELF"
export const ADMIN_AUTHOR_SEARCH = "ADMIN_AUTHOR_SEARCH"
export const FILTER_ADMIN_AUTHOR_SEARCH = "FILTER_ADMIN_AUTHOR_SEARCH"
export const GET_SIMILAR_FACULTY = "GET_SIMILAR_FACULTY"
export const ADD_COHORT_ROW = "ADD_COHORT_ROW"
export const REMOVE_COHORT_ROW = "REMOVE_COHORT_ROW"
export const ANALYZE_COHORT = "ANALYZE_COHORT"
export const GET_COMPARE_FACULTY = "GET_COMPARE_FACULTY"
export const CLEAR_COMPARE_FACULTY = "CLEAR_COMPARE_FACULTY"
export const SET_UNIV_ID = "SET_UNIV_ID"
export const MILESTONE_CHANGE = "MILESTONE_CHANGE"
export const TOGGLE_MODAL = "TOGGLE_MODAL"
export const SET_FACULTY_ID = "SET_FACULTY_ID"
export const INVERT_AXES = "INVERT_AXES"
export const GET_DEPARTMENT_CONFIG = "GET_DEPARTMENT_CONFIG"
export const RESUME_UPLOAD = "RESUME_UPLOAD"
export const SAVE_DEPARTMENT_CONFIG_DATA = "SAVE_DEPARTMENT_CONFIG_DATA"
export const CLEAR_DATA = "CLEAR_DATA"
export const GET_FACULTY_CARD_INFO = "GET_FACULTY_CARD_INFO"
export const SIGN_UP = "SIGN_UP"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FIND_YOUR_FIT = "FIND_YOUR_FIT"
export const COMPARE_DEPARTMENTS = "COMPARE_DEPARTMENTS"
export const HIRE_IMPACT = "HIRE_IMPACT"
export const GET_AUTHORS = "GET_AUTHORS"
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const FAILURE = "FAILURE"
export const GET_INTERESTING_FACTS = "GET_INTERESTING_FACTS"
export const GET_DEPARTMENT_FACTS = "GET_DEPARTMENT_FACTS"
export const GET_GENDER_DISTRIBUTION = "GET_GENDER_DISTRIBUTION"
export const GET_OUTLIERS = "GET_OUTLIERS"
export const FACULTY_PROGRESS = "FACULTY_PROGRESS"
export const GET_DASHBOARD_DEPARTMENTS = "GET_DASHBOARD_DEPARTMENTS"
export const SELECTED_DEPARTMENT_DASHBOARD = "SELECTED_DEPARTMENT_DASHBOARD"
export const SCHOOL_MODE = "SCHOOL_MODE"
export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST"
export const UPDATE_CONTACTED_FLAG = "UPDATE_CONTACTED_FLAG"
export const FETCH_LOGS = "FETCH_LOGS"
export const RESOLVE_LOG = "RESOLVE_LOG"
export const FILTER_LOGS = "FILTER_LOGS"
export const GET_FACULTY_BY_MARKET_SHARE = "GET_FACULTY_BY_MARKET_SHARE"
export const GET_FACULTY_CITATIONS = "GET_FACULTY_CITATIONS"
export const GET_TENURE_READINESS = "GET_TENURE_READINESS"
export const GET_MERGE_AUTHORS_FOR_TOOLS = "GET_MERGE_AUTHORS_FOR_TOOLS"
export const MERGE_SUCCESS = "MERGE_SUCCESS"
export const FIND_SIMILAR_AUTHORS_BEFORE_ADDING_NEW_AUTHOR = "FIND_SIMILAR_AUTHORS_BEFORE_ADDING_NEW_AUTHOR"
export const FILTER_AUTHOR_BY_DEPARTMENT = "FILTER_AUTHOR_BY_DEPARTMENT"
export const GET_AUTHOR_RANKING = "GET_AUTHOR_RANKING"
export const SEND_EMAIL = "SEND_EMAIL"
export const GET_UNIVERSITIES_TENURE_READINESS = "GET_UNIVERSITIES_TENURE_READINESS"
export const ADMIN_CHANGE_LOGIN = "ADMIN_CHANGE_LOGIN"
export const GET_UNIV_PROFILE = "GET_UNIV_PROFILE"
export const CLEAR_UNIV_PROFILE = "CLEAR_UNIV_PROFILE"
export const SET_FACULTY_PRODUCTIVITY_INPUTS = "SET_FACULTY_PRODUCTIVITY_INPUTS"
export const CLEAR_FACULTY_PRODUCTIVITY = "CLEAR_FACULTY_PRODUCTIVITY"
export const CLEAR_TENURE_READINESS = "CLEAR_TENURE_READINESS"
export const CLEAR_HIRE_IMPACT = "CLEAR_HIRE_IMPACT"
export const CLEAR_COHORT_ANALYSIS = "CLEAR_COHORT_ANALYSIS"
export const CLEAR_COMPARE_SCHOOLS = "CLEAR_COMPARE_SCHOOLS"
export const CLEAR_COMPARE_DEPARTMENTS = "CLEAR_COMPARE_DEPARTMENTS"
export const CLEAR_COMPARE_GENDER = "CLEAR_COMPARE_GENDER"
export const CLEAR_EXPLORE = "CLEAR_EXPLORE"
export const CLEAR_FACULTY_RANKING = "CLEAR_FACULTY_RANKING"
export const CLEAR_FACULTY_PROGRESS = "CLEAR_FACULTY_PROGRESS"
export const CLEAR_COMPARE_YOURSELF = "CLEAR_COMPARE_YOURSELF"
export const CLEAR_FIND_YOUR_FIT = "CLEAR_FIND_YOUR_FIT"
export const GET_JOURNAL_DISTRIBUTION = "GET_JOURNAL_DISTRIBUTION"
export const CLEAR_JOURNAL_DISTRIBUTION = "CLEAR_JOURNAL_DISTRIBUTION"
export const SIMILAR_AUTHOR_SEARCH = "SIMILAR_AUTHOR_SEARCH"
export const CLEAR_SIMILAR_AUTHOR_SEARCH = "CLEAR_SIMILAR_AUTHOR_SEARCH"
export const GET_DEPT_AND_JOURNALS = "GET_DEPT_AND_JOURNALS"
export const GET_AUTHOR_WITH_INCONSISTENT_PAPER = "GET_AUTHOR_WITH_INCONSISTENT_PAPER"
export const UPDATE_FACULTY_ACTION = "UPDATE_FACULTY_ACTION"
export const GET_AUTHOR_WITH_MISSING_GENDER = "GET_AUTHOR_WITH_MISSING_GENDER"
export const GET_AUTHOR_WITH_MISSING_DEPARTMENT = "GET_AUTHOR_WITH_MISSING_DEPARTMENT"
export const GET_AUTHOR_WITH_MISSING_POSITION = "GET_AUTHOR_WITH_MISSING_POSITION"
export const GET_AUTHOR_WITH_MISSING_MARKER = "GET_AUTHOR_WITH_MISSING_MARKER"
export const GET_AUTHOR_WITH_INCORRECT_PERCENT_APPOINTMENT = "GET_AUTHOR_WITH_INCORRECT_PERCENT_APPOINTMENT"
export const CHANGE_INCORRECT_PERCENT_APPOINTMENT_TOGGLE = "CHANGE_INCORRECT_PERCENT_APPOINTMENT_TOGGLE"
export const CHANGE_MISSING_MARKER_TOGGLE = "CHANGE_MISSING_MARKER_TOGGLE"
export const CHANGE_MISSING_GENDER_TOGGLE = "CHANGE_MISSING_GENDER_TOGGLE"
export const CHANGE_MISSING_POSITION_TOGGLE = "CHANGE_MISSING_POSITION_TOGGLE"
export const CHANGE_MISSING_DEPARTMENT_TOGGLE = "CHANGE_MISSING_DEPARTMENT_TOGGLE"
export const CHANGE_INCONSISTENT_PAPER_REDFLAG_TOGGLE = "CHANGE_INCONSISTENT_PAPER_REDFLAG_TOGGLE"

