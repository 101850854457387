import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as classes from './WorkHistory.module.css';
import './AddWorkHistoryModal.css'
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../../constants/Theme';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import SinglePreFilledAutoComplete from '../../../../InputComponents/SinglePreFilledAutoComplete';
import { BACKEND_URL } from '../../../../../utils';
import SingleSelectDisabled from '../../../../InputComponents/SingleSelectDisabled';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import { Checkbox } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { titleMapping, titlesWithSubTitle, markersOfSubTitle } from './WorkHistory';
import * as email from '../../../../../store/actions/email';


const presentYear = new Date().getFullYear();
const AddWorkHistoryModal = props => {
    const theme = Theme();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const dispatch = useDispatch();
    const [titleValue, setTitleValue] = useState("");
    const [fromYear, setFromYear] = useState("");
    const [toYear, setToYear] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const [university, setUniversity] = useState("");
    const [school, setSchool] = useState("");
    const [subTitle, setsubTitle] = useState("");
    const [workMarker, setWorkMarker] = useState("");
    const [selectedUnivTitle, setSelectedUnivTitle] = React.useState("");
    const [id, setId] = useState();
    const [isNew, setIsNew] = useState(true);
    const [isValidForm, setIsValidForm] = useState(false);
    const [rank, setRank] = useState(1);
    const [valueUniversity, setValueUniversity] = useState("");
    const [optionsUniversity, setOptionsUniversity] = React.useState([]);
    const [loadingUniversity, setLoadingUniversity] = React.useState(false);
    const [univId, setUnivId] = useState("");
    const [valueSchool, setValueSchool] = useState("");
    const [optionsSchool, setOptionsSchool] = React.useState([]);
    const [loadingSchool, setLoadingSchool] = React.useState(false);
  
    const userType = useSelector(state => state.auth.user_type);
    const token = useSelector(state => state.auth.token);

    const toSubTitleHandler = event => {
        const newInputValue = event.target.value
        setsubTitle(newInputValue)
        if (newInputValue.trim() !== "" && titleValue.trim() !== "" && school.trim() !== "" && fromYear !== "" && fromYear >= 1950 && university.trim() !== "" && (typeof toYear === 'string' ? toYear === "Present" : (fromYear <= toYear && toYear >= 1950 && toYear <= presentYear))) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const sendEmail = async () => {
        const recipient = "researchloupe.team@gmail.com";
        const subject = "Add new University and fix University Id for the Author";
        const body =
            `Hello Team RL,
        Please do the following:
        1) Add the University: "${university}" after spell check in the university collection.
        2) Go to the author "${props.data.last_name}, ${props.data.first_name}" with author Id: "${props.data._id}".
        3) Replace the univ_id field in the DB with the university Id of the above added new university ONLY IF the univ_name is equal to ${university}. This means that the current position of the author belongs in this newly added university and thus we need to udpate the univ_id field.

From Admin,
Work Experience Section,
Update Profile Page
                `;
        const emailData = {
            recipient: recipient,
            subject: subject,
            body: body
        };
        try {
            await dispatch(email.send_email(token, emailData));
        } catch (error) {
            alert("Failed to send email to add university and update university id for author. Please try again.");
        }
    }

    const saveWorkHistory = () => {
        let marker = parseInt(titleMapping[selectedUnivTitle].marker + '0')
        marker = toYear === "Present" ? marker / 10 : marker;
        marker = workMarker !== props.curWork.marker ? workMarker : marker;
        props.onSave({
            id: id,
            isNew: isNew,
            title: titlesWithSubTitle.includes(selectedUnivTitle) && subTitle !== "" ? subTitle : titleMapping[selectedUnivTitle].title,
            school: school,
            institution: university,
            univId: univId,
            from: fromYear,
            to: toYear,
            subTitle: "",
            marker: marker
        });
        setFromYear("");
        setToYear("");
        setUniversity("");
        setUnivId("");
        setSchool("");
        setsubTitle("");
        setWorkMarker("");
        setId(0);
        setIsNew(true);
        setRank(1);
        setTitleValue("");
        if (univId === undefined) {
            sendEmail();
        }
    };

    const checkValid = (curTitle) => {
        if (curTitle === "Associate Professor (tenured)") {
            if (rank === 1) {
                return true;
            }
        }
        if (curTitle === "Full Professor (tenured)") {
            if (rank < 3) {
                return true;
            }
        }
        return false;
    };

    const onInputChangeHandler = (event, newInputValue) => {
        setTitleValue(newInputValue);
        setSelectedUnivTitle(newInputValue);
        setsubTitle("");
        if (newInputValue.trim() !== "" && school.trim() !== "" && fromYear !== "" && fromYear >= 1950 && university.trim() !== "" && (typeof toYear === 'string' ? toYear === "Present" : (fromYear <= toYear && toYear >= 1950 && toYear <= presentYear))) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const getTitleValue = () => {
        const marker = parseInt(String(props.curWork.marker)[0]);
        const title = Object.keys(titleMapping).find(key => titleMapping[key].marker === marker);
        return title === undefined ? '' : title;
    }

    const deleteWork = () => {
        props.onHide(id);
    };

    const fromYearChangeHandler = (event) => {
        const newFromYear = event.target.value;
        if (newFromYear === "")
            setFromYear("");
        else
            setFromYear(parseInt(newFromYear));
        if (titleValue.trim() !== "" && school.trim() !== "" && newFromYear !== "" && newFromYear >= 1950 && university.trim() !== "" && (typeof toYear === 'string' ? toYear === "Present" : (newFromYear <= toYear && toYear >= 1950 && toYear <= presentYear))) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const toYearChangeHandler = event => {
        const newToYear = event.target.value;
        if (newToYear === "")
            setToYear("");
        else
            setToYear(parseInt(newToYear));
        if (titleValue.trim() !== "" && school.trim() !== "" && fromYear !== "" && fromYear >= 1950 && university.trim() !== "" && (typeof newToYear === 'string' ? newToYear === "Present" : (fromYear <= newToYear && newToYear >= 1950 && newToYear <= presentYear))) {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const workMarkerChangeHandler = event => {
        const newWorkMarker = event.target.value;
        if (newWorkMarker === "")
            setWorkMarker("");
        else
            setWorkMarker(parseInt(newWorkMarker));
    };

    const checkboxHandler = () => {
        if (checkbox === false)
            setToYear("Present");
        else
            setToYear("");
        setCheckbox(!checkbox);
    };

    const universityChangeHandler = (name, value) => {
        setUniversity(name);
        setUnivId(optionsUniversity.filter(uni => uni.name === name).map(uni => uni.univ_id)[0]);
        if (name && name.trim() !== "" && optionsUniversity.map(univ => univ.name).includes(name) === false && titleValue.trim() !== "" && school.trim() !== "" && fromYear !== "" && fromYear >= 1950 && name.trim() !== "" && (typeof toYear === 'string' ? toYear === "Present" : (fromYear <= toYear && toYear >= 1950 && toYear <= presentYear))) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const schoolChangeHandler = (name, value) => {
        setSchool(name);
        if (titleValue.trim() !== "" && name.trim() !== "" && fromYear !== "" && fromYear >= 1950 && university.trim() !== "" && (typeof toYear === 'string' ? toYear === "Present" : (fromYear <= toYear && toYear >= 1950 && toYear <= presentYear))) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const errorMessage = (isValidForm) => {
        if (isValidForm) {
            return "";
        } else {
            var message = "Incomplete form: ";
            if (titleValue.trim() === "") {
                message += "Select Title, ";
            }
            if (school.trim() === "") {
                message += "Select School, ";
            }
            if (!university || university.trim() === "" || (optionsUniversity.length !== 0 && optionsUniversity.map(univ => univ.name).includes(university) === false)) {
                message += "Select University, ";
            }
            if (fromYear === "" || isNaN(fromYear))
                message += "Enter From Year, ";
            if (typeof toYear === 'string' && toYear !== 'Present') {
                message += "Enter Valid To Year, ";
            } else {
                if (fromYear < 1950 || fromYear > toYear) {
                    message += "Enter Valid From Year between 1950 and To Year, ";
                }
                if (toYear < 1950 || toYear > presentYear) {
                    message += "Enter Valid To Year greater than From Year and within ";
                    message += (presentYear.toString()) + ", ";
                }
            }

            if (titlesWithSubTitle.includes(titleValue)) {
                if (subTitle.trim() === "") {
                    message += "Enter the Position Title, "
                }
            }
            if (message === "Incomplete form: ") {
                setIsValidForm(true)
                return "";
            }
        }
        return message.slice(0, message.length - 2);
    };

    useEffect(() => {
        setSelectedUnivTitle(getTitleValue())
        setFromYear(props.curWork.from);
        setToYear(typeof props.curWork.to === "string"
            ? props.curWork.to === "Present"
                ? props.curWork.to
                : ""
            : props.curWork.to);
        setUniversity(props.curWork.institution);
        setSchool(props.curWork.school);
        setsubTitle(markersOfSubTitle.includes(parseInt(String(props.curWork.marker)[0]))
            && props.curWork.subTitle === "" ?
            props.curWork.title : props.curWork.subTitle);
        setId(props.curWork.id)
        setIsNew(props.curWork.isNew)
        setRank(props.curWork.rank)
        setTitleValue(getTitleValue());
        setUnivId(props.currUnivId)
        setWorkMarker(props.curWork.marker)
        if (props.curWork.to === "Present")
            setCheckbox(true);
        else setCheckbox(false);
    }, [props.curWork]);

    const onInputUniversityChangeHandler = (event, newInputValue) => {
        setValueUniversity(newInputValue)
        universityChangeHandler(newInputValue, newInputValue)
        setLoadingUniversity(true)
        if (!newInputValue || newInputValue.trim() === "") {
            setLoadingUniversity(false)
            return;
        }
        const url = BACKEND_URL + 'auto/univ/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsUniversity(Object.keys(schools.universities).map((key) => schools.universities[key]));
            setLoadingUniversity(false)
        })();
    };

    const addMorePaper = () => {
        props.onAddMore();
    };

    const onInputSchoolChangeHandler = (event, newInputValue) => {
        setValueSchool(newInputValue)
        schoolChangeHandler(newInputValue, newInputValue)
        setLoadingSchool(true)
        if (!newInputValue || newInputValue.trim() === "") {
            setLoadingSchool(false)
            return;
        }
        const url = BACKEND_URL + 'auto/school/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsSchool(Object.keys(schools.schools).map((key) => schools.schools[key]));
            setLoadingSchool(false)
        })();
    };
    return (
        <ThemeProvider theme={theme}>
            <Modal
                {...props}
                dialogClassName={classes.myModal}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add/Edit Work Experience
                        <div className={classes.subheading}>
                            Please add Professor Positions before adding other positions
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row md={12}>
                            <Col md={4}>
                                <SingleSelectDisabled
                                    options={Object.keys(titleMapping)}
                                    getOptionDisabled={(option) => checkValid(option) === true}
                                    inputValue={titleValue}
                                    onInputChange={onInputChangeHandler}
                                    label="Select Title"
                                />
                            </Col>
                            <Col md={2}>
                                <TextInputControlled label="From" onChange={fromYearChangeHandler.bind(this)} value={fromYear} >
                                </TextInputControlled>
                            </Col>
                            <Col md={2}>
                                <TextInputControlled label="To" onChange={toYearChangeHandler.bind(this)} value={toYear}>
                                </TextInputControlled>
                                <Checkbox
                                    checked={checkbox}
                                    onClick={() => checkboxHandler()}
                                />Present
                            </Col>
                            {
                                titleValue !== ''
                                && titleMapping[titleValue] !== undefined
                                && titleMapping[titleValue]['title'] === ''
                                &&
                                <Col md={4}>
                                    <TextInputControlled label={"Position Title"} onChange={toSubTitleHandler.bind(this)} value={subTitle}>
                                    </TextInputControlled>
                                </Col>
                            }
                        </Row>

                        <Row md={14} style={{ marginTop: "25px" }}>
                            <Col md={5}>
                                <SinglePreFilledAutoComplete name="name" value={school}
                                    onInputChange={onInputSchoolChangeHandler}
                                    disabled={isNew !== true && userType !== 'admin'}
                                    inputValue={valueSchool}
                                    loading={loadingSchool}
                                    label="Select School"
                                    options={optionsSchool.map(option => option.name)} />
                            </Col>
                            <Col md={5} >
                                <SinglePreFilledAutoComplete name="name" value={university}
                                    onInputChange={onInputUniversityChangeHandler}
                                    disabled={isNew !== true && userType !== 'admin'}
                                    inputValue={valueUniversity}
                                    loading={loadingUniversity}
                                    label="Select University"
                                    options={optionsUniversity.map(option => option.name)} />
                            
                            </Col>
                            <Col md={2}>
                                    <LightTooltip title="Can't find your University? Click to request addition." placement="top">
                                        <Button variant="contained" onClick={addMorePaper} style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }}>More</Button>
                                    </LightTooltip>
                            </Col>
                        </Row>

                        {
                            userType === "admin" &&
                            <Row md={12}>
                                <Col md={3}>
                                    <TextInputControlled label="Marker for curr Work Exp" onChange={workMarkerChangeHandler.bind(this)} value={workMarker} >
                                    </TextInputControlled>
                                </Col>
                                <Col md={9}>
                                    <InfoIcon fontSize="small" style={{ color: "gray" }} />
                                    <span style={{ verticalAlign: "middle" }}>Use this field to only <strong>update</strong> the marker value, if wrong. Do not enter a value if it's blank.</span>
                                </Col>
                            </Row>
                        }

                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    {
                        isValidForm === false && userType === 'admin' &&
                        <Row className={classes.row} md={12}>
                            <Col md={12}>
                                <InfoIcon fontSize="small" style={{ color: "gray" }} />
                                <span style={{ verticalAlign: "middle" }}><strong>Note for admin</strong> - {errorMessage(isValidForm)}</span>
                            </Col>
                        </Row>
                    }
                    {
                        isNew ?
                            <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={deleteWork}>Close</Button>
                            :
                            userType === 'admin' ?
                                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={deleteWork}>Delete</Button>
                                :
                                null
                    }
                    <LightTooltip title={errorMessage(isValidForm)}>
                        <span>
                            <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={saveWorkHistory} disabled={userType === 'admin' ? false : !isValidForm}>Save</Button>
                        </span>
                    </LightTooltip>
                </Modal.Footer>
            </Modal>
        </ThemeProvider>
    );
};

export default AddWorkHistoryModal;