import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import Settings from './Settings';
import Loading from '../../../../../assets/Images/loading.gif';


const InterestingFacts = props => {
    const dispatch = useDispatch();
    const [department, setDepartment] = React.useState("");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const interestingFactsResult = useSelector(state => state.dashboards.interestingFactsResult);

    function createData(assistantProf, associateProf, fullProf, other, description) {
        return { assistantProf, associateProf, fullProf, other, description };
    }
    React.useEffect(() => {
        interestingFacts(null);
    }, []);
    const interestingFacts = async (dept) => {
        const token = localStorage.getItem('rl_token');
        setShowSpinner(true);
        try {
            await dispatch(dashboardActions.interestingFacts(token, dept));
        } catch (error) {
            console.log(error);
        }
        setShowSpinner(false);
    }

    const handleSchoolDeptSwitchChange = async (department) => {
        if (department === null) {
            setDepartment(null);
            interestingFacts(null);
        }
        else {
            interestingFacts(department);
            setDepartment(department);
        }
    }

    function roundToTwoDecimal(num) {
        return Math.round((num + Number.EPSILON) * 10) / 10
    };

    const interestingFactsDummyData = [
        interestingFactsResult["all"] !== undefined ?
        createData(roundToTwoDecimal(interestingFactsResult["all"]["assistant"]),
            roundToTwoDecimal(interestingFactsResult["all"]["associate"]),
            roundToTwoDecimal(interestingFactsResult["all"]["full"]),
            roundToTwoDecimal(interestingFactsResult["all"]["other"]),
            "Avg papers by faculty") : {},
        
        interestingFactsResult["male"] !== undefined ?
        createData(roundToTwoDecimal(interestingFactsResult["male"]["assistant"]),
            roundToTwoDecimal(interestingFactsResult["male"]["associate"]),
            roundToTwoDecimal(interestingFactsResult["male"]["full"]),
            roundToTwoDecimal(interestingFactsResult["male"]["other"]),
            "Avg papers by male faculty") : {},

        interestingFactsResult["female"] !== undefined ?
        createData(roundToTwoDecimal(interestingFactsResult["female"]["assistant"]),
            roundToTwoDecimal(interestingFactsResult["female"]["associate"]),
            roundToTwoDecimal(interestingFactsResult["female"]["full"]),
            roundToTwoDecimal(interestingFactsResult["female"]["other"]),
            "Avg papers by female faculty") : {},

        interestingFactsResult["other"] !== undefined ?
        createData(roundToTwoDecimal(interestingFactsResult["other"]["assistant"]),
            roundToTwoDecimal(interestingFactsResult["other"]["associate"]),
            roundToTwoDecimal(interestingFactsResult["other"]["full"]),
            roundToTwoDecimal(interestingFactsResult["other"]["other"]),
            "Avg papers by other faculty") : {}
    ]

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[3],
          fontSize: 13,
        },
      }))(Tooltip);

    return (
        <Paper style={{ height: "240px", padding: "12px", overflow: "auto" }}>
            <div style={{ fontSize: "20px" }}>
                Interesting Facts{department === null || department === "" ? "" : ` - ${department}`}
                <span style={{ float: "right" }}>
                    <Settings handleSchoolDeptSwitchChange={handleSchoolDeptSwitchChange} department={department} />
                </span>
            </div>
            {
                showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <TableContainer style={{ marginTop: "12px" }}>
                        <Table size="medium" aria-label="simple table" style={{borderBottom: "0px"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'} padding={'none'} style={{ paddingRight: "4px", paddingBottom: "12px" }}>Assist</TableCell>
                                    <TableCell align={'center'} padding={'none'} style={{ paddingRight: "4px", paddingBottom: "12px" }}>Assoc</TableCell>
                                    <TableCell align={'center'} padding={'none'} style={{ paddingRight: "4px", paddingBottom: "12px" }}>Full</TableCell>
                                    <TableCell align={'center'} padding={'none'} style={{ paddingRight: "4px", paddingBottom: "12px" }} data-tip="Non Tenured Track">
                                        <LightTooltip title="Non Tenure Track" placement="right-start">
                                            <span>Non-TT</span>
                                        </LightTooltip>
                                    </TableCell>
                                    <TableCell align={'center'} padding={'none'} style={{ paddingRight: "4px", paddingBottom: "12px" }}>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {interestingFactsDummyData.map((row) => (
                                    <TableRow key={row.name} >
                                        <TableCell align={'center'} padding={'none'} style={{ paddingBottom: "12px" }}>{row.assistantProf}</TableCell>
                                        <TableCell align={'center'} padding={'none'} style={{ paddingBottom: "12px" }}>{row.associateProf}</TableCell>
                                        <TableCell align={'center'} padding={'none'} style={{ paddingBottom: "12px" }}>{row.fullProf}</TableCell>
                                        <TableCell align={'center'} padding={'none'} style={{ paddingBottom: "12px" }}>{row.other}</TableCell>
                                        <TableCell padding={'none'} style={{ paddingBottom: "12px" }}>{row.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </Paper>
    )
}

export default InterestingFacts;