import { Paper } from '@material-ui/core';
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import Theme from '../../../../../constants/Theme';
import ReactFusioncharts from "react-fusioncharts";
import ReactFC from "react-fusioncharts";
import Settings from './Settings';
import Loading from '../../../../../assets/Images/loading.gif';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, FusionTheme);

const TenureReadiness = props => {
  const theme = Theme();
  const dispatch = useDispatch();
  const secondaryColor = theme.palette.secondary.main;
  const [switchChecked, setswitchChecked] = React.useState(true);
  const [journaSwitchChecked, setjournalSwitchChecked] = React.useState(true);
  const [yearsTenureReadiness, setView] = React.useState('10years');
  const [journalTenureReadiness, setjournalView] = React.useState('A and A-');
  const [department, setDepartment] = React.useState(null);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const tenureReadinessCategory = useSelector(state => state.dashboards.tenureReadinessCategory);
  const tenureReadinessPapers = useSelector(state => state.dashboards.tenureReadinessPapers);
  const tenureReadinessCitations = useSelector(state => state.dashboards.tenureReadinessCitations);
  const tenureReadinessAverage = useSelector(state => state.dashboards.tenureReadinessAverage);
  const tenureReadinessFlag = useSelector(state => state.dashboards.tenureReadinessFlag);
  const chartOptions = {
    chart: {
      theme: "fusion",
      showLegend: "0",
    },
    categories: [{
      category: tenureReadinessCategory
    }],

    //TODO: make bar chart into percentage
    dataset: [{
      seriesname: "Papers",
      data: tenureReadinessPapers,
      color: "#bbb218"
    },
    {
      seriesname: "Citations",
      data: tenureReadinessCitations,
      color: "#00259A"
    }
    ],
    trendlines: [{
      line: [{
        startvalue: "100",
        color: "#F6A016",
        valueOnRight: "1",
        displayValue: " ",
        thickness: "2",
        showOnTop: true,
        toolText: "Average",
      }
      ]
    }]
  };
  
  const useStyles = makeStyles(theme => ({
    scroll: {
      maxHeight: "120px",
      overflowY: "auto"
    },
    padding: {
      paddingBottom: "0px"
    }
  }))
  const BlueSwitch = withStyles({
    switchBase: {
      color: secondaryColor
    },
    track: {
      color: secondaryColor,
      backgroundColor: secondaryColor
    },
    '&$checked': {
      backgroundColor: secondaryColor,
      color: secondaryColor
    },
  })(Switch);

  const classes = useStyles();

  React.useEffect(() => {
    getTenureReadiness(null);
  }, []);

  const getTenureReadiness = async (dept, year,journal) => {
    const token = localStorage.getItem('rl_token');
    setShowSpinner(true);
    try {
      await dispatch(dashboardActions.getTenureReadiness(token, year === undefined ? switchChecked ? 10 : 5 : year,journal === undefined ? journaSwitchChecked ? 'A and A-' : 'All' : journal, dept));
    }
    catch (err) {
      alert("Cannot get tenure readiness");
    }
    setShowSpinner(false);
  }

  const handleSchoolDeptSwitchChange = async (department) => {
    if (department === null) {
      getTenureReadiness(null);
      setDepartment(null);
    }
    else {
      getTenureReadiness(department);
      setDepartment(department);
    }
  }

  const handleJournalSwitchChange = (event) => {
    if (event.target.name === 'A and A-') {
      setjournalSwitchChecked(false);
      setjournalView('All')
    } else {
      setjournalSwitchChecked(true);
      setjournalView('A and A-')
    }
    getTenureReadiness(department,yearsTenureReadiness === '10years' ? 10 : 5,event.target.name === 'A and A-' ? 'All' : 'A and A-' );
  }
  const handleSwitchChange = (event) => {
    if (event.target.name === '10years') {
      setswitchChecked(false);
      setView('5years')
    } else {
      setswitchChecked(true);
      setView('10years')
    }
    getTenureReadiness(department, event.target.name === '10years' ? 5 : 10,journalTenureReadiness);
  }

  const getHeight = () => {

    if (tenureReadinessPapers.length <= 6)
      return "20%"
    else if (tenureReadinessPapers.length <= 10 )
      return "30%"
    else if (tenureReadinessPapers.length <= 20 )
      return "40%"
    else
      return "70%" 
  }

  const getGraphHeight = () => {

    if (department == null)
      return "260px"
    else
      return "100px" 
  }

  return (
    <Paper style={{ height: "240px", padding: "12px" }}>
      <div style={{ fontSize : "20px"}}>
        Tenure Readiness{department === null || department === "" ? "" : ` - ${department}`}
        <span style={{ float: "right" }}>
          <Settings handleSchoolDeptSwitchChange={handleSchoolDeptSwitchChange} department={department} />
        </span>
      </div>
      <span style={{ padding: "1px", fontSize: "13px"}}><span>5 years</span>
        <BlueSwitch checked={switchChecked} onChange={handleSwitchChange} name={yearsTenureReadiness} color="secondaryColor" /><span>10 years</span></span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ padding: "1px",fontSize: "13px" }}><span>All</span>
        <BlueSwitch checked={journaSwitchChecked} onChange={handleJournalSwitchChange} name={journalTenureReadiness} color="secondaryColor" /><span>A&A-</span></span>
      {
        showSpinner ?
          <div style={{ textAlign: "center", height: "260px" }}>
            <img src={Loading} alt="Loading..." width="80px" height="80px" />
          </div>
          :
          <div>
            <div style={{ fontSize: "12px", padding: "0px", textAlign: "center" }}>
            <span style = {{background: "#F6A016", fontWeight: "bold"}} >Avg: {tenureReadinessAverage === undefined ? "" : parseFloat(tenureReadinessAverage.AveragePapers).toFixed(2)} Papers, {tenureReadinessAverage === undefined ? "" : parseFloat(tenureReadinessAverage.AverageCitations).toFixed(2)} Citations </span>
            &nbsp;&nbsp;
            <span style={{ fontSize: "10px", padding: "0px"}}>{tenureReadinessFlag === 0?"":"*School Average"}</span>
            </div>
            <div style={{ height: getGraphHeight() }} className={classes.scroll}>
              {
                tenureReadinessPapers.length === 0 ?
                <div style={{textAlign: "center"}}>No Data to Display</div>:
                <ReactFusioncharts type="msbar2d" width="100%" height={getHeight()} dataFormat="JSON" dataSource={chartOptions} />
              }
            </div>
          </div>
      }
    </Paper>
  )
}

export default TenureReadiness;