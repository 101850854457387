import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import { BACKEND_URL } from '../../../../../utils';
import SinglePreFilledAutoComplete from '../../../../InputComponents/SinglePreFilledAutoComplete';
import * as classes from './Education.module.css';
import InfoIcon from '@material-ui/icons/Info';

const presentYear = new Date().getFullYear();
const AddEducationModal = props => {
    
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const [degree, setDegree] = useState("");
    const [year, setYear] = useState("");
    const [university, setUniversity] = useState("");
    const [area, setArea] = useState("");
    const [id, setId] = useState()
    const [isNew, setIsNew] = useState(true)
    const [isValidForm, setIsValidForm] = useState(false);
    const [advisors, setAdvisors] = useState([])
    const [valueUniversity, setValueUniversity] = useState("");
    const [optionsUniversity, setOptionsUniversity] = React.useState([]);
    const [loadingUniversity, setLoadingUniversity] = React.useState(false);
    const [showInfo, setShowInfo] = React.useState(false);
    const userType = useSelector(state => state.auth.user_type);

    const phdRegex = /^(P|p)\.?(H|h)\.?(D|d)\.?$/; //Doctorate in Business Administration
    const dbaRegex = /^(D|d)\.?(B|b)\.?(A|a)\.?$/; //Doctor of Science
    const dscRegex = /(^(D|d)\.?(S|s)\.?(C|c)\.?$)|(^(S|s)\.?(C|c)\.?(D|d)\.?$)/; //Doctor of the Science of Law
    const jsdRegex = /^(J|j)\.?(S|s)\.?(D|d)\.?$/; //Doctor of the Science of Law
    const lhdRegex = /^(L|l)\.?(H|h)\.?(D|d)\.?$/; //Doctor of Humane Letters
    const mdRegex = /^(M|m)\.?(D|d)\.?$/; //Doctor of Medicine
    const daRegex = /^(D|d)\.?(A|a)\.?$/; //Doctor of Arts
    const dphilRegex = /^(D|d)\.?(P|p)(H|h)(I|i)(L|l)$/; //Doctor of Philosophy

    useEffect(() => {
        setId(props.curEducation.id)
        setDegree(props.curEducation.degree)
        setUniversity(props.curEducation.institution)
        setYear(props.curEducation.year)
        setArea(props.curEducation.area)
        setIsNew(props.curEducation.isNew)
        if (props.curEducation && props.curEducation.institution && props.curEducation.institution.trim() !== "" && props.curEducation.degree && props.curEducation.degree.trim() !== "" && props.curEducation.year !== "" && props.curEducation.year >= 1950 && props.curEducation.year <= presentYear && props.curEducation.area && props.curEducation.area.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
        setAdvisors([[props.curEducation.advisorFirstName, props.curEducation.advisorLastName]]);
    }, [props.curEducation]);

    const saveEducation = () => {
        props.onSave({
            id: id, degree: degree, isNew: isNew, institution: university, year: year, area: area,
            advisorFirstName: (advisors.length === 0 || advisors[0][0] === undefined) ? '' : advisors[0][0].trim(),
            advisorLastName: (advisors.length === 0 || advisors[0][1] === undefined) ? '' : advisors[0][1].trim()
        });
        setDegree("");
        setYear("");
        setUniversity("");
        setArea("");
        setId(0)
        setAdvisors([])
        setIsNew(true)
    };

    const deleteEducation = () => {
        props.onHide(id)
    };

    const degreeChangeHandler = event => {
        const value = event.target.value;
        const re = /.*(P|p)\.?(H|h)\.?(D|d)\.?.*/;
        if (re.test(value) === true) {
            setShowInfo(true);
        } else {
            setShowInfo(false);
        }
        if (degree.length <= value.length) {
            if (phdRegex.test(value) === true) {
                setDegree("Ph.D.");
            } else if (dbaRegex.test(value) === true) {
                setDegree("D.B.A");
            } else if (dscRegex.test(value) === true) {
                setDegree("D.Sc.");
            } else if (jsdRegex.test(value) === true) {
                setDegree("JSD");
            } else if (lhdRegex.test(value) === true) {
                setDegree("LHD");
            } else if (mdRegex.test(value) === true) {
                setDegree("M.D.");
            } else if (daRegex.test(value) === true) {
                setDegree("DA");
            } else if (dphilRegex.test(value) === true) {
                setDegree("Ph.D.");
            } else {
                setDegree(value);
            }
        } else {
            setDegree(value);
        }

        if (university.trim() !== "" && event.target.value.trim() !== "" && year !== "" && year >= 1950 && year <= presentYear && area.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const areaChangeHandler = event => {
        setArea(event.target.value);
        if (university.trim() !== "" && degree.trim() !== "" && year !== "" && year >= 1950 && year <= presentYear && event.target.value.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const yearChangeHandler = event => {
        setYear(parseInt(event.target.value));
        if (university.trim() !== "" && degree.trim() !== "" && event.target.value.trim() !== "" && event.target.value >= 1950 && event.target.value <= presentYear && area.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const universityChangeHandler = (name, value) => {
        setUniversity(name);
        if (name && name.trim() !== "" && optionsUniversity.map(univ => univ.name).includes(name) === true && degree && degree.trim() !== "" && year !== "" && year >= 1950 && year <= presentYear && area && area.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const errorMessage = (isValidForm) => {
        if (isValidForm) {
            return "";
        } else {
            var message = "Incomplete form: ";
            if (!degree || degree.trim() === "") {
                message += "Enter Degree, ";
            }
            if (!area || area.trim() === "") {
                message += "Enter Area, ";
            }
            if (!university || university.trim() === "" || (optionsUniversity.length !== 0 && optionsUniversity.map(univ => univ.name).includes(university) === false)) {
                
                message += "Select University, ";
            }
            if (year === "") {
                message += "Enter Year, ";
            } else if (year < 1950 || year > presentYear) {
                message += "Enter Valid Year between 1950 and ";
                message += (presentYear.toString()) + ", ";
            }
        }
        return message.slice(0, message.length - 2);
    };

    const advisorFirstNameHandler = event => {
        if (advisors.length === 0) {
            setAdvisors([[event.target.value, ""]])
        } else {
            var curAdvisor = advisors[0]
            setAdvisors([[event.target.value, curAdvisor[1]]])
        }
    };

    const advisorLastNameHandler = event => {
        if (advisors.length === 0) {
            setAdvisors([["", event.target.value]])
        } else {
            var curAdvisor = advisors[0]
            setAdvisors([[curAdvisor[0], event.target.value]])
        }
    };

    const onInputUniversityChangeHandler = (event, newInputValue) => {
        setValueUniversity(newInputValue)
        universityChangeHandler(newInputValue, newInputValue)
        setLoadingUniversity(true)
        if (!newInputValue || newInputValue.trim() === "") {
            setLoadingUniversity(false)
            return;
        }
        const url = BACKEND_URL + 'auto/univ/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsUniversity(Object.keys(schools.universities).map((key) => schools.universities[key]));
            setLoadingUniversity(false)
        })();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Education
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row md={12}>
                        {
                            showInfo === true &&
                            <div style={{ fontSize: "1rem", paddingLeft: "15px" }}>
                                <InfoIcon fontSize="small" style={{ color: "gray" }} />
                                <span style={{ verticalAlign: "middle" }}>PHD year is used as the first year of your career. Please do not enter Honorary PHD as Degree.</span>
                            </div>
                        }
                    </Row>
                    <Row md={12}>
                        <Col md={3}>
                            <TextInputControlled label="Degree" onChange={degreeChangeHandler.bind(this)} value={degree}>
                            </TextInputControlled>
                        </Col>
                        <Col md={2}>
                            <TextInputControlled label="Area" onChange={areaChangeHandler.bind(this)} value={area}>
                            </TextInputControlled>
                        </Col>
                        <Col md={5}>
                            <SinglePreFilledAutoComplete name="name" value={university}
                                onInputChange={onInputUniversityChangeHandler}
                                inputValue={valueUniversity}
                                loading={loadingUniversity}
                                label="Select University"
                                options={optionsUniversity.map(option => option.name)} />
                        </Col>
                        <Col md={2}>
                            <TextInputControlled label="Year" onChange={yearChangeHandler.bind(this)} value={year}>
                            </TextInputControlled>
                        </Col>
                    </Row>
                    <Row md={12} style={{ marginTop: "25px" }}>
                        <Col md={2}></Col>
                        <Col md={4}>
                            <TextInputControlled label="Advisor First Name" onChange={advisorFirstNameHandler.bind(this)} value={advisors.length === 0 ? "" : advisors[0][0]}>
                            </TextInputControlled>
                        </Col>
                        <Col md={4}>
                            <TextInputControlled label="Advisor Last Name" onChange={advisorLastNameHandler.bind(this)} value={advisors.length === 0 ? "" : advisors[0][1]}>
                            </TextInputControlled>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {
                    isValidForm === false && userType === 'admin' &&
                    <Row className={classes.row} md={12}>
                        <Col md={12}>
                            <InfoIcon fontSize="small" style={{ color: "gray" }} />
                            <span style={{ verticalAlign: "middle" }}><strong>Note for admin</strong> - {errorMessage(isValidForm)}</span>
                        </Col>
                    </Row>
                }
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={deleteEducation}>{isNew ? "Close" : "Delete"}</Button>
                <LightTooltip title={errorMessage(isValidForm)}>
                    <span>
                        <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={saveEducation} disabled={userType === 'admin' ? false : !isValidForm}>Save</Button>
                    </span>
                </LightTooltip>
            </Modal.Footer>
        </Modal>
    );
};

export default AddEducationModal;