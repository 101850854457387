import React from 'react';
import { useEffect } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Theme from '../../../../constants/Theme';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { createFilterOptions } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Loading from '../../../../assets/Images/loading.gif';
import { BACKEND_URL } from '../../../../utils';
import * as authorRankActions from '../../../../store/actions/authorRank';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import CollapsibleTable from './CollapsibleTableComponent/CollapsibleTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MultiSelect from "../../../InputComponents/MultiSelect";
import MultipleSelect from '../../../InputComponents/MultipleSelect';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import './AuthorRank.css';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    checkbox: {
        "&$checked": {
            color: "#BBB219"
        }
    },
    tag: {
        "& .MuiChip-label": {
            color: "black",
            fontSize: "1rem"
        },
        "& .MuiAutocomplete-tag": {
            background: "#f2f2f2"
        }
    }
}));

const Authorrank = props => {
    const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const dispatch = useDispatch();
    const theme = Theme();
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showResult, setShowResult] = React.useState(false);
    const [universityOptions, setUniversityOptions] = React.useState([]);
    const [selectedUniversities, setSelectedUniversities] = React.useState([]); // Final List of Universities 
    const [universityDropdownSelected, setuniversityDropdownSelected] = React.useState([]); // Maintains the structure to work with MultiSelect Autocomplete Component
    const facultyTypeOptions = ["Assistant Professor", "Associate Professor", "Full Professor"];
    const [facultyTypeErr, setFacultyTypeErr] = React.useState(false);
    const [selectedFacultyType, setSelectedFacultyType] = React.useState(facultyTypeOptions);
    const genderOptions = ["Female", "Male", "Other"];
    const [genderErr, setGenderErr] = React.useState(false);
    const [selectedGenders, setSelectedGender] = React.useState(genderOptions);
    const [departmentDropdownSelected, setDepartmentDropdownSelected] = React.useState([]); // Maintains the structure to work with MultiSelect Autocomplete Component
    const [departmentOptions, setDepartmentOptions] = React.useState([]);
    const [selectedDepartments, setSelectedDepartments] = React.useState([]); // Final List of Departments 
    const [selectedJournals, setSelectedJournals] = React.useState([]);
    const [journalsOption, setJournalsOption] = React.useState([{ "title": "A journals", "type": "A" }, { "title": "A- journals", "type": "A-" }, { "title": "B journals", "type": "B" }]);
    const [isCompleteSchoolSwitch, setIsCompleteSchoolSwitch] = React.useState(false);
    const facultyUnivId = useSelector(state => state.profile.profile.univ_id)
    const univId = useSelector(state => state.univProfile._id);
    const headCells = [
        { id: 'first_name', numeric: false, allowSort: true, disablePadding: true, label: 'First Name' },
        { id: 'last_name', numeric: false, allowSort: true, disablePadding: false, label: 'Last Name' },
        { id: 'total_papers', numeric: true, allowSort: true, disablePadding: false, label: '#Papers' },
        { id: 'total_citations', numeric: true, allowSort: true, disablePadding: false, label: '#Citations' },
    ];
    const completeHeadCells = [
        { id: 'h_index_score', numeric: true, allowSort: true, disablePadding: true, label: 'H-Index' },
        { id: 'percent_solo', numeric: true, allowSort: true, disablePadding: false, label: '%Solo papers' },
        { id: 'papersPerYear', numeric: true, allowSort: true, disablePadding: false, label: 'Papers/year' },
        { id: 'citationsPerYear', numeric: true, allowSort: true, disablePadding: false, label: 'Citations/year' },
        // { id: 'gender', numeric: false, allowSort: true, disablePadding: false, label: 'Gender' },
    ];
    const authorData = useSelector(state => state.rank.authors);
    const completeAuthorData = useSelector(state => state.rank.authorsInCompleteUniv);
    const [universityData, setUniversityData] = React.useState({});
    const [journalDict, setJournalDict] = React.useState({});
    const [filterDepartmentJournals, setFilterDepartmentJournals] = React.useState({
        A: [],
        Aminus: [],
        B: []
    });

    const [journalCheckbox, setJournalCheckbox] = React.useState({
        checkedA: false,
        checkedAminus: false,
        checkedB: false,
    });

    const [displayFacultyText, setDisplayFacultyText] = React.useState(selectedFacultyType.toString())
    const [displayGenderText, setDisplayGenderText] = React.useState(selectedGenders.toString())
    const [displayDepartmentText, setDisplayDepartmentText] = React.useState(selectedDepartments.toString())

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuPropsStyles = {
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 200,
    });

    // Hit the Department Config API to get the list of departments and its journals 
    // segregated as A,A- and B Journals
    const getUniversityMapping = async (token, univ_id) => {
        let url = BACKEND_URL + "dept?id=" + univ_id
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            alert("Unable to fetch Department Config Data!!!")
        }
        else {
            const response_data = await response.json();
            let createDepartmentOption = [];
            let deptList = [];
            let tempUnivData = {};
            response_data.departments.forEach(deptObj => {
                let deptName = deptObj['name'];
                deptList.push(deptName);
                createDepartmentOption.push({
                    label: deptName,
                    value: deptName
                });
                tempUnivData[deptName] = deptObj;
            });
            if (createDepartmentOption.length === 0) {
                response_data.default_mapping.forEach(deptObj => {
                    let deptName = deptObj['name'];
                    deptList.push(deptName);
                    createDepartmentOption.push({
                        label: deptName,
                        value: deptName
                    });
                    tempUnivData[deptName] = deptObj;
                });
            }
            setUniversityData(tempUnivData);
            setDepartmentOptions(createDepartmentOption);
            setDepartmentDropdownSelected(createDepartmentOption);
            setSelectedDepartments(deptList)
            if (createDepartmentOption.length > 0) {
                setDisplayDepartmentText(deptList.toString())
            }
            else {
                setDisplayDepartmentText("* Select Departments *")
            }
            setJournalDict(response_data.journals);
        }
    }

    // Hit API to get list of all universities(completed)
    const getUniversityOptions = async (token) => {
        let url = BACKEND_URL + "get_universities/completed"
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            alert("Unable to fetch Universities Data!!!")
        }
        else {
            const response_data = await response.json();

            let createUniversityOptions = []
            for (let i in response_data) {
                createUniversityOptions.push({
                    label: response_data[i].name,
                    value: response_data[i].name
                })
            }
            setUniversityOptions(createUniversityOptions);
            // setUniversityOptions([{ "name": "All" }].concat(response_data));
        }
    }

    // Hit API to get all journal options
    const getJournalsOptions = async (token, user_type) => {
        let url = BACKEND_URL + "get_journals/" + user_type
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            alert("Unable to fetch Journals Data!!!")
        }
        else {
            const response_data = await response.json();
            setJournalsOption(response_data)
        }
    }

    // Below functions are for MultiSelect Autocomplete for University 

    // Updates single select of university from the dropdown
    const handleUniversityToggleOption = universityDropdownSelected => {
        setuniversityDropdownSelected(universityDropdownSelected.sort(function (a, b) {
            if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
            if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
            return 0;
        }));
        dropdownSelectedUniversityToArray(universityDropdownSelected)
    };

    // Clears all selected universities --> Reset 
    const handleUniversityClearOptions = () => {
        setuniversityDropdownSelected([]);
        dropdownSelectedUniversityToArray([])
    };

    // Select all universities
    const handleUniversitySelectAll = isSelected => {
        if (isSelected) {
            setuniversityDropdownSelected(universityOptions.sort(function (a, b) {
                if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
                if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
                return 0;
            }));
            dropdownSelectedUniversityToArray(universityOptions)
        } else {
            handleUniversityClearOptions();
        }
    };

    // Converts the structure (List of Objects) used for Autocomplete to List of University Names
    // converts universityDropdownSelected to SelectedUniversities
    const dropdownSelectedUniversityToArray = (updateUniversity) => {
        let selectedUniversityArray = []
        for (let i in updateUniversity) {
            selectedUniversityArray.push(updateUniversity[i].value)
        }
        setSelectedUniversities(selectedUniversityArray)
    }

    // Faculty Change
    const handleFacultyTypeChange = (event) => {

        setShowResult(false);
        setSelectedFacultyType(event.target.value);

        if (event.target.value.length > 0) {
            setFacultyTypeErr(false);
            setDisplayFacultyText(event.target.value.toString())
        }
        else {
            setDisplayFacultyText("* Select Faculty Type *")
        }
    }

    // Gender Change
    const handleGenderChange = (event) => {
        setShowResult(false);
        let tempSelectedGender = (event.target.value).sort(function (a, b) {
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
            return 0;
        })
        setSelectedGender(tempSelectedGender)
        if (event.target.value.length > 0) {
            setGenderErr(false);
            setDisplayGenderText(event.target.value.toString())
        }
        else {
            setDisplayGenderText("* Select Gender Type *")
        }
    }

    // Below functions are for MultiSelect Autocomplete for Department 

    // Updates single select of department from the dropdown
    const handleDepartmentToggleOption = departmentDropdownSelected => {
        setDepartmentDropdownSelected(departmentDropdownSelected);
        dropdownSelectedDepartmentToArray(departmentDropdownSelected)
    };

    // Clears all selected departments --> Reset
    const handleDepartmentClearOptions = () => {
        setDepartmentDropdownSelected([]);
        dropdownSelectedDepartmentToArray([])
    };

    // Select all departments
    const handleDepartmentSelectAll = isSelected => {
        if (isSelected) {
            setDepartmentDropdownSelected(departmentOptions);
            dropdownSelectedDepartmentToArray(departmentOptions)
        } else {
            handleDepartmentClearOptions();
        }
    };

    // Converts the structure (List of Objects) used for Autocomplete to List of University Names
    const dropdownSelectedDepartmentToArray = (updateDepartment) => {
        let selectedDepartmentArray = []
        for (let i in updateDepartment) {
            selectedDepartmentArray.push(updateDepartment[i].value)
        }
        setSelectedDepartments(selectedDepartmentArray)
        if (selectedDepartmentArray.length > 0) {
            setDisplayDepartmentText(selectedDepartmentArray.toString())
        }
        else {
            setDisplayDepartmentText("* Select Departments *")
        }
        handleDepartment(selectedDepartmentArray)
    }

    // Based on the departments are selected, we filter the journal and store them in filterDepartmentJournals
    const handleDepartment = (tempDepartment) => {
        setShowResult(false);

        let tempA = [];
        let tempB = [];
        let tempAminus = [];
        let tempFilterList = {}
        tempDepartment.map(dept => {
            Object.entries(universityData[dept]["A"]).map(([key, value]) => {
                tempA.push({ "issn": value, "title": journalDict[value], "type": "A" })
            })
            Object.entries(universityData[dept]["A-"]).map(([key, value]) => {
                tempAminus.push({ "issn": value, "title": journalDict[value], "type": "A-" })
            })
            Object.entries(universityData[dept]["B"]).map(([key, value]) => {
                tempB.push({ "issn": value, "title": journalDict[value], "type": "B" })
            })
        })
        tempFilterList = { A: tempA, Aminus: tempAminus, B: tempB }
        setFilterDepartmentJournals({ A: tempA, Aminus: tempAminus, B: tempB })

        // If the checkbox option is active/true, it updates the selected journal list to display on journal autocomplete
        let nameMapping = { "checkedA": "A", "checkedAminus": "Aminus", "checkedB": "B" }
        if (journalCheckbox["checkedA"] || journalCheckbox["checkedAminus"] || journalCheckbox["checkedB"]) {
            let currJournals = new Set([])
            for (let temp in journalCheckbox) {
                if (journalCheckbox[temp]) {
                    for (let i = 0; i < tempFilterList[nameMapping[temp]].length; i++) {
                        currJournals.add(tempFilterList[nameMapping[temp]][i])
                    }
                }
            }
            setSelectedJournals([...currJournals])
        }
    }

    // Initialise the variables and keep updating based on the dependencies.
    React.useEffect(function filterJournalsbyAllDepartment() {
        if (universityData) {
            let tempA = [];
            let tempB = [];
            let tempAminus = [];
            for (let univDept in universityData) {
                Object.entries(universityData[univDept]["A"]).map(([key, value]) => {
                    tempA.push({ "issn": value, "title": journalDict[value], "type": "A" })
                })
                Object.entries(universityData[univDept]["A-"]).map(([key, value]) => {
                    tempAminus.push({ "issn": value, "title": journalDict[value], "type": "A-" })
                })
                Object.entries(universityData[univDept]["B"]).map(([key, value]) => {
                    tempB.push({ "issn": value, "title": journalDict[value], "type": "B" })
                })
            }
            setFilterDepartmentJournals({ A: tempA, Aminus: tempAminus, B: tempB })
        }
    }, [universityData, journalDict])

    // Updates the Journal CheckBox --> A checkbox that works as radio button but can also have none as default/selection criteria.
    // A refers to A journals
    // Aminus refers to A and A- Journals both 
    // B refers to All journals
    const handleJournalCheckBox = (event) => {
        if (event.target.checked) {
            let tempCheckbox = {}
            for (let check in journalCheckbox) {
                if (check === event.target.name) {
                    tempCheckbox[check] = true
                }
                else {
                    tempCheckbox[check] = false
                }
            }
            setJournalCheckbox(tempCheckbox);
        }
        else {
            setJournalCheckbox({ ...journalCheckbox, [event.target.name]: event.target.checked });
        }
        let nameMapping = { "checkedA": "A", "checkedAminus": "Aminus", "checkedB": "B" }
        updateSelectedJournal(nameMapping[event.target.name], event.target.checked)
    }

    // Based on the checkbox selection, we update our journal list.
    // A refers to A journals
    // Aminus refers to A and A- Journals both 
    // B refers to All journals
    const updateSelectedJournal = (journalType, updateType) => {
        let currJournals = new Set()
        if (updateType) {
            // if(journalType === "A"){
            for (let i = 0; i < filterDepartmentJournals['A'].length; i++) {
                currJournals.add(filterDepartmentJournals['A'][i])
            }
            // }
            // else if(journalType === "Aminus")
            if (journalType !== "A") {
                // for (let i = 0; i < filterDepartmentJournals['A'].length; i++) {
                //     currJournals.add(filterDepartmentJournals['A'][i])
                // }
                for (let i = 0; i < filterDepartmentJournals['Aminus'].length; i++) {
                    currJournals.add(filterDepartmentJournals['Aminus'][i])
                }
            }
            // else 
            if (journalType === "B") {
                // for (let i = 0; i < filterDepartmentJournals['A'].length; i++) {
                //     currJournals.add(filterDepartmentJournals['A'][i])
                // }
                // for (let i = 0; i < filterDepartmentJournals['Aminus'].length; i++) {
                //     currJournals.add(filterDepartmentJournals['Aminus'][i])
                // }
                for (let i = 0; i < filterDepartmentJournals['B'].length; i++) {
                    currJournals.add(filterDepartmentJournals['B'][i])
                }
            }
            // for (let i = 0; i < filterDepartmentJournals[journalType].length; i++) {
            //     currJournals.add(filterDepartmentJournals[journalType][i])
            // }
        }
        // else {
        //     for (let i = 0; i < filterDepartmentJournals[journalType].length; i++) {
        //         if ((filterDepartmentJournals[journalType][i]["type"] === nameMapping[journalType])) {
        //             currJournals.delete(filterDepartmentJournals[journalType][i])
        //         }
        //     }
        // }
        setSelectedJournals([...currJournals])
    }

    // Updates the journal selection based on any single select from the dropdown. (select/unselect)
    // Also handles clear all 
    const onJournalChange = (...params) => {
        setShowResult(false);
        let tempJournals = new Set(selectedJournals)
        if (params[2] === "clear") {
            tempJournals = [];
            setJournalCheckbox({ ...journalCheckbox, "checkedA": false, "checkedAminus": false, "checkedB": false })
            // setFilterDepartmentJournals({ A: [], Aminus: [], B: [] })
        }
        else {
            let selectedOption = params[3] !== undefined ? params[3].option : null;
            if (params[2] === "select-option") {
                tempJournals.add(selectedOption);
            }
            else if (params[2] === "remove-option") {
                tempJournals = [...tempJournals].filter(journal => journal["title"] !== selectedOption["title"]);
            }
        }
        setSelectedJournals([...tempJournals])
    }

    const handleIsCompleteSwitch = () => {
        setIsCompleteSchoolSwitch(!isCompleteSchoolSwitch);
    }

    useEffect(() => {
        //Fetch universities
        const token = localStorage.getItem("rl_token");
        const user_type = localStorage.getItem("rl_type");
        var university_id = ""
        if (user_type === "university") {
            university_id = univId;
        } else {
            university_id = facultyUnivId;
        }
        getUniversityMapping(token, university_id);
        getUniversityOptions(token);
        getJournalsOptions(token, user_type);
    }, []);

    // Hit API to get the response
    const search = async () => {
        // if (userType !== 'admin') {
        setShowSpinner(true);
        setShowResult(true);
        const token = localStorage.getItem("rl_token");
        await dispatch(authorRankActions.fetchAuthorRanking(token, selectedUniversities, selectedFacultyType, selectedGenders, selectedDepartments, selectedJournals));
        setAccordionExpand(false);
        setShowSpinner(false);
        // } else {
        //     alert("Please Login through university/faculty login")
        // }
    }


    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className="author-rank-headline">
                    Faculty Ranking
                </div>
                <div>* Please select all fields to enable the Search Button</div>
                <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                    <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />} >
                        <div style={{ float: "left" }}>
                            {/* <div>
                                Considering {displayFacultyText} as Faculty Type, {displayGenderText} as Gender Type and {displayDepartmentText} as Departments
                                along with Universities and Journals selected below as input for Faculty Ranking.
                            </div> */}
                            <Grid container>
                                Considering the Universities and Journals selected below as input along with
                                <Grid container>
                                    <Grid item xs={6}>
                                        <b>Faculty Type :</b> {displayFacultyText}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <b>Gender Type :</b> {displayGenderText}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <b>Departments :</b> {displayDepartmentText}
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: "1rem" }}>
                                {
                                    accordionExpand ? "Select filters for ranking" : "View selected filters"
                                }
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container>
                            <Row md={12} className="author-rank-row">
                                <Col md={12} style={{ paddingBottom: "10px" }}>
                                    {/* Add University Select */}
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <MultiSelect
                                            items={universityOptions}
                                            getOptionLabel={option => `${option.label}`}
                                            getOptionDisabled={option => option.value === universityOptions[0] !== undefined ? universityOptions[0].value : ""}
                                            selectedValues={universityDropdownSelected}
                                            label="University"
                                            placeholder="Select University"
                                            selectAllLabel="Select all"
                                            onToggleOption={handleUniversityToggleOption}
                                            onClearOptions={handleUniversityClearOptions}
                                            onSelectAll={handleUniversitySelectAll}
                                            limitTags={10}
                                            disablePortal={false}
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row md={12} className="author-rank-row">
                                <Col md={6}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <MultipleSelect error={facultyTypeErr} onChange={handleFacultyTypeChange} options={facultyTypeOptions}
                                            value={selectedFacultyType} menuProps={MenuPropsStyles} label="Faculty type to consider" helperText="Select value for faculty type to consider" />
                                    </FormControl>
                                </Col>
                                <Col md={6}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <MultipleSelect error={genderErr} onChange={handleGenderChange} options={genderOptions}
                                            value={selectedGenders} menuProps={MenuPropsStyles} label="Gender type to consider" helperText="Select value for gender type to consider" />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row md={12} className="author-rank-row">
                                <Col md={6}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <MultiSelect
                                            items={departmentOptions}
                                            getOptionLabel={option => `${option.label}`}
                                            getOptionDisabled={option => option.value === departmentOptions[0] !== undefined ? departmentOptions[0].value : ""}
                                            selectedValues={departmentDropdownSelected}
                                            label="Department"
                                            placeholder="Select Department"
                                            selectAllLabel="Select all"
                                            onToggleOption={handleDepartmentToggleOption}
                                            onClearOptions={handleDepartmentClearOptions}
                                            onSelectAll={handleDepartmentSelectAll}
                                            disablePortal={false}
                                        />
                                    </FormControl>
                                </Col>
                                <Col md={6}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Autocomplete
                                            className={classes.tag}
                                            multiple
                                            onChange={onJournalChange}
                                            value={selectedJournals}
                                            limitTags={1}
                                            filterOptions={filterOptions}
                                            options={journalsOption}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option, value) => option.title === value.title}
                                            renderOption={(option, state) => {
                                                const selectUniversityIndex = selectedJournals.findIndex(
                                                    university => university.title === option.title
                                                );
                                                if (selectUniversityIndex > -1) {
                                                    state.selected = true;
                                                }
                                                return (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            className={classes.checkbox}
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={state.selected}
                                                        />
                                                        {option.title}
                                                    </React.Fragment>)
                                            }
                                            }
                                            fullWidth={true}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="filled" label="Journals" placeholder="Select journals" />
                                            )}
                                        />
                                    </FormControl>
                                    <Grid container>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={journalCheckbox.checkedA} onChange={handleJournalCheckBox} name="checkedA" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
                                                label="A Journals"
                                            />
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={journalCheckbox.checkedAminus} onChange={handleJournalCheckBox} name="checkedAminus" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
                                                label="A & A- Journals"
                                            />
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={journalCheckbox.checkedB} onChange={handleJournalCheckBox} name="checkedB" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />}
                                                label="All Journals"
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                            </Row>
                            <Row md={12} className="author-rank-row">
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Button variant="contained" className="searchButton" color="primary" onClick={search}
                                        disabled={!(selectedUniversities.length !== 0 && selectedFacultyType.length !== 0 && selectedDepartments.length !== 0
                                            && selectedGenders.length !== 0 && selectedJournals.length !== 0)}
                                    >Search</Button>
                                </Col>
                            </Row>
                            <div>
                                * Standard response time is around 50s. If you select All Universities, A & A- Journals or All Journals as input, the processing might take some more time with the response. Click Search if you still wish to proceed.
                            </div>
                        </Container>
                    </AccordionDetails>
                </Accordion>

            </div>
            {
                (showResult || showSpinner) &&

                <div style={{
                    backgroundColor: "#fff", borderRadius: "2px", boxShadow: "0 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.22)",
                    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", marginRight: 20, marginLeft: 7, marginTop: 15, zIndex: "-10", padding: "1rem"
                }}>
                    {showSpinner &&
                        <div style={{ textAlign: "center" }}>
                            <img src={Loading} alt="Loading..." width="80px" height="80px" />
                        </div>
                    }
                    {
                        showResult && !showSpinner &&
                        <div>
                            <div style={{ display: "flex", minHeight: "50px", padding: "15px", justifyContent: "flex-end" }}>
                                <div>
                                    <FormControlLabel
                                        control={<Switch checked={isCompleteSchoolSwitch} onChange={handleIsCompleteSwitch} />}
                                        label="Compare Complete School"
                                    />
                                </div>
                            </div>
                            <CollapsibleTable
                                columns={headCells.concat(isCompleteSchoolSwitch ? completeHeadCells : [])}
                                showIndex={true}
                                defaultOrder={"desc"}
                                data={isCompleteSchoolSwitch ? completeAuthorData : authorData}
                                defaultLabel={"total_papers"}
                                displayOnCollapse={(row) => {
                                    return (
                                        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", paddingBottom: "10px" }}>
                                            <div style={{ flex: 6 }}>
                                                <div style={{ width: "100%" }}>
                                                    <p style={{ marginBottom: 0 }}><b>University: </b>{row.univ_name}</p>
                                                </div>
                                                <div style={{ width: "100%" }}>
                                                    <p style={{ marginBottom: 0 }}><b>Title: </b>{row.position}</p>
                                                </div>
                                                {row.gender &&
                                                    <div style={{ width: "100%" }}>
                                                        <p style={{ marginBottom: 0 }}><b>Gender: </b>{row.gender === "m" ? "Male" : (row.gender === "f" ? "Female" : "Other")}</p>
                                                    </div>
                                                }
                                                <div style={{ width: "100%" }}>
                                                    <p style={{ marginBottom: 0 }}><b>A: </b>{row.A_papers} <b> A-: </b>{row.AMinus_papers}<b> B: </b>{row.B_papers}</p>
                                                </div>

                                            </div>
                                            <div style={{ flex: 4 }}>
                                                <table style={{ border: "0px" }}>
                                                    <tr>
                                                        <th>
                                                        </th>
                                                        <th>

                                                        </th>
                                                        <th>
                                                            Last 5 years
                                                        </th>
                                                        <th>
                                                            Last 10 years
                                                        </th>
                                                        <th>
                                                            Overall
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            share of papers
                                                        </th>
                                                        <td></td>
                                                        <td>
                                                            {row.share_papers_5}
                                                        </td>
                                                        <td>
                                                            {row.share_papers_10}
                                                        </td>
                                                        <td>
                                                            {row.share_papers}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            share of citations
                                                        </th>
                                                        <td></td>
                                                        <td>
                                                            {row.share_citations_5}
                                                        </td>
                                                        <td>
                                                            {row.share_citations_10}
                                                        </td>
                                                        <td>
                                                            {row.share_citations}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    }
                </div>
            }
        </ThemeProvider>
    )
}

export default Authorrank;
