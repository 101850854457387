// import * as actionTypes from '../actions/actionTypes';
// import { updateObject } from '../../utils';
// import {firstBy} from "thenby";

const initialState = {
    dummyState: []
}


const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default dashboardReducer;