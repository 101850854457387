import React, { forwardRef, useEffect, useSelector } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, Divider } from '@material-ui/core';
import '../SidebarNav/SidebarNav.css';
import * as authActions from '../../../../../store/actions/auth';
import * as profileActions from '../../../../../store/actions/profile';
import * as univProfileActions from '../../../../../store/actions/univProfile';
import * as facultyProductivityActions from '../../../../../store/actions/facultyProductivity';
import * as tenureReadinessActions from '../../../../../store/actions/tenureReadiness';
import * as hireImpactActions from '../../../../../store/actions/hireImpact';
import * as cohortAnalysisActions from '../../../../../store/actions/cohortAnalysis';
import * as perceptualMapActions from '../../../../../store/actions/perceptualMap';
import * as compareDepartmentsActions from '../../../../../store/actions/compareDepartments';
import * as compareGenderActions from '../../../../../store/actions/compareGender';
import * as exploreActions from '../../../../../store/actions/explore';
import * as authorRankActions from '../../../../../store/actions/authorRank';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    padding: 0
  },
  button: {
    color: "#FFFFFF",
    padding: '8px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0,
    fontWeight: 200,
    fontSize: "1rem",
    lineHeight: 1.167,
    letterSpacing: "0em",
    marginLeft: 14,
    '&:visited': {
      color: '#FFFFFF'
    },
    '&:focus': {
      backgroundColor: "#BBB218 !important",
      color: '#000000'
    },
    '&:hover': {
      backgroundColor: "#BBB218 !important",
      color: "#000000"
    },
    '&:active': {
      color: '#000000'
    }
  },
  a: {
    color: '#00FF00',
    '&:visited': {
      color: '#00FF00'
    },
    '&:focus': {
      color: '#00FF00'
    },
    '&:hover': {
      color: '#00FF00'
    },
    '&:active': {
      color: '#00FF00'
    }
  },
  icon: {
    color: '#FFFFFF',
    width: 32,
    height: 24,
    display: 'flex',
    alignItems: 'end',
    marginRight: 1,
    opacity: 0.4,
  },
  whiteDivider: {
    backgroundColor: "#ffffff"
  }

}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { adminPages, pages, className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const token = localStorage.getItem('rl_token');

  const clearReduxStore = () => {
    dispatch(profileActions.clearState());
    dispatch(univProfileActions.clearState());
    dispatch(facultyProductivityActions.clearState());
    dispatch(tenureReadinessActions.clearState());
    dispatch(hireImpactActions.clearState());
    dispatch(cohortAnalysisActions.clearState());
    dispatch(perceptualMapActions.clearState());
    dispatch(compareDepartmentsActions.clearState());
    dispatch(compareGenderActions.clearState());
    dispatch(exploreActions.clearState());
    dispatch(authorRankActions.clearState());
  }

  const click = (title) => {
    if (title === 'Log Out') {
      dispatch(authActions.logOut());
      clearReduxStore();
    }
  }

  useEffect(() => {
    dispatch(univProfileActions.get_univ_profile(token))
  }, [])

  return (
    <Paper style={{ maxHeight: '100%', overflow: 'auto', backgroundColor: '#555559', margin: '0px' }}>
      <List

        {...rest}
        className={clsx(classes.root, className)}
      >
        {pages.map(page => (
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}>
            <Button
              onClick={() => { click(page.title) }}
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
              style={{ margin: '0px' }}>
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}
        {adminPages.length > 0 && <Divider className={classes.whiteDivider} />}
        {adminPages.length > 0 ?
          adminPages.map(page => (
            <ListItem
              className={classes.item}
              disableGutters
              key={page.title}>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
                style={{ margin: '0px' }}>

                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          )) :
          null
        }
      </List>
    </Paper>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;