import React from 'react';
import PaperDisplay from '../PaperDisplay/paperDisplay';
import './displayRecord'
import { Typography, Avatar } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
const defaultFaculty = require('../../../assets/Images/default_faculty.png');

const DisplayRecord = props => {
    let record = props.record;
    
    return (
        <div  onClick={props.onButtonClick}
            style={{display:"flex", flexDirection: "row", justifyContent:"center", alignItems:"center", backgroundColor: "white", cursor:"pointer"}}>
            <div style={{flex: 0.5}}>
                <p style={{textAlign:"center"}}>{props.index + "."}</p>
            </div>
            <div style={{flex: 1}}>
                {record.profile_picture != null ?
                    (<Avatar alt={record.first_name} src={record.profile_picture} style={{ height: 75, width: 75 }} />)
                    :
                    (<Avatar style={{ height: 75, width: 75 }}>
                        <img alt="faculty" src={defaultFaculty} height={75} width={75}/>
                    </Avatar>)
                }
            </div>
            <div style={{flex: 8.5, display: "flex", flexDirection: "column"}}>
                <div style={{paddingTop:"10px"}}>
                    <Typography component="div" variant="body2" color="textPrimary" style={{fontWeight:'520', fontSize: 'large'}}>
                        {record.last_name+", "+record.first_name+(record.mid_name ? " "+record.mid_name : "")}
                    </Typography>
                    <Typography component="div" variant="body2" color="textPrimary">
                        {record.univ_name === null ? "" : record.univ_name}
                    </Typography>
                    <Typography component="div" variant="body2" color="textPrimary">
                        <i>{!("email" in record) || record.email === null ? "" : record.email}</i>
                    </Typography>
                    <Typography component="div" variant="body2" color="textPrimary">
                        {record.gsLink === null || !record.gsLink ? "" : <a id="gsLink" href={record.gsLink} target="_blank" rel="noopener noreferrer">Google Scholar profile <OpenInNew style={{ fontSize: 14 }}/></a>}
                    </Typography>
                    <Typography component="div" variant="body2" color="textPrimary">
                        {record.rgLink === null || !record.rgLink ? "" : <a id="rgLink" href={record.rgLink} target="_blank" rel="noopener noreferrer">ResearchGate profile <OpenInNew style={{ fontSize: 14 }}/></a>}
                    </Typography>
                </div>
                <div style={{display: "flex", flexDirection: "column", marginTop:"10px"}}>
                    <p style={{fontWeight:'550', marginBottom:0}}>Papers:</p>
                    {
                        record.papers.map((paper, i) => 
                        <div key={paper._id}>
                            <PaperDisplay key={paper._id} paper={paper} />
                            {i < record.papers.length-1 ? <hr style={{float:"left", width:"100%"}}></hr>: <hr style={{float:"left", width:"100%", color:"white", backgroundColor:"white", border:"white"}}></hr>}
                        </div>
                        )
                    }
                </div>
            </div>

        </div>
    )
}

export default DisplayRecord;
