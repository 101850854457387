import React from 'react';
import {
    Avatar, Typography, ListItemAvatar, Grid, Button, Snackbar, List, ListItem, ListItemText
} from '@material-ui/core';
import Rodal from 'rodal';
import PersonIcon from '@material-ui/icons/Person';
import './InputComponents.css';
import UnivAutoCompleteWithBlur from '../Autocomplete/UnivAutoCompleteWithBlur';
import MuiAlert from '@material-ui/lab/Alert';
import TextInput from '../InputComponents/TextInput';
import * as facultyInfoInputActions from '../../store/actions/facultyInfoInput';
import { useDispatch, useSelector } from 'react-redux';
import Theme from '../../constants/Theme';
import { ThemeProvider, makeStyles, withStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    addButton: {
        color: "black !important",
        width: "20% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
    img: {
        height: "30px",
    },
    listItemHeight: {
        height: "30px"
    }
}));
const StyledListItem = withStyles({
    selected: {
        backgroundColor: "#e8f5f8 !important"
    }
})(ListItem);
const FacultyInfoInput = React.forwardRef((props, ref) => {
    const theme = Theme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const nameRegex = RegExp(/^[a-zA-Z ]*$/);
    // const [confirmedFacultyId, setConfirmedFacultyId] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [universityName, setUniversityName] = React.useState('');
    const [isDirtyFname, setIsDirtyFname] = React.useState(false);
    const [isDirtyLname, setIsDirtyLname] = React.useState(false);
    const [isDirtyComingFrom, setIsDirtyComingFrom] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState();
    const facultyList = useSelector(state => state.facultyInfoInput.facultyData);
    const showModal = useSelector(state => state.facultyInfoInput.showModal);
    const confirmedFacultyId = useSelector(state => state.facultyInfoInput.confirmedFacultyId);
    const confirmedFacultyPic = useSelector(state => state.facultyInfoInput.confirmedFacultyPic);
    const confirmedFacultyData = useSelector(state => state.facultyInfoInput.confirmedFacultyData);
    const len = useSelector(state => state.facultyInfoInput.len);
    const univId = React.useRef('');


    React.useEffect(() => {
        if ("clearData" in props ? props.clearData === true : false) {
            clearData();
        }
        clearData();
    }, []);

    const customRodalStyles = {
        height: 'auto',
        bottom: 'auto',
        top: '26%',
        left: '18%',
        width: '72%'
    };

    const Alert = (props) => {
        return <MuiAlert elevation={9} variant="filled" {...props} />;
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const handleFirstNameChange = (event) => {
        const value = event.target.value
        setFirstName(value);
        setIsDirtyFname(true);
        if (value.length !== 0 && nameRegex.test(value)) {
            checkCandidateDetails({ name: "fname", value: value });
        }
    };
    const handleLastNameChange = (event) => {
        const value = event.target.value
        setLastName(value);
        setIsDirtyLname(true);
        if (value.length !== 0 && nameRegex.test(value)) {
            checkCandidateDetails({ name: "lname", value: value });
        }
    };
    const handleUnivChange = (name, value, id) => {
        setUniversityName(name);
        setIsDirtyComingFrom(true);
        if (id !== '') {
            univId.current = id;
            checkCandidateDetails();
        }
    }
    const checkCandidateDetails = async (variable = null) => {
        let fname = firstName
        let lname = lastName
        let id = univId.current
        if (isDirtyFname && isDirtyLname && isDirtyComingFrom) {
            if (variable !== null && "name" in variable) {
                if (variable.name === "lname")
                    lname = variable.value
                else
                    fname = variable.value
            }
            if (isDirtyFname && isDirtyLname && isDirtyComingFrom) {
                try {
                    await dispatch(facultyInfoInputActions.getSimilarFaculty(fname, lname, id));
                }
                catch (err) {
                    console.log(err);
                }

            }
        }
    }
    const modalOkClick = () => {
        if (confirmedFacultyId !== '') {
            if (confirmedFacultyId === 'radio4') {
                setSnackbarText("We dont have record for this author in our database");
                setSnackbarOpen(true);
            }
            else {
                props.setFacultyId({
                    id: confirmedFacultyId,
                    index: props.index ? props.index : 0,
                    firstName: confirmedFacultyData.first_name, //firstName,
                    lastName: confirmedFacultyData.last_name, //lastName,
                    universityName: universityName,
                    first_year_career: confirmedFacultyData.first_year_career,
                    pic: confirmedFacultyPic
                });
            }
            closeModal();
        }
        else {
            setSnackbarText("Please select a candidate");
            setSnackbarOpen(true);
        }
    }
    const closeModal = async () => {
        await dispatch(facultyInfoInputActions.toggleModal(false));
    }
    const handleListItemClick = async (event, index, faculty) => {
        setSelectedIndex(index);
        await dispatch(facultyInfoInputActions.setConfirmedFacultyId(faculty !== null ? faculty.author_id : "-1", faculty !== null ? faculty.pic : "-1", faculty != null ? faculty : {}))
    };
    if (len === 1) {
        modalOkClick();
    }
    const clearData = () => {
        dispatch(facultyInfoInputActions.clearData());
        setFirstName("");
        setLastName("");
        setUniversityName("");
        setIsDirtyComingFrom(false);
        setIsDirtyFname(false);
        setIsDirtyLname(false);
        univId.current = '';
    }
    React.useImperativeHandle(
        ref,
        () => ({
            clearFields() {
                clearData();
            }
        }),
    )
    return (
        <ThemeProvider theme={theme}>
            <div style={{ width: "100%", marginBottom: confirmedFacultyId !== '' ? "" : "-20px" }}>
                <div>
                    <Rodal showCloseButton visible={showModal} onClose={closeModal} customStyles={customRodalStyles} closeMaskOnClick={false}>
                        <div>
                            <span className="standardSize" style={{ fontWeight: 'bold' }} >Select a candidate</span>
                            <hr />
                            <div>
                                <List component="nav">
                                    {facultyList.length > 0 &&
                                        facultyList.map((faculty, i) => (
                                            <StyledListItem divider key={i} button selected={selectedIndex === i} onClick={(event) => handleListItemClick(event, i, faculty)}>
                                                <ListItemAvatar style={{ minWidth: 30 }}>
                                                    {i + 1 + "."}
                                                </ListItemAvatar>
                                                <ListItemAvatar style={{ paddingRight: 30 }}>
                                                    {faculty.pic != null ?
                                                        (<Avatar alt={faculty.first_name} src={faculty.pic} style={{ height: 75, width: 75 }} />)
                                                        :
                                                        (<Avatar style={{ height: 75, width: 75 }}>
                                                            <PersonIcon />
                                                        </Avatar>)
                                                    }
                                                </ListItemAvatar>
                                                <ListItemText style={{ cursor: "pointer" }} primary={faculty.first_name + " " + faculty.last_name}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography component="div" variant="body2" color="textPrimary">
                                                                {faculty.paper === null ? "" : `${faculty.paper.journal} - ${faculty.paper.title}`}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </StyledListItem>
                                        ))
                                    }
                                    <StyledListItem divider key={-1} button selected={selectedIndex === facultyList.length + 1} onClick={(event) => handleListItemClick(event, facultyList.length + 1, null)}>
                                        <ListItemAvatar style={{ minWidth: 30 }}>
                                            {facultyList.length + 1 + "."}
                                        </ListItemAvatar>
                                        <ListItemAvatar style={{ paddingRight: 30 }}>
                                            <Avatar style={{ height: 75, width: 75 }}>
                                                <PersonIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText style={{ cursor: "pointer" }} primary={`None of the above`} />
                                    </StyledListItem>
                                </List>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Button className={classes.addButton} onClick={modalOkClick} >Select</Button>
                            </div>
                        </div>
                    </Rodal>
                    <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity="error">
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextInput error={null} value={firstName} onChange={handleFirstNameChange} onBlur={checkCandidateDetails} label="First name"></TextInput>
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput error={null} value={lastName} onChange={handleLastNameChange} onBlur={checkCandidateDetails} label="Last name"></TextInput>
                        </Grid>
                        <Grid item xs={4}>
                            <UnivAutoCompleteWithBlur name="name" value={universityName} onChange={handleUnivChange}
                                onBlur={checkCandidateDetails} error={null} helperText={""} />
                        </Grid>
                    </Grid>
                </div>
                {firstName && lastName && universityName && confirmedFacultyId === '' &&
                    <div style={{ color: props.borderColor ? props.borderColor : "#555559" }}>
                        Please enter correct faculty info
                    </div>
                }
            </div>
        </ThemeProvider>
    );
});

export default FacultyInfoInput;
