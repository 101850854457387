import React from 'react';
import { ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import Theme from '../../../constants/Theme';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, FormControl, Paper, Typography, ListItem, ListItemAvatar, Avatar, ListItemText, List,
    Button, FormLabel, Switch,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import TextInput from '../../InputComponents/TextInput';
import UnivAutoCompleteWithBlur from '../../Autocomplete/UnivAutoCompleteWithBlur';
import * as authActions from '../../../store/actions/auth';
import * as exploreActions from '../../../store/actions/explore';
import * as profileActions from '../../../store/actions/profile';
import * as univProfileActions from '../../../store/actions/univProfile';
import Loading from '../../../assets/Images/loading.gif';
const defaultFacultyPic = require('../../../assets/Images/default_faculty.png');
const useStyles = makeStyles((theme) => ({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    searchButton: {
        color: "black !important",
        width: "25% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    }
}));
const useInlineStyles = makeStyles((theme) => ({
    loginButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    }
}));
const ChangeLogin = props => {
    const theme = Theme();
    const history = useHistory();
    const classes = useStyles();
    const inlineClasses = useInlineStyles();
    const dispatch = useDispatch();

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [isUniversityLogin, setIsUniversityLogin] = React.useState(false);
    const [idToLogin, setIdToLogin] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState();
    const [universityName, setUniversityName] = React.useState('');

    const authorSearchResult = useSelector(state => state.explore.authorSearchResult);

    const StyledButton = withStyles((theme) => ({
        root: {
            '&:hover': {
                background: 'transparent'
            }
        },
        disabled: {
            backgroundColor: '#ebe691 !important'
        }
    }))(Button);
    const BlueSwitch = withStyles({
        switchBase: {
            color: theme.palette.secondary.main
        },
        track: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main
        }
    })(Switch);
    const StyledListItem = withStyles({
        selected: {
            backgroundColor: "#e8f5f8 !important"
        }
    })(ListItem);
    const StyledList = withStyles({
        padding: {
            padding: 0
        }
    })(List);

    const handleSwitchChange = (event) => {
        setIsUniversityLogin(!isUniversityLogin);
    }
    const handleSelectedUniversity = async (name, value, id) => {
        setUniversityName(name);
        if (id !== '') {
            setIdToLogin(id);
        }
    }
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }

    const resetLoginToAdmin = async () => {
        setShowSpinner(true);
        try {
            const adminToken = localStorage.getItem("rl_token_a");
            await dispatch(profileActions.clearState());
            await dispatch(univProfileActions.clearState());
            localStorage.setItem('rl_token', adminToken);
            await dispatch(authActions.changeLogin(adminToken, false));
            await dispatch(profileActions.get_author_profile(adminToken, ''));
        }
        catch (error) {
            alert("Failed to load Admin profile");
        }
        setShowSpinner(false);
    }

    const changeLogin = async () => {
        setShowSpinner(true);
        let adminToken = localStorage.getItem('rl_token_a');
        try {
            await dispatch(profileActions.clearState());
            await dispatch(univProfileActions.clearState());
            await dispatch(authActions.adminAuthChangeLogin(idToLogin, isUniversityLogin, adminToken));
        }
        catch (err) {
            console.log(err);
            alert(err);
            history.push({
                pathname: '/dashboard/changeLogin'
            });
            return;
        }
        if (isUniversityLogin === true) {
            history.push({
                pathname: '/login'
            });
        }
        setShowSpinner(false);
    }
    const findFaculty = async () => {
        setShowSpinner(true);
        const token = localStorage.getItem("rl_token");
        try {
            await dispatch(exploreActions.authorSearch(token, firstName.trim(), lastName.trim()));
        }
        catch (err) {
            console.log(err);
            alert("Cannot find faculty");
        }
        setShowSpinner(false);
    }
    const profileClickHandler = (index, faculty) => {
        setSelectedIndex(index);
        if (faculty !== null)
            setIdToLogin(faculty.id);
    }
    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={classes.featureHeading}>
                    Change Login
                </div>
                <Paper square style={{ padding: 20 }}>
                    <div>
                        <span style={{ fontWeight: 'bold' }} >Select a login type</span>
                        <hr />
                        <FormControl fullWidth component="fieldset" style={{ textAlign: "left" }}>
                            <FormLabel classes={{ root: classes.labelroot }} fullWidth component="legend">Login Type:</FormLabel>
                            <span><span className={classes.standardSize}>Faculty </span> <BlueSwitch checked={isUniversityLogin} size="small" color="secondary" onChange={handleSwitchChange} name={isUniversityLogin ? "Faculty" : "University"} /><span className={classes.standardSize}>University </span></span>
                        </FormControl>
                        <p style={{ marginBottom: 10 }} />
                        {isUniversityLogin ?
                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3} />
                                    <Grid item xs={6}>
                                        <UnivAutoCompleteWithBlur style={{ width: "50%" }} name="name" value={universityName} onChange={handleSelectedUniversity}
                                            helperText={false ? "Enter a valid school" : null} />
                                    </Grid>
                                    <Grid item xs={3} />
                                </Grid>
                            </div>
                            :
                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={5}>
                                        <TextInput required error={null} onChange={handleFirstNameChange} value={firstName} onBlur={null} label="Faculty First Name"></TextInput>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextInput required error={null} onChange={handleLastNameChange} value={lastName} onBlur={null} label="Faculty Last Name"></TextInput>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" className={inlineClasses.loginButton} color="primary" onClick={findFaculty}>Find Faculty</Button>
                                    </Grid>
                                </Grid>
                                {authorSearchResult.length > 0 &&
                                    <Paper square style={{ margin: "20px 0px", overflow: "auto", maxHeight: "430px" }}>
                                        <StyledList component="nav">
                                            {authorSearchResult.length > 0 &&
                                                authorSearchResult.map((faculty, i) => (
                                                    <StyledListItem divider key={i} button selected={selectedIndex === i} onClick={(event) => profileClickHandler(i, faculty)}>
                                                        <ListItemAvatar style={{ minWidth: 30 }}>
                                                            {i + 1 + "."}
                                                        </ListItemAvatar>
                                                        <ListItemAvatar style={{ paddingRight: 30 }}>
                                                            {faculty.profile_picture != null ?
                                                                (<Avatar alt={faculty.first_name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                                :
                                                                (<Avatar style={{ height: 75, width: 75 }}>
                                                                    <img src={defaultFacultyPic} alt={"profice pic"} height={75} width={75} />
                                                                </Avatar>)
                                                            }
                                                        </ListItemAvatar>
                                                        <ListItemText style={{ cursor: "pointer" }} primary={faculty.first_name + " " + faculty.last_name}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        {faculty.univ_name}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </StyledListItem>
                                                ))
                                            }
                                        </StyledList>
                                    </Paper>
                                }
                            </div>
                        }
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={3} />
                                {
                                    !showSpinner &&
                                    [
                                        <Grid item xs={3}>
                                            <StyledButton variant="contained" className={inlineClasses.loginButton} color="primary" onClick={changeLogin} disabled={(idToLogin === "")}>Change Login</StyledButton>
                                        </Grid>
                                        ,
                                        <Grid item xs={3}>
                                            <StyledButton variant="contained" className={inlineClasses.loginButton} color="primary" onClick={resetLoginToAdmin}>Change Login to Admin</StyledButton>
                                        </Grid>
                                    ]
                                }
                                {
                                    showSpinner &&
                                    <Grid item xs={6}>
                                        <div style={{ textAlign: "center" }}>
                                            <img src={Loading} alt="Loading..." width="80px" height="80px" />
                                        </div>
                                    </Grid>
                                }
                                <Grid item xs={3} />
                            </Grid>
                        </div>
                    </div>
                </Paper>
            </div>
        </ThemeProvider>
    );
}
export default ChangeLogin;