//Mondogb structure could also be kept as same!!
class Profile {
    constructor(id, first_name, mid_name, last_name, position, marker,
        department, education, expertise, interests, courses,
        work_history, bio, profile_picture, email, cv, univ_id,
        univ_name, verified, suffix, prefix, papers, is_complete,
        gender, gs, rg, percent_appointment, unusual_work_hist, name_variations, univ_profile_link, keywords, area_of_interest, highlighted_cv) {
        this._id = id;
        this.first_name = first_name;
        this.first_name = first_name;
        this.mid_name = mid_name;
        this.last_name = last_name;
        this.position = position;
        this.marker = marker;
        this.department = department;
        this.education = education;
        this.expertise = expertise;
        this.interests = interests;
        this.courses = courses;
        this.work_history_manual = work_history;
        this.bio = bio;
        this.profile_picture = profile_picture;
        this.email = email;
        this.cv = cv;
        this.univ_id = univ_id;
        this.univ_name = univ_name;
        this.verified = verified;
        this.suffix = suffix;
        this.prefix = prefix;
        this.papers = papers;
        this.is_complete = is_complete;
        this.gender = gender;
        this.gs = gs;
        this.rg = rg;
        this.percent_appointment = percent_appointment;
        this.unusual_work_hist = unusual_work_hist;
        this.name_variations = name_variations;
        this.univ_profile_link = univ_profile_link;
        this.keywords = keywords;
        this.area_of_interest = area_of_interest;
        this.highlighted_cv = highlighted_cv;
    };
};

export default Profile;