import React from 'react'

const Authorlog = props=> {
    const logRecord = props.data;
    return (
        <div style={{cursor:"pointer"}} onClick={props.onClick}>
            <h5>{logRecord.author_name}</h5>
            {
                logRecord.author_univ_name && <p><b>University: </b>{logRecord.author_univ_name}</p>
            }
            {
                logRecord.note && <p><b>Note: </b>{logRecord.note}</p>
            }
            {/* <div style={{display:"flex"}}>
                <div style={{flex:1}}>
                    <p><b>Name on paper: </b>{logRecord.author_name}</p>
                </div>
                <div style={{flex:1}}>
                    <p><b>Name on record: </b>{logRecord.author.name}</p>
                </div>
            </div> */}
            <p><b>Error: </b>{logRecord.error}</p>
        </div>
    )
}

export default Authorlog;
