import * as actionTypes from './actionTypes';

export const updateProfile = () => {
    return {
        type: actionTypes.EDIT_PROFILE
    };
}

export const doneUpdatingProfile = () => {
    return {
        type: actionTypes.DONE_EDIT
    };
}