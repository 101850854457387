import React, { useState } from 'react';
import * as classes from './addAuthor.css';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import UnivAutoComplete from '../../Autocomplete/UnivAutoComplete';
import loader from '../../../assets/loader/loupeload.gif';
import DisplayRecord from '../DisplayRecord/displayRecord';
import SingleSelectDropDownIcon from '../../InputComponents/SingleSelectDropDownIcon';
import * as addAuthorToolActions from '../../../store/actions/addAuthorTool';
import { titleMapping, titlesWithSubTitle } from '../../Dashboard/Views/ProfileEdit/WorkHistory/WorkHistory';

const useStyles = makeStyles((theme) => ({
  searchButton: {
    color: "black !important",
    width: "100% !important",
    borderRadius: "0% !important",
    height: "39px",
    backgroundColor: "#BBB218 !important",
  }
}));

const Addauthor = props => {
  const inlineClasses = useStyles();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [midName, setMidName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [gsLink, setGsLink] = useState("");
  const [rgLink, setRgLink] = useState("");
  const [gsName, setGsName] = useState("");
  const [rgName, setRgName] = useState("");
  const [authorCV, setAuthorCV] = useState(undefined);
  const [university, setUniversity] = useState("")
  const [savingResume, setSavingResume] = useState(false);
  const fileUploadLabel = "Upload New CV";
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const possibleRecords = useSelector(state => state.addAuthorTool.similar_authors);
  const [searchedForPossibleAuthors, setSearchedForPossibleAuthors] = useState(false);

  const uploadFileHandler = (event) => {
    setSavingResume(true);
    const file = event.target.files[0];
    setAuthorCV(file);
    setSavingResume(false);
  }

  const findAuthor = async (event) => {
    if (!firstName || !lastName) {
      alert("First name and last name are required to search for authors");
      return;
    }
    const token = localStorage.getItem("rl_token");
    await dispatch(addAuthorToolActions.fetchSimilarAuthors(token, firstName, lastName));
    setSearchedForPossibleAuthors(true);

  }

  const isFormComplete = () => {
    console.log(titlesWithSubTitle, title, subTitle)
    if (firstName.trim() === "" || lastName.trim() === "" ||
      university.trim() === "" || email.trim() === "" ||
      (title.trim() === "" || (titlesWithSubTitle.includes(title) && subTitle.trim() === ""))) {
      return false
    } else {
      return true
    }
  };

  const createRecord = async (event) => {
    if (!isFormComplete()) {
      alert("Complete all required fields");
      return;
    }
    const formData = new FormData();
    if (authorCV) {
      formData.append('file', authorCV);
    }
    formData.append('first_name', firstName.trim());
    formData.append('last_name', lastName.trim());
    formData.append('mid_name', midName.trim());
    formData.append('email', email.trim());
    formData.append('title', titlesWithSubTitle.includes(title) ? subTitle.trim() : titleMapping[title].title);
    formData.append('suffix', suffix.trim());
    formData.append('prefix', prefix.trim());
    formData.append('univ_name', university.trim());
    formData.append('marker', parseInt(titleMapping[title].marker));
    if (gsLink) {
      if (!gsName) {
        alert("Please enter author name from google scholar");
        return;
      }
      formData.append('google_scholar_link', gsLink.trim());
      formData.append('google_scholar_author_name', gsName.trim());
      let gsUrl = new URL(gsLink.trim());
      const params = new URLSearchParams(gsUrl.search);
      if (!params.has("user")) {
        alert("Invalid Google Scholar link");
        return;
      }
      formData.append('google_scholar_author_id', params.get("user"));
    }
    if (rgLink) {
      if (!rgName) {
        alert("Please enter author name from Researchgate");
        return;
      }
      formData.append('research_gate_link', rgLink.trim());
      formData.append('research_gate_author_name', rgName.trim());
      let rgLinkSplit = rgLink.trim().split("/");
      if (rgLinkSplit.length < 5) {
        alert("Invalid Researchgate link");
        return;
      }
      formData.append('research_gate_author_id', rgLinkSplit[rgLinkSplit.length - 1])
    }
    const token = localStorage.getItem("rl_token");
    let createdAuthorId = await addAuthorToolActions.addNewAuthor(token, formData);
    props.history.push({
      pathname: '/dashboard/profileEdit',
      state: { coauthorid: createdAuthorId }
    });
  }

  const selectAuthor = (record) => (event) => {
    if (event.target.id === "gsLink" || event.target.id === "rgLink") {
      return;
    }
    props.history.push({
      pathname: '/dashboard/profileEdit',
      state: { coauthorid: record.id }
    });
    console.log("Move to Edit Author part with id", record._id);
  }

  const firstNameHandler = event => {
    setFirstName(event.target.value);
    setSearchedForPossibleAuthors(false);
  }
  const midNameHandler = event => {
    setMidName(event.target.value);
    setSearchedForPossibleAuthors(false);

  }
  const lastNameHandler = event => {
    setLastName(event.target.value);
    setSearchedForPossibleAuthors(false);
  }
  const emailHandler = event => {
    setEmail(event.target.value);
  }
  const handleSuffixChange = event => {
    setSuffix(event.target.value);
  }

  const handlePrefixChange = event => {
    setPrefix(event.target.value);
  }

  const universityChangeHandler = (name, value) => {
    console.log(name)
    setUniversity(name);
  }
  const titleHandler = (event, newInputValue) => {
    setTitle(newInputValue);
    setSubTitle("");
  }

  const subTitleHandler = event => {
    setSubTitle(event.target.value);
  }

  const gsHandler = event => {
    setGsLink(event.target.value);
  }
  const rgHandler = event => {
    setRgLink(event.target.value);
  }
  const gsNameHandler = event => {
    setGsName(event.target.value);
  }
  const rgNameHandler = event => {
    setRgName(event.target.value);
  }
  return (
    <div>
      <Container className="add_author_container">
        <Row className={classes.row} md={12}>
          <Col md={12}>
            <h4 style={{ textAlign: "center" }}>Add Author Details</h4>
          </Col>
        </Row>
        <Row className={classes.row} md={12}>
          <Col md={2}>
            <TextField
              onChange={handlePrefixChange.bind(this)}
              size="small"
              id="Prefix"
              label="Prefix"
              fullWidth={true}
              variant="outlined"
              value={prefix} />
          </Col>
          <Col md={3}>
            <TextField
              required
              size="small"
              onChange={firstNameHandler.bind(this)}
              id="firstName"
              label="First Name"
              variant="outlined"
              fullWidth={true}
              value={firstName} />
          </Col>
          <Col md={2}>
            <TextField
              size="small"
              onChange={midNameHandler.bind(this)}
              id="middleName"
              label="Middle Name"
              fullWidth={true}
              variant="outlined"
              value={midName} />
          </Col>
          <Col md={3}>
            <TextField
              required
              size="small"
              onChange={lastNameHandler.bind(this)}
              id="lastName"
              label="Last Name"
              fullWidth={true}
              variant="outlined"
              value={lastName} />
          </Col>
          <Col md={2}>
            <TextField
              onChange={handleSuffixChange.bind(this)}
              size="small"
              id="Suffix"
              label="Suffix"
              fullWidth={true}
              variant="outlined"
              value={suffix} />
          </Col>
        </Row>
        <Row className={classes.row} md={12}>
          <Col md={6}>
            <SingleSelectDropDownIcon
              error={null}
              options={Object.keys(titleMapping)}
              onInputChange={titleHandler.bind(this)}
              inputValue={title}
              label="Select Title"
              helperText=""
              variant="outlined" />
          </Col>
          {
            title !== ''
            && titleMapping[title] !== undefined
            && titleMapping[title]['title'] === ''
            &&
            <Col md={6}>
              <TextField
                required
                onChange={subTitleHandler.bind(this)}
                size="small"
                id="subTitle"
                label="Position Title"
                fullWidth={true}
                variant="outlined"
                value={subTitle} />
            </Col>
          }
        </Row>
        <Row className={classes.row} md={12}>
          <Col md={4}>
            <UnivAutoComplete
              name="name"
              value={university}
              onChange={universityChangeHandler.bind(this)} />
          </Col>
          <Col md={4}>
            <TextField
              required
              onChange={emailHandler.bind(this)}
              size="small"
              id="email"
              label="Email"
              variant="outlined"
              fullWidth={true}
              value={email} />
          </Col>
          <Col md={4}>
            {savingResume ? <div><img src={loader} alt="loading..." style={{ height: '50px', width: '50px' }} />Saving... </div> :
              <Form.File
                required
                id="custom-file"
                label={authorCV ? authorCV.name : fileUploadLabel}
                custom
                onChange={uploadFileHandler.bind(this)}
              />
            }
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextField
              onChange={gsHandler.bind(this)}
              size="small"
              id="gs"
              label="Link to google scholar page"
              variant="outlined"
              fullWidth={true}
              value={gsLink} />
          </Col>
          <Col md={6}>
            <TextField
              onChange={rgHandler.bind(this)}
              size="small"
              id="rg"
              label="Link to resarchgate page"
              variant="outlined"
              fullWidth={true}
              value={rgLink} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextField
              onChange={gsNameHandler.bind(this)}
              size="small"
              required={gsLink.trim() ? true : false}
              id="gsName"
              label="Name on Google Scholar page"
              variant="outlined"
              fullWidth={true}
              value={gsName} />
          </Col>
          <Col md={6}>
            <TextField
              onChange={rgNameHandler.bind(this)}
              required={rgLink.trim() ? true : false}
              size="small"
              id="rgName"
              label="Name on Researchgate page"
              variant="outlined"
              fullWidth={true}
              value={rgName} />
          </Col>
        </Row>
        <Row>
        </Row>
        <Row>
          <Col md={3}></Col>
          <Col md={3}>
            <Button
              className={inlineClasses.searchButton}
              variant="contained"
              onClick={findAuthor}
            >
              Look if Author exists
            </Button>
          </Col>
          <Col md={3}>
            <Button
              className={inlineClasses.searchButton}
              variant="contained"
              onClick={createRecord}
            >
              Create new record
            </Button>
          </Col>
        </Row>
      </Container>
      <div hidden={searchedForPossibleAuthors === false}>
        <Typography variant="h6" style={{ padding: "20px 15px 0px" }}>
          {possibleRecords.length} results found
        </Typography>
        <Container className="add_author_container">
          <div className="author_record_container">
            {
              possibleRecords.map((record, i) =>
                <div key={record._id}>
                  <DisplayRecord index={i + 1} key={record.id} record={record} onButtonClick={selectAuthor(record)} style={{ marginBottom: "3px" }}></DisplayRecord>
                  {i !== possibleRecords.length - 1 ? <hr style={{ width: "100%", color: "black", backgroundColor: "black" }}></hr> : null}
                </div>
              )
            }
          </div>
        </Container >
      </div>
    </div>
  )
}

export default Addauthor;
