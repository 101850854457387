import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const updateFacultyAction = (data) => {
    return {
        type: actionTypes.UPDATE_FACULTY_ACTION,
        result: data
    }
}

export const updateFacultySearch = (token, univId, type) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `update_faculty?id=${univId}&type=${type}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData);
            throw new Error('Something went wrong while scraping for university with error: ' + errorResData);
        }
        const authorData = await response.json();
        dispatch(updateFacultyAction(authorData));
    };
}
