import React from 'react'

const Authorcard = props => {
    
    const authorRecord = props.data;
    let name = authorRecord.last_name+", "+authorRecord.first_name;
    if (authorRecord.mid_name) {
        name += " "+authorRecord.mid_name;
    }
    
    return (
        <div style={{cursor:"pointer", flex:1}} onClick={props.onClick}>
            <h4>{name}</h4>
            <p>{authorRecord.position +" at "+ authorRecord.univ_name}</p>
        </div>
    )
}

export default Authorcard;