import React from 'react';
import { Paper, List } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import Loading from '../../../../../assets/Images/loading.gif';

const CitationsTreeMap = props => {
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = React.useState(false);
    const mostCitedFaculty = useSelector(state => state.dashboards.facultyCitations[0]);
    const mostCitedPaper = useSelector(state => state.dashboards.facultyCitations[1]);

    React.useEffect(() => {
        // No need to call api twice as data is fetched for both faculty and paper card together
        if (props.card === "Most Cited Faculty")
            getCitations();
    }, []);

    const getCitations = async () => {
        setShowSpinner(true);
        try {
            const token = localStorage.getItem('rl_token');
            await dispatch(dashboardActions.getFacultyCitations(token));
        }
        catch (err) {
            alert("Something went wrong while fetching faculty citations for dashboard");
        }
        setShowSpinner(false);
    }

    const capitalizeFirstLetter = (string) => {
        const words = string.split(" ");
        for (let i = 0; i < words.length; i++) {
            if (words[i] !== "") {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
            }
        }
        return words.join(" ");
    };

    if (props.card === "Most Cited Faculty") {
        return (
            <Paper style={{ height: "360px", padding: "12px", overflow: 'auto' }}>
                <div style={{ fontSize: "20px" }}>Most Cited Faculty</div>
                {
                    showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <List style={{paddingTop: "0px"}}>
                        {mostCitedFaculty !== undefined &&
                        mostCitedFaculty.map((row) => (
                            <div key={row.department} >
                                <div align={'left'} padding={'none'} style={{ paddingTop: "12px", fontSize: "1rem", fontWeight:500 }}>{row.department}:</div>
                                {row.most_cited_faculty_data.map((data) => (
                                    <div align={'left'} padding={'none'} style={{ fontSize: "0.9rem" }}>{data.name}&nbsp;: {data.count} Citations</div>
                                ))}
                            </div>
                        ))}
                    </List>
                }
            </Paper>
        )
    } else if (props.card === "Most Cited Papers") {
        return (
            <Paper style={{ height: "360px", padding: "12px", overflow: 'auto' }}>
                <div style={{ fontSize: "20px" }}>Most Cited Papers</div>
                {
                    showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <List style={{paddingTop: "0px"}}>
                        {mostCitedPaper !== undefined &&
                        mostCitedPaper.map((row) => (
                            <div key={row.department} >
                                <div align={'left'} padding={'none'} style={{ paddingTop: "12px", fontSize: "1rem", fontWeight:600 }}>{row.department}:</div>
                                {row.most_cited_paper_data.map((data) => (
                                    [
                                        <div align={'left'} padding={'none'} style={{ fontSize: "0.9rem", fontWeight:500 }}>{data.name}&nbsp;: {data.count} Citations</div>,
                                        <div align={'left'} padding={'none'} style={{ paddingBottom: "6px", fontSize: "0.9rem" }}>{capitalizeFirstLetter(data.title)}&nbsp;published in {capitalizeFirstLetter(data.journal)} ({data.year})</div>
                                    ]
                                ))}
                            </div>
                        ))}
                    </List>
                }
            </Paper>
        )
    }

}

export default CitationsTreeMap;