import React, { useState } from "react";
import classes2 from "./redflags.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import PapersOlderThanTwoYearsBeforePhd from "./PapersOlderThanTwoYearsBeforePhd";
import FacultyPapersByUniversity from "./FacultyPapersByUniversity";
import PapersPublishedPerYearInAJournal from "./PapersPublishedPerYearInAJournal";
import AuthorsThatHaveSameNumberOfPapersPerGroup from "./AuthorsThatHaveSameNumberOfPapersPerGroup";
import AuthorsWithoutEducationField from "./AuthorsWithoutEducationField";
import PapersOlderThanLastEducationYear from "./PapersOlderThanLastEducationYear";
import PapersWithSpanOfMoreThanFiftyYears from "./PapersWithSpanOfMoreThanFiftyYears";
import TotalPapersByFacultyPerUniversity from "./TotalPapersByFacultyPerUniversity";
import FacultyWithIncorrectPercentAppointments from "./FacultyWithIncorrectPercentAppointment";
import FacultyWithMissingMarker from "./FacultyWithMissingMarker";
import FacultyWithMissingDepartment from "./FacultyWithMissingDepartment";
import FacultyWithMissingGender from "./FacultyWithMissingGender";
import FacultyWithMissingPosition from "./FacultyWithMissingPosition";
import FacultyWithInconsistentPaperNumber from "./FacultyWithInconsistentPaperNumber";
import { NoEncryption } from "@material-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import * as redflagActions from '../../../../store/actions/redflag';

const RedFlags = (props) => {
  const [curFlag, setCurFlag] = useState("");
  let isIncorrectPercentAppointmentActive = useSelector(state => state.redflags.authorWithIncorrectPercentAppointment);
  let isMissingMarker = useSelector(state => state.redflags.authorWithMissingMarker);
  let isMissingDept = useSelector(state => state.redflags.authorWithMissingDepartment);
  let isMissingGender = useSelector(state => state.redflags.authorWithMissingGender);
  let isMissingPos = useSelector(state => state.redflags.authorWithMissingPosition);
  let isInconsistentPaperActive = useSelector(state => state.redflags.authorWithInconsistentPaper);
  const dispatch = useDispatch();

  const possibleFlags = [
    "AUTHORS THAT HAVE SAME NUMBER OF PAPERS PER GROUP",
    "AUTHORS WITHOUT EDUCATION FIELD",
    "FACULTY PAPERS BY UNIVERSITY",
    "PAPERS OLDER THAN LAST EDUCATION YEAR",
    "PAPERS OLDER THAN TWO YEARS BEFORE PHDS",
    "PAPERS PUBLISHED PER YEAR IN A JOURNAL",
    "PAPERS WITH SPAN OF MORE THAN FIFTY YEARS",
    "TOTAL PAPERS BY FACULTY PER UNIVERSITY",
    "FACULTY WITH INCORRECT PERCENT APPOINTMENT",
    "FACULTY WITH MISSING MARKER",
    "FACULTY WITH MISSING DEPARTMENT",
    "FACULTY WITH MISSING GENDER",
    "FACULTY WITH MISSING POSITION",
    "FACULTY WITH INCONSISTENT PAPER NUMBERS"
  ];

  return (
    <div>
      <Container className={classes2.heading}>
        <Row>
          <Col>
            <span className={classes2.profile}>{"Red Flags"}</span>
          </Col>
        </Row>
      </Container>
      <div className={classes2.cardContainer}>
        <div className={classes2.resultCard}>
          <div className={classes2.formCard}>
            <Container>
              <Row md={12} className={classes2.row}>
                <Col md={2}></Col>
                <Col md={8}>
                  <Autocomplete
                    id="redflags-auto"
                    options={possibleFlags}
                    fullWidth
                    size="small"
                    // value={isInconsistentPaperActive ? 'FACULTY WITH INCONSISTENT PAPER NUMBERS': curFlag}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Red Flags Search"
                        variant="outlined"
                      />
                    )}
                    onChange={async (e, value) => {
                      console.log(value);
                      setCurFlag(() => { return value });

                      possibleFlags.includes(value) ? await dispatch(redflagActions.toggleFlag(value, true)) : await dispatch(redflagActions.toggleFlag(value, false))
                    }}
                  />
                </Col>
                <Col md={2}></Col>
              </Row>
            </Container>
          </div>
          <div>
            {curFlag === "PAPERS OLDER THAN TWO YEARS BEFORE PHDS" && (
              <PapersOlderThanTwoYearsBeforePhd />
            )}
            {curFlag === "FACULTY PAPERS BY UNIVERSITY" && (
              <FacultyPapersByUniversity />
            )}
            {curFlag === "PAPERS PUBLISHED PER YEAR IN A JOURNAL" && (
              <PapersPublishedPerYearInAJournal />
            )}
            {curFlag ===
              "AUTHORS THAT HAVE SAME NUMBER OF PAPERS PER GROUP" && (
                <AuthorsThatHaveSameNumberOfPapersPerGroup />
              )}
            {curFlag === "AUTHORS WITHOUT EDUCATION FIELD" && (
              <AuthorsWithoutEducationField />
            )}
            {curFlag === "PAPERS OLDER THAN LAST EDUCATION YEAR" && (
              <PapersOlderThanLastEducationYear />
            )}
            {curFlag === "PAPERS WITH SPAN OF MORE THAN FIFTY YEARS" && (
              <PapersWithSpanOfMoreThanFiftyYears />
            )}
            {curFlag === "TOTAL PAPERS BY FACULTY PER UNIVERSITY" && (
              <TotalPapersByFacultyPerUniversity />
            )}
            {(isIncorrectPercentAppointmentActive == true || curFlag === "FACULTY WITH INCORRECT PERCENT APPOINTMENT") && (
              <FacultyWithIncorrectPercentAppointments />
            )}
            {(isMissingMarker == true || curFlag === "FACULTY WITH MISSING MARKER") && (
              <FacultyWithMissingMarker />
            )}
            {(isMissingDept == true || curFlag === "FACULTY WITH MISSING DEPARTMENT") && (
              <FacultyWithMissingDepartment />
            )}
            {(isMissingGender || curFlag === "FACULTY WITH MISSING GENDER") && (
              <FacultyWithMissingGender />
            )}
            {(isMissingPos == true || curFlag === "FACULTY WITH MISSING POSITION") && (
              <FacultyWithMissingPosition />
            )}
            {(isInconsistentPaperActive == true || curFlag === "FACULTY WITH INCONSISTENT PAPER NUMBERS") && (
              <FacultyWithInconsistentPaperNumber />
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default RedFlags;
