import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    departments: [],
    journals: {},
    default_mapping: [],
    orginalData: []
}

const deptConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DEPARTMENT_CONFIG:
            let finalDepartmentData = []
            let i = 0

            for (let dept of action.searchResult.departments){
                const dept_clone = JSON.parse(JSON.stringify(dept))
                delete dept_clone.name
                finalDepartmentData.push({name:dept.name, data:dept_clone, id:i})
                i = i + 1;
            }

            let recreateDefaultMapping = []
            for (let dept of action.searchResult.default_mapping){
                const dept_clone = JSON.parse(JSON.stringify(dept))
                delete dept_clone.name
                recreateDefaultMapping.push({name:dept.name, data:dept_clone})
            }

            return updateObject(state, { originalData:action.searchResult.departments, departments: finalDepartmentData, journals: action.searchResult.journals, default_mapping: recreateDefaultMapping });

        case actionTypes.SAVE_DEPARTMENT_CONFIG_DATA:
            
            let newFinalDepartmentData = []
            let j = 0
            
            for (let dept of action.newOriginalDeptData){
                const dept_clone = JSON.parse(JSON.stringify(dept))
                delete dept_clone.name
                newFinalDepartmentData.push({name:dept.name, data:dept_clone, id:j})
                j = j + 1;
            }

            return updateObject(state, { originalData:action.newOriginalDeptData, departments: newFinalDepartmentData});

        default:
            return state
    }
}

export default deptConfigReducer;
