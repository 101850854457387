import React, { useState, useEffect, forwardRef } from 'react';
import * as authActions from '../../store/actions/auth';
import { NavLink as RouterLink } from 'react-router-dom';
import classes from './Signup.module.css';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ThemeProvider, makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';
import Theme from '../../constants/Theme';
import { Grid, Paper, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, Avatar, } from '@material-ui/core';
import { BACKEND_URL } from '../../utils';
import TextInput from '../InputComponents/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import UnivAutoCompleteWithBlur from '../Autocomplete/UnivAutoCompleteWithBlur';
import adminLogo from '../../assets/Images/logos/RL_Logo.jpg';
const defaultFacultyPic = require('../../assets/Images/default_faculty.png');
const bg = require('../../assets/Images/login_bg.png');
const successImg = require('../../assets/Images/success.jpg');

const useStyles = makeStyles((theme) => ({
    loginButton: {
        color: "black !important",
        width: "100%",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
    gridBG: {
        // backgroundImage: `url(${bg})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover'
    },
    textValidatorStyle: {
        width: 262,
        backgroundColor: "#f2f2f2"
    }
}));
const Signup = () => {
    const dispatch = useDispatch();

    const success = useSelector(state => state.auth.success)
    const [university, setUniversity] = useState("");
    const [univ_id, setUniv_id] = useState("");
    const [firstName, setFirstName] = useState("");
    const [midName, setMidName] = useState("");
    const [lastName, setLastName] = useState("");
    const [stage, setStage] = useState(1);
    const [potentialCandidate, setPotentialCandidate] = useState("");
    const [potentialProfiles, setPotentialProfiles] = useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState();
    const [formElements, setFormElements] = useState({ formData: { university: '', fName: '', middleName: '', lastName: '', email: '', password: '', confirmPassword: '', username: '' }, submitted: false })
    const formData = formElements.formData;
    const theme = Theme();
    const inlineClasses = useStyles();

    const StyledButton = withStyles((theme) => ({
        root: {
            '&:hover': {
                background: 'transparent'
            }
        },
        text: {
            padding: "0px !important"
        }
    }))(Button);
    const StyledListItem = withStyles({
        selected: {
            backgroundColor: "#e8f5f8 !important"
        }
    })(ListItem);
    const StyledList = withStyles({
        padding: {
            padding: 0
        }
    })(List);
    const theme2 = createMuiTheme({
        overrides: {
            MuiInputLabel: {
                filled: {
                    transform: "translate(12px, 16px) scale(1)",
                },
                shrink: {
                    transform: "translate(12px, 6px) scale(0.75) !important",
                }
            },
            MuiFilledInput: {
                root: {
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    height: 40,
                    "&:hover": {
                        backgroundColor: "#e8e8e8",
                        borderBottom: 0
                    }
                },
                underline: {
                    '&:before': {
                        borderBottom: 0
                    },
                    '&:after': {
                        borderBottom: 0
                    },
                }
            }
        }
    });
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1, display: "inline", marginLeft: "-5px" }}
        >
            <RouterLink {...props} />
        </div>
    ));

    const handleChange = (event) => {
        const { formData, submitted } = formElements;
        formData[event.target.name] = event.target.value;
        setFormElements({ formData: formData, submitted: submitted })
    }
    const handleSubmit = async () => {
        try{
            await dispatch(authActions.signup(formElements.formData.email, formElements.formData.username, formElements.formData.password, potentialCandidate,
                firstName, midName, lastName, university, univ_id));
        }
        catch(error) {
            alert(error);
        }
    }

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== formData.password) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('mustHaveUniversity', (value) => {
            if (value === "None") {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('mustHaveUniversityEmail', (value) => {
            if (value.slice(-3) !== 'edu') {
                return false;
            }
            return true;
        });
    })

    const firstNameChangeHandler = event => {
        setFirstName(event.target.value);
    }
    const midNameChangeHandler = event => {
        setMidName(event.target.value);
    }
    const lastNameChangeHandler = event => {
        setLastName(event.target.value);
    }
    const prevButtonHandler = () => {
        var initialStage = stage;
        setPotentialCandidate("");
        setSelectedIndex();
        setStage(initialStage - 1);
    }
    const getPotentialProfiles = () => {
        var url = BACKEND_URL + 'signup?fn=' + firstName + '&ln=' + lastName +
            '&uid=' + univ_id;

        fetch(url, {
            method: 'GET',
        }).then((response) => {
            response.json().then((body) => {
                setPotentialProfiles(body.authors);
                setStage(2);
            });
        });
    }
    const checkValidity = () => {
        if (stage === 1) {
            if (firstName !== "" && lastName !== "" && univ_id !== "") {
                return true;
            }
            else {
                alert("Please fill all the fields");
                return false;
            }
        }
        else {
            return true;
        }
    }
    const nextButtonHandler = () => {
        var initialStage = stage;
        if (checkValidity()) {
            if (stage === 1) {

                getPotentialProfiles();
                return;
            }
            setStage(initialStage + 1);
        }
    }
    const profileClickHandler = (index, faculty) => {
        setSelectedIndex(index);
        if (faculty !== null)
            setPotentialCandidate(faculty.author_id);
    }
    const handleUnivChange = (name, value, id) => {
        setUniversity(name);
        if (id !== '') {
            setUniv_id(id);
            console.log(id)
        }
    }

    let stageDisplay = "";

    if (stage === 1) {
        stageDisplay =
            <Grid container direction="column" justify="center" alignItems="center" spacing={3} className={inlineClasses.gridBG}>
                <Grid item style={{ paddingBottom: 0 }}>
                    <Typography variant="h5" style={{textAlign: "center"}}>Welcome To </Typography>
                    <img src={adminLogo} alt="Research Loupe" className={classes.adminLogo} style={{width: "15em"}}/>
                </Grid>
                <Grid item style={{ paddingTop: 5 }}>
                    <hr style={{marginTop: "0"}}/>
                    <Typography variant="body1">Create your account</Typography>
                </Grid>
                <Grid item>
                    <div style={{ width: 262 }}>
                        <TextInput required error={false} value={firstName} onChange={firstNameChangeHandler} label="First Name" helperText={""} />
                    </div>
                </Grid>
                <Grid item>
                    <div style={{ width: 262 }}>
                        <TextInput error={false} value={midName} onChange={midNameChangeHandler} label="Middle Name" helperText={""} />
                    </div>
                </Grid>
                <Grid item>
                    <div style={{ width: 262 }}>
                        <TextInput required error={false} value={lastName} onChange={lastNameChangeHandler} label="Last Name" helperText={""} />
                    </div>
                </Grid>
                <Grid item style={{ width: 286 }}>
                    <UnivAutoCompleteWithBlur name="university name" value={university} onChange={handleUnivChange}
                        error={null} helperText={""} />
                </Grid>
                <Grid item>
                    <Button style={{ width: 145 }} className={inlineClasses.loginButton} variant="contained"
                        onClick={nextButtonHandler} >
                        {"NEXT"}
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="body1">Already have an account?
                            <StyledButton component={CustomRouterLink} to={'/login'} disableElevation disableRipple
                            disableFocusRipple disableTouchRipple style={{ textDecoration: "underline" }}>
                            login
                            </StyledButton>
                    </Typography>
                </Grid>
            </Grid>
    }
    else if (stage === 2) {
        stageDisplay =
            <div>
                <div style={{ fontWeight: 'bold', textAlign: 'center' }} >Select your profile</div>
                <hr />
                <Paper square>
                    <StyledList component="nav">
                        {potentialProfiles.length > 0 &&
                            potentialProfiles.map((faculty, i) => (
                                <StyledListItem divider key={i} button selected={selectedIndex === i} onClick={(event) => profileClickHandler(i, faculty)}>
                                    <ListItemAvatar style={{ minWidth: 30 }}>
                                        {i + 1 + "."}
                                    </ListItemAvatar>
                                    <ListItemAvatar style={{ paddingRight: 30 }}>
                                        {faculty.pic != null ?
                                            (<Avatar alt={faculty.first_name} src={faculty.pic} style={{ height: 75, width: 75 }} />)
                                            :
                                            (<Avatar style={{ height: 75, width: 75 }}>
                                                <img src={defaultFacultyPic} alt={"profice pic"} height={75} width={75} />
                                            </Avatar>)
                                        }
                                    </ListItemAvatar>
                                    <ListItemText style={{ cursor: "pointer" }} primary={faculty.first_name + " " + faculty.last_name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="div" variant="body2" color="textPrimary">
                                                    {faculty.paper === null ? "" : `${faculty.paper.journal} - ${faculty.paper.title}`}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </StyledListItem>
                            ))
                        }
                        <StyledListItem divider key={-1} button selected={selectedIndex === potentialProfiles.length + 1} onClick={(event) => profileClickHandler(potentialProfiles.length + 1, null)}>
                            <ListItemAvatar style={{ minWidth: 30 }}>
                                {potentialProfiles.length + 1 + "."}
                            </ListItemAvatar>
                            <ListItemAvatar style={{ paddingRight: 30 }}>
                                <Avatar style={{ height: 75, width: 75 }}>
                                    <img src={defaultFacultyPic} alt={"profice pic"} height={75} width={75} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText style={{ cursor: "pointer" }} primary={`None of the above`} />
                        </StyledListItem>
                    </StyledList>
                </Paper>
                <div style={{ textAlign: "center", margin: "2%" }}>
                    <Button style={{ width: 145, marginRight: 20 }} className={inlineClasses.loginButton} variant="contained" onClick={prevButtonHandler} >
                        {"PREV"}
                    </Button>
                    <Button style={{ width: 145 }} className={inlineClasses.loginButton} variant="contained" onClick={nextButtonHandler} >
                        {"NEXT"}
                    </Button>
                </div>
            </div>
    }
    else if (stage === 3) {
        stageDisplay = success === null ?
            <ThemeProvider theme={theme2}>
                <ValidatorForm onSubmit={handleSubmit}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={3} className={inlineClasses.gridBG}>
                        <Grid item style={{ paddingBottom: 0 }}>
                            <Typography variant="h5">Welcome {firstName}</Typography>
                        </Grid>
                        <Grid item style={{ paddingTop: 5 }}>
                            <Typography variant="body1">Lets set up your account</Typography>
                        </Grid>
                        <Grid item>
                            <TextValidator
                                className={inlineClasses.textValidatorStyle}
                                InputProps={{ disableUnderline: true }}
                                name="username"
                                label="Username"
                                variant="filled"
                                value={formData.username}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']} />

                        </Grid>
                        <Grid item>
                            <TextValidator
                                className={inlineClasses.textValidatorStyle}
                                InputProps={{ disableUnderline: true }}
                                name="email"
                                label="Email"
                                variant="filled"
                                value={formData.email}
                                onChange={handleChange}
                                validators={['required', 'isEmail', 'mustHaveUniversityEmail']}
                                errorMessages={['This field is required', 'Email is not valid', 'Please provide University Email']} />

                        </Grid>
                        <Grid item>
                            <TextValidator
                                className={inlineClasses.textValidatorStyle}
                                InputProps={{ disableUnderline: true }}
                                name="password"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                label="Password"
                                variant="filled"
                                type="password"
                                value={formData.password}
                                onChange={handleChange} />
                        </Grid>
                        <Grid item>
                            <TextValidator
                                className={inlineClasses.textValidatorStyle}
                                InputProps={{ disableUnderline: true }}
                                name="confirmPassword"
                                label="Confirm Password"
                                value={formData.confirmPassword}
                                variant="filled"
                                validators={['isPasswordMatch', 'required']}
                                type="password"
                                errorMessages={['password mismatch', 'this field is required']}
                                onChange={handleChange} />
                        </Grid>

                        <Grid item>
                            <Button style={{ width: 120, marginRight: 20 }} className={inlineClasses.loginButton} variant="contained" onClick={prevButtonHandler} >
                                {"PREV"}
                            </Button>
                            <Button style={{ width: 120 }} className={inlineClasses.loginButton} variant="contained" type="submit" >
                                {"SIGN UP"}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </ThemeProvider>
            :
            success === true ?
                <Grid container direction="column" justify="center" alignItems="center" spacing={3} >
                    <Grid item>
                        <img src={successImg} alt="success" height={90} width={100} />
                    </Grid>
                    <Grid item>
                        <div>
                            Account created Successfully!
                    </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textAlign: "center" }}>
                            Your account has been successfully created! To complete the signup process, please go to your inbox
                            and complete the verification step and then
                        <StyledButton component={CustomRouterLink} to={'/login'} disableElevation disableRipple
                                disableFocusRipple disableTouchRipple style={{ textDecoration: "underline" }}>
                                login
                        </StyledButton>.
                    </div>
                    </Grid>
                </Grid>
                :
                <Grid container direction="column" justify="center" alignItems="center" spacing={3} >
                    <Grid item>
                        <div>
                            Account creation failed. Please try again later or
                            <StyledButton component={CustomRouterLink} to={'/signup'} disableElevation disableRipple
                                disableFocusRipple disableTouchRipple style={{ textDecoration: "underline" }} onClick={prevButtonHandler}>
                                &nbsp; start over
                            </StyledButton>.
                        </div>
                    </Grid>
                </Grid>
    }

    return (
        <div style={{ backgroundColor: "#E5E5E5", minHeight: "75vh" }}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <Paper square className={classes.paperStyle} style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            {stageDisplay}
                        </Paper>
                    </Grid>
                    <Grid item xs={3} />

                </Grid>
            </ThemeProvider>
        </div>
    )
}


export default Signup;