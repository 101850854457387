import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { BACKEND_URL } from '../../../../../utils';
import { useSelector } from 'react-redux';
import loader from '../../../../../assets/Images/loading.gif';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classes from './PersonalInformation.module.css';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../../constants/Theme';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import SingleSelectAutoComplete from '../../../../InputComponents/SingleSelectAutoComplete';
import { Checkbox } from '@material-ui/core';
import SingleSelect from '../../../../InputComponents/SingleSelect';
import InfoIcon from '@material-ui/icons/Info';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import NoteModal from '../../../../NoteModal/NoteModal';
const filter = createFilterOptions();

const AddPersonalInformationModal = props => {

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const theme = Theme();
    const token = useSelector(state => state.auth.token);

    const [firstName, setFirstName] = useState("")
    const [midName, setMidName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [position, setPosition] = useState("")
    const [cvLink, setcvLink] = useState("")
    const [id, setID] = useState("")
    const [university, setUniversity] = useState("")
    const [savingResume, setSavingResume] = useState(false);
    const [savingProfilePicture, setsavingProfilePicture] = useState(false);
    const [fileUploadLabel, setFileUploadLabel] = useState("Upload New CV");
    const [pictureUploadLabel, setPictureUploadLabel] = useState("Upload New Profile Picture");
    const [suffix, setSuffix] = useState("");
    const [prefix, setPrefix] = useState("");
    const [gender, setGender] = useState("")
    const [marker, setMarker] = useState("");
    const [profilePicture, setProfilePicture] = useState("");
    const userType = useSelector(state => state.auth.user_type);
    const [isValidForm, setIsValidForm] = useState(false);
    const [valueUniversity, setValueUniversity] = useState("");
    const [optionsUniversity, setOptionsUniversity] = React.useState([]);
    const [loadingUniversity, setLoadingUniversity] = React.useState(false)
    const [univId, setUnivId] = useState("");
    const [unusualWorkhist, setUnusualWorkhist] = useState(false);
    const [nameVariations, setNameVariations] = useState([]);
    const [typedNameVariation, setTypedNameVariation] = useState("");
    const genderMapping = { "Male": "m", "Female": "f", "Other": "o" };
    const [showNoteModal, setShowNoteModal] = useState(false);

    const [valueGender, setValueGender] = useState(Object.keys(genderMapping).find(key => genderMapping[key] === props.personalInfo.gender));

    const uploadFileHandler = async (event) => {
        setSavingResume(true);
        const data = new FormData();
        const fileName = event.target.files[0].name;
        data.append('file', event.target.files[0]);
        data.append('filename', fileName);
        data.append('user_id', props.personalInfo.id);
        const api_call = props.isCoAuthor === true ? `editauthor/upload_cv/${props.coAuthorId}` : 'profile/upload_cv';
        await fetch(BACKEND_URL + api_call, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: data,
        }).then(async (response) => {
            if (response.status === 200) {
                await response.json().then((body) => {
                    setcvLink(body.url);
                    if (firstName.trim() !== "" && lastName.trim() !== "" && body.url.startsWith("http") && gender.trim() !== "") {
                        setIsValidForm(true)
                    } else {
                        setIsValidForm(false)
                    }
                    setFileUploadLabel(fileName);
                });
            } else {
                alert('Failed to upload CV.')
            }
            setSavingResume(false);
        });
    };

    const uploadPictureHandler = async (event) => {
        setsavingProfilePicture(true);
        if (URL && (event.target.files).length !== 0) {
            const fileName = event.target.files[0].name;
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('filename', fileName);
            formData.append('user_id', props.coAuthorId)
            fetch(BACKEND_URL + 'profile/upload_picture', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token
                },
                body: formData,
            }).then(async (response) => {
                if (response.status !== 200) {
                    alert('Failed to update Profile Picture.')
                } else {
                    const res = await response.json();
                    setProfilePicture(res.url);
                    setPictureUploadLabel(fileName);
                }
                setsavingProfilePicture(false);
            })
                .catch(() => {
                    alert('Failed to update Profile Picture.')
                });
        }
    };

    const savePersonalInformation = () => {
        props.onSave({
            id: id,
            firstName: firstName,
            midName: midName,
            lastName: lastName,
            email: email,
            university: university,
            univId: univId,
            position: position,
            cvLink: cvLink,
            suffix: suffix,
            prefix: prefix,
            gender: gender,
            marker: marker,
            profilePicture: profilePicture,
            unusualWorkhist: ((unusualWorkhist === true) || (unusualWorkhist === false && props.personalInfo.unusualWorkhist === true)) ? unusualWorkhist : undefined,
            nameVariations: nameVariations.length !== 0 ? nameVariations : undefined
        });
        setFirstName("");
        setMidName("");
        setLastName("");
        setEmail("");
        setPosition("");
        setcvLink("");
        setUniversity("");
        setUnivId("");
        setID("");
        setSuffix("");
        setPrefix("");
        setGender("");
        setMarker("");
        setProfilePicture("");
        setUnusualWorkhist(false);
        setNameVariations([]);
        setTypedNameVariation("");
    };

    const handleSuffixChange = event => {
        setSuffix(event.target.value);
    };

    const handlePrefixChange = event => {
        setPrefix(event.target.value);
    };

    const handleUnusualWorkHistChange = () => {
        if (unusualWorkhist !== true)
            setUnusualWorkhist(true);
        else
            setUnusualWorkhist(false);
    };

    const HideModal = () => {
        props.onHide()
    };

    const errorMessage = (isValidForm) => {
        if (isValidForm) {
            return "";
        } else {
            var message = "Incomplete form: ";
            if (firstName.trim() === "") {
                message += "Enter First Name, ";
            }
            if (lastName.trim() === "") {
                message += "Enter Last Name, ";
            }
            if (email.trim() === "" || email === undefined) {
                message += "Enter Email, ";
            }
            if (cvLink === undefined || !cvLink.startsWith("http")) {
                message += "Upload CV, ";
            }
            if (gender === undefined || gender.trim() === "" || Object.values(genderMapping).includes(gender) === false) {
                message += "Choose Gender, ";
            }
            if (university === undefined || university.trim() === "" || (optionsUniversity.length !== 0 && optionsUniversity.map(univ => univ.name).includes(university) === false)) {
                message += "Choose Correct university, ";
            }
        }
        return message.slice(0, message.length - 2);
    };

    const firstNameHandler = event => {
        setFirstName(event.target.value);
        if (event.target.value.trim() !== "" && lastName.trim() !== "" && cvLink.startsWith("http") && gender.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const midNameHandler = event => {
        setMidName(event.target.value);
    };

    const lastNameHandler = event => {
        setLastName(event.target.value);
        if (firstName.trim() !== "" && event.target.value.trim() !== "" && cvLink.startsWith("http") && gender.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const genderHandler = (event, newInputValue) => {
        setValueGender(newInputValue);
        setGender(genderMapping[newInputValue]);
        if (firstName.trim() !== "" && lastName !== "" && cvLink.startsWith("http") && newInputValue.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    };

    const emailHandler = event => {
        setEmail(event.target.value);

        if (firstName.trim() !== "" && lastName.trim() !== "" && event.target.value.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }

    };

    const markerHandler = event => {
        const newInputValue = event.target.value;
        if (newInputValue === "")
            setMarker("");
        else setMarker(parseInt(newInputValue));
    };

    const universityChangeHandler = (name, value) => {
        setUniversity(name);
        setUnivId(optionsUniversity.filter(uni => uni.name === name).map(uni => uni.univ_id)[0]);
        if (optionsUniversity.length !== 0 && optionsUniversity.map(univ => univ.name).includes(name) === true) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const positionHandler = event => {
        setPosition(event.target.value);
    };

    const onInputUniversityChangeHandler = (event, newInputValue) => {
        setValueUniversity(newInputValue)
        universityChangeHandler(newInputValue, newInputValue)
        setLoadingUniversity(true)
        if (!newInputValue || newInputValue.trim() === "") {
            setLoadingUniversity(false)
            return;
        }
        const url = BACKEND_URL + 'auto/univ/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsUniversity(Object.keys(schools.universities).map((key) => schools.universities[key]));
            setLoadingUniversity(false)
        })();
    };

    const handleNewNameAddition = (newNameVar) => {
        setNameVariations([...nameVariations, newNameVar]);
        setTypedNameVariation("");
        setShowNoteModal(true);
    }

    const nameVariationChangeHandler = (event, newInputValue) => {
        if (typeof newInputValue === 'string') {
            setTypedNameVariation(newInputValue);
        } else if (newInputValue && newInputValue.newName) {
            var newVal = newInputValue.newName
            newVal = newVal.slice(5, newVal.length - 1);
            handleNewNameAddition(newVal)
        } else {
            setTypedNameVariation(newInputValue);
        }

    }

    const onInputChangeForNameVar = (event, target) => {
        if (event !== null)
            setTypedNameVariation(event.target.value)
    }

    const filterOptions = (nameVariations, typedNameVariation) => {
        const filtered = filter(nameVariations, typedNameVariation);

        const { inputValue } = typedNameVariation;

        const isExisting = nameVariations.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                newName: `Add "${inputValue}"`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.newName) {
            return option.newName;
        }
        return option;
    }

    useEffect(() => {
        setFirstName(props.personalInfo.firstName);
        setMidName(props.personalInfo.midName);
        setLastName(props.personalInfo.lastName);
        setEmail(props.personalInfo.email);
        setPosition(props.personalInfo.position);
        setcvLink(props.personalInfo.cvLink)
        setUniversity(props.personalInfo.university)
        setUnivId(props.personalInfo.univId)
        setID(props.personalInfo.id);
        setSuffix(props.personalInfo.suffix);
        setPrefix(props.personalInfo.prefix);
        setGender(props.personalInfo.gender);
        setMarker(props.personalInfo.marker);
        setProfilePicture(props.personalInfo.profilePicture);
        setUnusualWorkhist(props.personalInfo.unusualWorkhist === true ? true : false);
        setNameVariations(props.personalInfo.nameVariations === undefined ? [] : props.personalInfo.nameVariations);

        if (props.personalInfo.firstName.trim() !== "" && props.personalInfo.lastName.trim() !== "" && props.personalInfo.cvLink !== undefined && props.personalInfo.cvLink.startsWith("http") && props.personalInfo.gender.trim() !== "") {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    }, [props.personalInfo]);

    return (
        <ThemeProvider theme={theme}>
            <Modal
                {...props}
                dialogClassName={classes.myModal}
                aria-labelledby="example-custom-modal-styling-titles"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenters">
                        Add/Edit Personal Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className={classes.row} md={12}>
                            <Col md={2}>
                                <TextInputControlled label="Prefix" onChange={handlePrefixChange.bind(this)} value={prefix}>
                                </TextInputControlled>
                            </Col>
                            <Col md={3}>
                                <TextInputControlled label="First Name" onChange={firstNameHandler.bind(this)} value={firstName}>
                                </TextInputControlled>
                            </Col>
                            <Col md={2}>
                                <TextInputControlled label="Middle Name" onChange={midNameHandler.bind(this)} value={midName}>
                                </TextInputControlled>
                            </Col>
                            <Col md={3}>
                                <TextInputControlled label="Last Name" onChange={lastNameHandler.bind(this)} value={lastName}>
                                </TextInputControlled>
                            </Col>
                            <Col md={2}>
                                <TextInputControlled label="Suffix" onChange={handleSuffixChange.bind(this)} value={suffix}>
                                </TextInputControlled>
                            </Col>
                        </Row>
                        <Row className={classes.row} md={12}>
                            <Col md={6}>
                                <TextInputControlled label="Email" onChange={emailHandler.bind(this)} value={email}>
                                </TextInputControlled>
                            </Col>
                            <Col md={3}>
                                <SingleSelect
                                    name="gender"
                                    value={valueGender}
                                    defaultValue={valueGender}
                                    options={Object.keys(genderMapping)}
                                    label="Choose Gender"
                                    onInputChange={genderHandler}
                                    inputValue={valueGender} />
                            </Col>
                            <Col md={3}>
                                {
                                    userType === "admin" &&
                                    <SingleSelectAutoComplete
                                        options={nameVariations}
                                        curText={typedNameVariation}
                                        inputValue={typedNameVariation}
                                        onInputChange={onInputChangeForNameVar}
                                        label="Add/View Name Variations"
                                        onChange={nameVariationChangeHandler}
                                        filterOptions={filterOptions}
                                        getOptionLabel={getOptionLabel}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row className={classes.row} md={12}>
                            <Col md={6}>
                                {savingResume ? <div><img src={loader} alt="loading..." style={{ height: '50px', width: '50px' }} />Saving... </div> :
                                    <Form.File
                                        variant="filled"
                                        required
                                        id="custom-file"
                                        label={fileUploadLabel}
                                        custom
                                        onChange={uploadFileHandler.bind(this)}

                                    />
                                }
                            </Col>
                            {
                                userType === 'admin' && props.isCoAuthor === true &&
                                <Col md={6}>
                                    {savingProfilePicture === true ? <div><img src={loader} alt="loading..." style={{ height: '50px', width: '50px' }} />Saving... </div> :
                                        <Form.File
                                            accept="image/*"
                                            variant="filled"
                                            required
                                            id="custom-file"
                                            label={pictureUploadLabel}
                                            custom
                                            onChange={uploadPictureHandler.bind(this)}
                                        />
                                    }
                                </Col>
                            }
                        </Row>
                        {
                            userType === 'admin' &&
                            <Row className={classes.row} md={12}>
                                <Col md={4}>
                                    <Checkbox
                                        checked={unusualWorkhist}
                                        onClick={() => handleUnusualWorkHistChange()}
                                    />Unusual Profile
                                </Col>
                            </Row>
                            // [
                            //     <Row className={classes.row} md={12}>
                            //         <Col md={4}>
                            //             <TextInputControlled label="Marker" onChange={markerHandler.bind(this)} value={marker}>
                            //             </TextInputControlled>
                            //         </Col>
                            //         <Col md={4}>
                            //             <TextInputControlled label="Position" onChange={positionHandler.bind(this)} value={position}>
                            //             </TextInputControlled>
                            //         </Col>
                            //         <Col md={4}>
                            //             <SinglePreFilledAutoComplete name="name" value={university}
                            //                 onInputChange={onInputUniversityChangeHandler}
                            //                 inputValue={valueUniversity}
                            //                 loading={loadingUniversity}
                            //                 label="Select University"
                            //                 options={optionsUniversity.map(option => option.name)} />
                            //         </Col>
                            //     </Row>
                            //     ,
                            //     <Row className={classes.row} md={12}>
                            //         <Col md={12}>
                            //             <InfoIcon fontSize="small" style={{ color: "gray" }} />
                            //             <span style={{ verticalAlign: "middle" }}>Updating the University or Position will <strong>not</strong> update the Work Experience. Update these fields from here only as a correctional measure.</span>
                            //         </Col>
                            //     </Row>
                            // ]
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isValidForm === false && userType === 'admin' &&
                        <Row className={classes.row} md={12}>
                            <Col md={12}>
                                <InfoIcon fontSize="small" style={{ color: "gray" }} />
                                <span style={{ verticalAlign: "middle" }}><strong>Note for admin</strong> - {errorMessage(isValidForm)}</span>
                            </Col>
                        </Row>
                    }
                    <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={HideModal}>{"Close"}</Button>
                    <LightTooltip title={errorMessage(isValidForm)}>
                        <span>
                            <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={savePersonalInformation} disabled={userType === 'admin' ? false : !isValidForm}>Save</Button>
                        </span>
                    </LightTooltip>
                </Modal.Footer>
                <NoteModal show={showNoteModal} onHide={() => setShowNoteModal(false)} note={"The new name variation has been added."} />
            </Modal>
        </ThemeProvider>
    );
};

export default AddPersonalInformationModal;