import React from 'react';
import './MultipleNameVariationLog.css';
import Basicprofile from '../BasicProfile/BasicProfile';
import { useDispatch } from 'react-redux';
import * as logActions from '../../../store/actions/log';

const Multiplenamevariationslog = (props)=> {
    const dispatch = useDispatch();
    const log = props.location.state;
    const onResolveClick = async (removedNameVariations, removedPapers) => {
        const token = localStorage.getItem("rl_token");
        await dispatch(logActions.resolveMultipleNameVariationLog(token, log, removedPapers, removedNameVariations));  
        goBack();
    }
    
    const goBack = () => {
        console.log("GO BACK");
        props.history.replace({ 
            pathname: "/logs",
        });
    }

    return (
        <div>
            <span className="profile">
                        {"MULTIPLE_LAST_NAME_TWO_CHARACTER_NAME_VARIATION"}
            </span>
            <div style = {{ backgroundColor: "#fff", borderRadius: "2px", boxShadow: "0 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.22)", transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", margin: "1rem", zIndex: "-10", padding: "1rem"}}>
                <p>To resolve this log please have a look at the name variations and check if they are correct. Removing the name variation will not remove the corresponding paper so have a look at papers and see if they all belong to the same author. If not then remove them. Only click <b>RESOLVE</b> when you are sure you have made all the necessary changes to the record.</p>
            </div>
            <Basicprofile data={log} showEditOption={true} showNameVariations={true} onResolve={onResolveClick} onCancel={goBack}/>
        </div>
    )
}

export default Multiplenamevariationslog;
