import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as contactUsRequestAction from '../../store/actions/contactUsRequestAction';
import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { green, red } from "@material-ui/core/colors";
import { ThemeProvider } from "styled-components";

const ContactUsRequest = () => {

    const [currentPage, setCurrentPage] = React.useState(1);

    const dispatch = useDispatch();

    const muiTheme = createMuiTheme({ palette: { primary: { main:red[500]}, secondary: { main:green[500]}, }, })


    useEffect(() => {

        const getDefaultPage = async () => {

            const token = localStorage.getItem("rl_token");
            try {
                await dispatch(contactUsRequestAction.contactUsRequestAction(token, 0));
            }
            catch (error) {
                alert("Internal Server Error. Please try again after some time")
            }
        }

        getDefaultPage();

    }, []);


    const getContactsData = async (event, value) => {

        setCurrentPage(value);

        const token = localStorage.getItem("rl_token");
        const skipPage = ((value - 1) * 5)

        try {
            await dispatch(contactUsRequestAction.contactUsRequestAction(token, skipPage));
        }
        catch (error) {
            alert("Internal Server Error. Please try again after some time")
        }
    }

    const toggleContacted = async(id, flag, contactData) => {
        try {
            const adminToken = localStorage.getItem("rl_token");
            await dispatch(contactUsRequestAction.updateContacted(adminToken, id, flag, contactData));
        }
        catch (error) {
            alert("Internal Server Error. Please try again after some time")
        }
    }

    const requestData = useSelector(state => state.contactUsSupport.contactUsData);
    const requestCount = useSelector(state => state.contactUsSupport.totalData);

    return (
        <div style={{width:"98%"}}>
            <h2 style={{textAlign: "center", paddingTop:"3%",paddingBottom:"1%"}}>Contact Us Support</h2>
            {
                requestData ?

                    <TableContainer component={Paper}>
                        <Table className="contactTable" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"><b>Name</b></TableCell>
                                    <TableCell align="center"><b>Message</b></TableCell>
                                    <TableCell align="center"><b>Email</b></TableCell>
                                    <TableCell align="center"><b>Timestamp</b></TableCell>
                                    <TableCell align="center"><b>Contacted</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {requestData.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.message}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="center">{row.timestamp}</TableCell>
                                        <TableCell align="center">
                                            <ThemeProvider theme={muiTheme}>
                                                <Button variant="contained" color={row.contacted ? "primary" : "secondary"} onClick={() => toggleContacted(row.id, !row.contacted, requestData)}>
                                                    {row.contacted ? "Yes" : "No"}
                                                </Button>
                                            </ThemeProvider>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : null
            }

            <Grid container justify="center" alignItems="center">
                <Pagination count={requestCount} page={currentPage} onChange={getContactsData} />
            </Grid>
        </div>
    )
};

export default ContactUsRequest;
