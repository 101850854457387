import React ,{useState} from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';

const AddNoteModal = props => {

    const [note,setNote] = useState("");

    const onSaveClick = (toFlag) => {
      if (props.modalState === "merge") {
        props.onMergeSave(note, toFlag);
      }   
      else {
        props.onNoMergeSave(note, toFlag);
      }
      setNote("");
    }

    const noteChangeHandler = (event) => {
        setNote(event.target.value);
    }

    return (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Note to remember
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
                <Row md={12}>
                    <Col md={12}>
                        <TextField  
                            onChange={noteChangeHandler.bind(this)}
                            multiline={true}
                            rows={10}
                            fullWidth={true}
                            id="note"
                            label="Note" 
                            variant="outlined" />
                    </Col>
                </Row>
           </Container>
          </Modal.Body>
          <Modal.Footer>
            {
              props.modalState === "merge" ?
              <div style={{width:"100%", paddingLeft:"10%", paddingRight:"10%"}}>
                <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={() => onSaveClick(true)}>Merge & Flag</Button>
                <Button className="buttons" style={{ width: "145px", float:"right" }} variant="contained" onClick={() => onSaveClick(false)}>Merge</Button>
              </div>
              :
              <div style={{width:"100%", paddingLeft:"10%", paddingRight:"10%"}}>
                <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={() => onSaveClick(true)}>Add & Flag</Button>
                <Button  className="buttons" style={{ width: "145px", float:"right" }} variant="contained" onClick={() => onSaveClick(false)}>Add</Button>
              </div>
            }
          </Modal.Footer>
        </Modal>
      );

}

export default AddNoteModal;