import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const interestingFactsAction = (data) => {
    return {
        type: actionTypes.GET_INTERESTING_FACTS,
        interestingFactsResult: data
    }
}

export const departmentFactsAction = (data) => {
    return {
        type: actionTypes.GET_DEPARTMENT_FACTS,
        departmentFactsResult: data
    }
}

export const getDashboardDepartmentsAction = (data) => {
    return {
        type: actionTypes.GET_DASHBOARD_DEPARTMENTS,
        departmentsResult: data
    }
}

export const genderDistributionAction = (data) => {
    return {
        type: actionTypes.GET_GENDER_DISTRIBUTION,
        genderDistributionResult: data
    }
}

export const updateSelectedDeptAction = (data) => {
    return {
        type: actionTypes.SELECTED_DEPARTMENT_DASHBOARD,
        selectedDepartment: data
    }
}

export const schoolModeAction = (data) => {
    return {
        type: actionTypes.SCHOOL_MODE,
        schoolMode: data
    }
}

export const schoolMode = (data) => {
    return async dispatch => {
        dispatch(schoolModeAction(data))
    }
}

export const outliersAction = (data) => {
    return {
        type: actionTypes.GET_OUTLIERS,
        outliers: data
    }
}

export const facultyByMarkerShareAction = (data) => {
    return {
        type: actionTypes.GET_FACULTY_BY_MARKET_SHARE,
        facultyMarketShares: data
    }
}

export const facultyCitationsAction = (data) => {
    return {
        type: actionTypes.GET_FACULTY_CITATIONS,
        facultyCitations: data
    }
}

export const tenureReadinessAction = (data) => {
    return {
        type: actionTypes.GET_TENURE_READINESS,
        tenureReadiness: data
    }
}

export const selectedDepartment = (selectedDept) => {
    return async dispatch => {
        
    };
}

export const interestingFacts = (token, selectedDepartment) => {

    return async dispatch => {
        var URL = BACKEND_URL + `dashboard/interesting_fact`
        if (selectedDepartment !== null) {
            URL = URL + `?dept=` + selectedDepartment;
        }

        const response = await fetch(URL, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching intersting facts for dashboard' + errorResData);
        }
        else {
            const interestingFactsData = await response.json();
            dispatch(interestingFactsAction(interestingFactsData));

        }
    };
}

export const departmentFacts = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dashboard/department_fact`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });


        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching department facts for dashboard' + errorResData);
        }
        const departmentFactsData = await response.json();

        dispatch(departmentFactsAction(departmentFactsData));
    };
}

export const genderDistribution = (token, isSchoolMode, selectedDepartment) => {
    return async dispatch => {
        var URL = BACKEND_URL + `gender`;
        if (!isSchoolMode) {
            URL = URL + `?department=` + selectedDepartment;
        } else {
            URL = URL + `?department=all`;
        }

        const response = await fetch(URL, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching gender distribution for dashboard' + errorResData);
        }
        const genderDistributionData = await response.json();
        dispatch(genderDistributionAction(genderDistributionData));
    };
}

export const departments = (token, universityId) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dept?id=` + universityId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for ' + universityId + ' with error: ' + errorResData);
        }
        const departmentData = await response.json();

        dispatch(getDashboardDepartmentsAction(departmentData));
    };
}

export const getOutliers = (token, marker, dept) => {
    return async dispatch => {
        const departmentArgument = dept === null ? '' : `&dept=${dept}`
        const response = await fetch(BACKEND_URL + `dashboard/outliers?marker=${marker}${departmentArgument}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching outliers for dashboard' + errorResData);
        }
        const outliers = await response.json();
        dispatch(outliersAction(outliers));
    };
}

export const getFacultyByMarketShare = (token, markers, dept) => {
    return async dispatch => {
        let markerParam = "";
        for (let marker of markers) {
            markerParam += "marker=" + marker + "&";
        }
        const departmentArgument = dept === null ? '' : `dept=${dept}`
        const response = await fetch(BACKEND_URL + `dashboard/faculty_market_share?${markerParam}${departmentArgument}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching outliers for dashboard' + errorResData);
        }
        const facultyMarketShares = await response.json();
        dispatch(facultyByMarkerShareAction(facultyMarketShares));
    };
}

export const getFacultyCitations = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dashboard/faculty_citations`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error('Something went wrong while fetching faculty citations for dashboard');
        }
        const facultyCitationsData = await response.json();
        dispatch(facultyCitationsAction(facultyCitationsData));
    };
}

export const getTenureReadiness = (token, year,journal, dept) => {
    return async dispatch => {
        const departmentArgument = dept === null ? '' : `&dept=${dept}`
        const journalArgument = journal === null ? '' : `&journal=${journal}`
        const response = await fetch(BACKEND_URL + `dashboard/tenure_readiness?years=${year}${journalArgument}${departmentArgument}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while fetching tenure readiness for dashboard' + errorResData);
        }
        const tenureReadinessData = await response.json();
        dispatch(tenureReadinessAction(tenureReadinessData));
    };
}
