import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import profileReducer from './store/reducers/profile';
import univProfileReducer from './store/reducers/univProfile';
import authReducer from './store/reducers/auth';
import coauthorprofileReducer from './store/reducers/coauthorprofile';
import profileUpdateReducer from './store/reducers/profileupdate';
import exploreReducer from './store/reducers/explore';
import facultyProuductivityReducer from './store/reducers/facultyProductivity';
import compareGenderReducer from './store/reducers/compareGender';
import perceptualMapReducer from './store/reducers/perceptualMap';
import compareYourselfReducer from './store/reducers/compareYourself';
import authorSearchReducer from './store/reducers/authorSearch';
import facultyInfoInputReducer from './store/reducers/facultyInfoInput';
import cohortAnalysisReducer from './store/reducers/cohortAnalysis';
import departmentConfigReducer from './store/reducers/departmentConfigHome';
import findYourFitReducer from './store/reducers/findYourFit';
import compareDepartmentsReducer from './store/reducers/compareDepartments';
import hireImpactReducer from './store/reducers/hireImpact';
import dashboardReducerFaculty from './store/reducers/dashboardFaculty';
import dashboardReducerUniv from './store/reducers/dashboardUniv';
import facultyProgressReducer from './store/reducers/facultyProgress';
import contactUsRequestReducer from './store/reducers/contactUsRequestReducer'
import logReducer from './store/reducers/log';
import mergeToolReducer from './store/reducers/mergeTool';
import addAuthorToolReducer from './store/reducers/addAuthorTool';
import authorRankReducer from './store/reducers/authorRank';
import tenureReadinessReducer from './store/reducers/tenureReadiness';
import journalDistributionReducer from './store/reducers/journalDistributionTree';
import similarAuthorSearchReducer from './store/reducers/showSimilarAuthor';
import updateFacultyReducer from './store/reducers/updateFaculty';
import redflagReducer from './store/reducers/redflags';

const rootReducer = combineReducers({
    profile: profileReducer,
    univProfile: univProfileReducer,
    auth: authReducer,
    coauthorprofile: coauthorprofileReducer,
    profileUpdate: profileUpdateReducer,
    explore: exploreReducer,
    dashboard: dashboardReducerFaculty,
    dashboards: dashboardReducerUniv,
    facultyProductivity: facultyProuductivityReducer,
    compareGender: compareGenderReducer,
    perceptualMap: perceptualMapReducer,
    compareYourself: compareYourselfReducer,
    authorSearch: authorSearchReducer,
    facultyInfoInput: facultyInfoInputReducer,
    cohortAnalysis: cohortAnalysisReducer,
    deptConfig: departmentConfigReducer,
    findYourFit: findYourFitReducer,
    compareDepartments: compareDepartmentsReducer,
    hireImpact: hireImpactReducer,
    facultyProgress: facultyProgressReducer,
    contactUsSupport: contactUsRequestReducer,
    log: logReducer,
    mergeTool: mergeToolReducer,
    addAuthorTool: addAuthorToolReducer,
    rank: authorRankReducer,
    tenureReadiness: tenureReadinessReducer,
    journalDistribution: journalDistributionReducer,
    similarAuthorSearch: similarAuthorSearchReducer,
    updateFaculty: updateFacultyReducer,
    redflags: redflagReducer,
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(applyMiddleware(ReduxThunk)));
//const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

ReactDOM.render(<Provider store={store} ><App /></Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
