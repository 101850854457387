import React, {useState,useEffect} from 'react';
import {BACKEND_URL} from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
const UnivAutoComplete = props => {

    const [value,setValue] = useState("");
    
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading,setLoading] =React.useState(false);
  
    useEffect(() => {
        if(!props.value || props.value===""){
            setOptions([])
            setValue("")
        }else{
            setOptions([{name:props.value}])
            setValue(props.value)
        }
    },[props.value]);
    const onInputChangeHandler= (event,newInputValue) =>{
        setValue(newInputValue)
        props.onChange(newInputValue,newInputValue)
        setLoading(true)
        if(!newInputValue || newInputValue.trim()==="")
        {
          setLoading(false)
            return;
        }
        const url = BACKEND_URL +'auto/univ/'+ newInputValue;
        (async () => {
            const response = await fetch(url);
            //await sleep(1e3); // For demo purposes.
            const schools = await response.json();
            if (schools && schools !== undefined && schools.universities && schools.universities !== undefined){
              setOptions(Object.keys(schools.universities).map((key) => schools.universities[key]));
            }
           setLoading(false)
          })();
    }
    return(   
        <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
          setLoading(false)
        setOpen(false);
      }}
      defaultValue={{name:props.value}}
      inputValue={value}
      options={options.map(option=>option.name)}
      loading={loading}
      disableClearable
      freeSolo
      onInputChange = {onInputChangeHandler}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="University"
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {/*   {params.InputProps.endAdornment} */}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
    )
}
export default UnivAutoComplete;