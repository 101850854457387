import React, { useState, useEffect } from 'react';
import './Logs.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {  Container, Row, Col } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paperlog from './LogTableRecord/PaperLog';  
import Authorlog from './LogTableRecord/AuthorLog';
import * as logActions from '../../store/actions/log';
import { useDispatch, useSelector } from 'react-redux';

  

  const useStyles = makeStyles({
    root: {
      width: '99%',
    },
    tableBottomBorder : { 
        borderWidth: 1, 
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeftWidth:0,
        borderRightWidth:0,
    },
    indexHeaderColumn: {
        width: "15px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
        borderTopWidth:0,
        backgroundColor: "#BBB21E"
    },
    logHeaderColumn: {
        // Width: "500px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
        borderRightWidth:0, 
        borderTopWidth:0,
        backgroundColor: "#BBB21E"
    },
    indexColumn: {
        width: "15px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
    },
    logColumn: {
        // minWidth: "500px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
        borderRightWidth:0,
    }
  });

const errorTypes = { 
    "NAME_TOO_SIMILAR": {
        link: '',
        page: 'paper'
    }, 
    "RG_NAME_MISMATCH": {
        link: '/logs/name_mismatch',
        page: 'paper'
    }, 
    "GS_NAME_MISMATCH":{
        link: '/logs/name_mismatch',
        page: 'paper'
    }, 
    "MULTIPLE_LAST_NAME_TWO_CHARACTER_NAME_VARIATION":{
        link: '/logs/multiple_name_variations_log',
        page: 'author'
    }, 
    "RG_GS_MISMATCH":{
        link:  '/logs/rg_gs_name_mismatch',
        page: 'paper'
    }, 
    "GAP_OF_10_YEARS":{
        link: '/logs/gap_between_papers',
        page: 'author'
    },
    "MERGE_TOOL_CHECK_MERGE_LATER": {
        link: '/dashboard/profileEdit',
        page: 'author',
    }
};

const Logs = props=> {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const filterCondition =  useSelector(state => state.log.filter_condition);
    // const [filteredError, setFilteredError] = useState(filterCondition);
    const allLogs = useSelector(state => state.log.logs);
    
    useEffect(() => {
        // console.log("Called Logs UseEffect hook");
        async function fetchLogs() {
            const token = localStorage.getItem("rl_token");
            await dispatch(logActions.fetchLogs(token, filterCondition));        
        }
        fetchLogs();

    },[]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleFilterChange = async (selectedValue) =>{
        // setFilteredError(selectedValue);
        const token = localStorage.getItem("rl_token");
        await dispatch(logActions.filterLogs(token, selectedValue));
    }

    const onErrorClick = async (row) =>{
        // console.log("clicked...."+errorTypes[row.error].link)
        if (row.error === "MERGE_TOOL_CHECK_MERGE_LATER") {
            props.history.push({ 
                pathname: errorTypes[row.error].link,
                state: {
                    "coauthorid": row.author_final_id,
                    "note": row.note
                }
            });
        } 
        else {
            props.history.push({ 
                pathname: errorTypes[row.error].link,
                state: row
            });
        }
        // To be used while returning to log from specific error log page
        // await dispatch(logActions.resolveLog(row));
    }
    //Change it later to get the filter Options from a state maybe
    return (
        <div>
            <Container className="heading">
                <Row>
                    <Col>
                    <span className="profile">
                        {"Routine Logs"}
                    </span>
                    </Col>
                </Row>
            </Container>
                <Paper className={classes.root}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" style={{borderWidth:1, borderColor:"black", borderStyle:"solid"}}>
                        <TableHead >
                            <TableRow>
                            <TableCell
                                align="left"
                                className={classes.indexHeaderColumn}//, borderWidth:1, borderStyle:"solid", borderColor:"black", borderRightWidth:0 }}
                                >
                                <h4>#</h4>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.logHeaderColumn}//, borderWidth:1, borderStyle:"solid", borderColor:"black", borderLeftWidth:0 }}
                                >
                                <div style={{display:"flex"}}>
                                    <div style={{flex:1}}>
                                        <h4>Logs</h4>
                                    </div>
                                    <div style={{flex:1, backgroundColor: "white"}}>
                                    <Autocomplete
                                        width = "50px"
                                        id="errors-auto"
                                        options={["All"].concat(Object.keys(errorTypes))}
                                        size="small"
                                        value={filterCondition}
                                        renderInput={(params) => <TextField {...params} label={!filterCondition ? "Filter by Error" : undefined} variant="outlined" />}
                                        onChange={
                                            (e, value) => {
                                            handleFilterChange(value)
                                            }
                                        }
                                    />
                                    </div>
                                </div>
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                <TableCell className={classes.indexColumn} align="left" > 
                                        {index+1}
                                </TableCell>
                                <TableCell className={classes.logColumn} align="left" > 
                                    <div style={{minHeight:"50px"}}>
                                        {/* <h4>{row["name"]}</h4> */}
                                        {
                                        errorTypes[row.error].page === 'paper' 
                                        ?
                                            <Paperlog key={row._id} data={row} onClick={() => onErrorClick(row)}/> 
                                        :
                                            <Authorlog key={row._id} data={row} onClick={() => onErrorClick(row)}/>
                                        }
                                    </div>
                                </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allLogs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                    {/* <Container >
                        <Row md={12} className="row">
                            <Col md={2}>
                            </Col>
                            <Col md={8}>
                            <Autocomplete
                            id="errors-auto"
                            options={filterOptions}
                            fullWidth
                            size="small"
                            value={filteredError}
                            renderInput={(params) => <TextField {...params} label="Filter by Error" variant="outlined" />}
                            onChange={
                                (e, value) => {
                                console.log(value)
                                setFilteredError(value)
                                }
                            }
                            />
                            </Col>
                            <Col md={2}>
                            </Col>
                        </Row>
                    </Container> */}
                {/* </div> */}
            {/* </div> */}
            {/* <SingleSelect 
                error={null} 
                options={filterOptions} 
                onInputChange={handleFilterChange}
                inputValue={filteredError} 
                label="Filter by error"
                required={false}
                defaultValue= "All"
                helperText="" />        */}
            {/* <h1>Logs will appear here</h1> */}
        </div>
    )
}

export default Logs;
