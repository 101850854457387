import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import * as classes from '../ResearchProfiles/ResearchProfiles.module.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import classes2 from './AreaOfResearch.module.css';
import { BACKEND_URL } from '../../../../../utils';

const filter = createFilterOptions();

const AddAreaOfResearchModal = props => {

    const [AOIoptions, setAOIoptions] = React.useState([]);
    const [selectedAOI, setselectedAOI] = React.useState([]);
    const [TypedKeyword, setTypedKeyword] = useState("");
    const [selectedKeyword, setselectedKeyword] = React.useState([]);
    const [KeywordOptions, setKeywordOptions] = React.useState([]);
    const token = localStorage.getItem("rl_token");

    const saveKeywordsAOR = () => {

        props.onSave(props.profileKeywords !== selectedKeyword ? selectedKeyword : undefined,
            props.profileAOI !== selectedAOI ? selectedAOI : undefined)
        setAOIoptions([])
        setselectedAOI([])
        setTypedKeyword("")
        setselectedKeyword([])
        setKeywordOptions([])
    }

    const hideModal = () => {
        props.onHide();
    };

    const getAreaofInteretestOption = async (token) => {

        let data = [
            "Applied",
            "International Relations",
            "Social Sciences, Interdisciplinary",
            "Science & Technology",
            "Operations Research & Management Science",
            "Operations Research &",
            "Education & Educational Research",
            "Administration",
            "Economics",
            "History Of Social Sciences",
            "Social Issues",
            "Mathematics, Interdisciplinary Applications",
            "Biology",
            "Geography",
            "Development Studies",
            "Computer Science,",
            "Computer Science, Cybernetics",
            "Management",
            "Law",
            "Tourism",
            "Psychology, Social",
            "Public",
            "Computer Science, Software Engineering",
            "Computer Science, Theory & Methods",
            "Public, Environmental & Occupational Health",
            "Statistics & Probability",
            "Business",
            "Environmental Studies",
            "Engineering, Biomedical",
            "Psychology, Educational",
            "Regional & Urban Planning",
            "Engineering, Industrial",
            "Computer Science, Interdisciplinary Applications",
            "Behavioral Sciences",
            "Urban Studies",
            "Engineering,",
            "Ecology",
            "Information Science & Library Science",
            "Probability",
            "Computer Science, Information",
            "Agricultural Economics & Policy",
            "Statistics &",
            "Political Science",
            "Systems",
            "Biomedical",
            "Mathematics, Interdisciplinary",
            "Transportation Science & Technology",
            "Operations Research & Management",
            "Technology",
            "Applications",
            "Psychology, Multidisciplinary",
            "Operations Research & Management Science;",
            "Area Studies",
            "Industrial",
            "Mathematics,",
            "Food Science & Technology;",
            "Industrial Relations & Labor",
            "& Management Science",
            "GREEN & SUSTAINABLE SCIENCE & TECHNOLOGY",
            "Methods",
            "Health Care Sciences & Services",
            "Transportation Science &",
            "Sciences, Interdisciplinary",
            "Sciences, Mathematical Methods",
            "Social",
            "Mathematical & Computational Biology",
            "Mathematical Methods",
            "Nutrition & Dietetics",
            "Environmental Sciences",
            "Electronic",
            "Engineering, Environmental",
            "Engineering, Civil",
            "Social Sciences, Biomedical",
            "Thermodynamics",
            "Psychology",
            "Sociology",
            "Green & Sustainable Science & Technology",
            "Communication",
            "Cybernetics",
            "Information Science & Library",
            "Social Sciences, Mathematical Methods",
            "Psychology, Experimental",
            "Computer Science, Theory &",
            "Engineering, Electrical &",
            "Cultural Studies",
            "Computer Science, Artificial Intelligence",
            "Business, Finance",
            "Psychology,",
            "Operations Research",
            "Psychology, Mathematical",
            "Women's Studies",
            "Rehabilitation",
            "Psychology, Biological",
            "Anthropology",
            "Ethics",
            "Energy & Fuels",
            "Mathematics, Applied",
            "Hospitality, Leisure, Sport & Tourism",
            "Transportation",
            "Psychology, Applied",
            "Ergonomics",
            "Hospitality, Leisure, Sport &",
            "Physiology",
            "History",
            "Engineering, Manufacturing",
            "Science",
            "Computer Science, Information Systems",
            "Health Policy & Services",
            "Interdisciplinary Applications",
            "Social Work",
            "Management Science",
            "Social Sciences,",
            "Public Administration",
            "Demography"
        ]
        setAOIoptions(data.sort());
    };

    const onAOIChange = (...params) => {

        let tempAOI = [];

        if (params[2] === "clear") {
            tempAOI = [];
        }
        else {
            let selectedOption = params[3].option;
            let AOINames = [];
            if (selectedOption !== "All") {

                if (params[2] === "select-option") {
                    tempAOI = [selectedOption].concat(selectedAOI);
                }
                else if (params[2] === "remove-option") {

                    tempAOI = selectedAOI.filter(area => area !== "All" && area !== selectedOption);
                }
                tempAOI.map((area, i) => {
                    AOINames.push(area);
                });
            }

        }

        setselectedAOI(tempAOI);
    };

    const onKeywordChangeHandler = (...params) => {
        let tempKeyword = [];
        let newInputValue = params[3].option;

        if (params[1].length === 0) {
            setTypedKeyword(newInputValue);
        }

        if (params[2] === "clear") {
            tempKeyword = [];
        }
        else {

            let KeywordNames = [];
            let selectedOption = params[3].option;

            if (selectedOption === "All") {
                if (params[2] === "select-option") {
                    tempKeyword = KeywordOptions;
                }
                else if (params[2] === "remove-option") {
                    tempKeyword = [];
                }
                KeywordNames.push("All Keywords");

            }
            else {
                if (params[2] === "select-option") {
                    if (selectedOption.newKeyword) {
                        var newVal = selectedOption.newKeyword
                        newVal = newVal.slice(5, newVal.length - 1);
                        tempKeyword = [newVal].concat(selectedKeyword);
                        setKeywordOptions([...KeywordOptions, newVal])

                    }
                    else {
                        tempKeyword = [selectedOption].concat(selectedKeyword);
                    }
                }
                else if (params[2] === "remove-option") {
                    tempKeyword = selectedKeyword.filter(area => area !== "All" && area !== selectedOption);
                }
                tempKeyword.map((area, i) => {
                    KeywordNames.push(area);
                });
            }
        }
        setTypedKeyword('');
        setselectedKeyword(tempKeyword);
    };

    const filterOptions = (KeywordOptions, TypedKeyword) => {
        const filtered = filter(KeywordOptions, TypedKeyword);

        const { inputValue } = TypedKeyword;

        const isExisting = KeywordOptions.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                newKeyword: `Add "${inputValue}"`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.newKeyword) {
            return option.newKeyword;
        }
        return option;
    }

    const getKeywordOption = async (token, currKeywords) => {

        if (props.coAuthorId) {
            let url = BACKEND_URL + "get_auth_keywords/";
            const arg = props.isCoAuthor === true ? props.coAuthorId : 'get_profile_keywords';

            const response = await fetch(url + arg, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            });
            if (response.status !== 200) {
                alert("Unable to fetch areas of interest.")
            }
            else {
                const response_data = await response.json();
                if (currKeywords) {
                    var tempSet = new Set([...currKeywords, ...response_data])
                    setKeywordOptions((Array.from(tempSet)).sort())
                }
            }
        }
    };

    const onInputChangeForKeywords = (event, target) => {
        if (event !== null) {
            setTypedKeyword(event.target.value);
        }
    }

    useEffect(() => {
        getAreaofInteretestOption(token);
        setselectedAOI(props.profileAOI)
        setselectedKeyword(props.profileKeywords)
        getKeywordOption(token, props.profileKeywords);
    }, [props.profileKeywords, props.profileAOI, props.coAuthorId]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add/Edit Area of Research
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row md={12} className={classes2.row}>
                        <Col md={12} style={{ paddingBottom: "10px" }}>
                            <Autocomplete
                                options={KeywordOptions}
                                inputValue={TypedKeyword}
                                value={selectedKeyword}
                                onInputChange={onInputChangeForKeywords}
                                onChange={onKeywordChangeHandler}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                multiple
                                disableClearable
                                size="small"
                                limitTags={3}
                                disableCloseOnSelect
                                fullWidth={true}
                                renderInput={(params) => (
                                    <TextField  {...params} variant="filled" placeholder="Select Keywords" value={TypedKeyword} label="Keywords" />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row md={12} className={classes2.row}>

                        <Col md={12} style={{ paddingBottom: "10px" }}>
                            <Autocomplete
                                multiple
                                size="small"
                                onChange={onAOIChange}
                                value={selectedAOI}
                                limitTags={3}
                                disableClearable
                                id="checkboxes-tags-demo"
                                options={AOIoptions}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option}
                                fullWidth={true}
                                renderInput={(params) => (
                                    <TextField  {...params} variant="filled" label="Area of Interest" placeholder="Select Area of Interest" />
                                )}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={hideModal}>Close</Button>
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={saveKeywordsAOR}>Save</Button>
            </Modal.Footer>
        </Modal>

    );
};

export default AddAreaOfResearchModal;