import React, { useState } from 'react';
import classes from './PersonalInformation.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import * as profileActions from '../../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../../store/actions/coauthorprofile';
import Profile from '../../../../../model/profile';
import AddPersonalInformationModal from './AddPersonalInformationModal'
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import rl_icon from '../../../../../assets/Images/logos/RL_Icon.jpg';
import axios from 'axios'
import IconButton from 'material-ui/IconButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'; 
import { BACKEND_URL } from '../../../../../utils';
import ProgressBar from '../../../../Admin/ResumeUpload/ProgressBar';
import { Button } from 'react-bootstrap';


const PersonalInformation = props => {
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const token = useSelector(state => state.auth.token);
    const editColor = "#DADADA"
    const [firstName, setFirstName] = useState(props.data.first_name);
    const [midName, setMidName] = useState(props.data.mid_name);
    const [lastName, setLastName] = useState(props.data.last_name);
    const [suffix, setSuffix] = useState(props.data.suffix);
    const [prefix, setPrefix] = useState(props.data.prefix);
    const [unusualWorkhist, setUnusualWorkhist] = useState(props.data.unusual_work_hist);
    const [position, setPosition] = useState(props.data.position);
    const [email, setEmail] = useState(props.data.email);
    const [university, setUniversity] = useState(props.data.univ_name);
    const [cvLink, setCVLink] = useState(props.data.cv);
    const [gender, setGender] = useState(props.data.gender);
    const [marker, setMarker] = useState(props.data.marker);
    const [modalShow, setModalShow] = useState(false);
    const userType = useSelector(state => state.auth.user_type);
    const genderMapping = { "m": "Male", "f": "Female", "o": "Other" };
    const [profilePicture, setProfilePicture] = useState(props.data.profile_picture);
    const [progressStatus, setProgressStatus] = useState(0);
    const [highlightedCVLink, sethighlightedCVLink] = useState(props.data.highlighted_cv || "");
    
    const [personalInfo, setPersonalInfo] = useState({
        id: props.data._id, firstName: props.data.first_name, midName: props.data.mid_name,
        lastName: props.data.last_name, position: props.data.position, university: props.data.univ_name,
        univId: props.data.univ_id, email: props.data.email, cvLink: props.data.cv,
        suffix: props.data.suffix, prefix: props.data.prefix, gender: props.data.gender,
        marker: props.data.marker, profilePicture: props.data.profile_picture,
        unusualWorkhist: props.data.unusual_work_hist, nameVariations: props.data.name_variations
    })

    const dispatch = useDispatch();
    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const createUpdatedProfile = (fname, mname, lname, pos, emails, cvlinked, univ, univId, suf, prefix, gender, marker, unusualWorkhist, nameVariations) => {
        return Object.assign(new Profile(), {
            _id: props.data._id,
            first_name: fname,
            mid_name: mname,
            last_name: lname,
            position: pos,
            marker: marker,
            email: emails,
            cv: cvlinked,
            univ_id: univId,
            univ_name: univ,
            suffix: suf,
            prefix: prefix,
            gender: gender,
            ...(unusualWorkhist !== undefined) && { unusual_work_hist: unusualWorkhist },
            ...(nameVariations !== undefined) && { name_variations: nameVariations }
        })
    };

    const setPersonalInformationModal = () => {
        setPersonalInfo({ ...personalInfo })
        setModalShow(true);
    };

    const editPersonalInfo = async (personalInfo) => {
        setModalShow(false);
        setFirstName(personalInfo.firstName)
        setLastName(personalInfo.lastName)
        setMidName(personalInfo.midName)
        setPosition(personalInfo.position)
        setCVLink(personalInfo.cvLink)
        setEmail(personalInfo.email)
        setUniversity(personalInfo.university)
        setSuffix(personalInfo.suffix)
        setPrefix(personalInfo.prefix)
        setGender(personalInfo.gender)
        setMarker(personalInfo.marker)
        setProfilePicture(personalInfo.profilePicture)
        setUnusualWorkhist(personalInfo.unusualWorkhist)
        setPersonalInfo({
            ...personalInfo, firstName: personalInfo.firstName, midName: personalInfo.midName,
            lastName: personalInfo.lastName, position: personalInfo.position, university: personalInfo.university,
            univId: personalInfo.univId, email: personalInfo.email, cvLink: personalInfo.cvLink,
            suffix: personalInfo.suffix, prefix: personalInfo.prefix, gender: personalInfo.gender,
            marker: personalInfo.marker, profilePicture: personalInfo.profilePicture,
            unusualWorkhist: personalInfo.unusualWorkhist, nameVariations: personalInfo.nameVariations
        })
        try {
            const newProfile = createUpdatedProfile(
                personalInfo.firstName, personalInfo.midName, personalInfo.lastName, personalInfo.position,
                personalInfo.email, personalInfo.cvLink, personalInfo.university, personalInfo.univId,
                personalInfo.suffix, personalInfo.prefix, personalInfo.gender, personalInfo.marker,
                personalInfo.unusualWorkhist, personalInfo.nameVariations)

            if (props.isCoAuthor === true) {
                await dispatch(coAuthorProfileActions.update_co_author_profile(token, newProfile, props.data._id));
            } else {
                await dispatch(profileActions.update_author_profile(token, newProfile, props.data._id));
            }
        }
        catch (error) {
            alert("Failed to Update! Please Refresh the page and try again.");
        }
    };

    const markJournals = async e => {
        let file_path = e
        const formData = new FormData();
        formData.append('file', file_path);

        const token = localStorage.getItem('rl_token');
        axios.post(BACKEND_URL + "mark_journals", {file: file_path, author : {"first_name": firstName, "last_name": lastName}}, {
            headers: {
                'Content-Type':'application/json',
                Authorization: 'Bearer '+ token,
            },
            onUploadProgress: progressEvent => {
                setProgressStatus(parseInt(Math.round((progressEvent.loaded * 100)/progressEvent.total)));
            
            }
        })
        .then(function (response) {
            const filePath =  response.data.url;
            
            if(filePath.length > 0){    
                sethighlightedCVLink(filePath);
            }
            else{
                console.log('Highlighted CV URL is empty.');
            }
            setProgressStatus(0);
            })

        .catch(function(error){
            if (error.response){
                if(error.response.status === 500){
                    console.log('There was a problem with server');
                }
                else{
                    console.log('Error '+error.response.status);
                    }
            }
            setProgressStatus(0);
        });
    }

    const downloadHighlightedCV = e => {
        if(highlightedCVLink.length <= 0){
            return
        }
        const link = document.createElement('a');
        link.href = highlightedCVLink;
        link.target = '_blank';
        link.download = highlightedCVLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <MuiThemeProvider >
        <Container className={classes.container}>
            {progressStatus > 0 ? 
                <ProgressBar percentage={progressStatus}/>
                : null
                }
            <Row className={classes.header}>
                <Col sm={6} md={6}>
                    <Row>
                        <Col md={12}>
                            <h3>
                                {prefix !== "" ? prefix + " " : ""}
                                {firstName !== "" ? firstName + " " : ""}
                                {midName !== "" ? midName + " " : ""}
                                {lastName !== "" ? lastName + " " : ""}
                                {suffix}
                                {
                                    unusualWorkhist === true &&
                                    <span className={classes.flagged}>
                                        Flagged Unusual
                                    </span>
                                }
                            </h3>
                        </Col>
                    </Row>
                    <Row className={classes.historyRow}>
                        <Col md={12}>
                            <span className={classes.standardSize}>{position}</span>
                            <span className={classes.standardSize}>{(position !== null && position !== undefined && position.trim() !== "") ? " at " : ""}</span>
                            <span className={classes.standardSize}>{university}</span>
                            {
                                userType === "admin" && marker !== undefined && marker !== "" && marker !== null &&
                                <span className={classes.standardSize}>
                                    &nbsp;- Marker {marker}
                                </span>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={11} className={classes.italics} >
                            <span className={classes.standardSize}>
                                {email + ""}
                                &nbsp; &nbsp;
                                {gender !== undefined && gender !== "" ? "Gender: " + genderMapping[gender] : ""}
                                &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
                            </span>
                        </Col>
                    </Row>
                </Col>

                <Col md={2} sm={2} style={{ margin: "auto" }}>
                    {
                        (userType === "admin" && props.isCoAuthor === true) || props.isReadOnly === true ?
                            <div className={classes.container2}>
                                <img src={profilePicture === undefined || profilePicture.trim() === "" ? rl_icon : profilePicture} className={classes.avatar} alt="Profile Picture" />
                            </div>
                            : null
                    }
                </Col>

                <Col md={1} style={{ color: 'gray', textAlign: 'center', padding: "0px" }}>
                    {(cvLink !== undefined && cvLink.startsWith("http")) ?
                        <LightTooltip title="Click to download your CV." placement="bottom">
                            <a href={cvLink} target="_blank" type="application/pdf" rel="noopener noreferrer">
                                <DescriptionIcon style={{ width: '1.5em', height: '1.5em', marginTop: '2px', color: 'gray' }} />
                            </a>
                        </LightTooltip>
                        :
                        null
                    }
                </Col>

                <Col md={1} style={{ color: 'gray', textAlign: 'center' , padding: "0px"}}>
                    {(cvLink !== undefined && cvLink.startsWith("http")) ?
                        <LightTooltip title="Highlight CV" placement="bottom">
                            <IconButton style={{color: "#BBB218", padding:"0px", marginTop:"-3px"}} onClick = {() => markJournals(cvLink)} >
                                <DescriptionIcon fontSize="large" />
                            </IconButton>
                        </LightTooltip>
                        :
                        null
                    }
                </Col>

                {(highlightedCVLink !== undefined && highlightedCVLink.length > 0) ?
                <Col md={1} style={{ color: 'gray', textAlign: 'center', padding: "0px" }}>
                        <LightTooltip title="Download Highlighted CV" placement="bottom">
                            <IconButton style={{color: "#BBB218", padding:"0px", marginTop:"-3px"}} onClick = {() => downloadHighlightedCV(cvLink)} >
                                <GetAppIcon fontSize="large" />
                            </IconButton>
                        </LightTooltip>
                        </Col>
                        :
                        null
                    }


                <Col sm={1} md={1}>
                    {props.data.verified ? null :
                        <span className={classes.cursor}>
                            {profileUpdateState === true ?
                                <LightTooltip title="Click to edit Profile." placement="bottom">
                                    <EditIcon style={{ width: '1em', height: '1em', color: editColor, marginTop: '10px', marginLeft: "-5px" }} onClick={setPersonalInformationModal} ></EditIcon>
                                </LightTooltip>
                                : null}
                        </span>
                    }
                </Col>
            </Row>

            <AddPersonalInformationModal personalInfo={personalInfo} show={modalShow} onSave={editPersonalInfo} onHide={() => setModalShow(false)} isCoAuthor={props.isCoAuthor} coAuthorId={props.data._id} />
        </Container>
        </MuiThemeProvider>
    );
}

export default PersonalInformation;