import React, { useState, useEffect, Component } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import * as classes from './Papers.module.css';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../../constants/Theme';
import Tooltip from '@material-ui/core/Tooltip';
import * as profileActions from '../../../../../store/actions/profile';
import { withStyles } from '@material-ui/core/styles';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import SingleSelectAutoComplete from '../../../../InputComponents/SingleSelectAutoComplete';
import { BACKEND_URL } from '../../../../../utils';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Slider, Input } from '@material-ui/core';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { MuiThemeProvider } from 'material-ui';
import Loading from '../../../../../assets/Images/loading.gif';
import { makeStyles } from "@material-ui/core";
import TextInput from '../../../../InputComponents/TextInput';
import NoteModal from '../../../../NoteModal/NoteModal';
import Select from 'react-select';



const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center"
    },
    border: {
        borderBottom: "2px solid lightgray",
        width: "100%"
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        fontWeight: 500,
        fontSize: 22,
        color: "lightgray"
    }
}));

const AddPaperModal = props => {
    
    const theme = Theme();
    const dispatch = useDispatch();
    const history = useHistory();
    const minYear = 1940;
    const maxYear = new Date().getFullYear();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const [yearRange, setYearRange] = React.useState([maxYear - 5, maxYear]);
    const muiTheme = getMuiTheme({
        slider: {
            trackColor: "yellow",
            selectionColor: "red"
        }
    });

    const [curTextJournal, setcurTextJournal] = React.useState("")
    const [curJournalID, setcurJournalID] = React.useState("")
    const [curTextPaper, setcurTextPaper] = React.useState("")
    const [curPaperID, setcurPaperID] = React.useState("")
    const [isValidForm, setIsValidForm] = useState(false);
    const [journaloptions, setjournalOptions] = React.useState([]);
    const [paperoptions, setpaperOptions] = React.useState([]);
    const [journalYear, setJournalYear] = React.useState("")
    const [isPaperDisabled, setisPaperDisabled] = React.useState(true);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [addedPapers, setAddedPapers] = React.useState([]);
    const [curDOI, setcurDOI] = React.useState("")
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [Note, setNote] = React.useState("We will get back to you shortly");
    const [data, setData] = React.useState([]);

    const hideNoteModal = () => {
        setNoteModalShow(false);
        if (curPaperID !== "")
            addpaper();
    };

    const handleYearSliderChange = (event, newValue) => {
        if (yearRange !== newValue) {
            setYearRange(newValue);
            setShowSpinner(true);
        }
    };

    const handleYearInputChange = (event,) => {
        let newYearRange = event.target.id === "from" ? [Number(event.target.value), yearRange[1]] : [yearRange[0], Number(event.target.value)];
        setYearRange(newYearRange);
        setShowSpinner(true);
    };

    const handleBlur = () => {
        if (yearRange[0] < minYear) {
            setYearRange([minYear, yearRange[1]]);
        } else if (yearRange[1] > maxYear) {
            setYearRange([yearRange[0], maxYear]);
        }
    };

    const onJournalInputChangeHandler = (event, newInputValue) => {
        setcurTextJournal(newInputValue)
        if (!newInputValue || newInputValue.trim() === "") {
            return;
        }
        const url = BACKEND_URL + 'auto/journal/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const journals = await response.json();
            var p = Object.keys(journals).map((key) => journals[key]);
            setjournalOptions(p[0]);
        })();
    };

    const onChangeYear = (event) => {
        setJournalYear(event.target.value);
        setShowSpinner(true);
    };

    const onCheckClick = (event) => {
        setcurDOI(event.target.value);
        checkDOI();
    }

    const onPaperChange = (event, value) => {
        if (value && !(Object.keys(value).length === 0 && value.constructor === Object)) {
            setcurTextPaper(value.title)
            setcurPaperID(value.id)
        }
    };
    const onNewPaperChange = (event, value) => {
        if (value && !(Object.keys(value).length === 0 && value.constructor === Object)) {
            setcurTextPaper(value.publication_year + ': ' + value.title + ' - ' + value.journal_name);
            setcurPaperID(value.id);
            setcurTextJournal(value.journal_name);
            setcurJournalID(value.journal_id);

        }
    };

    const onJournalChange = (event, value) => {
        if (value && !(Object.keys(value).length === 0 && value.constructor === Object)) {
            setcurTextJournal(value.name);
            setcurJournalID(value.id);
            setShowSpinner(true);
        }
    };

    const getOptionLabelJournal = (option) => {

        if (!option || !option.name) {
            return "";
        }
        return option.name

    };

    const getOptionLabelPaper = (option) => {

        if (!option || !option.title) {
            return "";
        }
        return option.title

    };
    const getloadPaperlabel = (option) => {

        if (!option || !option.title) {
            return "";
        }
        return option.publication_year + ': ' + option.title + ' - ' + option.journal_name

    };



    const addMorePaper = () => {
        props.onAddMore();
    };

    const handleDOIchange = (event) => {
        setcurDOI(event.target.value);
    };

    const checkDOI = () => {
        const token = localStorage.getItem("rl_token");

        const url = BACKEND_URL + 'paper_search_by_doi';

        (async () => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        "doi": curDOI
                    }
                )
            });
            if (response.status !== 200) {
                alert('Failed to check for valid DOI.')
            } else {
                let data = await response.json();

                if (Object.keys(data).length > 0) {
                    let paper_id = data['id']
                    let paper_title = data['title']
                    let temp_Note = `Adding the paper titled: ${paper_title}`
                    setNote(temp_Note)
                    setcurPaperID(paper_id);
                    props.onHide();
                    setNoteModalShow(true);
                }
                else {
                    let temp_Note = 'Please enter a correct DOI';
                    setNote(temp_Note)
                    setNoteModalShow(true);
                }

            }
        })();

    };
    
    

    const addpaper = async () => {
        const editAuthor = props.isCoAuthor === true ? 'editauthor/' : '';
        const id = props.isCoAuthor === true ? `/${props.coAuthorId}` : '';
        const token = localStorage.getItem("rl_token");
        const url = BACKEND_URL + editAuthor + 'papers' + id;
        await (async () => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        "paper_id": curPaperID
                    }
                )
            });
            if (response.status !== 200) {
                alert('Something went wrong while adding paper.')
            }
            setcurTextJournal("");
            setcurJournalID("");
            setcurPaperID("");
            setcurTextPaper("");
            setpaperOptions([]);
            setjournalOptions([]);
        })();
        await (async () => {
            try {
                const token = localStorage.getItem("rl_token");
                if (props.isCoAuthor === true) {
                    // Added paper for a different author from profileEdit page
                    history.push({
                        pathname: '/dashboard/profileEdit',
                        state: { coauthorid: props.coAuthorId }
                    })
                } else if (props.isCoAuthor === false) {
                    // Added paper to own profile from profileEdit page
                    history.push({
                        pathname: '/dashboard/profileEdit'
                    })
                } else {
                    // Added paper from profileEdit page
                    await dispatch(profileActions.get_author_profile(token, "id"));
                    history.push({
                        pathname: '/dashboard/profileedit'
                    })
                }
            }
            catch (error) {
                alert("Failed to load profile! Try again in few seconds!");
            }
        })();
    };

    const errorMessage = (isValidForm) => {
        if (isValidForm) {
            return "";
        } else {
            var message = "Incomplete form: ";
            if (curTextJournal.trim() === "") {
                message += "\n Select Journal";
            }
            if (curTextPaper.trim() === "") {
                message += "\n Select Paper";
            }
            if (journalYear.trim() === "") {
                message += "\n Select a year";
            }
            if (message === "Incomplete form: ") {
                setIsValidForm(true)
                return "";
            }
        }
        return message;
    };
    const loadPaper = async()=> {
        const author_id = props.coAuthorId
        const url = BACKEND_URL + 'getmissingpapers?id='+author_id;
        (async () => {
            const response = await fetch(url);
            if (response.status !== 200) {
                alert('Failed to check for valid DOI.')
            } else {
                let actualData = await response.json();
                setData(actualData);
            }
    })();
    };

    const fetchPapers = async () => {
        if (localStorage.getItem("rl_type") === "admin") {
            if (curTextJournal !== undefined && curTextJournal !== null && curTextJournal.trim() !== "") {
                const url = BACKEND_URL + 'manual_mapping?issn=' + curJournalID + '&startyear=' + yearRange[0] + '&endyear=' + yearRange[1];
                setisPaperDisabled(false);
                (async () => {
                    const token = localStorage.getItem("rl_token");
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            Authorization: 'Bearer ' + token,
                        }
                    });
                    const newpapers = await response.json();
                    console.log(newpapers)
                    var p = Object.keys(newpapers).map((key) => newpapers[key]);
                    setpaperOptions(p[0].filter((paper) => {
                        let found = false;
                        for (let i = 0; i < addedPapers.length; i++) {
                            if (addedPapers[i]._id === paper.id) {
                                found = true;
                                break;
                            }
                        }
                        return !found;
                    }));
                })();
            } else {
                setisPaperDisabled(true)
            }
        }
        else {
            if (curTextJournal !== undefined && curTextJournal !== null && curTextJournal.trim() !== "" && journalYear !== undefined && journalYear !== null && journalYear.trim() !== "") {
                const url = BACKEND_URL + 'papers?journal_id=' + curJournalID + '&year=' + journalYear;
                setisPaperDisabled(false);
                (async () => {
                    const token = localStorage.getItem("rl_token");
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            Authorization: 'Bearer ' + token,
                        }
                    });
                    const newpapers = await response.json();
                    console.log(newpapers)
                    var p = Object.keys(newpapers).map((key) => newpapers[key]);
                    setpaperOptions(p[0].filter((paper) => {
                        let found = false;
                        for (let i = 0; i < addedPapers.length; i++) {
                            if (addedPapers[i]._id === paper.id) {
                                found = true;
                                break;
                            }
                        }
                        return !found;
                    }));
                })();
            } else {
                setisPaperDisabled(true)
            }
        }
    };
    

    useEffect(() => {
        setShowSpinner(false);
    }, [paperoptions]);

    useEffect(() => {
        fetchPapers();
    }, [curJournalID, journalYear, yearRange]);
   

    useEffect(() => {
        setcurTextJournal("")
        setcurJournalID("")
        setcurPaperID("")
        setcurTextPaper("")
        setpaperOptions([])
        setjournalOptions([])
        setData([])

        setIsValidForm(false)
        

        if (localStorage.getItem("rl_type") === "admin") {
            setJournalYear("1234")
        } else {
            setJournalYear("")
        }
        
        setAddedPapers(props.curPapers);   
    }, [props.curPaper, props.curPapers]);
    const line_classes = useStyles();
    

    return (
        <ThemeProvider theme={theme}>
            <Modal
                {...props}
                dialogClassName={classes.modalAddPapers}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter232">
                        <span>Add Your Paper</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container >
                        <Row md={12} className={classes.row}>
                            <Col md={9}>
                                <TextInput
                                    size="small"
                                    id="DOI"
                                    label="DOI"
                                    variant="filled"
                                    fullWidth={true}
                                    onChange={handleDOIchange}
                                />
                            </Col>
                            <Col md={2}>
                                <LightTooltip title="Check if valid DOI" placement="top">
                                    <Button variant="contained" onClick={onCheckClick} style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }}>Check</Button>
                                </LightTooltip>
                            </Col>
                        </Row>
                        
                        <div className={line_classes.container}>
                            <div className={line_classes.border} />
                            <span className={line_classes.content}>OR</span>
                            <div className={line_classes.border} />
                        </div>

                        <Row md={12} className={classes.row}>
                            <Col md={9}>
                                <SingleSelectAutoComplete
                                    onInputChange={onJournalInputChangeHandler}
                                    options={journaloptions}
                                    curText={curTextJournal}
                                    onChange={onJournalChange}
                                    getOptionLabel={getOptionLabelJournal}
                                    label={"Enter Journal Name"}>
                                </SingleSelectAutoComplete>
                            </Col>
                            <Col md={2}>
                                {
                                    localStorage.getItem("rl_type") === "admin"
                                        ? null :
                                        <TextInputControlled label="Enter Year"
                                            value={journalYear}
                                            onChange={onChangeYear}></TextInputControlled>
                                }
                            </Col>
                        </Row>
                        {
                            localStorage.getItem("rl_type") === "admin" ?
                                <Row md={12} className={classes.row}>
                                    <Col md={3}>
                                        <Input size="small" className="yearRangeInput" value={yearRange[0]} id="from" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Col>
                                    <Col md={6}>
                                        <MuiThemeProvider muiTheme={muiTheme}>
                                            <Slider classes={{ rail: classes.rail, track: classes.track, thumb: classes.thumb, markLabel: classes.markLabel }} value={yearRange} onChange={handleYearSliderChange} valueLabelDisplay="auto" min={minYear} max={maxYear} />
                                        </MuiThemeProvider>
                                    </Col>
                                    <Col md={3}>
                                        <Input className="yearRangeInput" value={yearRange[1]} id="to" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Col>
                                </Row>
                                : null
                        }
                        
                            
                        {
                            showSpinner &&
                            <div style={{ textAlign: "center" }}>
                                <img src={Loading} alt="Loading..." width="80px" height="80px" />
                            </div>
                        }
                        {
                            !isPaperDisabled && !showSpinner &&
                            <Row md={12} className={classes.row}>
                                <Col md={9}>
                                    <SingleSelectAutoComplete
                                        disabled={isPaperDisabled}
                                        onChange={onPaperChange}
                                        options={paperoptions}
                                        curText={curTextPaper}
                                        getOptionLabel={getOptionLabelPaper}
                                        label={"Select Paper"}></SingleSelectAutoComplete>
                                </Col>
                                <Col md={2}>
                                    <LightTooltip title="Can't find your paper? Click to request addition." placement="top">
                                        <Button variant="contained" onClick={addMorePaper} style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }}>More</Button>
                                    </LightTooltip>
                                </Col>
                            </Row>
                        }
                        <div className={line_classes.container}>
                            <div className={line_classes.border} />
                            <span className={line_classes.content}>OR</span>
                            <div className={line_classes.border} />
                        </div>
                        <Row md={12} className={classes.row}>
                            <Col md={10}>
                                <h5>Suggested Papers</h5>     
                            </Col>
                            <Col md={2}>
                                <LightTooltip title="Check if valid DOI" placement="top">
                                    <Button variant="contained" onClick={loadPaper} style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }}>Check</Button>
                                </LightTooltip>
                            </Col>
                        </Row>
                        <Row md={12} className={classes.row}>  
                            <Col md={12}>
                                <SingleSelectAutoComplete 
                                    options = {data}
                                    getOptionLabel={getloadPaperlabel}
                                    onChange={onNewPaperChange}
                                    onInputChange = {loadPaper}
                                    curText={curTextPaper}
                                    label={"Select Paper"}
                                ></SingleSelectAutoComplete>        
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <LightTooltip title={errorMessage(isValidForm)} placement="bottom">
                        <span>
                            <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} disabled={!isValidForm} onClick={() => { props.onHide(); addpaper(); }}>Add</Button>
                        </span>
                    </LightTooltip>
                </Modal.Footer>
            </Modal>
            <NoteModal show={noteModalShow} onHide={hideNoteModal} note={Note} />
        </ThemeProvider>
    );

}

export default AddPaperModal;
