import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FacultyCard from './FacultyCard';
import classes2 from '../FacultyProductivity.module.css'
import { Divider, Accordion, AccordionSummary , AccordionDetails, Typography, Grid} from '@material-ui/core';
const ViewByDepartment =    (props) =>{
    let data = props.deptList;
    const [departmentList, setDepartmentList] = React.useState([]);
    data.sort((a,b)=> a.name > b.name ? 1 : -1)
    const facultyCount = props.facultyCount;
    const ExpansionPanelDetailsStyle = {
        maxHeight:"497px",
        overflowY:"auto"
    }
    React.useEffect(()=>{
    if(facultyCount === "3"){
        let temp = [];
        for(let i=0; i < data.length; i++){
                // temp[i].faculty = facultyCount==="All"? data[i].faculty: data[i].faculty.slice(0, parseInt(facultyCount));
                temp.push({
                    faculty: data[i].faculty.slice(0,3),
                    name: data[i].name
                })
            }
        setDepartmentList(temp)
    }else if(facultyCount === "9"){
        let temp = [];
        for(let i=0; i < data.length; i++){
                // temp[i].faculty = facultyCount==="All"? data[i].faculty: data[i].faculty.slice(0, parseInt(facultyCount));
                temp.push({
                    faculty: data[i].faculty.slice(0,9),
                    name: data[i].name
                })
            }
        setDepartmentList(temp)
    }else {
        setDepartmentList(data)
    }
    },[facultyCount]);
    
    return (
        <div>
            {departmentList.map(department => (
                <Accordion key={department.name}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} >
                        <Typography classes={{root:classes2.standardSize,body1:classes2.standardSize,body2:classes2.standardSize}}>{department.name}</Typography>
                    </AccordionSummary >
                    <Divider/>
                    <AccordionDetails style={ExpansionPanelDetailsStyle}>
                        <Grid container spacing={3} style={{padding:20}}>
                        {
                            department.faculty.map((faculty,i) => (
                                <Grid item xs={4} key={faculty.name} style={{ padding: 20 }}>
                                    <FacultyCard data={faculty} rank={i + 1} allJournals={props.allJournals} />
                                </Grid>
                            ))
                        }
                        </Grid>    
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>      
    )
}

export default ViewByDepartment;