import { Paper, Checkbox, FormGroup, FormControlLabel, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import Settings from './Settings';
import Loading from '../../../../../assets/Images/loading.gif';

const CustomCheckbox = withStyles(theme => ({
    root: {
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingRight: '8px',
        paddingLeft: '4px',
        '&$checked': {
            color: '#bbb218',
        },
    },
    checked: {},
}))(Checkbox);

const useStyles = makeStyles(theme => ({
    scroll: {
        maxHeight: "200px",
        overflowY: "auto"
    },
    padding: {
        paddingBottom: "0px"
    }
}))

const facultyData = [
    "Assistant",
    "Associate",
    "Full",
]
const facultyTypeToMarkerDict = { "Assistant": 1, "Associate": 2, "Full": 3, "Non Tenured": 6 }

const FacultyByMarketShare = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [selectedFacultyOptions, setFacultyOptions] = React.useState(["Assistant"]);
    const [department, setDepartment] = React.useState("");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const facultyMarketShares = useSelector(state => state.dashboards.facultyMarketShares);

    React.useEffect(() => {
        getMarketShare([1], null);
    }, []);

    const getMarketShare = async (markers, dept) => {
        setShowSpinner(true);
        try {
            const token = localStorage.getItem('rl_token');
            await dispatch(dashboardActions.getFacultyByMarketShare(token, markers, dept));
        }
        catch (err) {
            console.log("Cannot get market share", err);
        }
        setShowSpinner(false);
    }

    const handleFacultyPositionChange = (event) => {
        let updatedFacultyOptions = [...selectedFacultyOptions];
        if (updatedFacultyOptions.indexOf(event.target.value) === -1) {
            updatedFacultyOptions.push(event.target.value);
        } else {
            var index = updatedFacultyOptions.indexOf(event.target.value);
            updatedFacultyOptions.splice(index, 1);
        }
        let markers = [];
        for (let facultyType of updatedFacultyOptions) {
            markers.push(facultyTypeToMarkerDict[facultyType]);
        }
        getMarketShare(markers, null);
        setFacultyOptions(updatedFacultyOptions);
    };

    const handleSchoolDeptSwitchChange = async (department) => {
        if (department === null) {
            setDepartment(null);
            let markers = [];
            for (let facultyType of selectedFacultyOptions) {
                markers.push(facultyTypeToMarkerDict[facultyType]);
            }
            getMarketShare(markers, null);
        }
        else {
            setDepartment(department);
            let markers = [];
            for (let facultyType of selectedFacultyOptions) {
                markers.push(facultyTypeToMarkerDict[facultyType]);
            }
            getMarketShare(markers, department);
        }
    }

    return (
        <Paper style={{ height: "360px", padding: "12px" }}>
            <div style={{ fontSize: "20px" }}>Faculty By Share{department === null || department === "" ? "" : ` - ${department}`}
                <span style={{ float: "right" }}>
                    <Settings handleSchoolDeptSwitchChange={handleSchoolDeptSwitchChange} department={department} />
                </span>
            </div>
            <FormGroup row>
                {
                    facultyData.map(facultyPosition => (
                        <FormControlLabel
                            control={<CustomCheckbox checked={selectedFacultyOptions.indexOf(facultyPosition) > -1} onChange={handleFacultyPositionChange} name={facultyPosition} value={facultyPosition} />}
                            label={facultyPosition} className={classes.padding}
                        />
                    ))
                }
            </FormGroup>
            {
                showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <div className={classes.scroll}>
                        <TableContainer style={{ marginTop: "12px" }}>
                            <Table size="medium">
                                <TableBody>
                                    {facultyMarketShares.map((row) => (
                                        <TableRow key={row.name} >
                                            <TableCell align={''} padding={'none'} style={{ paddingBottom: "12px" }}>{row.name}</TableCell>
                                            <TableCell align={''} padding={'none'} style={{ paddingBottom: "12px" }}>: {row.market_share}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
            }
        </Paper>
    )
}

export default FacultyByMarketShare;