import React from 'react';
import { TextField, FormHelperText, FormControl } from '@material-ui/core';
import './InputComponents.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search } from '@material-ui/icons';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const StyledAutoComplete = withStyles({
    inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
            paddingRight: "8px !important",
            paddingTop: "11px !important",
            borderRadius: "0px !important",
            backgroundColor: "#f2f2f2 !important"
        }
    },

})(Autocomplete);
const StyledFormHelperText = withStyles({
    contained: {
        marginLeft: "12px",
        marginTop: "0px"
    }
})(FormHelperText);
const theme2 = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            filled: {
                transform: "translate(12px, 16px) scale(1)",
            },
            shrink: {
                transform: "translate(12px, 3px) scale(0.75) !important",
            }
        },
        MuiFilledInput: {
            root: {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                height: 40,
                "&:hover": {
                    backgroundColor: "#e8e8e8",
                    borderBottom: 0
                }
            },
            underline: {
                '&:before': {
                    borderBottom: 0
                },
                '&:after': {
                    borderBottom: 0
                },
            }
        }
    }
});
const SingleSelectAutoComplete = props => {
    const {
        error,
        options,
        onInputChange,
        inputValue,
        label,
        helperText,
        curText,
        getOptionSelected,
        getOptionLabel,
        disabled,
        onBlur,
        onChange,
        filterOptions,
        renderOption,
        freesolo
    } = props;
    return (
        <ThemeProvider theme={theme2}>
        <FormControl fullWidth size="small" variant="filled" error={error} style={{ marginBottom: error ? "-20px" : "" }}>
            <StyledAutoComplete required fullWidth size="small" options={options}
                getOptionSelected={getOptionSelected}
                onInputChange={onInputChange} inputValue={inputValue} style={{paddingRight:0}}
                getOptionLabel={getOptionLabel}
                disabled={disabled}
                onBlur={onBlur}
                onChange={onChange}
                filterOptions={filterOptions}
                renderOption={renderOption}
                freesolo={freesolo}
                renderInput={(params) => (
                    <TextField
                        required
                        fullWidth
                        error={error}
                        value={curText}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Search style={{ marginBottom: "auto" }} className="blackColor" />
                            ),
                        }}
                        label={label} variant="filled" />
                )}
            />
            {error && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
        </FormControl>
        </ThemeProvider>
    );
}

export default SingleSelectAutoComplete;
