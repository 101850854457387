import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const authorSearchAction = (data) => {
    return {
        type: actionTypes.AUTHOR_SEARCH,
        searchResult: data
    }
}

export const paperSearchAction = (data) => {
    return {
        type: actionTypes.PAPER_SEARCH,
        searchResult: data
    }
}

export const authorSearch = (token, firstName, lastName) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `auth_search?first_name=${firstName}&last_name=${lastName}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for profile.');
        }
        const authorData = await response.json();
        for (let i in authorData.authors) {
            let temp = authorData.authors[i];
            if (temp.first_name.toLowerCase() + ' ' + temp.last_name.toLowerCase() === firstName + ' ' + lastName && i !== 0) {
                authorData.authors[i] = authorData.authors[0]
                authorData.authors[0] = temp
                break;
            }
        }
        dispatch(authorSearchAction(authorData));
    };
}

export const paperSearch = (token, searchText) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `paper_search/${searchText}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while searching for ' + searchText + ' with error: ' + errorResData);
        }
        const paperSearchResult = await response.json();
        dispatch(paperSearchAction(paperSearchResult));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_EXPLORE });
    }
}