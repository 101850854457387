import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    facultyProgress: [],
    departments: [],
    departmentToJournalsDict: {},
    defaultMappingsDict: {},
    journalIdToNameDict: {},
    yourSchoolData: [],
    otherSchoolData: [],
    yourSchoolIndex: -1,
    otherSchoolIndex: -1
}

const facultyProgressReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_DEPT_AND_JOURNALS:
            let defaultMappingObj = action.departments.default_mapping;
            let deptObj = action.departments.departments;
            let journal_id_name_map = action.departments.journals
            let status = action.departments.status

            let departmentToJournalsDict = {};
            let depts = [];
            let defaultMappingsDict = {};

            if (status === "Completed") {
                for (let index in deptObj) {
                    const dept = deptObj[index]
                    const dept_name = dept['name'];
                    depts.push(dept_name);

                    departmentToJournalsDict[dept_name] = {
                        "A": dept["A"],
                        "A-": dept["A-"],
                        "B": dept["B"]
                    };

                    defaultMappingsDict[dept_name] = dept['default_mapping'];
                }
            } else {
                for (let index in defaultMappingObj) {
                    const dept = defaultMappingObj[index]
                    const dept_name = dept['name'];
                    depts.push(dept_name);

                    departmentToJournalsDict[dept_name] = {
                        "A": dept["A"],
                        "A-": dept["A-"],
                        "B": dept["B"]
                    };
                    defaultMappingsDict[dept_name] = [dept['name']];
                }
            }

            return updateObject(state, {
                departments: depts,
                departmentToJournalsDict: departmentToJournalsDict,
                journalIdToNameDict: journal_id_name_map,
                defaultMappingsDict: defaultMappingsDict
            });
        case actionTypes.FACULTY_PROGRESS:

            return updateObject(state, {
                yourSchoolData: action.facultyProgressData.rank_within_university,
                otherSchoolData: action.facultyProgressData.rank_all_universities,
                yourSchoolIndex: action.facultyProgressData.faculty_rank_within_university,
                otherSchoolIndex: action.facultyProgressData.faculty_rank_all_university,
            });
        case actionTypes.CLEAR_FACULTY_PRODUCTIVITY:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default facultyProgressReducer;