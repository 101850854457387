import React ,{useState,useEffect} from 'react';
import { TextField, FormHelperText, FormControl } from '@material-ui/core';
import './InputComponents.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search } from '@material-ui/icons';
const SinglePreFilledAutoComplete = props => {
    const [value,setValue] = useState("");
    const [open, setOpen] = React.useState(false);
    const [loading,setLoading] =React.useState(false)//= open && options.length === 0;
    
    useEffect(() => {
        if(!props.value || props.value===""){
            setValue("")
        }else{
            setValue(props.value)
        }
    },[props.value]);

    const {
        error,
        onInputChange,
        label,
        helperText,
        disabled
    } = props;
    return (
        <FormControl fullWidth size="small" variant="filled" error={error}>
            <Autocomplete required fullWidth size="small" 
                   onInputChange={onInputChange}
                   open={open}
                   onOpen={() => {
                    setOpen(true);
                    }}
                    onClose={() => {
                        setLoading(false)
                        setOpen(false);
                    }}
                    defaultValue={{name:props.value}}
                    inputValue={value}
                    options={props.options}
                    loading={loading}
                    disabled={disabled}
                    disableClearable
                    freeSolo
                    renderInput={(params) => (
                    <TextField
                        required
                        fullWidth
                        error={error}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Search style={{ marginBottom: "auto" }} className="blackColor" />
                            ),
                        }}
                        label={label} variant="filled" />
                )}
            />
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

export default SinglePreFilledAutoComplete;
