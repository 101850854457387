import React,{useState, useEffect } from 'react';
import classes2 from './redflags.module.css';
import { Button, Container, Row, Col } from 'react-bootstrap';
import {BACKEND_URL} from '../../../../utils';
import Loading from '../../../../assets/Images/loading.gif';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SingleSelect from '../../../InputComponents/SingleSelect';
import TextField from '@material-ui/core/TextField';
import ReactFusioncharts from "react-fusioncharts";
const PapersPublishedPerYearInAJournal = props =>{

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [searched,setSearched]=useState(false);
    const [journalOptions,setJournalOptions]=useState([]);
    const [journalId, setJournalId] = useState("");
    const [journalName, setJournalName] = useState("");
    const [journalMap, setJournalMap] = useState({});
    const [category,setCategory]=useState([]);
    const [yearData,setYearData]=useState([]);
    const dataSource = {
        "chart": {
          "caption": "Journal Year Distribution",
          "xAxisname": "Years",
          "yAxisName": "Papers",
          "theme": "fusion",
          "usePlotGradientColor": 0,
          "showPlotBorder": false,
          "showAlternateHGridColor": false,
          "legendItemFontColor": "000000",
          "drawCustomLegendIcon": true,
          "legendIconBorderThickness": 0,
          "xAxisNameFontSize": 15,
          "yAxisNameFontSize": 15,
          "captionFontSize": 20,
          "captionFontColor": "#000000",
          "captionFontBold": false,
          "xAxisNameFontBold": false,
          "yAxisNameFontBold": false
        },
        "categories": [
          {
            "category": category
          }
        ],
        "dataset": yearData
      };

    const getJournalOptions = async() => {
        const url = BACKEND_URL+ `red_flag/papers_published_per_year_in_journal?list=true&id=none`;
        const response = await fetch(url,{
          method:'GET'
        });

        var responseData = await response.json();
        let journalMap = {};
        let journalIF = [];
        let journalNames = []

        for (const key in responseData["response"]){
          
          journalMap[key] = responseData["response"][key]["issn"];
          journalIF.push({"journal_name": key,  "impact_factor": responseData["response"][key]["impact_factor"]});
        }
        
        journalIF.sort((a,b) => b.impact_factor - a.impact_factor);
        journalIF.map((value, i) => {
          journalNames.push(value["journal_name"]);
        });
        
        setJournalMap(journalMap);
        setJournalOptions(journalNames);
    };

    const getResponse =()=>{
      const url = BACKEND_URL +`red_flag/papers_published_per_year_in_journal?list=false&id=${journalId}`;
      setSearched(true);
      setShowSpinner(true);
        (async () => {
                const response = await fetch(url,{
                  method:'GET'
                });
                var responseData = await response.json();
                responseData=responseData.response;    
                
                let category1 = []
                let yearsData = []  
                let years = []
                let issue = {}

                Object.keys(responseData).map((key)=>{
                  var yearCount=responseData[key]; // Array
                  yearCount.map((item) => {
                    years.push(item["year"].toString())

                    if (item["issue"] in issue) {
                      issue[item["issue"]].push( {"value": item["count"]<0 ? 0 : item["count"]})
                    } else {
                      issue[item["issue"]] = [{"value": item["count"]<0 ? 0 : item["count"]}]
                    }
                  });

                  let unique_years = new Set(years);
        
                  unique_years.forEach((value) => {
                    category1.push({"label":value.toString()})
                  });
        
                  for (const key in issue) {
                    yearsData.push({ "seriesname": key.toString(), "data": issue[key]})  
                  }  
               });

                setShowSpinner(false); 
                setCategory(category1); 
                setYearData(yearsData);
              })();   
    }

    const handleJournalChange = (event, newInputValue) => {
      setJournalName(newInputValue ? newInputValue : '');
      if (newInputValue && newInputValue !== '' && journalOptions.includes(newInputValue)) {
        setJournalId(journalMap[newInputValue]);
      }
    }

    const handleClick= ()=>{
        getResponse();
    }

    useEffect (() => {
      getJournalOptions();
    }, []);

    return (
        <div>
          <div className={classes2.formCard}>
          <Container >
                <Row md={12} className={classes2.row}>
                    <Col md={1}>
                    </Col>
                    <Col md={7}>
                      <SingleSelect 
                        options={journalOptions} 
                        onInputChange={handleJournalChange}
                        inputValue={journalName}
                        label="Journal Name" 
                        helperText="Select a Journal Name" 
                      />
                    </Col>
                    <Col md={3}>
                    <Button variant="contained" className="searchButton" color="primary" onClick={(e)=>handleClick()}>Select</Button>
                    </Col>
                    <Col md={1}>
                    </Col>
                </Row>
                </Container>
          </div>
          
          {
              searched===true && showSpinner===true &&
              <div style={{ textAlign: "center" }}>
                <img src={Loading} alt="Loading..." width="80px" height="80px" />
              </div>
          }
          {
              searched===true && showSpinner===false &&
             <div className={classes2.formCard} style={{ height: "500px" }}>
             <ReactFusioncharts
               type="stackedcolumn2d"
               width="100%"
               height="90%"
               dataFormat="JSON"
               dataSource={dataSource}
             />
           </div>
          }
        </div>
    )
}

export default PapersPublishedPerYearInAJournal;