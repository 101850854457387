import React, { useEffect } from 'react';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import {
  Grid, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Divider, Button, Switch, Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactApexChart from "react-apexcharts";
import SingleSelect from '../../../InputComponents/SingleSelect';
import MultipleSelect from '../../../InputComponents/MultipleSelect';
import './PerceptualMap.css';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../constants/Theme';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../assets/Images/loading.gif';
import * as perceptualMapActions from '../../../../store/actions/perceptualMap';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridPaddingWithoutRatio: {
    padding: "12px 30px 20px 15px !important"
  },
  gridPaddingWithRatio: {
    paddingBottom: "10px !important",
    width: "100% !important"
  },
  featureHeading: {
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "normal",
    fontSize: "36px !important",
    lineHeight: "54px !important",
    color: "#2B2F4B !important",
  },
}));
const facultyTypeOptions = ["Assistant Professor", "Associate Professor", "Full Professor"]
charts(FusionCharts);
const schoolsToCompareDict = {
  "Top Schools Plus": "top20", "Top Schools": "top10", "Peer Schools": "range5", "Expanded Peer Schools": "range10"
}
const xAxisOptions = ["Papers", "Papers per Faculty", "Papers in A Journals", "Papers in A- Journals"]
const yAxisOptions = ["Citations", "Citations per Faculty", "Citations in A Journals", "Citations in A- Journals"]
const schoolsToCompareOptions = ["Top Schools Plus", "Top Schools", "Peer Schools", "Expanded Peer Schools"]
const PerceptualMap = props => {
  const classes = useStyles();
  const theme = Theme();
  const dispatch = useDispatch();
  const [xAxisInfo, setXAxisInfo] = React.useState("Papers");
  const [yAxisInfo, setYAxisInfo] = React.useState("Citations");
  const [facultyTypeInfo, setfacultyTypeInfo] = React.useState(" * faculty type *");
  const [selectedXAxis, setSelectedXAxis] = React.useState();
  const [selectedYAxis, setSelectedYAxis] = React.useState();
  const [selectedFacultyType, setSelectedFacultyType] = React.useState([]);
  const [selectedSchoolsToCompare, setSelectedSchoolsToCompare] = React.useState("");
  const [accordionExpand, setAccordionExpand] = React.useState(true);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [facultyTypeErr, setFacultyTypeErr] = React.useState(false);
  const [xErr, setXErr] = React.useState(false);
  const [yErr, setYErr] = React.useState(false);
  const [schoolErr, setSchoolErr] = React.useState(false);
  const [invertAxes, setInvertAxes] = React.useState(false);
  const perceptualMapData = useSelector(state => state.perceptualMap.perceptualMapData);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuPropsStyles = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const [xmin, setxmin] = React.useState();
  const [xmax, setxmax] = React.useState();
  const [ymin, setymin] = React.useState();
  const [ymax, setymax] = React.useState();
  const univprofileState = useSelector(state => state.univProfile);


  useEffect(() => {
    if (perceptualMapData !== undefined && perceptualMapData.length > 0) {
      calculateMinMaxAxisValue();
    }
  }, [perceptualMapData]);

  const calculateMinMaxAxisValue = () => {

    const dataX = new Set();

    const dataY = new Set();

    perceptualMapData.map((univ) => {
      dataX.add(univ.data[0][0]);
      dataY.add(univ.data[0][1])
    })

    let minX = Math.min(...Array.from(dataX.values()));
    let maxX = Math.max(...Array.from(dataX.values()));
    let minY = Math.min(...Array.from(dataY.values()));
    let maxY = Math.max(...Array.from(dataY.values()));
    let xval = parseInt((maxX - minX) * 0.15)
    setxmax(maxX + xval + 1);
    setxmin(minX - xval - 1);

    let yval = parseInt((maxY - minY) * 0.15)
    setymax(maxY + yval + 1);
    setymin(minY - yval - 1);
    for (var i = 0; i < perceptualMapData.length; i++) {
      if (perceptualMapData[i]['value'] === univprofileState['name']) {
        perceptualMapData[i]['color'] = '#85276B'
      }
    }
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // search();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const bubbleChartOptions = {
    chart: {
      height: 350,
      type: 'bubble',
      toolbar: {
        show: false
      }

    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        let index = opts["seriesIndex"];
        return perceptualMapData.length > 0 ? perceptualMapData[index]["displayValue"] : ''
      }
    },
    fill: {
      opacity: 0.75
    },
    markers: {
      strokeWidth: 0
    },
    title: {
      text: 'University Comparison'
    },

    legend: {
      position: "right"
    },
    xaxis: {
      tickAmount: 7,
      type: 'category',
      title: {
        text: `${xAxisInfo}`,
        style: {
          fontSize: '16px',
          fontWeight: 400,
        }
      },
      min: xmin,
      max: xmax,
      labels: {
        formatter: (value) => { return value.toFixed(1) }
      },
      axisBorder: {
        show: true,
        color: '#555559',
        offsetX: 0,
        offsetY: 0
      },
    },
    yaxis: {
      tickAmount: 8,
      type: 'category',
      title: {
        text: `${yAxisInfo}`,
        style: {
          fontSize: '16px',
          fontWeight: 400,
        }
      },
      min: ymin,
      max: ymax,
      labels: {
        formatter: (value) => { return value.toFixed(1) }
      },
      axisBorder: {
        show: true,
        color: '#555559',
        offsetX: 0,
        offsetY: 0
      },
    },
    tooltip: {
      z:
      {
        show: false,
        enabled: false,
        formatter: function () {
          return
        },
        title: ""
      }
    }
  };


  const handleAccordionExpand = () => {
    setAccordionExpand(!accordionExpand);
  }
  const handleXAxisChange = (event, newInputValue) => {
    let xAxisValue = newInputValue ? newInputValue : '';
    setSelectedXAxis(xAxisValue)
    let xAxisInfo1 = "";
    xAxisInfo1 += " ( ";
    xAxisInfo1 += xAxisValue;
    xAxisInfo1 += " ) ";
    if (xAxisValue && xAxisValue.length !== 0) {
      setXErr(false);
    }
    setXAxisInfo(xAxisInfo1);
  }
  const handleYAxisChange = (event, newInputValue) => {
    let yAxisValue = newInputValue ? newInputValue : '';
    setSelectedYAxis(yAxisValue);
    let yAxisInfo1 = "";
    yAxisInfo1 += " ( ";
    yAxisInfo1 += yAxisValue;
    yAxisInfo1 += " ) ";
    if (yAxisValue && yAxisValue.length !== 0) {
      setYErr(false);
    }
    setYAxisInfo(yAxisInfo1);
  }
  const handleSchoolsToCompareChange = (event, newInputValue) => {
    setSelectedSchoolsToCompare(newInputValue ? newInputValue : '');
    if (newInputValue.length > 0) {
      setSchoolErr(false);
    }
  };
  const handleFacultyTypeChange = (event) => {
    setSelectedFacultyType(event.target.value);
    let facultyTypeInfo1 = " ";
    for (let val of event.target.value) {
      facultyTypeInfo1 += ` ${val}, `;
    }
    facultyTypeInfo1 = facultyTypeInfo1.slice(0, facultyTypeInfo1.length - 2);
    if (event.target.value.length > 0) {
      setFacultyTypeErr(false);
    }
    setfacultyTypeInfo(facultyTypeInfo1);
  }

  const getDataToSend = () => {
    const token = localStorage.getItem("rl_token");
    let school = "";
    school = schoolsToCompareDict[selectedSchoolsToCompare];
    return { token: token, titles: selectedFacultyType, x: selectedXAxis, y: selectedYAxis, school: school }
  }
  const handleInvertAxesChange = async () => {
    setInvertAxes(!invertAxes);
    let xAxisInfo1 = xAxisInfo;
    setXAxisInfo(yAxisInfo)
    setYAxisInfo(xAxisInfo1);
    await dispatch(perceptualMapActions.invertAxes(perceptualMapData));
    calculateMinMaxAxisValue();
  }
  const checkValidity = () => {
    let isValid = true;
    if (selectedFacultyType.length === 0) {
      setFacultyTypeErr(true);
      isValid = false;
    }
    if (selectedXAxis !== undefined && selectedXAxis.length === 0) {
      setXErr(true);
      isValid = false;
    }
    if (selectedYAxis !== undefined && selectedYAxis.length === 0) {
      setYErr(true);
      isValid = false;
    }
    if (selectedSchoolsToCompare.length === 0) {
      setSchoolErr(true);
      isValid = false;
    }
    return isValid
  }
  const search = async () => {
    if (checkValidity()) {
      setShowSpinner(true);
      setAccordionExpand(false);
      let params = getDataToSend();
      try {
        setInvertAxes(false);
        setXAxisInfo("( " + params.x + " )");
        setYAxisInfo("( " + params.y + " )");
        await dispatch(perceptualMapActions.getPerceptualMap(params.token, params.titles, params.x, params.y, params.school));
      }
      catch (err) {
        console.error(err);
        alert("Internal Server Error. Please try again after some time")
      }
      setShowSpinner(false);
    }
  }
  return (
    <ThemeProvider theme={theme} >
      <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
        <div className={classes.featureHeading}>
          Compare Schools
        </div>
        <Accordion square expanded={accordionExpand} onChange={handleAccordionExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ float: "left" }}>
              Considering {xAxisInfo} on X-axis and {yAxisInfo} on Y-axis for {facultyTypeInfo} for the {selectedSchoolsToCompare ? selectedSchoolsToCompare : "* schools to compare *"} schools
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "10px 16px 24px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ padding: "2px 2px" }}>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <MultipleSelect error={facultyTypeErr} onChange={handleFacultyTypeChange} options={facultyTypeOptions}
                  value={selectedFacultyType} menuProps={MenuPropsStyles} label="Faculty type to consider" helperText="Select value for faculty type to consider" />
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={5} container direction="column" justify="center" alignItems="center" className={classes.gridPaddingWithoutRatio}>
                <SingleSelect error={xErr} onInputChange={handleXAxisChange} options={xAxisOptions}
                  inputValue={selectedXAxis} menuProps={MenuPropsStyles} label="X-Axis" helperText="Select value for X-Axis" />
              </Grid>
              <Grid style={{ fontSize: 26 }} item xs={2} container direction="column" justify="center" alignItems="center">
                vs
              </Grid>
              <Grid item xs={5} container direction="column" justify="center" alignItems="center" className={classes.gridPaddingWithoutRatio}>
                <SingleSelect error={yErr} onInputChange={handleYAxisChange} options={yAxisOptions}
                  inputValue={selectedYAxis} menuProps={MenuPropsStyles} label="Y-Axis" helperText="Select value for Y-Axis" />
              </Grid>
              <Grid item xs={12} style={{ padding: "2px 2px" }}>
                <Divider />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <SingleSelect error={schoolErr} options={schoolsToCompareOptions} onInputChange={handleSchoolsToCompareChange}
                  inputValue={selectedSchoolsToCompare} label="Schools to compare" helperText="Select a schools to compare" required={false} />
              </Grid>
              <Grid item xs={3}><Button variant="contained" className="searchButton" color="primary" onClick={search} >Search</Button></Grid>
              <Grid item xs={3}></Grid>

            </Grid>
          </AccordionDetails>
        </Accordion>
        {showSpinner &&
          <div style={{ textAlign: "center" }}>
            <img src={Loading} alt="Loading..." width="80px" height="80px" />
          </div>
        }
        {perceptualMapData.length > 0 && !showSpinner &&
          <Paper square style={{ marginTop: 20 }}>
            <div style={{ padding: "20px 0px 0px 40px" }}>
              <FormControlLabel
                control={<Switch checked={invertAxes} onChange={handleInvertAxesChange} />}
                label="Invert Axes"
              />
            </div>
            <div class="PerceptualMapContainer">
              <ReactApexChart options={bubbleChartOptions}
                series={perceptualMapData}
                type="bubble" height={550} width={1020} />

            </div>
          </Paper>
        }
      </div>
    </ThemeProvider >
  );
}

export default PerceptualMap;