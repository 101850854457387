import React ,{useState,useEffect} from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Theme from  '../../../../../constants/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import {BACKEND_URL} from '../../../../../utils';
import SinglePreFilledAutoComplete from '../../../../InputComponents/SinglePreFilledAutoComplete';
import * as classes from './Education.module.css';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
const presentYear=new Date().getFullYear();
const AddPhdModal = props => {
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }))(Tooltip);
    const theme = Theme();
    const primaryColor='#555559';
    const [degree,setDegree] = useState("Ph.D.");
    const [year,setYear] = useState("");
    const [university,setUniversity] = useState("");
    const [area, setArea] = useState("");
    const [noPhd,setNoPhd]= useState(false)
    const [advisors,setAdvisors]=useState([])
    const [id,setId]=useState()
    const [coAdvisors,setCoAdvisors]=useState([])
    const [toggleAdvisor,setToggleAdvisor]=useState(false);
    const [isNew,setIsNew]=useState(true)
    const [isValidForm,setIsValidForm] = useState(false);
    const [valueUniversity,setValueUniversity] = useState("");
    const [optionsUniversity, setOptionsUniversity] = React.useState([]);
    const [loadingUniversity,setLoadingUniversity] =React.useState(false);
    const userType = useSelector(state => state.auth.user_type);

    useEffect(() => {
        setId(props.curEducation.id)
        setDegree(props.curEducation.degree)
        setUniversity(props.curEducation.institution)
        setYear(props.curEducation.year)
        setArea(props.curEducation.area)
        setIsNew(props.curEducation.isNew)
        setAdvisors([[props.curEducation.advisorFirstName,props.curEducation.advisorLastName]])
        setCoAdvisors([[props.curEducation.coadvisorFirstName,props.curEducation.coadvisorLastName]])
        setToggleAdvisor(props.curEducation.coadvisorFirstName !== undefined && props.curEducation.coadvisorFirstName.length !== 0)
        if(noPhd===true){
            setDegree("")
        }
        if(noPhd){
            if(props.curEducation.institution.trim()!=="" && props.curEducation.degree.trim()!=="" && props.curEducation.year!=="" && props.curEducation.year>=1950 && props.curEducation.year<=presentYear && props.curEducation.area.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }else{
            if(!props.curEducation.advisorFirstName || !props.curEducation.advisorLastName){
                setIsValidForm(false)
            }else if(props.curEducation.institution.trim()!=="" && props.curEducation.degree.trim()!=="" && props.curEducation.year!=="" && props.curEducation.year>=1950 && props.curEducation.year<=presentYear && props.curEducation.area.trim()!=="" && props.curEducation.advisorFirstName.trim()!=="" && props.curEducation.advisorLastName.trim()!=="" ){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }
    },[props.curEducation]);

    const saveEducation = () => {
        props.onSave({
            id: id,
            isNew: isNew,
            degree: degree, 
            institution: university, 
            year: year, 
            area: area,
            advisorFirstName: (advisors.length===0 || advisors[0][0]===undefined) ? '':advisors[0][0].trim(),
            advisorLastName: (advisors.length===0 || advisors[0][1]===undefined)? '':advisors[0][1].trim(),
            coadvisorFirstName: (coAdvisors.length===0 ||coAdvisors[0][0]===undefined)? '':coAdvisors[0][0].trim(),
            coadvisorLastName: (coAdvisors.length===0||coAdvisors[0][1]===undefined) ? '':coAdvisors[0][1].trim(),
        });
        setId(0);
        setDegree("");
        setYear("");
        setUniversity("");
        setArea("");
        setAdvisors([])
        setCoAdvisors([])
        setIsNew(true)
    };

    const degreeChangeHandler = event => {
        setDegree(event.target.value);
        if(noPhd){
            if(university.trim()!=="" && event.target.value.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }else{
             if(university.trim()!=="" && event.target.value.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!=="" && advisors.length!==0 && advisors[0][0].trim()!=="" && advisors[0][1].trim()!==""){
                 setIsValidForm(true)
             }else{
                 setIsValidForm(false)
             }
        }
    };

    const areaChangeHandler = event => {
        setArea(event.target.value);
        if(noPhd){
            if(university.trim()!=="" && degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && event.target.value.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }else{
            if(university.trim()!=="" && degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && event.target.value.trim()!=="" && advisors.length!==0 && advisors[0][0].trim()!=="" && advisors[0][1].trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }
   };

    const yearChangeHandler = event => {
        setYear(parseInt(event.target.value));
        if(noPhd){
            if(university.trim()!=="" && degree.trim()!=="" && event.target.value.trim()!=="" && event.target.value>=1950 && event.target.value<=presentYear && area.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }else{
            if(university.trim()!=="" && degree.trim()!=="" && event.target.value.trim()!=="" && event.target.value>=1950 && event.target.value<=presentYear && area.trim()!=="" && advisors.length!==0 && advisors[0][0].trim()!=="" && advisors[0][1].trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }
    };

    const universityChangeHandler = (name, value) =>{
        setUniversity(name);
        if(noPhd){
            if(name && name.trim()!=="" && optionsUniversity.map(univ => univ.name).includes(name) === true && degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }else{
            if(name && name.trim()!=="" && optionsUniversity.map(univ => univ.name).includes(name) === true && degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!=="" && advisors.length!==0 && advisors[0][0].trim()!=="" && advisors[0][1].trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }
    };

    const errorMessage= (isValidForm) => {
        if(isValidForm){
            return "";
        }else{
            var message="Incomplete form: ";
            if(noPhd){
                if(degree.trim()===""){
                    message+="Enter Degree, ";
                }
                if(area.trim()===""){
                    message+="Enter Area, ";
                }
                if(!university || university.trim()==="" || (optionsUniversity.length !== 0 && optionsUniversity.map(univ => univ.name).includes(university) === false)){
                    message+="Select University, ";
                }
                if(year===""){
                    message+="Enter Year, ";
                }else if(year<1950 || year>presentYear){
                    message+="Enter Valid Year between 1950 and ";
                    message+=(presentYear.toString()) + ", ";
                }
            }else{
                if(!degree || degree.trim()===""){
                    message+="Enter Degree, ";
                }
                if(!area || area.trim()===""){
                    message+="Enter Area, ";
                }
                if(!university || university.trim()==="" || (optionsUniversity.length !== 0 && optionsUniversity.map(univ => univ.name).includes(university) === false)){
                    message+="Select University, ";
                }
                if(year===""){
                    message+="Enter Year, ";
                }else if(year<1950 || year>presentYear){
                    message+="Enter Valid Year between 1950 and ";
                    message+=(presentYear.toString()) + ", ";
                }
                if(!advisors ||advisors.length===0 || !advisors[0] || !advisors[0][0] || advisors[0][0].trim()===""){
                    message+="Enter Advisor First Name, ";
                }
                if(!advisors || advisors.length===0 || !advisors[0] || !advisors[0][1] || advisors[0][1].trim()===""){
                    message+="Enter Advisor Last Name, ";
                }
            }
        }
        return message.slice(0, message.length - 2);
    };

    const saveNoPhd = event => {
        setNoPhd(true)
        setDegree("")
        setAdvisors([])
        setIsValidForm(false)
    };

    const saveYesPhd = event => {
        setNoPhd(false)
        setDegree("Ph.D.")
        if(university.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!=="" && advisors.length!==0 && advisors[0][0].trim()!==""){
            setIsValidForm(true)
        }else{
            setIsValidForm(false)
        }
    };

    const advisorFirstNameHandler = event => {
        if(advisors.length===0){
            setAdvisors([[event.target.value,""]])
        }else{
        var curAdvisor=advisors[0]
        setAdvisors([[event.target.value,curAdvisor[1]]])
        }

        if(noPhd){
            if(degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        } else {
            if(degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!=="" && event.target.value.trim()!=="" && advisors.length!==0 && advisors[0][1]!==undefined && advisors[0][1].trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
        }
    };

    const advisorLastNameHandler = event =>{
        if(advisors.length===0){
            setAdvisors([["",event.target.value]])
          }else{
           var curAdvisor=advisors[0]
           setAdvisors([[curAdvisor[0],event.target.value]])
          }

          if(noPhd){
            if(degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!==""){
                setIsValidForm(true)
            }else{
                setIsValidForm(false)
            }
         }else{
             if(degree.trim()!=="" && year!=="" && year>=1950 && year<=presentYear && area.trim()!=="" && event.target.value.trim()!=="" && advisors[0][0].trim()!==""){
                 setIsValidForm(true)
             }else{
                 setIsValidForm(false)
             }
         }
    };

    const coadvisorFirstNameHandler = event =>{
        if(coAdvisors.length===0){
            setCoAdvisors([[event.target.value,""]])
        }else{
        var curAdvisor=coAdvisors[0]
        setCoAdvisors([[event.target.value,curAdvisor[1]]])
        }
    };

    const coadvisorLastNameHandler = event =>{
        if(coAdvisors.length===0){
            setCoAdvisors([["",event.target.value]])
        }else{
            var curAdvisor=coAdvisors[0]
            setCoAdvisors([[curAdvisor[0],event.target.value]])
        }
    };

    const removeCoAdvisors = () =>{
        setCoAdvisors([]);
        setToggleAdvisor(false)
    };

    const onInputUniversityChangeHandler= (event,newInputValue) =>{
        setValueUniversity(newInputValue)
        universityChangeHandler(newInputValue,newInputValue)
        setLoadingUniversity(true)
        if(!newInputValue || newInputValue.trim()==="")
        {
          setLoadingUniversity(false)
            return;
        }
        const url = BACKEND_URL +'auto/univ/'+ newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsUniversity(Object.keys(schools.universities).map((key) => schools.universities[key]));
            setLoadingUniversity(false)
          })();
    };
    return (
        <ThemeProvider theme={theme}>
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModal}
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Add Phd
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row md={12}>
                            <Col md={3}>
                                <TextInputControlled label="Degree" onChange={degreeChangeHandler.bind(this)}  value={degree}
                                disabled={noPhd===true || userType === 'admin'?false:true}>
                                </TextInputControlled>
                            </Col>
                            <Col md={2}>
                                <TextInputControlled label="Area" onChange={areaChangeHandler.bind(this)} value={area}/>
                            </Col>

                            <Col md ={5}>
                                <SinglePreFilledAutoComplete name="name" value={university}
                                onInputChange={onInputUniversityChangeHandler}
                                inputValue={valueUniversity}
                                loading={loadingUniversity}
                                label="Select University"
                                options={optionsUniversity.map(option=>option.name)}/>
                            </Col>

                            <Col md={2}>
                                <TextInputControlled label="Year"  onChange={yearChangeHandler.bind(this)} value={year}/>
                            </Col>
                        </Row>
                        <Row md={12} style={{marginTop: "25px"}}>
                            <Col md={2}>
                                {
                                    (isNew && noPhd===false)?
                                    <Button variant="contained" style={{width:'6em',height:'2.5em',margin: "0px",color:'#FFFFFF',backgroundColor:primaryColor}} onClick={saveNoPhd}>No Phd</Button>
                                    :
                                    null
                                }
                                {
                                    (isNew && noPhd===true)?
                                    <Button variant="contained" style={{width:'6em',height:'2.5em',margin: "0px",color:'#FFFFFF',backgroundColor:primaryColor}} onClick={saveYesPhd}>Have Phd</Button>
                                    :
                                    null
                                }
                            </Col>
                            {
                                noPhd===false
                                ? 
                                <Col md={4}>
                                    <TextInputControlled label="Advisor First Name"   onChange={advisorFirstNameHandler.bind(this)}  value={advisors.length===0?"":advisors[0][0]}/>
                                </Col>
                                :null
                            }
                            {
                                noPhd===false
                                ? 
                                <Col md={4}>
                                    <TextInputControlled label="Advisor Last Name"    onChange={advisorLastNameHandler.bind(this)} value={advisors.length===0?"":advisors[0][1]}/>
                                </Col>
                                : null
                            }
                            {
                                (noPhd===false && (toggleAdvisor===false))? 
                                <Col md={1}>
                                    <LightTooltip title="Click to add a Co-advisor." placement="top">
                                    <AddCircleIcon style={{marginLeft:"0px",marginTop: "12px",color:primaryColor}} onClick={()=>setToggleAdvisor(true)} />
                                    </LightTooltip>
                            </Col>
                            :null
                            }
                            {
                                (noPhd===false && (toggleAdvisor===true)) || (coAdvisors.length !== 0 && (coAdvisors[0][0].length !== 0 || coAdvisors[0][1].length !== 0))?
                                <Col md={1}>
                                    <LightTooltip title="Click to remove Co-advisor" placement="top">
                                    <RemoveCircleIcon style={{marginLeft:"0px",marginTop: "12px",color:primaryColor}} onClick={()=>removeCoAdvisors()}  />
                                    </LightTooltip>
                                </Col>
                                :null
                            }   
                        </Row>
                        {
                            (noPhd===false && toggleAdvisor===true) || (coAdvisors.length !== 0 && (coAdvisors[0][0].length !== 0 || coAdvisors[0][1].length !== 0))?
                            <Row md={12} style={{marginTop: "25px"}}>
                                <Col md={2}></Col>
                                <Col md={4}>
                                    <TextInputControlled label="Co-advisor First Name"  onChange={coadvisorFirstNameHandler.bind(this)} value={coAdvisors.length===0?"":coAdvisors[0][0]}/>
                                </Col>
                                <Col md={4}>
                                    <TextInputControlled label="Co-advisor Last Name" onChange={coadvisorLastNameHandler.bind(this)}  value={coAdvisors.length===0?"":coAdvisors[0][1]}/>
                                </Col>
                            </Row>
                            :
                            null
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isValidForm === false && userType === 'admin' &&
                        <Row className={classes.row} md={12}>
                            <Col md={12}>
                                <InfoIcon fontSize="small" style={{ color: "gray" }} />
                                <span style={{ verticalAlign: "middle" }}><strong>Note for admin</strong> - {errorMessage(isValidForm)}</span>
                            </Col>
                        </Row>
                    }
                    <Button  variant="contained" style={{color:'#000000',backgroundColor:"#BBB218",borderRadius:"0%"}} onClick={props.onHide}>Close</Button>
                    <LightTooltip title={errorMessage(isValidForm)}>
                    <span>
                    <Button  variant="contained" style={{color:'#000000',backgroundColor:"#BBB218",borderRadius:"0%"}} onClick={saveEducation} disabled={userType === 'admin' ? false : !isValidForm}>Save</Button>
                    </span>
                    </LightTooltip>
                </Modal.Footer>
            </Modal>
        </ThemeProvider>
      );
};

export default AddPhdModal;