import * as actionTypes from '../actions/actionTypes';
import UnivProfile from '../../model/univProfile';

const initialState = Object.assign(new UnivProfile(), {
    _id: "",
    abbreviation: "",
    business_school: "",
    country: "",
    departments: [],
    domain: "",
    name: "",
    status: "",
    profile_picture: "",
    departments_list: [],
})

const univProfileState = {
    ...initialState
};

const univProfileReducer = (state = univProfileState, action) => {
    switch (action.type) {
        case actionTypes.GET_UNIV_PROFILE:
            let departments_list = [];
            if (!('abbreviation' in action.profileData) || !action.profileData.abbreviation) {
                action.profileData.abbreviation = "";
            }
            if (!('business_school' in action.profileData) || !action.profileData.business_school) {
                action.profileData.business_school = "";
            }
            if (!('country' in action.profileData) || !action.profileData.country) {
                action.profileData.country = "";
            }
            if (!('departments' in action.profileData) || !action.profileData.departments) {
                action.profileData.departments = [];
            } else {
                departments_list = action.profileData.departments.map(obj => obj['name']);
            }
            if (!('domain' in action.profileData) || !action.profileData.domain) {
                action.profileData.domain = "";
            }
            if (!('name' in action.profileData) || !action.profileData.name) {
                action.profileData.name = "";
            }
            if (!('status' in action.profileData) || !action.profileData.status) {
                action.profileData.status = "";
            }
            if (!('profile_picture' in action.profileData) || !action.profileData.profile_picture) {
                action.profileData.profile_picture = "";
            }

            return {
                ...state,
                ...Object.assign(new UnivProfile(), {
                    _id: action.profileData._id,
                    abbreviation: action.profileData.abbreviation,
                    business_school: action.profileData.business_school,
                    country: action.profileData.country,
                    departments: action.profileData.departments,
                    departments_list: departments_list,
                    domain: action.profileData.domain,
                    name: action.profileData.name,
                    status: action.profileData.status,
                    profile_picture: action.profileData.profile_picture,
                })
            };
        case actionTypes.CLEAR_UNIV_PROFILE:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
};

export default univProfileReducer;
