import React, { useEffect, useState } from 'react';
import './HireImpact.css';
import classes2 from './HireImpact.module.css';
import ReactApexChart from "react-apexcharts";
import {
    Button, Grid, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
    FormControl, RadioGroup, FormControlLabel, Radio, Accordion, AccordionDetails, AccordionSummary
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from "prop-types";
import Theme from '../../../../constants/Theme';
import colors from '../../../../constants/RLcolors';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Loading from '../../../../assets/Images/loading.gif';
import * as hireImpactActions from '../../../../store/actions/hireImpact';
import SingleSelect from '../../../InputComponents/SingleSelect';
import MultipleSelect from '../../../InputComponents/MultipleSelect';
import InfoCard from '../../../InputComponents/InfoCard';
import FacultyInfoInput from '../../../InputComponents/FacultyInfoInput';
import { convertArrayToSummary } from '../../../../utils';
const useStyles = makeStyles((theme) => ({
    searchButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
}));
const HireImpact = props => {
    const theme = Theme();
    const dispatch = useDispatch();
    const inlineClasses = useStyles();
    const [showDepartmentError, setShowDepartmentError] = React.useState(false);
    const [showFacultyToConsiderError, setShowFacultyToConsiderError] = React.useState(false);
    const [showJournalsError, setShowJournalsError] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [selectedFacultyName, setSelectedFacultyName] = React.useState([]);
    const [selectedJournalName, setSelectedJournalName] = React.useState([]);
    const [selectedFacultyToString, setSelectedFacultyToString] = React.useState('')
    const [journalInfo, setJournalInfo] = React.useState("*Journals*");
    const [facultiesToConsiderInfo, setFacultiesToConsiderInfo] = React.useState("*Faculties to consider*");
    const [journalNames, setJournalNames] = React.useState([]);
    const [allJournalsCopy, setAllJournalsCopy] = React.useState('');
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('papers');
    const [department, setdepartment] = React.useState('');
    const [candidateFirstName, setCandidateFirstName] = React.useState('');
    const [candidateLastName, setCandidateLastName] = React.useState('');
    const [bubbleChartHireImpactOn, setBubbleChartHireImpactOn] = React.useState('Department');
    const [chartSeries, setChartSeries] = React.useState([]);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [facultyId, setFacultyId] = React.useState('');
    const [facultyInfoColor, setFacultyInfoColor] = React.useState(colors.gray);
    const departmentBubbleChartSeries = useSelector(state => state.hireImpact.departmentBubbleChartSeries);
    const schoolBubbleChartSeries = useSelector(state => state.hireImpact.schoolBubbleChartSeries);
    const comparisonTableData = useSelector(state => state.hireImpact.comparisonTableData);
    const hiringAuthorData = useSelector(state => state.hireImpact.hiringAuthorData);
    const universityData = useSelector(state => state.hireImpact.universityData);
    const departments = useSelector(state => state.hireImpact.departments);
    const departmentToJournalsDict = useSelector(state => state.hireImpact.departmentToJournalsDict);
    const authors = useSelector(state => state.hireImpact.authors);
    const authorsNameToIdDict = useSelector(state => state.hireImpact.authorsNameToIdDict);
    const reverseJournalsDict = useSelector(state => state.hireImpact.reverseJournalsDict);
    const [removedFaculty, setRemovedFaculty] = useState(false);
    const card1Title = `How your school improves with hiring of ${hiringAuthorData.first_name} ${hiringAuthorData.last_name}`;
    const card2Title = `Papers and citations of school`;
    const card3Title = `Papers and citations of ${hiringAuthorData.first_name} ${hiringAuthorData.last_name}`;
    const card4Title = `Comparing research papers and citations of ${hiringAuthorData.first_name} ${hiringAuthorData.last_name} with ${selectedFacultyToString}`



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuPropsStyles = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    React.useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = async () => {
        const token = localStorage.getItem('rl_token');
        const userId = localStorage.getItem('rl_userId');
        try {
            await dispatch(hireImpactActions.getDepartments(token, userId));
        }
        catch (err) {
            console.log("cannot get departments of this university. Error: ", err);
        }
    }
    const getAuthors = async (dept) => {
        const token = localStorage.getItem('rl_token');
        try {
            await dispatch(hireImpactActions.getAuthors(token, dept));
        }
        catch (err) {
            console.log("cannot get authors of this university. Error: ", err);
        }
    }

    // hardcoded stuff
    const headCells = [
        { id: "name", numeric: false, disablePadding: true, label: "Name" },
        { id: "papersPerYear", numeric: true, disablePadding: true, label: "Papers per year" },
        { id: "citationsPerPaper", numeric: true, disablePadding: true, label: "Citations per paper" },
        { id: "papers", numeric: true, disablePadding: true, label: "Papers" },
        { id: "citations", numeric: true, disablePadding: true, label: "Citations" },
    ];

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }
    const handleDepartmentChange = (event, newInputValue) => {
        setdepartment(newInputValue ? newInputValue : '');
        getAuthors(newInputValue ? newInputValue : '');
        let journalNames1 = newInputValue in departmentToJournalsDict ? departmentToJournalsDict[newInputValue] : [];
        setAllJournalsCopy(journalNames1);
        if (journalNames1[0] !== "All")
            journalNames1.unshift('All');
        setJournalNames(journalNames1);
        if (newInputValue && newInputValue !== '') {
            setShowDepartmentError(false)
        }
    };
    const handleFacultyChange = (event) => {
        const newSelectedFacultyNames = event.target.value
        if (newSelectedFacultyNames.length < selectedFacultyName.length)
            setRemovedFaculty(true)
        setSelectedFacultyName(event.target.value);
        setFacultiesToConsiderInfo(convertArrayToSummary(event.target.value));
        if (event.target.value.length !== 0) {
            setShowFacultyToConsiderError(false)
        }
    };
    useEffect(() => {
        if (removedFaculty === true && comparisonTableData.length > 0)
            search()
    }, [selectedFacultyName]);
    const handleJournalChange = (event) => {
        if (selectedJournalName.includes("All") && event.target.value.length > 1) {
            const index = event.target.value.indexOf("All");
            if (index > -1) {
                event.target.value.splice(index, 1);
            }
        }
        if (!selectedJournalName.includes("All") && event.target.value.includes("All")) {
            event.target.value = ["All"];
        }
        setSelectedJournalName(event.target.value);
        setJournalInfo(convertArrayToSummary(event.target.value));
        if (event.target.value.length !== 0) {
            setShowJournalsError(false)
        }
    };
    const handleBubbleChartHireImpactOnChange = (event) => {
        setBubbleChartHireImpactOn(event.target.value);
        event.target.value.toLowerCase() === "department" ? beautifyChartAxis(departmentBubbleChartSeries, "department") : beautifyChartAxis(schoolBubbleChartSeries, "school");
        setChartSeries(event.target.value.toLowerCase() === 'school' ? schoolBubbleChartSeries : departmentBubbleChartSeries);
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const setFacultyInfo = (facultyInfo) => {
        if (facultyInfo !== null) {
            setCandidateFirstName(facultyInfo.firstName);
            setCandidateLastName(facultyInfo.lastName);
            setFacultyId(facultyInfo.id);
        }
    }
    const checkValidity = () => {
        let isDataValid = true;
        if (facultyId === '') {
            setFacultyInfoColor(colors.error);
            isDataValid = false;
        }
        if (department === '') {
            setShowDepartmentError(true)
            isDataValid = false;
        }
        if (selectedFacultyName.length === 0) {
            setShowFacultyToConsiderError(true);
            isDataValid = false;
        }
        if (selectedJournalName.length === 0) {
            setShowJournalsError(true);
            isDataValid = false;
        }
        return isDataValid;
    }
    const search = async () => {
        if (checkValidity()) {
            setShowSpinner(true);
            const token = localStorage.getItem('rl_token');
            let facultiesToConsider = "";
            let journal_ids = "";

            for (let journal1 of selectedJournalName.includes("All") ? allJournalsCopy : selectedJournalName) {

                if (journal1 in reverseJournalsDict) {
                    journal_ids += `${reverseJournalsDict[journal1]}&journal_ids=`
                }
            }
            

            journal_ids = journal_ids.slice(0, journal_ids.length - 13);
            if (selectedJournalName.includes("All")){
                journal_ids = 'All'

            }

            for (let faculty1 of selectedFacultyName) {
                facultiesToConsider += `${authorsNameToIdDict[faculty1]}&facultyIds=`
            }
            facultiesToConsider = facultiesToConsider.slice(0, facultiesToConsider.length - 12);
            try {
                await dispatch(hireImpactActions.hireImpact(token, department, facultyId, facultiesToConsider, journal_ids));
            }
            catch (err) {
                console.log(err);
                alert("Internal Server Error. Please try again later");
            }

            let comparisonTitle = '';
            selectedFacultyName.map((item, index) => {
                index === 0 ?
                    comparisonTitle = comparisonTitle + ' ' + item
                    :
                    comparisonTitle = comparisonTitle + ', ' + item
            })
            comparisonTitle = comparisonTitle + '.'

            setSelectedFacultyToString(comparisonTitle);
            setRemovedFaculty(false);
            setShowSpinner(false);
        }
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead style={{ backgroundColor: "#EDEDED" }}>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell classes={{ root: classes2.standardSizeBold }}
                            align={"center"}
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };


    const [percentageChanged, setPercentageChanged] = React.useState(0)
    const [percentageFlag, setPercentageFlag] = React.useState(true)
    const [arrowDirection, setArrowDirection] = React.useState(0);

    const calculatePercent = () => {
        let difference = 0.00;
        let percentAfter = 100.00;
        percentAfter = ((universityData.average_citations_after_hire / universityData.average_citations_before_hire) * 100);
        percentAfter = percentAfter.toFixed(2);
        difference = percentAfter - 100.00;
        setPercentageChanged(difference.toFixed(2));
        if (difference > 0.00) {
            setArrowDirection(1)
        } else if (difference < 0.00) {
            setArrowDirection(-1)
        }
        setPercentageFlag(false);
    }

    const card1 = (
        <Grid container spacing={3} direction='row' style={{ textAlign: 'start' }}>
            <Grid item xs={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {arrowDirection === 0 ? <RemoveIcon className={classes2.svg1} /> : null}
                        {arrowDirection === 1 ? <ArrowUpwardIcon className={classes2.svg1} style={{ color: "#28a745" }} /> : null}
                        {arrowDirection === -1 ? <ArrowDownwardIcon className={classes2.svg1} style={{ color: "#dc3545" }} /> : null}
                        <span style={{ fontSize: 30 }}>{percentageChanged}%</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={3} direction='column' style={{ textAlign: 'start' }}>
                    <Grid item xs={12} style={{ paddingBottom: 0, fontSize: 14 }}>Average citations before hire: {universityData.average_citations_before_hire}</Grid>
                    <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0, fontSize: 14 }}>Average citations after hire: {universityData.average_citations_after_hire}</Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    const card2 = (
        <Grid container spacing={3} direction='row' style={{ textAlign: 'start' }}>
            <Grid item xs={6} style={{ paddingBottom: 0 }}>
                <span className="cardInfoText">School Total:</span>
            </Grid>
            <Grid item xs={6} style={{ paddingBottom: 0 }}>
                <span className="cardInfoText">{universityData.total_papers} papers, {universityData.total_citations} citations</span>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <span className="cardInfoText">Department Average Per Faculty:</span>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <span className="cardInfoText">{universityData.average_department_papers.toFixed(2)} papers, {universityData.average_department_citations.toFixed(2)} citations</span>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <span className="cardInfoText">Selected Faculty:</span>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <span className="cardInfoText">{universityData.selected_faculty_average_papers} papers, {universityData.selected_faculty_average_citations} citations</span>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid>
    );

    const card3 = (
        <Grid container spacing={3} direction='row' style={{ textAlign: 'start' }}>
            <Grid item xs={3}>
                <Grid container spacing={3} direction='column' style={{ textAlign: 'start' }}>
                    <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0, margin: "auto", fontSize: 50 }}>{hiringAuthorData.h_index}</Grid>
                    <Grid item xs={12} className="cardInfoText" style={{ paddingTop: 0, paddingBottom: 0, margin: "auto", marginTop: -10 }}>H-Index</Grid>
                </Grid>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={3} direction='row' style={{ textAlign: 'start' }}>
                    <Grid item xs={8} style={{ paddingBottom: 0 }}>
                        <span className="cardInfoText">Papers:</span>
                    </Grid>
                    <Grid item xs={4} style={{ paddingBottom: 0 }}>
                        <span className="cardInfoText">{hiringAuthorData.total_papers}</span>
                    </Grid>
                    <Grid item xs={8} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <span className="cardInfoText">Citations:</span>
                    </Grid>
                    <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <span className="cardInfoText">{hiringAuthorData.total_citations}</span>
                    </Grid>
                    <Grid item xs={8} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        {/*<span className="cardInfoText">Proportion of (A&A-)/B journals:</span>*/}
                    </Grid>
                    <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        {/*<span className="cardInfoText">{hiringAuthorData.proportion}</span>*/}
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    const table1 = (
        <TableContainer>
            <Table style={{ marginLeft: "-13px", borderBottomWidth: "0" }}>
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                    {stableSort(comparisonTableData, getComparator(order, orderBy)).map(
                        (row, index) => {
                            return (
                                <TableRow tabIndex={-1} key={row.name} selected={row.name === `${candidateFirstName.toLowerCase()} ${candidateLastName.toLowerCase()}`}>
                                    <TableCell classes={{ root: classes2.standardSize }} align="center" component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell classes={{ root: classes2.standardSize }} align="center">{row.papersPerYear.toFixed(2)}</TableCell>
                                    <TableCell classes={{ root: classes2.standardSize }} align="center">{row.citationsPerPaper.toFixed(2)}</TableCell>
                                    <TableCell classes={{ root: classes2.standardSize }} align="center">{row.papers}</TableCell>
                                    <TableCell classes={{ root: classes2.standardSize }} align="center">{row.citations}</TableCell>
                                </TableRow>
                            );
                        }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );


    const [chartAxisX, setChartAxisX] = React.useState([0, 0]);
    const [chartAxisY, setChartAxisY] = React.useState([0, 0]);
    const [bubbleColour, setBubbleColour] = React.useState(['#E24511']);

    const beautifyChartAxis = (dataList, toggle) => {
        let color = []
        toggle === "department" ? color.push('#85276B', '#85276B') : color.push('#85276B', '#85276B')

        for (let i = 2; i < dataList.length; i++) {
            color.push('#E24301')
        }
        console.log(dataList)

        setBubbleColour(color);

        const dataX = new Set();

        const dataY = new Set();

        dataList.map((univ) => {
            dataX.add(univ.data[0][0]);
            dataY.add(univ.data[0][1])
        })

        let minX = Math.min(...Array.from(dataX.values()));
        let maxX = Math.max(...Array.from(dataX.values()));
        let minY = Math.min(...Array.from(dataY.values()));
        let maxY = Math.max(...Array.from(dataY.values()));

        setChartAxisX([minX, maxX]);
        setChartAxisY([minY, maxY]);
    }

    const [initialChartRenderFlag, setInitialChartRenderFlag] = React.useState(true);

    if (initialChartRenderFlag && departmentBubbleChartSeries.length > 0) {
        setChartSeries(departmentBubbleChartSeries);
        beautifyChartAxis(departmentBubbleChartSeries, "department");
        setInitialChartRenderFlag(false);
    }

    // const bubbleChartSeries = bubbleChartHireImpactOn.toLowerCase === "school" ? schoolBubbleChartSeries : departmentBubbleChartSeries;
    const bubbleChartOptions = {
        chart: {
            height: 350,
            type: 'bubble',
            toolbar: {
                show: false
            }
            
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                let index = opts["seriesIndex"];
                // return chartSeries.length > 0 ? chartSeries[index]["name"] : ''
                return chartSeries.length > 0 ? chartSeries[index]["abbreviation"] : ''
            }
        },
        fill: {
            opacity: 0.75
        },
        markers: {
            strokeWidth: 0
        },
        title: {
            text: ''
        },
        // colors: {bubbleColour},
        colors: bubbleColour,
        xaxis: {
            title: {
                text: "Average Papers Per Faculty",
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                }
            },
            labels: {
                formatter: (value) => { return value.toFixed(2) }
            },
            tickAmount: 5,
            min: 0,
            max: chartAxisX[1] + 10,
            type: 'category',
            axisBorder: {
                show: true,
                color: '#555559',
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            title: {
                text: "Average Citations Per Faculty",
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                }
            },
            labels: {
                formatter: (value) => { return value.toFixed(2) }
            },
            min: 0,
            tickAmount: 5,
            max: chartAxisY[1] + 100,
            axisBorder: {
                show: true,
                color: '#555559',
                offsetX: 0,
                offsetY: 0
            }
        },

        tooltip: {
            z:
            {
                show: false,
                enabled: false,
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return
                },
                title: ""
            }
        },

        legend: {
            position: "bottom"
        },
    };


    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={inlineClasses.featureHeading}>
                    Hire Impact
                </div>
                <Accordion square expanded={accordionExpand} onChange={handleAccordionExpand}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ float: "left" }}>
                            Considering {candidateFirstName === '' ? "*name of candidate*" : candidateFirstName + " " + candidateLastName} in&nbsp;
                            {department === '' ? "*department*" : department}&nbsp;as compared to&nbsp;
                            {facultiesToConsiderInfo} &nbsp;based on publications at {journalInfo}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={9} container direction="column" justify="center" alignItems="center">
                                <FacultyInfoInput index={0} setFacultyId={setFacultyInfo} borderColor={facultyInfoColor} />
                            </Grid>
                            <Grid item xs={3} container direction="column" justify="center" alignItems="center">
                                <SingleSelect error={showDepartmentError} options={departments} onInputChange={handleDepartmentChange}
                                    inputValue={department} label="Hiring Department" helperText="Select a department" />
                            </Grid>
                            <Grid item xs={5}>
                                <MultipleSelect disabled={department === ''} error={showFacultyToConsiderError} onChange={handleFacultyChange} options={authors}
                                    value={selectedFacultyName} menuProps={MenuPropsStyles} label="Faculty To Consider" helperText="Select a faculty" />
                            </Grid>
                            <Grid item xs={4}>
                                <MultipleSelect disabled={department === ''} error={showJournalsError} onChange={handleJournalChange} options={journalNames}
                                    value={selectedJournalName} menuProps={MenuPropsStyles} label="Journals" helperText="Select a journal" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" className="searchButton" color="primary" onClick={search} >Search</Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                {departmentBubbleChartSeries.length > 0 && !showSpinner &&
                    <Grid container spacing={3} className="formGrid" style={{ textAlign: "start" }}>
                        <Grid item xs={4}>
                            <div className={classes2.infoCard}>
                                {percentageFlag ? calculatePercent() : null}
                                <InfoCard title="PERCENTAGE OF IMPROVEMENT" subheader={card1Title} content={card1} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes2.infoCard}>
                                <InfoCard title="SCHOOL RESEARCH STATS" subheader={card2Title} content={card2} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes2.infoCard}>
                                <InfoCard title="NEW HIRE RESEARCH STATS" subheader={card3Title} content={card3} />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className={classes2.infoCard}>
                                <InfoCard title="COMPARISON TO SELECTED FACULTY" subheader={card4Title} content={table1} />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div id="chart" className={classes2.infoCard}>
                                <Card style={{ borderRadius: 0, height: "100%" }}>
                                    <CardContent>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div>Hire impact for All Journals on: </div>
                                            <div style={{ marginTop: 10 }}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup row name="position" defaultValue="top" value={bubbleChartHireImpactOn} onChange={handleBubbleChartHireImpactOnChange}>
                                                        <FormControlLabel value="Department" control={<Radio color="primary" />} label="Department" />
                                                        <FormControlLabel value="School" control={<Radio color="primary" />} label="School" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: -40 }}>
                                            <ReactApexChart options={bubbleChartOptions}
                                                series={bubbleChartHireImpactOn.toLowerCase() === "department" ? departmentBubbleChartSeries : schoolBubbleChartSeries}
                                                type="bubble" height={410} />
                                        </div>

                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        </ThemeProvider>
    );
}

export default HireImpact;
