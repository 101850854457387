import { GET_MERGE_AUTHORS_FOR_TOOLS, MERGE_SUCCESS } from '../actions/actionTypes';
import { updateObject } from '../../utils';


const initialState = {
    authors: [],
    author_to_map : {}
}


const mergeToolReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_MERGE_AUTHORS_FOR_TOOLS:
            return updateObject(state, {authors: action.payload, author_to_map: {}});
        case MERGE_SUCCESS:
            let filtered_autors = state.authors.filter(author => author._id !== action.payload);
            return updateObject(state, {authors: filtered_autors, author_to_map:{}});
        default:
            return state 
    }
}


export default mergeToolReducer;