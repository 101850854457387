import React from 'react';
import classes2 from './redflags.module.css';
import { Button, Container, Row, Col } from 'react-bootstrap';
import SingleSelectAutoComplete from '../../../InputComponents/SingleSelectAutoComplete';
import {BACKEND_URL} from '../../../../utils';
import Loading from '../../../../assets/Images/loading.gif';
import ReactFusioncharts from "react-fusioncharts";
import axios from 'axios';

const AuthorsThatHaveSameNumberOfPapersPerGroup = props =>{
    
  const [curTextUniv,setcurTextUniv]=React.useState("")
  const [curTextGroup,setcurTextGroup]=React.useState("")
  const [univoptions, setunivOptions] = React.useState([]);
  const [curUnivID,setcurUnivID]=React.useState("")
  const [category,setCategory]=React.useState([])
  const [papersData,setPapersData]=React.useState([])
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [searched,setSearched]=React.useState(false)
  const [groups,setGroups]=React.useState([])
  const [metadataCategory,setmetadataCategory]=React.useState([])
  const [metadataPapers,setmetadataPapers]=React.useState([])
    const getResponse =()=>{
      setSearched(true)
      setShowSpinner(true)
        const url = BACKEND_URL +'red_flag/authors_that_have_same_number_of_papers_per_group?university_id='+curUnivID;
        (async () => {
            const response = await fetch(url,{
              method:'GET'
            });
            const jsonData= await response.json();
            const resData=jsonData;
            let faculty=[]
            let papers=[]
            let metadataOffaculty=[]
            let metadataOfpapers=[]
            var setOfGroups= new Set()
            resData.map((k)=>{
                setOfGroups.add(k["bucket"])
                faculty.push({"label": k["faculty_name"]})
                papers.push({"value": k["total_papers"].toString()})

                metadataOffaculty.push({"label": k["faculty_name"],"group": k["bucket"]})
                metadataOfpapers.push({"value": k["total_papers"].toString()})
             })
             var groupSet=Array.from(setOfGroups)
             groupSet.push("Show all")
             setGroups(groupSet)
           setCategory(faculty)
           setPapersData(papers)
           setmetadataCategory(metadataOffaculty)
           setmetadataPapers(metadataOfpapers)
           setShowSpinner(false)
          })();
      }
      const dataSource = {
        "chart": {
          "caption": "Faculty Paper Distribution",
          "xAxisname": "Faculty",
          "yAxisName": "Papers",
          "theme": "fusion",
          "usePlotGradientColor": 0,
          "showPlotBorder": false,
          "showAlternateHGridColor": false,
          "legendItemFontColor": "000000",
          "drawCustomLegendIcon": true,
          "legendIconBorderThickness": 0,
          "xAxisNameFontSize": 15,
          "yAxisNameFontSize": 15,
          "captionFontSize": 20,
          "captionFontColor": "#000000",
          "captionFontBold": false,
          "xAxisNameFontBold": false,
          "yAxisNameFontBold": false,
          "valueFontColor": "FFFFFF"
        },
        "categories": [
          {
            "category": category
          }
        ],
        "dataset": [
          {
            "color": "00259A",
            "data": papersData
          }
        ]
      };
    const handleClick= ()=>{
        getResponse();
    }
    const handleDownload =()=>{
         const url = BACKEND_URL +'red_flag/authors_that_have_same_number_of_papers_per_group?university_id='+curUnivID+"&download=1";
        (async () => {
            axios({
              url: url,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'authorsThatHaveSameNumberOfPapers_'+ curTextUniv+  '.csv');
              document.body.appendChild(link);
              link.click();
            });
          })();
    }
    const onUnivInputChangeHandler= (event,newInputValue) =>{
      setcurTextUniv(newInputValue)
      if(!newInputValue || newInputValue.trim()===""){
            return;
        }
        const url = BACKEND_URL +'auto/univ/'+ newInputValue;
        (async () => {
            const response = await fetch(url);
            const univs = await response.json();
           var p=Object.keys(univs).map((key) => univs[key]);
           setunivOptions(p[0]);
         //  setLoading(false)
          })();
  }
  const onGroupInputChangeHandler= (event,newInputValue) =>{
    setcurTextGroup(newInputValue)
}
  const onUnivChange=(event,value)=>{
    if(value && !(Object.keys(value).length === 0 && value.constructor === Object)){
       setcurTextUniv(value.name)
       setcurUnivID(value.univ_id)
       }
  }
  const onGroupChange=(event,value)=>{
    var faculty=[]
    var papers=[]
    if(!value || value.trim()===""){
        return;
    }
    if(value==="Show all"){
        for(let i=0;i<metadataCategory.length;i++){
            faculty.push({"label": metadataCategory[i].label})
            papers.push(metadataPapers[i])
        }
    }else{
        for(let i=0;i<metadataCategory.length;i++){
            if(metadataCategory[i].group===value){
                faculty.push({"label": metadataCategory[i].label})
                papers.push(metadataPapers[i])
            }
        }
    }
    setCategory(faculty)
    setPapersData(papers)
  }
  const getOptionLabelUniv=(option) => {
    
        if(!option || !option.name){
            return "";
        }
    return option.name
  }
    return (
        <div>
          <div className={classes2.formCard}>
          <Container >
                <Row md={12} className={classes2.row}>
                    <Col md={2}>
                    </Col>
                    <Col md={4}>
                    <SingleSelectAutoComplete onInputChange={onUnivInputChangeHandler} options={univoptions}  
                      curText={curTextUniv}
                      onChange={onUnivChange}
                      getOptionLabel={getOptionLabelUniv}
                      label={"Enter University Name"}></SingleSelectAutoComplete>
                       </Col>
                    <Col md={4}>
                    <Button variant="contained" className="searchButton" color="primary" onClick={(e)=>handleClick()}>Click to Search</Button>
                    </Col>
                    <Col md={2}>
                    </Col>
                </Row>
                {
                    searched===true && showSpinner===false &&
                    <Row md={12} className={classes2.row}>
                    <Col md={2}>
                    </Col>
                    <Col md={4}>
                    <SingleSelectAutoComplete onInputChange={onGroupInputChangeHandler} options={groups}  
                      curText={curTextGroup}
                      onChange={onGroupChange}
                      label={"Select Group"}>
                      </SingleSelectAutoComplete>
                       </Col>
                       <Col md={4}>
                    <Button variant="contained" className="searchButton" color="primary" onClick={(e)=>handleDownload()}>Click to Download</Button>
                    </Col> 
                    <Col md={2}>
                    </Col>
                </Row>
                }
                </Container>
          </div>
          {
              searched===true && showSpinner===true &&
              <div style={{ textAlign: "center" }}>
                <img src={Loading} alt="Loading..." width="80px" height="80px" />
              </div>
          }
          {
              searched===true && showSpinner===false &&
             <div className={classes2.formCard} style={{ height: "500px" }}>
             <ReactFusioncharts
               type="stackedcolumn2d"
               width="100%"
               height="90%"
               dataFormat="JSON"
               dataSource={dataSource}
             />
           </div>
          }
        </div>
    )
}

export default AuthorsThatHaveSameNumberOfPapersPerGroup;