import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const addCohortRowAction = (faculty, count, rows) => {
    return {
        type: actionTypes.ADD_COHORT_ROW,
        facultyToAdd: faculty,
        newCount: count,
        rows: rows
    }
}
const removeCohortRowAction = (idToRemove, rows) => {
    return {
        type: actionTypes.REMOVE_COHORT_ROW,
        idToRemove: idToRemove,
        rows: rows
    }
}
const analyzeAction = (cohortAnalysisData) => {
    return {
        type: actionTypes.ANALYZE_COHORT,
        cohortAnalysisData: cohortAnalysisData
    }
}
const milestoneChangeAction = (milestone, allData) => {
    return {
        type: actionTypes.MILESTONE_CHANGE,
        milestone: milestone,
        allData: allData
    }
}
const compareFacultySearchAction = (data) => {
    return {
        type: actionTypes.GET_COMPARE_FACULTY,
        searchResult: data
    }
}
const clearCompareFacultyAction = () => {
    return {
        type: actionTypes.CLEAR_COMPARE_FACULTY,
    }
}

export const addCohortRow = (faculty, count) => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(addCohortRowAction(faculty, count, state.cohortAnalysis.cohortRows));
    };
}
export const removeCohortRow = (idToRemove) => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(removeCohortRowAction(idToRemove, state.cohortAnalysis.cohortRows));
    };
}
export const milestoneChange = (milestone, allData) => {
    return async (dispatch) => {
        dispatch(milestoneChangeAction(milestone, allData));
    };
}
export const analyze = (token, facultyIds, journals) => {
    return async (dispatch) => {
        let params = ""
        for (let id of facultyIds) {
            params += `id=${id}&`
        }
        for (let journal of journals) {
            params += `journals=${journal}&`
        }
        params = params.slice(0, -1);
        const response = await fetch(BACKEND_URL + `cohortAnalysis?${params}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while doing cohort analysis with error: ' + errorResData);
        }
        const cohortAnalysisData = await response.json();
        dispatch(analyzeAction(cohortAnalysisData));
    };
}

export const clearCompareFaculty = () => {
    return async (dispatch) => {
        dispatch(clearCompareFacultyAction());
    };
}
export const compareFacultyData = (selectedAuthors) => {
    return async (dispatch, getState) => {
        const state = getState();
        let dataToCompare = [];
        for (let data of state.cohortAnalysis.compareFacultyData) {
            if (data["id"] === selectedAuthors[0] || data["id"] === selectedAuthors[1]) {
                dataToCompare.push({ "data": data, "first_name": data["first_name"], "last_name": data["last_name"], "id": data["id"] });
            }
        }
        dispatch(compareFacultySearchAction({ "result": dataToCompare }));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_COHORT_ANALYSIS });
    }
}