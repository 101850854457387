import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import * as classes from './Papers.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../../constants/Theme';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import SingleSelectAutoComplete from '../../../../InputComponents/SingleSelectAutoComplete';
import { BACKEND_URL } from '../../../../../utils';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import * as email from '../../../../../store/actions/email';

const AddMorePapersModal = props => {
    const theme = Theme();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const dispatch = useDispatch();
    const [isValidForm, setIsValidForm] = useState(false);
    const [curTextJournal, setcurTextJournal] = React.useState("");
    const [journaloptions, setjournalOptions] = React.useState([]);
    const [newPaper, setNewPaper] = useState("");
    const token = useSelector(state => state.auth.token);

    const onJournalInputChangeHandler = (event, newInputValue) => {
        setcurTextJournal(newInputValue);
        if (!newInputValue || newInputValue.trim() === "") {
            return;
        }
        const url = BACKEND_URL + 'auto/journal/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const journals = await response.json();
            var p = Object.keys(journals).map((key) => journals[key]);
            setjournalOptions(p[0]);
            //  setLoading(false)
        })();
    };

    const getOptionSelectedJournal = (option, value) => {
        if (value && !(Object.keys(value).length === 0 && value.constructor === Object)) {
            setcurTextJournal(value.name)
        }
        return option.name === value.name
    };

    const getOptionLabelJournal = (option) => {

        if (!option || !option.name) {
            return "";
        }
        return option.name

    };

    const errorMessage = (isValidForm) => {
        if (isValidForm) {
            return "";
        } else {
            var message = "Incomplete form: ";
            if (curTextJournal.trim() === "") {
                message += "\n Select Journal";
            }
            if (newPaper.trim() === "") {
                message += "\n Enter Paper";
            }
            if (message === "Incomplete form: ") {
                setIsValidForm(true)
                return "";
            }
        }
        return message;
    };

    const onChangePaper = (event) => {
        setNewPaper(event.target.value);
    };

    const addMorePapersRequest = async () => {
        const recipient = "researchloupe.team@gmail.com";
        const subject = "Request to Add Paper";
        const body =
            `Hello Team RL,

I have a request to add the paper titled "${newPaper}", published in the journal ${curTextJournal}.

Regards,
${props.profileState.last_name}, ${props.profileState.first_name}
Author Id: ${props.profileState._id}
        `;
        const emailData = {
            recipient: recipient,
            subject: subject,
            body: body
        };
        try {
            await dispatch(email.send_email(token, emailData));
            props.onHide("Success");
        } catch (error) {
            alert("Failed to send email. Please try again later.");
            props.onHide("Error");
        }
    };

    useEffect(() => {
        setNewPaper(props.curPaper.paper)
        setIsValidForm(false)
    }, [props.curPaper]);

    return (
        <ThemeProvider theme={theme}>
            <Modal
                {...props}
                dialogClassName={classes.modalAddPapers}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter2">
                        Add Your Paper
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container >
                        <Row md={12} className={classes.row}>
                            <Col md={12}>
                                <SingleSelectAutoComplete onInputChange={onJournalInputChangeHandler} options={journaloptions}
                                    getOptionSelected={getOptionSelectedJournal}
                                    curText={curTextJournal}
                                    getOptionLabel={getOptionLabelJournal}
                                    label={"Enter Journal Name"}></SingleSelectAutoComplete>
                            </Col>
                        </Row>
                        <Row md={12} className={classes.row}>
                            <Col md={12}>
                                <TextInputControlled label="Enter Paper" value={newPaper}
                                    onChange={onChangePaper}></TextInputControlled>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <LightTooltip title={errorMessage(isValidForm)} placement="bottom">
                        <span>
                            <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} disabled={!isValidForm} onClick={addMorePapersRequest}>Request to Add</Button>
                        </span>
                    </LightTooltip>
                    <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={() => props.onHide()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </ThemeProvider>
    );
};

export default AddMorePapersModal;