import React, { useState } from 'react';
import classes from './Departments.module.css';
import profileClassses from '../Profile.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Profile from '../../../../../model/profile';
import * as profileActions from '../../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../../store/actions/coauthorprofile';
import AddDepartmentModal from './AddDepartmentModal';
import { Grid, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Departments = props => {
    const addColor = "#DADADA";
    const dangerColor = "#DADADA";
    const dispatch = useDispatch();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const token = useSelector(state => state.auth.token);
    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const [authorDepartments, setAuthorDepartments] = useState(props.data.percent_appointment);
    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [accordionExpand, setAccordionExpand] = React.useState(true);

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    const createUpdatedProfile = (new_dept) => {
        return Object.assign(new Profile(), {
            _id: props.data._id,
            percent_appointment: new_dept,
            department: Object.keys(new_dept),
        })
    }
    const editHandler = async (new_dept) => {
        try {
            if (props.isCoAuthor === true) {
                await dispatch(coAuthorProfileActions.update_co_author_profile(token, createUpdatedProfile(new_dept), props.data._id));
            } else {
                await dispatch(profileActions.update_author_profile(token, createUpdatedProfile(new_dept)));
            }
        }
        catch (error) {
            alert("Failed to Update! Please Refresh the page and try again.");
        }
    }

    const saveDepartment = (newDept, percentAppointment) => {
        setAccordionExpand(true);
        setAddModalShow(false);
        setEditModalShow(false);
        let updatedAuthorDepartments = { ...authorDepartments }
        updatedAuthorDepartments[newDept] = percentAppointment;
        setAuthorDepartments(updatedAuthorDepartments);
        editHandler(updatedAuthorDepartments);
    };

    const editDepartment = (dept) => {
        setSelectedDepartment([dept, authorDepartments[dept]]);
        setEditModalShow(true);
    };

    const deleteDepartment = () => {
        const updatedAuthorDepartments = Object.keys(authorDepartments).filter(key =>
            key !== selectedDepartment[0]).reduce((obj, key) => {
                obj[key] = authorDepartments[key];
                return obj;
            }, {}
            );
        setAuthorDepartments(updatedAuthorDepartments);
        setSelectedDepartment([]);
        setEditModalShow(false);
        editHandler(updatedAuthorDepartments);
    };

    return (
        <Grid container xs={20} alignItems="center" justify="center" className={profileClassses.grid}>
            <Accordion square className={profileClassses.accordian} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary className={profileClassses.accordian_summary}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Row className={classes.header}>
                        <Col sm={3} md={3}>
                            <h3 style={{ marginTop: "10px" }}>Departments</h3>
                        </Col>
                        {profileUpdateState === true ? (
                            <Col md={8} style={{ color: 'gray', textAlign: 'right' }}>
                                <span style={{ cursor: "pointer" }}>
                                    <LightTooltip title="Click to add Department." placement="bottom">
                                        <AddCircleIcon style={{ width: "1.2em", height: "1.2em", color: addColor, marginLeft: "746px", marginTop: "9px", }} onClick={() => setAddModalShow(true)}></AddCircleIcon>
                                    </LightTooltip>
                                </span>
                            </Col>
                        ) : (
                            <Col sm={1} md={1}></Col>
                        )}
                    </Row>
                </AccordionSummary>

                <Container className={classes.container}>

                    {
                        authorDepartments !== undefined &&
                        Object.keys(authorDepartments).sort().map((dept, index) => {
                            return (
                                [
                                    <Row className={classes.subheaderFirst}>
                                        <Col md={11}>
                                            <span className={classes.standardSize}>
                                                {
                                                    (!dept) ? null :
                                                        dept + ' - ' + parseFloat(authorDepartments[dept]).toFixed(2) + '%'
                                                }
                                            </span>
                                        </Col>
                                        <Col md={1}>
                                            {
                                                profileUpdateState === true ?
                                                    <span style={{ cursor: "pointer" }}>
                                                        <LightTooltip title="Click to edit Department." placement="bottom">
                                                            <EditIcon style={{ width: '1em', height: '1em', color: dangerColor }} name={dept} onClick={() => editDepartment(dept)} />
                                                        </LightTooltip>
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </Col>
                                    </Row>
                                ]
                            )
                        })
                    }

                    <AddDepartmentModal
                        data={props.data}
                        selectedDepartmentsList={authorDepartments !== undefined ? Object.keys(authorDepartments) : []}
                        isNew={true}
                        show={addModalShow}
                        totalPercentAppointment={authorDepartments !== undefined ? Object.values(authorDepartments).reduce((a, b) => a + b, 0) : 0}
                        onSave={saveDepartment}
                        onHide={() => { setAddModalShow(false); setAccordionExpand(true) }} />
                    <AddDepartmentModal
                        data={props.data}
                        selectedDepartmentsList={authorDepartments !== undefined ? Object.keys(authorDepartments) : []}
                        selectedDepartment={selectedDepartment}
                        isNew={false}
                        show={editModalShow}
                        totalPercentAppointment={authorDepartments !== undefined ? Object.values(authorDepartments).reduce((a, b) => a + b, 0) - selectedDepartment[1] : 0}
                        onSave={saveDepartment}
                        onHide={() => setEditModalShow(false)}
                        onDeleteDept={deleteDepartment} />
                </Container>
            </Accordion>
        </Grid>
    );
}

export default Departments;