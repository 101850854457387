import React from 'react';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import { ThemeProvider } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Theme from '../../../../constants/Theme';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const DeleteFacultyWarning = props => {
    const theme = Theme();
    const primaryColor='#555559';
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }))(Tooltip);
    const deleteFaculty =()=>{
        props.onYes(props.currentFacultyIndex)
    }

    return (
      <ThemeProvider theme={theme}>
        <Modal
          {...props}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter2">
              Confirm remove
              <span>
              <LightTooltip title="Remove the faculty name if they are no longer affiliated to your University" placement="top">
                 <InfoIcon style={{marginLeft:"10px",marginTop: "0px",color:primaryColor}}  />
                 </LightTooltip>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container >
                <Row md={12}>
                    Are you sure you want to remove this faculty?
                  </Row>
           </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button  variant="contained" style={{color:'#FFFFFF',backgroundColor:primaryColor}} onClick={deleteFaculty}>Yes</Button>
            <Button  variant="contained" style={{color:'#FFFFFF',backgroundColor:primaryColor}} onClick={()=>props.onNo()}>No</Button>
          </Modal.Footer>
        </Modal>
        </ThemeProvider>
      );

}

export default DeleteFacultyWarning;