import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    authorSearchResult: [],
    paperSearchResult: []
}

const exploreReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTHOR_SEARCH:
            return updateObject(state, { authorSearchResult: action.searchResult.authors });

        case actionTypes.PAPER_SEARCH:
            let finalResult = []
            console.log(action)
            for (let paper of action.searchResult.authors) {
                console.log(paper)
                let resultString = "";
                for (let author of paper.authors) {
                    resultString += author.last_name + " " + author.first_name + "; ";
                }
                resultString += paper.title + ". " + paper.journal;
                finalResult.push(resultString);
            }
            console.log(finalResult);
            return updateObject(state, { paperSearchResult: finalResult });
        case actionTypes.CLEAR_EXPLORE:
            return updateObject(state, {
                authorSearchResult: [],
                paperSearchResult: []
            })
        default:
            return state
    }
}

export default exploreReducer;