import React, { useEffect, useState } from "react";
import classes2 from "./redflags.module.css";
import { Button, Container, Row, Col } from "react-bootstrap";
import SingleSelectAutoComplete from "../../../InputComponents/SingleSelectAutoComplete";
import { BACKEND_URL } from "../../../../utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../../../../assets/Images/loading.gif";
import MultiSelect from "../../../InputComponents/MultiSelect";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as redflagActions from '../../../../store/actions/redflag';

function FacultyWithMissingPosition() {
    const [showSpinner, setShowSpinner] = useState(false);
    const [searched, setSearched] = useState(false);
    const [selectedUniversities, setSelectedUniversities] = useState([]); // Final List of Universities
    const [universityOptions, setUniversityOptions] = React.useState([]);
    const [universityDropdownSelected, setuniversityDropdownSelected] =
        React.useState([]); // Maintains the structure to work with MultiSelect Autocomplete Component
    const history = useHistory();
    const dispatch = useDispatch();
    const authors = useSelector(state => state.redflags.authorSearchResult);
    const activeFlag = useSelector(state => state.redflags.authorWithMissingPosition);

    const getUniversityOptions = async (token) => {
        let url = BACKEND_URL + "get_universities/completed";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData);
            alert("Unable to fetch Universities Data!!!");
        } else {
            const response_data = await response.json();
            let createUniversityOptions = [];
            for (let i in response_data) {
                createUniversityOptions.push({
                    univ_id: response_data[i]['_id'],
                    label: response_data[i].name,
                    value: response_data[i].name,
                });
            }
            setUniversityOptions(createUniversityOptions);
        }
    };

    const getResponse = async () => {
        setSearched(true);
        setShowSpinner(true);

        var unis = selectedUniversities;
        if (selectedUniversities.length == 0) {
            var allOptions = universityOptions;
            allOptions = allOptions.map((e) => e.univ_id).join(",");
            unis = allOptions;
            setSelectedUniversities([...selectedUniversities, ...allOptions]);
        }

        const url =
            BACKEND_URL +
            "red_flag/faculty_with_missing_position?" +
            "university_id=" +
            unis;
        const token = localStorage.getItem("rl_token_a");
        try {
            await dispatch(redflagActions.authorSearch(token, url));
        }
        catch (error) {
            alert("Internal Server Error. Please try again after some time");
        }
        setShowSpinner(false);
    };

    const handleClick = () => {
        getResponse();
    };

    const handleUniversityToggleOption = (universityDropdownSelected) => {
        setuniversityDropdownSelected(
            universityDropdownSelected.sort(function (a, b) {
                if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
                if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
                return 0;
            })
        );
        dropdownSelectedUniversityToArray(universityDropdownSelected);
    };

    const handleUniversityClearOptions = () => {
        setuniversityDropdownSelected([]);
        dropdownSelectedUniversityToArray([]);
    };

    const handleUniversitySelectAll = (isSelected) => {
        if (isSelected) {
            setuniversityDropdownSelected(
                universityOptions.sort(function (a, b) {
                    if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
                    if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
                    return 0;
                })
            );
            dropdownSelectedUniversityToArray(universityOptions);
        } else {
            handleUniversityClearOptions();
        }
    };

    const dropdownSelectedUniversityToArray = (updateUniversity) => {
        let selectedUniversityArray = [];
        for (let i in updateUniversity) {
            selectedUniversityArray.push(updateUniversity[i].univ_id);
        }
        setSelectedUniversities(selectedUniversityArray);
    };

    const editProfile = (id) => {
        localStorage.setItem('coauthorid', id);

        history.push({
            pathname: '/dashboard/profileEdit',
            state: { coauthorid: id }
        })

    }
    useEffect(() => {
        const token = localStorage.getItem("rl_token");

        getUniversityOptions(token);
    }, []);

    return (
        <div>
            <div className={classes2.formCard}>
                <Container>
                    <Row md={12} className={classes2.row}>
                        <Col md={2}></Col>
                        <Col md={4}>
                            <MultiSelect
                                items={universityOptions}
                                getOptionLabel={(option) => `${option.label}`}
                                getOptionDisabled={(option) =>
                                    (option.value === universityOptions[0]) !== undefined
                                        ? universityOptions[0].value
                                        : ""
                                }
                                selectedValues={universityDropdownSelected}
                                label="University"
                                placeholder="Select University"
                                selectAllLabel="Select all"
                                onToggleOption={handleUniversityToggleOption}
                                onClearOptions={handleUniversityClearOptions}
                                onSelectAll={handleUniversitySelectAll}
                                limitTags={10}
                                disablePortal={false}
                            />
                        </Col>
                        <Col md={4}>
                            <Button
                                variant="contained"
                                className="searchButton"
                                color="primary"
                                onClick={(e) => handleClick()}
                            >
                                Click to Search
                            </Button>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    {
                        searched === true && showSpinner === true &&
                        <div style={{ textAlign: "center" }}>
                            <img src={Loading} alt="Loading..." width="80px" height="80px" />
                        </div>
                    }
                    {
                        ((searched === true && showSpinner === false) || authors !== 'Initial State') &&
                        <Row md={12} className={classes2.row}>
                            <Col md={2}></Col>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Author Name </TableCell>
                                            <TableCell align="right">University</TableCell>
                                            <TableCell align="right">University Profile URL</TableCell>

                                            <TableCell align="right">ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {authors.map((author) => (
                                            <TableRow
                                                key={author["_id"]["$oid"]}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >

                                                <TableCell
                                                    onClick={() => editProfile(author["_id"]["$oid"])}
                                                    className={classes2.pointer}
                                                    component="th"
                                                    scope="row">
                                                    {author.name}
                                                </TableCell>
                                                <TableCell align="right">{author.univ_name}</TableCell>
                                                <TableCell align="right">
                                                    <a href={author["univ_profile_link"]}>Link</a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {author["_id"]["$oid"]}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Col md={2}></Col>
                        </Row>
                    }
                </Container>
            </div>
        </div>
    )
}

export default FacultyWithMissingPosition;