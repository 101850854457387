import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    similarAuthor: []
}

const similarAuthorSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIMILAR_AUTHOR_SEARCH:
            return updateObject(state, { similarAuthor: action.searchResult });
        case actionTypes.CLEAR_SIMILAR_AUTHOR_SEARCH:
            return updateObject(state, {
                similarAuthor: []
            })
        default:
            return state
    }
}

export default similarAuthorSearchReducer;