import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    perceptualMapData: []
}

const bubbleColors = ['#BBB218', '#00259A', '#E24301', '#F6A016']
const perceptualMapReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PERCEPTUAL_MAP:
            {
                if (action.perceptualMapData.result === undefined || action.perceptualMapData.result.length === 0) {
                    return updateObject(state, { perceptualMapData: action.perceptualMapData.result });
                }
                var results = action.perceptualMapData.result;

                var data = []
                var colorsArr = []
                for (let i = 0; i < results.length - 1; i++) {
                    colorsArr.push(results[i].y)
                }
                colorsArr = colorsArr.sort();
                let colorsMap = new Map()
                for (let i = 0; i < results.length - 1; i++) {
                    if (colorsMap.has(colorsArr[i])) {
                        var arr = colorsMap.get(colorsArr[i]);
                        arr.push(i);
                        colorsMap.set(colorsArr[i], arr);
                    } else {
                        var arr = []
                        arr.push(i);
                        colorsMap.set(colorsArr[i], arr);
                    }
                }
                for (let i = 0; i < results.length - 1; i++) {
                    var arr = colorsMap.get(results[i].y)
                    var Bubblecolor = bubbleColors[(arr[0]) % bubbleColors.length]
                    arr.pop();
                    colorsMap.set(results[i].y, arr);
                    data.push({
                        data: [[(results[i].x), (results[i].y), 20]],
                        name: (results[i].name),
                        displayValue: (results[i].displayValue),
                        value: (results[i].name),

                        color: Bubblecolor
                    }
                    );
                }
                Bubblecolor = bubbleColors[(arr[0]) % bubbleColors.length]
                data.push({
                    data: [[(results[results.length - 1].x), (results[results.length - 1].y), 20]],
                    name: (results[results.length - 1].name),
                    displayValue: (results[results.length - 1].displayValue),
                    value: (results[results.length - 1].name),
                    color: Bubblecolor
                }
                );

                return updateObject(state, { perceptualMapData: data });
            }
        case actionTypes.INVERT_AXES:
            {
                for (let data1 of action.perceptualMapData) {
                    let temp = data1["data"][0][0]
                    data1["data"][0][0] = data1["data"][0][1]
                    data1["data"][0][1] = temp

                }
                return updateObject(state, { perceptualMapData: action.perceptualMapData });
            }
        case actionTypes.CLEAR_COMPARE_SCHOOLS:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default perceptualMapReducer;