import React from 'react';
import { TextField, FormHelperText, FormControl } from '@material-ui/core';
import './InputComponents.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles';
const StyledAutoComplete = withStyles({
    inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
            paddingRight: "8px !important",
            paddingTop: "11px !important",
            borderRadius: "0px !important",
            backgroundColor: "#fff !important",
        },
    },

})(Autocomplete);
const SingleSelectDropDownIcon = props => {
    const {
        error,
        options,
        onInputChange,
        inputValue,
        label,
        helperText,
        required,
        defaultValue,
        variant
    } = props;
    return (
        <FormControl fullWidth size="small" variant="filled" error={error}>
            <StyledAutoComplete required fullWidth size="small" options={options} defaultValue={defaultValue ? defaultValue : ""}
                onInputChange={onInputChange} inputValue={inputValue} style={{ paddingRight: 0 }}
                renderInput={(params) => (
                    <TextField
                        required={required !== false}
                        error={error}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <ArrowDropDownIcon style={{ marginBottom: "auto" }} className="blackColor" />
                            ),
                            style:{paddingRight:"10px"},
                        }}
                        label={label} variant={variant ? variant : "filled"} />
                )}
            />
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

export default SingleSelectDropDownIcon;
