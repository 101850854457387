import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import '../FindYourFit/FindYourFit.css';
import { withStyles } from '@material-ui/core/styles';
import classes2 from './FindYourFit.module.css';
import * as findYourFitActions from '../../../../store/actions/findYourFit';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../assets/Images/loading.gif';
import uniqueId from 'uniqid';

const borderCSS = { borderRight: "1px solid #e0e0e0", padding: "10px", borderLeft: "1px solid #e0e0e0" };
const tooltipData = {
    "a": "Average percentage of A and A- papers out of total papers for all tenured and tenure-track faculty, based on our list of journals.",
    "soloCoauthor": "Average percentage of solo papers out of total papers for all tenured and tenure-track faculty, based on our list of journals.",
    "citationByPapers": "Total citations / number of papers",
    "averagePapersPerYear": "Average papers per year for a faculty / Total Faculty"
}
const titleMap = new Map();
titleMap.set("a_to_b_journals_ratio", "% of A A- out of total");
titleMap.set("solo_to_coauthored_papers_ratio", "Solo/Co-Author paper");
titleMap.set("citations_per_paper", "Citations by paper");
titleMap.set("avg_papers_per_year_per_faculty", "Avg papers/year/faculty");


const tooltipMap = new Map();
tooltipMap.set("a_journal_ratio", "A journal ratio");
tooltipMap.set("avg_papers_per_year_per_faculty", "Average papers/year/faculty");
tooltipMap.set("citations_per_paper", "Citations per paper");
tooltipMap.set("solo_papers_ratio", "Solo paper ratio");

const FindYourFit = () => {
    const dispatch = useDispatch();

    const [showSpinner, setShowSpinner] = React.useState(false);
    const data = useSelector(state => state.findYourFit.findYourFitResult);
    const profile = useSelector(state => state.profile.profile);
    const universityNameMap = useSelector(state => state.findYourFit.universityNameMap);

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setShowSpinner(true);
        const token = localStorage.getItem('rl_token');
        try {
            await dispatch(findYourFitActions.findYourFit(token));
            setShowSpinner(false);
        } catch (error) {
            setShowSpinner(false);
        }
    }

    const getUniversityNameCSS = (index, data) => {
        if (index < 2 && data !== "N/A")
            return "red universityNamePadding universityHover cellStyle";
        else if (index < 4 && data !== "N/A")
            return "orange universityNamePadding universityHover cellStyle";
        else if (index < 7 && data !== "N/A")
            return "green universityNamePadding universityHover cellStyle";
        else if (data === "N/A")
            return "na universityNamePadding cellStyle"
    }
    const getUniversityNameTooltipText = (data, col, index, univ) => {
        let univName = univ in universityNameMap ? universityNameMap[univ] : univ;
        var tootltipString = "";
        if (index < 2) {
            tootltipString += univName;
            tootltipString += ": Ambitious \n";
        }
        else if (index < 4) {
            tootltipString += univName;
            tootltipString += ": Moderate \n";
        }
        else {
            tootltipString += univName;
            tootltipString += ": Safe \n";
        }
        Object.keys(data[col][index]).map((key) => {
            if (key !== "name") {
                tootltipString += tooltipMap.get(key);
                tootltipString += ": ";
                tootltipString += parseFloat(data[col][index][key]).toFixed(2);
                tootltipString += "\n";
            }
        })
        return tootltipString;
    }


    let dataLength = 7;
    const facultySymbolAt = Math.ceil(dataLength / 2);
    let universityFit = []
    for (let universityIndex = 0; universityIndex < dataLength; universityIndex++) {
        universityFit.push(<TableRow key={universityIndex} />);

        Object.keys(data).map((column, index) => {
            universityFit.push(
                <TableCell align="center" key={uniqueId()} style={borderCSS}>
                    {facultySymbolAt === universityIndex ?
                        <LightTooltip title={profile.first_name ? `${profile.first_name} ${profile.last_name}` : "Yourself"} placement="left" arrow>
                            <EmojiPeopleIcon classes={{ root: classes2.iconColor }} />
                        </LightTooltip>
                        :
                        data[column][universityIndex].name === "N/A" ?
                            <div className={getUniversityNameCSS(universityIndex, data[column][universityIndex].name)}>
                                {data[column][universityIndex].name}
                            </div>
                            :
                            <LightTooltip title={<span style={{ whiteSpace: 'pre-line', width: "25em", textAlign: "center", display: "inline-block", paddingRight: "0px", margin: "0px" }}>{getUniversityNameTooltipText(data, column, universityIndex, data ? (data[column] ? (data[column][universityIndex].name) : undefined) : undefined)}</span>} placement="top" arrow>
                                <div className={getUniversityNameCSS(universityIndex, data ? (data[column] ? (data[column][universityIndex].name) : undefined) : undefined)}>
                                    {data ? (data[column] ? (data[column][universityIndex].name) : undefined) : undefined}
                                </div>
                            </LightTooltip>
                    }
                </TableCell>
            );
        });
    }
    return (
        <div className={classes2.cardContainer}>
            <div className="featureCard">
                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                {!showSpinner && true &&
                    <TableContainer /*component={Paper}*/>
                        <Table style={{ border: 'none' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={borderCSS}>
                                        <span className={classes2.standardSizeBold}>
                                            <LightTooltip title={tooltipData["a"]} placement="top" arrow>
                                                <span className="headerHover">Percent of A & A- out of total </span>
                                            </LightTooltip>
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" style={borderCSS}>
                                        <span className={classes2.standardSizeBold}>
                                            <LightTooltip title={tooltipData["soloCoauthor"]} placement="top" arrow>
                                                <span className="headerHover">Solo/Co-Author Papers</span>
                                            </LightTooltip>
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" style={borderCSS}>
                                        <span className={classes2.standardSizeBold}>
                                            <LightTooltip title={tooltipData["citationByPapers"]} placement="top" arrow>
                                                <span className="headerHover">Citation by papers</span>
                                            </LightTooltip>
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" style={borderCSS}>
                                        <span className={classes2.standardSizeBold}>
                                            <LightTooltip title={tooltipData["averagePapersPerYear"]} placement="top" arrow>
                                                <span className="headerHover">Average papers per year per faculty</span>
                                            </LightTooltip>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {universityFit}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
        </div>
    );
}

export default FindYourFit;