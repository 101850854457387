import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../constants/Theme';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import { Autocomplete } from '@material-ui/lab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
const Verification = props => {
    const [formElements,setFormElements] = React.useState({formData: { email: '', password:'', previousAffiliations:[],currentAffiliations:[],publications:[], gender:'female'}, submitted:false, percentage:80})

    const handleChange = (event) => {
        const { formData, submitted, percentage } = formElements;
        formData[event.target.name] = event.target.value;
        setFormElements({formData:formData, submitted:submitted, percentage:percentage})
        console.log(formElements)
    }
     const handlePreviousAffiliations = (event,values) => {
         const { formData, submitted, percentage } = formElements;
         formData.previousAffiliations=values
         setFormElements({formData:formData, submitted:submitted, percentage:percentage})
         console.log(formData)
    };
     const handleCurrentAffiliations = (event,values) => {
         const { formData, submitted,percentage } = formElements;
         formData.currentAffiliations=values
         setFormElements({formData:formData, submitted:submitted, percentage:percentage})
         console.log(formData)
    };
     const handlePublications = (event,values) => {
         const { formData, submitted,percentage } = formElements;
         formData.publications=values
         setFormElements({formData:formData, submitted:submitted, percentage:percentage})
         console.log(formData)
    };
    const handleSubmit = () => {
        setFormElements({formData:formData, submitted: true, percentage: percentage})
        setTimeout(() => setFormElements({formData:formData, submitted: false, percentage:percentage}), 5000);
        console.log("Submitted")
    }
     const univList = [
        "University of Southern California - Viterbi School of Engineering",
        "University of Southern California - Marshall's School of Business",
        "University of California Los Angeles"
    ];
     const papers = [
        "Title of Paper 1",
        "Title of Paper 2",
        "Title of Paper 3"
    ];
    const theme = Theme();
    const { formData, submitted, percentage } = formElements;
    const Filler =(props)=>{
        var z=props.percentage;
        console.log(z);
        return (
        <div className="filler" style={{width: `${props.percentage}%`}}>
        </div>
        )
    }
    const ProgressBar =(props)=>{
            return (
            <div className="progress-bar" style={{backgroundColor:'white'}}>
            <Filler percentage={props.percentage}/>
            </div>
            )
    }
    
    return (
     <ValidatorForm
            onSubmit={handleSubmit}
            >
            <ThemeProvider theme={theme}>
            <div className="cardContainer">
                <div className="formCard card1">
                <Grid container spacing={3} style={{marginTop:13}}>
                <Grid item xs={3}>
                 <Grid container spacing={3} >
                 <Grid item xs = {12}>
                            <TextValidator id="outlined-basic2" 
                            name="fName" 
                            label="FirstName" 
                            variant="outlined" 
                            value={formData.fName}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            onChange={handleChange}/>
                        </Grid>
                <Grid item xs = {12}>
                            <TextValidator id="outlined-basic" 
                            name="middleName" 
                            label="MiddleName" 
                            variant="outlined" 
                            value={formData.middleName} 
                            onChange={handleChange}/>
                        </Grid>
                <Grid item xs = {12}>
                            <TextValidator id="outlined-basic" 
                             name="lastName" 
                             validators={['required']}
                             errorMessages={['this field is required']} 
                             label="LastName" 
                             variant="outlined" 
                             value={formData.lastName}
                             onChange={handleChange}/>
                        </Grid>
                 </Grid>
                </Grid>
                        <Grid item xs={3}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Gender</FormLabel>
                          <RadioGroup aria-label="gender" name="gender" value={formData.gender} onChange={handleChange}>
                            <FormControlLabel value="female" control={<Radio required={true}  />} label="Female" />
                            <FormControlLabel value="male" control={<Radio required={true} />} label="Male" />
                            <FormControlLabel value="other" control={<Radio required={true} />} label="Do not wish to disclose" />
                          </RadioGroup>
                        </FormControl>
                        </Grid>
                <Grid item xs = {6}>
                       <div>
                       <ProgressBar percentage={percentage} />
                       </div>
                        </Grid>
                        <Grid item xs={6}>
                         <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={univList}
                        name="previousAffiliations"
                        getOptionLabel={(option) => option}
                        onChange={handlePreviousAffiliations}
                        renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" icon={<PersonIcon />} label="Enter Previous Affiliations"  />
                        )}
                      />
                        </Grid>
                        
                        <Grid item xs={6}>
                         <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={univList}
                        name="currentAffiliations"
                        getOptionLabel={(option) => option}
                        onChange={handleCurrentAffiliations}
                        renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined"  label="Enter Current Affiliations"  />
                        )}
                      />
                        </Grid>   
                        <Grid item xs={12}>
                         <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={papers}
                        name="publications"
                        getOptionLabel={(option) => option}
                        onChange={handlePublications}
                        renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined"  label="Select your Publications"  />
                        )}
                      />
                        </Grid>
                        <Grid item xs={3} >
                          
                        </Grid>
                        
                        <Grid item xs = {3}>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    Login
                </Button>
                </Grid>
                
                </Grid>
                </div>
        </div>
                </ThemeProvider>
            </ValidatorForm>
    )
}

    export default Verification;