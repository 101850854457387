import axios from 'axios';
import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';
import * as profileActions from './profile';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
}
export const authSuccess = (token, user_type) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user_type: user_type
    };
}
export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
}
export const changeLogin = (token, changeLogin) => {
    return {
        type: actionTypes.ADMIN_CHANGE_LOGIN,
        token: token,
        user_type: "admin",
        changedLogin: changeLogin
    };
};
export const logOut = () => {
    localStorage.removeItem('rl_token');
    localStorage.removeItem('rl_expirationDate');
    localStorage.removeItem('rl_type');
    localStorage.removeItem('rl_userId');
    localStorage.removeItem('rl_token_a');
    //redirect user to login page.
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}
export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logOut());
        }, expirationTime)
    }
}
export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: email,
            password: password
        }
        axios.post(BACKEND_URL + 'login', authData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expires_in);
                localStorage.setItem('rl_token', response.data.access_token);
                localStorage.setItem('rl_expirationDate', expirationDate);
                localStorage.setItem('rl_type', response.data.type);
                localStorage.setItem('rl_userId', response.data.id);
                if (response.data.type === "admin") {
                    localStorage.setItem('rl_token_a', response.data.access_token);
                }
                dispatch(authSuccess(response.data.access_token, response.data.type));
                dispatch(checkAuthTimeOut(response.data.expires_in));
            })
            .catch(err => {
                dispatch(authFail(err));
            });

    }
}
export const adminAuthChangeLogin = (id, isUniversity, adminToken) => {
    return async dispatch => {
        const params = {
            "id": id,
            "isUniversity": isUniversity ? 1 : 0
        }
        let url = new URL(BACKEND_URL + `admin_login`)
        // url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + adminToken,
            },
            body: JSON.stringify(params),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(errorResData.message);
        }
        const credentials = await response.json();
        try {
            logOut();
            const expirationDate = new Date(new Date().getTime() + credentials.expires_in);
            localStorage.setItem('rl_token', credentials.access_token);
            localStorage.setItem('rl_expirationDate', expirationDate);
            localStorage.setItem('rl_type', credentials.type);
            localStorage.setItem('rl_userId', credentials.id);
            localStorage.setItem('rl_token_a', adminToken);
            if (isUniversity === true) {
                await dispatch(authSuccess(credentials.access_token, credentials.type));
            } else {
                await dispatch(authSuccess(adminToken, "admin"));
                await dispatch(changeLogin(adminToken, true));
                await dispatch(profileActions.get_author_profile(credentials.access_token, ''));
            }
            await dispatch(checkAuthTimeOut(credentials.expires_in));
        }
        catch (err) {
            dispatch(authFail(err));
        }

    }
}
export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('rl_token');
        if (!token) {
            dispatch(logOut());
        }
        else {
            const expirationDate = new Date(localStorage.getItem('rl_expirationDate'));
            const user_type = localStorage.getItem('rl_type');
            if (expirationDate <= new Date()) {
                dispatch(logOut());
            } else {
                dispatch(authSuccess(token, user_type));
                dispatch(checkAuthTimeOut(expirationDate.getTime() - new Date().getTime()))
            }

        }
    }
}
export const signup = (email, username, password, id, first_name, mid_name, last_name, univ_name, univ_id) => {

    return async (dispatch) => {
        const params = {
            email: email,
            username: username,
            password: password,
            author_id: id,
            new_author: id === "",
            first_name: first_name,
            mid_name: mid_name,
            last_name: last_name,
            univ_name: univ_name,
            univ_id: univ_id
        }
        let url = new URL(BACKEND_URL + `signup`)
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            // If email or username already exists, display error to user
            if (errorResData.message === "exists") {
                throw new Error('Email or Username already exists');
            } else {
                throw new Error('Something went wrong while doing cohort analysis with error: ' + errorResData);
            }
        }
        const signupResult = await response.json();
        if (signupResult.message === "Sign Up Successful") {
            const response1 = await fetch(BACKEND_URL + `verify_email?email=${email}`, {
                method: 'GET'
            });
            const verify_email = await response1.json();
            // if verification email api returns error, dispatch false
            if (verify_email.message === "Internal Server Error") {
                //verification email was not sent
                dispatch({ type: actionTypes.SIGN_UP, result: false });
            } else {
                dispatch({ type: actionTypes.SIGN_UP, result: signupResult.message === "Sign Up Successful" });
            }
        } else {
            dispatch({ type: actionTypes.SIGN_UP, result: false });
        }
    };
}
export const forgotPassword = (email) => {
    return async (dispatch) => {
        let url = new URL(BACKEND_URL + `reset_password?email=${email}`)
        const response = await fetch(url, {
            method: 'GET',
        });
        if (response.status !== 200) {
            dispatch({ type: actionTypes.FORGOT_PASSWORD, result: false });
            throw new Error('Something went wrong while resetting your password. Please try again later.');
        } else {
            dispatch({ type: actionTypes.FORGOT_PASSWORD, result: true });
        }
    };
}