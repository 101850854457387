import React, { useRef } from 'react';
import Theme from '../../../../constants/Theme';
import { ThemeProvider, lighten, withStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, FormControl, Paper, Input,
    Button, FormLabel, Switch, Slider, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { Container, Row, Col } from 'react-bootstrap';

import colors from '../../../../constants/RLcolors';
import PersonIcon from '@material-ui/icons/Person';
import '../FacultyProgress/FacultyProgress.css';
import classes2 from './FacultyProgress.module.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from '../../../../assets/Images/loading.gif';
import SingleSelect from '../../../InputComponents/SingleSelect';
import MultipleSelect from '../../../InputComponents/MultipleSelect';
import * as facultyProgressActions from '../../../../store/actions/facultyProgress';
import { BACKEND_URL, convertArrayToSummary } from '../../../../utils';
// const departmentOptions = [
//     "Marketing",
//     "Finance",
//     "Economics",
//     "Operations Management",
//     "Information Systems",
//     "Statistics"
// ];
// const journalNames = [
//     'journal 1', 'journal 2', 'journal 3', 'journal 4', 'journal 5', 'journal 6'
// ];
const aJournals = "A journals";
const aAndAminusJournals = "A and A- journals";
const allJournals = "All journals"
let journalNames = [];
const journalsTypeOptions = [
    aJournals, aAndAminusJournals, allJournals
];
const facultyCountOptions = [
    "10", "30", "All"
];
const useStyles = makeStyles({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    searchButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
    scrollButton: {
        color: "black !important",
        borderRadius: "0% !important",
        backgroundColor: "#BBB218 !important",
    },
});

const minYear = 1980;
const maxYear = new Date().getFullYear();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuPropsStyles = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const FacultyProgress = props => {

    const theme = Theme();
    const dispatch = useDispatch();
    const yourSchoolFacultyRef1 = useRef(null);
    const yourSchoolFacultyParentRef1 = useRef(null);
    const otherSchoolFacultyParentRef2 = useRef(null);
    const [switchChecked, setswitchChecked] = React.useState(false);
    const secondaryColor = theme.palette.secondary.main;
    const [selectedJournalName, setJournalName] = React.useState([]);
    const [journalsError, setJournalsError] = React.useState(false);
    const [department, setdepartment] = React.useState('');
    const [departmentError, setDepartmentError] = React.useState(false);
    const [facultyCount, setFacultyCount] = React.useState("");
    const [facultyCountError, setFacultyCountError] = React.useState(false);
    const [journalsType, setJournalsType] = React.useState("");
    const [journalsTypeError, setJournalsTypeError] = React.useState(false);
    const [viewRankBy, setViewRankBy] = React.useState('Papers');
    const [yearRange, setYearRange] = React.useState([maxYear - 5, maxYear]);
    const [universityId, setUniversityId] = React.useState("");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [journalsInfo, setJournalsInfo] = React.useState("");
    const profile = useSelector(state => state.profile.profile);
    const departmentOptions = useSelector(state => state.facultyProgress.departments);
    const departmentToJournalsDict = useSelector(state => state.facultyProgress.departmentToJournalsDict);
    const journalIdToNameDict = useSelector(state => state.facultyProgress.journalIdToNameDict);
    const defaultMappingsDict = useSelector(state => state.facultyProgress.defaultMappingsDict);
    const yourSchoolData = useSelector(state => state.facultyProgress.yourSchoolData);
    const otherSchoolData = useSelector(state => state.facultyProgress.otherSchoolData);
    const yourSchoolIndex = useSelector(state => state.facultyProgress.yourSchoolIndex);
    // to filter journal names based on dept/journal type selection
    if (department !== "" && journalsType !== "") {
        if (journalsType === aJournals) {
            journalNames = departmentToJournalsDict[department]["A"];
        }
        else if (journalsType === aAndAminusJournals) {
            journalNames = [...departmentToJournalsDict[department]["A"], ...departmentToJournalsDict[department]["A-"]];
        }
        else {
            journalNames = [...departmentToJournalsDict[department]["A"], ...departmentToJournalsDict[department]["A-"], ...departmentToJournalsDict[department]["B"]];
        }
    }
    const inlineClasses = useStyles();
    const BlueSwitch = withStyles({
        switchBase: {
            color: secondaryColor
        },
        track: {
            color: secondaryColor,
            backgroundColor: secondaryColor
        }
    })(Switch);

    React.useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = async () => {
        const token = localStorage.getItem('rl_token');
        let univId = ""
        if (profile.univ_id && profile.univ_id !== undefined) {
            univId = profile.univ_id;
        }
        else {
            const url = BACKEND_URL + 'auto/univ/' + profile.univ_name;
            const response = await fetch(url);
            const schools = await response.json();
            if (schools.universities.length > 0) {
                univId = schools.universities[0].univ_id;
            }
        }
        try {
            setUniversityId(univId);
            await dispatch(facultyProgressActions.getDepartments(token, univId));
        }
        catch (err) {
            console.log("cannot get departments of this university. Error: ", err);
        }
    }

    const handleDepartmentChange = (event, newInputValue) => {
        setJournalName([])
        setJournalsType("")
        setJournalsInfo("")

        setdepartment(newInputValue ? newInputValue : '');
        if (newInputValue && newInputValue !== '') {
            setDepartmentError(false);
        }
    };
    const handleFacultyCountChange = (event, newInputValue) => {
        setFacultyCount(newInputValue ? newInputValue : '');
        if (newInputValue && newInputValue !== '') {
            setFacultyCountError(false);
        }
    }
    const handleJournalsTypeChange = (event, newInputValue) => {
        setJournalName([])
        setJournalsInfo("")
        setJournalsType(newInputValue ? newInputValue : '');
        setJournalsTypeError(newInputValue ? false : true);
    }
    const handleJournalChange = (event) => {
        setJournalName(event.target.value);
        let JouralNamesIDs = event.target.value
        let JouralNames = []
        for (let i = 0; i < JouralNamesIDs.length; i++) {
            JouralNames[i] = journalIdToNameDict[JouralNamesIDs[i]];
        }
        setJournalsInfo(convertArrayToSummary(JouralNames));
        if (event.target.value.length !== 0) {
            setJournalsError(false)
        }
    };
    const handleSwitchChange = (event) => {
        if (event.target.name === 'Papers') {
            setswitchChecked(true);
        } else {
            setswitchChecked(false);
        }
        setViewRankBy(event.target.name === 'Citations' ? 'Papers' : 'Citations');
    }
    const handleYearSliderChange = (event, newValue) => {
        setYearRange(newValue);
    };
    const handleYearInputChange = (event,) => {
        let newYearRange = event.target.id === "from" ? [Number(event.target.value), yearRange[1]] : [yearRange[0], Number(event.target.value)];
        setYearRange(newYearRange);
    };
    const handleBlur = () => {
        if (yearRange[0] < minYear) {
            setYearRange([minYear, yearRange[1]]);
        } else if (yearRange[1] > maxYear) {
            setYearRange([yearRange[0], maxYear]);
        }
    };
    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }

    const scrollYourSchoolCurrentFaculty = () => {
        var myEle = document.getElementById('userLoc')
        var topPos = myEle.offsetTop;
        document.getElementById('userUni').scrollTop = topPos;
    }
    const scrollOtherSchoolCurrentFaculty = () => {
        var myEle = document.getElementById('userLoc')
        var topPos = myEle.offsetTop;
        document.getElementById('otherUni').scrollTop = topPos;

    }
    const search = async () => {
        if (checkValidity()) {
            const token = localStorage.getItem("rl_token");

            let journal_params = "";
            for (let journal of selectedJournalName) {
                journal_params += `${journal},`
            }
            journal_params = journal_params.slice(0, journal_params.length - 1);

            let default_depts_for_selected_dept = "";
            for (let defaultDept of defaultMappingsDict[department]) {
                default_depts_for_selected_dept += `${defaultDept},`
            }
            default_depts_for_selected_dept = default_depts_for_selected_dept.slice(0, default_depts_for_selected_dept.length - 1);

            setShowSpinner(true);
            try {
                setAccordionExpand(false);
                await dispatch(facultyProgressActions.facultyProgress(token, department, journal_params, yearRange[0], yearRange[1], viewRankBy.toLowerCase(), default_depts_for_selected_dept, universityId));
            }
            catch (e) {
                setAccordionExpand(true);
                console.log(e);
                alert("Internal Server Error. Please try again later.")
            }
            setShowSpinner(false);

        }
    }
    const checkValidity = () => {
        let isValid = true;
        if (department === "") {
            isValid = false;
            setDepartmentError(true);
        }
        if (journalsType === "") {
            isValid = false;
            setJournalsTypeError(true);
        }
        if (selectedJournalName.length === 0) {
            isValid = false;
            setJournalsError(true);
        }
        if (facultyCount === "") {
            isValid = false;
            setFacultyCountError(true);
        }
        return isValid;
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={inlineClasses.featureHeading}>
                    Faculty Progress
                </div>
                <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                    <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />}>
                        <div style={{ float: "left" }}>
                            {`Faculty progress of ${facultyCount ? facultyCount : "*Number of faculty*"} faculties in ${department ? department : "*Department*"} department for ${journalsInfo ? journalsInfo : "*Journals*"} journals from
                            ${yearRange[0]} - ${yearRange[1]} ranked by ${viewRankBy}`}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 25 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} id="department">
                                <SingleSelect error={departmentError} options={departmentOptions} onInputChange={handleDepartmentChange}
                                    inputValue={department} label="Department" helperText="Select a department" />
                            </Grid>
                            <Grid item xs={4} id="journalTypeSelect">
                                <SingleSelect disabled={department === ""} error={journalsTypeError} options={journalsTypeOptions} onInputChange={handleJournalsTypeChange}
                                    inputValue={journalsType} label="Journals type" helperText="Select journals type" />
                            </Grid>
                            <Grid item xs={4} id="journalSelect">
                                <MultipleSelect disabled={journalsType === ""} error={journalsError} onChange={handleJournalChange} options={journalNames}
                                    value={selectedJournalName} menuProps={MenuPropsStyles} label="Journals" helperText="Select journals to consider" displayDict={journalIdToNameDict} />
                            </Grid>
                            <Grid item container justify="center" alignItems="center" xs={4} id="noOfFaculty" style={{ textAlign: "left" }}>
                                <SingleSelect error={facultyCountError} options={facultyCountOptions} onInputChange={handleFacultyCountChange}
                                    inputValue={facultyCount} label="Number of faculty" helperText="Select number of faculty" />
                            </Grid>
                            <Grid item xs={4} id="yearRange" style={{ textAlign: "left" }}>
                                <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Year Range:</FormLabel>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={yearRange[0]} id="from" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }} value={yearRange} onChange={handleYearSliderChange} valueLabelDisplay="auto" min={minYear} max={maxYear} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={yearRange[1]} id="to" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} id="switch">
                                <FormControl fullWidth component="fieldset" style={{ textAlign: "left" }}>
                                    <FormLabel classes={{ root: classes2.labelroot }} fullWidth component="legend">View Rank By:</FormLabel>
                                    <span><span className={classes2.standardSize}>Papers </span> <BlueSwitch checked={switchChecked} size="small" color="secondary" onChange={handleSwitchChange} name={viewRankBy} /><span className={classes2.standardSize}>Citations </span></span>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4} id="search" container justify="center" alignItems="center">
                                <Button variant="contained" className={inlineClasses.searchButton} onClick={() => { search() }}>
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={4} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                {yourSchoolIndex !== -1 &&
                    <Paper square style={{ marginTop: "2%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} className="compareColumn">
                                <div className={classes2.standardSizeBold}>
                                    <span className="facultyItem" style={{ paddingLeft: "3%" }}>In your school</span>
                                    <Button variant="contained" className={inlineClasses.scrollButton} style={{ padding: "2px 10px", float: "right", marginRight: "4%" }} onClick={scrollYourSchoolCurrentFaculty}>Scroll to yourself</Button>

                                </div>
                                <hr />
                                <div>
                                    <Row>
                                        <Col>
                                            <span className={classes2.standardSizeBold} style={{ marginLeft: 55 }}>
                                                Name
                                            </span>
                                        </Col>
                                        <Col>
                                            <span className={classes2.standardSizeBold}>
                                                Total {viewRankBy}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                                {!switchChecked ?
                                    <div id='userUni' ref={yourSchoolFacultyParentRef1} className="facultyData">
                                        <span >
                                            {
                                                yourSchoolData.sort((a, b) =>
                                                    b.total_papers - a.total_papers
                                                ).map((faculty, index) => (

                                                    <div className="facultyContainer" key={index} ref={index === yourSchoolIndex ? yourSchoolFacultyRef1 : null}>
                                                        <div className="facultyItem">

                                                            <Row>
                                                                <Col>
                                                                    {index !== yourSchoolIndex ?
                                                                        <span className={classes2.standardSize}>
                                                                            {faculty['name']}
                                                                        </span>
                                                                        :
                                                                        <span id='userLoc' className={classes2.standardSize}>
                                                                            {<PersonIcon />}
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <span className={classes2.standardSize}>
                                                                        {faculty['total_papers']}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </span>
                                    </div>
                                    :
                                    <div ref={yourSchoolFacultyParentRef1} className="facultyData">
                                        <span >
                                            {
                                                yourSchoolData.sort((a, b) =>
                                                    b.total_citations - a.total_citations
                                                ).map((faculty, index) => (

                                                    <div className="facultyContainer" key={index} ref={index === yourSchoolIndex ? yourSchoolFacultyRef1 : null}>
                                                        <div className="facultyItem">
                                                            <Row>
                                                                <Col>
                                                                    {index !== yourSchoolIndex ?
                                                                        <span className={classes2.standardSize}>
                                                                            {faculty['name']}
                                                                        </span>
                                                                        :
                                                                        <span id='userLoc' className={classes2.standardSize}>
                                                                            {<PersonIcon />}
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <span className={classes2.standardSize}>
                                                                        {faculty['total_citations']}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </span>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={6} className="compareColumn">
                                <div className={classes2.standardSizeBold}>
                                    <span className="facultyItem" style={{ paddingLeft: "3%" }}>In other schools</span>
                                    <Button variant="contained" className={inlineClasses.scrollButton} style={{ padding: "2px 10px", float: "right", marginRight: "4%" }} onClick={scrollOtherSchoolCurrentFaculty}>Scroll to yourself</Button>
                                </div>
                                <hr />
                                <div>
                                    <Row>
                                        <Col>
                                            <span className={classes2.standardSizeBold} style={{ marginLeft: 55 }}>
                                                Name
                                            </span>
                                        </Col>
                                        <Col>
                                            <span className={classes2.standardSizeBold}>
                                                University
                                            </span>
                                        </Col>
                                        <Col>
                                            <span className={classes2.standardSizeBold}>
                                                Total {viewRankBy}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                                {!switchChecked ?
                                    <div id='otherUni' ref={otherSchoolFacultyParentRef2} className={classes2.facultyData}>
                                        <span >
                                            {
                                                otherSchoolData.sort((a, b) =>
                                                    b.total_papers - a.total_papers
                                                ).map((faculty, index) => (

                                                    <div className="facultyContainer" key={index} ref={index === yourSchoolIndex ? yourSchoolFacultyRef1 : null}>
                                                        <div className="facultyItem">
                                                            <Row>
                                                                <Col>
                                                                    {index !== yourSchoolIndex ?
                                                                        <span className={classes2.standardSize}>
                                                                            {faculty['name']}
                                                                        </span>
                                                                        :
                                                                        <span id='userLoc' className={classes2.standardSize}>
                                                                            {<PersonIcon />}
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <span className={classes2.standardSize}>
                                                                        {faculty['univ_abv']}
                                                                    </span>
                                                                </Col>
                                                                <Col>
                                                                    <span className={classes2.standardSize}>
                                                                        {faculty['total_papers']}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </span>
                                    </div>
                                    :
                                    <div ref={yourSchoolFacultyParentRef1} className="facultyData">
                                        <span >
                                            {
                                                otherSchoolData.sort((a, b) =>
                                                    b.total_citations - a.total_citations
                                                ).map((faculty, index) => (

                                                    <div className="facultyContainer" key={index} ref={index === yourSchoolIndex ? yourSchoolFacultyRef1 : null}>
                                                        <div className="facultyItem">
                                                            <Row>
                                                                <Col>
                                                                    {index !== yourSchoolIndex ?
                                                                        <span className={classes2.standardSize}>
                                                                            {faculty['name']}
                                                                        </span>
                                                                        :
                                                                        <span id='userLoc' className={classes2.standardSize}>
                                                                            {<PersonIcon />}
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <span className={classes2.standardSize}>
                                                                        {faculty['total_citations']}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </span>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </div>
        </ThemeProvider >
    );
}
export default FacultyProgress;