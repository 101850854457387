import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import TuneIcon from '@material-ui/icons/Tune';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PublishIcon from '@material-ui/icons/Publish';
import PersonAdd from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import MergeType from '@material-ui/icons/MergeType';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const adminPages = [
    {
        title: 'Author Search',
        href: '/dashboard/authorSearch',
        icon: <SearchIcon />
    },
    {
        title: 'Highlight CV',
        href: '/dashboard/resumeUpload',
        icon: <PublishIcon />
    },
    {
        title: 'Add Author',
        href: '/tools/addAuthor',
        icon: <PersonAdd />
    },
    {
        title: 'Edit Author Tool',
        href: '/tools/editAuthor',
        icon: <EditIcon />
    },
    {
        title: 'Merge Author Tool',
        href: '/tools/mergeAuthorTool',
        icon: <MergeType />
    },
    {
        title: 'Manual Mapping',
        href: '/dashboard/manualmapping',
        icon: <RecentActorsIcon />
    },
    {
        title: 'Compute Fields',
        href: '/dashboard/UniversityStats',
        icon: <AssessmentOutlinedIcon/>
    },
    {
        title: 'Department Config',
        href: '/dashboard/DepartmentConfig',
        icon: <TuneIcon />
    },
    {
        title: 'RSS Feed Tool',
        href: '/dashboard/RSSFeedTool',
        icon: <ListAltIcon />
    },
    {
        title: 'Contact Us Support',
        href: '/dashboard/contactUsRequest',
        icon: <ContactSupportIcon />
    },
    {
        title: 'Red Flags',
        href: '/dashboard/redflags',
        icon: <GolfCourseIcon />
    },
    {
        title: 'Logs',
        href: '/logs',
        icon: <GolfCourseIcon />
    },
    {
        title: 'Change Login',
        href: '/dashboard/changeLogin',
        icon: <SupervisedUserCircleIcon />
    },
    {
        title: 'Update Faculty',
        href: '/dashboard/updateFaculty',
        icon: <SupervisedUserCircleIcon /> // TODO: Change icon
    }
];