import React, { useEffect, useState } from 'react';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import { Button, Paper, Grid, ListItem, ListItemAvatar, Avatar, Typography, List, ListItemText, Tooltip } from '@material-ui/core';
import * as updateFacultyActions from '../../../store/actions/updateFaculty';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Delete, Save } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_URL } from '../../../utils';
import Stack from '@mui/material/Stack';
import { CSVLink } from "react-csv";
import Loading from '../../../assets/Images/loading.gif';
const defaultFaculty = require('../../../assets/Images/default_faculty.png');

const useStyles = makeStyles(() => ({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    root: {
        overflow: 'auto',
        maxHeight: 560,
    }
}));

const UpdateFaculty = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const searchResult = useSelector(state => state.updateFaculty.searchResult);
    const facultyScrapeResult = useSelector(state => state.updateFaculty.facultyScrapeResult);
    const checkMarkerResult = useSelector(state => state.updateFaculty.checkMarkerResult);
    const compareFacultyResult = useSelector(state => state.updateFaculty.compareFacultyResult);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [type, setType] = React.useState('');
    const [universitySelectedFlag, setUniversitySelectedFlag] = React.useState(false);
    const [univId, setUnivId] = React.useState(sessionStorage.getItem('universityID') || '');
    const [university, setUniversity] = React.useState(sessionStorage.getItem('university') || '');
    const [universityOptions, setUniversityOptions] = React.useState([]);
    const [newMarker, setNewMarker] = React.useState({"id": null, "marker": null});
    const [csvData, setCsvData] = React.useState([]);

    useEffect(() => {
        const token = localStorage.getItem("rl_token");
        getUniversityOptions(token);

        if (univId !== '' && university !== '') {
            search(type);
            sessionStorage.clear();
        }
    }, [type]);

    const theme = createTheme({
        palette: {
            primary: { 500: '#BBB21E' }
        }
    });
    
    const csvHeaders = [
        { label: 'Name', key: 'scrapeName' },
        { label: 'Maker', key: 'scrapeMarker' },
        { label: 'Department', key: 'scrapeDepartment' },
        { label: 'Actions', key: 'actionName' },
        { label: 'System_Name', key: 'systemName' },
        { label: 'System_Maker', key: 'systemMarker' },
        { label: 'System_Department', key: 'systemDepartment' }
    ]

    const csvRefLink = React.useRef();

    const exists = (item) => {
        return item !== undefined && item !== null
    }

    const actionData = {
        0: "Add Name",
        1: "Remove Name",
        2: "Promoted",
        3: "Marker_Mismatch",
        4: "No Middle Name",
        5: "Name Variation",
        6: "No Action"
    }

    const createCSVData = async () => {
        if (compareFacultyResult.length > 0) {
            let tempData = []
            compareFacultyResult.map((item, key) => {
                if (item.length > 0) {
                    for (let obj in item) {
                        if (key === 0) {
                            let data = item[obj]
                            let tempElement = {
                                'scrapeName': exists(data.name) ? data.name : "",
                                'scrapeMarker': exists(data.marker) ? data.marker : "",
                                'scrapeDepartment': exists(data.department) ? data.department : "",
                                'actionName': actionData[key],
                                'systemName': "",
                                'systemMarker': "",
                                'systemDepartment': ""
                            };
                            tempData.push(tempElement);
                        } else if (key === 1) {
                            let data = item[obj]
                            let tempElement = {
                                'scrapeName': "",
                                'scrapeMarker': "",
                                'scrapeDepartment': "",
                                'actionName': actionData[key],
                                'systemName': exists(data.name) ? data.name : "",
                                'systemMarker': exists(data.marker) ? data.marker : "",
                                'systemDepartment': exists(data.department) ? data.department : ""
                            };
                            tempData.push(tempElement);
                        } else {
                            let scrapeData = item[obj][0];
                            let systemData = item[obj][1];
                            let tempElement = {
                                'scrapeName': exists(scrapeData.name) ? scrapeData.name : "",
                                'scrapeMarker': exists(scrapeData.marker) ? scrapeData.marker : "",
                                'scrapeDepartment': exists(scrapeData.department) ? scrapeData.department : "",
                                'actionName': actionData[key],
                                'systemName': exists(systemData.name) ? systemData.name : "",
                                'systemMarker': exists(systemData.marker) ? systemData.marker : "",
                                'systemDepartment': exists(systemData.department) ? systemData.department : ""
                            };
                            tempData.push(tempElement);
                        }
                    }
                }
            });
            setCsvData(tempData);
            setTimeout(() => {
                csvRefLink.current.link.click();
            });
        }
    }

    const getUniversityOptions = async (token) => {
        let url = BACKEND_URL + "get_universities/completed"
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            alert("Unable to fetch Universities Data!!!")
        }
        else {
            const response_data = await response.json();
            setUniversityOptions(response_data);
        }
    }

    const onUniversityChange = (...params) => {
        let selectedUnivData = params[3].option;
        setUniversity(selectedUnivData.name);
        if (selectedUnivData._id !== '') {
            setUnivId(selectedUnivData._id);
        }
    }

    const search = async (type) => {
        const token = localStorage.getItem("rl_token");
        if (univId !== '') {
            setShowSpinner(true);
            setUniversitySelectedFlag(false);
            try {
                await dispatch(updateFacultyActions.updateFacultySearch(token, univId, type));
                setUniversitySelectedFlag(true);
                setType(type);
                setShowSpinner(false);
            }
            catch (error) {
                setShowSpinner(false);
                alert("Internal Server Error. Please try again after some time");
            }
        } else {
            alert("Please select a university");
        }
    }

    const handleCheckMarkerChange = async (author_id, type) => {
        const token = localStorage.getItem("rl_token");
        
        if ((newMarker.id === author_id && newMarker.marker !== null) || type === 'delete_faculty') {
            const data = {'author_id': author_id["$oid"]}

            if (type === 'update_marker') {
                data['new_marker'] = newMarker.marker;
                data['type'] = 'update_marker';
            } else {
                data['type'] = 'delete_faculty';
            }

            if ((newMarker !== "" && type === 'update_marker') || type === 'delete_faculty') {
                try {
                    const response = await fetch(BACKEND_URL + `update_faculty`, {
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data),
                    });

                    if (!response.ok) {
                        const responseData = await response.json()
                        if (responseData.type === "update_marker")
                            alert('Failed to update marker.');
                        else
                            alert('Failed to delete faculty.');
                    } else {
                        const responseData = await response.json()
                        if (responseData.type === "update_marker")
                            alert('Marker Updated!');
                        else
                            alert('Faculty Deleted!');
                    }
                }
                catch (error) {
                    alert("Internal Server Error. Please try again after some time");
                }
            }
        } else {
            alert('Marker needed or different author!');
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={classes.featureHeading}>
                    Update Faculty
                </div>
                <Paper square style={{ padding: "30px 10px 17px 10px" }}>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={2} />
                        <Grid item xs={8} >
                            <Autocomplete
                                onChange={onUniversityChange}
                                value={university}
                                // limitTags={3}
                                id="checkboxes-tags-demo"
                                options={universityOptions}
                                getOptionLabel={(option) => {
                                    if (option.hasOwnProperty('name')) {
                                        return option.name;
                                    }
                                    return option;
                                }}
                                fullWidth={true}
                                disableClearable
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="University" placeholder="Select university" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={3} container direction="column" justifyContent="center" alignItems="center">
                            <Button variant="contained" style={{ color: "#000000", width: "100%", backgroundColor: "#BBB218", borderRadius: "0%" }} color="primary" onClick={() => search("search")}>Search</Button>
                        </Grid>
                        <Grid item xs={3} container direction="column" justifyContent="center" alignItems="center">
                            <Button variant="contained" style={{ color: "#000000", width: "100%", backgroundColor: "#BBB218", borderRadius: "0%" }} color="primary" onClick={() => search("scrape")}>Scrape</Button>
                        </Grid>
                        <Grid item xs={3} container direction="column" justifyContent="center" alignItems="center">
                            <Button variant="contained" style={{ color: "#000000", width: "100%", backgroundColor: "#BBB218", borderRadius: "0%" }} color="primary" onClick={() => search("check")}>Check Markers</Button>
                        </Grid>
                        <Grid item xs={3} container direction="column" justifyContent="center" alignItems="center">
                            <Button variant="contained" style={{ color: "#000000", width: "100%", backgroundColor: "#BBB218", borderRadius: "0%" }} color="primary" onClick={() => search("compare")}>Compare</Button>
                        </Grid>
                    </Grid>
                </Paper>
                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                {universitySelectedFlag ?
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            {type === "search" && searchResult.length > 0 ?
                            <div>
                                <div style={{ padding: "20px 0px" }}>
                                    Last scraped on - {searchResult[0].last_scrape_date} <br></br>
                                    {searchResult.length} results found
                                </div>
                                <Paper>
                                    <div style={{ backgroundColor: "white" }}>
                                        <List className={classes.root}>
                                            {searchResult.map((faculty, i) =>
                                                <ListItem divider key={i}>
                                                    <ListItemAvatar style={{ minWidth: 30 }}>
                                                        {i + 1 + "."}
                                                    </ListItemAvatar>
                                                    <ListItemAvatar style={{ paddingRight: 30 }}>
                                                        {faculty.profile_picture !== undefined && faculty.profile_picture !== "" && faculty.profile_picture !== null ?
                                                            (<Avatar alt={faculty.name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                            :
                                                            (<Avatar style={{ height: 75, width: 75 }}>
                                                                <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                            </Avatar>)
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText style={{ cursor: "pointer" }} primary={faculty.name}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography component="div" variant="body2" color="textPrimary">
                                                                    <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                                </Typography>
                                                                <Typography component="div" variant="body2" color="textPrimary">
                                                                    {
                                                                        ("position" in faculty) && faculty.position !== null &&
                                                                            faculty.position !== undefined && faculty.position.length > 0 ?
                                                                            faculty.position : null
                                                                    }
                                                                </Typography>
                                                                <Typography component="div" variant="body2" color="textPrimary">
                                                                    Marker: {faculty.marker !== null ? faculty.marker : 'N/A'}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            )}
                                        </List>
                                    </div>
                                </Paper>
                            </div>
                            :
                            type === "scrape" && facultyScrapeResult.length > 0 ?
                                <div>
                                    <div style={{ padding: "20px 0px" }}>
                                        {facultyScrapeResult.length} results found
                                    </div>
                                    <Paper>
                                        <div style={{ backgroundColor: "white" }}>
                                            <List className={classes.root}>
                                                {facultyScrapeResult.map((faculty, i) =>
                                                    <ListItem divider key={i}>
                                                        <ListItemAvatar style={{ minWidth: 30 }}>
                                                            {i + 1 + "."}
                                                        </ListItemAvatar>
                                                        <ListItemAvatar style={{ paddingRight: 30 }}>
                                                            {faculty.profile_picture !== undefined && faculty.profile_picture !== "" && faculty.profile_picture !== null ?
                                                                (<Avatar alt={faculty.name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                                :
                                                                (<Avatar style={{ height: 75, width: 75 }}>
                                                                    <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                                </Avatar>)
                                                            }
                                                        </ListItemAvatar>
                                                        <ListItemText style={{ cursor: "pointer" }} primary={faculty.name}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                                    </Typography>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        {
                                                                            ("position" in faculty) && faculty.position !== null &&
                                                                                faculty.position !== undefined && faculty.position.length > 0 ?
                                                                                faculty.position : null
                                                                        }
                                                                    </Typography>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        Marker: {faculty.marker !== null ? faculty.marker : 'N/A'}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </div>
                                    </Paper>
                                </div>
                                :
                                type === "check" && checkMarkerResult.length > 0 ?
                                <div>
                                    <div style={{ padding: "20px 0px" }}>
                                        {checkMarkerResult.length} results found
                                    </div>
                                    <Paper>
                                        <div style={{ backgroundColor: "white" }}>
                                            <List className={classes.root}>
                                                {checkMarkerResult.map((faculty, i) =>
                                                    <ListItem divider key={i}>
                                                        <ListItemAvatar style={{ minWidth: 30 }}>
                                                            {i + 1 + "."}
                                                        </ListItemAvatar>
                                                        <ListItemAvatar style={{ paddingRight: 30 }}>
                                                            {faculty.profile_picture !== undefined && faculty.profile_picture !== "" && faculty.profile_picture !== null ?
                                                                (<Avatar alt={faculty.name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                                :
                                                                (<Avatar style={{ height: 75, width: 75 }}>
                                                                    <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                                </Avatar>)
                                                            }
                                                        </ListItemAvatar>
                                                        <ListItemText style={{ cursor: "pointer" }} primary={faculty.name}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                                    </Typography>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        {
                                                                            ("position" in faculty) && faculty.position !== null &&
                                                                                faculty.position !== undefined && faculty.position.length > 0 ?
                                                                                faculty.position : null
                                                                        }
                                                                    </Typography>
                                                                    <Typography component="div" variant="body2" color="textPrimary">
                                                                        Marker: {faculty.marker !== null && (faculty.marker === 0 || faculty.marker === 9) ? faculty.marker : ""}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {faculty.marker === 9 ?
                                                            <Stack direction="row" spacing={1}>
                                                                <TextField
                                                                    edge="end"
                                                                    size="small"
                                                                    variant="outlined" 
                                                                    label="New marker"
                                                                    style = {{width: 125}}
                                                                    onChange={(e) => {setNewMarker({"id": faculty._id, "marker": e.target.value})}}
                                                                />
                                                                <Tooltip title="Save New Marker">
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => {handleCheckMarkerChange(faculty._id, 'update_marker')}}
                                                                    variant="contained"
                                                                ><Save /></Button></Tooltip>
                                                                <Tooltip title="Delete Faculty">
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => {handleCheckMarkerChange(faculty._id, 'delete_faculty')}}
                                                                    variant="contained"
                                                                ><Delete /></Button></Tooltip>
                                                            </Stack>
                                                            :
                                                            <Stack direction="row" spacing={1}>
                                                                <TextField
                                                                    edge="end"
                                                                    size="small"
                                                                    variant="outlined" 
                                                                    label="New marker"
                                                                    style = {{width: 125}}
                                                                    onChange={(e) => {setNewMarker({"id": faculty._id, "marker": e.target.value})}}
                                                                />
                                                                <Tooltip title="Save New Marker">
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => {handleCheckMarkerChange(faculty._id, 'update_marker')}}
                                                                    variant="contained"
                                                                ><Save /></Button></Tooltip>
                                                            </Stack>
                                                        }
                                                    </ListItem>
                                                )}
                                            </List>
                                        </div>
                                    </Paper>
                                </div>
                                :
                                type === "compare" && compareFacultyResult.length > 0 ? 
                                <div>
                                    <Grid xs={12} item justifyContent='center' style={{ textAlign: 'center', width: '100%', alignItems: 'center', display: 'inline-flex', marginTop: '1em' }}>
                                        <CSVLink filename={university.replace(/ /g, "_") + '_Todo_List'} headers={csvHeaders} data={csvData} ref={csvRefLink} />
                                        <ThemeProvider theme={theme}>
                                            <Button variant="contained" color="primary" onClick={createCSVData}>
                                                Download Report
                                            </Button>
                                        </ThemeProvider>
                                    </Grid>
                                </div> 
                                :
                                <div>
                                    <div style={{ padding: "20px 0px" }}>
                                        No results found
                                    </div>
                                </div>
                            } 
                        </Grid>
                    </Grid>
                : null}
            </div>
        </ThemeProvider>
    );
}

export default UpdateFaculty;