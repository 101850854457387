import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import * as classes from './WorkHistory.module.css';
import { useDispatch, useSelector} from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../../constants/Theme';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import SingleSelectAutoComplete from '../../../../InputComponents/SingleSelectAutoComplete';
import { BACKEND_URL } from '../../../../../utils';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import * as email from '../../../../../store/actions/email';
import SinglePreFilledAutoComplete from '../../../../InputComponents/SinglePreFilledAutoComplete';



const AddUniversityWorkHistoryModal = props => {
    const theme = Theme();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const dispatch = useDispatch();
    const [isNew, setIsNew] = useState(true);
    const userType = useSelector(state => state.auth.user_type);

    const [isValidForm, setIsValidForm] = useState(false);

    const [university, setUniversity] = useState("");
    const [valueUniversity, setValueUniversity] = useState("");
    const [optionsUniversity, setOptionsUniversity] = React.useState([]);
    const [loadingUniversity, setLoadingUniversity] = React.useState(false);
    const [univId, setUnivId] = useState("");
    const [valueSchool, setValueSchool] = useState("");
    const [optionsSchool, setOptionsSchool] = React.useState([]);
    const [loadingSchool, setLoadingSchool] = React.useState(false);
    const [school, setSchool] = useState("");

    const token= useSelector(state => state.auth.token);

    const onInputUniversityChangeHandler = (event, newInputValue) => {
        setValueUniversity(newInputValue)
        universityChangeHandler(newInputValue, newInputValue)
        setLoadingUniversity(true)
        if (!newInputValue || newInputValue.trim() === "") {
            setLoadingUniversity(false)
            return;
        }
        const url = BACKEND_URL + 'auto/univ/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsUniversity(Object.keys(schools.universities).map((key) => schools.universities[key]));
            setLoadingUniversity(false)
        })();

    };

    const onInputSchoolChangeHandler = (event, newInputValue) => {
        setValueSchool(newInputValue)
        schoolChangeHandler(newInputValue, newInputValue)
        setLoadingSchool(true)
        if (!newInputValue || newInputValue.trim() === "") {
            setLoadingSchool(false)
            return;
        }
        const url = BACKEND_URL + 'auto/school/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            setOptionsSchool(Object.keys(schools.schools).map((key) => schools.schools[key]));
            setLoadingSchool(false)
        })();
    };

    const universityChangeHandler = (name, value) => {
        setUniversity(name);
        setUnivId(optionsUniversity.filter(uni => uni.name === name).map(uni => uni.univ_id)[0]);
        if (name && name.trim() !== "" && optionsUniversity.map(univ => univ.name).includes(name) === false) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
        
    };

    const schoolChangeHandler = (name, value) => {
        setSchool(name);
        if (name.trim() !== "" && university.trim() !== "") {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };


    

    const errorMessage = (isValidForm) => {
        if (isValidForm) {
            return "";
        } else {
            var message = "Incomplete form: ";
            if (valueSchool.trim() === "") {
                message += "\n Enter School ";
            }
            if (university.trim() === "") {
                message += "\n Enter University";
            }
            if (message === "Incomplete form: ") {
                setIsValidForm(true)
                return "";
            }
        }
        return message;
    };

   

    const addNewUnivRequest = async () => {
        const recipient = "researchloupe.team@gmail.com";
        const subject = "Request to Add University/School";
        const body = 
        `Hello Team RL,

I have a request to add a new university and school has been made. Name of the university is "${university}" and name of the school is ${valueSchool}.

Regards,
${props.profileState.last_name}, ${props.profileState.first_name}
Author Id: ${props.profileState._id}
        `;
        const emailData = {
            recipient: recipient,
            subject: subject,
            body: body
        };
        try {
            await dispatch(email.send_email(token, emailData));
            props.onHide("Success");
        } catch(error) {
            alert("Failed to send email. Please try again later.");
            props.onHide("Error");
        }
    };

  

    return (
        <ThemeProvider theme={theme}>
            <Modal
                {...props}
                dialogClassName={classes.modalAddPapers}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter2">
                        Add Your University
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container >
                        <Row md={12} className={classes.row}>
                            <Col md={12}>
                            <SinglePreFilledAutoComplete name="name" value={university}
                                    onInputChange={onInputUniversityChangeHandler}
                                    disabled={isNew !== true && userType !== 'admin'}
                                    inputValue={valueUniversity}
                                    loading={loadingUniversity}
                                    label="Select University"
                                    options={optionsUniversity.map(option => option.name)} />
                            </Col>
                        </Row>
                        <Row md={12} className={classes.row}>
                            <Col md={12}>
                                
                                    <SinglePreFilledAutoComplete name="name" value={school}
                                    onInputChange={onInputSchoolChangeHandler}
                                    disabled={isNew !== true && userType !== 'admin'}
                                    inputValue={valueSchool}
                                    loading={loadingSchool}
                                    label="Select School"
                                    options={optionsSchool.map(option => option.name)} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <LightTooltip title={errorMessage(isValidForm)} placement="bottom">
                        <span>
                            <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} disabled={!isValidForm} onClick={addNewUnivRequest}>Request to Add</Button>
                        </span>
                    </LightTooltip>
                    <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={() => props.onHide()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </ThemeProvider>
    );
};

export default AddUniversityWorkHistoryModal;