import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import * as classes from './Papers.module.css';
import { ThemeProvider } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Theme from '../../../../../constants/Theme';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from '../../../../../utils';

const DeleteWarningModal = props => {
    const theme = Theme();
    const primaryColor = '#555559';
    const token = useSelector(state => state.auth.token);
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const [curIndex, setIndex] = useState(-1)
    const [curId, setCurId] = useState(0)
    
    useEffect(() => {
        setIndex(props.curIndex)
        setCurId(props.curId)
    }, [props.curIndex, props.curId]);

    const deletepaper = () => {
        const editAuthor = props.isCoAuthor === true ? 'editauthor/' : '';
        const id = props.isCoAuthor === true ? `/${props.coAuthorId}` : '';
        const url = BACKEND_URL + editAuthor + 'papers' + id;
        (async () => {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        "paper_id": curId
                    }
                )
            });
            if (response.status !== 200) {
                alert("Something went wrong while deleting paper.")
            }
        })();
    };

    const deletePaper = () => {
        props.onYes(curIndex)
        deletepaper(props.curId)
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal
                {...props}
                dialogClassName={classes.myModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter2">
                        Confirm Remove
                        <span>
                            <LightTooltip title="Remove the paper if you are not an author. Once deleted, it cannot be directly retrieved back." placement="top">
                                <InfoIcon style={{ marginLeft: "10px", marginTop: "0px", color: primaryColor }} />
                            </LightTooltip>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container >
                        <Row md={12}>
                            Are you sure you want to remove this paper?
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={deletePaper}>Yes</Button>
                    <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={() => props.onNo()}>No</Button>
                </Modal.Footer>
            </Modal>
        </ThemeProvider>
    );
}

export default DeleteWarningModal;