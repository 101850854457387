import { BACKEND_URL } from '../../utils';

export const getPublishers  = async () => {

    let url = BACKEND_URL + "rss/journal-publisher/search";
    const response = await fetch(url, {
        method:'GET',
    });

    if (response.status !== 200) {
        alert("Unable to fetch Publisher Data!!!")
    }
    else {
        const response_data = await response.json();
        return response_data.data
    }
};

export const getJournals = async (publisherId) => {
    let url = BACKEND_URL + `rss/journal-publisher/search?pub_id=${publisherId}`;
    const response = await fetch(url, {
        method:'GET',
    });

    if (response.status !== 200) {
        alert("Unable to fetch Journal Data!!!")
    }
    else {
        const response_data = await response.json();
        return response_data
    }

};

export const getData = async (link) => {
    link = encodeURI(link);
    let url = new URL(BACKEND_URL +`/rss`);
    const params = new URLSearchParams();
    params.set('link',link)
    url = encodeURI(url);

    const response = await fetch(url+'?'+params.toString(), 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        alert('Something went wrong while uploading link');
    }
    const rssLinkData= await response.json();
    return rssLinkData ;
};

export const checkData = async (token, rssLink, data) => {

    const params = {
        "link": rssLink,
        "config": data,
    }

    let url = new URL(BACKEND_URL +`/rss`);

    const response = await fetch(url, 
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),

    });

    if (!response.ok) {
        alert('Something went wrong while checking data');
    }
    const rssMappedData= await response.json();
    return rssMappedData;
};

export const submitData = async (token, rssLink, data, journalId) => {

    const params = {
        "link": rssLink,
        "config": data,
        "journal_id": journalId,
    }

    let url = new URL(BACKEND_URL +'/rss/add');

    const response = await fetch(url, 
    {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params),
    
    });

    if (!response.ok) {
        alert('Something went wrong while submitting data');
    }
    return response;
};