import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const departmentConfigurationAction = (data) => {
    return {
        type: actionTypes.GET_DEPARTMENT_CONFIG,
        searchResult: data
    }
}

const departmentUpdateData = (originalData) => {
    return {
        type: actionTypes.SAVE_DEPARTMENT_CONFIG_DATA,
        newOriginalDeptData: originalData,
    }
}

export const deptConfigAction = (token, searchText) => {

    return async dispatch => {
        const response = await fetch( BACKEND_URL + `dept?id=` + searchText, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for '+searchText+' with error: ' +errorResData);
        }
        const departmentData = await response.json();
        dispatch(departmentConfigurationAction(departmentData));
    };
}

export const saveDataAction = (token, universityID, originalData, newData, actionMessage) => {
    return async dispatch => {

        let temp = JSON.parse(JSON.stringify(originalData));
        const editData = {
            "name": newData.name,
            "A": newData.data["A"],
            "A-": newData.data["A-"],
            "B": newData.data["B"],
            "default_mapping": newData.data.default_mapping
        };
        let confirmUpdateData = false;

        if (actionMessage === 'save') {
            confirmUpdateData = true;
            console.log('Message is to save the data')

            if (newData.id) {
                temp[newData.id] = editData;                
            } else {
                temp.push(editData);
            }
        }

        if (actionMessage === 'delete') {
            if (newData.id) {
                confirmUpdateData = true;
                console.log('Message is to delete the data')
                temp.splice(newData.id, 1)
                console.log(temp)
            }
            else {
                alert('Cannot Delete Unsaved Data')
            }
        }

        if(confirmUpdateData){
            const params = {
                "id":universityID,
                "department": temp,
            }

            const UpdateDataresponse = await fetch( BACKEND_URL + `dept`, {
                method: 'POST',
                headers: {
                    "Content-Type":"application/json",
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify(params),
            });
            if(!UpdateDataresponse.ok) {
                const errorResData = await UpdateDataresponse.json();
                throw new Error('Something went wrong. Error: ' + errorResData);
            }

            const updatedDataMessage = await UpdateDataresponse.json();

            if(updatedDataMessage.message === "Departments updated successfully"){
                dispatch(departmentUpdateData(temp));
            }else{
                alert('Something went wrong. Please try again')
            }
        }else{
            dispatch(departmentUpdateData(temp));
        }
    };
}

