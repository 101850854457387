import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

const useRowStyles = makeStyles({
    root: {
        // '& > *': {
        // borderBottom: 'unset',
        // },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    avatar: {
        height: 30,
        width: 30,
        fontSize: 12,
        fontWeight: 600
      },
});

function Row(props) {
    const { row, rowIndex, showIndex, headCells, displayOnCollapse } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
        <React.Fragment>
            <TableRow onClick={() => setOpen(!open)}>
                {
                    showIndex ?         
                        <TableCell
                            style={{border:"1px", 
                                borderBottomWidth:"0px", borderLeftWidth:"0px", 
                                borderRightWidth:"0px",
                                borderColor:"#E0E0E0", borderStyle:"solid"}}
                        >
                            <Avatar className={classes.avatar} style={{ color: "#000000" }}>{rowIndex+1}</Avatar>
                            {/* <div>{}</div> */}
                        </TableCell>
                    :null
                }
                {
                    headCells.map((headCell)=>{
                        return (
                            <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'} 
                                style={{border:"1px", 
                                borderBottomWidth:"0px", borderLeftWidth:"0px", 
                                borderRightWidth:"0px",
                                borderColor:"#E0E0E0", borderStyle:"solid"}}
                            >
                                {row[headCell.id]}
                            </TableCell>
                        )
                    })
                }
            </TableRow>
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, width:"100%"}} colSpan={headCells.length + (showIndex ? 1 : 0)}>
            <Collapse in={open} timeout="auto" unmountOnExit onClick={() => setOpen(false)}>
                {displayOnCollapse(row)}
            </Collapse>
            </TableCell>
        </TableRow>
        
        </React.Fragment>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, showIndex, headCells } = props;
    
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
            {showIndex ? <TableCell style={{backgroundColor:"#BBB218"}} >#</TableCell>: null}
            {headCells.map((headCell) => (
                <TableCell
                style={{backgroundColor:"#BBB218"}}
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                sortDirection={orderBy === headCell.id ? order : false}
                >
                {
                    headCell.allowSort === true ? 
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                        ) : null}
                        </TableSortLabel>
                    : headCell.label
                }
                
                </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  }

export default function CollapsibleTable(props) {
    const [order, setOrder] = React.useState(props.defaultOrder);
    const [orderBy, setOrderBy] = React.useState(props.defaultLabel);
    const classes = useRowStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    return (
        <Paper style={{width:"100%"}}>
            <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" style={{borderWidth:1, borderColor:"#E0E0E0", borderStyle:"solid"}}>
                <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                showIndex={props.showIndex}
                headCells={props.columns}
                />
                <TableBody>
                    {stableSort(props.data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <Row key={index+page*rowsPerPage} row={row} rowIndex={index+ page*rowsPerPage} showIndex={props.showIndex} headCells={props.columns} displayOnCollapse={props.displayOnCollapse}/>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
