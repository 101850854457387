import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const authorSearchAction = (data) => {
    return {
        type: actionTypes.ADMIN_AUTHOR_SEARCH,
        searchResult: data
    }
}

const filterSearchAction = (value, result) => {
    return {
        type: actionTypes.FILTER_ADMIN_AUTHOR_SEARCH,
        value: value,
        result: result
    }
}

const filterByDepartmentAction = (value, sortBy, result) => {
    return {
        type: actionTypes.FILTER_AUTHOR_BY_DEPARTMENT,
        value: value,
        sortBy:sortBy,
        result: result
    }
}

export const authorSearch = (token, univId) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `get_authors?id=${univId}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while searching for ' + univId + ' with error: ' + errorResData);
        }
        const authorData = await response.json();
        dispatch(authorSearchAction(authorData));
    };
}

export const filterSearch = (value) => {

    return async (dispatch, getState) => {
        const state = getState();
        dispatch(filterSearchAction(value, state.authorSearch.authorSearchResultCopy));
    };
}

export const filterAuthorByDepartment = (value,sortByValue) => {

    return async (dispatch, getState) => {
        const state = getState();
        dispatch(filterByDepartmentAction(value,sortByValue, state.authorSearch.authorSearchResultCopy));
    };
}
