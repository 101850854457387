import { Paper } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as JournalDistributionActions from '../../../../../store/actions/journalDistributionTree';
import Chart from "react-apexcharts";

const JournalDistribution = props => {

    const dispatch = useDispatch();
    const TreeMapData = useSelector(state => state.journalDistribution.TreeMapData);


    React.useEffect(() => {

        getJournalDistributionData();
    }, []);

    const getJournalDistributionData = async () => {
        try {
            const token = localStorage.getItem('rl_token');
            await dispatch(JournalDistributionActions.JournalDistribution(token));

        }
        catch (err) {
            alert("Something went wrong while fetching journal distribution of author");
        }
    }

    const series = [

        {
            data: TreeMapData
        }
    ];

    const options = {

        tooltip: {
            x: {
                show: false,
                formatter: undefined,
            },
            y: {
                formatter: undefined,
                title: {
                    formatter: (seriesName) => 'No. of Papers:',
                },
            },
            z: {
                formatter: undefined,
                title: 'Journal:'
            }
        },
        // dataLabels: {
        //     enabled: true,
        //     style: {
        //         fontSize: "15px",
        //     }
        // },
        chart: {
            height: 350,
            type: 'treemap'
        },
        colors: [
            '#00259A',
            '#BBB21E',
            '#FFA300',
            '#E24301',
            '#84266A'
        ],
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
            }
        }
    };

    return (
        <Paper style={{ height: "460px", padding: "12px", width: "500px" }} >
            <div style={{ fontSize: "18px" }}>
                Journal distribution of your papers
            </div>
            <div style={{ fontSize: "18px" }}>
                {
                    TreeMapData.length > 0 &&
                    <Chart options={options} series={series} type="treemap" height={380} />
                }
                {
                    TreeMapData.length === 0 &&
                    <div style={{ marginTop: 10, fontSize: '15px', fontWeight: 'bold' }}>No Data to display</div>
                }

            </div>
        </Paper >

    )
}

export default JournalDistribution;