import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textAlign: "initial"
  },
  avatar: {
    backgroundColor: "#555559",
    height: 50,
    width: 50,
    alignContent: 'end',
  },
  difference: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: "#000000"
  },
  differenceValue: {
    color: "#000000",
    marginRight: 2
  },
  dataValue: {
      float:"left"
  }
}));

const InfoCard = props => {
  const { className, data, ...rest } = props;
  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)} style={{width:150, height:70}} >
      <CardContent>
        <Grid container >
          <Grid item xs={7}>
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
              {data["title"]}
            </Typography>
            <Typography variant="body2" className={classes.dataValue}>{data["value"]}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {data["icon"]}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string
};

export default InfoCard;