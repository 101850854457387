import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const get_univ_profile = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'univ_profile/get_univ_profile', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            throw new Error('Something went wrong in fetching university profile');
        }
        const resData = await response.json();
        dispatch({ type: actionTypes.GET_UNIV_PROFILE, profileData: resData });
    };
};

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_UNIV_PROFILE });
    }
}