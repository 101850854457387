import React from 'react';
import './Settings.css';
import TextInputNoIcon from '../../../InputComponents/TextInputNoIcon';
import {
    Button, Paper, Grid, Avatar, Typography, List, ListItemText,
    ListItem, ListItemAvatar, Table, TableBody, TableContainer, TableHead, TableRow, TableCell
} from '@material-ui/core';
import SingleSelectDropDownIcon from '../../../InputComponents/SingleSelectDropDownIcon';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit'; 
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteFacultyWarning from './DeleteFacultyWarning';

const Settings = props => {

    const [accessLevel, setAccessLevel] =  React.useState('');
    const [showDeleteConfirmationModal,setShowDeleteConfirmationModal]=React.useState(false);
    const [currentFacultyIndex,setCurrentFacultyIndex]= React.useState(-1);
    
      var authorSearchResult = [
          {
              'email': 'pauladler@usc.edu',
              first_name: 'Paul',
              id:'gadgb',
              last_name: 'Adler',
              profile_picture: 'https://www.marshall.usc.edu/sites/default/files/styles/202x280/public/padler/pci/Adler_Paul-2.jpg?itok=RzFSxPDA',
              univ_name: 'University of southern california'
          }, 
          {
            'email': 'pauladler@usc.edu',
            first_name: 'Paul',
            id:'gadgb',
            last_name: 'Adler',
            profile_picture: 'https://www.marshall.usc.edu/sites/default/files/styles/202x280/public/padler/pci/Adler_Paul-2.jpg?itok=RzFSxPDA',
            univ_name: 'University of southern california'
        }
      ]
    
    function createData(name, email, accessLevel, addRemoveUserButton) {
        return { name, email, accessLevel, addRemoveUserButton };
    }

    const handleNameChange = (event) => {
    }
    const handleEmailChange = (event) => {
    } 
    const handleAccessLevelChange = (event, newInputValue) => {
        setAccessLevel(newInputValue ? newInputValue : '');
    }

    //dummy data 
    const accessLevelDropDownValues = ["Admin", "User"];

    const usersWithAccessData = 1;
    const membersWithAccess = [
        createData("Aneri Shah", "aneriche@usc.edu", "Owner","")
    ]
    if(usersWithAccessData === 2) {
        membersWithAccess.push(
            createData(
            "Aneri Shah", 
            "aneriche@usc.edu", 
            <SingleSelectDropDownIcon 
                error={null} 
                options={accessLevelDropDownValues} 
                onInputChange={handleAccessLevelChange}
                inputValue={accessLevel} 
                label="Access Level" 
                defaultValue = "User"
                helperText="" />,
            <Button variant="contained" className="removeButton" >REMOVE</Button>
        ))
    }
    else if(usersWithAccessData === 1) {
        membersWithAccess.push(createData(
            <TextInputNoIcon error={null} onChange={handleNameChange} value={"fullName"} onBlur={null} label="First Name"/>,
            <TextInputNoIcon error={null} onChange={handleEmailChange} value={"email"} onBlur={null} label="Email"/>,
            <SingleSelectDropDownIcon 
                error={null} 
                options={accessLevelDropDownValues} 
                onInputChange={handleAccessLevelChange}
                inputValue={accessLevel} 
                label="Access Level" 
                defaultValue = "User"
                helperText="" />,
                <Button variant="contained" className="addButton" >ADD USER</Button>
           ))
    }

    const passIndex =(index)=>{
        console.log("current index "+ index);
        setCurrentFacultyIndex(index)
        setShowDeleteConfirmationModal(true)
    }

    const removeFaculty =(curId)=>{
        console.log("Remove")
        console.log(curId)
        setShowDeleteConfirmationModal(false)
        if(authorSearchResult.length===1){
        }else{
            const currentFaculty=[authorSearchResult.length-1]
            var counter=0;
            for(var i=0;i<authorSearchResult.length;i++){
                if(i!==curId){
                    currentFaculty[counter]={...authorSearchResult[i]}
                    counter++;
                }
            }
            console.log('new data length' + currentFaculty.length)
        }
    }

    const doNotRemove =()=>{
        console.log("Do not remove")
        setShowDeleteConfirmationModal(false)
    }

    return (
        <div>
            <div className="heading" style = {{padding:"16px"}}> Settings</div>

            <Paper id="userAccessCard" square style={{ padding: "16px 22px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <div style={{fontSize: "20px"}}>Members with access</div>
                        <div style={{fontSize: "16px"}}>Invite someone by entering their email. Total available seats: 2</div>

                    </Grid>
                </Grid>
            </Paper>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Access level</TableCell>
                                    <TableCell align="right"> &nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {membersWithAccess.map((row) => (
                                <TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.accessLevel}</TableCell>
                                <TableCell align="center">{row.addRemoveUserButton}</TableCell>
                                </TableRow> 
                            ))}        
                            </TableBody>
                        </Table>
                    </TableContainer>

            <Paper id="dashboardCustomization" square style={{ padding: "16px 22px" , marginTop:"16px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <div style={{fontSize: "20px"}}>Customize Dashboard</div>
                    </Grid>
                </Grid>

            </Paper>    
            <Paper id="facultyEditCard" square style={{ padding: "16px 22px" , marginTop:"16px"}}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <div style={{fontSize: "20px"}}>Faculty</div>
                </Grid>
            </Grid>

        {authorSearchResult.length > 0 &&
                        <div>
                            
                            <div style={{ backgroundColor: "white" }}>
                                <List>
                                    {authorSearchResult.map((faculty, i) =>
                                        <ListItem divider>
                                            <ListItemAvatar style={{ minWidth: 30 }}>
                                                {i + 1 + "."}
                                            </ListItemAvatar>
                                            <ListItemAvatar style={{ paddingRight: 30 }}>
                                                {faculty.profile_picture != null ?
                                                    (<Avatar alt={faculty.first_name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                                                    :
                                                    (<Avatar style={{ height: 75, width: 75 }}>
                                                        <PersonIcon />
                                                    </Avatar>)
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={faculty.first_name + " " + faculty.last_name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            {faculty.univ_name === null ? "" : faculty.univ_name}
                                                        </Typography>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />

                                            <EditIcon style={{ color:'#4f4f4f',marginBottom:"5px", marginRight:"5px", width:'0.8em',height:'0.8em'} }/>
                                            <DeleteIcon style={{ color:'#4f4f4f',marginBottom:"5px", width:'0.8em',height:'0.8em'}} onClick={()=>passIndex(i)} />

                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        </div>
                    }
                </Paper>
                <DeleteFacultyWarning currentFacultyIndex={currentFacultyIndex} onYes={removeFaculty} onNo={doNotRemove} show={showDeleteConfirmationModal} onHide={()=>setShowDeleteConfirmationModal(false)}/>
        </div>
    );
}

export default Settings;