class UnivProfile {
    constructor(_id, abbreviation, business_school, country,
        departments, domain, name, status, profile_picture, departments_list) {
        this._id = _id;
        this.abbreviation = abbreviation;
        this.business_school = business_school;
        this.country = country;
        this.departments = departments;
        this.domain = domain;
        this.name = name;
        this.status = status;
        this.profile_picture = profile_picture;
        this.departments_list = departments_list;
    };
};

export default UnivProfile;