import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    searchResult: [],
    facultyScrapeResult: [],
    checkMarkerResult: [],
    compareFacultyResult: []
}

const updateFacultyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_FACULTY_ACTION:
            return updateObject(state, {
                searchResult: action.result.allauthors, 
                facultyScrapeResult: action.result.scrapedauthors, 
                checkMarkerResult: action.result.checkmarkers,
                compareFacultyResult: action.result.comparedata
            });
        default:
            return state
    }
}

export default updateFacultyReducer;