import React from 'react';
import * as profileActions from '../../../../store/actions/profile';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as dashboardActions from '../../../../store/actions/dashboardUniv';
import JournalDistribution from "./Components/journalDistributionTree";



const DashboardHome = () => {
    return (
        <div>
            <div style={{ padding: "4px" }}>
                <span className="heading" style={{ padding: "3px" }}>
                    Dashboard
                </span>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={6} md={8}>
                    <JournalDistribution />
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardHome;