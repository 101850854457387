import React from 'react';
import Theme from '../../../../constants/Theme';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import './CompareByDepartment.css';
import classes2 from './CompareByDepartment.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, Paper, Slider, Button, Input, Accordion, AccordionSummary, AccordionDetails, FormLabel
} from '@material-ui/core';
import Chart from "react-apexcharts";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from '../../../../assets/Images/loading.gif';
import * as compareDepartmentsActions from '../../../../store/actions/compareDepartments';

const useStyles = makeStyles({
    root: {
        width: 250,
    },
    input: {
        width: 62,
    },
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    searchButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
});
const currentYear = new Date().getFullYear();
const minYear = 1950;
const maxYear = currentYear;

const CompareByDepartment = props => {
    const theme = Theme();
    const dispatch = useDispatch();

    const [yearRange, setYearRange] = React.useState([currentYear - 5, currentYear]);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const categories = useSelector(state => state.compareDepartments.categories);
 
    const schoolPapersMarketShare = useSelector(state => state.compareDepartments.schoolPapersMarketShare);
    const schoolCitationsMarketShare = useSelector(state => state.compareDepartments.schoolCitationsMarketShare);
    const abbreviation = useSelector(state => state.compareDepartments.abbreviation);

    const ylim = Math.max(Math.max(schoolPapersMarketShare),Math.max(schoolCitationsMarketShare))

    const inlineClasses = useStyles();

    const series = [
        // {
        //     name: 'Top 50 schools average share of: Papers',
        //     data: top50PapersMarketShare.map(val => val / parseInt(val) === 1 ?val : parseFloat(val.toFixed(2)))
        // },
        // {
        //     name: 'Top 50 schools average share of: Citations',
        //     data: top50CitationsMarketShare.map(val => val / parseInt(val) === 1 ?val : parseFloat(val.toFixed(2))),
        // },
        {
            name: `${abbreviation} share of: Papers`,
            data: schoolPapersMarketShare.map(val => val),
        },
        {
            name: `${abbreviation} share of: Citations`,
            data: schoolCitationsMarketShare.map(val => val),
        },
    ];
    

    const options2 = {
        plotOptions: {
            radar: {
                polygons: {
                    strokeColor: '#e8e8e8',
                    fill: {
                        colors: ['#f8f8f8', '#fff']
                    }
                }
            }
          
        },
        chart: {
            height: 650,
            type: 'radar',
            toolbar: {
                tools: {
                    download: false,
                }
            }
        },
        colors: ['#84266A', '#E24301'],
        title: {
            text: 'Share of Publications and Citations Among Journals for Each Department',
            align: 'center',
            offsetY: 40,
            style: {
                fontSize: '1em'
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    colors: "#555559",
                }
            }
        },yaxis: {
            forceNiceScale: false,
            labels: {
              formatter: function(val) {
                return val.toFixed(1);
              },
              max: (max) => {
                console.log(`Max value: ${max}`);
                return max;
              }
         
            },
            
        
          },
        fill: {
            opacity: 0.1,
        },
        stroke: {
            show: true,
            width: [2],
            curve: 'straight',
            dashArray: [5, 0, 5, 0]
        },
        legend: {
            offsetY: "-40"
        }
    };

    const handleYearSliderChange = (event, newValue) => {
        setYearRange(newValue);
    };
    const handleYearInputChange = (event,) => {
        let newYearRange = event.target.id === "from" ? [Number(event.target.value), yearRange[1]] : [yearRange[0], Number(event.target.value)];
        setYearRange(newYearRange);
    };
    const handleBlur = () => {
        if (yearRange[0] < minYear) {
            setYearRange([minYear, yearRange[1]]);
        } else if (yearRange[1] > maxYear) {
            setYearRange([yearRange[0], maxYear]);
        }
    };
    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }
    const search = async () => {
        const token = localStorage.getItem('rl_token');
        setShowSpinner(true);
        try {
            await dispatch(compareDepartmentsActions.compareDepartments(token, yearRange[0], yearRange[1]))
            setAccordionExpand(false);
        }
        catch (err) {
            alert("Internal server error. Please try again later");
            setAccordionExpand(true);
        }
        setShowSpinner(false);   
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={inlineClasses.featureHeading}>
                    Compare Departments
                </div>
                <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                    <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />}>
                        <div style={{ float: "left" }}>
                            {`Comparing departments from  ${yearRange[0]} -  ${yearRange[1]}`}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 25 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} />
                            <Grid item xs={5}>
                                <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Year Range:</FormLabel>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={yearRange[0]} id="from" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }} value={yearRange} onChange={handleYearSliderChange} valueLabelDisplay="auto" min={minYear} max={maxYear} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={yearRange[1]} id="to" margin="dense" onChange={handleYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container justify="center" alignItems="center">
                                <Button variant="contained" className={inlineClasses.searchButton} onClick={() => { search() }}>
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={2} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                {categories.length > 0 && !showSpinner &&
                    <Paper square style={{ marginTop: "2%" }}>
                        <div id="chart">
                            <Chart options={options2} series={series} type="radar" />
                        </div>
                    </Paper>
                }
            </div>
        </ThemeProvider>
    );
}
export default CompareByDepartment;