import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const compareYourselfSearchAction = (data) => {
    return {
        type: actionTypes.GET_COMPARE_YOURSELF,
        searchResult: data
    }
}

export const compareYourselfSearch = (token, id) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `compareyourself?id=${id}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for compareyourself for ' + id + ' with error: ' + errorResData);
        }
        const compareYourselfData = await response.json();
        // const compareYourselfData = dummyResponse;
        dispatch(compareYourselfSearchAction(compareYourselfData));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_COMPARE_YOURSELF });
    }
}