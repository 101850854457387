import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    TreeMapData: []
}

const journalDistributionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_JOURNAL_DISTRIBUTION:
            let alldata = action.searchResult
            let graphdata = []
            for (const id in alldata) {
                let data = {
                    x: alldata[id]['journal_abbreviation'],
                    y: alldata[id]['journal_count'],
                    z: alldata[id]['journal_title']
                };
                graphdata.push(data)
            }
            graphdata.sort(function (a, b) {
                return a.y - b.y;
            });
            return updateObject(state, {
                TreeMapData: graphdata
            });
        case actionTypes.CLEAR_JOURNAL_DISTRIBUTION:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default journalDistributionReducer;