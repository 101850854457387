import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';


const initialState = {
    contactUsData : [],
    totalData : 0
}

const contactUsRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONTACT_US_REQUEST:

            let restructureData = [];
            let totalData = action.paginationData.results[0]['total'];
            let totalPages = Math.ceil(totalData/5)

            let i = 1

            while(i < action.paginationData.results.length){
                restructureData.push(action.paginationData.results[i])
                i = i + 1
            }

            return updateObject(state, {contactUsData:restructureData, totalData:totalPages});
            
        case actionTypes.UPDATE_CONTACTED_FLAG:
            
            return updateObject(state, {contactUsData:[...action.updatedContactedData]});

        default:
            return state
    }
}
        
export default contactUsRequestReducer;

