import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const getAuthorRanking = (data) => {
    return {
        type: actionTypes.GET_AUTHOR_RANKING,
        payload: data
    }
}

export const fetchAuthorRanking = (token, universities, markers, gender, departments, journals) => {
    return async dispatch => {
        // let universityNames = [];
        // for (let i = 0; i < universities.length; i++) {
        //     universityNames.push(universities[i]["name"]);
        // }
        let finalMarker = [];
        if (markers.length === 3) {
            finalMarker = ["Any"]
        }
        else {
            finalMarker = markers
        }
        let finalGender = [];
        if (gender.length === 3) {
            finalGender = ["Any"]
        }
        else {
            finalGender = gender
        }
        // for (let i = 0; i < markers.length; i++) {
        //     finalMarker.push(markers[i]["value"]);
        // }
        let journal_issns = [];
        for (let i = 0; i < journals.length; i++) {
            journal_issns.push(journals[i]["issn"])
        }
        let post_body = {
            "journals": journal_issns,
            "markers": finalMarker,
            "universities": universities,
            "gender": finalGender,
            "department": departments,
        }
        // const response = await axios.post(BACKEND_URL + 'ranking/authors',  post_body, 
        //     {
        //         headers: {
        //             Authorization: 'Bearer '+ token,
        //     }}
        // );
        const response = await fetch(BACKEND_URL + 'ranking/authors', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(post_body),
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while getting author ranks. error : ' + errorResData);
        }
        const data = await response.json()
        // const response_data = await response.data();
        dispatch(getAuthorRanking(data));
        // dispatch(getAuthorRanking(response.data));
    }
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_FACULTY_RANKING });
    }
}