import { Button, Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as calculateFields from '../../../store/actions/calculateFields';
import { useDispatch } from 'react-redux';
import NoteModal from '../../NoteModal/NoteModal';

const useStyles = makeStyles((theme) => ({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    }
}));

const token = localStorage.getItem("rl_token");
const UniversityStatsGenerate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newGenerateStatsLastUpdate, setNewGenerateStatsLastUpdate] = React.useState({});
    const [disableButtonUniversityStats, setDisableButtonUniversityStats] = useState(false);
    const [disableButtonTenureTrack, setDisableButtonTenureTrack] = useState(false);
    const [disableButtonFirstYearCareer, setDisableButtonFirstYearCareer] = useState(false);
    const note = "An email will be sent on successful completion of the script";
    const [noteModalShow, setNoteModalShow] = useState(false);

    const generateUnivstatsButton = () => {
        setNoteModalShow(true);
        (async () => {
            setDisableButtonUniversityStats(true)
            try {
                const response = await dispatch(calculateFields.generateUnivstats(token))
                if (response.message === "Success") {
                    getButtons()
                    alert('Email Sent Successfully')
                }
                else {
                    alert("Something went wrong.");
                }
            } catch (error) {
                alert("Something went wrong. ", error);
            }
            setDisableButtonUniversityStats(false);
        })();
    };

    const generateTenureTrackButton = () => {
        setNoteModalShow(true);
        (async () => {
            setDisableButtonTenureTrack(true)
            try {
                const response = await dispatch(calculateFields.generateTenurestats(token))
                if (response.message === "Success") {
                    getButtons()
                    alert('Email Sent Successfully')
                }
                else {
                    alert("Something went wrong.");
                }
            } catch (error) {
                alert("Something went wrong. ", error);
            }
            setDisableButtonTenureTrack(false);
        })();
    };

    const generateFirstYearCareer = () => {
        setNoteModalShow(true);
        (async () => {
            setDisableButtonFirstYearCareer(true)
            try {
                const response = await dispatch(calculateFields.generateFirstYearCareer(token))
                if (response.message === "Success") {
                    getButtons()
                    alert('First Year of Career updated successfully.')
                }
                else {
                    alert("Something went wrong.");
                }
            } catch (error) {
                alert("Something went wrong. ", error);
            }
            setDisableButtonFirstYearCareer(false);
        })();
    };

    const getButtons = () => {
        (async () => {
            let tempButton = {};
            try {
                const details = await dispatch(calculateFields.getButtonDetails(token))
                // setNewGenerateStatsLastUpdate(details['details'][0]);
                details['details'].map((item) => {
                    tempButton[item['button_name']] = item['modified_on']
                })
                setNewGenerateStatsLastUpdate(tempButton)
            } catch (error) {
                setNewGenerateStatsLastUpdate("Couldn't load button details");
            }
        })();
    }

    const hideNoteModal = () => {
        setNoteModalShow(false);
    };

    React.useEffect(() => {
        if (Object.keys(newGenerateStatsLastUpdate).length === 0) {
            getButtons()
        }
    }, [newGenerateStatsLastUpdate]);

    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <div className={classes.featureHeading}>
                Compute Fields
            </div>
            <Container>
                <Row className={classes.row} md={12} style={{ paddingTop: '5%' }}>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <Button variant="contained" className="searchButton" disabled={disableButtonUniversityStats} color="primary" onClick={generateUnivstatsButton}>Generate University Statistics</Button>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row className={classes.row} md={12}>
                    <Col md={4}>
                    </Col>
                    <Col className='justify-content-center' md={4} style={{ fontSize: "13px", marginLeft: '2%' }}>Last Modified on: {newGenerateStatsLastUpdate['generate_university_stats'] ? newGenerateStatsLastUpdate['generate_university_stats'] : 'Unable to load last update'}
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
                <Row className={classes.row} md={12} style={{ paddingTop: '5%' }}>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <Button variant="contained" className="searchButton" disabled={disableButtonTenureTrack} color="primary" onClick={generateTenureTrackButton}>Generate Tenure Track</Button>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row className={classes.row} md={12}>
                    <Col md={4}>
                    </Col>
                    <Col className='justify-content-center' md={4} style={{ fontSize: "13px", marginLeft: '2%' }}>Last Modified on: {newGenerateStatsLastUpdate['generate_tenure_track'] ? newGenerateStatsLastUpdate['generate_tenure_track'] : 'Unable to load last update'}
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
                <Row className={classes.row} md={12} style={{ paddingTop: '5%' }}>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <Button variant="contained" className="searchButton" disabled={disableButtonFirstYearCareer} color="primary" onClick={generateFirstYearCareer}>Generate First Year of Career</Button>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row className={classes.row} md={12}>
                    <Col md={4}>
                    </Col>
                    <Col className='justify-content-center' md={4} style={{ fontSize: "13px", marginLeft: '2%' }}>Last Modified on: {newGenerateStatsLastUpdate['generate_first_year_career'] ? newGenerateStatsLastUpdate['generate_first_year_career'] : 'Unable to load last update'}
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
            </Container>
            <NoteModal show={noteModalShow} onHide={hideNoteModal} note={note} />
        </div>
    )
};
export default UniversityStatsGenerate;