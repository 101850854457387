import React from 'react';
import '../MultipleNameVariationsLog/MultipleNameVariationLog.css';
import Basicprofile from '../BasicProfile/BasicProfile';
import { useDispatch } from 'react-redux';
import * as logActions from '../../../store/actions/log';

const Gapbetweenpaperslog = (props)=> {
    const dispatch = useDispatch();
    const log = props.location.state;
    const onResolveClick = async (removedNameVariations, removedPapers) => {
        const token = localStorage.getItem("rl_token");
        await dispatch(logActions.resolveGapBetweenPapersLog(token, log, removedPapers));  
        goBack();
    }
    
    const goBack = () => {
        console.log("GO BACK");
        props.history.replace({ 
            pathname: "/logs",
        });
    }

    return (
        <div>
            <span className="profile">
                        {"GAP_OF_10_YEARS"}
            </span>
            <div style = {{ backgroundColor: "#fff", borderRadius: "2px", boxShadow: "0 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.22)", transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", margin: "1rem", zIndex: "-10", padding: "1rem"}}>
                <p>To resolve this log please have a look at the hightlighted papers and check if those papers actually belong to the author. If not then unmap that paper. Only click <b>RESOLVE</b> when you are sure that you have checked all the highlighted papers.</p>
            </div>
            <Basicprofile data={log} showEditOption={true} showNameVariations={false} onResolve={onResolveClick} onCancel={goBack}/>
        </div>
    )
}

export default Gapbetweenpaperslog;
