import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { BACKEND_URL } from '../../../../../utils';
import * as classes from './Departments.module.css';
import SingleSelectAutoComplete from '../../../../InputComponents/SingleSelectAutoComplete';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';
import InfoIcon from '@material-ui/icons/Info';

const AddDepartmentModal = props => {
    const token = localStorage.getItem("rl_token");
    const defaultDepartments = ["Accounting", "Strategy and Management", "Management Information Systems", "Business & International Management", "Management of Technology and Innovation", "Organizational Behavior and Human Resource", "Marketing", "Industrial Relations", "Tourism, Leisure and Hospitality Management", "Information Systems and Management", "Statistics", "Finance", "Economics and Econometrics", "Management Science & Operations Research"];

    const univId = props.data.univ_id;
    const [newUnivDepartment, setNewUnivDepartment] = useState("");
    const [percentAppointment, setPercentAppointment] = useState(0);
    const [univDepartments, setUnivDepartments] = useState([]);
    const [completedUniv, setCompletedUniv] = useState();
    const [showAlert, setShowAlert] = useState(false);

    const univDeptOnChangeHandler = (event, newInputValue) => {
        setNewUnivDepartment(newInputValue);
    };

    const addAuthorDepartment = () => {
        if (completedUniv === false) {
            (async () => {
                const response = await fetch(BACKEND_URL + "dept",
                    {
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "id": univId,
                            "department": newUnivDepartment
                        })
                    });
                if (response.status !== 200) {
                    alert("Something went wrong in adding new department. Please try again");
                }
            })();
        }
        props.onSave(newUnivDepartment, percentAppointment);
        setNewUnivDepartment("");
        setPercentAppointment(0);
    };

    const percentAppointmentChangeHandler = event => {
        let newInputValue = event.target.value;
        if (newInputValue === "")
            setPercentAppointment(0)
        else {
            newInputValue = parseFloat(newInputValue)
            if (newInputValue !== 0 && newInputValue + props.totalPercentAppointment > 100.0)
                setShowAlert(true)
            else
                setShowAlert(false)
            setPercentAppointment(newInputValue)
        }
    };

    const hideModal = () => {
        props.onHide();
        setNewUnivDepartment("");
    };

    const deleteDepartment = () => {
        props.onDeleteDept()
    };

    const disableSave = () => {
        if (newUnivDepartment === "" || percentAppointment === "" || percentAppointment === 0 || showAlert === true)
            return true
        return false
    }

    useEffect(() => {
        if (props.isNew === false) {
            setNewUnivDepartment(props.selectedDepartment[0])
            setPercentAppointment(props.selectedDepartment[1])

        }
        else if (univId !== undefined && univId !== "") {
            (async () => {
                await fetch(BACKEND_URL + `dept?id=` + univId, {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + token,
                    }
                }).then(async (response) => {
                    const dept = await response.json();
                    if (dept.departments !== undefined) {
                        let deparmentList = dept.departments.map(val => val['name']).filter(el => {
                            return !props.selectedDepartmentsList.includes(el);
                        });
                        setUnivDepartments(deparmentList)
                        setCompletedUniv(dept.status === "Completed");
                    }
                })
            })();
        }
    }, [props.selectedDepartmentsList, props.isNew, univId, props.selectedDepartment]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.isNew === true ? "Add Department" : "Edit Department"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row md={12}>
                        {
                            props.isNew === true ?
                                <Col md={8}>
                                    <SingleSelectAutoComplete
                                        options={completedUniv === true ? univDepartments : defaultDepartments}
                                        label="Choose Department"
                                        curText={newUnivDepartment}
                                        onChange={univDeptOnChangeHandler}
                                        disabled={props.isNew === false}
                                    />
                                </Col>
                                :
                                <Col md={8}>
                                    <TextInputControlled
                                        label="Department"
                                        value={newUnivDepartment}
                                        disabled={true} />
                                </Col>
                        }
                        <Col md={4}>
                            <TextInputControlled
                                label="Choose % of appointment"
                                onChange={percentAppointmentChangeHandler.bind(this)}
                                value={percentAppointment} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {
                    showAlert === true &&
                    <Row className={classes.row} md={12}>
                        <Col md={12}>
                            <InfoIcon fontSize="small" style={{ color: "gray" }} />
                            <span style={{ verticalAlign: "middle" }}>Total percentage of appointment for all departments has exceeded 100. Please fix the value for other departments and try again.</span>
                        </Col>
                    </Row>
                }
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={hideModal}>Cancel</Button>
                {
                    props.isNew === false &&
                    <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={deleteDepartment}>Delete</Button>
                }
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={addAuthorDepartment} disabled={disableSave()}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default AddDepartmentModal;