import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const findYourFitAction = (data) => {
    return {
        type: actionTypes.FIND_YOUR_FIT,
        findYourFitResult: data
    }
}

export const findYourFit = (token) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dashboard/find_your_fit`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while finding fit ' + errorResData);
        }
        const findYourFitResult = await response.json();
        console.log(findYourFitResult);
        dispatch(findYourFitAction(findYourFitResult));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_FIND_YOUR_FIT });
    }
}