import React from 'react';
import { Switch, MenuItem, Menu, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Theme from '../../../../../constants/Theme';
import { useSelector } from 'react-redux';
import SingleSelect from "../../../../InputComponents/SingleSelect";

const theme = Theme();
const secondaryColor = theme.palette.secondary.main;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

const BlueSwitch = withStyles({
    switchBase: {
        color: secondaryColor
    },
    track: {
        color: secondaryColor,
        backgroundColor: secondaryColor
    },
})(Switch);

const Settings = props => {
    const classes = useStyles();
    const [switchChecked, setswitchChecked] = React.useState(false);
    const [department, setDepartment] = React.useState("");
    const departments = useSelector(state => state.dashboards.departmentsResult);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSwitchChange = (event) => {
        if (switchChecked) {
            props.handleSchoolDeptSwitchChange(null);
        }
        setswitchChecked(!switchChecked);
    }

    const handleDepartmentChange = async (event, newInputValue) => {
        setDepartment(newInputValue ? newInputValue : "");
        props.handleSchoolDeptSwitchChange(newInputValue ? newInputValue : "");
    }

    return (
        <div className={classes.root}>
            <div>
                <Button onClick={handleClick}>
                    <MoreVertIcon />
                </Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} >
                    <MenuItem>
                        <span style={{ padding: "4px" }}>
                            <span>School </span>
                            <BlueSwitch checked={switchChecked} onChange={handleSwitchChange} color="secondaryColor" />
                            <span>Department </span>
                        </span>
                    </MenuItem>
                    {switchChecked &&
                        <MenuItem disableGutters>
                            <SingleSelect error={false} options={departments} onInputChange={handleDepartmentChange}
                                inputValue={department} label="Department" helperText="Select a department" />
                        </MenuItem>
                    }
                </Menu>
            </div>
        </div>
    );
};

export default Settings;