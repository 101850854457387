import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const journalDistributionAction = (data) => {
    return {
        type: actionTypes.GET_JOURNAL_DISTRIBUTION,
        searchResult: data
    }
}

export const JournalDistribution = (token) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dashboard/journal_distri_of_papers`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            throw new Error('Something went wrong while searching for journal distribution of author.');
        }
        const JournalDistributionData = await response.json();
        dispatch(journalDistributionAction(JournalDistributionData));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_JOURNAL_DISTRIBUTION });
    }
}

