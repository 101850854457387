import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel, FormControl } from '@material-ui/core';

const RadioSelect = props => {
    const {
        data,
        onChange
    } = props;
    
    return (
        <FormControl fullWidth>
            <RadioGroup value={props.value} onChange={onChange}>
                {
                    data.map(item => {
                        return (
                            <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    );
};

export default RadioSelect;