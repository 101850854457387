import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../store/actions/coauthorprofile';
import * as profileupdateActions from '../../../../store/actions/profileupdate'
import PersonalInformation from './PersonalInformation/PersonalInformation';
import Departments from './Departments/Departments';
import ResearchProfiles from './ResearchProfiles/ResearchProfiles';
import Education from './Education/Education';
import WorkHistory from './WorkHistory/WorkHistory';
import logo from '../../../../assets/Images/loading.gif';
import Papers from './Papers/Papers';
import classes from './Profile.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Paper, Grid } from '@material-ui/core';
import AreaOfResearch from './AreaOfResearch/AreaOfResearch';


const ProfileEdit = (props) => {
    const dispatch = useDispatch();
    const isCoAuthorProfile = typeof props.location.state !== 'undefined' && props.location.state !== null;
    const isReadOnly = typeof props.location.state !== 'undefined' && props.location.state !== null && props.location.state.isReadOnly === true;
    const [isLoading, setIsLoading] = useState(false);
    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const [token, setToken] = useState("Dummy Token");
    const userType = useSelector(state => state.auth.user_type);
    const isProfileComplete = useSelector(state => state.profile.profile.is_complete);
    const [message, setMessage] = useState("");

    const profileState = useSelector(state => {
        if (isCoAuthorProfile === true) {
            return state.coauthorprofile.profile
        } else {
            return state.profile.profile
        }
    });

    let complete = false;
    let coAuthorId = "";

    const profileComplete = () => {
        let missing = "Add ";
        let re = /(P|p)\.?(H|h)\.?(D|d)\.?/;
        complete = true;
        if (!('first_name' in profileState) || !profileState.first_name || profileState.first_name.trim() === "") {
            complete = false;
            missing += "First name, ";
        }
        if (!('education' in profileState) || !profileState.education || profileState.education.length === 0) {
            complete = false;
            missing += "Education, ";
        }
        if (('education' in profileState) && !profileState.education.some(element => re.test(element.degree) === true)) {
            complete = false;
            missing += "PHD, ";
        }
        if (!('work_history_manual' in profileState) || !profileState.work_history_manual || profileState.work_history_manual.length === 0) {
            complete = false;
            missing += "Work Experience, ";
        }
        // If work_history_manual exists and position is empty, we check if we have a subTitle which is set when
        // user selects 'Title' as 'Others'. If there exists subTitle, we assign the latest subtitle as position 
        if ('work_history_manual' in profileState && 'position' in profileState && profileState.position.trim() === "") {
            let subTitles = profileState.work_history_manual.filter(work =>
                'subTitle' in work && work.subTitle.trim() !== ""
            ).map(work => ({ to: work.to, subTitle: work.subTitle }));
            subTitles.sort((a, b) => { return b.to - a.to });
            if (subTitles.length !== 0) {
                profileState.position = subTitles[0]["subTitle"];
            }
        }
        if (!('position' in profileState) || !profileState.position || profileState.position.trim() === "") {
            complete = false;
            missing += "Position, ";
        }
        if (!('email' in profileState) || !profileState.email || profileState.email.trim() === "") {
            complete = false;
            missing += "Email, ";
        }
        if (!('cv' in profileState) || !profileState.cv || profileState.cv.trim() === "") {
            complete = false;
            missing += "CV, ";
        }
        if (!('univ_name' in profileState) || !profileState.univ_name || profileState.univ_name.trim() === "") {
            complete = false;
            missing += "University, ";
        }
        if (missing === "Add ") {
            setMessage("");
        } else {
            setMessage(missing.slice(0, -2));
        }
        // Below we update profile state from false to true if profile is complete.
        /// This extra dispatch is needed for the case when an author logs in for the first time and his/her profile is already complete.
        if (isProfileComplete === false && complete === true) {
            dispatch(profileActions.update_author_profile(token, { ...profileState, is_complete: complete }));
        }
    };

    const setupdateProfile = () => {
        dispatch(profileupdateActions.updateProfile());
    };

    const setDoneUpdateProfile = () => {
        // check and update if profile is complete everytime user is faculty and done editing profile
        if (userType === 'faculty' && !isReadOnly) {
            profileComplete();
            dispatch(profileActions.update_author_profile(token, { ...profileState, is_complete: complete }));
        }
        dispatch(profileupdateActions.doneUpdatingProfile());
    };

    const loadProfile = async () => {

        try {
            const token = localStorage.getItem("rl_token");
            // If coAuthorId is present, search and load that Author profile for editing
            if (userType !== "university")
                await dispatch(profileActions.get_author_profile(token, ''));
            if (coAuthorId !== "")
                await dispatch(coAuthorProfileActions.get_co_author_profile(token, coAuthorId));
        }
        catch (error) {
            console.log("error thrown while loading profile page:" + error);
            alert("Failed to load profile! Try again in few seconds!");
            setIsLoading(false);
        }
    };

    const initiateProfile = () => {
        setIsLoading(true);
        if (isCoAuthorProfile === true && coAuthorId === '') {
            coAuthorId = props.location.state.coauthorid;
            if (!isReadOnly)
                setupdateProfile();
            else
                setDoneUpdateProfile();
        }
        setToken(localStorage.getItem("rl_token"))
        loadProfile().then(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        initiateProfile()
    }, [dispatch, props.location]);

    useEffect(() => {
        if (userType === 'faculty') profileComplete();
    }, [profileState]);

    if (isLoading) {
        return (<div style={{ width: "100%", height: "900px" }}>
            <img src={logo} alt="Fetching Profile.." style={{ marginLeft: '40%', marginTop: '20%', width: '80px', height: '80px' }} />
        </div>)
    };

    return (
        <div>
            <Container className={classes.heading}>
                <Row>
                    <Col>
                        <span className={classes.profile}>
                            {isCoAuthorProfile === true ? isReadOnly === true ? 'Author Profile' : "Update Faculty's Profile" : 'My Profile'}
                        </span>

                        {
                            !isReadOnly &&
                            <span className={classes.editbox} style={{ cursor: "pointer" }}>
                                {profileUpdateState === true ?
                                    <span onClick={() => setDoneUpdateProfile()}>
                                        <span className={classes.done}>
                                            DONE
                                        </span>
                                        <span className={classes.icon}>
                                            <CheckCircleIcon
                                                style={{ width: '0.6em', height: '0.6em', marginBottom: "5px" }}
                                                onClick={() => setDoneUpdateProfile()}
                                            />
                                        </span>
                                    </span>
                                    :
                                    <span onClick={() => setupdateProfile()}>
                                        <span>
                                            <span className={classes.done}>
                                                EDIT
                                            </span>
                                            <span className={classes.icon}>
                                                <EditIcon
                                                    style={{ width: '0.6em', height: '0.6em', marginBottom: "5px" }}
                                                    onClick={() => setupdateProfile()}
                                                />
                                            </span>
                                        </span>
                                    </span>
                                }
                            </span>
                        }
                    </Col>
                </Row>
            </Container>
            {
                userType === 'faculty' &&
                isProfileComplete === false &&
                message !== "" &&
                <Paper id="userAccessCard" square style={{ padding: "16px 22px" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ paddingTop: '10px' }}>
                            <div style={{ fontSize: "16px" }}>Please complete your profile by providing the folowing information: {message}</div>
                        </Grid>
                    </Grid>
                </Paper>
            }
            {
                props.location.state !== null && typeof props.location.state !== 'undefined' && props.location.state.note &&
                <Paper id="note" square style={{
                    background: "#fff", borderRadius: "2px", boxShadow: "0 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.22)",
                    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", margin: "1rem", zIndex: "-10", padding: "1rem"
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ paddingTop: '10px' }}>
                            <div style={{ fontSize: "16px" }}><b>Note: </b>{props.location.state.note}</div>
                        </Grid>
                    </Grid>
                </Paper>
            }
            <PersonalInformation data={profileState} isCoAuthor={isCoAuthorProfile} isReadOnly={isReadOnly} />
            {
                !isReadOnly &&
                [
                    <Departments data={profileState} isCoAuthor={isCoAuthorProfile} />,
                    <Education data={profileState} isCoAuthor={isCoAuthorProfile} />,
                    <WorkHistory data={profileState} isCoAuthor={isCoAuthorProfile} refreshProfile={initiateProfile} />,
                    <ResearchProfiles data={profileState} isCoAuthor={isCoAuthorProfile} />
                ]
            }
            <Papers data={profileState} isCoAuthor={isCoAuthorProfile} />
            {
                !isReadOnly &&
                <AreaOfResearch data={profileState} isCoAuthor={isCoAuthorProfile} />

            }
        </div>
    )
};

export default ProfileEdit;