import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';
import * as profileActions from './profile';

export const get_co_author_profile = (token, id) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'profile/' + id, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong in fetching author profile' + errorResData);
        }
        const resData = await response.json();
        // console.log(resData)
        dispatch({ type: actionTypes.GET_COAUTHOR_PROFILE, profileData: resData });
    };
};

export const update_co_author_profile = (token, profile, id) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + 'profile/' + id, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "update": profileActions.create_update_author_object(profile)
            })
        });
        if (!response.ok) {
            throw new Error('Something went wrong in updating Author Profile');
        }
        dispatch({ type: actionTypes.UPDATE_COAUTHOR_PROFILE, profileData: profile });
    };
};