import React from 'react';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import InfoIcon from '@material-ui/icons/Info';
import * as classes from './WorkHistory.module.css';

const NoteModal = props => {

    const hideModal = () => {
        props.onHide();
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModalNote}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Note
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                    <div style={{fontSize: "1rem", paddingLeft: "15px"}}>
                        <InfoIcon fontSize="small" style={{ color: "gray" }}/>
                        <span style={{verticalAlign: "middle"}}>&nbsp;{props.note}</span>
                    </div>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" style={{color:'#000000',backgroundColor:"#BBB218",borderRadius:"0%"}} onClick={hideModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default NoteModal;