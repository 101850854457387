import { FIND_SIMILAR_AUTHORS_BEFORE_ADDING_NEW_AUTHOR } from '../actions/actionTypes';
import { updateObject } from '../../utils';


const initialState = {
    similar_authors: [],
    author_id_created : ""
}


const addAuthorToolReducer = (state = initialState, action) => {
    switch(action.type) {
        case FIND_SIMILAR_AUTHORS_BEFORE_ADDING_NEW_AUTHOR:
            return updateObject(state, {similar_authors: action.payload, author_id_created: ""});
        default:
            return state 
    }
}


export default addAuthorToolReducer;