import React, { useState, useEffect } from 'react';
import { Input, MenuItem, Checkbox, ListItemText, InputLabel, Select, FormHelperText, FormControl } from '@material-ui/core';
import './InputComponents.css';
import { withStyles } from '@material-ui/core/styles';
const MultipleSelect = props => {
    const {
        onChange,
        options,
        menuProps,
        value,
        label,
        disabled,
        displayDict,
        tagLimit
    } = props;
    const StyledFormHelperText = withStyles({
        contained: {
            marginLeft: "12px",
            marginTop: "-6px"
        }
    })(FormHelperText);
    const [errorFlag, setErrorFlag] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    useEffect(() => {
        setErrorFlag(props.error);
        setErrorMessage(props.helperText);
    }, [props.error])
    return (
        <FormControl className="textInput blackColor" fullWidth size="small" variant="filled" error={errorFlag}>
            <InputLabel fullWidth size="small">{label}</InputLabel>
            <Select disabled={disabled ? disabled : false} variant="filled" size="small" fullWidth multiple
                value={value} onChange={onChange} input={<Input fullWidth />}
                renderValue={(selected) => (
                    <div style={{ paddingLeft: 12, float: "left" }}>
                        {(selected.length !== 0) &&
                            <span key={selected[0]}>
                                {displayDict ? displayDict[selected[0]] : selected[0]}
                                {(selected.length > 1) && <b style={{ fontSize: 13 }}> + {selected.length - 1} {(selected.length - 1) === 1 ? 'other' : 'others'}  </b>}
                            </span>
                        }
                    </div>
                )} MenuProps={menuProps} >
                {options.map(name => (
                    <MenuItem fullWidth size="small" key={name} value={name}>
                        <Checkbox fullWidth size="small" checked={value.indexOf(name) > -1} />
                        <ListItemText fullWidth size="small" primary={displayDict ? displayDict[name] : name} />
                    </MenuItem>
                ))}
            </Select>
            {errorFlag && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}
        </FormControl>
    );
}

export default MultipleSelect;
