import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const facultyProgressAction = (data) => {
   return {
      type: actionTypes.FACULTY_PROGRESS,
      facultyProgressData: data
   }
}

const getDepartmentsAction = (data) => {
   return {
      type: actionTypes.GET_DEPT_AND_JOURNALS,
      departments: data
   }
}

export const getDepartments = (token, universityId) => {
   return async dispatch => {
      const response = await fetch(BACKEND_URL + `dept?id=` + universityId, {
         method: 'GET',
         headers: {
            Authorization: 'Bearer ' + token,
         }
      });
      if (!response.ok) {
         const errorResData = await response.json();
         throw new Error('Something went wrong while searching for ' + universityId + ' with error: ' + errorResData);
      }
      const departmentData = await response.json();
      dispatch(getDepartmentsAction(departmentData));
   };
}

export const facultyProgress = (token, department, journals, from_year, to_year, rankBy, defaultDepts, universityId) => {

   return async dispatch => {
      let url = BACKEND_URL + 'dashboard/faculty_progress';
      const response = await fetch(url, {
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            'from_year': from_year,
            'to_year': to_year,
            'univ_id': universityId,
            'journals': journals,
            'default': defaultDepts,
            'rank_by': rankBy,
            'department': department
         })
      });
      if (!response.ok) {
         dispatch({ type: actionTypes.FAILURE });
         throw new Error('Something went wrong while fetching Faculty Progress');
      }
      const facultyProgressData = await response.json();
      dispatch(facultyProgressAction(facultyProgressData));
   };
}

export const clearState = () => {
   return async dispatch => {
      dispatch({ type: actionTypes.CLEAR_FACULTY_PROGRESS });
   }
}