import React, { useEffect, forwardRef,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Theme from '../../constants/Theme';
import classes from './Login.module.css';
import * as authActions from '../../store/actions/auth';
import * as profileActions from '../../store/actions/profile';
import * as univActions from '../../store/actions/univProfile';
import loader from '../../assets/Images/loading.gif';
import { NavLink as RouterLink } from 'react-router-dom';
import {
    Paper, Grid, Typography, Button
} from '@material-ui/core';
import { withStyles, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import TextInput from '../InputComponents/TextInput';
const bg = require('../../assets/Images/login_bg.png');
const useStyles = makeStyles((theme) => ({
    loginButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    }
}));

const Login = props => {
    const [isEmailNotValid, setIsEmailNotValid] = React.useState(false);
    const [isPasswordNotValid, setIsPasswordNotValid] = React.useState(false);
    const [showForgotPasswordDisplay, setShowForgotPasswordDisplay] = React.useState(false);
    const [formElements, setFormElements] = React.useState({ formData: { email: '', password: '' }, submitted: false })
    const { formData, submitted } = formElements;
    const loadingState = useSelector(state => state.auth.loading);
    const [sendingMail, setsendingMail] = useState(false);

    const token = useSelector(state => state.auth.token);
    const userType = useSelector(state => state.auth.user_type);
    const isProfileComplete = useSelector(state => state.profile.profile.is_complete);
    const loginErrorState = useSelector(state => state.auth.error);
    const errorMessage = useSelector(state => state.auth.message);
    const forgotPasswordSuccess = useSelector(state => state.auth.forgotPasswordSuccess);
    const inlineClasses = useStyles();
    const theme = Theme();
    const dispatch = useDispatch();

    const StyledButton = withStyles((theme) => ({
        root: {
            '&:hover': {
                background: 'transparent'
            }
        },
        disabled: {
            backgroundColor: '#ebe691 !important'
        }
    }))(Button);
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1, display: "inline" }}
        >
            <RouterLink {...props} />
        </div>
    ));

    useEffect(() => {
        // Update the document title using the browser API
        if (token != null) {
            props.location.aboutProps = { "loggedState": "LOGIN" };
            redirectToDashboard();
        }
    });

    const redirectToDashboard = () => {
        if (props) {
            if (props.location) {
                if (props.location.aboutProps) {
                    if (props.location.aboutProps.loggedState === 'LOGOUT') {
                        dispatch(authActions.logOut());
                        setFormElements({ formData: { email: '', password: '' }, submitted: false })
                        return;
                    }
                }
            }
        }
        if (props.history) {
            if (userType.toLowerCase() === "faculty") {
                if (isProfileComplete === false)
                    props.history.push('/dashboard/profileedit')
                else {
                    dispatch(profileActions.get_author_profile(token));
                    props.history.push('/dashboard')
                }
            }
            else if (userType.toLowerCase() === "university") {
                dispatch(univActions.get_univ_profile(token))
                props.history.push('/dashboards')
            }
            else {
                dispatch(profileActions.get_author_profile(token));
                props.history.push('/dashboard/changeLogin')
            }

        }
    }
    const handleEmailChange = (event) => {
        const { formData, submitted } = formElements;
        formData["email"] = event.target.value;
        if (event.target.value.trim() === "") {
            setIsEmailNotValid(true);
        } else {
            setIsEmailNotValid(false);
        }
        setFormElements({ formData: formData, submitted: submitted })
    }
    const handlePasswordChange = (event) => {
        const { formData, submitted } = formElements;
        formData["password"] = event.target.value;
        if (event.target.value.trim() === "") {
            setIsPasswordNotValid(true);
        } else {
            setIsPasswordNotValid(false);
        }
        setFormElements({ formData: formData, submitted: submitted })
    }
    const logInHandler = async () => {
        setFormElements({ formData: formData, submitted: true })
        if (formElements.formData.email.trim() === "" || formElements.formData.password.trim() === "") {
            if (formElements.formData.email.trim() === "") {
                setIsEmailNotValid(true);
            }
            if (formElements.formData.password.trim() === "") {
                setIsPasswordNotValid(true);
            }
            setFormElements({ formData: formData, submitted: false })
            return;
        }
        try {
            await dispatch(authActions.auth(formElements.formData.email, formElements.formData.password));
            setFormElements({ formData: formData, submitted: false })
        }
        catch (error) {
            alert("Failed to Login! Please try again.");
            setFormElements({ formData: formData, submitted: false })
        }
    }
    const forgotPassword = async () => {
        setsendingMail(true);
        if (formData.email !== "") {
            await dispatch(authActions.forgotPassword(formData.email));
        }
        else {
            alert("Please enter a correct email Id");
        }
        setsendingMail(false);

    }
    const handleEnterKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            logInHandler();
        }
    }
    return (
        <div style={{ backgroundColor: "#E5E5E5", minHeight: "75vh" }}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <Paper square className={classes.paperStyle} style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: 342 }}>
                            {showForgotPasswordDisplay ?
                                forgotPasswordSuccess === null ?
                                    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                                        <Grid item>
                                            <Typography variant="h5">Please enter your account email Id.</Typography>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ width: 262 }}>
                                                <TextInput required error={false} value={formData.email} onChange={handleEmailChange} onKeyDown={handleEnterKeyDown} label="Email" helperText={isEmailNotValid ? 'Email is not valid' : null} />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                        {sendingMail ?
                                            <div><img src={loader} alt="loading..." style={{ height: '50px', width: '50px' }} />Logging In... </div>
                                            :
                                            <Button className={inlineClasses.loginButton} variant="contained"
                                                onClick={forgotPassword} disabled={submitted} >
                                                Forgot password
                                            </Button>
                                        }
                                        </Grid>
                                    </Grid>
                                    :
                                    forgotPasswordSuccess === true ?
                                        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                                            <Grid item>
                                                <Typography variant="h5">Please follow the instructions sent on {formData.email} to reset your password.</Typography>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                                            <Grid item>
                                                <Typography variant="h5">Request could not be completed. Please try again later.</Typography>
                                            </Grid>
                                        </Grid>
                                :
                                <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                                    <Grid item style={{ paddingBottom: 0 }}>
                                        <Typography variant="h5">Welcome To Research Loupe!</Typography>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 5 }}>
                                        <Typography variant="body1">Login to your account</Typography>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ width: 262 }}>
                                            <TextInput required error={isEmailNotValid} value={formData.email} onChange={handleEmailChange} onKeyDown={handleEnterKeyDown} label="Email" helperText={isEmailNotValid ? 'Email is not valid' : null}></TextInput>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ width: 262, overflowY: "hidden" }}>
                                            <TextInput type="password" error={isPasswordNotValid} value={formData.password}
                                                required onChange={handlePasswordChange} onKeyDown={handleEnterKeyDown} label="Password" helperText={isPasswordNotValid ? 'Password is not valid' : null}></TextInput>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        {loadingState ?
                                            <div><img src={loader} alt="loading..." style={{ height: '50px', width: '50px' }} />Logging In... </div>
                                            :
                                            <Button style={{ width: 145 }} className={inlineClasses.loginButton} variant="contained"
                                                onClick={logInHandler} onKeyDown={handleEnterKeyDown} disabled={submitted} >
                                                Login
                                            </Button>
                                        }
                                        {
                                            loginErrorState ?
                                                <div style={{ marginTop: 10, color: "red" }}> Failed to Login! {errorMessage} </div>
                                                : null
                                        }
                                    </Grid>
                                    <Grid item style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <Typography variant="body1">
                                            <u onClick={() => { setShowForgotPasswordDisplay(true) }} className={classes.forgotPassword}>
                                                forgot password?
                                            </u>
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 0 }}>
                                        <Typography variant="body1">Do not have an account?
                                            <StyledButton component={CustomRouterLink} to={'/signup'} disableElevation disableRipple
                                                disableFocusRipple disableTouchRipple style={{ textDecoration: "underline" }}>
                                                Sign up
                                            </StyledButton>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={3} />
                </Grid>
            </ThemeProvider>
        </div>
    );
}

export default Login;
