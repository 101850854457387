import * as actionTypes from '../actions/actionTypes'; 

const profileUpdateState = {
    editProfile: false
};

const profileUpdateReducer = (state = profileUpdateState, action) => {
    switch(action.type){
        case actionTypes.EDIT_PROFILE:
             return {editProfile: true}
        case actionTypes.DONE_EDIT:
            return {editProfile:false}
        default:
            return state
    }
};

export default profileUpdateReducer;