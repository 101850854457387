import { FETCH_LOGS, RESOLVE_LOG, FILTER_LOGS } from '../actions/actionTypes';
import { updateObject } from '../../utils';


const initialState = {
    logs: [],
    log_to_resolve : {},
    filter_condition: "All"
}


const logReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_LOGS:
            return updateObject(state, {logs: action.payload, flog_to_resolve: {}});
        case FILTER_LOGS:
            return updateObject(state, {logs: action.payload, filter_condition: action.condition, flag_to_resolve:{}});
        case RESOLVE_LOG:
            let newLogs = state.logs.filter(log => action.payload._id !== log._id);
            let newFilteredLogs = state.filtered_logs;
            if (state.filter_condition === 'All') {
                 newFilteredLogs = newLogs;
            }
            else if (state.filter_condition === action.payload.error) {
                newFilteredLogs = newLogs.filter(log => log.error === state.filter_condition);
            }
            return updateObject(state, {logs: newLogs, filtered_logs: newFilteredLogs, log_to_resolve:{}});
        default:
            return state 
    }
}


export default logReducer;