import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import FacultyCard from '../Components/FacultyCard';
import classes2 from '../FacultyProductivity.module.css';

const ViewByAllFaculty = props => {
    let facultyList = props.facultyList;
    const facultyCount = props.facultyCount;
    facultyList = facultyCount==="All"? facultyList: facultyList.slice(0, parseInt(facultyCount));
    return (
        <div>
            <Paper style={{ padding: 20 }}>
                <Grid container spacing={3} className={classes2.outputMaxHeight}>
                    { facultyList.length>0 &&
                        facultyList.map((faculty, i) => (
                            <Grid item xs={4} key={faculty.name} style={{ padding: 20 }}>
                                <FacultyCard data={faculty} rank={i + 1} allJournals={props.allJournals} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Paper>
        </div>
    )
}

export default ViewByAllFaculty;