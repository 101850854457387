import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classes from './Education.module.css';
import profileClassses from '../Profile.module.css';
import { useDispatch, useSelector } from 'react-redux';
import AddEducationModal from './AddEducationModal';
import AddPhdModal from './AddPhdModal';
import ChoosePhdModal from './ChoosePhdModal';
import * as profileActions from '../../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../../store/actions/coauthorprofile';
import Profile from '../../../../../model/profile';
import InfoIcon from '@material-ui/icons/Info';
import uniqueId from 'uniqid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Theme from '../../../../../constants/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Grid, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Education = props => {
    const theme = Theme();
    const addColor = "#DADADA";
    const editColor = "#DADADA";
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const token = useSelector(state => state.auth.token);
    const [education, setEducation] = useState(props.data.education);
    const [modalShow, setModalShow] = useState(false);
    const [editEduModalShow, setEditEduModalShow] = useState(false);
    const [editPhdModalShow, setEditPhdModalShow] = useState(false);
    const [choosePhdModalShow, setChoosePhdModalShow] = useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [curEducation, setCurEducation] = useState({ isNew: true, id: 0, degree: "", institution: "", year: "", area: "", advisorFirstName: "", advisorLastName: "", coadvisorFirstName: "", coadvisorLastName: "" })
    const dispatch = useDispatch();
    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    // Matches all of the degree's that we consider equivalent to a PHD
    const re = /(^(P|p)\.?(H|h)\.?(D|d)\.?$)|(^(D|d)\.?(B|b)\.?(A|a)\.?$)|(^(D|d)\.?(S|s)\.?(C|c)\.?$)|(^(J|j)\.?(S|s)\.?(D|d)\.?$)|(^(L|l)\.?(H|h)\.?(D|d)\.?$)|(^(M|m)\.?(D|d)\.?$)|(^(D|d)\.?(A|a)\.?$)|(^(D|d)\.?(P|p)(H|h)(I|i)(L|l)$)/;

    const createUpdatedProfile = (new_education) => {
        return Object.assign(new Profile(), {
            _id: props.data._id,
            education: new_education,
        })
    };

    const editHandler = async (new_education) => {
        try {
            if (props.isCoAuthor === true) {
                await dispatch(coAuthorProfileActions.update_co_author_profile(token, createUpdatedProfile(new_education), props.data._id));
            } else {
                await dispatch(profileActions.update_author_profile(token, createUpdatedProfile(new_education)));
            }
        }
        catch (error) {
            alert("Failed to Update! Please Refresh the page and try again.");
        }
    };

    const setNewPhdEducation = () => {
        var curID = assignID()
        setCurEducation({ ...curEducation, institution: "", year: "", area: "", advisorFirstName: "", advisorLastName: "", coadvisorFirstName: "", coadvisorLastName: "", isNew: true, id: curID, degree: "Ph.D." })
    };

    const setNewEducation = () => {
        var curID = assignID()
        setCurEducation({ ...curEducation, degree: "", institution: "", year: "", area: "", advisorFirstName: "", advisorLastName: "", coadvisorFirstName: "", coadvisorLastName: "", isNew: true, id: curID })
    };

    const assignID = () => {
        var largestUsedId = 0
        for (var i = 0; i < education.length; i++) {
            largestUsedId = Math.max(largestUsedId, education[i].id)
        }
        return (largestUsedId + 1)
    };

    const setModalandsetPhdEducation = () => {
        setNewPhdEducation()
        showModal()
    };

    const setModalandsetEducation = () => {
        setNewEducation()
        showModal()
    };

    const showModal = () => {
        setModalShow(true);
    };

    const showEditEduModal = () => {
        setEditEduModalShow(true);
    };

    const showEditPhdModal = () => {
        setEditPhdModalShow(true);
    };

    const showChoosePHDModal = (updatedEducation) => {
        const phdWithId = updatedEducation.filter(item => {
            return re.test(item.degree) === true || typeof item.isPHDtoConsider !== 'undefined';
        }).map(item => item.id);

        if (phdWithId.length > 0) {
            if (phdWithId.length === 1) {
                var edu = [...updatedEducation];
                edu[phdWithId[0] - 1] = { isPHDtoConsider: true, ...edu[phdWithId[0] - 1] }
                setEducation(edu);
                saveChosenPHD(edu);
            } else {
                setChoosePhdModalShow(true);
            }
        } else {
            editHandler(updatedEducation);
        }
    };

    const addEducation = (edu) => {
        setAccordionExpand(true);
        var updatedEducation = []
        if (edu.isNew === true) {
            updatedEducation = [education.length + 1]
            for (var i = 0; i < education.length; i++) {
                updatedEducation[i] = { ...education[i] }
            }
            updatedEducation[education.length] = edu;
        } else {
            updatedEducation = [education.length]
            for (var i = 0; i < education.length; i++) {
                if (education[i].id === edu.id) {
                    updatedEducation[i] = edu;
                } else {
                    updatedEducation[i] = { ...education[i] }
                }
            }
        }
        setEducation(updatedEducation);

        if (edu.isNew === true) {
            setModalShow(false);
        } else {
            setEditEduModalShow(false);
            setEditPhdModalShow(false);
        }

        if (re.test(edu.degree) === true) {
            showChoosePHDModal(updatedEducation);
        } else {
            editHandler(updatedEducation);
        }
    };

    const saveChosenPHD = (updatedEducation) => {
        setChoosePhdModalShow(false);
        editHandler(updatedEducation);
    };

    const hideEducation = (curID) => {
        setModalShow(false);
        setAccordionExpand(true);
    };

    const hideEducationEdit = (curID) => {
        setEditEduModalShow(false);

        var deletedDegreeisChoosenPHD = false;
        var updatedEducation = education.filter((item) => {
            if (item.id !== curID) {
                return item
            } else {
                deletedDegreeisChoosenPHD = item.isPHDtoConsider === true;
            }
        });

        updatedEducation = updatedEducation.map((item, index) => {
            item.id = index + 1;
            return item
        });
        setEducation(updatedEducation);

        if (deletedDegreeisChoosenPHD === true) {
            showChoosePHDModal(updatedEducation);
        } else {
            editHandler(updatedEducation);
        }
    };

    const editItems = (id, isPhd) => {
        var curEdu = {}
        for (var i = 0; i < education.length; i++) {
            if (education[i].id === id) {
                curEdu = { ...education[i], isNew: false }
            }
        }
        setCurEducation(curEdu)
        isPhd === true ? showEditPhdModal() : showEditEduModal();
    };

    if (education.length === 0 && !props.data.verified) {
        return (<Container className={classes.container}>
            <Row className={classes.header}>
                <Col sm={3} md={3}>
                    <h3>Education</h3>
                </Col>
                {profileUpdateState === true ? (
                    <Col md={8} style={{ color: 'gray', textAlign: 'right' }}>

                        <span style={{ cursor: "pointer" }}>
                            <LightTooltip title="Click to add Education." placement="bottom">
                                <AddCircleIcon style={{ width: '1.2em', height: '1.2em', color: addColor, }} onClick={setModalandsetPhdEducation}> </AddCircleIcon>
                            </LightTooltip>
                        </span>


                    </Col>) : (
                    <Col sm={1} md={1}></Col>
                )}
            </Row>
            <AddPhdModal curEducation={curEducation} show={modalShow} onSave={addEducation} onHide={() => setModalShow(false)} />
        </Container>
        );
    }

    return (
        <Grid container xs={20} alignItems="center" justify="center" className={profileClassses.grid}>
            <Accordion square className={profileClassses.accordian} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary className={profileClassses.accordian_summary}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Row className={classes.header}>
                        <Col sm={3} md={3}>
                            <h3 style={{ marginTop: "10px" }}>Education</h3>
                        </Col>
                        <Col sm={1} md={1}>
                            {
                                profileUpdateState === true ?
                                    <span style={{ cursor: "pointer" }}>
                                        <LightTooltip title="Click to add Education." placement="bottom">
                                            <AddCircleIcon style={{ width: '1.2em', height: '1.2em', color: addColor, marginLeft: "756px", marginTop: "9px", }} onClick={setModalandsetEducation}> </AddCircleIcon>
                                        </LightTooltip>
                                    </span>
                                    : null
                            }

                        </Col>
                    </Row>
                </AccordionSummary>
                <Container className={classes.container}>

                    {
                        education.sort((a, b) => (a.year > b.year) ? -1 : ((b.year > a.year) ? 1 : 0)).map((item, index) => {
                            return ([
                                <Row key={uniqueId()} className={[classes.subheader, profileClassses.subheaderFirst]}>
                                    <Col className={classes.standardSize} md={6}>
                                        <span className={classes.subHeading}>{education[index].degree.trim().toUpperCase() + (education[index].area ? (", " + education[index].area.trim()) : "") + (education[index].year ? (", " + education[index].year) : "") + ""} </span>
                                        {
                                            (re.test(education[index].degree) === true) ?
                                                <span className={classes.standardSize}>
                                                    {/* <LightTooltip title={ (education[index].advisorFirstName!=undefined && education[index].advisorFirstName!="" && education[index].advisorFirstName.trim()!="") ?  ( "Advisor: "+ education[index].advisorFirstName+ " "+education[index].advisorLastName) : "Advisor info not available"    +  (10>20 ? "False" : "True")+ ( (education[index].coadvisorFirstName!=="" && education[index].coadvisorFirstName!=undefined) ? (" and Co-advisor: "+ education[index].coadvisorFirstName+ " "+ education[index].coadvisorLastName): "" ) } placement="top"> */}
                                                    <LightTooltip title={(education[index].advisorFirstName !== undefined && education[index].advisorFirstName.trim() !== "" ? (education[index].coadvisorFirstName !== undefined && education[index].coadvisorFirstName.trim() !== "" ? ('Advisor: ' + education[index].advisorFirstName + " " + education[index].advisorLastName + " and Co-advisor: " + education[index].coadvisorFirstName + " " + education[index].coadvisorLastName) : ('Advisor: ' + education[index].advisorFirstName + " " + education[index].advisorLastName)) : "Advisor Info Not Available")} placement="top">
                                                        <InfoIcon style={{ marginLeft: "0px", marginTop: "0px", color: 'gray' }} />
                                                    </LightTooltip>
                                                </span>
                                                :
                                                ((education[index].advisorFirstName !== undefined && education[index].advisorFirstName.trim() !== "") ?
                                                    <LightTooltip title={'Advisor: ' + education[index].advisorFirstName + " " + education[index].advisorLastName} placement="top">
                                                        <InfoIcon style={{ marginLeft: "0px", marginTop: "0px", color: 'gray' }} />
                                                    </LightTooltip>
                                                    :
                                                    null)
                                        }
                                    </Col>
                                    <Col md={5}></Col>
                                    <Col className={classes.standardSize} md={1}>
                                        {
                                            profileUpdateState === true ?
                                                <span style={{ cursor: "pointer" }}>
                                                    <LightTooltip title="Click to edit" placement="bottom">
                                                        <EditIcon style={{ width: '1em', height: '1em', color: editColor }} onClick={(re.test(education[index].degree) === true) ? () => editItems(education[index].id, true) : () => editItems(education[index].id, false)} ></EditIcon>
                                                    </LightTooltip>
                                                </span>
                                                : null
                                        }
                                    </Col>
                                </Row>,
                                <Row className={classes.bottomMargin}>
                                    <Col className={classes.standardSize} md={11}>
                                        <span className={classes.standardSize}>{education[index].institution}</span>
                                    </Col>
                                </Row>
                            ])
                        })
                    }
                    <AddEducationModal
                        curEducation={curEducation}
                        show={modalShow}
                        onSave={addEducation}
                        onHide={hideEducation} />
                    <AddPhdModal
                        curEducation={curEducation}
                        show={editPhdModalShow}
                        onSave={addEducation}
                        onHide={() => setEditPhdModalShow(false)} />
                    <AddEducationModal
                        curEducation={curEducation}
                        show={editEduModalShow}
                        onSave={addEducation}
                        onHide={hideEducationEdit} />
                    <ChoosePhdModal
                        education={education}
                        show={choosePhdModalShow}
                        onSave={saveChosenPHD} />
                </Container>
            </Accordion>
        </Grid>
    );
};

export default Education;