import { Tooltip } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
const RLChart = props => {
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const points = [40, 28, 90, 28, 100, 84, 57, 100, 20, 70, 40, 28];
    const {
        name,
        paper_per_year_in_top_journal_radius,
        citation_per_paper_radius,
        solo_paper_count_radius,
        h_index_score_radius,
        top_journal_percentage_radius,
        paper_per_year_in_top_journal,
        citation_per_paper,
        solo_paper_count,
        h_index_score,
        top_journal_percentage,
    } = props.data;

    return (
        <div>
            <div style={{ fontSize: 14, textAlign: "center" }}>
                {props.title ? `Average stats of ${props.title} Professor` : name}
            </div>
            <div style={{ textAlign: "center", margin: "auto" }}>
                <svg style={{display: "block", width: "120px", height: "120px", margin: "auto"}}>
                    <polyline points={points.toString()}
                        style={{ fill: "none", stroke: "#828282", strokeWidth: "4" }} />
                    <LightTooltip title={`Papers per year in A and A- journals: ${paper_per_year_in_top_journal}`} placement="top" arrow>
                        <circle cx={points[0]} cy={points[1]} r={paper_per_year_in_top_journal_radius} stroke-width="1" fill="#E24301" />
                    </LightTooltip>
                    <LightTooltip title={`Citations per paper in A and A- journals: ${citation_per_paper}`} placement="top" arrow>
                        <circle cx={points[2]} cy={points[3]} r={citation_per_paper_radius} stroke-width="1" fill="#BBB218" />
                    </LightTooltip>
                    <LightTooltip title={`Solo:coauthored papers: ${solo_paper_count}`} placement="top" arrow>
                        <circle cx={points[4]} cy={points[5]} r={solo_paper_count_radius} stroke-width="1" fill="#85276B" />
                    </LightTooltip>
                    <LightTooltip title={`H-index: ${h_index_score}`} placement="top" arrow>
                        <circle cx={points[6]} cy={points[7]} r={h_index_score_radius} stroke-width="1" fill="#F6A016" />
                    </LightTooltip>
                    <LightTooltip title={`A:B papers is just A+A-:B: ${top_journal_percentage}`} placement="top" arrow>
                        <circle cx={points[8]} cy={points[9]} r={top_journal_percentage_radius} stroke-width="1" fill="#00259A" />
                    </LightTooltip>
                </svg>
            </div>
        </div>
    )
}

export default RLChart;