import React, { useState, useEffect } from 'react';
import Loading from '../../../../assets/Images/loading.gif';
import TextInput from '../../../InputComponents/TextInput';
import { BACKEND_URL } from '../../../../utils';
import {  Container, Row, Col } from 'react-bootstrap';
import { OpenInNew} from '@material-ui/icons';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { KeyboardArrowRight } from '@material-ui/icons';
import AddNoteModal from './AddNoteModal';
import { Button, Grid } from '@material-ui/core';
import './MergeRecordPage.css';
import { useDispatch } from 'react-redux';
import * as mergeToolActions from '../../../../store/actions/mergeTool';

const Mergerecordpage = props => {
    
    // let author = props.location.state;
    const dispatch = useDispatch();
    const [userAuthor, setUserAuthor] = useState({});
    const [possibleAuthors, setPossibleAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState({"_id": undefined});
    const [isLoading, setIsLoading] = useState(true);
    const [paperTitle, setPaperTitle] = useState("");
    const [checked, setChecked] = useState([]);
    const [sliderEndIndex, setSliderEndIndex] = useState(4);
    const [isSearching, setIsSearching] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [modalState, setModalState] = useState("");
    
    useEffect(() => {
        // console.log("Called MergeRecord UseEffect hook");
        if (props.location.state) {
            setUserAuthor(props.location.state);
        }
        else {
            let user_author_id = props.location.pathname.split("/").pop();
            const token = localStorage.getItem("rl_token");
            let author = fetchUserAuthor(token, user_author_id)
            setUserAuthor(author);
        }
        fetchSearchResults();
        setIsLoading(false);
    },[]);

    const fetchUserAuthor = async (token, user_author_id) => {
        // let url = BACKEND_URL + "mergeAuthors/"+user_author_id;
        // const response = await fetch(url, {
        //     method:'GET',
        //     headers:{
        //         Authorization: 'Bearer '+ token,
        //     }
        // });
        // if(!response.ok) {
        //     const errorResData = await response.json();
        //     console.log(errorResData)
        //     throw new Error('Something went wrong while fetching user author to merge: ' +errorResData);
        // }
        let author = {
            "_id": "1",
            "last_name": "Data",
            "first_name": "Dummy"
        }
        return author;
    }

    const sliderBack = () => {
        // console.log("BACK");
        let newEnd = Math.max(4, sliderEndIndex - 4)
        setSliderEndIndex(newEnd);
        // console.log(newEnd);
    }

    const sliderNext = () => {
        // console.log("NEXT");
        let newEnd = Math.min(possibleAuthors.length, sliderEndIndex + 4);
        setSliderEndIndex(newEnd);
        // console.log(newEnd);
    }
    const fetchSearchResults = async () => {
        setIsSearching(true);
        let user_author_id = props.location.pathname.split("/").pop();
        const token = localStorage.getItem("rl_token");
        let url = BACKEND_URL + "tools/merge_author_search/"+user_author_id;
        const response = await fetch(url, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(response.status !== 200) {
            const errorResData = await response.json();
            // console.log(errorResData)
            throw new Error('Something went wrong while fetching search results: ' +errorResData);
        }
        let authors = await response.json();
        await setPossibleAuthors(authors);
        if (authors.length > 0) {
            await setSelectedAuthor(authors[0]);
        }
        else {
            await setSelectedAuthor({"_id":undefined})
        }
        setIsSearching(false);
        return authors;
    }

    const listToString = (list_of_string) => {
        let return_string = "";
        for (let i = 0; i < list_of_string.length; i++) {
            return_string = return_string + list_of_string[i]+"; ";
        }
        return return_string;
    }

    const formatWorkHistory = (work_history) => {
        if (work_history.school) {
            return work_history.title +" at "+ work_history.institution +" ("+ work_history.school+")" + " from "+work_history.from+" to " + work_history.to; 
        }
        return work_history.title +" at "+ work_history.institution + " from " + work_history.from+" to "+work_history.to; 
    }

    const formatEducation = (education) => {
        if (education.area) {
            return education.degree +" in "+education.area+" from "+ education.institution+" ("+education.year+")";
        }
        return education.degree +" from "+ education.institution+" ("+education.year+")";
    }

    if (isLoading === true) {
        return (
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <img src={Loading} alt="Loading..." width="80px" height="80px" />
          </div>
        )
    }

    const handlePaperTitleChange = (event) => {
        setPaperTitle(event.target.value);
    }

    const selectAuthorToCompare = (author) => {
        setSelectedAuthor(author);
    }
    

    const search = async() => {
        setIsSearching(true);
        const token = localStorage.getItem("rl_token");
        let url = `${BACKEND_URL}tools/merge_author_search/${userAuthor._id}?paper_title=${encodeURIComponent(paperTitle)}`
        const response = await fetch(url, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(response.status !== 200) {
            const errorResData = await response.json();
            // console.log(errorResData)
            setIsSearching(false);
            throw new Error('Something went wrong while fetching search results: ' +errorResData);
        }
        let authors = await response.json();
        await setPossibleAuthors(authors);
        if (authors.length > 0) {
            await setSelectedAuthor(authors[0]);
        }
        else {
            await setSelectedAuthor({"_id":undefined})
        }
        setIsSearching(false);
    }

    const goBack = () => {
        // console.log("GO BACK");
        props.history.replace({ 
            pathname: "/tools/mergeAuthorTool",
        });
    }

    const onMergeSave = async (note, toFlag) => {
        closeModal();
        if (!selectedAuthor._id) {
            alert("Please select an author to merge");
            return;
        }
        const token = localStorage.getItem("rl_token");
        await dispatch(mergeToolActions.mergeAuthors(token, userAuthor._id, selectedAuthor._id, false, note, toFlag));
        goBack();

    }

    const onNoMergeSave = async (note, toFlag) => {
        closeModal();
        const token = localStorage.getItem("rl_token");
        await dispatch(mergeToolActions.mergeAuthors(token, userAuthor._id, null, true, note, toFlag));
        goBack();
    }

    const generateGSLinkFromId  = (gs_id) => {
        return "https://scholar.google.com/citations?user="+gs_id;
    }

    const generateRGLinkFromId  = (rg_id) => {
        return "https://www.researchgate.net/profile/"+rg_id
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
      };

    const openModal = (value) => {
        setModalState(value);
        setModalShow(true);
    }

    const closeModal = () => {
        setModalState("");
        setModalShow(false);
    }

    return (
        <div>
            <h4 style={{marginLeft:"1rem"}}>Find a record to merge</h4>
            <Container className="merge-record-container">
                <h4>{userAuthor.last_name+", "+userAuthor.first_name + (userAuthor.mid_name ? " " + userAuthor.mid_name : "")}</h4>
                <p>{userAuthor.position +" at "}<b>{userAuthor.univ_name}</b></p>
                {userAuthor.email && <p><b>Email:</b> {userAuthor.email}</p>}
                {userAuthor.bio && <p><b>Bio:</b> {userAuthor.bio}</p>}
                {userAuthor.expertise && userAuthor.expertise.length > 0 && <p><b>Expertise:</b> {listToString(userAuthor.expertise)}</p>}
                {userAuthor.interests && userAuthor.interests.length > 0 && <p><b>Interests:</b> {listToString(userAuthor.interests)}</p>}
                {userAuthor.courses && userAuthor.courses.length > 0 && <p><b>Courses:</b> {listToString(userAuthor.courses)}</p>}
                {userAuthor.department && userAuthor.department.length > 0 && <p><b>Department:</b> {listToString(userAuthor.department)}</p>}
                {
                    (userAuthor.education!==undefined || userAuthor.work_history!==undefined) > 0 && 
                    <table>
                        <tbody>
                        {
                            userAuthor.education!==undefined && userAuthor.education.length > 0 ?
                            userAuthor.education.map((education, index) => {
                                    return (
                                    <tr key={index}>
                                        {index === 0 ?
                                            <td>
                                                <b>Education:</b>
                                            </td>
                                            :
                                            <td>
                                                
                                            </td>
                                        }
                                        <td>
                                        {formatEducation(education)}
                                        </td>
                                    </tr>)
                                })
                            :null
                        }
                        {
                            userAuthor.work_history!==undefined && userAuthor.work_history.length > 0 ?
                            userAuthor.work_history.map((history, index) => {
                                    return (
                                    <tr key={index}>
                                        {index === 0 ?
                                            <td>
                                                <b>Work History:</b>
                                            </td>
                                            :
                                            <td>
                                                
                                            </td>
                                        }
                                        <td>
                                        {formatWorkHistory(history)}
                                        </td>
                                    </tr>)
                                })
                            :null
                        }
                    </tbody>
                    </table>
                }
                <hr></hr>
                <p><b>Search by Paper:</b></p>
                <Grid container spacing={3}>
                    <Grid item xs={10} className="extraPadding">
                        <TextInput error={null} onChange={handlePaperTitleChange} onBlur={null} label="Paper Title"></TextInput>
                    </Grid>
                    <Grid item xs={2} className="extraPadding">
                        <Button variant="contained" className="searchButton" color="primary" onClick={search}>Search</Button>
                    </Grid>
                </Grid>
            </Container>
            {/* Search result container */}
            {
                isSearching === true ? 
                <Container className="merge-record-container">
                    <Row>
                        <Col md={12}>
                        <center><h5>Fetching similar authors...</h5></center>   
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                        <div style={{ textAlign: "center" }}>
                            <img src={Loading} alt="Loading..." width="80px" height="80px" />
                        </div>
                        </Col>
                    </Row>
                </Container>
            :   possibleAuthors.length < 1 ? 
            <Container className="merge-record-container">
                <Row>
                    <Col md={12}>
                    <center><h5>No authors found</h5></center>   
                    </Col>
                </Row>
            </Container>
            :
            <Container className="merge-record-container" style={{padding:"0px"}}>
                <Row>
                    <Col md={12}>
                        <center><h5>Select authors to compare and merge</h5></center>                    
                    </Col>
                </Row>
                <Row noGutters>
                <Col md={0.5}>
                    <div>
                        <KeyboardArrowLeft style={{marginTop:"60px", fontSize:"30px", cursor:"pointer"}} onClick={() => sliderBack()}></KeyboardArrowLeft>
                    </div>
                </Col>
                <Col>
                    <div className="result-container">
                        {
                            possibleAuthors.length > 0 && possibleAuthors.slice(Math.max(sliderEndIndex-4, 0), sliderEndIndex).map((author, index)=>{
                                return (
                                    <div key={author._id} className="result-item" style={author._id === selectedAuthor._id ? {backgroundColor:"#BBB21E"} : {}} onClick={()=>selectAuthorToCompare(author)}>
                                        <h6>{author.name}</h6>
                                        { 
                                            author.homepage_url &&
                                            <p className="profile-link">
                                                <a href={author.homepage_url} target="_blank" rel="noopener noreferrer">Homepage <OpenInNew style={{fontSize:"12"}}/></a>
                                            </p>
                                        }
                                        {
                                            author.gs &&
                                            <p className="profile-link">
                                                <a href={generateGSLinkFromId(author.gs.author_id)} target="_blank" rel="noopener noreferrer">Google Scholar<OpenInNew style={{fontSize:"12"}}/></a>
                                            </p>
                                        }
                                        {
                                            author.rg &&
                                            <p className="profile-link">
                                                <a href={generateRGLinkFromId(author.rg.author_id)} target="_blank" rel="noopener noreferrer">Researchgate<OpenInNew style={{fontSize:"12"}}/></a>
                                            </p>
                                        }
                                    </div>
                                )                            
                            })
                        }
                    </div>
                </Col>
                <Col md={0.5}>
                    <div>
                        <KeyboardArrowRight style={{marginTop:"60px", fontSize:"30px", cursor:"pointer"}} onClick={() => sliderNext()}></KeyboardArrowRight>
                    </div>
                </Col> 
                </Row>
            </Container>
            }
            <div className="profile-container">
                <div className = "two-profile-container">
                    <div className="individual-profile">
                        <Container>
                        <Row>
                            <Col>
                                <Row className="profile-header">
                                    <Col><h3> {userAuthor.last_name+", "+userAuthor.first_name +(userAuthor.mid_name ? " " + userAuthor.mid_name : "")}</h3></Col>
                                </Row>
                                <Row className="profile-link">
                                {
                                    userAuthor.cv && userAuthor.homepage_url!==null ?
                                    <Col sm={3} md={3}><a href={userAuthor.cv} target="_blank" rel="noopener noreferrer">CV <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                    : <Col sm={6} md={6}><a target="_blank" rel="noopener noreferrer">CV Unavailable</a></Col>
                                }
                                </Row> 
                            </Col>
                        </Row>
                        <hr></hr>
                        </Container>    
                    </div>
                    { selectedAuthor._id &&
                    <div className="individual-profile">
                        
                        <Container>
                        <Row>
                            <Col>
                                <Row className="profile-header">
                                    <Col><h3> {selectedAuthor.name}</h3></Col>
                                </Row>
                                <Row className="profile-link">
                                {
                                    selectedAuthor.homepage_url && selectedAuthor.homepage_url!==null ?
                                    <Col sm={3} md={3}><a href={selectedAuthor.homepage_url} target="_blank" rel="noopener noreferrer">Homepage <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                    : null
                                }
                                {
                                    selectedAuthor.gs ? 
                                        <Col sm={4} md={4}><a href ={generateGSLinkFromId(selectedAuthor.gs.author_id)} target="_blank" rel="noopener noreferrer">Google Scholar <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                    : null
                                }
                                {
                                    selectedAuthor.rg ? 
                                    <Col sm={4} md={4}><a href ={generateRGLinkFromId(selectedAuthor.rg.author_id)} target="_blank" rel="noopener noreferrer">Researchgate <OpenInNew style={{fontSize:"14"}}/></a></Col>
                                    : <Col sm={4} md={4}></Col>
                                }
                                </Row> 
                            </Col>
                        </Row>
                        <hr></hr>
                        </Container>
                    </div>
                    }    
                </div>
                {/* papers for comparison of both authors*/}
                <div className = "two-profile-container">
                    <div className="individual-profile">
                        <Container key={userAuthor._id}>
                        <Row className="profile-header">
                            <Col><h4>Papers</h4></Col>
                        </Row>
                        {
                            userAuthor.bow_papers && userAuthor.bow_papers.sort((a,b) => (a.publication_year > b.publication_year) ? -1 : ((b.publication_year > a.publication_year) ? 1 : 0)).map((item,index)=>{
                                // const labelId = `checkbox-list-label-${item.p_id}`;
                            return (
                                <Row key={item._id} className={index===0?"subheaderFirst": "subheader"} onClick={handleToggle(item.p_id)} > 
                                    {/* <Col md={1}>
                                        <Checkbox
                                            style={{padding:"0px"}}
                                            edge="start"
                                            checked={checked.indexOf(item.p_id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </Col> */}
                                    <Col md={12}>
                                        <p>{index+1+".  "}{item.document_title
                                        +
                                        ((item.document_title!==undefined && item.document_title.trim()!=="" && item.document_title.slice(-1)!=='?' && item.document_title.slice(-1)!=='!')?
                                        "."
                                        :
                                        "")}
                                        <i>{"  " + item.publication_name +"   ("+item.publication_year+")"}</i>
                                        </p>
                                    </Col>
                                </Row>
                            )
                            })
                        }
                        </Container>          
                    </div>
                    { selectedAuthor._id &&
                    <div className="individual-profile">
                        <Container>
                        <Row className="profile-header">
                            <Col><h4>Papers</h4></Col>
                        </Row>
                        {
                            selectedAuthor.papers && selectedAuthor.papers.sort((a,b) => (a.publication_year > b.publication_year) ? -1 : ((b.publication_year > a.publication_year) ? 1 : 0)).map((item,index)=>{
                            return (
                                <Row key={index} className={index===0?"subheaderFirst": "subheader"} > 
                                    <Col md={12}>
                                        <p>{index+1+".  "}{item.document_title
                                        +
                                        ((item.document_title!==undefined && item.document_title.trim()!=="" && item.document_title.slice(-1)!=='?' && item.document_title.slice(-1)!=='!')?
                                        "."
                                        :
                                        "")}
                                        <i>{"  " + item.publication_name +"   ("+item.publication_year+")"}</i>
                                        </p>
                                    </Col>
                                </Row>
                            )
                            })
                        }
                        </Container>          
                   </div>
                    }
                </div>
            </div>
            {/* Buttons Container*/}
            <Container className="merge-record-container">
                <Row>
                    <AddNoteModal show={modalShow} modalState={modalState} onMergeSave={onMergeSave} onNoMergeSave={onNoMergeSave} onHide={() => setModalShow(false)}/>
                </Row>
                <Row>
                    <Col md={1}>
                    </Col>
                    <Col md={3}>
                        <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={()=> openModal("merge")}>
                            Merge
                        </Button>
                    </Col>
                    <Col md={1}>
                    </Col>
                    {/* <Col md={3}>
                        <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={()=>onMergeClick()}>
                            Merge & Correct
                        </Button>
                    </Col> */}
                    <Col md={3}>
                        {/* <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={() => setModalShow(true)}>
                            Merge & remind later
                        </Button> */}
                    </Col>
                    <Col md={1}>
                    </Col>
                    <Col md={3}>
                        <Button className="buttons" style={{ width: "145px" }} variant="contained" onClick={()=>openModal("no-merge")}>
                            No Merge Required
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Mergerecordpage;
