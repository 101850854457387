import { createMuiTheme } from '@material-ui/core/styles';

const theme = props => {
    return createMuiTheme({
        palette: {
          primary: {
            main:"#00259A"
          },
          secondary: {
            main:"#BBB21E"
          },
        },
        status: {
          danger: 'orange',
        },
    });
}

export default theme;