import React, { useEffect, useCallback } from 'react';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import Paper from '@material-ui/core/Paper';
import classes2 from './CompareGender.module.css'
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../constants/Theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Container, Row, Col } from 'react-bootstrap';
import MultipleSelect from '../../../../components/InputComponents/MultipleSelect';
import { useDispatch, useSelector } from 'react-redux';
import * as compareGenderActions from '../../../../store/actions/compareGender';
import Loading from '../../../../assets/Images/loading.gif';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { BACKEND_URL } from '../../../../utils';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

charts(FusionCharts);
const CompareGender = props => {
    const dispatch = useDispatch();
    const theme = Theme();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuPropsStyles = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
        table: { borderTopWidth: 1, borderColor: "red", borderStyle: "solid" }
    };
    const token = localStorage.getItem("rl_token");
    const [showDeptToConsiderError, setShowDeptToConsiderError] = React.useState(false);
    const [univIDS, setunivIDS] = React.useState([]);
    const [deptnames, setDeptnames] = React.useState([]);
    const [completedUniversities, setCompletedUniversities] = React.useState([]);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [selectedUniversityName, setSelectedUniversityName] = React.useState([]);
    const [selectedDeptName, setSelectedDeptName] = React.useState([]);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const category = useSelector(state => state.compareGender.category);
    const maleData = useSelector(state => state.compareGender.maleData);
    const femaleData = useSelector(state => state.compareGender.femaleData);
    const othersData = useSelector(state => state.compareGender.othersData);
    const otherUniversities = useSelector(state => state.compareGender.otherUniversities);
    const selectedDeptNameRef = React.useRef(selectedDeptName);
    const univIDSRef = React.useRef(univIDS);
    const showDeptToConsiderErrorRef = React.useRef(showDeptToConsiderError);
    const univ_name = useSelector(state => state.univProfile.name);
    let graph_caption = "Gender Distribution ";
    if (selectedDeptName.length === 0) {
        graph_caption = "";
    } else if (selectedDeptName.length === 1 && selectedDeptName[0] === "all") {
        graph_caption += "of all departments";
    } else if (selectedDeptName.length === 1) {
        graph_caption += `of ${selectedDeptName[0]} department`;
    } else {
        graph_caption += "of " + selectedDeptName.map((dept, i) => {
            return `${i < selectedDeptName.length - 1 ? `${dept}, ` : ""}`
        }).join("") + `and ${selectedDeptName[selectedDeptName.length - 1]} departments`;
    }

    const getUniversityOptions = async () => {
        let url = BACKEND_URL + "get_universities/completed"
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            alert("Unable to fetch Universities Data.")
        }
        else {
            const response_data = await response.json();
            const index = response_data.findIndex(item => item.name === univ_name);
            if (index !== -1) {
                response_data.splice(index, 1);
            }
            setCompletedUniversities(response_data);
        }
    };

    const getDepts = () => {
        (async () => {
            const url = BACKEND_URL + 'get_departments';
            let tokens = localStorage.getItem("rl_token");
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + tokens,
                }
            });
            const responseData = await response.json();
            var depts = []
            depts.push("all")
            if (responseData.departments !== undefined && responseData.length !== 0) {
                responseData.departments.map((data) => {
                    depts.push(data);
                })
            }
            setDeptnames(depts)
        })();
    }
    const handleUserKeyPress = useCallback(event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (showDeptToConsiderErrorRef.current !== true) {
                handleAccordionExpand();
                setShowSpinner(true);
                (async () => {
                    try {
                        await dispatch(compareGenderActions.compareGenderSearch(token, univIDSRef.current, selectedDeptNameRef.current));
                        setShowSpinner(false);
                    }
                    catch (error) {
                        setShowSpinner(false);
                        alert("There was an error in loading the data. Please try again after sometime.");
                    }
                })();
            }
        }
    }, []);

    useEffect(() => {
        setunivIDS([])
        univIDSRef.current = [];
        getUniversityOptions();
        setTimeout(getDepts(), 2000);
        setSelectedDeptName(["all"])
        selectedDeptNameRef.current = ["all"]
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress, selectedDeptName, univIDS]);

    const handleDeptChange = (event) => {
        if (event && event.target && event.target.value) {
            var departments = []
            var alldepart = false;
            var values = event.target.value;
            if (values.length === 0) {
                setShowDeptToConsiderError(true);
                showDeptToConsiderErrorRef.current = true;
            } else {
                setShowDeptToConsiderError(false);
                showDeptToConsiderErrorRef.current = false;
            }
            for (let i = 0; i < values.length; i++) {
                if (event.target.value[i] === "all") {
                    alldepart = true;
                    break;
                } else {
                    departments.push(event.target.value[i]);
                }
            }
            if (alldepart === true) {
                setSelectedDeptName(["all"]);
                selectedDeptNameRef.current = ["all"]
            } else {
                setSelectedDeptName(departments);
                selectedDeptNameRef.current = departments
            }
        }
    };

    const dataSource = {
        "chart": {
            "caption": graph_caption,
            "captionPadding": "30",
            "xAxisname": "University",
            "yAxisName": "Percentage",
            "yAxisMinValue": "0",
            "yAxisMaxValue": "100",
            "theme": "fusion",
            "usePlotGradientColor": 0,
            "showPercentValues": true,
            "showPercentInToolTip": true,
            "showPlotBorder": false,
            "showAlternateHGridColor": false,
            "legendItemFontColor": "000000",
            "drawCustomLegendIcon": true,
            "legendIconBorderThickness": 0,
            "xAxisNameFontSize": 15,
            "yAxisNameFontSize": 15,
            "captionFontSize": 20,
            "captionFontColor": "#000000",
            "captionFontBold": false,
            "xAxisNameFontBold": false,
            "yAxisNameFontBold": false,
            "valueFontColor": "FFFFFF"
        },
        "categories": [
            {
                "category": category
            }
        ],
        "dataset": [
            {
                "seriesname": "Male",
                "color": "00259A",
                "data": maleData
            },
            {
                "seriesname": "Female",
                "color": "E24301",
                "data": femaleData,
                "fontColor": "#FFFFFF"
            },
            {
                "seriesname": "Others",
                "color": "BBB218",
                "data": othersData
            }
        ]
    };


    const compareGenderSearch = async () => {
        if (showDeptToConsiderError !== true) {
            handleAccordionExpand();
            setShowSpinner(true);
            try {
                await dispatch(compareGenderActions.compareGenderSearch(token, univIDS, selectedDeptName));
                setShowSpinner(false);
            }
            catch (error) {
                setShowSpinner(false);
                alert("There was an error in loading the data. Please try again after sometime.");
            }
        }
    };

    const getOptionSelectedUniv = (option, value) => {
        return option.name === value.name
    };

    const getOptionLabelUniv = (option) => {

        if (!option || !option.name) {
            return "";
        }
        return option.name

    };

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={classes2.profile}>
                    Compare Gender
                </div>
                <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                    <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />} >
                        <div style={{ float: "left" }}>
                            Considering the gender distribution of &nbsp;
                            {
                                selectedDeptName.length === 0
                                    ? ""
                                    : selectedDeptName.map((dept, i) => {
                                        return (
                                            <span>{dept} {i !== selectedDeptName.length - 1 ? ", " : ""}</span>
                                        );
                                    })
                            }
                            &nbsp;
                            {selectedDeptName.length === 1 && selectedDeptName[0] !== "all" ? "department of" : "departments of"}
                            &nbsp;
                            {
                                selectedUniversityName.length === 0
                                    ? ""
                                    : selectedUniversityName.map((univ, i) => {
                                        return (
                                            <span>{univ}{i !== selectedUniversityName.length - 1 ? ", " : ""}</span>
                                        );
                                    })
                            }
                            {selectedUniversityName.length === 0 ? "your University" : " and your University"}.
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container>
                            <Row md={12} className={classes2.row}>
                                <Col md={12} style={{ paddingBottom: "10px" }}>
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        id="tags-filled"
                                        options={completedUniversities}
                                        getOptionSelected={getOptionSelectedUniv}
                                        getOptionLabel={getOptionLabelUniv}
                                        onChange={(e, value) => {
                                            value.sort((a, b) => a.name < b.name ? -1 : 1)
                                            if (value.length <= 12) {
                                                var univids = [];
                                                var univNames = [];
                                                for (let j = 0; j < value.length; j++) {
                                                    univids.push(value[j]._id)
                                                    univNames.push(value[j].name)
                                                }
                                                setunivIDS(univids);
                                                univIDSRef.current = univids;
                                                setSelectedUniversityName(univNames);
                                                setunivIDS(univids);
                                                univIDSRef.current = univids;
                                            }
                                        }}
                                        renderTags={(value, getTagProps) => {
                                            if (value.length > 12) {
                                                value.splice(-1, 1)
                                            }
                                            return (
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                                                ))
                                            )
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField {...params} variant="filled" label="University" />
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row md={12} className={classes2.row}>
                                <Col md={6}>
                                    <MultipleSelect error={showDeptToConsiderError} onChange={handleDeptChange} options={deptnames}
                                        value={selectedDeptName} menuProps={MenuPropsStyles} label="Select departments to compare" helperText="Select a Department" />
                                </Col>
                                <Col md={6}>
                                    <Button variant="contained" className="searchButton" color="primary" onClick={compareGenderSearch}>Search</Button>
                                </Col>
                            </Row>
                        </Container>
                    </AccordionDetails>
                </Accordion>

                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }

                {category.length > 0 &&
                    <div>
                        <div>
                            <p style={{ margin: "10px 0px" }} />
                            <InfoIcon style={{ marginTop: "-3px", color: "#555559", fontSize: "larger" }} />
                            <span style={{ fontSize: "15px", marginLeft: 5 }}>Gender is first determined by a faculty's bio, then facial recognition and then their name. If the faculty has opened an account with ResearchLoupe and have their profile verified, then the information entered by them takes precedence. This information is available only for those universities for whom we have complete information on their faculty.</span>
                        </div>
                        <div className={classes2.formCard} style={{ height: "500px" }}>
                            <ReactFusioncharts
                                type="stackedcolumn2d"
                                width="100%"
                                height="90%"
                                dataFormat="JSON"
                                dataSource={dataSource}
                            />
                        </div>
                        <TableContainer component={Paper} className={classes2.formCard}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes2.tableprop}>
                                        <TableCell colSpan={3} className={classes2.tableprop}></TableCell>
                                        <TableCell colSpan={3} align="center" className={classes2.tableprop} style={{ fontWeight: "bold" }}>Assisstant Professor</TableCell>
                                        <TableCell colSpan={3} align="center" className={classes2.tableprop} style={{ fontWeight: "bold" }}>Associate Professor</TableCell>
                                        <TableCell colSpan={3} align="center" className={classes2.tableprop} style={{ fontWeight: "bold" }}>Professor</TableCell>
                                    </TableRow>
                                    <TableRow className={classes2.tableprop}>
                                        <TableCell colSpan={3} align="center" className={classes2.tableprop} style={{ fontWeight: "bold" }}>Universities</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} >Male</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#DEDEDE' }}>Female</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#BDBDBD' }}>Others</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop}>Male</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#DEDEDE' }}>Female</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#BDBDBD' }}>Others</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop}>Male</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#DEDEDE' }}>Female</TableCell>
                                        <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#BDBDBD' }}>Others</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {otherUniversities.map((other) => (
                                        <TableRow className={classes2.tableprop}>
                                            <TableCell colSpan={3} align="left" className={classes2.tableprop}>{other.name}</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop}>{other.AssisstantProfessor.male}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#DEDEDE' }}>{other.AssisstantProfessor.female}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#BDBDBD' }}>{other.AssisstantProfessor.other}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop}>{other.AssociateProfessor.male}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#DEDEDE' }}>{other.AssociateProfessor.female}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#BDBDBD' }}>{other.AssociateProfessor.other}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop}>{other.Professor.male}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#DEDEDE' }}>{other.Professor.female}%</TableCell>
                                            <TableCell colSpan={1} align="center" className={classes2.tableprop} style={{ backgroundColor: '#BDBDBD' }}>{other.Professor.other}%</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            </div>
        </ThemeProvider>
    );
};

export default CompareGender;