import React from 'react';
import '../../Dashboard/Views/Explore/Explore.css';
import Explore from '../../Dashboard/Views/Explore/Explore';

const EditAuthor = (props) => {
    return (
        <Explore data={{from: 'editAuthor'}}/>
    )
};

export default EditAuthor;