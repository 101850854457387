import React, { useState, useEffect } from 'react';
import { BACKEND_URL } from '../../../../../utils';
import { useSelector } from 'react-redux';
import rl_icon from '../../../../../assets/Images/logos/RL_Icon.jpg';
import clsx from 'clsx';
import { Button } from "@material-ui/core";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import classes2 from './Profile.module.css';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    backgroundColor: '#555559'
  },
  name: {
    marginTop: 1,
    textAlign: "center"
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const profileState = useSelector(state => state.univProfile);
  const classes = useStyles();
  const token = useSelector(state => state.auth.token);
  const [file, setFile] = useState("");
  useEffect(() => {
    if (profileState) {
      setFile(profileState.profile_picture === undefined || profileState.profile_picture === ""
        ? rl_icon : profileState.profile_picture)
    }
  }, [profileState]);
  const univ = {
    name: profileState.name,
    bio: ''
  };
  const uploadFileHandler = (event) => {
    if (URL && (event.target.files).length !== 0) {
      const image = URL.createObjectURL(event.target.files[0])
      const fileName = event.target.files[0].name;
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('filename', fileName);
      formData.append('univ_id', profileState._id)
      fetch(BACKEND_URL + 'profile/upload_picture', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        body: formData,
      }).then((response) => {
        if (response.status !== 200) {
          alert('Failed to update Profile Picture.')
        } else {
          setFile(image)
        }
      });
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >

      <div className={classes2.container}>
        <img src={file === "" ? rl_icon : file} className={classes2.avatar} alt="Profile Image" />
        <div className={classes2.overlay} >
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={uploadFileHandler.bind(this)}
          />
          <label htmlFor="raised-button-file">
            <Button component="span" className={classes.button} fullWidth>
              <span style={{ color: 'white' }}>Click to change</span>
            </Button>
          </label>
        </div>
      </div>
      <Typography
        className={classes2.name}
        variant="subtitle1"
      ><b>
          {univ.name}
        </b>
      </Typography>
      <Typography variant="body2" className={classes2.name}>{univ.bio}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;