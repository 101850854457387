import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_URL, convertArrayToSummary } from '../../../../utils';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../../../constants/Theme';
import classes2 from './TenureReadiness.module.css';
import './TenureReadiness.css';
import * as tenureReadinessActions from '../../../../store/actions/tenureReadiness';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Grid, Avatar, Typography, Switch } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MultipleSelect from '../../../../components/InputComponents/MultipleSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Loading from '../../../../assets/Images/loading.gif';
import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";

ReactFC.fcRoot(FusionCharts, FusionTheme);
const defaultFaculty = require('../../../../assets/Images/default_faculty.png');

const TenureReadiness = props => {

  const token = localStorage.getItem("rl_token");
  const theme = Theme();
  const dispatch = useDispatch();
  const [accordionExpand, setAccordionExpand] = React.useState(true);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [universityOptions, setUniversityOptions] = React.useState([]);
  const [selectedUniversities, setSelectedUniversities] = React.useState([]);
  const [showDeptToConsiderError, setShowDeptToConsiderError] = React.useState(false);
  const [showUnivToConsiderError, setShowUnivToConsiderError] = React.useState(false);
  const deptnames = useSelector(state => state.univProfile.departments_list);
  const [selectedDeptName, setSelectedDeptName] = React.useState([]);
  const [universityNameInfo, setUniversityNameInfo] = React.useState(" Universities");
  const [universityIDs, setUniversityIDs] = React.useState([]);
  const selectedDeptNameRef = React.useRef(selectedDeptName);
  const showDeptToConsiderErrorRef = React.useRef(showDeptToConsiderError);
  const [switchChecked, setswitchChecked] = React.useState(false);
  const [sortBy, setSortBy] = React.useState('Sort by University');
  const tenureReadiness = useSelector(state => state.tenureReadiness.tenureReadiness);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuPropsStyles = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    table: { borderTopWidth: 1, borderColor: "red", borderStyle: "solid" }
  };
  const borderCSS = { padding: "10px" };
  const secondaryColor = theme.palette.secondary.main;
  const BlueSwitch = withStyles({
    switchBase: {
      color: secondaryColor
    },
    track: {
      color: secondaryColor,
      backgroundColor: secondaryColor
    },
    '&$checked': {
      backgroundColor: secondaryColor,
      color: secondaryColor
    },
  })(Switch);

  const getFacultyChart = (index, data) => {

    const chartOptions = {
      chart: {
        theme: "fusion",
        showLegend: "0",
        showLabels: "1",
        yAxisMaxValue: "300",
        adjustDiv: "0",
        numDivLines: "5",
      },
      categories: [{
        category: [{ label: "Papers<br>Citations" }]
      }],
      dataset: [{
        seriesname: "Papers",
        data: [{
          value: data["percentPaper"],
          toolText: String(data["name"]) + " | Papers: " + String(data["papers"]) + " | Paper %: " + String((data["percentPaper"]).toFixed(2)),
        }],
        color: "#85276B"
      },
      {
        seriesname: "Citations",
        data: [{
          value: data["percentCitation"],
          toolText: String(data["name"]) + " | Citations: " + String(data["citations"]),
        }],
        color: "#00259A"
      }],
      trendlines: [{
        line: [{
          startvalue: "100",
          color: "#F6A016",
          valueOnRight: "1",
          displayvalue: " ",
          thickness: "2",
          showOnTop: true,
          toolText: "Average",
        }]
      }]
    };
    return chartOptions;
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 550,
      overflow: "auto",
      align: "center",
    },
    name: {
      fontSize: "150%",
      color: "#000000",
      height: 30,
      justifyContent: "center",
      width: "100%"
    },
    univName: {
      fontSize: "100%",
      color: "#000000",
      height: 20,
      justifyContent: "center",
      width: "100%"
    },
    gridInfo: {
      height: 20,
      fontSize: "90%",
      color: "#000000",
      padding: "10px",
      width: "100%"
    },
    scroll: {
      maxHeight: "120px",
      overflowY: "auto"
    },
    span1: {
      borderRadius: "50%",
      float: "left",
      width: "12px",
      height: "12px",
      margin: "2% 1px 2% 1px"
  },
    c1: { backgroundColor: "#E24301" },
  }));
  const classes = useStyles();

  const handleAccordionExpand = () => {
    setAccordionExpand(!accordionExpand);
  };

  const onUniversityChange = (...params) => {

    let tempUniversities = [];
    if (params[1].length === 0) {
      setShowUnivToConsiderError(true);
    } else {
      setShowUnivToConsiderError(false);
    }
    if (params[2] === "clear") {
      tempUniversities = [];
      setUniversityNameInfo(" Universities");
    }
    else {
      let selectedOption = params[3].option;
      let universityNames = [];
      let univIDs = [];
      if (selectedOption["name"] === "All") {
        if (params[2] === "select-option") {
          tempUniversities = universityOptions;
        }
        else if (params[2] === "remove-option") {
          tempUniversities = [];
        }
        universityNames.push("All Universities");

      }
      else {
        if (params[2] === "select-option") {
          tempUniversities = [selectedOption].concat(selectedUniversities);
        }
        else if (params[2] === "remove-option") {
          tempUniversities = selectedUniversities.filter(univ => univ["name"] !== "All" && univ["name"] !== selectedOption["name"]);
        }
        tempUniversities.sort((a, b) => a["name"].localeCompare(b["name"]))
        tempUniversities.map((univ, i) => {
          universityNames.push(univ.name);
        });
      }
      tempUniversities.map((univ, i) => {
        if (i === 0 && selectedOption["name"] === "All");
        else
          univIDs.push(univ["_id"]);
      });
      setUniversityNameInfo(convertArrayToSummary(universityNames));
      setUniversityIDs(univIDs);
    }
    setSelectedUniversities(tempUniversities);
  };

  const handleDeptChange = (event) => {
    if (event && event.target && event.target.value) {
      var departments = []
      var alldepart = false;
      var values = event.target.value;
      if (values.length === 0) {
        setShowDeptToConsiderError(true);
        showDeptToConsiderErrorRef.current = true;
      } else {
        setShowDeptToConsiderError(false);
        showDeptToConsiderErrorRef.current = false;
      }
      for (let i = 0; i < values.length; i++) {
        if (event.target.value[i] === "All") {
          alldepart = true;
          break;
        } else {
          departments.push(event.target.value[i]);
        }
      }
      if (alldepart === true) {
        setSelectedDeptName(["All"]);
        selectedDeptNameRef.current = ["All"]
      } else {
        setSelectedDeptName(departments);
        selectedDeptNameRef.current = departments
      }
    }
  };

  const handleSwitchChange = (event) => {
    if (event.target.name === 'Sort by University') {
      setswitchChecked(true);
    }
    else {
      setswitchChecked(false);
    }
    setSortBy(event.target.name === 'Sort by University' ? 'Sort by Statistics' : 'Sort by University');
  };

  const getUniversityOptions = async (token) => {
    let url = BACKEND_URL + "get_universities/completed";
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      }
    });
    if (response.status !== 200) {
      alert("Unable to fetch Universities Data.")
    }
    else {
      const response_data = await response.json();
      setUniversityOptions([{ "name": "All" }].concat(response_data));
    }
  };

  const tenureSearch = async () => {
    if (showDeptToConsiderError !== true && selectedUniversities.length !== 0) {
      handleAccordionExpand();
      setShowSpinner(true);
      const token = localStorage.getItem("rl_token");
      try {
        await dispatch(tenureReadinessActions.getTenureReadiness(token, universityIDs, selectedDeptName));
        setShowSpinner(false);
      }
      catch (error) {
        setShowSpinner(false);
        alert("Somthing went wrong while fetching the data!");
      }
    } else if (selectedUniversities.length === 0) {
      setShowUnivToConsiderError(true);
    }
  };

  useEffect(() => {
    getUniversityOptions(token);
    setSelectedDeptName(["All"]);
    selectedDeptNameRef.current = ["All"];
  }, []);

  const getTenureReadiness = () => {

    let facultyReadiness = [];
    if (sortBy === 'Sort by University') {
      tenureReadiness.sort((a, b) =>
        a.univ_name.localeCompare(b.univ_name) || a.name.localeCompare(b.name))
    } else {
      tenureReadiness.sort((a, b) =>
        a.color.localeCompare(b.color) || b.A - a.A || b["A-"] - a["A-"] || b.B - a.B)
    }
    tenureReadiness.map((faculty, i) => {
      facultyReadiness.push(<TableRow key={i} />);
      facultyReadiness.push(
        <TableCell align="center" style={borderCSS}>
          <div className={faculty.color + " universityNamePadding cellStyle"}>
            <Grid container wrap="nowrap" spacing={3} direction="row">
              <Grid item xs={1} zeroMinWidth>
                {faculty["profile_picture"] !== "" ?
                  (<Avatar alt={faculty.name} src={faculty.profile_picture} style={{ height: 75, width: 75 }} />)
                  :
                  (<Avatar style={{ height: 75, width: 75 }}>
                    <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                  </Avatar>)
                }
              </Grid>
              <Grid item xs={4} zeroMinWidth>
              
                <Typography className={classes.name} justify="center">
                  
                  {faculty.unusual_work_hist == 1? <span className={`${classes.c1} ${classes.span1}`}></span>: null }

                  
                  {faculty.name}
                </Typography>
                <Typography className={classes.univName}>
                  {faculty.univ_name}
                </Typography>
                <Typography className={classes.univName}>
                  Department: {convertArrayToSummary(faculty.dept)}
                </Typography>
              </Grid>
              <Grid item xs={2} zeroMinWidth>
                <Typography className={classes.gridInfo} justify="center">
                  H-index: {faculty["h-index"]} | Position Start Year: {faculty['start_year']}
                </Typography>
                <Typography className={classes.gridInfo} justify="center">
                  Percentage of Solo Papers: {faculty.solo}%
                </Typography>
                <Typography className={classes.gridInfo} justify="center">
                  A: {faculty["A"]} | A-: {faculty["A-"]} | B: {faculty["B"]}
                </Typography>
              </Grid>
              <Grid item xs={5} zeroMinWidth>
                <Typography>
                  <ReactFusioncharts type="msbar2d" width="75%" height="95" dataFormat="JSON" dataSource={getFacultyChart(i, faculty)} />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </TableCell>
      );
    });
    return facultyReadiness;
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
        <div className={classes2.profile}>
          Tenure Readiness
        </div>
        <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
          <AccordionSummary style={{ borderBottom: "solid 1px #E0E0E0" }} expandIcon={<ExpandMoreIcon />} >
            <div style={{ float: "left" }}>
              Faculty Tenure Readiness of
              {selectedDeptName.length === 0 ? "" : convertArrayToSummary(selectedDeptName)}
              {selectedDeptName.length === 1 && selectedDeptName[0] !== "all" ? " Department of " : " Departments of "}
              {selectedUniversities.length === 0 ? "Universities" : universityNameInfo}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Container>
              <Row md={12} className={classes2.row}>
                <Col md={12} style={{ paddingBottom: "10px" }}>
                  <Autocomplete
                    multiple
                    size="small"
                    onChange={onUniversityChange}
                    value={selectedUniversities}
                    limitTags={3}
                    id="checkboxes-tags-demo"
                    options={universityOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => {
                      const selectUniversityIndex = selectedUniversities.findIndex(
                        university => university.name === option.name
                      );
                      if (selectUniversityIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.name}
                        </React.Fragment>)
                    }
                    }
                    fullWidth={true}
                    renderInput={(params) => (
                      <TextField error={showUnivToConsiderError} menuProps={MenuPropsStyles} {...params} variant="filled" label="University" placeholder="Select university" />
                    )}
                  />
                </Col>
              </Row>
              <Row md={12} className={classes2.row}>
                <Col md={6}>
                  <MultipleSelect error={showDeptToConsiderError} onChange={handleDeptChange} options={["All", ...deptnames]}
                    value={selectedDeptName} menuProps={MenuPropsStyles} label="Select Departments" />
                </Col>
                <Col md={6}>
                  <Button variant="contained" className="searchButton" color="primary" onClick={tenureSearch} >Search</Button>
                </Col>
              </Row>
            </Container>
          </AccordionDetails>
        </Accordion>
        {showSpinner &&
          <div style={{ textAlign: "center" }}>
            <img src={Loading} alt="Loading..." width="80px" height="80px" />
          </div>
        }
        {!showSpinner && (tenureReadiness.length > 0) &&
          <div>
            <InfoIcon style={{ marginTop: "-3px", color: "#555559", fontSize: "larger" }} />
            <span style={{ fontSize: "15px", marginLeft: 5 }}>
              The average line on the bar chart represents the historically expected average number of papers and citations of Assistant Professors at the end of the year before receiving tenure at that school. A red dot next to faculty name indicates unusual work history.
            </span>
            <Paper>
              <span style={{ padding: "10px 20px 0px 0px", float: "right" }}>
                <span>Sort by University</span>
                <BlueSwitch color="secondaryColor" checked={switchChecked} onChange={handleSwitchChange} name={sortBy} />
                <span>Sort by Statistics</span>
              </span>
              <TableContainer component={Paper} className={classes.container}>
                <Table>
                  <TableBody>
                    {getTenureReadiness()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        }
      </div>
    </ThemeProvider>
  );
};

export default TenureReadiness;