import React, { useState, useEffect } from 'react';
import './BasicProfile.css';
import {Container, Row, Col} from 'react-bootstrap';
import {OpenInNew} from '@material-ui/icons';
import Loading from '../../../assets/Images/loading.gif';
import Tooltip from '@material-ui/core/Tooltip';
import {Clear} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { BACKEND_URL } from '../../../utils';
import { Link } from 'react-router-dom';

const Basicprofile = (props) => {
    const [author, setAuthor] = useState({});
    const [removedNameVariations, setRemovedNameeVariation] = useState([]);
    const [removedPapers, setRemovedPapers] = useState([]);
    const [papersToHighlight, setPapersToHighlight] = useState(new Set());
    const [isLoading, setIsLoading] = useState(true);

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }))(Tooltip);

    const getAuthorDetails = async (token, author_id) => {
        const response = await fetch(BACKEND_URL + `profile/`+author_id, {
            method:'GET',
            headers:{
                Authorization: 'Bearer '+ token,
            }
        });
        if(!response.ok) {
            const errorResData = await response.json();
            // console.log(errorResData)
            throw new Error('Something went wrong while fetching logs with error: ' +errorResData);
        }
        const author = await response.json();
        setIsLoading(false)
        // console.log(author);
        setAuthor(author);
    }

    const generateGSLinkFromId  = (gs_id) => {
        return "https://scholar.google.com/citations?user="+gs_id;
    }

    const generateRGLinkFromId  = (rg_id) => {
        return "https://www.researchgate.net/profile/"+rg_id
    }

    useEffect(() => {
        // console.log("Called Basic Profile UseEffect hook");
        //fetch profile from data._id
        const token = localStorage.getItem("rl_token");
        let highlightedPapers = new Set();
        if (props.data.papers_with_10_year_gap) {
          for (let i = 0; i < props.data["papers_with_10_year_gap"].length; i++) {
            let temp = [...props.data["papers_with_10_year_gap"][i]];
            for (let j = 0; j < temp.length; j++) {
              highlightedPapers.add(temp[j]["_id"]);
            }
          }
        }
        setPapersToHighlight(highlightedPapers);
        // console.log(highlightedPapers);
        //TODO: Need to change it to .author_details_id
        // console.log(props.data);
        if (props.data.error === 'RG_NAME_MISMATCH' || props.data.error === 'GS_NAME_MISMATCH') {
            getAuthorDetails(token, props.data.author_detail_id === undefined ? props.data.mongo_author_id : props.data.author_detail_id);
        } else {
            getAuthorDetails(token, props.data.author_id === undefined ? props.data.mongo_author_id : props.data.author_id);
        }

    },[]);

    const removeNameVariation = (index) => {
        let removedName = author.name_variations[index];
        let updatedAuthorRecord = {...author};
        updatedAuthorRecord.name_variations.splice(index, 1);
        let updatedRemovedNameVariation = [...removedNameVariations];
        updatedRemovedNameVariation.push(removedName);
        setAuthor(updatedAuthorRecord);
        setRemovedNameeVariation(updatedRemovedNameVariation);
    };

    const addNameVariation = (index) => {
        let addedName = removedNameVariations[index];
        let updatedRemovedNameVariation = [...removedNameVariations];
        updatedRemovedNameVariation.splice(index, 1);
        let updatedAuthorRecord = {...author};
        updatedAuthorRecord.name_variations.push(addedName);
        setAuthor(updatedAuthorRecord);
        setRemovedNameeVariation(updatedRemovedNameVariation);
    }

    const removePaper = (index, paper_id) => {
        let removedPaper = author.papers[index];
        let updatedAuthorRecord = {...author};
        let updatedRemovedPapers = [...removedPapers];
        updatedAuthorRecord.papers.splice(index, 1);
        updatedRemovedPapers.push(removedPaper);
        setAuthor(updatedAuthorRecord);
        setRemovedPapers(updatedRemovedPapers);
    }

    const addPaper = (index, paper_id) => {
        let paperToAdd = removedPapers[index];
        let updatedAuthorRecord = {...author};
        let updatedRemovedPapers = [...removedPapers];
        updatedRemovedPapers.splice(index, 1);
        updatedAuthorRecord.papers.push(paperToAdd);
        setAuthor(updatedAuthorRecord);
        setRemovedPapers(updatedRemovedPapers);

    }
    if (isLoading === true) {
      return (
        <div key={author._id} style={{ textAlign: "center" }}>
          <img src={Loading} alt="Loading..." width="80px" height="80px" />
        </div>
      )
    }
    return (
        <div>
            <Container className="profile-container-basic-profile">
            <Row>
            <Col md={10} sm={10}>
                
                <Row className="profile-header-basic-profile">
                    <Col sm={11} md={11}><h3> {author.name === undefined ? author.last_name+", "+author.first_name+" "+(author.mid_name === undefined ? "" : author.mid_name): author.name}</h3></Col>
                </Row>
                <Row>
                    <Col sm={11} md={11}><p>{(author.position ? author.position +" at ": "") + (author.univ_name ? author.univ_name : "")}</p></Col>
                </Row>
                <Row className="profile-link-basic-profile">
                {
                    author.homepage_url && author.homepage_url!==null ?
                    <Col sm={3} md={3}><a href={author.homepage_url} target="_blank" rel="noopener noreferrer">Homepage <OpenInNew style={{fontSize:"14"}}/></a></Col>
                    : null
                }
                {
                    author.cv && author.cv!==null ?
                    <Col sm={3} md={3}><a href={author.cv} target="_blank" rel="noopener noreferrer">CV <OpenInNew style={{fontSize:"14"}}/></a></Col>
                    : null
                }
                {
                    author.gs ? 
                        <Col sm={3} md={3}><a href ={generateGSLinkFromId(author.gs.author_id)} target="_blank" rel="noopener noreferrer">Google Scholar <OpenInNew style={{fontSize:"14"}}/></a></Col>
                    : null
                }
                {
                    author.rg ? 
                    <Col sm={3} md={3}><a href ={generateRGLinkFromId(author.rg.author_id)} target="_blank" rel="noopener noreferrer">Researchgate <OpenInNew style={{fontSize:"14"}}/></a></Col>
                    : null
                }
                {
                    <Col sm={3} md={3}>
                        <Link to={{
                                    pathname: "/dashboard/resumeUpload",
                                }}
                                target="_blank" 
                                rel="noopener noreferrer" >
                        Mark CV<OpenInNew style={{fontSize:"14"}}/>
                        </Link>
                    </Col>
                }
                </Row> 
            </Col>
            </Row>
            </Container>
            {
            props.showNameVariations === true && 
            <Container className="profile-container-basic-profile">
                <Row className="profile-header-basic-profile">
                    <Col sm={11} md={11}><h4>Name Variations</h4></Col>
                </Row>
                {
                    author.name_variations && author.name_variations.map((name, index)=>{
                        return (
                            [
                                <Row key={index}>
                                    <Col md={props.showEditOption === false ? 12 : 11}>{name}</Col>
                                    { props.showEditOption === true ? 
                                      <Col md={1}>
                                          <span style={{cursor:"pointer"}}>
                                              <Clear style={{width:'1em',height:'1em'} } onClick={()=>removeNameVariation(index)} />
                                          </span>        
                                      </Col>
                                      : null
                                    }
                                </Row>
                            ]
                        );
                    })
                }
                { 
                  props.showEditOption === true ? <hr></hr> :null
                }
                { props.showEditOption === true ?
                  <Row className="profile-header-basic-profile">
                    <Col sm={11} md={11}><h4>Removed Name Variations</h4></Col>
                  </Row>
                  : null
                }

                {
                    props.showEditOption && removedNameVariations.map((name, index)=>{
                        return (
                            [
                                <Row key={index}>
                                    <Col md={11}>{name}</Col>
                                    <Col md={1}>
                                        <span style={{cursor:"pointer"}}>
                                            <Clear style={{width:'1em',height:'1em'} } onClick={()=>addNameVariation(index)} />
                                        </span>
                                    </Col>
                                </Row>
                            ]
                        );
                    })
                }
            </Container>
            }
            <Container className="profile-container-basic-profile">
                <Row className="profile-header-basic-profile">
                    <Col sm={11} md={11}><h4>Papers</h4></Col>
                </Row>
                {
                    author.papers && author.papers.sort((a,b) => (a.publication_year > b.publication_year) ? -1 : ((b.publication_year > a.publication_year) ? 1 : 0)).map((item,index)=>{
                    return (
                        [
                        <Row key={item._id} className={index===0?"subheaderFirst-basic-profile": "subheader-basic-profile"} > 
                            <Col md={props.showEditOption === false ? 12 : 11}>
                                <span className="standardSize-basic-profile" style={papersToHighlight.has(item["_id"]) ? {backgroundColor:"yellow"} : null}>
                                {index+1+"."}
                                &nbsp;&nbsp;
                                {
                                    (!item.authors)? null:
                                    item.authors.map((author,i)=>{
                                    return (
                                      <span key={i}>
                                        <span className="standardSizeUnderline-basic-profile">
                                            {(author.first_name!==undefined && author.first_name.trim()!=="" ? author.first_name+(((author.mid_name!==undefined && author.mid_name.trim()!=="") || (author.last_name!==undefined && author.last_name.trim()!=="")) ? " " : "") : "")+(author.mid_name!==undefined && author.mid_name.trim()!=="" ? author.mid_name+((author.last_name!==undefined && author.last_name.trim()!=="")? " ": ""): "")+ 
                                            (author.last_name!==undefined && author.last_name.trim()!=="" ? author.last_name+"": "")}
                                        </span>
                                        <span className="standardSize-basic-profile">
                                        {(i===(item.authors.length-1) ? " ": (i===(item.authors.length-2) ? "; and ": "; ")   )}
                                        </span>
                                        <span>
                                            {" "}
                                        </span>
                                    </span>
                                    )
                                    })
                                }
                                &nbsp;&nbsp;
                                {item.document_title
                                +
                                ((item.document_title!==undefined && item.document_title.trim()!=="" && item.document_title.slice(-1)!=='?' && item.document_title.slice(-1)!=='!')?
                                "."
                                :
                                "")}
                                </span>
                                <span className="italics-basic-profile">
                                &nbsp;&nbsp;
                                    {item.publication_name}
                                    </span>
                                    <span className="standardSize-basic-profile">
                                    &nbsp; &nbsp;&nbsp;
                                    {"("+item.publication_year+")"}
                                </span>
                            </Col>
                            {
                              props.showEditOption === true ? 
                              <Col md={1}>
                                <span style={{cursor:"pointer"}}>
                                  <LightTooltip title="Wrongly mapped? Click to remove." placement="bottom">
                                  <DeleteIcon style={{width:'1em',height:'1em'} }onClick={()=>removePaper(index,item._id)} />
                                  </LightTooltip>
                                </span>
                              </Col> 
                              : null
                            }

                        </Row>,
                        // <Row>
                        //     <Col md={10}>
                                
                        //     </Col>
                        // </Row>
                        ]
                    )
                    })
                }
                { 
                  props.showEditOption === true ? <hr></hr> :null
                }
                {
                  props.showEditOption === true ? 
                  <Row className="profile-header-basic-profile">
                    <Col sm={11} md={11}><h4>Removed Papers</h4></Col>
                  </Row>
                  :null
                }
                {
                    props.showEditOption && removedPapers.sort((a,b) => (a.publication_year > b.publication_year) ? -1 : ((b.publication_year > a.publication_year) ? 1 : 0)).map((item,index)=>{
                    return (
                        [
                        <Row key={item._id} className={index===0?"subheaderFirst-basic-profile": "subheader-basic-profile"} > 
                            <Col md={11}>
                                <span className="standardSize-basic-profile" >
                                {index+1+"."}
                                &nbsp;&nbsp;
                                {
                                    (!item.authors)? null:
                                    item.authors.map((author,i)=>{
                                    return (
                                        <span >
                                        <span className="standardSizeUnderline-basic-profile">
                                            {(author.first_name!==undefined && author.first_name.trim()!=="" ? author.first_name+(((author.mid_name!==undefined && author.mid_name.trim()!=="") || (author.last_name!==undefined && author.last_name.trim()!=="")) ? " " : "") : "")+(author.mid_name!==undefined && author.mid_name.trim()!=="" ? author.mid_name+((author.last_name!==undefined && author.last_name.trim()!=="")? " ": ""): "")+ 
                                            (author.last_name!==undefined && author.last_name.trim()!=="" ? author.last_name+"": "")}
                                        </span>
                                        <span className="standardSize-basic-profile">
                                        {(i===(item.authors.length-1) ? " ": (i===(item.authors.length-2) ? "; and ": "; ")   )}
                                        </span>
                                        <span>
                                            {" "}
                                        </span>
                                    </span>
                                    )
                                    })
                                }
                                &nbsp;&nbsp;
                                {item.document_title
                                +
                                ((item.document_title!==undefined && item.document_title.trim()!=="" && item.document_title.slice(-1)!=='?' && item.document_title.slice(-1)!=='!')?
                                "."
                                :
                                "")}
                                </span>
                                <span className="italics-basic-profile">
                                &nbsp;&nbsp;
                                    {item.publication_name}
                                    </span>
                                    <span className="standardSize-basic-profile">
                                    &nbsp; &nbsp;&nbsp;
                                    {"("+item.publication_year+")"}
                                </span>
                            </Col>
                            <Col md={1}>
                                <span style={{cursor:"pointer"}}>
                                <LightTooltip title="Wrongly mapped? Click to remove." placement="bottom">
                                <DeleteIcon style={{width:'1em',height:'1em'} }onClick={()=>addPaper(index,item._id)} />
                                </LightTooltip>
                                </span>
                            </Col>
                        </Row>,
                        // <Row>
                        //     <Col md={10}>
                                
                        //     </Col>
                        // </Row>
                        ]
                    )
                    })
                }
            </Container>
            {
              props.showEditOption === true ?
              <Container className="profile-container-basic-profile">
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Button className="buttons-basic-profile" style={{ width: "145px" }} variant="contained" onClick={()=>props.onResolve(removedNameVariations, removedPapers)}>
                        Resolve
                    </Button>
                  </Col>
                  <Col md={1}></Col>
                  
                  <Col md = {3}>
                    <Button className="buttons-basic-profile" style={{ width: "145px" }} variant="contained" onClick={()=>props.onCancel()}>
                        Back
                    </Button>
                  </Col>
                </Row> 
              </Container>
              : null
            } 
        </div> 
    )
}

export default Basicprofile;
