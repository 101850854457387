import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const hireImpactAction = (data) => {
    return {
        type: actionTypes.HIRE_IMPACT,
        hireImpactData: data
    }
}
const getAuthorsAction = (data) => {
    return {
        type: actionTypes.GET_AUTHORS,
        authors: data
    }
}
const getDepartmentsAction = (data) => {
    return {
        type: actionTypes.GET_DEPARTMENTS,
        departments: data
    }
}

export const hireImpact = (token, department, facultyId, facultiesToConsider, journal_ids) => {

    return async dispatch => {
        const response = await fetch(BACKEND_URL + `hireImpact?hiringCandidateId=${facultyId}&department=${department}&facultyIds=${facultiesToConsider}&journal_ids=${journal_ids}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while doing compare departments with error: ' + errorResData);
        }
        const hireImpactData = await response.json();
        dispatch(hireImpactAction(hireImpactData));
    };
}

export const getAuthors = (token, department) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dashboard/get_faculty_by_department?department=${department}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while doing compare departments with error: ' + errorResData);
        }
        const data = await response.json();
        // const data = [{first_name:"d",last_name:"v",id:"dsdads"},{first_name:"a",last_name:"s",id:"dsdads"},{first_name:"h",last_name:"a",id:"dsdads"}];
        dispatch(getAuthorsAction(data));
    };
}

export const getDepartments = (token, universityId) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `dept?id=` + universityId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while searching for ' + universityId + ' with error: ' + errorResData);
        }
        const departmentData = await response.json();
        dispatch(getDepartmentsAction(departmentData));
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_HIRE_IMPACT });
    }
}
