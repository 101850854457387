import React from 'react';
import classes2 from './redflags.module.css';
import { Button, Container, Row, Col } from 'react-bootstrap';
import SingleSelectAutoComplete from '../../../InputComponents/SingleSelectAutoComplete';
import {BACKEND_URL} from '../../../../utils';
import Loading from '../../../../assets/Images/loading.gif';
import ReactFusioncharts from "react-fusioncharts";

const FacultyPapersByUniversity = props =>{
    
  const [curTextUniv,setcurTextUniv]=React.useState("")
  const [univoptions, setunivOptions] = React.useState([]);
  const [curUnivID,setcurUnivID]=React.useState("")
  const [category,setCategory]=React.useState([])
  const [papersData,setPapersData]=React.useState([])
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [searched,setSearched]=React.useState(false)
    const getResponse =()=>{
      setSearched(true)
      setShowSpinner(true)
        const url = BACKEND_URL +'red_flag/faculty_papers_by_university?university_id='+curUnivID;
        (async () => {
            const response = await fetch(url,{
              method:'GET'
            });
            const jsonData= await response.json();
            if(!jsonData|| !jsonData.response || !jsonData.response[0] || !jsonData.response[0].faculty){
              let faculty=[]
              let papers=[]
              setCategory(faculty)
              setPapersData(papers)
            }else{
            const resData=jsonData.response[0].faculty;
            let faculty=[]
            let papers=[]
            resData.map((k)=>{
              faculty.push({"label": k["faculty_name"]})
              papers.push({"value": k["papers_published"].toString()})
           })
           setCategory(faculty)
           setPapersData(papers)
          }
           
           setShowSpinner(false)
          })();
      }
      const dataSource = {
        "chart": {
          "caption": "Faculty Paper Distribution",
          "xAxisname": "Faculty",
          "yAxisName": "Papers",
          "theme": "fusion",
          "usePlotGradientColor": 0,
          "showPlotBorder": false,
          "showAlternateHGridColor": false,
          "legendItemFontColor": "000000",
          "drawCustomLegendIcon": true,
          "legendIconBorderThickness": 0,
          "xAxisNameFontSize": 15,
          "yAxisNameFontSize": 15,
          "captionFontSize": 20,
          "captionFontColor": "#000000",
          "captionFontBold": false,
          "xAxisNameFontBold": false,
          "yAxisNameFontBold": false,
          "valueFontColor": "FFFFFF"
        },
        "categories": [
          {
            "category": category
          }
        ],
        "dataset": [
          {
            "color": "00259A",
            "data": papersData
          }
        ]
      };
    const handleClick= ()=>{
        getResponse();
    }
    const onUnivInputChangeHandler= (event,newInputValue) =>{
      setcurTextUniv(newInputValue)
      if(!newInputValue || newInputValue.trim()===""){
            return;
        }
        const url = BACKEND_URL +'auto/univ/'+ newInputValue;
        (async () => {
            const response = await fetch(url);
            const univs = await response.json();
           var p=Object.keys(univs).map((key) => univs[key]);
           setunivOptions(p[0]);
          })();
  }
  const onUnivChange=(event,value)=>{
    if(value && !(Object.keys(value).length === 0 && value.constructor === Object)){
       setcurTextUniv(value.name)
       setcurUnivID(value.univ_id)
       }
  }
  const getOptionLabelUniv=(option) => {
    
        if(!option || !option.name){
            return "";
        }
    return option.name
  }
    return (
        <div>
          <div className={classes2.formCard}>
          <Container >
                <Row md={12} className={classes2.row}>
                    <Col md={2}>
                    </Col>
                    <Col md={4}>
                    <SingleSelectAutoComplete onInputChange={onUnivInputChangeHandler} options={univoptions}  
                      curText={curTextUniv}
                      onChange={onUnivChange}
                      getOptionLabel={getOptionLabelUniv}
                      label={"Enter University Name"}></SingleSelectAutoComplete>
                       </Col>
                    <Col md={4}>
                    <Button variant="contained" className="searchButton" color="primary" onClick={(e)=>handleClick()}>Click to Search</Button>
                    </Col>
                    <Col md={2}>
                    </Col>
                </Row>
                </Container>
          </div>
          {
              searched===true && showSpinner===true &&
              <div style={{ textAlign: "center" }}>
                <img src={Loading} alt="Loading..." width="80px" height="80px" />
              </div>
          }
          {
              searched===true && showSpinner===false &&
             <div className={classes2.formCard} style={{ height: "500px" }}>
             <ReactFusioncharts
               type="stackedcolumn2d"
               width="100%"
               height="90%"
               dataFormat="JSON"
               dataSource={dataSource}
             />
           </div>
          }
        </div>
    )
}

export default FacultyPapersByUniversity;