import React, { useEffect, useCallback } from 'react';
import {
    Button, Paper, Grid
} from '@material-ui/core';
import './CompareYourself.css';
import Loading from '../../../../assets/Images/loading.gif';
import * as compareYourselfActions from '../../../../store/actions/compareYourself';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FacultyInfoInput from '../../../InputComponents/FacultyInfoInput';
import Compareyourselfresult from './compareYourselfResult';

const useStyles = makeStyles((theme) => ({
    infoTableGrid: {
        paddingRight: "0px !important", paddingTop: "0px !important", paddingBottom: "0px !important",
    },
    infoTableValue: {
        padding: "0px 12px 0px 0px !important"
    },
    BG: {
        backgroundColor: "#F2F2F2"
    },
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    searchButton: {
        color: "black !important",
        width: "100% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    },
}));
const CompareYourself = props => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const faculty1 = useSelector(state => state.compareYourself.faculty1);
    const faculty2 = useSelector(state => state.compareYourself.faculty2);
    const [showSpinner, toggleShowSpinner] = React.useState(false);
    const papersChartseries = useSelector(state => state.compareYourself.papersChartSeries);
    const citationsChartSeries = useSelector(state => state.compareYourself.citationsChartSeries);
    const chartCategories = useSelector(state => state.compareYourself.chartCategories);
    const [facultyId, setFacultyId] = React.useState('');
    const facultyIdRef = React.useRef(facultyId);
    console.log(faculty1)
    console.log(faculty2)

    const handleUserKeyPress = useCallback(event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (checkValidity()) {
                toggleShowSpinner(true);
                const token = localStorage.getItem("rl_token");
                (async () => {
                    try {
                        await dispatch(compareYourselfActions.compareYourselfSearch(token, facultyIdRef.current));
                    }
                    catch (err) {
                        console.log(err);
                        alert("Internal Server Error. Please try again later");
                    }
                })();
                toggleShowSpinner(false);
            }
            else {
                alert("Fill all the input fields");
            }
        }
    }, []);
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const search = async () => {
        if (checkValidity()) {
            toggleShowSpinner(true);
            const token = localStorage.getItem("rl_token");
            try {
                await dispatch(compareYourselfActions.compareYourselfSearch(token, facultyId));
            }
            catch (err) {
                console.log(err);
                alert("Internal Server Error. Please try again later");
            }
            toggleShowSpinner(false);
        }
        else {
            alert("Fill all the input fields");
        }
    }
    const checkValidity = () => {
        let isDataValid = true;
        if (facultyId === '' && facultyIdRef.current === '') {
            isDataValid = false;
        }
        return isDataValid;
    }
    const setFacultyInfo = (facultyInfo) => {
        setFacultyId(facultyInfo.id);
        facultyIdRef.current = facultyInfo.id;
    }

    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <div className={classes.featureHeading}>
                Compare Yourself
            </div>
            <Paper square style={{ padding: "20px 17px 25px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <FacultyInfoInput index={0} setFacultyId={setFacultyInfo} />
                    </Grid>
                    <Grid item xs={3} container direction="column" alignItems="center" justify="center">
                        <Button variant="contained" className={classes.searchButton} color="primary" onClick={search} >Compare</Button>
                    </Grid>
                </Grid>
            </Paper>
            {(showSpinner) &&
                <div style={{ textAlign: "center" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
            }
            {chartCategories.length > 0 &&
                <Compareyourselfresult faculty1={faculty1} faculty2={faculty2} papersChartseries={papersChartseries} citationsChartSeries={citationsChartSeries} chartCategories={chartCategories} />
            }
        </div>

    );
}

export default CompareYourself;