import { FIND_SIMILAR_AUTHORS_BEFORE_ADDING_NEW_AUTHOR } from './actionTypes';
import { BACKEND_URL } from '../../utils';
import axios from 'axios';

const findSimilarAuthorsAction = (authors) => {
    return {
        type: FIND_SIMILAR_AUTHORS_BEFORE_ADDING_NEW_AUTHOR,
        payload: authors
    }
}

export const fetchSimilarAuthors = (token, firstName, lastName) => {
    return async dispatch => {
        let url = BACKEND_URL + `add_author_tool/auth_search?first_name=${firstName}&last_name=${lastName}`
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (response.status !== 200) {
            const errorResData = await response.json();
            throw new Error('Something went wrong while fetching similar author to add with error: ' + errorResData);
        }
        const response_data = await response.json();
        dispatch(findSimilarAuthorsAction(response_data.authors));
    }
}

export const addNewAuthor = async (token, data) => {
    let url = BACKEND_URL + "add_author_tool/add";
    const response = await axios.post(url, data,
        {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }
    );
    if (response.status !== 200) {
        const errorResData = await response.json();
        console.log(errorResData)
        throw new Error('Something went wrong while adding new Author: ' + errorResData);
    }
    const author_id_created = await response.data.author_id;
    return author_id_created;
}