import React from 'react';
import { FormControl, InputLabel, FilledInput } from '@material-ui/core';
import './InputComponents.css';
const TextInput = props => {
    const {
        onChange,
        onBlur,
        label,
        error,
        value,
        type,
        required,
        onKeyDown
    } = props;
    return (
        <FormControl variant="filled" style={{ width: "100%"}}>
            <InputLabel className="blackColor verticalCenter">{label}{required?" *":""}</InputLabel>
            <FilledInput className='textInput' type={type?type:"text"} value={value } onChange={onChange} onBlur={onBlur} onKeyDown={onKeyDown}
                error={error} fullWidth disableUnderline style={{ height: 40 }}
            />
        </FormControl>
    );
}

export default TextInput;
