import React from 'react';
import {Container} from 'react-bootstrap';
import classes from './ForOthers.module.css';
import SwingComponent from '../../swing/SwingComponent';
import Subscribe from '../../Subscribe/Subscribe';

const forOthers = props => {
    return (
    <div>
        <Subscribe type={"SpecialReports"}/>
        <Container>
            <div className={classes.container}>
            <p className={classes.description}>SPECIAL REPORTS</p> 
            <SwingComponent/>
            </div>
        </Container>
    </div>
    )
}

export default forOthers;