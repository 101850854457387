import React, {useEffect} from 'react';
import { Redirect, Route } from 'react-router-dom'
import { useSelector} from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token2 = useSelector(state => state.auth.token);
    useEffect(() => {
     console.log("Private route login use effect")
     console.log(token2);
    });

    const token = localStorage.getItem("rl_token")
  

    console.log(token);
    console.log(token2);
    // Add your own authentication on the below line.
  
    return (
      <Route
        {...rest}
        render={props =>
          token!==null ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }
      />
    )
  }
  
  export default PrivateRoute