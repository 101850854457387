import { BACKEND_URL } from '../../utils';

export const getButtonDetails = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `generate_university_stats`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        if (!response.ok) {
            throw new Error('Something went wrong');
        }
        const details = await response.json();
        return details;
    };
}

export const generateUnivstats = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `generate_university_stats`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            body: {}
        });
        return await response.json();
    };
};

export const generateTenurestats = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `generate_tenure_data`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            body: {}
        });
        return await response.json();
    };
};

export const generateFirstYearCareer = (token) => {
    return async dispatch => {
        const response = await fetch(BACKEND_URL + `generate_first_year_career`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return await response.json();
    };
};