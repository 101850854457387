import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    departmentBubbleChartSeries: [],
    schoolBubbleChartSeries: [],
    comparisonTableData: [],
    hiringAuthorData: { 'id': "", 'first_name': "Deval", 'last_name': "Vora", 'total_papers': 41, 'total_citations': 200, 'h_index': 23, 'proportion': '6:3' },
    universityData: {
        "univ_id": "",
        "name": "University of Southern California",
        "average_papers": 45,
        "average_citations": 60,
        "average_department_papers": 20,
        "average_department_citations": 5,
        "average_citations_before_hire": 2200,
        "average_citations_after_hire": 2300,
        "average_papers_before_hire": 2200,
        "average_papers_after_hire": 2300,
        "selected_faculty_average_papers": 30,
        "selected_faculty_average_citations": 30,
        "total_papers": 26,
        "total_citations": 26,
    },
    // departments: ["s","s","s"],
    departments: [],
    departmentToJournalsDict: [],
    reverseJournalsDict: {},
    authors: [],
    authorsNameToIdDict: {},
}

const hireImpactReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HIRE_IMPACT:
            //table
            let comparisonTableData = [];
            for (let facultyId in action.hireImpactData.selected_author_data) {
                let faculty = action.hireImpactData.selected_author_data[facultyId];

                comparisonTableData.push({
                    name: `${faculty.first_name} ${faculty.last_name}`,
                    papers: faculty.total_papers,
                    citations: faculty.total_citations,
                    papersPerYear: faculty.papers_per_year,
                    citationsPerPaper: faculty.citations_per_paper
                })
            }
            // for (let faculty of action.hireImpactData.selected_author_data) {
            //     comparisonTableData.push({
            //         name: `${faculty.first_name} ${faculty.last_name}`,
            //         papers: faculty.total_papers,
            //         citations: faculty.total_citations,
            //         papersPerYear: faculty.papers_per_year,
            //         citationsPerPaper: faculty.citaitons_per_paper
            //     })
            // }
            comparisonTableData.push({
                name: `${action.hireImpactData.hiring_author_data.first_name} ${action.hireImpactData.hiring_author_data.last_name}`,
                papers: action.hireImpactData.hiring_author_data.total_papers,
                citations: action.hireImpactData.hiring_author_data.total_citations,
                papersPerYear: action.hireImpactData.hiring_author_data.papers_per_year,
                citationsPerPaper: action.hireImpactData.hiring_author_data.citations_per_paper
            })

            // bubble chart    
            let departmentBubbleChartSeries = [];
            let schoolBubbleChartSeries = [];

            let currentUniv = action.hireImpactData.university_data;
            schoolBubbleChartSeries.push({
                name: `${currentUniv.name}` + " Before",
                data: [[currentUniv.uni_average_papers_before_hire, currentUniv.uni_average_citations_before_hire, 18]],
                abbreviation: `${currentUniv.abbreviation}` + " Before"
            })
            schoolBubbleChartSeries.push({
                name: `${currentUniv.name}` + " After",
                data: [[currentUniv.uni_average_papers_after_hire, currentUniv.uni_average_citations_after_hire, 18]],
                abbreviation: `${currentUniv.abbreviation}` + " After"
            })

            departmentBubbleChartSeries.push({
                name: `${currentUniv.name}` + " Before",
                data: [[currentUniv.dep_average_papers_before_hire, currentUniv.dep_average_citations_before_hire, 18]],
                abbreviation: `${currentUniv.abbreviation}` + " Before"
               
            })
            departmentBubbleChartSeries.push({
                name: `${currentUniv.name}` + " After",
                data: [[currentUniv.dep_average_papers_after_hire, currentUniv.dep_average_citations_after_hire, 18]],
                abbreviation: `${currentUniv.abbreviation}` + " After"
               
            })

            // for (let univ of action.hireImpactData.comparable_univ_data) {
            //     departmentBubbleChartSeries.push({
            //         name: univ.name,
            //         data: [[univ.average_department_papers, univ.average_department_citations, 30]]
            //     });
            //     schoolBubbleChartSeries.push({
            //         name: univ.name,
            //         data: [[univ.average_papers, univ.average_citations, 30]]
            //     })
            // }
            for (let univId1 in action.hireImpactData.comparable_univ_data) {
                let univ = action.hireImpactData.comparable_univ_data[univId1];
                schoolBubbleChartSeries.push({
                    name: univ.name,
                    data: [[univ.average_papers, univ.average_citations, 18]],
                    abbreviation: `${univ.abbreviation}`
                })
            }

            for (let univId1 in action.hireImpactData.comparable_dept_data) {
                let univ = action.hireImpactData.comparable_dept_data[univId1];
                departmentBubbleChartSeries.push({
                    name: univ.name,
                    // data: [[univ.average_department_papers_per_faculty, univ.average_department_citations_per_faculty, 30]]
                    data: [[univ.average_department_papers, univ.average_department_citations, 18]],
                    abbreviation: `${univ.abbreviation}`
                });
            }

            return updateObject(state, {
                departmentBubbleChartSeries: departmentBubbleChartSeries,
                schoolBubbleChartSeries: schoolBubbleChartSeries,
                comparisonTableData: comparisonTableData,
                hiringAuthorData: action.hireImpactData.hiring_author_data,
                universityData: action.hireImpactData.university_data,
            });
        case actionTypes.GET_DEPARTMENTS:
            let departmentToJournalsDict = {};
            let depts = [];
            let reverseJournalsDict = {};
            for (let i = 0; i < action.departments.departments.length; i++) {
                let journals = [];

                depts.push(action.departments.departments[i]['name']);
                for (let journal of action.departments.departments[i]["A"]) {
                    journals.push(action.departments.journals[journal]);
                    reverseJournalsDict[action.departments.journals[journal]] = journal;
                }
                for (let journal of action.departments.departments[i]["A-"]) {
                    journals.push(action.departments.journals[journal]);
                    reverseJournalsDict[action.departments.journals[journal]] = journal;
                }
                for (let journal of action.departments.departments[i]["B"]) {
                    journals.push(action.departments.journals[journal]);
                    reverseJournalsDict[action.departments.journals[journal]] = journal;
                }
                if (journals.length === 0) {
                    for (let default_dept of action.departments[i]["default_mapping"]) {
                        for (let j in action.departments.default_mapping) {
                            if (default_dept in action.departments.default_mapping[j]) {
                                let journals1 = action.departments.default_mapping[j][default_dept]
                                for (let journal of journals1["A"]) {
                                    journals.push(action.departments.journals[journal]);
                                    reverseJournalsDict[action.departments.journals[journal]] = journal;
                                }
                                for (let journal of journals1["A-"]) {
                                    journals.push(action.departments.journals[journal]);
                                    reverseJournalsDict[action.departments.journals[journal]] = journal;
                                }
                                for (let journal of journals1["B"]) {
                                    journals.push(action.departments.journals[journal]);
                                    reverseJournalsDict[action.departments.journals[journal]] = journal;
                                }
                            }
                        }
                    }
                }
                departmentToJournalsDict[action.departments.departments[i]['name']] = journals
            }
            return updateObject(state, { departments: depts, departmentToJournalsDict: departmentToJournalsDict, reverseJournalsDict: reverseJournalsDict });
        case actionTypes.GET_AUTHORS:
            let authors = []
            let authorsNameToIdDict = {}
            for (let author of action.authors) {
                let name = `${author.faculty_name}`
                authors.push(name);
                authorsNameToIdDict[name] = author._id
            }
            return updateObject(state, { authors: authors, authorsNameToIdDict: authorsNameToIdDict });
        case actionTypes.CLEAR_HIRE_IMPACT:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default hireImpactReducer;