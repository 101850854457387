import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

const getSimilarFacultyAction = (data) => {
    return {
        type: actionTypes.GET_SIMILAR_FACULTY,
        searchResult: data
    }
}

export const getSimilarFaculty = (firstName, lastName, univId) => {

    return async dispatch => {
        const params = {
            fn: firstName.toLowerCase(),
            ln: lastName.toLowerCase(),
            uid: univId,
        }
        let url = new URL(BACKEND_URL + `/signup`)
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {
            method: 'GET'
        });
        if (!response.ok) {
            const errorResData = await response.json();
            console.log(errorResData)
            throw new Error('Something went wrong while searching for ' + firstName + ' with error: ' + errorResData);
        }
        const facultyData = await response.json();
        dispatch(getSimilarFacultyAction(facultyData));
    };
}

export const toggleModal = (value) => {
    return async dispatch => {
        dispatch({ type: actionTypes.TOGGLE_MODAL, value: value });
    };
}

export const setConfirmedFacultyId = (id, pic, facultyDetails) => {
    return async dispatch => {
        dispatch({ type: actionTypes.SET_FACULTY_ID, id: id, pic: pic, data: facultyDetails});
    };
}

export const clearData = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_DATA });
    };
}