import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  const token = localStorage.getItem("rl_token");
  const isProfileComplete = useSelector(state => state.profile.profile.is_complete);
  const userType = useSelector(state => state.auth.user_type);
  return (
    <Route
      {...rest}
      render={matchProps => 
        token != null ? (
        userType === 'faculty' && localStorage.getItem("rl_token_a") == null && isProfileComplete === false && props.path !== '/dashboard/profileEdit' ? (
          <Redirect to={{ pathname: '/dashboard/profileEdit', state: { from: matchProps.location } }} />
        ) : (
        <Layout>
          <Component {...matchProps} />
        </Layout>
        )) : (
        <Redirect to={{ pathname: '/login', state: { from: matchProps.location } }} />
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;