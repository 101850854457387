import React, { useEffect, useCallback } from 'react';
import {
    Button, Paper, Grid, Typography, List, ListItemText,
    ListItem, ListItemAvatar, Avatar
} from '@material-ui/core';
import TextInput from '../../../InputComponents/TextInput';
import './ManualMapping.css';
import Loading from '../../../../assets/Images/loading.gif';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from '../../../../utils';
import UnivAutoCompleteWithBlur from '../../../Autocomplete/UnivAutoCompleteWithBlur'

const defaultFaculty = require('../../../../assets/Images/default_faculty.png');
const useStyles = makeStyles((theme) => ({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    }
}));
const ManualMapping = props => {
    const history = useHistory();
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [showSpinner, setShowSpinner] = React.useState(false);
    // const authorSearchResult = useSelector(state => state.explore.authorSearchResult);
    //const paperSearchResult = useSelector(state => state.explore.paperSearchResult);
    const [authors, setAuthors] = React.useState([]);
    const classes = useStyles();
    const curTextUniv = "";
    const curUnivID = "";

    const curTextUnivRef = React.useRef(curTextUniv)
    const curUnivIDRef = React.useRef(curUnivID)
    const firstNameRef = React.useRef(firstName)
    const lastNameRef = React.useRef(lastName)

    const handleUserKeyPress = useCallback(event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (curTextUnivRef.current && curTextUnivRef.current.trim() !== "") {
                setShowSpinner(true);
                try {
                    (async () => {
                        const params = {
                            fn: firstNameRef.current.toLowerCase(),
                            ln: lastNameRef.current.toLowerCase(),
                            uid: curUnivIDRef.current,
                        }
                        let url = new URL(BACKEND_URL + `/signup`)
                        url.search = new URLSearchParams(params).toString();
                        const response = await fetch(url, {
                            method: 'GET'
                        });
                        const jsonData = await response.json();

                        if (jsonData) {
                            setAuthors(jsonData.authors)
                        }
                        setShowSpinner(false)
                    })();
                    setShowSpinner(false);
                }
                catch (error) {
                    setShowSpinner(false);
                    alert("Internal Server Error. Please try again after some time")
                }
            }

        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        firstNameRef.current = event.target.value;
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
        lastNameRef.current = event.target.value;
    }


    const [univId, setUnivId] = React.useState('');

    const [university, setUniversity] = React.useState("");

    const onInputUniversityChangeHandler = (event, newInputValue) => {
        universityChangeHandler(newInputValue, newInputValue)
        if (!newInputValue || newInputValue.trim() === "") {
            return;
        }
        const url = BACKEND_URL + 'auto/univ/' + newInputValue;
        (async () => {
            const response = await fetch(url);
            const schools = await response.json();
            if (schools.universities.length === 1) {
                setUnivId(schools.universities[0].univ_id);
            }
        })();
    }

    const universityChangeHandler = (name, value) => {
        setUniversity(name);
    }
    const search = async () => {
        if (university && university.trim() !== "") {
            setShowSpinner(true);
            try {
                (async () => {
                    const params = {
                        fn: firstName.toLowerCase(),
                        ln: lastName.toLowerCase(),
                        uid: univId,
                    }
                    let url = new URL(BACKEND_URL + `/signup`)
                    url.search = new URLSearchParams(params).toString();
                    const response = await fetch(url, {
                        method: 'GET'
                    });
                    const jsonData = await response.json();

                    if (jsonData) {
                        setAuthors(jsonData.authors)
                    }
                    setShowSpinner(false)
                })();
                setShowSpinner(false);
            }
            catch (error) {
                setShowSpinner(false);
                alert("Internal Server Error. Please try again after some time")
            }
        }
    }

    const showCoAuthors = (id) => {
        localStorage.setItem('coauthorid', id);
        var curid = localStorage.getItem('rl_userId')
        if (id === curid) {

        } else {
            history.push({
                pathname: '/dashboard/profileEdit',
                state: { coauthorid: id, isReadOnly: true }
            })
        }
    }
    return (
        <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
            <div className={classes.featureHeading}>
                Manual Mapping
            </div>
            <Paper square style={{ padding: "16px 22px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={6} className="extraPadding">
                        <TextInput error={null} onChange={handleFirstNameChange} value={firstName} onBlur={null} label="Faculty First Name"></TextInput>
                    </Grid>
                    <Grid item xs={6} className="extraPadding">
                        <TextInput error={null} onChange={handleLastNameChange} value={lastName} onBlur={null} label="Faculty Last Name"></TextInput>
                    </Grid>
                    <Grid item xs={6} className="smallPadding">
                        <UnivAutoCompleteWithBlur name="name" value={university} onChange={onInputUniversityChangeHandler}
                            helperText={false ? "Enter a valid school" : null} />
                    </Grid>
                    <Grid item xs={2} className="smallPadding">
                        <Button variant="contained" className="searchButton" color="primary" onClick={search}>Search</Button>
                    </Grid>
                </Grid>
            </Paper>
            {showSpinner &&
                <div style={{ textAlign: "center" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
            }
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    {authors.length > 0 &&
                        <div><Typography variant="h6" style={{ padding: "20px 0px" }}>
                            {authors.length} results found
                        </Typography>
                            <div style={{ backgroundColor: "white" }}>
                                <List>
                                    {authors.map((faculty, i) =>
                                        <ListItem divider style={{ cursor: "pointer" }} onClick={() => showCoAuthors(faculty.author_id)}>
                                            <ListItemAvatar style={{ minWidth: 30 }}>
                                                {i + 1 + "."}
                                            </ListItemAvatar>
                                            <ListItemAvatar style={{ paddingRight: 30 }}>
                                                {faculty.pic != null ?
                                                    (<Avatar alt={faculty.first_name} src={faculty.pic} style={{ height: 75, width: 75 }} />)
                                                    :
                                                    (<Avatar style={{ height: 75, width: 75 }}>
                                                        <img alt="faculty" src={defaultFaculty} height={75} width={75} />
                                                    </Avatar>)
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={faculty.first_name + " " + faculty.last_name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            {faculty.univ_name === null ? "" : faculty.univ_name}
                                                        </Typography>
                                                        <Typography component="div" variant="body2" color="textPrimary">
                                                            <i>{!("email" in faculty) || faculty.email === null ? "" : faculty.email}</i>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default ManualMapping;