import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import RLChart from './RLChart';
import { makeStyles } from '@material-ui/core/styles';
import SingleSelect from '../../../../InputComponents/SingleSelect';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import Carousel from 'react-bootstrap/Carousel';
import '../../../Dashboard.css';
import Settings from './Settings';
import Loading from '../../../../../assets/Images/loading.gif';

const useStyles = makeStyles((theme) => ({
    legend: {
        listStyle: "none",
    },
    span1: {
        border: "1px solid #ccc",
        float: "left",
        width: "12px",
        height: "12px",
        margin: "2% 5px 2% 1px"
    },
    li1: {
        padding: "0px !important",
        float: "left",
        marginRight: "17px"
    },
    c1: { backgroundColor: "#E24301" },
    c2: { backgroundColor: "#BBB218" },
    c3: { backgroundColor: "#85276B" },
    c4: { backgroundColor: "#F6A016" },
    c5: { backgroundColor: "#00259A" },
    titleDiv: {
        fontSize: "20px", marginBottom: "10%", clear: "both"
    }
}));
const facultyTypeDropdownValues = ["Assistant", "Associate", "Full", "Non Tenured Track"];
const facultyTypeToMarkerDict = { "Assistant": 1, "Associate": 2, "Full": 3, "Non Tenured Track": 6 }
const Outliers = props => {
    const dispatch = useDispatch();
    const [facultyType, setFacultyType] = React.useState("Assistant");
    const [department, setDepartment] = React.useState(null);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const outliersAllAuthors = useSelector(state => state.dashboards.outliersAllAuthors);
    const outliersAvgAuthor = useSelector(state => state.dashboards.outliersAvgAuthor);
    const inlineClasses = useStyles();
    React.useEffect(() => {
        getOutliers(1, null);
    }, []);

    const getOutliers = async (marker, dept) => {
        const token = localStorage.getItem('rl_token');
        setShowSpinner(true);
        try {
            await dispatch(dashboardActions.getOutliers(token, marker, dept));
        }
        catch (err) {
            alert("Something went wrong while getting outliers")
        }
        setShowSpinner(false);
    }

    const handleFacultyTypeChange = (event, newInputValue) => {
        setFacultyType(newInputValue ? newInputValue : '');
        getOutliers(facultyTypeToMarkerDict[newInputValue], department);
    }

    const handleSchoolDeptSwitchChange = (department) => {
        if (department === null) {
            setDepartment(null);
            getOutliers(facultyTypeToMarkerDict[facultyType], null);
        }
        else {
            setDepartment(department);
            getOutliers(facultyTypeToMarkerDict[facultyType], department);
        }
    }
    return (
        <Paper style={{ height: "360px", padding: "12px", overflow: "hidden" }}>
            <div className={inlineClasses.titleDiv}>
                <span style={{ float: "left" }}>Visualization by Rank{department === null || department === "" ? "" : ` - ${department}`}</span>
                <span style={{ float: "right", width: "38%", display: "inline-flex" }}>
                    <SingleSelect error={null} options={facultyTypeDropdownValues} onInputChange={handleFacultyTypeChange}
                        inputValue={facultyType} label="Average stats of:" helperText="" defaultValue={"Assistant"} />
                    <Settings handleSchoolDeptSwitchChange={handleSchoolDeptSwitchChange} department={department} />
                </span>
            </div>

            {
                showSpinner ?
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                    :
                    <div>
                        <Grid container>
                            <Grid item xs={3} style={{ borderRight: "1px solid #828282" }}>
                                <RLChart data={outliersAvgAuthor} title={facultyType} />
                            </Grid>
                            <Grid item xs={9}>
                                <Carousel interval={null} wrap={false} indicators={false} prevIcon={<span style={{ backgroundColor: "#828282", margin: "20px", borderRadius: "20%" }} class="carousel-control-prev-icon" />} nextIcon={<span style={{ backgroundColor: "#828282", margin: "20px", borderRadius: "20%" }} class="carousel-control-next-icon" />}>
                                    {
                                        outliersAllAuthors.map((authors) => (
                                            <Carousel.Item>
                                                <Grid container style={{ padding: "0% 7% 0% 8%" }}>
                                                    {
                                                        authors.map((author) => (
                                                            <Grid item xs={4}>
                                                                <RLChart data={author} />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Carousel.Item>
                                        ))
                                    }
                                </Carousel>
                            </Grid>
                        </Grid>
                        <div>
                            <ul className={inlineClasses.legend}>
                                <li className={inlineClasses.li1}>
                                    <span className={`${inlineClasses.c1} ${inlineClasses.span1}`} />
                                    Papers per year in A and A- journals
                                </li>
                                <li className={inlineClasses.li1}>
                                    <span className={`${inlineClasses.c2} ${inlineClasses.span1}`} />
                                    Citations per paper in A and A- journals
                                </li>
                                <li className={inlineClasses.li1}>
                                    <span style={{ marginTop: "3%" }} className={`${inlineClasses.c3} ${inlineClasses.span1}`} />
                                    Solo:coauthored papers
                                </li>
                                <li className={inlineClasses.li1}>
                                    <span style={{ marginTop: "6%" }} className={`${inlineClasses.c4} ${inlineClasses.span1}`} />
                                    H-Index
                                </li>
                                <li className={inlineClasses.li1}>
                                    <span style={{ marginTop: "3%" }} className={`${inlineClasses.c5} ${inlineClasses.span1}`} />
                                    A & A-:B
                                </li>
                            </ul>
                        </div>
                    </div>
            }
        </Paper >
    )
}

export default Outliers;