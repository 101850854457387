import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Layout from '../components/Layout/Layout'
import { Route, Switch } from 'react-router-dom';
import login from '../components/Login/Login';
import PrivateRoute from '../components/Login/PrivateRoute';
import ForSchools from '../components/LandingPage/ForSchools/ForSchools';
import ForFaculty from '../components/LandingPage/ForFaculty/ForFaculty';
import ForOthers from '../components/LandingPage/ForOthers/ForOthers';
import RedFlags from '../components/Dashboard/Views/RedFlags/RedFlags';
import HireImpacts from '../components/Dashboards/Views/HireImpact/HireImpact';
import PerceptualMaps from '../components/Dashboards/Views/PerceptualMap/PerceptualMap';
import CompareGenders from '../components/Dashboards/Views/CompareGender/CompareGender';
import FacultyProductivitys from '../components/Dashboards/Views/FacultyProductivity/FacultyProductivity';
import RouteWithLayout from '../components/Dashboard/RouteWithLayout';
import DashboardHome from '../components/Dashboard/Views/DashboardHome/DashboardHome';
import DashboardHomes from '../components/Dashboards/Views/DashboardHome/DashboardHome';
import UnivStats from '../components/Admin/UniversityStats/UniversityStatsGenerate';
import ProfileEdit from '../components/Dashboard/Views/ProfileEdit/Profile';
import CompareByDepartments from '../components/Dashboards/Views/CompareByDepartment/CompareByDepartment';
import FindYourFit from '../components/Dashboard/Views/FindYourFit/FindYourFit';
import FacultyProgress from '../components/Dashboard/Views/FacultyProgress/FacultyProgress';
import MainLayout from '../components/Dashboard/Main';
import MainLayouts from '../components/Dashboards/Main';
import Signup from '../components/Login/Signup';
import Verification from '../components/Login/Verification';
import resumeupload from '../components/Admin/ResumeUpload/ResumeUpload';
import DeptConfig from '../components/Admin/DepartmentConfig/DeptConfigHome';
import * as authActions from '../store/actions/auth';
import Explore from '../components/Dashboard/Views/Explore/Explore';
import Explores from '../components/Dashboards/Views/Explore/Explore';
import ManualMapping from '../components/Dashboard/Views/ManualMapping/ManualMapping';
import CompareYourself from '../components/Dashboard/Views/CompareYourself/CompareYourself';
import AuthorSearch from '../components/Dashboard/Views/AuthorSearch/AuthorSearch';
// import LandingPageHome from '../components/NewLandingsPage/LandingPageHome'
import CohortAnalysiss from '../components/Dashboards/Views/CohortAnalysis/CohortAnalysis';
import Settings from '../components/Dashboard/Views/Settings/Settings';
import ChangeLogin from '../components/Admin/ChangeLogin/ChangeLogin';
import contactUsRequest from '../components/ContactUsRequest/contactUsRequest';
import EditAuthor from '../components/Admin/EditAuthor/EditAuthor';
import Logs from '../components/Logs/Logs';
import Multiplenamevariationslog from '../components/Logs/MultipleNameVariationsLog/MultipleNameVariationsLog';
import Gapbetweenpaperslog from '../components/Logs/GapBetweenPapersLog/GapBetweenPapersLog';
import RgGsnamemismatchlog from '../components/Logs/RGGSNameMismatchLog/RgGsNameMismatchLog';
import NameMismatch from '../components/Logs/NameMismatch/NameMismatch';
import Mergetool from '../components/Admin/MergeTool/MergeTool';
import Mergerecordpage from '../components/Admin/MergeTool/MergeRecordPage/MergeRecordPage';
import Addauthor from '../components/Admin/AddAuthor/addAuthor';
import Authorrank from '../components/Dashboards/Views/AuthorRank/AuthorRank';
import TenureReadiness from '../components/Dashboards/Views/TenureReadiness/TenureReadiness';
import RSSFeedTool from '../components/Admin/RSSFeed/RSSFeed';
import NewHome from '../components/NewLandingsPage/components/Pages/NewHome';
import UpdateFaculty from '../components/Admin/UpdateFaculty/UpdateFaculty';
// import NameTooSimilarLogs from '../components/Logs/NameTooSimilar/NameTooSimilar'

function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.authCheckState());
  }, [dispatch]);


  return (
    <BrowserRouter>

      <Layout>
        <Switch>
          <Route path="/login" exact component={login} />
          <Route path="/signup" exact component={Signup} />
          <Route path='/verify' exact component={Verification} />
          <Route path="/" exact component={NewHome} />
          <Route path="/forOthers" exact component={ForOthers} />
          <PrivateRoute path="/forFaculty/" exact component={ForFaculty} />
        </Switch>

        <Switch>
          <RouteWithLayout
            component={DashboardHome}
            exact
            layout={MainLayout}
            path="/dashboard"
            Redirect="/dashboard/home"
          />
          <RouteWithLayout
            component={DashboardHomes}
            exact
            layout={MainLayouts}
            path="/dashboards"
            Redirect="/dashboards/home"
          />
          <RouteWithLayout
            component={RedFlags}
            exact
            layout={MainLayout}
            path="/dashboard/redflags"
          />
          <RouteWithLayout
            component={HireImpacts}
            exact
            layout={MainLayouts}
            path="/dashboards/hireImpact"
          />
          <RouteWithLayout
            component={PerceptualMaps}
            exact
            layout={MainLayouts}
            path="/dashboards/compareSchools"
          />
          <RouteWithLayout
            component={FacultyProductivitys}
            exact
            layout={MainLayouts}
            path="/dashboards/facultyProductivity"
          />
          <RouteWithLayout
            component={TenureReadiness}
            exact
            layout={MainLayouts}
            path='/dashboards/tenureReadiness'
          />
          <RouteWithLayout
            component={ProfileEdit}
            exact
            layout={MainLayout}
            path="/dashboard/profileEdit"
          />
          <RouteWithLayout
            component={ProfileEdit}
            exact
            layout={MainLayouts}
            path="/dashboards/profileEdit"
          />
          <RouteWithLayout
            component={Settings}
            exact
            layout={MainLayout}
            path="/dashboard/settings"
          />
          <RouteWithLayout
            component={Settings}
            exact
            layout={MainLayout}
            path="/dashboards/settings"
          />
          <RouteWithLayout
            component={CompareYourself}
            exact
            layout={MainLayout}
            path="/dashboard/compareYourself"
          />
          <RouteWithLayout
            component={CohortAnalysiss}
            exact
            layout={MainLayouts}
            path="/dashboards/cohortAnalysis"
          />
          <RouteWithLayout
            component={CompareByDepartments}
            exact
            layout={MainLayouts}
            path="/dashboards/compareByDepartment"
          />
          <RouteWithLayout
            component={Authorrank}
            exact
            layout={MainLayouts}
            path="/dashboards/authorrank"
          />
          <RouteWithLayout
            component={Authorrank}
            exact
            layout={MainLayout}
            path="/dashboard/authorrank"
          />
          <RouteWithLayout
            component={FindYourFit}
            exact
            layout={MainLayout}
            path="/dashboard/findYourFit"
          />
          <RouteWithLayout
            component={FacultyProgress}
            exact
            layout={MainLayout}
            path="/dashboard/facultyProgress"
          />
          <RouteWithLayout
            component={CompareGenders}
            exact
            layout={MainLayouts}
            path='/dashboards/comparegender'
          />
          <RouteWithLayout
            component={Explore}
            exact
            layout={MainLayout}
            path="/dashboard/explore"
          />
          <RouteWithLayout
            component={Explores}
            exact
            layout={MainLayouts}
            path="/dashboards/explore"
          />
          <RouteWithLayout
            component={ManualMapping}
            exact
            layout={MainLayout}
            path="/dashboard/manualmapping"
          />
          <RouteWithLayout
            component={AuthorSearch}
            exact
            layout={MainLayout}
            path="/dashboard/authorSearch"
          />
          <RouteWithLayout
            component={resumeupload}
            exact
            layout={MainLayout}
            path="/dashboard/resumeUpload"
          />
          <RouteWithLayout
            component={DeptConfig}
            exact
            layout={MainLayout}
            path="/dashboard/DepartmentConfig"
          />
          <RouteWithLayout
            component={RSSFeedTool}
            exact
            layout={MainLayout}
            path="/dashboard/RSSFeedTool"
          />
          <RouteWithLayout
            component={UnivStats}
            exact
            layout={MainLayout}
            path="/dashboard/UniversityStats"
          />
          <RouteWithLayout
            component={contactUsRequest}
            exact
            layout={MainLayout}
            path="/dashboard/contactUsRequest"
          />
          {/* <RouteWithLayout
            component={NameTooSimilarLogs}
            exact
            layout={MainLayout}
            path="/logs/name_too_similar"
          /> */}
          <RouteWithLayout
            component={ChangeLogin}
            exact
            layout={MainLayout}
            path="/dashboard/changeLogin"
          />
          <RouteWithLayout
            component={EditAuthor}
            exact
            layout={MainLayout}
            path="/tools/editAuthor"
          />
          <RouteWithLayout
            component={Logs}
            exact
            layout={MainLayout}
            path="/logs"
          />
          <RouteWithLayout
            component={Multiplenamevariationslog}
            exact
            layout={MainLayout}
            path="/logs/multiple_name_variations_log"
          />
          <RouteWithLayout
            component={Gapbetweenpaperslog}
            exact
            layout={MainLayout}
            path="/logs/gap_between_papers"
          />
          <RouteWithLayout
            component={RgGsnamemismatchlog}
            exact
            layout={MainLayout}
            path="/logs/rg_gs_name_mismatch"
          />
          <RouteWithLayout
            component={NameMismatch}
            exact
            layout={MainLayout}
            path="/logs/name_mismatch"
          />
          <RouteWithLayout
            component={Mergetool}
            exact
            layout={MainLayout}
            path="/tools/mergeAuthorTool"
          />
          <RouteWithLayout
            component={Mergerecordpage}
            exact
            layout={MainLayout}
            path="/tools/mergeAuthorTool/:id"
          />
          <RouteWithLayout
            component={Addauthor}
            exact
            layout={MainLayout}
            path="/tools/addAuthor"
          />
          <RouteWithLayout
              component={UpdateFaculty}
              exact
              layout={MainLayout}
              path="/dashboard/updateFaculty"
            />
            <RouteWithLayout
              component={UpdateFaculty}
              exact
              layout={MainLayouts}
              path="/dashboards/updateFaculty"
            />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;