import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    searchResult: [],
    category: [],
    maleData: [],
    femaleData: [],
    othersData: [],
    otherUniversities: []
}
const getPercent = (value, total) => {
    return (((value) * 100) / (total));
}
const getPercentTwoDec = (value, total) => {
    return (((value) * 100) / (total)).toFixed(2);
}

const compareGenderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPARE_GENDER:
            let category1 = []
            let maleData1 = []
            let femaleData1 = []
            let othersData1 = []
            let otherUniversities1 = []
            console.log("Inside Reducer:", action.searchResult)
            for (let university of action.searchResult) {
                for (let key in university) {
                    let genderData = university[key];
                    category1.push({ "label": genderData["name"] });
                    let total = 0
                    total += genderData["Assistant Professor"]["f"]
                    total += genderData["Assistant Professor"]["m"]
                    total += genderData["Assistant Professor"]["other"]

                    total += genderData["Associate Professor"]["f"]
                    total += genderData["Associate Professor"]["m"]
                    total += genderData["Associate Professor"]["other"]

                    total += genderData["Full Professor"]["f"]
                    total += genderData["Full Professor"]["m"]
                    total += genderData["Full Professor"]["other"]

                    maleData1.push({
                        "value": getPercent(genderData["Assistant Professor"]["m"] +
                            genderData["Associate Professor"]["m"] + genderData["Full Professor"]["m"], total), "color": "00259A"
                    });
                    femaleData1.push({
                        "value": getPercent(genderData["Assistant Professor"]["f"] +
                            genderData["Associate Professor"]["f"] + genderData["Full Professor"]["f"], total), "color": "E24301"
                    });
                    othersData1.push({
                        "value": getPercent(genderData["Assistant Professor"]["other"] +
                            genderData["Associate Professor"]["other"] + genderData["Full Professor"]["other"], total), "color": "BBB218"
                    });

                    otherUniversities1.push({
                        "name": genderData["name"], "AssisstantProfessor": {
                            "male": getPercentTwoDec(genderData["Assistant Professor"]["m"],
                                genderData["Assistant Professor"]["m"] + genderData["Assistant Professor"]["f"] + genderData["Assistant Professor"]["other"])
                            , "female": getPercentTwoDec(genderData["Assistant Professor"]["f"],
                                genderData["Assistant Professor"]["m"] + genderData["Assistant Professor"]["f"] + genderData["Assistant Professor"]["other"]),
                            "other": getPercentTwoDec(genderData["Assistant Professor"]["other"],
                                genderData["Assistant Professor"]["m"] + genderData["Assistant Professor"]["f"] + genderData["Assistant Professor"]["other"])
                        }, "AssociateProfessor": {
                            "male": getPercentTwoDec(genderData["Associate Professor"]["m"],
                                genderData["Associate Professor"]["m"] + genderData["Associate Professor"]["f"] + genderData["Associate Professor"]["other"])
                            , "female": getPercentTwoDec(genderData["Associate Professor"]["f"],
                                genderData["Associate Professor"]["m"] + genderData["Associate Professor"]["f"] + genderData["Associate Professor"]["other"]),
                            "other": getPercentTwoDec(genderData["Associate Professor"]["other"],
                                genderData["Associate Professor"]["m"] + genderData["Associate Professor"]["f"] + genderData["Associate Professor"]["other"])
                        }, "Professor": {
                            "male": getPercentTwoDec(genderData["Full Professor"]["m"],
                                genderData["Full Professor"]["m"] + genderData["Full Professor"]["f"] + genderData["Full Professor"]["other"])
                            , "female": getPercentTwoDec(genderData["Full Professor"]["f"],
                                genderData["Full Professor"]["m"] + genderData["Full Professor"]["f"] + genderData["Full Professor"]["other"]),
                            "other": getPercentTwoDec(genderData["Full Professor"]["other"],
                                genderData["Full Professor"]["m"] + genderData["Full Professor"]["f"] + genderData["Full Professor"]["other"])
                        }
                    })
                }
            }
            return updateObject(state, {
                category: category1,
                maleData: maleData1,
                femaleData: femaleData1,
                othersData: othersData1,
                otherUniversities: otherUniversities1
            });
        case actionTypes.CLEAR_COMPARE_GENDER:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}

export default compareGenderReducer;