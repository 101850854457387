import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ImageIcon from '@material-ui/icons/Image';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import Profile from './Components/Profile/Profile';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import SidebarNav from './Components/SidebarNav/sidebarNav';
import WcIcon from '@material-ui/icons/Wc';
import { grey } from '@material-ui/core/colors';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BarChartIcon from '@material-ui/icons/BarChart';
import logo from '../../../assets/Images/logos/ResearchLoupe_Logo_White.png';
import TrendingUp from '@material-ui/icons/TrendingUp';
import { adminPages } from '../../Admin/adminPages';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: "18%",
    zIndex:2,
    backgroundColor:'#555559',
    marginTop:0
  },
  root: {
    backgroundColor: grey,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  divider: {
    margin: 2
  },
  nav: {
    marginBottom: 2,
  },
  profile: {
    marginTop: 0
  },
  logo:{
    width:'250px'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboards',
      icon: <DashboardIcon />
    },
    {
      title: 'Faculty Productivity',
      href: '/dashboards/facultyProductivity',
      icon: <PersonAddIcon />
    },
    {
      title: 'Tenure Readiness',
      href: '/dashboards/tenureReadiness',
      icon: <DirectionsRunIcon />
    },
    {
      title: 'Hire Impact',
      href: '/dashboards/hireImpact',
      icon: <TransferWithinAStationIcon />
    },
    {
      title: 'Cohort Analysis',
      href: '/dashboards/cohortAnalysis',
      icon: <BarChartIcon />
    },
    {
      title: 'Compare Schools',
      href: '/dashboards/compareSchools',
      icon: <ImageIcon />
    },
    {
      title: 'Compare Departments',
      href: '/dashboards/compareByDepartment',
      icon: <CompareArrowsIcon />
    },
    {
      title: 'Compare Gender',
      href: '/dashboards/comparegender',
      icon: <WcIcon />
    },
    {
      title: 'Explore',
      href: '/dashboards/explore',
      icon: <SearchIcon />
    },
    {
      title: 'Faculty Ranking',
      href: '/dashboards/authorrank',
      icon: <TrendingUp/>
    },
    {
      title: 'Log Out',
      href: '/login',
      icon: <ExitToAppIcon />
    }
  ];

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant} >
      <div {...rest} className={clsx(classes.root, className)} >
      <div style={{textAlign:"center"}}>
        <img src={logo} alt="Research Loupe" className={classes.logo} />
        </div>
        <Profile className={classes.profile}/>
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} adminPages={localStorage.getItem("rl_token_a") !== null ? adminPages : []} />
        <div style={{marginTop: "30px"}}></div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;