import React, { useState } from 'react';
import classes from './ResearchProfiles.module.css';
import profileClassses from '../Profile.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Theme from '../../../../../constants/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Profile from '../../../../../model/profile';
import { OpenInNew } from '@material-ui/icons';
import * as profileActions from '../../../../../store/actions/profile';
import * as coAuthorProfileActions from '../../../../../store/actions/coauthorprofile';
import AddResearchProfileModal from './AddResearchProfileModal';
import EditIcon from '@material-ui/icons/Edit';
import Loading from '../../../../../assets/Images/loading.gif';
import { Grid, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ResearchProfiles = props => {
    const theme = Theme();
    const editColor = "#DADADA";
    const dispatch = useDispatch();
    const history = useHistory();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const [showSpinner, setShowSpinner] = useState(false);
    const userType = useSelector(state => state.auth.user_type);
    const token = useSelector(state => state.auth.token);
    const profileUpdateState = useSelector(state => state.profileUpdate.editProfile);
    const [modalShow, setModalShow] = useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const gsObject = props.data.gs;
    const rgObject = props.data.rg;
    const univProfileLink = props.data.univ_profile_link;

    const createUpdatedProfile = (gsObject, rgObject, univProfileLink) => {
        return Object.assign(new Profile(), {
            _id: props.data._id,
            gs: gsObject,
            rg: rgObject,
            ...(univProfileLink !== undefined) && { univ_profile_link: univProfileLink }
        })
    };

    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    };

    const refreshPage = async () => {
        try {
            const token = localStorage.getItem("rl_token");
            if (props.isCoAuthor === true) {
                history.push({
                    pathname: '/dashboard/profileEdit',
                    state: { coauthorid: props.data._id }
                })
            } else if (props.isCoAuthor === false) {
                history.push({
                    pathname: '/dashboard/profileEdit'
                })
            } else {
                await dispatch(profileActions.get_author_profile(token, "id"));
                history.push({
                    pathname: '/dashboard/profileedit'
                })
            }
        }
        catch (error) {
            alert("Failed to load profile! Try again in few seconds!");
        }
    };

    const generateGSLinkFromId = (gs_id) => {
        return "https://scholar.google.com/citations?user=" + gs_id;
    };

    const generateRGLinkFromId = (rg_id) => {
        return "https://www.researchgate.net/profile/" + rg_id
    };

    const updateResearchProfiles = async (gsObject, rgObject, univProfileLink) => {
        setAccordionExpand(true);
        setModalShow(false);
        setShowSpinner(true);
        try {
            const newProfile = createUpdatedProfile(gsObject, rgObject, univProfileLink);
            if (props.isCoAuthor === true) {
                await dispatch(coAuthorProfileActions.update_co_author_profile(token, newProfile, props.data._id));
            } else {
                await dispatch(profileActions.update_author_profile(token, newProfile));
            }
        }
        catch (error) {
            alert("Failed to Update! Please refresh the page and try again.");
        }
        setShowSpinner(false);
        refreshPage();
    };

    return (
        <Grid container xs={20} alignItems="center" justify="center" className={profileClassses.grid}>
            <Accordion square className={profileClassses.accordian} expanded={accordionExpand} onChange={handleAccordionExpand}>
                <AccordionSummary className={profileClassses.accordian_summary} expandIcon={<ExpandMoreIcon />} >
                    <Row className={classes.header}>
                        <Col sm={11} md={11}><h3 style={{ marginTop: "10px" }}>Research Profiles</h3></Col>
                        {
                            profileUpdateState === true ?
                                <Col sm={1} md={1}>
                                    <span style={{ cursor: "pointer" }}>
                                        <LightTooltip title="Click to edit" placement="bottom">
                                            <EditIcon style={{ width: '1.2em', height: '1.2em', color: editColor, marginLeft: "370px", marginTop: "9px", }} onClick={() => setModalShow(true)}></EditIcon>
                                        </LightTooltip>
                                    </span>
                                </Col>
                                :
                                <Col sm={1} md={1}></Col>
                        }
                    </Row>
                </AccordionSummary>
                <Container className={classes.container}>
                    {
                        showSpinner === true ?
                            <Row>
                                <Col md={12} style={{ textAlign: "center" }}>
                                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                                </Col>
                            </Row>
                            :
                            [

                                <Row className={profileClassses.subheaderFirst}>
                                    <Col md={4}>
                                        {typeof gsObject.author_id === 'undefined' || gsObject.author_id === "" ?
                                            <span title="No Google Scholar profile added">Google Scholar</span>
                                            :
                                            <a href={generateGSLinkFromId(gsObject.author_id)} target="_blank" rel="noopener noreferrer">
                                                Google Scholar
                                                <OpenInNew style={{ fontSize: "14" }} />
                                            </a>
                                        }
                                    </Col>
                                    <Col md={4}>
                                        {typeof rgObject.author_id === 'undefined' || gsObject.author_id === "" ?
                                            <span title="No ResearchGate profile added">ResearchGate</span>
                                            :
                                            <a href={generateRGLinkFromId(rgObject.author_id)} target="_blank" rel="noopener noreferrer">
                                                ResearchGate
                                                <OpenInNew style={{ fontSize: "14" }} />
                                            </a>
                                        }
                                    </Col>
                                    <Col md={4}>
                                        {userType === "admin" ? univProfileLink !== "" ?
                                            <a href={univProfileLink} target="_blank" rel="noopener noreferrer">
                                                University Profile
                                                <OpenInNew style={{ fontSize: "14" }} />
                                            </a>
                                            :
                                            <span title="No University Profile added">University Profile</span>
                                            :
                                            null
                                        }
                                    </Col>
                                </Row>
                            ]

                    }
                    <AddResearchProfileModal
                        rgObject={rgObject}
                        gsObject={gsObject}
                        univProfileLink={univProfileLink}
                        show={modalShow}
                        onSave={updateResearchProfiles}
                        onHide={() => { setModalShow(false); setAccordionExpand(true) }} />
                </Container>
            </Accordion>
        </Grid>
    );
};

export default ResearchProfiles;