import React from 'react';
import './paperDisplay.css';

const PaperDisplay = props => {
    let paper = props.paper;
    return (
        <div className="paper-container">
            {paper.document_title !== undefined || paper.issn !== undefined ?
            <p style={{width:"100%", margin:0}}>{paper.document_title +"  "+ paper.issn["title"] +" ("+paper.publication_year+")."}</p>
               :null }
            {/* <p style={{float:"left", width:"50%",margin:0}}>Journal: {paper.journal}</p>
            <p style={{float:"left", width:"50%", margin:0}}>Year: {paper.year}</p> */}
            {/* <table>
                <tr>
                    <td colSpan={2}>
                        <p>{paper.title}</p>
                    </td>
                </tr>
                <tr>
                    <td style={{width:"50%"}}>
                        <p>Journal:{paper.journal}</p>
                    </td>
                    <td>
                        <p>Year:{paper.year}</p>
                    </td>
                </tr>
            </table> */}
        </div>
    )
}

export default PaperDisplay;
