import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as classes from './ResearchProfiles.module.css';
import TextInputControlled from '../../../../InputComponents/TextInputControlled';

const AddResearchProfileModal = props => {
    const userType = useSelector(state => state.auth.user_type);
    const [rgName, setRgName] = useState("");
    const [gsName, setGsName] = useState("");
    const [rgLink, setRgLink] = useState("");
    const [gsLink, setGsLink] = useState("");
    const [univProfileLink, setUnivProfileLink] = useState("");
    const gsProfileExists = props.gsObject.author_id !== undefined && props.gsObject.author_id !== "";
    const rgProfileExists = props.rgObject.author_id !== undefined && props.rgObject.author_id !== "";

    const hideModal = () => {
        props.onHide();
    };

    const generateGSLinkFromId = (gs_id) => {
        return "https://scholar.google.com/citations?user=" + gs_id;
    };

    const generateRGLinkFromId = (rg_id) => {
        return "https://www.researchgate.net/profile/" + rg_id
    };

    const gsLinkChangeHandler = event => {
        const newValue = event.target.value;
        setGsLink(newValue);
    };

    const rgLinkChangeHandler = event => {
        const newValue = event.target.value;
        setRgLink(newValue);
    };

    const gsNameChangeHandler = event => {
        const newValue = event.target.value;
        setGsName(newValue);
    };

    const rgNameChangeHandler = event => {
        const newValue = event.target.value;
        setRgName(newValue);
    };

    const univProfileLinkChangeHandler = event => {
        const newValue = event.target.value;
        setUnivProfileLink(newValue);
    }

    const updateResearchProfiles = () => {
        let isGsUpdated = false;
        let isRgUpdated = false;
        let gs = {};
        let rg = {};
        if (gsLink && gsLink !== generateGSLinkFromId(props.gsObject.author_id)) {
            if (!gsName) {
                alert("Please enter author name from google scholar")
                return;
            }
            gs = { 'google_scholar_link': gsLink, 'google_scholar_author_name': gsName };
            let gsUrl = new URL(gsLink.trim());
            const params = new URLSearchParams(gsUrl.search);
            if (!params.has("user")) {
                alert("Invalid Google Scholar link");
                return;
            }
            gs['google_scholar_author_id'] = params.get("user");
            isGsUpdated = true;
        }
        if (rgLink && rgLink !== generateRGLinkFromId(props.rgObject.author_id)) {
            if (!rgName) {
                alert("Please enter author name from Researchgate")
                return;
            }
            rg = { 'research_gate_link': rgLink, 'research_gate_author_name': rgName };
            let rgLinkSplit = rgLink.trim().split("/");
            if (rgLinkSplit.length < 5) {
                alert("Invalid Researchgate link");
                return;
            }
            rg['research_gate_author_id'] = rgLinkSplit[rgLinkSplit.length - 1];
            isRgUpdated = true;
        }
        props.onSave(isGsUpdated === true ? gs : props.gsObject,
            isRgUpdated === true ? rg : props.rgObject,
            univProfileLink !== "" || (univProfileLink === "" && props.univProfileLink !== "") ? univProfileLink : undefined);
    };

    useEffect(() => {
        setGsLink(gsProfileExists === true ? generateGSLinkFromId(props.gsObject.author_id) : "");
        setGsName(props.gsObject.author_name ? props.gsObject.author_name : "");
        setRgLink(rgProfileExists === true ? generateRGLinkFromId(props.rgObject.author_id) : "");
        setRgName(props.rgObject.author_name ? props.rgObject.author_name : "");
        setUnivProfileLink(props.univProfileLink)
    }, []);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={classes.myModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Research Profiles
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row md={12}>
                        <Col md={6}>
                            <TextInputControlled label="Name on Google Scholar page" onChange={gsNameChangeHandler.bind(this)} value={gsName}>
                            </TextInputControlled>
                        </Col>
                        <Col md={6}>
                            <TextInputControlled label="Link to Google Scholar page" onChange={gsLinkChangeHandler.bind(this)} value={gsLink}>
                            </TextInputControlled>
                        </Col>
                    </Row>
                    <Row md={12}>
                        <Col md={6}>
                            <TextInputControlled label="Name on ResearchGate page" onChange={rgNameChangeHandler.bind(this)} value={rgName}>
                            </TextInputControlled>
                        </Col>
                        <Col md={6}>
                            <TextInputControlled label="Link to ResearchGate page" onChange={rgLinkChangeHandler.bind(this)} value={rgLink}>
                            </TextInputControlled>
                        </Col>
                    </Row>
                    {
                        userType === "admin" &&
                        <Row md={12}>
                            <Col md={3}></Col>
                            <Col md={6}>
                                <TextInputControlled label="Link to University Profile" onChange={univProfileLinkChangeHandler.bind(this)} value={univProfileLink}>
                                </TextInputControlled>
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={hideModal}>Close</Button>
                <Button variant="contained" style={{ color: '#000000', backgroundColor: "#BBB218", borderRadius: "0%" }} onClick={updateResearchProfiles}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddResearchProfileModal;