import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../../assets/Images/loading.gif';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {  Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Authorcard from './AuthorCard/AuthorCard';
import * as mergeToolActions from '../../../store/actions/mergeTool';

const useStyles = makeStyles({
    root: {
      width: '99%',
    },
    tableBottomBorder : { 
        borderWidth: 1, 
        borderColor: 'black',
        borderStyle: 'solid',
        borderLeftWidth:0,
        borderRightWidth:0,
    },
    indexHeaderColumn: {
        width: "15px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
        borderTopWidth:0,
        backgroundColor: "#BBB21E"
    },
    logHeaderColumn: {
        // Width: "500px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
        borderRightWidth:0, 
        borderTopWidth:0,
        backgroundColor: "#BBB21E"
    },
    indexColumn: {
        width: "15px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
    },
    logColumn: {
        // minWidth: "500px", 
        borderWidth:1, 
        borderColor:"black", 
        borderStyle:"solid", 
        borderLeftWidth:0, 
        borderRightWidth:0,
    }
  });

const Mergetool = props => {
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const allAuthors = useSelector(state => state.mergeTool.authors);
    const [isFetching, setIsFetching] = useState(true);
    
    useEffect(() => {
        // console.log("Called MergeTool UseEffect hook");
        async function fetchAuthors() {
            const token = localStorage.getItem("rl_token");
            await dispatch(mergeToolActions.fetchAuthorsToMerge(token));        
            setIsFetching(false);
        }
        fetchAuthors();

    },[]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onAuthorClick = async (row) =>{
        // console.log("clicked....");
        props.history.push({ 
            pathname: "/tools/mergeAuthorTool/"+row._id,
            state: row
        });
        // To be used while returning to log from specific error log page
        // await dispatch(logActions.resolveLog(row));
    }
    return (
        <div>
            <Container className="heading">
                <Row>
                    <Col>
                    <span className="profile">
                        {"Authors to Merge"}
                    </span>
                    </Col>
                </Row>
            </Container>
            {
                isFetching === true ? 
                <div style={{ textAlign: "center", minHeight: "300px", paddingTop:"100px" }}>
                    <img src={Loading} alt="Loading..." width="80px" height="80px" />
                </div>
                :
                <Paper className={classes.root}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" style={{borderWidth:1, borderColor:"black", borderStyle:"solid"}}>
                        <TableHead >
                            <TableRow>
                            <TableCell
                                align="left"
                                className={classes.indexHeaderColumn}
                                >
                                <h4>#</h4>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.logHeaderColumn}
                                >
                                <div style={{display:"flex"}}>
                                    <div style={{flex:1}}>
                                        <h4>Authors</h4>
                                    </div>
                                    {/* <div style={{flex:1, backgroundColor: "white"}}>
                                    <Autocomplete
                                        width = "50px"
                                        id="errors-auto"
                                        options={["All"].concat(Object.keys(errorTypes))}
                                        size="small"
                                        value={filterCondition}
                                        renderInput={(params) => <TextField {...params} label={!filterCondition ? "Filter by Error" : undefined} variant="outlined" />}
                                        onChange={
                                            (e, value) => {
                                            handleFilterChange(value)
                                            }
                                        }
                                    />
                                    </div> */}
                                </div>
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allAuthors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                <TableCell className={classes.indexColumn} align="left" > 
                                        {index+1}
                                </TableCell>
                                <TableCell className={classes.logColumn} align="left" > 
                                    <div style={{minHeight:"50px"}}>
                                        <Authorcard key={index} data={row} onClick={() => onAuthorClick(row)}/>
                                    </div>
                                </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allAuthors.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            }
        </div>
    )
}

export default Mergetool;