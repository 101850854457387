import React, { useEffect } from 'react';
import * as actionTypes from '../../../../store/actions/actionTypes';
import './FacultyProductivity.css';
import {
    Grid, Button, FormControlLabel, FormControl, Input, Paper,
    Divider, Slider, FormLabel, AccordionDetails, AccordionSummary, Accordion, RadioGroup, Radio,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import ViewByDepartment from './Components/ViewByDepartment';
import ViewByAllFaculty from './Components/ViewByAllFaculty';
import Theme from '../../../../constants/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import SingleSelect from '../../../InputComponents/SingleSelect';
import MultipleSelect from '../../../InputComponents/MultipleSelect';
import classes2 from './FacultyProductivity.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../assets/Images/loading.gif';
import { makeStyles } from '@material-ui/core/styles';
import * as facultyProductivityActions from '../../../../store/actions/facultyProductivity';
import { convertArrayToSummary } from '../../../../utils';
const today = new Date();
const minYear = 1940;
const maxYear = today.getFullYear();
const facultyTypeOptions = ["Assistant Professor", "Associate Professor", "Full Professor", "Non Tenure Track"]
const facultyTypeOptionsDict = { "Assistant Professor": "assistant", "Associate Professor": "associate", "Full Professor": "full", "Non Tenure Track": "nontenured" }
const journalOptions = ["A journals", "A and A- journals", "All journals"];
const journalOptionsDict = { "A journals": "a", "A and A- journals": "a,a-", "All journals": "a,a-,b" };
const rankByDropdownValues = ["Papers", "Citations", "H-Index", "Alphabetical"];
const rankByDropdownValuesDict = { "Papers": "papers", "Citations": "citations", "H-Index": "hindex", "Alphabetical": "alphabet" };
const facultyCountDropdownValues = ["3", "9", "All"];
const useStyles = makeStyles((theme) => ({
    featureHeading: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "normal",
        fontSize: "36px !important",
        lineHeight: "54px !important",
        color: "#2B2F4B !important",
    },
    searchButton: {
        color: "black !important",
        width: "25% !important",
        borderRadius: "0% !important",
        height: "39px",
        backgroundColor: "#BBB218 !important",
    }
}));
const FacultyProductivity = props => {
    const theme = Theme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [viewByAllFaculty, setViewByAllFaculty] = React.useState(1);
    const [rankBy, setRankBy] = React.useState("");
    const [facultyCount, setFacultyCount] = React.useState("9");
    const [journals, setJournals] = React.useState("");
    const [selectedFacultyType, setSelectedFacultyType] = React.useState([]);
    const [paperYearRange, setPaperYearRange] = React.useState([minYear, maxYear]);
    const [citationYearRange, setCitationYearRange] = React.useState([minYear, maxYear]);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [facultyTypeErr, setFacultyTypeErr] = React.useState(false);
    const [sortByErr, setSortByErr] = React.useState(false);
    const [journalsErr, setJournalsErr] = React.useState(false);
    const [accordionExpand, setAccordionExpand] = React.useState(true);
    const [facultyTypeInfo, setfacultyTypeInfo] = React.useState(" * faculty type * ");
    const allFacultyList = useSelector(state => state.facultyProductivity.facultyList);
    const departmentList = useSelector(state => state.facultyProductivity.departmentList);
    const [displayJournalFlagCard, setDisplayJournalFlagCard] = React.useState(false);
    const inputData = useSelector(state => state.facultyProductivity.inputData);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuPropsStyles = {
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleViewFacultyByChange = (event) => {
        setViewByAllFaculty(parseInt(event.target.value));
    }
    const handleRankByChange = (event, newInputValue) => {
        setRankBy(newInputValue ? newInputValue : '');
        setSortByErr(newInputValue ? false : true);
        setDisplayJournalFlagCard(false);
    }
    const handleFacultyTypeChange = (event) => {
        setSelectedFacultyType(event.target.value);
        if (event.target.value.length > 0) {
            setFacultyTypeErr(false);
        }
        setfacultyTypeInfo(convertArrayToSummary(event.target.value));
        setDisplayJournalFlagCard(false);
    }
    const handleJournalsChange = (event, newInputValue) => {
        setJournals(newInputValue ? newInputValue : '');
        setJournalsErr(newInputValue ? false : true);
        setDisplayJournalFlagCard(false);
    }
    const handleFacultyCountChange = (event, newInputValue) => {
        setFacultyCount(newInputValue ? newInputValue : '');
    }
    const handleAccordionExpand = () => {
        setAccordionExpand(!accordionExpand);
    }
    const handlePaperYearSliderChange = (event, newValue) => {
        setPaperYearRange(newValue);
    }
    const handleCitationYearSliderChange = (event, newValue) => {
        setCitationYearRange(newValue);
    }
    const handlePaperYearInputChange = (event,) => {
        let newYearRange = event.target.id === "from" ? [Number(event.target.value), paperYearRange[1]] : [paperYearRange[0], Number(event.target.value)];
        if (newYearRange[0] < minYear) {
            newYearRange = [minYear, newYearRange[1]];
        }
        else if (newYearRange[0] > maxYear) {
            newYearRange = [maxYear, newYearRange[1]];
        }
        if (newYearRange[1] > maxYear) {
            newYearRange = [newYearRange[0], maxYear]
        }
        else if (newYearRange[1] < minYear) {
            newYearRange = [newYearRange[0], minYear];
        }
        if (newYearRange[0] > newYearRange[1]) {
            newYearRange = [newYearRange[1], newYearRange[1]];
        }
        setPaperYearRange(newYearRange);
    }
    const handleCitationYearInputChange = (event,) => {
        let newYearRange = event.target.id === "from" ? [Number(event.target.value), citationYearRange[1]] : [citationYearRange[0], Number(event.target.value)];
        if (newYearRange[0] < minYear) {
            newYearRange = [minYear, newYearRange[1]];
        }
        else if (newYearRange[0] > maxYear) {
            newYearRange = [maxYear, newYearRange[1]];
        }
        if (newYearRange[1] > maxYear) {
            newYearRange = [newYearRange[0], maxYear]
        }
        else if (newYearRange[1] < minYear) {
            newYearRange = [newYearRange[0], minYear];
        }
        if (newYearRange[0] > newYearRange[1]) {
            newYearRange = [newYearRange[1], newYearRange[1]];
        }
        setCitationYearRange(newYearRange);
    }
    const handleBlur = () => {
        if (paperYearRange[0] < minYear) {
            setPaperYearRange([minYear, paperYearRange[1]]);
        } else if (paperYearRange[1] > maxYear) {
            setPaperYearRange([paperYearRange[0], maxYear]);
        }
        if (citationYearRange[0] < minYear) {
            setCitationYearRange([minYear, citationYearRange[1]]);
        } else if (citationYearRange[1] > maxYear) {
            setCitationYearRange([citationYearRange[0], maxYear]);
        }
    }
    const getFacultyProductivity = async () => {
        if (checkValidity()) {
            const inputData = {
                'viewByAllFaculty': viewByAllFaculty,
                'paperYearRange': paperYearRange,
                'citationYearRange': citationYearRange,
                'rankBy': rankBy,
                'selectedFacultyType': selectedFacultyType,
                'journals': journals,
                'facultyCount': facultyCount
            }
            dispatch({ type: actionTypes.SET_FACULTY_PRODUCTIVITY_INPUTS, inputData: inputData });

            setAccordionExpand(false);
            setShowSpinner(true);
            const token = localStorage.getItem("rl_token");
            try {
                let acceptedFaculties = "";
                for (let faculty1 of selectedFacultyType) {
                    acceptedFaculties += facultyTypeOptionsDict[faculty1] + ",";
                }
                acceptedFaculties = acceptedFaculties.slice(0, acceptedFaculties.length - 1);
                await dispatch(facultyProductivityActions.getFacultyProductivity(token, viewByAllFaculty, paperYearRange[0], paperYearRange[1], citationYearRange[0], citationYearRange[1], rankByDropdownValuesDict[rankBy], acceptedFaculties, journalOptionsDict[journals]));
            }
            catch (err) {
                alert("Internal Server Error. Please try again after some time")
            }
            setShowSpinner(false);
            setDisplayJournalFlagCard(true);
        }
    }
    const checkValidity = () => {
        let isValid = true;
        if (selectedFacultyType.length === 0) {
            setFacultyTypeErr(true);
            isValid = false;
        }
        if (journals === "") {
            setJournalsErr(true);
            isValid = false;
        }
        if (rankBy === "") {
            setSortByErr(true);
            isValid = false;
        }
        return isValid;
    }

    useEffect(() => {
        if (allFacultyList.length !== 0 || departmentList.length !== 0) {
            setAccordionExpand(false);
            setViewByAllFaculty(inputData.viewByAllFaculty);
            setPaperYearRange(inputData.paperYearRange);
            setCitationYearRange(inputData.citationYearRange);
            setRankBy(inputData.rankBy);
            setSelectedFacultyType(inputData.selectedFacultyType);
            setJournals(inputData.journals);
            setFacultyCount(inputData.facultyCount);
            setfacultyTypeInfo(convertArrayToSummary(inputData.selectedFacultyType))
            setDisplayJournalFlagCard(true)
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <div style={{ marginRight: 20, marginLeft: 7, paddingTop: 20 }}>
                <div className={classes.featureHeading}>
                    Faculty Productivity
                </div>
                <Accordion square style={{ padding: "0px 16px" }} expanded={accordionExpand} onChange={handleAccordionExpand}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ float: "left" }}>
                            Faculty productivity {viewByAllFaculty === 1 ? " in the school" : " per department in the school "} for {facultyTypeInfo} ordered by {rankBy ? rankBy : "* sort by * "};
                            considering papers published in {journals ? journals : "* journals *"} between {paperYearRange[0]} - {paperYearRange[1]}
                            &nbsp; and the faculty's citations between {citationYearRange[0]} - {citationYearRange[1]}.
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ padding: "2px 2px" }}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" style={{ height: 20, paddingBottom: 35 }}>
                                    <FormControlLabel value="View by:" labelPlacement='start' control={
                                        <RadioGroup style={{ paddingTop: 10 }} row name="position" defaultValue="top" value={viewByAllFaculty} onChange={handleViewFacultyByChange}>
                                            <FormControlLabel style={{ paddingLeft: 15 }} value={0} control={<Radio color="secondary" />} label="Department" />
                                            <FormControlLabel style={{ paddingLeft: 15 }} value={1} control={<Radio color="secondary" />} label="School" />
                                        </RadioGroup>} label="View by:"></FormControlLabel>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Year Range:</FormLabel>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item style={{ width: "13.2%" }}>
                                        <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend">Papers:</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={paperYearRange[0]} id="from" margin="dense" onChange={handlePaperYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }} value={paperYearRange} onChange={handlePaperYearSliderChange} valueLabelDisplay="auto" min={minYear} max={maxYear} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={paperYearRange[1]} id="to" margin="dense" onChange={handlePaperYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item style={{ width: "13.2%" }}>
                                        <FormLabel classes={{ root: classes2.labelroot }} size="small" component="legend" >Citations:</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Input size="small" className="yearRangeInput" value={citationYearRange[0]} id="from" margin="dense" onChange={handleCitationYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider classes={{ rail: classes2.rail, track: classes2.track, thumb: classes2.thumb, markLabel: classes2.markLabel }} value={citationYearRange} onChange={handleCitationYearSliderChange} valueLabelDisplay="auto" min={minYear} max={maxYear} />
                                    </Grid>
                                    <Grid item>
                                        <Input className="yearRangeInput" value={citationYearRange[1]} id="to" margin="dense" onChange={handleCitationYearInputChange} onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: { minYear },
                                                max: { maxYear },
                                                type: 'number',
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                                <div style={{ marginTop: 20, width: "100%" }}>
                                    <SingleSelect error={sortByErr} options={rankByDropdownValues} onInputChange={handleRankByChange}
                                        inputValue={rankBy} label="Sort by" helperText="Select sort by" />
                                </div>
                            </Grid>
                            <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                                <MultipleSelect error={facultyTypeErr} onChange={handleFacultyTypeChange} options={facultyTypeOptions}
                                    value={selectedFacultyType} menuProps={MenuPropsStyles} label="Faculty type to consider" helperText="Select value for faculty type to consider" />
                            </Grid>
                            <Grid item xs={6} container direction="column" justify="center" alignItems="center" >
                                <SingleSelect error={journalsErr} options={journalOptions} onInputChange={handleJournalsChange}
                                    inputValue={journals} label="Journals" helperText="Select journals" />
                            </Grid>
                            <Grid item xs={12} container direction="column" justify="center" alignItems="center">
                                <Button variant="contained" className={classes.searchButton} color="primary" onClick={getFacultyProductivity}>Search</Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {showSpinner &&
                    <div style={{ textAlign: "center" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
                }
                {!showSpinner && ((viewByAllFaculty === 1 && allFacultyList.length > 0) || (viewByAllFaculty === 0 && departmentList.length > 0)) &&
                    <div>
                        <p style={{ margin: "10px 0px" }} />
                        {rankBy !== "Alphabetical" &&
                            <div>
                                <InfoIcon style={{ marginTop: "-3px", color: "#555559", fontSize: "larger" }} />
                                <span style={{ fontSize: "15px", marginLeft: 5 }}>Change in position reflects the difference between the last two full years. The h-index does not change based on the filters.</span>
                            </div>
                        }
                        <Paper square style={{ padding: 20 }}>
                            <div style={{ marginBottom: 10 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={9} />
                                    <Grid item xs={3}>
                                        <SingleSelect error={null} options={facultyCountDropdownValues} onInputChange={handleFacultyCountChange}
                                            inputValue={facultyCount} label="Faculty Count" helperText="" defaultValue={facultyCount} />
                                    </Grid>
                                </Grid>
                            </div>

                            {viewByAllFaculty === 1 && allFacultyList.length > 0 && !showSpinner && displayJournalFlagCard &&
                                <ViewByAllFaculty facultyList={allFacultyList} facultyCount={facultyCount} allJournals={journals} />
                                // allJournals={journals==="All journals"}
                            }
                            {viewByAllFaculty === 0 && departmentList.length > 0 && !showSpinner && displayJournalFlagCard &&
                                <ViewByDepartment deptList={departmentList} facultyCount={facultyCount} allJournals={journals} />
                                // allJournals={journals==="All journals"}
                            }
                        </Paper>
                    </div>
                }
            </div>
        </ThemeProvider>
    );
}

export default FacultyProductivity;