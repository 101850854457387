import React, { useState, useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BACKEND_URL } from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import '../InputComponents/InputComponents.css';
const theme2 = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      filled: {
        transform: "translate(12px, 16px) scale(1)",
      },
      shrink: {
        transform: "translate(12px, 3px) scale(0.75) !important",
      }
    },
    MuiFilledInput: {
      root: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        height: 40,
        "&:hover": {
          backgroundColor: "#e8e8e8",
          borderBottom: 0
        }
      },
      underline: {
        '&:before': {
          borderBottom: 0
        },
        '&:after': {
          borderBottom: 0
        },
      }
    }
  }
});
const useStyles = makeStyles((theme) => ({
  inputRoot1: {
    paddingTop: "11px !important",
    borderRadius: "0px"
  }
}));
const UnivAutoComplete = props => {
  const inlineClasses = useStyles();

  const [value, setValue] = useState("");
  const [univIdDict, setunivIdDict] = useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false)//= open && options.length === 0;

  useEffect(() => {
    if (!props.value || props.value === "") {
      setOptions([])
      setValue("")

    } else {
      setOptions([{ name: props.value }])
      setValue(props.value)

    }
  }, [props.value]);

  const onInputChangeHandler = (event, newInputValue) => {
    let id = newInputValue in univIdDict ? univIdDict[newInputValue] : "";
    setValue(newInputValue)
    props.onChange(newInputValue, newInputValue, id);
    setLoading(true)
    if (!newInputValue || newInputValue.trim() === "") {
      setLoading(false)
      return;
    }
    const url = BACKEND_URL + 'auto/univ/' + newInputValue;

    (async () => {
      const response = await fetch(url);
      const schools = await response.json();
      let tempUnivIdDict = {}
      for (const key in schools.universities) {
        tempUnivIdDict[schools.universities[key]["name"]] = schools.universities[key]["univ_id"]
      }
      if (schools && schools !== undefined && schools.universities && schools.universities !== undefined){
        setunivIdDict(tempUnivIdDict);
        setOptions(Object.keys(schools.universities).map((key) => schools.universities[key]));
      }
      setLoading(false);
    })();
  }
  return (
    <ThemeProvider theme={theme2}>
      <Autocomplete
        classes={{ inputRoot: inlineClasses.inputRoot1 }}
        id="asynchronous-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setLoading(false)
          setOpen(false);
        }}
        defaultValue={{ name: props.value }}
        inputValue={value}
        options={options.map(option => option.name)}
        loading={loading}
        disableClearable
        freeSolo
        onInputChange={onInputChangeHandler}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            label="University"
            variant="filled"
            size="small"
            error={props.error}
            helperText={props.helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : <Search style={{ marginBottom: "auto" }} className="blackColor" />}
                  {/*   {params.InputProps.endAdornment} */}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </ThemeProvider>
  )
}
export default UnivAutoComplete;
