import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import {
  Avatar, Typography, Grid, Paper, Divider
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import InfoCard from '../../DashboardHome/Components/InfoCard';
import DescriptionIcon from '@material-ui/icons/Description';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import '../FacultyProductivity.css';
import Rodal from 'rodal';
import { useHistory } from "react-router-dom";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useDispatch, useSelector } from 'react-redux';
import * as facultyProductivityActions from '../../../../../store/actions/facultyProductivity';
import Loading from '../../../../../assets/Images/loading.gif';
const FacultyCard = props => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const categories = useSelector(state => state.facultyProductivity.categories);
  const paperSeries = useSelector(state => state.facultyProductivity.paperSeries);
  const citationSeries = useSelector(state => state.facultyProductivity.citaionSeries);
  const facultyDetails = useSelector(state => state.facultyProductivity.facultyDetails);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
      cursor: "pointer"
    },
    avatar: {
      height: 64,
      width: 64,
      marginLeft: 10,
    },
    appointment: {
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "15px",
    },
    difference: {
      display: 'flex',
      alignItems: 'center'
    },
    cardHeader: {
      textAlign: "start"
    },
    caption: {
      fontSize: "12px",
      color: "#000000",
    },
    name: {
      fontSize: "18px",
      color: "#000000",
    },
    icon: {
      width: 21,
      height: 18,
      marginLeft: "2%",
    },
    infoCardIcon: {
      height: 32,
      width: 32
    },
    gridHeight: {
      height: 20
    },
    avatarGrid: {
      paddingBottom: 0,
      paddingRight: 0
    },
    facultyName: {
      marginLeft: "8%",
      fontSize: "26px"
    }
  }));
  const customRodalStyles = {
    height: '582px',
    bottom: 'auto',
    top: '10%',
    left: '18%',
    width: '74%'
  };
  const classes = useStyles();
  const series = [
    {
      name: "Papers",
      data: paperSeries
    },
    {
      name: "Citations",
      data: citationSeries
    }
  ];
  const options = {
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -5,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        autoSelected: 'zoom'
      },
    },
    colors: ['#00259A', '#E24301'],
    dataLabels: {
      enabled: true,
    },
    title: {
      text: 'Papers and citations per year',
      align: 'center'
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Year'
      }
    },
    yaxis: {
      title: {
        text: 'Total'
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      floating: true,
      offsetY: 4,
      offsetX: -5
    }
  }

  let faculty = props.data;
  let paperYearRange = props.paperYearRange;
  let citationYearRange = props.citationYearRange;
  let facultyName = `${faculty.faculty_name}`;
  let total_papers = faculty.total_papers;
  let total_citations = faculty.total_citations;
  let hIndex = faculty.h_index;
  let aJournals = faculty.a_journal_count;
  let bJournals = faculty.b_journal_count;
  let aminusJournals = faculty.aminus_journal_count;
  let appointment = faculty.appointment === undefined || faculty.appointment === "0%" || faculty.appointment === "100%" ? "" : faculty.appointment;
  let rank = props.rank;
 

  let papersCard = {
    "title": "Papers",
    "value": facultyDetails.totalPapers,
    "icon": <DescriptionIcon className={classes.infoCardIcon} />
  }
  let citationsCard = {
    "title": "Citations",
    "value": facultyDetails.totalCitations,
    "icon": <CreateRoundedIcon className={classes.infoCardIcon} />
  }
  let papersPerYearCard = {
    "title": "Papers per Year",
    "value": facultyDetails.avgPapersPerYear,
    "icon": <DescriptionIcon className={classes.infoCardIcon} />
  }
  let citationsPerPaperCard = {
    "title": "Citations per Paper",
    "value": facultyDetails.avgCitationsPerPaper,
    "icon": <CreateRoundedIcon className={classes.infoCardIcon} />
  }
  let hIndexCard = {
    "title": "H-index",
    "value": facultyDetails.hIndex,
    "icon": <PersonAddIcon className={classes.infoCardIcon} />
  }
  const openModal = async () => {
    const token = localStorage.getItem("rl_token");
    setShowModal(true);
    setShowSpinner(true);
    try {
      await dispatch(facultyProductivityActions.getFacultyCardInfo(token, faculty.id, paperYearRange, citationYearRange));
    }
    catch (err) {
      alert("Internal Server Error. Please try again later");
      console.log(err);
    }
    setShowSpinner(false);

  }
  const closeModal = () => {
    setShowModal(false);
  }
  const goToProfile = (id) => {
    history.push({
      pathname: '/dashboards/profileEdit',
      state: { coauthorid: id, isReadOnly: true }
    })
  }
  return (
    <div>
      <Rodal showCloseButton={true} visible={showModal} onClose={closeModal} customStyles={customRodalStyles}>
        {showSpinner &&
          <div style={{ display: "flex", justifyContent: "center", "alignItems": "center", height: "100%" }}>
            <img src={Loading} alt="Loading..." width="200px" height="200px" />
          </div>
        }
        {!showSpinner &&
          <div>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <span className={classes.facultyName} style={{ cursor: "pointer" }} onClick={() => { goToProfile(faculty.id) }}><u>{facultyName}</u></span>
                <Divider style={{ height: 1 }} />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} className={classes.card}>
                <InfoCard data={papersCard} />
              </Grid>
              <Grid item xs={2} className={classes.card}>
                <InfoCard data={citationsCard} />
              </Grid>
              <Grid item xs={2} className={classes.card}>
                <InfoCard data={papersPerYearCard} />
              </Grid>
              <Grid item xs={2} className={classes.card}>
                <InfoCard data={citationsPerPaperCard} />
              </Grid>
              <Grid item xs={2} className={classes.card}>
                <InfoCard data={hIndexCard} />
              </Grid>
              <Grid item xs={1} />
              <div style={{ width: "100%", paddingLeft: 60, paddingRight: 60, marginTop: 15 }}>
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  height={400}
                />
              </div>
            </Grid>
          </div>
        }
      </Rodal>
      <Paper square onClick={openModal} className={classes.root} elevation={5}>
        <Grid container spacing={3}>
          <Grid item className={classes.avatarGrid} xs={3} container direction="column" justify="center" alignItems="center">
            <Avatar className={classes.avatar} style={{ color: "#000000" }}>{rank}</Avatar>
            <div className={classes.appointment}>
              {appointment}
            </div>
          </Grid>
          <Grid item xs={9} container direction="column" justify="center">

            <div className={classes.name}>
              {facultyName}
            </div>

            <div className={classes.caption}>
              Papers: {total_papers} | Citations: {total_citations}
            </div>

            <div className={classes.caption}>
              Overall H-Index: {hIndex}
            </div>

            {props.allJournals === "All journals" ?
              <div className={classes.caption}>
                Journals<br />
                A : {aJournals}&nbsp;|&nbsp;A- : {aminusJournals}&nbsp;|&nbsp;B : {bJournals}
              </div>
              :
              props.allJournals === "A journals" ?
                <div className={classes.caption}>
                  A journals : {aJournals}
                </div>
                :
                <div className={classes.caption}>
                  Journals<br />
                  A : {aJournals}&nbsp;|&nbsp;A- : {aminusJournals}
                </div>
            }
          </Grid>
          {"delta" in faculty &&
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <div className={classes.difference}>
                {faculty.delta < 0 ? <ArrowDownwardIcon className={classes.icon} style={{ color: "#dc3545" }} />
                  : faculty.delta === 0 ? <RemoveIcon className={classes.icon} style={{ color: "#000000" }} />
                    : <ArrowUpwardIcon className={classes.icon} style={{ color: "#28a745" }} />}
                <Typography className={classes.differenceValue} variant="body2" >
                  {Math.abs(faculty.delta)}
                </Typography>
                <Typography style={{ marginLeft: 5 }} className={classes.caption} variant="caption" >
                  Since Dec {faculty.curr_year}
                </Typography>
              </div>
            </Grid>
          }
        </Grid>
      </Paper>
    </div>
  );
}

export default FacultyCard