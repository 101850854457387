import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';
import { firstBy } from "thenby";

const maxRadius = 20;
const minRadius = 8;
const averageRadius = 14;
const outliers_to_show = 3;
const initialState = {
    interestingFactsResult: { "all": { "assistant": "-", "associate": "-", "full": "-", "other": "-" }, "female": { "assistant": "-", "associate": "-", "full": "-", "other": "-" }, "male": { "assistant": "-", "associate": "-", "full": "-", "other": "-" } },
    departmentFactsResult: { "highest_citation_per_faculty": "", "highest_faculty_diff": "", "highest_paper": "", "largest_market_share_by_paper": "" },
    genderDistributionResult: { "male": "", "female": "", "other": "" },
    departmentsResult: ["b", "v", "g"],
    selectedDepartment: "",
    schoolMode: true,
    outliersResult: {},
    outliersAvgAuthor: {},
    outliersAllAuthors: [],
    facultyMarketShares: [],
    facultyCitations: [],
    tenureReadinessCategory: [],
    tenureReadinessPapers: [],
    tenureReadinessCitations: [],
    tenureReadinessflag:"0",
}

const calculateRadius = (value, avgValue, min, max) => {
    const scale_value_range = [min, max]
    if (value >= avgValue) {
        var scale = (maxRadius - averageRadius) / (scale_value_range[1] - scale_value_range[0]);
        var capped = Math.min(scale_value_range[1], Math.max(scale_value_range[0], value)) - scale_value_range[0];
        return parseFloat((capped * scale + averageRadius).toFixed(2));
    } else {
        scale = (averageRadius - minRadius) / (scale_value_range[1] - scale_value_range[0]);
        capped = Math.min(scale_value_range[1], Math.max(scale_value_range[0], value)) - scale_value_range[0];
        return parseFloat((capped * scale + minRadius).toFixed(2));
    }
}

const getTotalOfRadius = (author) => {
    return author["citation_per_paper_radius"]
        + author["h_index_score_radius"]
        + author["paper_per_year_in_top_journal_radius"]
        + author["solo_paper_count_radius"]
        + author["top_journal_percentage_radius"]
}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTERESTING_FACTS:
            return updateObject(state, { interestingFactsResult: action.interestingFactsResult });
        case actionTypes.GET_DEPARTMENT_FACTS:
            return updateObject(state, { departmentFactsResult: action.departmentFactsResult });
        case actionTypes.GET_GENDER_DISTRIBUTION:
            let totalMaleFaculty = 0;
            let totalFemaleFaculty = 0;
            let totalOtherFaculty = 0;
            for (let university of action.genderDistributionResult) {
                for (let key in university) {
                    let genderData = university[key];
                    totalFemaleFaculty += genderData["Assistant Professor"]["f"]
                    totalMaleFaculty += genderData["Assistant Professor"]["m"]
                    totalOtherFaculty += genderData["Assistant Professor"]["other"]

                    totalFemaleFaculty += genderData["Associate Professor"]["f"]
                    totalMaleFaculty += genderData["Associate Professor"]["m"]
                    totalOtherFaculty += genderData["Associate Professor"]["other"]

                    totalFemaleFaculty += genderData["Full Professor"]["f"]
                    totalMaleFaculty += genderData["Full Professor"]["m"]
                    totalOtherFaculty += genderData["Full Professor"]["other"]
                }
            }
            let totalFaculty = totalMaleFaculty + totalFemaleFaculty + totalOtherFaculty;
            //console.log(totalOtherFaculty + " " + totalFemaleFaculty + " " + totalMaleFaculty);
            let genderDistributionUpdatedResult = { "male": totalMaleFaculty, "female": totalFemaleFaculty, "other": totalOtherFaculty, "total": totalFaculty }
            return updateObject(state, { genderDistributionResult: genderDistributionUpdatedResult });
        case actionTypes.GET_DASHBOARD_DEPARTMENTS:
            let depts = [];
            let departmentsArrayOfObjs = action.departmentsResult.departments;
            for (let i = 0; i < departmentsArrayOfObjs.length; i++) {
                depts.push(departmentsArrayOfObjs[i]['name'])
            }
            return updateObject(state, { departmentsResult: depts });
        case actionTypes.SELECTED_DEPARTMENT_DASHBOARD:
            return updateObject(state, { selectedDepartment: action.selectedDepartment });
        case actionTypes.SCHOOL_MODE:
            return updateObject(state, { schoolMode: action.schoolMode });
        case actionTypes.GET_OUTLIERS:
            const maxCitationPerPaper = Math.max.apply(Math, action.outliers.all_authors.map(function (o) { return o.citation_per_paper; }))
            const maxHIndex = Math.max.apply(Math, action.outliers.all_authors.map(function (o) { return o.h_index_score; }))
            const maxPaperPerYear = Math.max.apply(Math, action.outliers.all_authors.map(function (o) { return o.paper_per_year_in_top_journal; }))
            const maxSoloPaperCount = Math.max.apply(Math, action.outliers.all_authors.map(function (o) { return o.solo_paper_count; }))
            const maxABProportionValue = Math.max.apply(Math, action.outliers.all_authors.map(function (o) { return o.top_journal_percentage; }))
            const minCitationPerPaper = Math.min.apply(Math, action.outliers.all_authors.map(function (o) { return o.citation_per_paper; }))
            const minHIndex = Math.min.apply(Math, action.outliers.all_authors.map(function (o) { return o.h_index_score; }))
            const minPaperPerYear = Math.min.apply(Math, action.outliers.all_authors.map(function (o) { return o.paper_per_year_in_top_journal; }))
            const minSoloPaperCount = Math.min.apply(Math, action.outliers.all_authors.map(function (o) { return o.solo_paper_count; }))
            const minABProportionValue = Math.min.apply(Math, action.outliers.all_authors.map(function (o) { return o.top_journal_percentage; }))
            let authors = [];
            let temp_authors = [];

            action.outliers.all_authors.sort(
                firstBy(function (v1, v2) { return v2.h_index_score - v1.h_index_score; })
                    .thenBy(function (v1, v2) { return v2.citation_per_paper - v1.citation_per_paper; })
                    .thenBy(function (v1, v2) { return v2.paper_per_year_in_top_journal - v1.paper_per_year_in_top_journal; })
                    .thenBy(function (v1, v2) { return v2.top_journal_percentage - v1.top_journal_percentage; })
                    .thenBy(function (v1, v2) { return v2.solo_paper_count - v1.solo_paper_count; })
            );

            for (let author of action.outliers.all_authors) {
                author["citation_per_paper_radius"] = calculateRadius(author.citation_per_paper, action.outliers.avg_author["citation_per_paper"], minCitationPerPaper, maxCitationPerPaper);
                author["h_index_score_radius"] = calculateRadius(author.h_index_score, action.outliers.avg_author["h_index_score"], minHIndex, maxHIndex);
                author["paper_per_year_in_top_journal_radius"] = calculateRadius(author.paper_per_year_in_top_journal, action.outliers.avg_author["paper_per_year_in_top_journal"], minPaperPerYear, maxPaperPerYear);
                author["solo_paper_count_radius"] = calculateRadius(author.solo_paper_count, action.outliers.avg_author["solo_paper_count"], minSoloPaperCount, maxSoloPaperCount);
                author["top_journal_percentage_radius"] = calculateRadius(author.top_journal_percentage, action.outliers.avg_author["top_journal_percentage"], minABProportionValue, maxABProportionValue);
                temp_authors.push(author);
            }
            temp_authors.sort((a, b) => getTotalOfRadius(b) - getTotalOfRadius(a))
            let authorArray = []
            for (let author of temp_authors) {
                authorArray.push(author);
                if (authorArray.length === outliers_to_show) {
                    authors.push(authorArray);
                    authorArray = [];
                }
            }
            authors.push(authorArray);
            action.outliers.avg_author["citation_per_paper_radius"] = averageRadius
            action.outliers.avg_author["h_index_score_radius"] = averageRadius
            action.outliers.avg_author["paper_per_year_in_top_journal_radius"] = averageRadius
            action.outliers.avg_author["solo_paper_count_radius"] = averageRadius
            action.outliers.avg_author["top_journal_percentage_radius"] = averageRadius
            return updateObject(state, { outliersAllAuthors: authors, outliersAvgAuthor: action.outliers.avg_author });
        case actionTypes.GET_FACULTY_BY_MARKET_SHARE:
            return updateObject(state, { facultyMarketShares: action.facultyMarketShares.largest_market_share_by_paper })
        case actionTypes.GET_FACULTY_CITATIONS:
            return updateObject(state, { facultyCitations: action.facultyCitations })
        case actionTypes.GET_TENURE_READINESS:
            let category = [];
            let papers = [];
            let citations = [];
            action.tenureReadiness.AuthorData.sort((a, b) => b.paper - a.paper || b.citation - a.citation);
            for (let faculty of action.tenureReadiness.AuthorData) {
                category.push({ label: faculty.name });
                papers.push({ value: faculty.percent_paper, toolText: faculty.name + " | Papers: " + String(faculty.paper) });
                citations.push({ value: faculty.percent_citation, toolText: faculty.name + " | Citations: " + String(faculty.citation) });
            }
            return updateObject(state, { tenureReadinessCategory: category, tenureReadinessPapers: papers, tenureReadinessCitations: citations, tenureReadinessAverage: action.tenureReadiness.AuthorAverage,tenureReadinessFlag: action.tenureReadiness.flag})
        default:
            return state
    }
}

export default dashboardReducer;