import React from 'react';
import {Container} from 'react-bootstrap';
import classes from './ForFaculty.module.css';
import SwingComponent from '../../swing/SwingComponent';
import Subscribe from '../../Subscribe/Subscribe';

const forFaculty = props => {
    return (
        <div>
            <Subscribe type={"ForFaculty"}/>
            <Container>
                <div className={classes.container}>
                <p className={classes.description}>FOR FACULTY</p> 
                <SwingComponent type="faculty"/>
                </div>
            </Container>
        </div>
    )
}

export default forFaculty;