import React from 'react'

const Paperlog = props=> {
    const logRecord = props.data;
    return (
        <div style={{cursor:"pointer"}} onClick={props.onClick}>
            {logRecord.paper!==undefined && <h5>{logRecord.paper.document_title}</h5>}
            <div style={{display:"flex"}}>
                <div style={{flex:1}}>
                    <p><b>Name on paper: </b>{logRecord.author_name}</p>
                </div>
                {
                    logRecord.error === "RG_GS_MISMATCH" ? 
                        <div style={{flex:1}}>
                            <p><b>Name on record: </b>{logRecord.gs_name+"; "+logRecord.rg_profile_name}</p>
                        </div>
                    : logRecord.error !== "NAME_TOO_SIMILAR" ?
                        <div style={{flex:1}}>
                            <p><b>Name on record: </b>{logRecord.already_name}</p>
                        </div>
                        :null
                }
                
            </div>
            <p><b>Error: </b>{logRecord.error}</p>
        </div>
    )
}

export default Paperlog;
