import React from 'react'
import PropTypes from 'prop-types'

const Message = (props) => {

    


    return (
        <>

            <div class="alert alert-info alert-dismissible fade show" role="alert">
                { props.msg }
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={()=> props.close()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
      
        </>
    );
};

Message.propTypes = {
    msg: PropTypes.string.isRequired,
    displayFlag: PropTypes.bool.isRequired
}

export default Message
