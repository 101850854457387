import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
    cohortRows: [],
    count: 2,
    cohortAnalysisData: [],
    cohortAnalysisAllData: [],
    comparefacultyData: {
        faculty1: {},
        faculty2: {},
        papersChartSeries: [],
        citationsChartSeries: [],
        chartCategories: []
    },
    compareFacultyData: []
}
const defaultProfessor = "Assistant Professor";
const cohortanalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_COHORT_ROW:
            let temp = action.rows;
            temp.push(action.facultyToAdd);
            return updateObject(state, { count: action.newCount, cohortRows: temp });
        case actionTypes.REMOVE_COHORT_ROW:
            let temp1 = action.rows.filter(element => element.index !== action.idToRemove);
            return updateObject(state, { cohortRows: temp1 });
        case actionTypes.ANALYZE_COHORT:
            let response = [];
            let compareResponse = [];
            let result = action.cohortAnalysisData.result;
            for (const id in result) {
                let data = {
                    name: `${result[id]["First Name"]} ${result[id]["Last Name"]}`,
                    id: id,
                    totalPapers: result[id][defaultProfessor]["total_papers"] !== -1 ? result[id][defaultProfessor]["total_papers"] : "-",
                    totalCitations: result[id][defaultProfessor]["total_citations"] !== -1 ? result[id][defaultProfessor]["total_citations"] : "-",
                    hIndex: result[id][defaultProfessor]["hindex"] !== -1 ? result[id][defaultProfessor]["hindex"] : "-",
                    abJournalRatio: result[id][defaultProfessor]["abratio"] !== -1 ? result[id][defaultProfessor]["abratio"] : "-"
                };
                response.push(data);
                compareResponse.push(result[id]["data"]);
            }
            return updateObject(state, { cohortAnalysisData: response, cohortAnalysisAllData: action.cohortAnalysisData.result, compareFacultyData: compareResponse });
        case actionTypes.MILESTONE_CHANGE:
            let updatedResponse = [];
            let allData = action.allData;
            let milestone = action.milestone
            for (const id in allData) {
                let data = {
                    name: `${allData[id]["First Name"]} ${allData[id]["Last Name"]}`,
                    id: id,
                    totalPapers: allData[id][milestone]["total_papers"] !== -1 ? allData[id][milestone]["total_papers"] : "-",
                    totalCitations: allData[id][milestone]["total_citations"] !== -1 ? allData[id][milestone]["total_citations"] : "-",
                    hIndex: allData[id][milestone]["hindex"] !== -1 ? allData[id][milestone]["hindex"] : "-",
                    abJournalRatio: allData[id][milestone]["abratio"] !== -1 ? allData[id][milestone]["abratio"] : "-"
                };
                updatedResponse.push(data);
            }
            return updateObject(state, { cohortAnalysisData: updatedResponse });
        case actionTypes.GET_COMPARE_FACULTY:
            let tempFaculty1, tempFaculty2;
            [tempFaculty1, tempFaculty2] = action.searchResult["result"];
            let chartCategories = [];
            let faculty1Papers = [];
            let faculty1Citations = [];
            let faculty2Papers = [];
            let faculty2Citations = [];
            let maxYears = Math.max(Object.keys(tempFaculty1.data.papers_over_years).length, Object.keys(tempFaculty2.data.papers_over_years).length);
            chartCategories = Array.from({ length: maxYears }, (x, i) => (i + 1));
            for (const key in tempFaculty1.data.papers_over_years) {
                faculty1Papers.push(tempFaculty1.data.papers_over_years[key]);
                faculty1Citations.push(tempFaculty1.data.citation_over_years[key]);
            }
            for (const key in tempFaculty2.data.papers_over_years) {
                faculty2Papers.push(tempFaculty2.data.papers_over_years[key]);
                faculty2Citations.push(tempFaculty2.data.citation_over_years[key]);
            }
            let workHistoryTitle = [];
            let workHistoryYear = [];
            for (const key in tempFaculty1.data.work_history) {
                workHistoryTitle.push(key);
                workHistoryYear.push(tempFaculty1.data.work_history[key][0]);
            }
            tempFaculty1.data["work_history_title"] = workHistoryTitle;
            tempFaculty1.data["work_history_year"] = workHistoryYear;
            workHistoryTitle = [];
            workHistoryYear = [];
            for (const key in tempFaculty2.data.work_history) {
                workHistoryTitle.push(key);
                workHistoryYear.push(tempFaculty2.data.work_history[key][0]);
            }
            tempFaculty2.data["work_history_title"] = workHistoryTitle;
            tempFaculty2.data["work_history_year"] = workHistoryYear;
            let papersChartSeries1 = { "name": tempFaculty1.first_name + " " + tempFaculty1.last_name, "data": faculty1Papers }
            let papersChartSeries2 = { "name": tempFaculty2.first_name + " " + tempFaculty2.last_name, "data": faculty2Papers }
            let citationsChartSeries1 = { "name": tempFaculty1.first_name + " " + tempFaculty1.last_name, "data": faculty1Citations }
            let citationsChartSeries2 = { "name": tempFaculty2.first_name + " " + tempFaculty2.last_name, "data": faculty2Citations }
            return updateObject(state, {
                comparefacultyData: {
                    faculty1: tempFaculty1,
                    faculty2: tempFaculty2,
                    papersChartSeries: [papersChartSeries1, papersChartSeries2],
                    citationsChartSeries: [citationsChartSeries1, citationsChartSeries2],
                    chartCategories: chartCategories
                }
            });
        case actionTypes.CLEAR_COMPARE_FACULTY:
            return updateObject(state, {
                comparefacultyData: {
                    faculty1: {},
                    faculty2: {},
                    papersChartSeries: [],
                    citationsChartSeries: [],
                    chartCategories: []
                }
            });
        case actionTypes.CLEAR_COHORT_ANALYSIS:
            return updateObject(state, {
                cohortRows: [],
                count: 2,
                cohortAnalysisData: [],
                cohortAnalysisAllData: [],
                comparefacultyData: {
                    faculty1: {},
                    faculty2: {},
                    papersChartSeries: [],
                    citationsChartSeries: [],
                    chartCategories: []
                },
                compareFacultyData: []
            });
        default:
            return state
    }
}

export default cohortanalysisReducer;