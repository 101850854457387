import React, { useState, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UnivAutoComplete from '../../Autocomplete/UnivAutoCompleteWithBlur'
import * as departmentConfigHome from '../../../store/actions/departmentConfigHome';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import './deptConfig.css'

const DeptConfigHome = () => {

    // Testing on AAB College

    // University Autocomplete

    const [currentSchool, setCurrentSchool] = useState('');
    const [tempUniversityID, setTempUniversityID] = React.useState('');
    const EditBoxRef = useRef(null);

    //  EDIT Journal

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    // const [tempUniversityID, setTempUniversityID] = React.useState('5e73ea496922717d461529b3')

    const handleSelectedUniversity = async (name, value, id) => {

        setCurrentSchool(name);

        const token = localStorage.getItem("rl_token");

        if (id !== '') {
            setTempUniversityID(id);

            try {
                await dispatch(departmentConfigHome.deptConfigAction(token, id));
            }
            catch (error) {
                alert("Internal Server Error. Please try again after some time")
            }
        }
    }

    const departmentData = useSelector(state => state.deptConfig.departments);
    const defaultDepartments = useSelector(state => state.deptConfig.default_mapping);
    const allJournalDict = useSelector(state => state.deptConfig.journals);
    const originalData = useSelector(state => state.deptConfig.originalData);

    const defaultDeptNames = [];
    if (defaultDepartments.length > 0) {
        defaultDepartments.map((dept) => {
            defaultDeptNames.push(dept.name);
        });
    }

    const dispatch = useDispatch();

    // Display Add New Department

    const [displayFlag, setDisplayFlag] = useState(false);
    const [editDepartmentData, setEditDepartmentData] = useState({
        'name': "",
        'data': {
            'A': [],
            'A-': [],
            'B': [],
            'default_mapping': []
        },
        'id': null
    })

    const [dropdownAJournal, setDropdownAJournal] = useState([]);
    const [dropdownAminusJournal, setDropdownAminusJournal] = useState([]);
    const [dropdownBJournal, setDropdownBJournal] = useState([]);
    const [flagUpdateJournal, setFlagUpdateJournal] = useState(false);

    const onChangeSelect = (updatedData) => {
        setEditDepartmentData({ ...editDepartmentData, data: { ...editDepartmentData.data, default_mapping: updatedData } });
        setFlagUpdateJournal(true);
    }

    const onChangeAJournal = (updatedAjournal) => {
        setEditDepartmentData({ ...editDepartmentData, data: { ...editDepartmentData.data, 'A': updatedAjournal } });
    }

    const onChangeAminusJournal = (updatedAminusJournal) => {
        setEditDepartmentData({ ...editDepartmentData, data: { ...editDepartmentData.data, 'A-': updatedAminusJournal } });
    }

    const onChangeBJournal = (updatedBJournal) => {
        setEditDepartmentData({ ...editDepartmentData, data: { ...editDepartmentData.data, 'B': updatedBJournal } });
    }

    const onChangeTitle = (updatedText) => {
        setEditDepartmentData({ ...editDepartmentData, name: updatedText });
    }

    const updateJournalDropdown = () => {
        let tempA = [];
        let tempAminus = [];
        let tempB = [];
        let currentData = [];

        if (editDepartmentData.data.default_mapping.length > 0) {
            editDepartmentData.data.default_mapping.map((default_d) => {
                defaultDepartments.map((dept) => {
                    if (dept.name === default_d) {
                        currentData = dept.data
                    }
                })

                currentData["A"].map((journal) => {
                    tempA = [...tempA, journal];
                })

                currentData["A-"].map((journal) => {
                    tempAminus = [...tempAminus, journal];
                })

                currentData["B"].map((journal) => {
                    tempB = [...tempB, journal];
                })
            });
        }

        setDropdownAJournal(tempA);
        setDropdownAminusJournal(tempAminus);
        setDropdownBJournal(tempB);

        setFlagUpdateJournal(false);
    }

    const openDisplayAddDepartment = (updateDepartmentData) => {
        let tempA = [];
        let tempAminus = [];
        let tempB = [];
        let currentData = [];

        if (updateDepartmentData) {
            setEditDepartmentData(updateDepartmentData);

            if (updateDepartmentData.data.default_mapping !== undefined && updateDepartmentData.data.default_mapping.length > 0) {
                updateDepartmentData.data.default_mapping.map((default_d) => {
                    defaultDepartments.map((dept) => {
                        if (dept.name === default_d) {
                            currentData = dept.data
                        }
                    })

                    if (currentData['A'] == null) {
                        alert("Default Department Names are not consistent, thus this error");
                    }
                    else {
                        for (let journal of currentData["A"]) {
                            tempA = [...tempA, journal];
                        }

                        for (let journal of currentData["A-"]) {
                            tempAminus = [...tempAminus, journal];
                        }

                        for (let journal of currentData["B"]) {
                            tempB = [...tempB, journal];
                        }
                    }

                    // currentData["A"].map((journal) =>{
                    //     tempA = [...tempA, journal];
                    // })

                    // currentData["A-"].map((journal) =>{
                    //     tempAminus = [...tempAminus, journal];
                    // })

                    // currentData["B"].map((journal) =>{
                    //     tempB = [...tempB, journal];
                    // })
                });
            }
        }

        setDropdownAJournal(tempA);
        setDropdownAminusJournal(tempAminus);
        setDropdownBJournal(tempB);

        setDisplayFlag(true);

        setTimeout(() => {
            if (EditBoxRef.current) {
                EditBoxRef.current.scrollIntoView();
            }
        }, 50);
    }

    const closeDisplayAddDepartment = () => {
        setDisplayFlag(false);
        setEditDepartmentData({
            'name': "",
            'data': {
                'A': [],
                'A-': [],
                'B': [],
                'default_mapping': []
            },
            'id': null
        });
    }

    const updateDataAPI = async (actionMessage) => {
        if (editDepartmentData.name === '') {
            alert('Department cannot be saved or deleted. Please add Department Name')
        }
        else {
            try {
                const adminToken = localStorage.getItem("rl_token");
                await dispatch(departmentConfigHome.saveDataAction(adminToken, tempUniversityID, originalData, editDepartmentData, actionMessage));
                setDisplayFlag(false);
                setEditDepartmentData({
                    'name': "",
                    'data': {
                        'A': [],
                        'A-': [],
                        'B': [],
                        'default_mapping': []
                    },
                    'id': null
                });
            }
            catch (error) {
                alert("Internal Server Error. Please try again after some time")
            }
        }
    }

    return (
        <div className="displayContainer center mt-4" style={{ marginBottom: 20 }}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={5}>
                    <UnivAutoComplete style={{ width: "50%" }} name="name" value={currentSchool} onChange={handleSelectedUniversity} helperText={false ? "Enter a valid school" : null} />
                </Grid>
            </Grid>

            {defaultDepartments.length > 0 ?
                <>
                    <Paper elevation={10} style={{ margin: "auto", width: "80%", marginTop: 40 }}>
                        <Grid container alignItems="center" justify="center">
                            <Grid item container className="Headers" alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item xs={10}>
                                    <b>Departments</b>
                                </Grid>
                            </Grid>
                        </Grid>

                        {departmentData.length !== 0 ? departmentData.map((department, index) => {

                            return (
                                <div>
                                    {/* Updated code block start */}

                                    {/* <Divider variant="middle" /> */}
                                    <Grid key={index} container xs={12} alignItems="center" justify="center">
                                        <Grid item xs={12} alignItems="center" justify="center" style={{ marginTop: 1, marginBottom: 1 }}>
                                            <Accordion defaultExpanded={false}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    style={{ backgroundColor: "#F3F3F3" }}>
                                                    
                                                    <Divider variant="middle" />

                                                    <Grid container >
                                                        <Grid item xs={10}>
                                                            <b>{department.name}</b>
                                                        </Grid>

                                                        <Grid item xs={2}>
                                                            <b><Button style={{ outline: "none" }} onClick={() => openDisplayAddDepartment(department)}>EDIT</Button></b>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails >
                                                    <Grid container alignItems="center" justify="flex-start">
                                                        <Grid item container alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                                            <Grid conatiner xs={10}>
                                                                <Accordion defaultExpanded={true}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        style={{ backgroundColor: "#F3F3F3" }}>
                                                                        <b>Selected Department Mapping</b>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails >
                                                                        <Grid item>
                                                                            {department.data.default_mapping !== undefined && department.data.default_mapping.length !== 0 ? department.data.default_mapping.sort(function (a, b) {
                                                                                if(a && b){
                                                                                    return a.localeCompare(b);
                                                                                }
                                                                              }).map((deptTemp, i) =>
                                                                                    <b key={i}><Chip label={deptTemp} style={{ margin: 5 }} /></b>
                                                                                )
                                                                                :
                                                                                <b><Chip label="No Department Selected" /></b>
                                                                            }
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </Grid>

                                                        {
                                                            Object.keys(department.data).map((Jname, JVal) => {
                                                                const journalList = department.data[Jname].sort(function (a, b) {
                                                                    if(allJournalDict[a] && allJournalDict[b]){
                                                                    return allJournalDict[a].localeCompare(allJournalDict[b]);
                                                                    }
                                                                  })

                                                                let chipLength = journalList.length

                                                                return (
                                                                    <>
                                                                        {Jname === 'A' || Jname === 'A-' || Jname === 'B' ?
                                                                            <Grid key={JVal} item container className="Headers" alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                                                                <Grid conatiner xs={10}>
                                                                                    <Accordion defaultExpanded={Jname === 'A' || Jname === 'A-'}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls="panel1a-content"
                                                                                            style={{ backgroundColor: "#F3F3F3" }}>
                                                                                            <b>{Jname} Journals</b>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails >
                                                                                            <Grid item>
                                                                                                {journalList && chipLength !== 0 ? journalList.map((journals, i) =>

                                                                                                    <b key={i}><Chip label={allJournalDict[journals] !== '' ? allJournalDict[journals] : journals} style={{ margin: 5 }} /></b>
                                                                                                )
                                                                                                    :
                                                                                                    <b><Chip label="No Journals" /></b>
                                                                                                }
                                                                                            </Grid>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Grid>
                                                                            </Grid>
                                                                        : null}
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>

                                    {/* updated code block ends */}

                                    {/* <Divider variant="middle" />
                                    <Grid key={index} container xs={12} alignItems="center" justify="center">
                                        <Grid item container alignItems="center" justify="flex-start"
                                            style={{ marginTop: 10, marginBottom: 10 }}>
                                            <Divider variant="middle" />
                                            <Grid item xs={10}>
                                                <b>{department.name}</b>
                                            </Grid>

                                            <Grid item >
                                                <b><Button style={{ outline: "none" }}
                                                    onClick={() => openDisplayAddDepartment(department)}>EDIT</Button></b>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10}>
                                            <Accordion defaultExpanded={true}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    style={{ backgroundColor: "#F3F3F3" }}>
                                                    <b>Selected Department Mapping</b>
                                                </AccordionSummary>
                                                <AccordionDetails >
                                                    <Grid item>

                                                        {department.data.default_mapping.length !== 0 ? department.data.default_mapping.map((deptTemp, i) =>
                                                            
                                                            <b ><Chip label={deptTemp } style={{ margin: 5 }} /></b>     
                                                        )
                                                            :
                                                            <b><Chip label="No Department Selected" /></b>
                                                        }
                                                    </Grid>

                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        {   
                                            Object.keys(department.data).map((Jname, JVal) => {

                                                

                                                const journalList = department.data[Jname]

                                                let chipLength = journalList.length

                                                

                                                return (
                                                    <>
                                                        { Jname == 'A' || Jname == 'A-' || Jname == 'B' ?
                                                        <Grid key={JVal} item container className="Headers" alignItems="center" justify="center"
                                                            style={{ marginTop: 10, marginBottom: 10 }}>
                                                            <Grid item xs={10}>
                                                                <Accordion defaultExpanded={Jname == 'A' || Jname == 'A-'}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        style={{ backgroundColor: "#F3F3F3" }}>
                                                                        <b>{Jname} Journals</b>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails >
                                                                        <Grid item>

                                                                            {journalList && chipLength !== 0 ? journalList.map((journals, i) =>
                                                                                
                                                                                <b ><Chip label={allJournalDict[journals] !== '' ? allJournalDict[journals] : journals } style={{ margin: 5 }} /></b>     
                                                                            )
                                                                                :
                                                                                <b><Chip label="No Journals" /></b>
                                                                            }

                                                                        </Grid>

                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </Grid>
                                                        : null}
                                                    </>
                                                );

                                            })
                                        }

                                    </Grid> */}

                                </div>
                            );
                        })
                            : null}

                        <Divider variant="middle" />

                        <Grid container alignItems="center" justify="center">
                            <Grid item container className="Headers" alignItems="center" justify="flex-end" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={5} justify="flex-end" style={{ margin: 10, marginRight: 20 }}>
                                    <Button onClick={() => openDisplayAddDepartment()} variant="contained" color="primary">Add Department</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>

                    { displayFlag ?

                        <Paper elevation={10} style={{ margin: "auto", width: "80%", marginTop: 40 }} ref={EditBoxRef}>
                            <Grid item container alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={10} alignItems="center" justify="center" style={{ marginTop: 10 }}>
                                    {editDepartmentData.id ? <b>Edit Department</b> : <b>Add New Department</b>}
                                </Grid>

                                <Grid item alignItems="center" justify="center" style={{ marginTop: 10 }}>
                                    <Button><CloseIcon onClick={closeDisplayAddDepartment} /></Button>
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={12} alignItems="center" justify="center" style={{ marginTop: 10 }}>
                                    <TextField id="outlined-basic" label="Name Your Deparment" variant="outlined" style={{ width: "80%" }}
                                        value={editDepartmentData.name} onChange={(e) => { onChangeTitle(e.target.value) }} required />
                                </Grid>
                            </Grid>

                            <Divider variant="middle" />

                            <Grid item container alignItems="center" justify="center"
                                style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={12} justify="center" alignItems="center">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={defaultDeptNames.sort(function (a, b) {
                                            if(a && b){
                                                return a.localeCompare(b);
                                            }
                                          })}
                                        value={editDepartmentData.data.default_mapping !== undefined ? editDepartmentData.data.default_mapping.sort(function (a, b) {
                                            if(a && b){
                                                return a.localeCompare(b);
                                            }
                                          }):[]}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option}
                                        style={{ width: "80%" }}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </React.Fragment>
                                        )}
                                        onChange={(_, selectedOptions) => { onChangeSelect(selectedOptions) }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Select Departments" placeholder="Select Journal Department" />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Divider variant="middle" />

                            {
                                flagUpdateJournal ? updateJournalDropdown() : null
                            }

                            <Grid item container alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={12} justify="center" alignItems="center">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={dropdownAJournal.sort(function (a, b) {
                                            if(allJournalDict[a] && allJournalDict[b]){
                                                return allJournalDict[a].localeCompare(allJournalDict[b]);
                                            }
                                          })}
                                        value={editDepartmentData.data['A'].sort(function (a, b) {
                                            if(allJournalDict[a] && allJournalDict[b]){
                                                return allJournalDict[a].localeCompare(allJournalDict[b]);
                                            }
                                          })}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => allJournalDict[option]}
                                        style={{ width: "80%" }}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {allJournalDict[option]}
                                            </React.Fragment>
                                        )}
                                        onChange={(_, selectedOptions) => { onChangeAJournal(selectedOptions) }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Add A Journals" placeholder="A Journals" />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Divider variant="middle" />

                            <Grid item container alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={12} justify="center" alignItems="center">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={dropdownAminusJournal.sort(function (a, b) {
                                            if(allJournalDict[a] && allJournalDict[b]){
                                                return allJournalDict[a].localeCompare(allJournalDict[b]);
                                            }
                                          })}
                                        value={editDepartmentData.data['A-'].sort(function (a, b) {
                                            if(allJournalDict[a] && allJournalDict[b]){
                                                return allJournalDict[a].localeCompare(allJournalDict[b]);
                                            }
                                          })}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => allJournalDict[option]}
                                        style={{ width: "80%" }}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {allJournalDict[option]}
                                            </React.Fragment>
                                        )}
                                        onChange={(_, selectedOptions) => { onChangeAminusJournal(selectedOptions) }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Add A- Journals" placeholder="A- Journals" />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Divider variant="middle" />

                            <Grid item container alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={12} justify="center" alignItems="center">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={dropdownBJournal.sort(function (a, b) {
                                            if(allJournalDict[a] && allJournalDict[b]){
                                                return allJournalDict[a].localeCompare(allJournalDict[b]);
                                            }
                                          })}
                                        value={editDepartmentData.data['B'].sort(function (a, b) {
                                            if(allJournalDict[a] && allJournalDict[b]){
                                                return allJournalDict[a].localeCompare(allJournalDict[b]);
                                            }
                                          })}
                                        disableClearable
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => allJournalDict[option] || ""}
                                        style={{ width: "80%" }}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {allJournalDict[option]}
                                            </React.Fragment>
                                        )}
                                        onChange={(_, selectedOptions) => { onChangeBJournal(selectedOptions) }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Add B Journals" placeholder="B Journals" />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container alignItems="center" justify="center"
                                style={{ marginTop: 10, marginBottom: 10 }}>
                                <Grid item container xs={5} justify="center" style={{ margin: 10, marginRight: 20 }}>
                                    <Button variant="contained" color="primary" onClick={() => updateDataAPI('save')}>Save</Button>
                                </Grid>

                                <Grid item container xs={5} justify="center" style={{ margin: 10, marginRight: 20 }}>
                                    <Button variant="contained" color="primary" onClick={() => updateDataAPI('delete')}>Delete</Button>
                                </Grid>
                            </Grid>
                        </Paper>

                        : null}

                </> : null}
        </div>
    )
}

export default DeptConfigHome;
