import * as actionTypes from './actionTypes';
import { BACKEND_URL } from '../../utils';

export const similarAuthorSearchAction = (data) => {
    return {
        type: actionTypes.SIMILAR_AUTHOR_SEARCH,
        searchResult: data
    }
}

export const similarAuthorSearch = (token, author_id) => {

    return async dispatch => {
        if (author_id) {

            const response = await fetch(BACKEND_URL + 'get_similar_auth/' + author_id, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            });
            if (!response.ok) {
                throw new Error('Something went wrong while searching for profile.');
            }
            const similarAuthorData = await response.json();
            let primaryCoAuthList = similarAuthorData['primary_co_authors']
            let secondaryCoAuthList = similarAuthorData["secondary_co_authors"]

            primaryCoAuthList.sort(function (first, second) {
                return second.first_year_career - first.first_year_career;
            });
            secondaryCoAuthList.sort(function (first, second) {
                return second.first_year_career - first.first_year_career;
            });

            let final_similar_authors = [...primaryCoAuthList, ...secondaryCoAuthList]
            dispatch(similarAuthorSearchAction(final_similar_authors));
        }
    };
}

export const clearState = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_SIMILAR_AUTHOR_SEARCH });
    }
}

