import React, { Fragment, useState } from 'react'
import axios from 'axios'
import Message from './Message'
import ProgressBar from './ProgressBar'
import { BACKEND_URL } from '../../../utils'
import Loading from '../../../assets/Images/loading.gif';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons'

const API = 'https://api.researchloupe.com/mark_journals'


const FileUpload = () => {
    const [file,setFile] = useState('');
    const [filename,setFilename] = useState('Choose File (Supports PDF)');
    const [uploadedFile, setUploadedFile] = useState({});
    const [message, setMessage] = useState('');
    const [uploadStatus, setUploadStatus] = useState(0);
    const [showSpinner, setShowSpinner] = React.useState(false);

    const [openMessage, setOpenMessage] = useState(false);

    const onChange = e =>{
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);

    };

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        const token = localStorage.getItem('rl_token');
        
        axios.post(BACKEND_URL + "mark_journals", formData, {
            headers: {
                'Content-Type':'multiport/form-data',
                Authorization: 'Bearer '+ token,
            },
            onUploadProgress: progressEvent => {
                setUploadStatus(parseInt(Math.round((progressEvent.loaded * 100)/progressEvent.total)));
                
            setTimeout(() => setUploadStatus(0), 5000);
            setShowSpinner(true);
           
            }
        })
        .then(function (response) {
            const filePath = response.data.url;
            setUploadedFile({filename, filePath});
            setMessage('File Uploaded');
            setShowSpinner(false);
            setOpenMessage(true);
           
            })
        .catch(function(error){
            if (error.response){
                if(error.response.status === 500){
                    setMessage('There was a problem with server');
                }else if(error.response.status === 400){
                    setMessage('No File Uploaded')
                }
                else{
                    setMessage('Error '+error.response.status);
                    }
                setOpenMessage(false);
                setShowSpinner(false);
            }
        });
    };
    const closeMessage = () =>{
        setOpenMessage(false)
        setShowSpinner(false);
    }

    return (
        <Fragment>
            { openMessage ? <Message msg={message} close={closeMessage}/> :null}
            <form onSubmit={onSubmit}>
                <div>
                    <div className="custom-file mb-4" style={{width:'85%'}} >
                        <input type="file" className="custom-file-input" id="customFile" onChange={onChange} accept="application/pdf"/>
                        <label className="custom-file-label" htmlFor="customFile">{filename}</label>
                    </div>

                    <input type="submit" value="Upload" className='btn btn-primary btn-block'style={{width:'13%', display:'inline', marginLeft:'2%', marginTop:'5px'}}/>

                </div>

                {uploadStatus > 0 ? 
                <ProgressBar percentage={uploadStatus}/>
                : null
                }
                {
                   showSpinner &&
                            <div style={{ textAlign: "center" }}>
                                <img src={Loading} alt="Loading..." width="80px" height="80px" />
                            </div>
                        }

            </form>

            {uploadedFile ? <div className='row mt-5'>
                <div className='col-md-12 m-auto'>
                    <h3 className='text-center'>{uploadedFile.filename}</h3>
                    {uploadedFile.filePath ? <iframe style={{width:'100%', height:'40rem'}} src={uploadedFile.filePath} alt=''/>
                    : null}
                    
                </div>
            </div> : null}

        </Fragment>
    )
}

export default FileUpload;
