import React from 'react';
 import { TextField, FormHelperText, FormControl } from '@material-ui/core';
 import './InputComponents.css';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { Search } from '@material-ui/icons';
 const SingleSelectDisabled = props => {
     const {
         error,
         options,
         onInputChange,
         inputValue,
         label,
         getOptionDisabled,
         helperText
     } = props;
     return (
         <FormControl fullWidth size="small" variant="filled" error={error}>
             <Autocomplete required fullWidth size="small" options={options}
                 onInputChange={onInputChange} inputValue={inputValue} 
                 getOptionDisabled={getOptionDisabled}
                 style={{paddingRight:0}}
                 renderInput={(params) => (
                     <TextField
                         required
                         error={error}
                         {...params}
                         InputProps={{
                             ...params.InputProps,
                             endAdornment: (
                                 <Search style={{ marginBottom: "auto" }} className="blackColor" />
                             ),
                         }}
                         label={label} variant="filled" />
                 )}
             />
             {error && <FormHelperText>{helperText}</FormHelperText>}
         </FormControl>
     );
 }
 
	 export default SingleSelectDisabled; 