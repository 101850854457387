import { Paper, List } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../../../../store/actions/dashboardUniv';
import Loading from '../../../../../assets/Images/loading.gif';

const DepartmentFacts = props => {
    const dispatch = useDispatch();
    const departmentFactsResult = useSelector(state => state.dashboards.departmentFactsResult);
    function createData(title, department) {
        return { title, department };
    }

    React.useEffect(() => {
        const token = localStorage.getItem('rl_token');
        const userType = localStorage.getItem('rl_type');
        if (token && token !== null) {
            departmentFacts(token, userType);
        }
    }, []);

    const departmentFacts = async (token, userType) => {
        try {
            await dispatch(dashboardActions.departmentFacts(token));
        } catch (error) {
            console.log("Error getting department facts")
        }
    }


    const result = [
        createData("Highest papers per year", departmentFactsResult["highest_paper"]),
        createData("Largest difference among faculty", departmentFactsResult["highest_faculty_diff"]),
        createData("Largest share by paper", departmentFactsResult["largest_market_share_by_paper"]),
        createData("Highest citations per faculty", departmentFactsResult["highest_citation_per_faculty"])
    ]

    return (
        <Paper style={{ maxHeight: "240px", padding: "12px", overflow: 'auto' }}>
            <div style={{ fontSize: "20px" }}>Department Facts</div>
            {
                departmentFactsResult["highest_paper"] !== '' || departmentFactsResult["highest_faculty_diff"] !== ''
                    || departmentFactsResult["largest_market_share_by_paper"] !== '' || departmentFactsResult["highest_citation_per_faculty"] !== ''

                    ?
                    <List>
                        {result.map((row) => (
                            <div key={row.name} >
                                <div align={'left'} padding={'none'} style={{ fontSize: "0.9rem", fontWeight:500 }}>{row.title}:</div>
                                <div align={'left'} padding={'none'} style={{ paddingBottom: "12px",fontSize: "0.9rem" }}>{row.department}</div>
                            </div>
                        ))}
                    </List>

                    :
                    <div style={{ textAlign: "center", height: "260px" }}>
                        <img src={Loading} alt="Loading..." width="80px" height="80px" />
                    </div>
            }
        </Paper>
    )
}

export default DepartmentFacts;