import React from 'react';
import classes from './SocialMedia.module.css';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

const socialMedia = props => {

    return (
        <div style={{ paddingBottom: 20 }} >
            {/* <FontAwesomeIcon  className={classes.fa} /> */}
            <ul className={classes.ulSocialMedia}>

                <li><a href="https://twitter.com/ResearchLoupe" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} className={classes.fa} /></a></li>
                <li><a href="https://www.linkedin.com/company/researchloupe/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} className={classes.fa} /></a></li>
                <li><a href="mailto:RLteam@researchloupe.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAt} className={classes.fa} /></a></li>
                {/* <li><a href="https://facebook.com/" target="blank"><FontAwesomeIcon icon={faFacebookF} className={classes.fa} /></a></li> */}
            </ul>
        </div>
    );
}

export default socialMedia;
