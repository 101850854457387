import React from 'react';
import FileUpload from './FileUpload'

const Resume = props => {
    return (
        <div className='container'>
            <h4 className='display-4 text-center mb-4'>CV Upload</h4>
        <FileUpload/>
        </div>
    );
}

export default Resume;